import moment from 'moment-timezone';
import qs from 'query-string';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import ContentWrapper from '../../components/ContentWrapper';
import { Dropdown } from '../../components/Dropdown';
import { LoadingModal } from '../../components/LoadingModal';
import { Plot } from '../../components/PlotlyPlot';
import { useFetch } from '../../components/useFetch';
import { COLOR_PRIMARY } from '../../constants';
import { BLUE } from '../../styles/variables';
import { formatMoneyCents, formatPercentage } from '../../utils';
import { ensureString } from '../../utils/strings';
import { MyWidth } from '../bookings/components/MasonryGallery';
import { COMPLETION_TEAM_DASH_FILTERS } from './utils';

const formatMoney = (value) =>
  formatMoneyCents(value, {
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

type ResultRow = {
  salesagent_uid: string;
  salesagent_full_name?: string;

  team_name?: string;
  team_uid?: string;

  total_leads_count?: string;
  completed_bookings_count?: string;

  revenue?: string;
  non_unique_completion_rate?: string;
};

function SummaryRow({
  results,
  title,
  showQuota,
  highlightColor,
}: {
  results: ResultRow[];
  title: string;
  showQuota?: boolean;
  highlightColor?: string;
}) {
  return (
    <tr
      style={{
        color: highlightColor,
        fontSize: '1.2rem',
      }}
    >
      <th scope="row" className="text-capitalize">
        {title}
      </th>

      <th align="right" className="text-right">
        {results.reduce(
          (acc, current) => parseFloat(current.total_leads_count ?? '0') + acc,
          0
        )}
      </th>

      <th align="right" className="text-right">
        {results.reduce(
          (acc, current) =>
            parseFloat(current.completed_bookings_count ?? '0') + acc,
          0
        )}
      </th>

      <th align="right" className="text-right">
        {formatMoney(
          results.reduce(
            (acc, current) => parseFloat(current.revenue ?? '0') + acc,
            0
          )
        )}
      </th>

      <th align="right" className="text-right">
        {formatPercentage(
          (results.reduce(
            (acc, current) =>
              parseFloat(current.completed_bookings_count ?? '0') + acc,
            0
          ) ?? 0) /
            (results.reduce(
              (acc, current) =>
                parseFloat(current.total_leads_count ?? '0') + acc,
              0
            ) ?? 0)
        )}
      </th>
    </tr>
  );
}

function CompletionTeamPerformanceModel({
  session,
  fromDate,
  toDate,
  chartType,
  showQuota,
}: {
  session;
  fromDate: string;
  toDate: string;
  showQuota?: boolean;
  chartType;
}) {
  const metricsFetch = useFetch<{
    results?: ResultRow[];
    count?: number;
  }>({
    urlToFetch: '/api/v2/completion-agents/performance',
    queryToFetch: {
      fromDate,
      toDate,
    },
    token: session.token,
  });

  const teams = metricsFetch.result?.results?.reduce((arr, row) => {
    let teamRow = arr.find((r) =>
      row.team_uid == null ? r.teamId == null : r.teamId === row.team_uid
    );

    if (teamRow == null) {
      teamRow = { teamId: row.team_uid, teamName: row.team_name, results: [] };
      arr.push(teamRow);
    }

    teamRow.results.push(row);

    return arr;
  }, [] as { teamId?: string; teamName?: string; results: ResultRow[] }[]);

  return (
    <>
      <ContentWrapper>
        <table className="table table-hover" style={{ position: 'relative' }}>
          <thead>
            <tr>
              <th scope="col">Agent</th>
              <th scope="col" className="text-right">
                Leads
              </th>
              <th scope="col" className="text-right">
                Completed
              </th>
              <th scope="col" className="text-right">
                Revenue
              </th>
              <th scope="col" className="text-right">
                Completion rate
              </th>
            </tr>
          </thead>

          <tbody>
            {teams?.map((team) => (
              <React.Fragment key={team.teamId}>
                <SummaryRow
                  results={team.results}
                  title={
                    team.teamName == null
                      ? 'Unknown team'
                      : `Team ${team.teamName}`
                  }
                  showQuota={showQuota}
                  highlightColor={
                    team.teamName === 'MARk$m3n'
                      ? '#EFCB44'
                      : team.teamName === 'VIN$@n!ty'
                      ? '#28a745'
                      : team.teamName === 'ABZ@$$!n$'
                      ? '#6B1806'
                      : team.teamName === 'CHEr!3BOMBer$'
                      ? '#F93005'
                      : team.teamName === '$L@mDUNNk3r$'
                      ? '#445AEF'
                      : team.teamName == null
                      ? '#85878F'
                      : undefined
                  }
                />

                {team.results?.map((agent) => (
                  <tr key={agent.salesagent_uid}>
                    <th scope="row" className="text-capitalize">
                      {agent.salesagent_full_name}
                    </th>
                    <td align="right">{agent.total_leads_count}</td>
                    <td align="right">{agent.completed_bookings_count}</td>
                    <td align="right">{formatMoney(agent.revenue)}</td>
                    <td align="right">
                      {formatPercentage(agent.non_unique_completion_rate)}
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            ))}

            {metricsFetch.result?.results != null && (
              <SummaryRow
                results={metricsFetch.result?.results}
                title="All Teams Total"
                showQuota={showQuota}
                highlightColor="red"
              />
            )}
          </tbody>
        </table>

        {metricsFetch.isPending && <LoadingModal />}
      </ContentWrapper>

      <p style={{ margin: 0 }}>
        * Conversion rate = Unique paying customers / Unique customer leads
        <br />
        ** Only applies for agents whom worked a full month. Does not apply for
        agents who started mid month or only worked part of the month
      </p>
    </>
  );
}

export const CompletionTeamPerformanceRoute = ({ session, location }) => {
  const { filter = 'this-month' } = qs.parse(location.search);
  const chartType =
    ensureString(qs.parse(location.search).chartType) ?? 'leads';

  const { fromDate, toDate, label: filterLabel, showQuota } =
    COMPLETION_TEAM_DASH_FILTERS.find((f) => f.id === filter) ?? {};

  return (
    <div className="flex-1">
      <div className="d-flex justify-content-between align-items-center mb-3 pr-3">
        <h1>Completion team performance </h1>

        <Dropdown title={filterLabel}>
          {COMPLETION_TEAM_DASH_FILTERS.map(({ label, id }) => (
            <Link
              key={`filter-${id}`}
              className="btn-link dropdown-item"
              to={{
                ...location,
                search: qs.stringify({
                  ...qs.parse(location.search),
                  filter: id,
                }),
              }}
            >
              {label}
            </Link>
          ))}
        </Dropdown>
      </div>

      {fromDate == null || toDate == null ? null : (
        <CompletionTeamPerformanceModel
          session={session}
          fromDate={fromDate}
          toDate={toDate}
          chartType={chartType}
          showQuota={showQuota}
        />
      )}
    </div>
  );
};
