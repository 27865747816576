import React from 'react';
import uuidV4 from 'uuid/v4';
import { Promiser } from 'react-prometo';
import Textarea from 'react-textarea-autosize';
import debounce from 'lodash.debounce';
import moment from 'moment-timezone';

import { Fetcher } from './NewFetcher';
import { FirebaseLibLoader, FirebaseListener } from './Firebase';
import { apiFetch } from '../utils';
import { multiline } from '../utils/strings';

export class ScrollToBottom extends React.Component<any, any> {
  componentDidMount() {
    this.props.onScroll();
  }

  componentDidUpdate(prevProps) {
    const prevMessages = prevProps.messages;
    const newMessages = this.props.messages;
    if (
      prevMessages.length !== newMessages.length ||
      (prevMessages.length > 0 && prevMessages[prevMessages.length - 1].uid) !==
        (newMessages.length > 0 && newMessages[newMessages.length - 1].uid)
    ) {
      this.props.onScroll();
    }
  }

  render() {
    return null;
  }
}

export class ScrollAtBottom extends React.Component<
  { scrollableElement?; children: (p: boolean | undefined) => React.ReactNode },
  any
> {
  state = { atBottom: undefined };

  deactivate?: () => void;

  componentDidMount() {
    this.setup({ scrollableElement: this.props.scrollableElement });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.scrollableElement !== this.props.scrollableElement) {
      this.setup({ scrollableElement: this.props.scrollableElement });
    }
  }

  componentWillUnmount() {
    this.deactivate && this.deactivate();
  }

  setup = ({ scrollableElement }) => {
    this.deactivate && this.deactivate();

    if (scrollableElement == null) {
      const scrollNode = document.scrollingElement || document.documentElement;

      const scrollMonitor = () =>
        this.setState({
          atBottom:
            scrollNode != null &&
            scrollNode.scrollHeight <=
              scrollNode.scrollTop +
                window.innerHeight +
                // Add 2 because high res display might have floats here
                2,
        });
      const deboucedScrollMonitor = debounce(scrollMonitor, 200, {
        leading: true,
        trailing: true,
      });

      document.addEventListener('scroll', deboucedScrollMonitor);

      this.deactivate = () => {
        deboucedScrollMonitor.cancel();
        document.removeEventListener('scroll', deboucedScrollMonitor);
      };
    } else {
      const scrollMonitor = () =>
        this.setState({
          atBottom:
            Math.abs(
              scrollableElement.scrollTop -
                (scrollableElement.scrollHeight -
                  scrollableElement.offsetHeight)
            ) <= 2,
        });
      const deboucedScrollMonitor = debounce(scrollMonitor, 200, {
        leading: true,
        trailing: true,
      });

      scrollableElement.addEventListener('scroll', deboucedScrollMonitor);

      this.deactivate = () => {
        deboucedScrollMonitor.cancel();
        scrollableElement.removeEventListener('scroll', deboucedScrollMonitor);
      };
    }
  };

  render() {
    return this.props.children(this.state.atBottom);
  }
}

class CursorCompare extends React.Component<any, any> {
  componentDidUpdate(prevProps) {
    if (prevProps.cursor != null && prevProps.cursor !== this.props.cursor) {
      this.props.reload();
    }
  }

  render() {
    return null;
  }
}

export class ChatManager extends React.Component<any, any> {
  state = { message: null, sendMessagePromise: null, pendingMessages: [] };

  sendMessage = ({ chatMessagesReload }) => {
    const { chatChannelId, session: { token } = {} as any } = this.props;

    const clientId = uuidV4();

    const sendMessagePending = apiFetch(
      `/api/v2/chat/channels/${chatChannelId}/messages`,
      {
        token,
        method: 'POST',
        body: JSON.stringify({ message: this.state.message }),
      }
    )
      .then(() => chatMessagesReload())
      .then(() =>
        this.setState((state) => ({
          pendingMessages: state.pendingMessages.filter(
            (pendingMessage) => pendingMessage.clientId !== clientId
          ),
        }))
      );

    this.setState((state) => ({
      sendMessagePending,
      message: null,
      pendingMessages: [
        ...state.pendingMessages,
        { clientId, message: this.state.message },
      ],
    }));

    return sendMessagePending;
  };

  render() {
    const {
      chatChannelId,
      chatChannelFirebaseRef,
      firebaseConfig,
      session,
    } = this.props;

    return (
      <FirebaseLibLoader
        chatChannelId={chatChannelId}
        session={session}
        config={firebaseConfig}
      >
        {({ result: firebase }) => (
          <FirebaseListener
            firebase={firebase}
            refPath={chatChannelFirebaseRef}
          >
            {
              // @ts-ignore
              ({ val: { latest_cursor } = {} }) => (
                <Fetcher
                  disabled={chatChannelId == null}
                  urlToFetch={`/api/v2/chat/channels/${chatChannelId}/messages`}
                  session={session}
                >
                  {({
                    isPending: chatMessagesPending,
                    // @ts-ignore
                    result: { results: chatMessages } = {},
                    reload: chatMessagesReload,
                  }) => (
                    <React.Fragment>
                      <CursorCompare
                        cursor={latest_cursor}
                        reload={chatMessagesReload}
                      />

                      <Promiser promise={this.state.sendMessagePromise}>
                        {({ isPending: sendMessagePending }) =>
                          // @ts-ignore
                          this.props.children({
                            chatMessagesPending,
                            chatMessages: chatMessages && [
                              ...[...chatMessages].reverse(),
                              ...this.state.pendingMessages.map(
                                (pendingMessage) => ({
                                  // @ts-ignore
                                  uid: pendingMessage.clientId,
                                  // @ts-ignore
                                  message: pendingMessage.message,
                                  isPending: true,
                                })
                              ),
                            ],
                            chatMessagesReload,

                            message: this.state.message,
                            setMessage: ({ value }) =>
                              this.setState({ message: value }),

                            sendMessage: () =>
                              this.sendMessage({ chatMessagesReload }),
                            sendMessagePending,
                          })
                        }
                      </Promiser>
                    </React.Fragment>
                  )}
                </Fetcher>
              )
            }
          </FirebaseListener>
        )}
      </FirebaseLibLoader>
    );
  }
}

export const ChatMessage = ({
  message,
  currentUserId,
}: {
  message: {
    user_uid: string;
    isPending?: boolean;
    message?: string;
    created_at: string;
  };
  currentUserId: string;
}) => (
  <div
    className={
      'd-flex flex-shrink-0' +
      (message.isPending || currentUserId === message.user_uid
        ? ' justify-content-end'
        : ' justify-content-start')
    }
    style={{
      opacity: message.isPending ? 0.7 : 1,
    }}
  >
    <div
      className={
        'card mt-1' +
        (message.isPending || currentUserId === message.user_uid
          ? ' bg-primary text-white'
          : ' bg-light')
      }
    >
      <div className="card-body p-2 px-3">
        {multiline(message.message)}

        <div style={{ height: 8 }} />
        <small className="text-secondary">
          {moment(message.created_at).fromNow()} /{' '}
          {moment(message.created_at).format('llll Z')}
        </small>
      </div>
    </div>
  </div>
);

export const ChatComposer = ({
  wrapperRef,
  wrapperPosition = 'fixed',
  message,
  setMessage,
  sendMessage,
  disabled = false,
}) => (
  <div
    ref={wrapperRef}
    className="border-top d-flex"
    style={{
      // @ts-ignore
      position: wrapperPosition,
      bottom: 0,
      right: 0,
      left: 0,
    }}
  >
    <form
      className="position-relative flex-grow-1 d-flex"
      onSubmit={(ev) => {
        ev.preventDefault();
        if (disabled) return;
        message && message.length > 0 && sendMessage();
      }}
    >
      <Textarea
        className="w-100 border-0 py-3 pl-3"
        style={{ paddingRight: 80 }}
        maxRows={5}
        value={message || ''}
        onChange={({ target: { value } }) =>
          setMessage({
            value: value === '' ? null : value,
          })
        }
        onKeyPress={(ev) => {
          // Send the message when pressing enter but without shift key
          if (ev.key === 'Enter' && !ev.shiftKey) {
            ev.preventDefault();
            message && message.length > 0 && sendMessage();
          }
        }}
        disabled={disabled}
      />

      <div
        className="d-flex align-items-center justify-content-center"
        style={{
          position: 'absolute',
          right: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <button
          type="submit"
          className="btn btn-sm btn-outline-primary mx-3 my-0 text-uppercase"
          disabled={disabled}
        >
          Send
        </button>
      </div>
    </form>
  </div>
);
