import DeclarativeComponent from '@reach/component-component';

const PARTY_AT_FAULT = {
  CUSTOMER: 'customer',
  PHOTOGRAPHER: 'photographer',
  SNAPPR: 'Snappr',
};

const reasonsForParty = ({ reasons, party }) =>
  ({
    [PARTY_AT_FAULT.PHOTOGRAPHER]: reasons.provider,
    [PARTY_AT_FAULT.SNAPPR]: reasons.snappr,
  }[party] ?? reasons.customer);

export const CancellationReasonSelector = ({
  reasons,
  selectedReason,
  autoSelectReason = true,
  onReasonChange,
}) => (
  <DeclarativeComponent
    initialState={{
      partyAtFault:
        autoSelectReason === true ? PARTY_AT_FAULT.CUSTOMER : undefined,
    }}
    didMount={({ state }) =>
      autoSelectReason === true &&
      onReasonChange({
        reason: reasonsForParty({ reasons, party: state.partyAtFault })[0],
      })
    }
    willUnmount={() => onReasonChange({ reason: null })}
  >
    {({ state, setState }) => (
      <>
        <div>
          <span>Party at fault:</span>
          <div className="form-check form-check-inline ml-2">
            <input
              id="customerAtFault"
              className="form-check-input"
              type="radio"
              name="partyAtFaultOptions"
              value={PARTY_AT_FAULT.CUSTOMER}
              checked={state.partyAtFault === PARTY_AT_FAULT.CUSTOMER}
              onChange={({ target: { value } }) => {
                setState({ partyAtFault: value });

                onReasonChange({
                  reason: reasonsForParty({ reasons, party: value })[0],
                });
              }}
            />
            <label htmlFor="customerAtFault" className="form-check-label">
              Customer
            </label>
          </div>

          <div className="form-check form-check-inline">
            <input
              id="photographerAtFault"
              className="form-check-input"
              type="radio"
              name="partyAtFaultOptions"
              value={PARTY_AT_FAULT.PHOTOGRAPHER}
              checked={state.partyAtFault === PARTY_AT_FAULT.PHOTOGRAPHER}
              onChange={({ target: { value } }) => {
                setState({ partyAtFault: value });

                onReasonChange({
                  reason: reasonsForParty({ reasons, party: value })[0],
                });
              }}
            />
            <label htmlFor="photographerAtFault" className="form-check-label">
              Photographer
            </label>
          </div>

          <div className="form-check form-check-inline">
            <input
              id="snapprAtFault"
              className="form-check-input"
              type="radio"
              name="partyAtFaultOptions"
              value={PARTY_AT_FAULT.SNAPPR}
              checked={state.partyAtFault === PARTY_AT_FAULT.SNAPPR}
              onChange={({ target: { value } }) => {
                setState({ partyAtFault: value });

                onReasonChange({
                  reason: reasonsForParty({ reasons, party: value })[0],
                });
              }}
            />
            <label htmlFor="snapprAtFault" className="form-check-label">
              Snappr
            </label>
          </div>
        </div>

        <div className="mt-2">
          <span>Cancellation reason</span>

          <select
            className="form-control d-inline w-50 ml-2"
            value={selectedReason || ''}
            disabled={state.partyAtFault == null}
            onChange={({ target: { value } }) =>
              onReasonChange({
                reason: value,
              })
            }
          >
            {reasonsForParty({ reasons, party: state.partyAtFault }).map(
              (reason) => (
                <option key={reason} value={reason}>
                  {reason}
                </option>
              )
            )}
          </select>
        </div>
      </>
    )}
  </DeclarativeComponent>
);
