import React, { useState, useEffect } from 'react';

import { apiFetch, checkIfCanSeeAnythingInTheAdmin } from './utils';
import { Routes } from './routes';

import {
  getApolloClient,
  ApolloProvider,
  gql,
  useQuery,
} from './components/ApolloClient';

function getLocalSession(store = window.localStorage) {
  return Promise.resolve().then(() => ({
    token: store.getItem('_auth.token'),
    expire: store.getItem('_auth.expire'),
    uid: store.getItem('_auth.uid'),
  }));
}

function redirectToLogin() {
  window.location.replace('/login');
}

function ApolloProviderConfig({
  session,
  children,
}: {
  session?: { token: string };
  children: React.ReactNode;
}) {
  const token = session?.token;

  const [client, setClient] = React.useState<
    ReturnType<typeof getApolloClient>
  >();

  React.useEffect(() => {
    if (token == null) {
      return;
    }

    setClient(getApolloClient({ token }));
  }, [token]);

  return client == null ? null : (
    <ApolloProvider client={client}>{children}</ApolloProvider>
  );
}

function Loading() {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '100wh',
        minHeight: '100vh',
      }}
    >
      <div
        style={{
          borderRadius: 4,
          padding: '2em',
          backgroundColor: '#fff',
          boxShadow: '0 0 1.625em rgba(0,0,0,.05)',
        }}
      >
        <h3 style={{ padding: 0, margin: 0 }}>Loading dashboard...</h3>
      </div>
    </div>
  );
}

function IAM({ children, session }) {
  const { data, loading } = useQuery<{
    IAM?: { id?: string; authorizedResources?: string[] };
  }>(gql`
    query getIamUser {
      IAM {
        id
        authorizedResources
      }
    }
  `);

  if (loading) return <Loading />;
  if (session == null || data?.IAM?.authorizedResources == null) return null;

  session = {
    ...session,
    user: {
      ...session.user,
      IAM: data?.IAM?.authorizedResources,
    },
  };

  if (checkIfCanSeeAnythingInTheAdmin({ session }) === false) {
    return (
      <div className="container flex-column flex-grow-1 d-flex justify-content-center align-items-center">
        <div
          style={{
            borderRadius: 4,
            padding: '2em',
            backgroundColor: '#fff',
            boxShadow: '0 0 1.625em rgba(0,0,0,.05)',
          }}
          className="flex-column d-flex justify-content-center align-items-center"
        >
          <h3>You don't have access</h3>

          <div className="pt-3" />

          <a className="btn btn-outline-dark" href="/logout">
            Logout
          </a>
        </div>
      </div>
    );
  }

  return children(session);
}

export function App() {
  const [loading, setLoading] = useState(true);
  const [session, setSession] = useState<any>(null);

  useEffect(() => {
    const getUserSession = async () => {
      const session = await getLocalSession();

      if (!session.token) {
        redirectToLogin();
        return;
      }

      let user;
      try {
        user = (
          await apiFetch<{ data: [{}] }>(
            `/rest/v1/user/${session.uid}?_fields=firstname,surname,email,username,grants,profileimage`,
            { token: session.token }
          )
        ).data[0];
      } catch (err) {
        if (err.status === 401) {
          redirectToLogin();
          return;
        }
        throw err;
      }

      setLoading(false);
      setSession({ ...session, user });
    };

    getUserSession();
  }, []);

  if (loading) {
    <Loading />;
  }

  return (
    <ApolloProviderConfig session={session}>
      <IAM session={session}>{(session) => <Routes session={session} />}</IAM>
    </ApolloProviderConfig>
  );
}
