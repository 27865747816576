import { Component } from 'react';

export class OnBeforeUnload extends Component {
  componentDidMount() {
    const message =
      this.props.message ||
      'There are pending changes. Are you sure you want to leave?';

    window.onbeforeunload = function () {
      return message;
    };

    const unblockHistory =
      this.props.history &&
      (this.props.historyBlocker
        ? this.props.history.block(this.props.historyBlocker({ message }))
        : this.props.history.block(message));

    this.deactivate = () => {
      window.onbeforeunload = null;
      unblockHistory && unblockHistory();
    };
  }

  componentWillUnmount() {
    this.deactivate();
  }

  render() {
    return null;
  }
}
