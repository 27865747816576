import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import moment from 'moment-timezone';
import Component2 from '@reach/component-component';
import InView from 'react-intersection-observer';
import qs from 'query-string';
import Select from 'react-select';
import sortBy from 'lodash.sortby';

import ContentWrapper from '../../components/ContentWrapper';
import { gql, useMutation, useQuery } from '../../components/ApolloClient';
import { TdLink } from '../../components';
import { ensureString } from '../../utils/strings';
import { checkIfCanAccess, formatTimestampToCountdown } from '../../utils';
import { locationForModal, ModalRoute } from '../../components/ModalRoute';
import { Modal } from '../../components/Modal';
import { useFetch } from '../../components/useFetch';
import { ENABLE_HIDE_CLAIM_BUTTON } from '../../config';

const basePath = 'ai-portrait-job';

const STATUS = 'status';
const EDITOR = 'editor';
const Q = 'q';

type PortraitJobQuery = {
  offset: string;
  q: string;
  limit: string;
};

const statusOptions = [
  { label: 'created', value: 'created' },
  { label: 'paid', value: 'paid' },
  { label: 'queued', value: 'queued' },
  { label: 'succeeded', value: 'succeeded' },
  { label: 'completed', value: 'completed' },
  { label: 'canceled', value: 'canceled' },
  { label: 'processing', value: 'processing' },
  { label: 'failed', value: 'failed' },
  { label: 'pending revision', value: 'pending_revision' },
  { label: 'pending qc', value: 'pending_qc' },
  { label: 'in editing', value: 'in_editing' },
  { label: 'in qc', value: 'in_qc' },
  { label: 'pending upload', value: 'pending_upload' },
  { label: 'in selection', value: 'in_selection' },
  { label: 'triggering', value: 'triggering' },
  { label: 'starting', value: 'starting' },
  { label: 'ready to edit', value: 'ready_to_edit' },
];

const customStyles = {
  valueContainer: (provided, state) => ({
    ...provided,
    marginLeft: 70,
  }),
};

// Filters
const MultiSelectFiler = ({
  filterName,
  options,
  location,
  history,
}: {
  filterName: string;
  options;
  location: RouteComponentProps['location'];
  history: RouteComponentProps['history'];
}) => {
  const filterString = qs.parse(location.search)[filterName];
  const selectedValues = filterString
    ? ensureString(filterString ?? '')?.split(',')
    : [];

  const selectedOptions =
    selectedValues && options.filter((o) => selectedValues.includes(o.value));

  return (
    <div
      style={{
        minWidth: 150,
        marginRight: 16,
        position: 'relative',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 7,
          left: 10,
          zIndex: 2,
          textTransform: 'capitalize',
          color: '#C1C8D4',
        }}
      >
        {filterName !== 'dueAt' ? filterName : 'Due in'}
      </div>
      <div style={{ minWidth: 100 }}>
        <Select
          isMulti
          value={selectedOptions}
          options={options}
          styles={customStyles}
          placeholder=""
          onChange={(selectedOptions) => {
            const selected = (selectedOptions || []).map((s) => s.value);

            history.push({
              ...location,
              search: qs.stringify({
                ...qs.parse(location.search),
                [filterName]:
                  selected.length > 0 ? selected.join(',') : undefined,
              }),
            });
          }}
        />
      </div>
    </div>
  );
};

function DataRowsChunk({
  cursor,
  onMore,
  location,
  history,
  session,
  selectedIds,
  setSelectedIds,
  aiPortraitsJobsAssignToEditor,
}: {
  cursor?: string;
  onMore?: (p: { nextCursor: string }) => void;
  location: RouteComponentProps['location'];
  history: RouteComponentProps['history'];
  session: { token: string; uid: string };
  setSelectedIds: (p) => void;
  selectedIds: string[];
  aiPortraitsJobsAssignToEditor: any;
}) {
  const selectedStatus = React.useMemo(
    () =>
      ensureString(qs.parse(location.search)[STATUS] ?? null)?.split(',') ?? [],
    [location]
  );

  const selectedEditors = React.useMemo(
    () =>
      ensureString(qs.parse(location.search)[EDITOR] ?? null)?.split(',') ?? [],
    [location]
  );

  const q = React.useMemo(
    () => ensureString(qs.parse(location.search)[Q] ?? null) ?? '',
    [location]
  );

  type AiPortraitJob = {
    id: string;
    subjectName?: string;
    createdAt: string;
    paidAt?: string;
    deadlineDate?: string;
    status?: string;
    editorEmail?: string;
    headshotPackage?: { displayName?: string };
  };

  const aiPortraitJobList = useQuery<
    {
      aiPortraitJobList?: {
        edges?: AiPortraitJob[];
        cursor?: string;
      };
    },
    {
      first: number;
      cursor?: string;
      statusFilter?: string[];
      editorFilter?: string[];
      search?: string;
    }
  >(
    gql`
      query AiPortraitJobListAsAdmin(
        $first: Int
        $cursor: String
        $statusFilter: [String]
        $editorFilter: [String]
        $search: String
      ) {
        aiPortraitJobList(
          first: $first
          cursor: $cursor
          statusFilter: $statusFilter
          editorFilter: $editorFilter
          search: $search
        ) {
          edges {
            id
            subjectName
            createdAt
            paidAt
            deadlineDate
            editorEmail
            status
            headshotPackage {
              displayName
            }
          }
          cursor
        }
      }
    `,
    {
      variables: {
        cursor,
        first: cursor == null ? 20 : 40,
        statusFilter: selectedStatus,
        editorFilter: selectedEditors,
        search: q,
      },
      fetchPolicy: 'network-only',
    }
  );

  const AiPortraitJobListData =
    aiPortraitJobList.data?.aiPortraitJobList?.edges ?? [];

  const nextCursor = aiPortraitJobList.data?.aiPortraitJobList?.cursor;

  return (
    <>
      {onMore == null || nextCursor == null ? null : (
        <InView>
          {({ inView, ref }) => (
            <tr ref={ref}>
              {inView && <Component2 didMount={() => onMore({ nextCursor })} />}
            </tr>
          )}
        </InView>
      )}

      {AiPortraitJobListData == null && aiPortraitJobList.loading
        ? Array.from(Array(7)).map((i, index) => (
            <tr key={'loading-' + index}>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
            </tr>
          ))
        : AiPortraitJobListData?.map((aiPortraitJob) => (
            <tr key={aiPortraitJob.id}>
              <td>
                <input
                  checked={selectedIds.includes(aiPortraitJob?.id)}
                  type="checkbox"
                  disabled={aiPortraitJob.status === 'created'}
                  onChange={() =>
                    setSelectedIds((prev) =>
                      prev.includes(aiPortraitJob?.id)
                        ? prev.filter((id) => id !== aiPortraitJob?.id)
                        : [...prev, aiPortraitJob?.id]
                    )
                  }
                />
              </td>
              <TdLink
                title={aiPortraitJob.id}
                to={`${basePath}/${aiPortraitJob.id}`}
                className="text-truncate"
              >
                {aiPortraitJob.id}
              </TdLink>
              <TdLink
                title={aiPortraitJob.subjectName}
                to={`${basePath}/${aiPortraitJob.id}`}
                className="text-truncate"
              >
                {aiPortraitJob.subjectName}
              </TdLink>
              <TdLink
                title={aiPortraitJob.createdAt}
                to={`${basePath}/${aiPortraitJob.id}`}
                className="text-truncate"
              >
                {moment(aiPortraitJob.createdAt)
                  .tz(moment.tz.guess())
                  .format('YYYY-MM-DD HH:mm z')}
              </TdLink>
              <TdLink
                title={aiPortraitJob.paidAt}
                to={`${basePath}/${aiPortraitJob.id}`}
                className="text-truncate"
              >
                {aiPortraitJob.paidAt != null &&
                  moment(aiPortraitJob.paidAt)
                    .tz(moment.tz.guess())
                    .format('YYYY-MM-DD HH:mm z')}
              </TdLink>
              <TdLink
                title={aiPortraitJob.headshotPackage?.displayName}
                to={`${basePath}/${aiPortraitJob.id}`}
                className="text-truncate"
              >
                {aiPortraitJob.headshotPackage?.displayName}
              </TdLink>
              <TdLink
                title={aiPortraitJob.deadlineDate}
                to={`${basePath}/${aiPortraitJob.id}`}
                className="text-truncate"
                style={{
                  color:
                    moment(aiPortraitJob.deadlineDate) < moment() &&
                    aiPortraitJob.status !== 'completed'
                      ? 'red'
                      : null,
                }}
              >
                {aiPortraitJob.deadlineDate != null &&
                aiPortraitJob.status !== 'completed'
                  ? formatTimestampToCountdown({
                      deadline: aiPortraitJob.deadlineDate,
                    })
                  : '-'}
              </TdLink>
              <td>
                {aiPortraitJob.editorEmail == null &&
                aiPortraitJob.status !== 'created' &&
                (ENABLE_HIDE_CLAIM_BUTTON === true
                  ? !(
                      aiPortraitJob.status === 'succeeded' ||
                      aiPortraitJob.status === 'in_selection'
                    )
                  : true) ? (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      aiPortraitsJobsAssignToEditor({
                        variables: {
                          aiPortraitJobIds: [aiPortraitJob.id],
                          editorId: session.uid,
                        },
                      }).then(() => aiPortraitJobList.refetch());
                    }}
                  >
                    Claim
                  </button>
                ) : (
                  aiPortraitJob.editorEmail ?? '-'
                )}
              </td>

              <TdLink
                title={aiPortraitJob.status}
                to={`${basePath}/${aiPortraitJob.id}`}
                className="text-truncate"
              >
                {aiPortraitJob.status ?? '-'}
              </TdLink>
            </tr>
          ))}

      {onMore == null || nextCursor == null ? null : (
        <InView>
          {({ inView, ref }) => (
            <tr ref={ref}>
              {inView && <Component2 didMount={() => onMore({ nextCursor })} />}
            </tr>
          )}
        </InView>
      )}
    </>
  );
}

const MODAL_NAME_ASSIGN_EDITOR = 'assign-editor';

type Editor = {
  edited_bookings_count: number;
  email: string;
  uid: string;
};

type EditorsResponse = {
  results: Editor[];
};

export function AssignEditorModal({
  onDismiss,
  selectedIds,
  session,
  aiPortraitsJobsAssignToEditor,
}: {
  onDismiss: () => void;
  selectedIds: string[];
  session: any;
  aiPortraitsJobsAssignToEditor: any;
}) {
  const [selectedEditor, setSelectedEditor] = React.useState<{
    value: string;
    label: string;
  }>();

  const {
    isPending: editorsPending,
    result: { results: editors = [] } = {},
  } = useFetch<EditorsResponse>({
    urlToFetch: `/api/v2/photo-editing-queue/editors`,
    session,
  });

  return (
    <Modal onDismiss={onDismiss}>
      <div className="card">
        <div className="card-body d-flex flex-column" style={{ minWidth: 600 }}>
          <h3>
            Assign editor for {selectedIds.length}{' '}
            {selectedIds.length === 1 ? 'ai portrait job' : 'ai portrait jobs'}
          </h3>

          <form
            className="d-flex flex-column mt-4"
            onSubmit={async (ev) => {
              ev.preventDefault();

              if (selectedEditor != null) {
                await aiPortraitsJobsAssignToEditor({
                  variables: {
                    aiPortraitJobIds: selectedIds,
                    editorId: selectedEditor.value,
                  },
                }).then(() => onDismiss());
              }
            }}
          >
            {editors == null ? (
              <div>loading...</div>
            ) : (
              <React.Fragment>
                <Select
                  className="w-100 pb-4"
                  placeholder={editorsPending ? 'Loading...' : 'Select editor'}
                  isLoading={editorsPending}
                  options={editors.map((editor) => ({
                    label: editor.email,
                    value: editor.uid,
                  }))}
                  value={selectedEditor}
                  onChange={(value: { label: string; value: string }) => {
                    setSelectedEditor(value);
                  }}
                  isSearchable
                  styles={{
                    menu: (provided, state) => ({
                      ...provided,
                      marginTop: 0,
                      top: 48,
                    }),
                    option: (styles, { data, isSelected }) => ({
                      ...styles,
                      backgroundColor: isSelected ? 'black' : undefined,
                      color: isSelected ? 'white' : undefined,
                    }),
                  }}
                />
                <div className="row">
                  <div className="col">
                    <button
                      type="button"
                      className="btn btn-outline-secondary btn-block"
                      onClick={onDismiss}
                    >
                      Cancel
                    </button>
                  </div>

                  <div className="col">
                    <button
                      type="submit"
                      className="btn btn-dark btn-block"
                      disabled={
                        selectedEditor == null ||
                        aiPortraitsJobsAssignToEditor.loading
                      }
                    >
                      {aiPortraitsJobsAssignToEditor.loading
                        ? 'Processing...'
                        : 'Confirm'}
                    </button>
                  </div>
                </div>{' '}
              </React.Fragment>
            )}
          </form>
        </div>
      </div>
    </Modal>
  );
}

const AssignEditorSection = ({
  location,
  selectedIds,
  session,
  aiPortraitsJobsAssignToEditor,
}: {
  location: RouteComponentProps['location'];
  selectedIds: string[];
  session: {
    token: string;
    uid: string;
    user: {
      IAM: string[];
    };
  };
  aiPortraitsJobsAssignToEditor: any;
}) => {
  return (
    <React.Fragment>
      {checkIfCanAccess(session, 'headshotQualityControl') && (
        <>
          <Link
            to={locationForModal({
              location,
              modal: { modalName: MODAL_NAME_ASSIGN_EDITOR },
            })}
            className={
              'btn btn-primary mr-2' +
              (selectedIds.length === 0 ? ' disabled' : '')
            }
            replace
          >
            Assign editor
          </Link>

          <button
            onClick={async () => {
              await aiPortraitsJobsAssignToEditor({
                variables: {
                  aiPortraitJobIds: selectedIds,
                  editorId: null,
                },
              }).then(() => window.location.reload());
            }}
            className={
              'btn btn-danger mr-2' +
              (selectedIds.length === 0 ? ' disabled' : '')
            }
          >
            Remove editor
          </button>
        </>
      )}

      <ModalRoute modalName={MODAL_NAME_ASSIGN_EDITOR}>
        {({ location, history }) => (
          <AssignEditorModal
            session={session}
            selectedIds={selectedIds}
            aiPortraitsJobsAssignToEditor={aiPortraitsJobsAssignToEditor}
            onDismiss={() =>
              Promise.resolve()
                .then(() =>
                  history.push(locationForModal({ location, modal: undefined }))
                )
                .then(() => window.location.reload())
            }
          />
        )}
      </ModalRoute>
    </React.Fragment>
  );
};

export const AiPortraitJobListRoute = ({
  location,
  session,
  history,
}: {
  location: RouteComponentProps['location'];
  session: {
    token: string;
    uid: string;
    user: {
      IAM: string[];
    };
  };
  history: RouteComponentProps['history'];
}) => {
  const [cursors, setCursors] = React.useState<string[]>([]);
  const [selectedIds, setSelectedIds] = React.useState<string[]>([]);

  const [aiPortraitsJobsAssignToEditor] = useMutation<
    { success: boolean },
    {
      aiPortraitJobIds: string[];
      editorId: string;
    }
  >(
    gql`
      mutation aiPortraitsJobsClaim($aiPortraitJobIds: [ID!]!, $editorId: ID) {
        aiPortraitsJobsAssignToEditor(
          input: { aiPortraitJobIds: $aiPortraitJobIds, editorId: $editorId }
        ) {
          success
        }
      }
    `
  );

  const editorsFetch = useFetch<{
    results?: {
      uid: string;
      email: string;
      edited_bookings_count: number;
    }[];
  }>({
    urlToFetch: '/api/v2/photo-editing-queue/editors',
    session,
  });

  const sortedEditors = React.useMemo(
    () =>
      sortBy(
        editorsFetch.result?.results?.map((e) => ({
          value: e.uid,
          label: e.email,
        })),
        (e) => e.value
      ),
    [editorsFetch.result?.results]
  );

  const { offset, q } = qs.parse(location.search) as PortraitJobQuery;

  const [search, setSearch] = React.useState<string>(q);

  const handleSubmit = (e) => {
    e.preventDefault();
    history.push({ ...location, search: qs.stringify({ offset, q: search }) });
  };

  return (
    <>
      <h2>AI Portrait jobs</h2>
      <ContentWrapper>
        <div
          className="table-responsive"
          style={{
            position: 'relative',
          }}
        >
          <div style={{ margin: 8, display: 'flex', flexWrap: 'wrap' }}>
            <MultiSelectFiler
              filterName={STATUS}
              options={statusOptions}
              location={location}
              history={history}
            />

            <MultiSelectFiler
              filterName={'editor'}
              options={sortedEditors}
              location={location}
              history={history}
            />

            <form
              onSubmit={handleSubmit}
              style={{ display: 'flex', marginRight: '16px' }}
            >
              <input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                type="text"
                className="form-control"
                style={{ maxWidth: 300 }}
                placeholder="Search by jobId"
              />
            </form>

            <AssignEditorSection
              location={location}
              selectedIds={selectedIds}
              session={session}
              aiPortraitsJobsAssignToEditor={aiPortraitsJobsAssignToEditor}
            />

            <table className="table table-hover">
              <thead>
                <tr>
                  <th className="text-muted text-truncate"></th>
                  <th className="text-muted text-truncate">ID</th>
                  <th className="text-muted text-truncate">Name</th>
                  <th className="text-muted text-truncate">Created at</th>
                  <th className="text-muted text-truncate">Paid at</th>
                  <th className="text-muted text-truncate">Package</th>
                  <th className="text-muted text-truncate">Deadline</th>
                  <th className="text-muted text-truncate">Allocated to</th>
                  <th className="text-muted text-truncate">Status</th>
                </tr>
              </thead>

              <tbody>
                {[null, ...cursors].map((cursor, index) => (
                  <DataRowsChunk
                    key={cursor ?? '0'}
                    cursor={cursor ?? '0'}
                    history={history}
                    location={location}
                    session={session}
                    selectedIds={selectedIds}
                    setSelectedIds={setSelectedIds}
                    aiPortraitsJobsAssignToEditor={
                      aiPortraitsJobsAssignToEditor
                    }
                    onMore={
                      index === cursors.length + 1 - 1
                        ? ({ nextCursor }) =>
                            setCursors((state) =>
                              state.includes(nextCursor)
                                ? state
                                : [...state, nextCursor]
                            )
                        : undefined
                    }
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </ContentWrapper>
    </>
  );
};
