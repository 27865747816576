import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { useQuery, gql } from '../../../../../components/ApolloClient';

import { Fetcher } from '../../../../../components/NewFetcher';
import { getAvatarUrl, apiFetch } from '../../../../../utils';
import {
  API_URL,
  ENABLE_PHOTOGRAPHER_GEO_BATCHING_SHOOTS,
} from '../../../../../config';
import { ModalRoute, locationForModal } from '../ModalRoute';
import { PhotographerSelection } from './PhotographerSelection';
import { PhotographerBatchMap } from './PhotographerBatchMap';
import { usePromise } from '../../../../../components/usePromise';
import moment, { Moment } from 'moment-timezone';
import { LoadingSpinnerCentered } from '../../../../../components/LoadingSpinner';
import { Tooltip } from '../../../../../components/Tooltip';
import { isAutoAllocationRunning } from '../../utils';
import { useGoogleMapsLoader } from '../../../../../components/useGoogleMapsLoader';
import { BOOKING_STATUS } from '../../selectors';
import BlackMarkerIcon from '../../../../../components/img/booking-black-marker.svg';
import PurpleMarkerIcon from '../../../../../components/img/booking-purple-marker.svg';

import 'react-datepicker/dist/react-datepicker.css';

enum BOOKING_STAGE {
  BOOKING_PAID = 'booking_paid',
  CUSTOMER_CONTACTED = 'customer_contacted',
  CUSTOMER_CONTACT_HELP_REQUESTED = 'customer_contact_help_requested',
  PHOTOS_TAKEN = 'photos_taken',
  PHOTOS_DELIVERED = 'photos_delivered',
}

enum TabCode {
  PHOTOGRAPHER = 'PHOTOGRAPHER',
  REALLOCATE = 'REALLOCATE',
  REALLOCATIONS = 'REALLOCATIONS',
  BATCH = 'BATCH',
}

function useBookingReallocationsQuery({ bookingId }: { bookingId?: string }) {
  return useQuery<{
    bookingById?: {
      reallocations?: {
        id: string;
        reason?: string;
        createdAt?: Date;
        provider?: { id: string; firstName?: string; lastName?: string };
      }[];
    };
  }>(
    gql`
      query BookingReallocationByIdQuery($bookingId: ID!) {
        bookingById(id: $bookingId) {
          reallocations {
            id
            reason
            createdAt
            provider {
              id
              firstName
              lastName
            }
          }
        }
      }
    `,
    {
      variables: { bookingId },
      skip: bookingId == null,
    }
  );
}

function BatchMapCardBody({
  bookingClone,
  hide = false,
  session,
  onProviderChange,
}: {
  bookingClone: { point: [number, number] };
  hide: boolean;
  session: { token: string };
  onProviderChange: (p: { provider_uid: string }) => void;
}) {
  const [date, setDate] = useState<Date>(new Date());
  const [maxDistance, setMaxDistance] = useState<
    { label: string; value: number } | undefined | null
  >({ label: '0 - 5 miles', value: 5 });
  const [isDoordash, setIsDoordash] = useState(false);

  const { maps } = useGoogleMapsLoader();

  const distanceOptions = [
    { label: '0 - 5 miles', value: 5 },
    { label: '5 - 10 miles', value: 10 },
    { label: '10 - 15 miles', value: 15 },
    { label: '15 - 20 miles', value: 20 },
    { label: '20 - 25 miles', value: 25 },
  ];

  return (
    <div className={'card-body flex-column' + (hide ? ' d-none' : ' d-flex')}>
      <div className="d-flex align-items-center justify-content-between">
        <div className="mr-2 mb-2" style={{ minWidth: '33%' }}>
          <DatePicker
            className="form-control"
            selected={date}
            placeholderText="Date"
            onChange={(date: Date) => {
              setDate(date);
            }}
          />
        </div>
        <div className="mr-2 mb-2" style={{ minWidth: '33%' }}>
          <Select
            value={maxDistance}
            placeholder="Distance"
            options={distanceOptions}
            onChange={(selectedOption) => {
              setMaxDistance(selectedOption);
            }}
          />
        </div>
        <div className="form-check" style={{ minWidth: '33%' }}>
          <input
            id="isDoordashBooking"
            className="form-check-input"
            type="checkbox"
            checked={isDoordash}
            onChange={({ target: { checked } }) => setIsDoordash(checked)}
          />
          <label className="form-check-label" htmlFor="isDoordashBooking">
            DoorDash Bookings
          </label>
        </div>
      </div>
      {maps != null &&
      bookingClone.point != null &&
      bookingClone.point.at(0) != null &&
      bookingClone.point.at(0) != null ? (
        <PhotographerBatchMap
          maps={maps}
          bookingClone={bookingClone}
          date={moment(date).format('YYYY-MM-DD')}
          maxDistance={maxDistance?.value}
          isDoordash={isDoordash}
          session={session}
          onProviderChange={onProviderChange}
        />
      ) : null}

      <div style={{ height: 20, width: '100' }} />

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={BlackMarkerIcon}
            alt="current-booking-marker"
            style={{ maxHeight: 22 }}
          />

          <div style={{ height: '100%', width: 4 }} />

          <p style={{ color: '#141B24', margin: 0, fontSize: 11 }}>
            Current Booking
          </p>
        </div>

        <div style={{ height: '100%', width: 20 }} />

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={PurpleMarkerIcon}
            alt="scheduled-booking"
            style={{ maxHeight: 22 }}
          />

          <div style={{ height: '100%', width: 4 }} />

          <p style={{ color: '#141B24', margin: 0, fontSize: 11 }}>
            Scheduled Bookings
          </p>
        </div>
      </div>
    </div>
  );
}

function ReallocationHistoryCardBody({
  reallocationsQuery,
  hide = false,
}: {
  reallocationsQuery: ReturnType<typeof useBookingReallocationsQuery>;
  hide: boolean;
}) {
  return (
    <div
      className={'card-body flex-column' + (hide ? ' d-none' : ' d-flex')}
      style={{ maxHeight: '450px' }}
    >
      <div className="table-responsive">
        <table className="table table-hover mb-0">
          <thead>
            <tr>
              <th className="text-muted text-truncate">Provider</th>
              <th className="text-muted text-truncate">Reason</th>
              <th className="text-muted text-truncate">Creation date</th>
            </tr>
          </thead>
          <tbody>
            {reallocationsQuery.data?.bookingById?.reallocations?.map(
              (reallocation) => (
                <tr key={reallocation?.id}>
                  <td>
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      to={`/providers/${reallocation?.provider?.id}`}
                    >
                      {reallocation?.provider?.firstName}{' '}
                      {reallocation?.provider?.lastName}
                    </Link>
                  </td>
                  <td>{reallocation?.reason}</td>
                  <td>
                    {moment(reallocation?.createdAt).format(
                      'MMM Do YYYY, h:mm a zz'
                    )}
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>{' '}
    </div>
  );
}

function PhotographerCardBody({
  location,
  bookingClone,
  session,
  hide = false,
}) {
  return (
    <div className={'card-body flex-column' + (hide ? ' d-none' : ' d-flex')}>
      {bookingClone.provider_booking_stage ===
      BOOKING_STAGE.CUSTOMER_CONTACT_HELP_REQUESTED ? (
        <span className="alert alert-danger">Contact help requested</span>
      ) : bookingClone.provider_booking_stage === BOOKING_STAGE.BOOKING_PAID ? (
        <span className="alert alert-warning">
          Hasn't contacted the customer
        </span>
      ) : null}

      <div className="d-flex justify-content-end align-items-center">
        <Link
          className={`btn btn-secondary ${
            isAutoAllocationRunning({ booking: bookingClone }) ? 'disabled' : ''
          }`}
          to={locationForModal({
            location,
            modal: {
              modalName: 'photographers',
              videography_available:
                bookingClone.service_type === 'videography',
            },
          })}
        >
          {bookingClone.provider_uid == null ? 'Select' : 'Change'}
        </Link>
      </div>

      {bookingClone.provider_uid && (
        <Fetcher
          urlToFetch={`/api/v2/admin/providers/${bookingClone.provider_uid}`}
          session={session}
        >
          {({ result: bookingProvider }) => (
            <>
              <div className="row">
                <div className="form-group col">
                  <label>
                    <small className="text-secondary">First name</small>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    readOnly
                    value={(bookingProvider && bookingProvider.firstname) || ''}
                    // @ts-ignore
                    onClick={(ev) => ev.target.select()}
                  />
                </div>

                <div className="form-group col">
                  <label>
                    <small className="text-secondary">Surname</small>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    readOnly
                    value={(bookingProvider && bookingProvider.surname) || ''}
                    // @ts-ignore
                    onClick={(ev) => ev.target.select()}
                  />
                </div>
              </div>

              <div className="form-group">
                <label>
                  <small className="text-secondary">Email</small>
                </label>
                <input
                  type="text"
                  className="form-control"
                  readOnly
                  value={(bookingProvider && bookingProvider.email) || ''}
                  // @ts-ignore
                  onClick={(ev) => ev.target.select()}
                />
              </div>

              <div className="form-group">
                <label>
                  <small className="text-secondary">Phone</small>
                </label>
                <input
                  type="text"
                  className="form-control"
                  readOnly
                  value={(bookingProvider && bookingProvider.mobilephone) || ''}
                  // @ts-ignore
                  onClick={(ev) => ev.target.select()}
                />
              </div>

              <div className="form-group">
                <label>
                  <small className="text-secondary">UID</small>
                </label>

                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    readOnly
                    value={bookingClone.provider_uid || ''}
                    // @ts-ignore
                    onClick={(ev) => ev.target.select()}
                  />

                  <div className="input-group-append">
                    <Link
                      to={`/providers/${bookingClone.provider_uid}`}
                      className="btn btn-outline-primary"
                    >
                      Go
                    </Link>
                  </div>
                </div>
              </div>

              <div className="flex-1 d-flex flex-column justify-content-center align-items-center">
                {bookingClone.provider_uid && (
                  <img
                    src={getAvatarUrl({
                      uid: bookingClone.provider_uid,
                    })}
                    alt="Profile"
                    className="rounded-circle"
                    width="60"
                    height="60"
                  />
                )}
              </div>
            </>
          )}
        </Fetcher>
      )}
    </div>
  );
}

function AvailableProviderCard({
  provider,
  index,
  onProviderChange,
}: {
  provider: Provider;
  index: number;
  onProviderChange: (p: { provider_uid: string }) => void;
}) {
  return (
    <div
      className={
        'd-flex justify-content-between' + (index === 0 ? '' : ' mt-3')
      }
    >
      <button
        className="btn btn-link py-0 d-flex align-items-center"
        onClick={() => onProviderChange({ provider_uid: provider.uid })}
      >
        <img
          src={`${API_URL}/rpc/v1/avatar/${provider.uid}`}
          alt={provider.firstname}
          width={30}
          height={30}
          className="rounded-circle"
          style={{ filter: 'grayscale(100%)' }}
        />

        <div className="d-flex flex-row align-items-center justify-content-between pl-3">
          <strong>
            {provider.firstname} {provider.surname}
          </strong>
        </div>
      </button>
    </div>
  );
}

function AvailableProvidersCardBody({
  availableProviders,
  availableSpecialistProviders,
  hide = false,
  loadingProviders = false,
  onProviderChange,
  bookingClone,
  reloadBooking,
  session,
}: {
  availableProviders: Provider[];
  availableSpecialistProviders: Provider[];
  hide: boolean;
  loadingProviders: boolean;
  onProviderChange: (p: { provider_uid: string }) => void;
  bookingClone;
  reloadBooking;
  session: { token: string };
}) {
  const triggerAutoAllocationPromiser = usePromise();

  return (
    <div
      className={
        'card-body flex-column justify-content-between' +
        (hide ? ' d-none' : ' d-flex')
      }
    >
      <div>
        <h5 className="text-center">Specialists</h5>
        {loadingProviders ? (
          <LoadingSpinnerCentered />
        ) : (
          (availableSpecialistProviders || []).map((provider, index) => (
            <AvailableProviderCard
              onProviderChange={onProviderChange}
              key={provider.uid}
              provider={provider}
              index={index}
            />
          ))
        )}

        <div className="p-3" />

        <h5 className="text-center">Non Specialists</h5>
        {loadingProviders ? (
          <LoadingSpinnerCentered />
        ) : (
          (availableProviders || []).map((provider, index) => (
            <AvailableProviderCard
              onProviderChange={onProviderChange}
              key={provider.uid}
              provider={provider}
              index={index}
            />
          ))
        )}

        <div className="p-1" />
      </div>

      <div className="d-flex flex-column">
        <h5 className="text-center">Or</h5>
        <div className="p-1" />

        {isAutoAllocationRunning({ booking: bookingClone }) ? (
          <button
            className="btn btn-warning"
            disabled={triggerAutoAllocationPromiser.isPending}
            onClick={() => {
              if (
                window.confirm(
                  'Do you want to cancel the auto-reallocation process for this booking?'
                )
              ) {
                triggerAutoAllocationPromiser.setPromise(
                  apiFetch(
                    `/api/v2/admin/bookings/${bookingClone.uid}/cancel-auto-allocation`,
                    { method: 'POST', token: session.token }
                  ).then(reloadBooking)
                );
              }
            }}
          >
            {triggerAutoAllocationPromiser.isPending
              ? 'Cancelling...'
              : 'Cancel auto-reallocation'}
          </button>
        ) : (
          <Tooltip
            label="This will trigger a process like the short notice one to reallocate the shoot"
            style={{
              background: 'hsla(0, 0%, 0%, 0.75)',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              padding: '0.5em 1em',
            }}
            centered
          >
            <button
              className="btn btn-primary"
              disabled={triggerAutoAllocationPromiser.isPending}
              onClick={() => {
                if (
                  window.confirm(
                    'Do you want to initiate an auto-reallocation process for this booking?'
                  )
                ) {
                  triggerAutoAllocationPromiser.setPromise(
                    apiFetch(
                      `/api/v2/admin/bookings/${bookingClone.uid}/start-auto-allocation`,
                      { method: 'POST', token: session.token }
                    ).then(reloadBooking)
                  );
                }
              }}
            >
              {triggerAutoAllocationPromiser.isPending
                ? 'Triggering...'
                : 'Trigger auto-reallocation'}
            </button>
          </Tooltip>
        )}
      </div>
    </div>
  );
}

type Provider = {
  uid: string;
  firstname: string;
  surname: string;
};

type ProviderAvailability = {
  startDate: Moment;
  availableProvidersUids: string[];
  availableSpecialistProvidersUids: string[];
};

export function PhotographerDetails({
  bookingClone,
  session,
  location,
  onProviderChange,
  providersAvailability,
  reloadBooking,
}: {
  bookingClone;
  session: { token: string };
  location;
  providersAvailability: ProviderAvailability[];
  onProviderChange: (p: { provider_uid: string }) => void;
  reloadBooking;
}) {
  const isAutoAllocationInProcess = isAutoAllocationRunning({
    booking: bookingClone,
  });
  const [selectedTab, setSelectedTab] = useState<TabCode>(
    isAutoAllocationInProcess ? TabCode.REALLOCATE : TabCode.PHOTOGRAPHER
  );

  const availableProvidersPromiser = usePromise<Provider[]>();
  const availableSpecialistProvidersPromiser = usePromise<Provider[]>();

  const {
    setPromise: setAvailableProviderPromise,
  } = availableProvidersPromiser;
  const {
    setPromise: setAvailableSpecialistProviderPromise,
  } = availableSpecialistProvidersPromiser;

  const timeSlot = useMemo(
    () =>
      providersAvailability.find(({ startDate }) =>
        startDate.isSame(
          moment(bookingClone.start_at).tz(bookingClone.timezone)
        )
      ) || { availableProvidersUids: [], availableSpecialistProvidersUids: [] },
    [bookingClone.start_at, bookingClone.timezone, providersAvailability]
  );

  const {
    availableProvidersUids = [],
    availableSpecialistProvidersUids = [],
  } = timeSlot;

  useEffect(() => {
    setAvailableProviderPromise(
      Promise.all(
        availableProvidersUids
          .filter((p) => !availableSpecialistProvidersUids.includes(p))
          .slice(0, 3)
          .map((providerId) =>
            apiFetch<Provider>(`/api/v2/admin/providers/${providerId}`, {
              token: session.token,
            })
          )
      )
    );

    setAvailableSpecialistProviderPromise(
      Promise.all(
        availableSpecialistProvidersUids.slice(0, 3).map((providerId) =>
          apiFetch<Provider>(`/api/v2/admin/providers/${providerId}`, {
            token: session.token,
          })
        )
      )
    );
  }, [
    availableProvidersUids,
    availableSpecialistProvidersUids,
    session.token,
    setAvailableProviderPromise,
    setAvailableSpecialistProviderPromise,
  ]);

  useEffect(() => {
    if (isAutoAllocationInProcess) {
      setSelectedTab(TabCode.REALLOCATE);
    }
  }, [isAutoAllocationInProcess]);

  const bookingReallocationsQuery = useBookingReallocationsQuery({
    bookingId: bookingClone.uid,
  });

  const hasReallocations =
    bookingReallocationsQuery?.data?.bookingById?.reallocations != null &&
    bookingReallocationsQuery?.data?.bookingById?.reallocations?.length > 0;

  const showBatchMap =
    ENABLE_PHOTOGRAPHER_GEO_BATCHING_SHOOTS &&
    [
      BOOKING_STATUS.PAID,
      BOOKING_STATUS.PAID_PENDING_SCHEDULE,
      BOOKING_STATUS.PENDING_SCHEDULE,
      BOOKING_STATUS.PAID_ON_HOLD,
    ].includes(bookingClone.status) === true;

  return (
    <Fetcher
      urlToFetch={`/api/v2/bookings/${bookingClone.uid}/provider-candidates`}
      session={session}
    >
      {({ result: { results: providerCandidates = [] } = {} }) => (
        <div className="col-xl-4 col-lg-12 d-flex flex-column">
          <div className="card flex-1">
            <div className="card-header">
              <ul className="nav nav-tabs card-header-tabs">
                <li className="nav-item">
                  <button
                    className={
                      'btn btn-link nav-link' +
                      (selectedTab !== TabCode.PHOTOGRAPHER ? '' : ' active')
                    }
                    onClick={() => setSelectedTab(TabCode.PHOTOGRAPHER)}
                  >
                    <strong className="mb-0">Photographer</strong>
                  </button>
                </li>

                <li className="nav-item">
                  <button
                    className={
                      'btn btn-link nav-link' +
                      (selectedTab !== TabCode.REALLOCATE ? '' : ' active')
                    }
                    onClick={() => setSelectedTab(TabCode.REALLOCATE)}
                  >
                    <strong className="mb-0">Reallocate</strong>
                  </button>
                </li>

                <li className="nav-item">
                  <button
                    className={
                      'btn btn-link nav-link' +
                      (selectedTab !== TabCode.REALLOCATIONS ? '' : ' active')
                    }
                    onClick={() => setSelectedTab(TabCode.REALLOCATIONS)}
                  >
                    {hasReallocations && (
                      <i
                        className="fa fa-exclamation mr-1"
                        aria-hidden="true"
                      />
                    )}
                    <strong className="mb-0">Reallocations</strong>
                  </button>
                </li>

                {showBatchMap && (
                  <li className="nav-item">
                    <button
                      className={
                        'btn btn-link nav-link' +
                        (selectedTab !== TabCode.BATCH ? '' : ' active')
                      }
                      onClick={() => setSelectedTab(TabCode.BATCH)}
                    >
                      <strong className="mb-0">Batch</strong>
                    </button>
                  </li>
                )}

                {/* <li className="nav-item">
                  <button
                    className={
                      'btn btn-link nav-link' +
                      (showReallocate ? ' active' : '')
                    }
                    onClick={() => setShowReallocate(true)}
                  >
                    <strong className="mb-0">
                      Candidates ({(providerCandidates || []).length})
                    </strong>
                  </button>
                </li> */}
              </ul>
            </div>

            <PhotographerCardBody
              location={location}
              bookingClone={bookingClone}
              session={session}
              hide={selectedTab !== TabCode.PHOTOGRAPHER}
            />

            <AvailableProvidersCardBody
              onProviderChange={({ provider_uid }) => {
                onProviderChange({ provider_uid });
                setSelectedTab(TabCode.PHOTOGRAPHER);
              }}
              availableProviders={availableProvidersPromiser.result || []}
              availableSpecialistProviders={
                availableSpecialistProvidersPromiser.result || []
              }
              loadingProviders={
                availableProvidersPromiser.isPending ||
                availableSpecialistProvidersPromiser.isPending
              }
              hide={selectedTab !== TabCode.REALLOCATE}
              bookingClone={bookingClone}
              reloadBooking={reloadBooking}
              session={session}
            />

            <ReallocationHistoryCardBody
              reallocationsQuery={bookingReallocationsQuery}
              hide={selectedTab !== TabCode.REALLOCATIONS}
            />

            <BatchMapCardBody
              bookingClone={bookingClone}
              hide={selectedTab !== TabCode.BATCH}
              session={session}
              onProviderChange={({ provider_uid }) => {
                onProviderChange({ provider_uid });
                setSelectedTab(TabCode.PHOTOGRAPHER);
              }}
            />

            {/* <CandidatesCardBody
              providerCandidates={providerCandidates}
              hide={!showReallocate}
            /> */}
          </div>

          <ModalRoute modalName="photographers">
            {(props) => (
              <PhotographerSelection
                {...props}
                bookingClone={bookingClone}
                session={session}
                onProviderChange={onProviderChange}
                reloadBooking={reloadBooking}
              />
            )}
          </ModalRoute>
        </div>
      )}
    </Fetcher>
  );
}
