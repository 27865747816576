import React from 'react';
import Select from 'react-select';
import moment from 'moment-timezone';

import { Promiser } from 'react-prometo';
import { Fetcher } from '../../components/NewFetcher';
import { formatCredits, apiFetch, formatMoneyCents } from '../../utils';
import { ErrorProcessor } from '../../components/ErrorProcessor';

const REASONS = [
  'booking_creation',
  'booking_time_location_change',
  'admin_other',
  'booking_package_change',
  'booking_refund',
  'top_up',
];

export class AdjustmentSection extends React.Component {
  state = {
    partnerId: undefined,
    credits: 0,
    reason: undefined,
    note: undefined,
    orderId: undefined,
    adjustmentPromise: null,
    partners: [],
  };

  render() {
    const { session } = this.props;

    return (
      <Promiser promise={this.state.adjustmentPromise}>
        {({
          result: adjustment,
          isPending: adjustmentPromisePending,
          error: adjustmentError,
        }) => (
          <form
            onSubmit={(event) => {
              event.preventDefault();

              this.setState({
                adjustmentPromise: apiFetch(
                  `/api/v2/partners/${this.state.partnerId}/adjustments`,

                  {
                    token: session.token,
                    method: 'POST',
                    body: JSON.stringify({
                      credits: this.state.credits,
                      reason: this.state.reason,
                      note: this.state.note,
                      orderId: this.state.orderId,
                    }),
                  }
                ),
              });
            }}
          >
            <h4>Credits adjustments</h4>
            <div className="form-group">
              <label htmlFor="credits">Partner</label>

              <Fetcher
                urlToFetch={`/api/v2/partners/summary`}
                session={session}
                key={adjustment && adjustment.uid}
                onFulfilled={({ result }) =>
                  this.setState({
                    partners: (result && result.results) || [],
                  })
                }
              >
                {({
                  result: { results: partners = [] } = {},
                  isLoading: loadingPartners,
                  reload: reloadPartners,
                }) => {
                  const partnerOptions = partners.map((partner) => ({
                    label: `${partner.name} - ${formatCredits(
                      partner.credits
                    )}`,
                    value: partner.uid,
                  }));

                  return (
                    <Select
                      value={partnerOptions.find(
                        (partner) => partner.value === this.state.partnerId
                      )}
                      options={partnerOptions}
                      onChange={(selectedOption) => {
                        this.setState({
                          partnerId: selectedOption.value,
                        });
                      }}
                    />
                  );
                }}
              </Fetcher>
            </div>

            <div className="form-row">
              <div className="form-group col">
                <label htmlFor="credits">Credits</label>
                <input
                  type="number"
                  className="form-control"
                  id="credits"
                  placeholder="credits"
                  value={this.state.credits}
                  onChange={(event) => {
                    this.setState({
                      credits: Number(event.target.value),
                    });
                  }}
                />
              </div>

              <div className="form-group col">
                <label htmlFor="reason">Reason</label>

                <select
                  className="form-control"
                  id="reason"
                  value={this.state.reason}
                  onChange={(event) => {
                    this.setState({ reason: event.target.value });
                  }}
                >
                  <option value="">Select a reason</option>
                  {REASONS.map((reason) => (
                    <option value={reason} key={reason}>
                      {reason}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {this.state.reason === 'top_up' && (
              <div className="form-group">
                <label htmlFor="order">Order Id</label>

                <Fetcher
                  urlToFetch={`/api/v2/partners/${this.state.partnerId}/credits-orders`}
                  session={session}
                  key={adjustment && adjustment.uid}
                >
                  {({
                    result: { results: orders = [] } = {},
                    isLoading: loadingOrders,
                  }) => {
                    const orderOptions = orders.map((order) => ({
                      label: `${order.uid} - ${formatMoneyCents(order.price, {
                        currency: order.currency,
                      })} - created ${moment(order.created_at).fromNow()}`,
                      value: order.uid,
                    }));

                    return (
                      <Select
                        value={orderOptions.find(
                          (order) => order.value === this.state.orderId
                        )}
                        options={orderOptions}
                        onChange={(selectedOption) => {
                          this.setState({
                            orderId: selectedOption.value,
                          });
                        }}
                      />
                    );
                  }}
                </Fetcher>
              </div>
            )}

            <div className="form-group">
              <label htmlFor="notes">Notes</label>
              <textarea
                type="text"
                className="form-control"
                id="notes"
                value={this.state.note}
                onChange={(event) => {
                  this.setState({ note: event.target.value });
                }}
              />
            </div>

            {adjustment && adjustment.uid && (
              <p className="text-success">
                Credit Transaction created successfully: {adjustment.uid}
              </p>
            )}

            <ErrorProcessor error={adjustmentError}>
              {({ result: errorBody }) =>
                errorBody ? (
                  <React.Fragment>
                    <p className="text-danger">Credit Transaction failed</p>
                    <p className="text-danger">{errorBody}</p>
                  </React.Fragment>
                ) : null
              }
            </ErrorProcessor>

            <button
              type="submit"
              className="btn btn-primary btn-block"
              disabled={
                !this.state.partnerId ||
                this.state.credits === 0 ||
                !this.state.reason ||
                adjustmentPromisePending
              }
            >
              {adjustmentPromisePending ? (
                'Processing'
              ) : (
                <span>
                  You're going to {this.state.credits > 0 ? 'add' : 'remove'}{' '}
                  {formatCredits(Math.abs(this.state.credits))} credits to{' '}
                  {
                    (
                      this.state.partners.find(
                        (p) => p.uid === this.state.partnerId
                      ) || {}
                    ).name
                  }
                </span>
              )}
            </button>
          </form>
        )}
      </Promiser>
    );
  }
}

export class OrderSection extends React.Component {
  state = {
    partnerId: undefined,
    credits: 0,
    price: 0,
    adjustmentPromise: null,
    partners: [],
  };

  render() {
    const { session } = this.props;

    return (
      <Promiser promise={this.state.adjustmentPromise}>
        {({
          result: { order_uid } = {},
          isPending: adjustmentPromisePending,
          error: adjustmentError,
        }) => (
          <form
            className="d-flex flex-1 h-100 flex-column justify-content-between"
            onSubmit={(event) => {
              event.preventDefault();

              this.setState({
                adjustmentPromise: apiFetch(
                  `/api/v2/partners/${this.state.partnerId}/credits-orders`,

                  {
                    token: session.token,
                    method: 'POST',
                    body: JSON.stringify({
                      credits: this.state.credits,
                      price: this.state.price,
                    }),
                  }
                ),
              });
            }}
          >
            <div>
              <h4>Associating an order number</h4>
              <div className="form-group">
                <label htmlFor="credits">Partner</label>

                <Fetcher
                  urlToFetch={`/api/v2/partners/summary`}
                  session={session}
                  key={order_uid}
                  onFulfilled={({ result }) =>
                    this.setState({
                      partners: (result && result.results) || [],
                    })
                  }
                >
                  {({
                    result: { results: partners = [] } = {},
                    isLoading: loadingPartners,
                    reload: reloadPartners,
                  }) => {
                    const partnerOptions = partners.map((partner) => ({
                      label: `${partner.name} - ${formatCredits(
                        partner.credits
                      )}`,
                      value: partner.uid,
                    }));

                    return (
                      <Select
                        value={partnerOptions.find(
                          (partner) => partner.value === this.state.partnerId
                        )}
                        options={partnerOptions}
                        onChange={(selectedOption) => {
                          this.setState({
                            partnerId: selectedOption.value,
                          });
                        }}
                      />
                    );
                  }}
                </Fetcher>
              </div>

              <div className="form-row">
                <div className="form-group col">
                  <label htmlFor="price">Payment</label>

                  <input
                    type="number"
                    className="form-control"
                    id="price"
                    placeholder="Price"
                    min={0}
                    value={this.state.price}
                    onChange={(event) => {
                      this.setState({
                        price: Number(event.target.value),
                      });
                    }}
                  />
                </div>

                <div className="form-group col">
                  <label htmlFor="reason">Credits</label>

                  <input
                    type="number"
                    className="form-control"
                    id="credits"
                    min={0}
                    placeholder="credits"
                    value={this.state.credits}
                    onChange={(event) => {
                      this.setState({
                        credits: Number(event.target.value),
                      });
                    }}
                  />
                </div>
              </div>

              {order_uid && (
                <p className="text-success">
                  Order created successfully: {order_uid}
                </p>
              )}
            </div>

            <ErrorProcessor error={adjustmentError}>
              {({ result: errorBody }) =>
                errorBody ? (
                  <React.Fragment>
                    <p className="text-danger">Order creation failed</p>
                    <p className="text-danger">{errorBody}</p>
                  </React.Fragment>
                ) : null
              }
            </ErrorProcessor>

            <button
              type="submit"
              className="btn btn-primary btn-block"
              disabled={
                !this.state.partnerId ||
                this.state.credits === 0 ||
                this.state.price === 0 ||
                adjustmentPromisePending
              }
            >
              {adjustmentPromisePending ? (
                'Processing'
              ) : (
                <span>
                  Create order {formatMoneyCents(this.state.price)} ||{' '}
                  {formatCredits(Math.abs(this.state.credits))} credits for{' '}
                  {
                    (
                      this.state.partners.find(
                        (p) => p.uid === this.state.partnerId
                      ) || {}
                    ).name
                  }
                </span>
              )}
            </button>
          </form>
        )}
      </Promiser>
    );
  }
}

export class AdjustmentRoute extends React.Component {
  state = {
    partnerId: undefined,
    credits: 0,
    reason: undefined,
    note: undefined,
    orderId: undefined,
    adjustmentPromise: null,
    partners: [],
  };

  render() {
    const { session } = this.props;

    return (
      <Promiser promise={this.state.adjustmentPromise}>
        {({
          result: adjustment,
          isPending: adjustmentPromisePending,
          error: adjustmentError,
        }) => (
          <React.Fragment>
            <h2>Accounting for partner credit adjustments</h2>
            <div className="d-flex flex-row">
              <div className="flex-1 bg-white p-3 w-50 soft-shadow border-rounded">
                <AdjustmentSection session={session} />
              </div>

              <div className="p-2" />

              <div className="bg-white w-50 p-3 soft-shadow ">
                <OrderSection session={session} />
              </div>
            </div>
          </React.Fragment>
        )}
      </Promiser>
    );
  }
}
