import React from 'react';
import { Promiser } from 'react-prometo';

export class FirebaseLibLoader extends React.Component {
  state = { loadPromise: null };

  componentDidMount() {
    this.setup(this.props);
  }

  componentDidUpdate() {
    this.setup(this.props);
  }

  setup = ({ session: { token } = {}, config }) => {
    const libLoadPromise = this.getFirebasePromise();
    const key = [token, config].map(JSON.stringify).join(':');
    if (this.key === key || config == null || token == null) return;
    this.key = key;
    this.onUnmount && this.onUnmount();

    const loadPromise = libLoadPromise.then((firebase) => {
      if (key !== this.key) return;

      const appName = config.databaseURL;
      if (FirebaseLibLoader.cache[appName] == null) {
        const app = firebase.initializeApp(config, config.databaseURL);
        FirebaseLibLoader.cache[appName] = app;
      }

      return FirebaseLibLoader.cache[appName];
    });

    this.setState({ loadPromise });
  };

  getFirebasePromise = () => {
    if (!FirebaseLibLoader.loadPromise) {
      FirebaseLibLoader.loadPromise = new Promise((resolve, reject) => {
        const head = document.getElementsByTagName('head')[0];

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = `https://www.gstatic.com/firebasejs/5.7.0/firebase-app.js`;

        // There are several events for cross browser compatibility.
        script.onreadystatechange = () => resolve();
        script.onload = () => resolve();
        script.onerror = () => {
          FirebaseLibLoader.loadPromise = null;
          reject(new Error('Error loading firebase base'));
        };

        head.appendChild(script);
      }).then(
        () =>
          new Promise((resolve, reject) => {
            const head = document.getElementsByTagName('head')[0];

            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = `https://www.gstatic.com/firebasejs/5.7.0/firebase-database.js`;

            // There are several events for cross browser compatibility.
            script.onreadystatechange = () => resolve(window.firebase);
            script.onload = () => resolve(window.firebase);
            script.onerror = () => {
              FirebaseLibLoader.loadPromise = null;
              reject(new Error('Error loading firebase database'));
            };

            head.appendChild(script);
          })
      );
    }

    return FirebaseLibLoader.loadPromise;
  };

  componentWillUnmount() {
    this.key = null;
    this.onUnmount && this.onUnmount();
  }

  render() {
    return (
      <Promiser promise={this.state.loadPromise}>
        {({ isPending, ...rest }) =>
          this.props.children({
            ...rest,
            isPending: this.state.loadPromise === null ? true : isPending,
          })
        }
      </Promiser>
    );
  }
}

FirebaseLibLoader.cache = {};

export class FirebaseListener extends React.Component {
  state = { val: null };

  componentDidMount() {
    this.setup(this.props);
  }

  componentDidUpdate() {
    this.setup(this.props);
  }

  componentWillUnmount() {
    this.key = null;
    this.onUnmount && this.onUnmount();
  }

  setup = ({ refPath, firebase }) => {
    const key = [refPath, !!firebase].map(JSON.stringify).join(':');
    if (key === this.key) return;
    this.key = key;

    if (refPath == null || firebase == null) return;

    this.onUnmount && this.onUnmount();

    this.ref = firebase.database().ref(refPath);
    this.ref.on('value', this.listener);
    this.onUnmount = () => this.ref.off('value', this.listener);
  };

  listener = (snapshot) => {
    this.setState(() => ({ val: snapshot.val() }));
  };

  render() {
    return this.props.children({
      val: this.state.val == null ? undefined : this.state.val,
    });
  }
}
