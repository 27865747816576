import moment from 'moment-timezone';
import React from 'react';
import { Link } from 'react-router-dom';
import qs from 'query-string';

import { ActionsDropdown } from '../../../components/ActionsDropdown';
import { gql, useQuery } from '../../../components/ApolloClient';

import {
  EnterpriseInvoiceCancelModal,
  EnterpriseInvoiceCreateModal,
  EnterpriseInvoiceSetToPaidModal,
  EnterpriseInvoiceSetToPendingModal,
} from '../../../components/EnterpriseInvoiceModal';
import { locationForModal, ModalRoute } from '../../../components/ModalRoute';
import { OrderByLink } from '../../../components/OrderByLink';
import { formatCredits, formatMoneyCents } from '../../../utils';
import { SearchField } from '../../partners/components';
import { API_URL } from '../../../config';

const MODAL_NAME_ENTERPRISE_INVOICE_SET_TO_PENDING_MODAL =
  'modal_name_enterprise_invoice_set_to_pending_modal';

const MODAL_NAME_ENTERPRISE_INVOICE_CREATE_MODAL =
  'modal_name_enterprise_invoice_create_modal';

const MODAL_NAME_ENTERPRISE_INVOICE_CANCEL_MODAL =
  'modal_name_enterprise_invoice_cancel_modal';

const MODAL_NAME_ENTERPRISE_INVOICE_SET_TO_PAID_MODAL =
  'modal_name_enterprise_invoice_set_to_paid_modal';

type Invoice = {
  id: string;
  invoiceId: string;
  status: string;
  dateIssued: string;
  datePaid?: string;
  credits: number;
  amount: number;
  currency: string;
  setToPaidBy?: string;
  pdfUrl?: string;
};

function InvoiceStatusToLabel(status) {
  return (
    {
      open: 'pending payment',
      pending: 'processing',
      paid: 'paid',
      cancelled: 'cancelled',
    }[status] || status
  );
}

const InvoiceStatusPil: React.FC<{ status: string }> = ({ status }) => (
  <span
    style={{
      borderRadius: 1,
      color: 'white',
      paddingLeft: 5,
      paddingRight: 5,
      paddingTop: 2,
      paddingBottom: 0,
      fontSize: 10,
      fontWeight: 'bold',
      backgroundColor:
        {
          paid: '#2FB67D',
          open: '#FFBB00',
          cancelled: '#E11F59',
          pending: '#1F6FFF',
        }[status] || 'gray',
    }}
  >
    {InvoiceStatusToLabel(status).toUpperCase()}
  </span>
);

function EnterpriseInvoiceList({
  location,
  partnerId,
  InvoiceList,
  history,
  session,
}: {
  partnerId: string;
  InvoiceList: Invoice[];
  history;
  location;
  session: { token: string };
}) {
  return (
    <div>
      <div className="d-flex align-items-center">
        <div className="d-flex flex-1 p-3">
          <SearchField
            onSearch={({ q }) =>
              history.push({
                ...location,
                search: qs.stringify({
                  ...qs.parse(location.search),
                  q: q || undefined,
                }),
              })
            }
            defaultValue={qs.parse(location.search).q || ''}
          />
        </div>
        <div className="d-flex flex-1 justify-content-end p-3">
          <Link
            className="btn btn-dark btn-sm"
            to={locationForModal({
              location,
              modal: {
                modalName: MODAL_NAME_ENTERPRISE_INVOICE_CREATE_MODAL,
              },
            })}
          >
            Create invoice
          </Link>
        </div>
      </div>

      <div
        className="table-responsive p-2"
        style={{ overflow: 'scroll', minHeight: 400 }}
      >
        <table className="table table-hover mb-0">
          <thead>
            <tr>
              <th className="text-muted text-truncate">
                <OrderByLink attrName="invoice_id" location={location}>
                  Invoice ID
                </OrderByLink>
              </th>
              <th className="text-muted text-truncate">
                <OrderByLink attrName="shoot_type" location={location}>
                  Status
                </OrderByLink>
              </th>
              <th className="text-muted text-truncate">
                <OrderByLink attrName="packages" location={location}>
                  Date Issued
                </OrderByLink>
              </th>
              <th className="text-muted text-truncate">
                <OrderByLink attrName="user_type" location={location}>
                  Date paid
                </OrderByLink>
              </th>
              <th className="text-muted text-truncate">
                <OrderByLink attrName="assets" location={location}>
                  Credits
                </OrderByLink>
              </th>
              <th className="text-muted text-truncate">
                <OrderByLink attrName="last_updated" location={location}>
                  Amount
                </OrderByLink>
              </th>
              <th className="text-muted text-truncate">
                <OrderByLink attrName="setToPaidBy" location={location}>
                  Set to paid by
                </OrderByLink>
              </th>
              <th className="text-muted text-truncate" />
            </tr>
          </thead>

          <tbody>
            {(InvoiceList ?? []).map((invoice) => (
              <tr key={invoice.id} style={{ whiteSpace: 'nowrap' }}>
                <td>{invoice.invoiceId}</td>

                <td>
                  <InvoiceStatusPil status={invoice.status} />
                </td>

                <td>
                  {invoice.dateIssued &&
                    moment(invoice.dateIssued).format('D MMM YY')}
                </td>

                <td>
                  {invoice.datePaid &&
                    moment(invoice.datePaid).format('D MMM YY')}
                </td>

                <td>{formatCredits(invoice.credits)}</td>

                <td>
                  {formatMoneyCents(invoice.amount, {
                    currency: invoice.currency,
                  })}
                </td>

                <td>{invoice.setToPaidBy}</td>

                <td className="text-right">
                  <ActionsDropdown>
                    {({ onClose }) => (
                      <div
                        style={{ textAlign: 'left', flexDirection: 'column' }}
                      >
                        <button
                          className="d-flex btn"
                          onClick={() =>
                            window.location.assign(
                              `${API_URL}/api/v2/partners/${partnerId}/${
                                invoice.status === 'paid'
                                  ? 'receipt'
                                  : 'invoice'
                              }/${invoice.id}?${qs.stringify({
                                access_token: (session || {}).token,
                              })}`
                            )
                          }
                          disabled={false}
                        >
                          Download PDF
                        </button>

                        {invoice.pdfUrl != null && (
                          <a
                            className="d-flex btn"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={invoice.pdfUrl}
                            style={{
                              textDecoration: 'none',
                              color: 'black',
                            }}
                          >
                            Download proof of payment
                          </a>
                        )}

                        {invoice.status === 'open' && (
                          <>
                            <Link
                              className="d-flex btn"
                              to={locationForModal({
                                location,
                                modal: {
                                  modalName: MODAL_NAME_ENTERPRISE_INVOICE_SET_TO_PENDING_MODAL,
                                  invoiceCredits: formatCredits(
                                    invoice.credits
                                  ),
                                  id: invoice.id,
                                },
                              })}
                            >
                              Submit for approval
                            </Link>
                            <Link
                              className="d-flex btn"
                              to={locationForModal({
                                location,
                                modal: {
                                  modalName: MODAL_NAME_ENTERPRISE_INVOICE_CANCEL_MODAL,
                                  id: invoice.id,
                                },
                              })}
                            >
                              Cancel Invoice
                            </Link>
                          </>
                        )}
                      </div>
                    )}
                  </ActionsDropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export const OnEnterpriseInvoiceCancelModal = ({
  partnerId,
  session,
  reloadInvoices,
}: {
  partnerId: string;
  session: { token: string };
  reloadInvoices: () => Promise<any>;
}) => {
  return (
    <ModalRoute
      modalName={MODAL_NAME_ENTERPRISE_INVOICE_CANCEL_MODAL}
      render={({ history, location, modalParams }) => (
        <EnterpriseInvoiceCancelModal
          onDismiss={() =>
            history.replace(
              locationForModal({
                location,
                modal: undefined,
              })
            )
          }
          session={session}
          location={location}
          history={history}
          partnerId={partnerId}
          onContinue={() =>
            reloadInvoices().then(() => {
              history.replace(
                locationForModal({
                  location,
                  modal: undefined,
                })
              );
            })
          }
          modalParams={{
            ...modalParams,
          }}
        />
      )}
    />
  );
};

export const OnEnterpriseInvoiceSetToPaidModal = ({
  partnerId,
  session,
  reloadInvoices,
}: {
  partnerId: string;
  session: { token: string };
  reloadInvoices: () => Promise<any>;
}) => {
  return (
    <ModalRoute
      modalName={MODAL_NAME_ENTERPRISE_INVOICE_SET_TO_PAID_MODAL}
      render={({ history, location, modalParams }) => (
        <EnterpriseInvoiceSetToPaidModal
          onDismiss={() =>
            history.replace(
              locationForModal({
                location,
                modal: undefined,
              })
            )
          }
          session={session}
          location={location}
          history={history}
          partnerId={partnerId}
          onContinue={() =>
            reloadInvoices().then(() => {
              history.replace(
                locationForModal({
                  location,
                  modal: undefined,
                })
              );
            })
          }
          modalParams={{
            ...modalParams,
          }}
        />
      )}
    />
  );
};

export const OnEnterpriseInvoiceSetToPendingModal = ({
  partnerId,
  session,
  reloadInvoices,
}: {
  partnerId: string;
  session: { token: string };
  reloadInvoices: () => Promise<any>;
}) => {
  return (
    <ModalRoute
      modalName={MODAL_NAME_ENTERPRISE_INVOICE_SET_TO_PENDING_MODAL}
      render={({ history, location, modalParams }) => (
        <EnterpriseInvoiceSetToPendingModal
          onDismiss={() =>
            history.replace(
              locationForModal({
                location,
                modal: undefined,
              })
            )
          }
          session={session}
          location={location}
          history={history}
          partnerId={partnerId}
          onContinue={() =>
            reloadInvoices().then(() => {
              history.replace(
                locationForModal({
                  location,
                  modal: undefined,
                })
              );
            })
          }
          modalParams={modalParams}
        />
      )}
    />
  );
};

export const OnEnterpriseInvoiceCreateModal = ({
  partnerId,
  currency,
  session,
  reloadInvoices,
}: {
  partnerId: string;
  currency: string;
  session: { token: string };
  reloadInvoices: () => Promise<any>;
}) => {
  return (
    <ModalRoute
      modalName={MODAL_NAME_ENTERPRISE_INVOICE_CREATE_MODAL}
      render={({ history, location, modalParams }) => (
        <EnterpriseInvoiceCreateModal
          onDismiss={() =>
            history.replace(
              locationForModal({
                location,
                modal: undefined,
              })
            )
          }
          session={session}
          location={location}
          history={history}
          partnerId={partnerId}
          currency={currency}
          onContinue={() =>
            reloadInvoices().then(() => {
              history.replace(
                locationForModal({
                  location,
                  modal: undefined,
                })
              );
            })
          }
          modalParams={{
            ...modalParams,
          }}
        />
      )}
    />
  );
};

export function EnterpriseInvoicesSection({
  session,
  partner,
  location,
  history,
}: {
  session: { token: string };
  partner: { name: string; uid: string; currency: string };
  location;
  history;
}) {
  const partnerInvoicesQuery = useQuery<{
    partnerById: {
      invoices: {
        edges: Invoice[];
      };
    };
  }>(
    gql`
      query PartnerInvoicesListQuery(
        $partnerId: UUID!
        $query: String
        $first: Int
      ) {
        partnerById(uid: $partnerId) {
          invoices(sort: INVOICE_ID_DESC, query: $query, first: $first) {
            edges {
              amount
              currency
              dateIssued
              datePaid
              credits
              id
              invoiceId
              status
              setToPaidBy
              pdfUrl
            }
          }
        }
      }
    `,
    {
      variables: {
        partnerId: partner.uid,
        query: qs.parse(location.search).q || undefined,
        first: 200,
      },
    }
  );

  const partnerInvoices = partnerInvoicesQuery.data?.partnerById.invoices.edges;

  return (
    <>
      {partnerInvoices != null && (
        <EnterpriseInvoiceList
          partnerId={partner.uid}
          InvoiceList={partnerInvoices}
          location={location}
          history={history}
          session={session}
        />
      )}

      <OnEnterpriseInvoiceSetToPendingModal
        partnerId={partner.uid}
        session={session}
        reloadInvoices={partnerInvoicesQuery.refetch}
      />

      <OnEnterpriseInvoiceCreateModal
        partnerId={partner.uid}
        currency={partner.currency}
        session={session}
        reloadInvoices={partnerInvoicesQuery.refetch}
      />

      <OnEnterpriseInvoiceCancelModal
        partnerId={partner.uid}
        session={session}
        reloadInvoices={partnerInvoicesQuery.refetch}
      />
    </>
  );
}
