import React from 'react';
import { Link } from 'react-router-dom';

import { Promiser } from 'react-prometo';
import { Fetcher } from '../../../../../components/Fetcher';
import { Modal } from '../../../../../components/Modal';
import { ErrorProcessor } from '../../../../../components/ErrorProcessor';
import { apiFetch } from '../../../../../utils';
import { locationForModal } from '../ModalRoute';

export const SALESAGENT_BOOKING_CLAIM_MODAL_NAME = 'salesagent-booking-claim';

const PreliminaryQuestion = ({ location, modalParams }) => (
  <div
    className="card-body d-flex flex-column"
    style={{ width: 400, minHeight: 200 }}
  >
    <div className="flex-1">
      <p>
        Did you have a substantial impact on the {"customer's"} decision to
        purchase <strong>prior</strong> to the booking being paid?
      </p>

      <p className="mb-0">
        Please note that if your claim is rejected, this information will be
        kept on your agent record.
      </p>
    </div>

    <div className="d-flex justify-content-between mt-3">
      <Link
        className="btn btn-secondary mr-2"
        to={locationForModal({
          location,
          modal: { ...modalParams, had_substantial_impact: false },
        })}
        replace
      >
        No
      </Link>

      <Link
        className="btn btn-primary"
        to={locationForModal({
          location,
          modal: { ...modalParams, had_substantial_impact: true },
        })}
        replace
      >
        Yes
      </Link>
    </div>
  </div>
);

const NoSubstantialImpact = ({ location, modalParams }) => (
  <div className="card-body d-flex flex-column" style={{ width: 400 }}>
    <div className="flex-1">
      <p className="mb-0">You {"can't"} send a claim request.</p>
    </div>

    <div className="d-flex justify-content-between mt-3">
      <Link
        className="btn btn-secondary mr-2"
        to={locationForModal({
          location,
          modal: undefined,
        })}
        replace
      >
        Close
      </Link>
    </div>
  </div>
);

export class SalesagentBookingClaimModal extends React.Component {
  state = {
    bookingClaimReason: null,
    bookingClaimNote: null,
    bookingClaimFreshsalesLink: null,

    bookingClaimCreationPromise: null,
  };

  render() {
    const {
      history,
      location,
      modalParams,
      session,
      bookingClone,
    } = this.props;

    return (
      <Modal
        onDismiss={() =>
          history.replace(
            locationForModal({
              location,
              modal: undefined,
            })
          )
        }
      >
        <div className="card my-4">
          <div className="card-header">
            <h4 className="mb-0">Booking claim</h4>
          </div>

          {modalParams.had_substantial_impact == null ? (
            <PreliminaryQuestion
              location={location}
              modalParams={modalParams}
            />
          ) : modalParams.had_substantial_impact === false ? (
            <NoSubstantialImpact
              location={location}
              modalParams={modalParams}
            />
          ) : (
            <Promiser promise={this.state.bookingClaimCreationPromise}>
              {({
                isPending: bookingClaimCreationPending,
                error: bookingClaimCreationError,
              }) => (
                <ErrorProcessor error={bookingClaimCreationError}>
                  {({ result: bookingClaimCreationErrorBody }) => (
                    <Fetcher
                      urlToFetch="/api/v2/salesagents/booking-claims/reasons"
                      session={session}
                      onFulfilled={({ response: { results } }) => {
                        this.setState({ bookingClaimReason: results[0] });
                      }}
                    >
                      {({
                        loading: reasonsPending,
                        response: { results: reasons = [] } = {},
                      }) => (
                        <div
                          className="card-body d-flex flex-column"
                          style={{ minWidth: 1000, minHeight: 300 }}
                        >
                          <div className="flex-1">
                            {bookingClaimCreationErrorBody && (
                              <div className="alert alert-danger">
                                {bookingClaimCreationErrorBody}
                              </div>
                            )}

                            <div className="d-flex align-items-baseline">
                              <label>
                                <small className="text-secondary">
                                  Reallocation reason
                                </small>
                              </label>

                              <select
                                className="form-control flex-1 ml-2"
                                value={this.state.bookingClaimReason || ''}
                                onChange={({ target: { value } }) =>
                                  this.setState({
                                    bookingClaimReason:
                                      value === '' ? null : value,
                                  })
                                }
                              >
                                {reasonsPending ? (
                                  <option value="">Loading...</option>
                                ) : (
                                  reasons.map((reason) => (
                                    <option key={reason} value={reason}>
                                      {reason}
                                    </option>
                                  ))
                                )}
                              </select>
                            </div>

                            <div className="form-group mt-2">
                              <label>
                                <small className="text-secondary">
                                  Freshsales link
                                </small>
                              </label>

                              <input
                                className="form-control"
                                value={
                                  this.state.bookingClaimFreshsalesLink || ''
                                }
                                onChange={({ target: { value } }) =>
                                  this.setState({
                                    bookingClaimFreshsalesLink:
                                      value === '' ? null : value,
                                  })
                                }
                              />
                            </div>

                            <div className="form-group mt-2">
                              <label>
                                <small className="text-secondary">Note</small>
                              </label>

                              <textarea
                                rows="3"
                                className="form-control"
                                value={this.state.bookingClaimNote || ''}
                                onChange={({ target: { value } }) =>
                                  this.setState({
                                    bookingClaimNote:
                                      value === '' ? null : value,
                                  })
                                }
                              />
                            </div>
                          </div>

                          <div className="d-flex justify-content-between mt-3">
                            <Link
                              className="btn btn-secondary mr-2"
                              to={locationForModal({
                                location,
                                modal: undefined,
                              })}
                              replace
                            >
                              Close
                            </Link>

                            <button
                              className="btn btn-primary"
                              disabled={
                                !this.state.bookingClaimReason ||
                                bookingClaimCreationPending
                              }
                              onClick={() =>
                                this.setState({
                                  bookingClaimCreationPromise: apiFetch(
                                    `/api/v2/salesagents/booking-claims`,
                                    {
                                      token: session.token,
                                      method: 'POST',
                                      body: JSON.stringify({
                                        reason: this.state.bookingClaimReason,
                                        note: this.state.bookingClaimNote,
                                        freshsales_link: this.state
                                          .bookingClaimFreshsalesLink,
                                        booking_uid: bookingClone.uid,
                                      }),
                                    }
                                  ).then(() =>
                                    history.replace(
                                      locationForModal({
                                        location,
                                        modal: undefined,
                                      })
                                    )
                                  ),
                                })
                              }
                            >
                              {bookingClaimCreationPending
                                ? 'Sending...'
                                : 'Send'}
                            </button>
                          </div>
                        </div>
                      )}
                    </Fetcher>
                  )}
                </ErrorProcessor>
              )}
            </Promiser>
          )}
        </div>
      </Modal>
    );
  }
}
