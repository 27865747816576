import React, {
  createRef,
  InputHTMLAttributes,
  ReactNode,
  useEffect,
  useRef,
} from 'react';
import qs from 'query-string';
import moment from 'moment-timezone';
import DeclarativeComponent from '@reach/component-component';
import Select from 'react-select';
import { Formik } from 'formik';
import { Link, RouteComponentProps } from 'react-router-dom';

import { prettifyShoottype } from './utils';
import { ensureString } from '../../utils/strings';
import { COLOR_PALLETTE } from '../../constants';
import { Fetcher } from '../../components/Fetcher';
import { gql, useMutation, useQuery } from '../../components/ApolloClient';
import { Pagination } from '../../components/Pagination';
import ContentWrapper from '../../components/ContentWrapper';
import { BookingStatusBadge } from '../../components/BookingStatusBadge';
import { checkIfCanAccess, formatCredits } from '../../utils';
import { LoadingModal } from '../../components/LoadingModal';
import { locationForModal, ModalRoute } from '../../components/ModalRoute';
import { Modal } from '../../components/Modal';
import { LoadingSpinnerCentered } from '../../components/LoadingSpinner';
import pencilEdit from '../../img/pencil-edit.svg';
import archiveIcon from '../../img/archive.svg';
import { AddressAutocompleteInput, MapsLibLoader } from '../../components';
import { MultiSelectFilter as LongDistanceMultiSelectFilter } from './components/MultiSelectFilter';
import { NotesModalBody, NotesModalForm } from '../../components/NotesModal';
import { SearchField } from '../partners/components';
import { OrderByLink } from '../../components/OrderByLink';
import { ClosestProvidersModal } from '../../components/ClosestProvidersModal';

const BOOKINGS_PER_PAGE = 10;
const LONG_DISTANCE_REQUEST_BOOKINGS_PER_PAGE = 50;
const STATUS = 'Request status';
const BOOKING_STATUS = 'Booking status';
const PARTNERS = 'partners';
const ASSIGNEE = 'assignee';

type LongDistanceRequestValues = {
  bookingId?: string;
  status?: string;
  shootType?: string;
  address?: string;
  duration?: number | string;
  partnerId: string;
  customerName?: string;
  phone?: string;
  email?: string;
  requestType?: string;
};

const TabCode = {
  BOOKINGS_LIST: 'BOOKINGS_LIST',
  BOOKINGS_LONG_DISTANCE: 'BOOKINGS_LONG_DISTANCE',
};

const BOOKINGS_PATH = '/bookings';

enum BookingLongDistanceTrackerStatusEnum {
  PENDING = 'PENDING',
  CLAIMED = 'CLAIMED',
  PG_FOUND = 'PG_FOUND',
  PG_NOT_FOUND = 'PG_NOT_FOUND',
  BOOKED = 'BOOKED',
  COMPLETED = 'COMPLETED',

  ECX_UNRESPONSIVE = 'ECX_UNRESPONSIVE',
  EREP_CHANGEDMIND = 'EREP_CHANGEDMIND',
  EREP_NOTRAVELFEES = 'EREP_NOTRAVELFEES',
  DUPLICATE = 'DUPLICATE',
}

const STATUS_COLOR = {
  [BookingLongDistanceTrackerStatusEnum.PENDING]: COLOR_PALLETTE.WARNING,
  [BookingLongDistanceTrackerStatusEnum.CLAIMED]: COLOR_PALLETTE.PRIMARY,
  [BookingLongDistanceTrackerStatusEnum.PG_FOUND]: COLOR_PALLETTE.TEAL,
  [BookingLongDistanceTrackerStatusEnum.PG_NOT_FOUND]: COLOR_PALLETTE.DANGER,
  [BookingLongDistanceTrackerStatusEnum.BOOKED]: COLOR_PALLETTE.GRAY_5,
  [BookingLongDistanceTrackerStatusEnum.COMPLETED]: COLOR_PALLETTE.SUCCESS,

  [BookingLongDistanceTrackerStatusEnum.ECX_UNRESPONSIVE]: '#4B0096',
  [BookingLongDistanceTrackerStatusEnum.EREP_CHANGEDMIND]: '#4B0096',
  [BookingLongDistanceTrackerStatusEnum.EREP_NOTRAVELFEES]: '#4B0096',
  [BookingLongDistanceTrackerStatusEnum.DUPLICATE]: '#4B0096',
};

const bookingLongDistanceStatusListValueLabel = Object.values(
  BookingLongDistanceTrackerStatusEnum
).map((status) => ({
  value: status,
  label: status,
}));

const generateLabel = (minutes) => {
  if (minutes < 60) return `${minutes} minutes`;
  const hours = minutes / 60;
  return `${hours} hours`;
};

const bookingLongDistanceDurationList = Array.from(
  { length: 840 / 30 }, // the limit is 14 hours = 840 minutes
  (_, idx) => {
    const value = 30 * (idx + 1);
    return { value, label: generateLabel(value) };
  }
);

const badgeBgColorStatus = ({ status }: { status: string }) => {
  const color = STATUS_COLOR[status];
  return { background: color || COLOR_PALLETTE.GRAY_5 };
};

const colorConfig = [
  { threshold: 7, color: COLOR_PALLETTE.DANGER },
  { threshold: 14, color: COLOR_PALLETTE.WARNING },
  { threshold: 28, color: COLOR_PALLETTE.SUCCESS },
];

function getDaysLeftUntilDeadline({
  startDate,
  deadlineDays = 28,
}: {
  startDate: string;
  deadlineDays?: number;
}) {
  const deadline = moment(startDate).add(deadlineDays, 'days');
  const daysLeft: number = deadline.diff(moment(), 'days');

  const matchedColorConfig = colorConfig.find(
    (config) => daysLeft <= config.threshold
  );

  const color = matchedColorConfig
    ? matchedColorConfig.color
    : COLOR_PALLETTE.PRIMARY;

  const text =
    daysLeft <= 0
      ? '0 days left'
      : `${daysLeft} day${daysLeft === 1 ? '' : 's'} left`;

  return (
    <span className="d-block" style={{ color }}>
      {text}
    </span>
  );
}

type Booking = {
  id: string;
  status?: string;
  startAt?: string;
  address?: string;

  customer?: {
    firstName?: string;
    lastName?: string;
  };

  shootType?: {
    displayName?: string;
  };

  provider?: {
    firstName?: string;
    lastName?: string;
  };

  partner?: {
    name?: string;
  };

  partnerEndCustomer?: {
    firstName?: string;
    lastName?: string;
  };

  partnerRepresentative?: {
    firstName?: string;
    lastName?: string;
  };
};

type IGetBookingsQuery = {
  bookingsAsAdmin?: {
    total?: number;
    edges: Booking[];
  };
};

const CopyIdField = ({ id = '' }: { id?: string }) => {
  const bookingIdInputRef = createRef<HTMLInputElement>();

  return (
    <div className="input-group">
      <input
        type="text"
        className="form-control"
        readOnly={true}
        ref={bookingIdInputRef}
        value={id}
        style={{ maxWidth: 320, textOverflow: 'ellipsis' }}
      />
      <div className="input-group-append">
        <button
          className="btn btn-primary"
          onClick={() => {
            if (bookingIdInputRef.current != null) {
              bookingIdInputRef.current.select();
              document.execCommand('copy');
            }
          }}
        >
          <i className="fa fa-copy" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
};

const AutofocusInput = (props: InputHTMLAttributes<HTMLInputElement>) => {
  const inputEl = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputEl == null || inputEl.current == null) return;

    inputEl.current.focus();

    if (props.value) {
      inputEl.current.value = props.value.toString();
    }
  }, [props.value]);

  return <input ref={inputEl} {...props} />;
};

const MultiSelectFilter = ({ filter, onFilter, location }) => {
  const options = filter.options.map((o) => ({ label: o.name, value: o.code }));
  const filterString = qs.parse(location.search)[filter.code] as string;
  const selectedValues = filterString ? filterString.split(',') : [];
  const selectedOptions =
    selectedValues && options.filter((o) => selectedValues.includes(o.value));
  return (
    <div className="mr-2" style={{ minWidth: 150 }}>
      <div className="mb-2">{filter.name}:</div>

      <Select
        isMulti
        value={selectedOptions}
        options={options}
        onChange={(selectedOptions) => {
          const selected = (selectedOptions ?? []).map((s) => s.value);

          onFilter({
            filterCode: filter.code,
            filterValue: selected.length > 0 ? selected.join(',') : undefined,
          });
        }}
      />
    </div>
  );
};

const Filters = ({ session, location, onFilter }) => (
  <Fetcher urlToFetch={`/api/v2/admin/bookings/meta`} session={session}>
    {({ loading: metaPending, response: { filters = [] } = {} }) => (
      <>
        {filters.map((filter: Record<string, any>) => (
          <MultiSelectFilter
            key={filter.code}
            onFilter={onFilter}
            filter={filter}
            location={location}
          />
        ))}
        {metaPending && <LoadingModal text="meta data" />}
      </>
    )}
  </Fetcher>
);

const TdLink = ({
  to,
  children,
  title,
  className,
  ...props
}: {
  to: string;
  children: ReactNode;
  title?: string;
  className?: string;
  [K: string]: unknown;
}) => (
  <td title={title} style={{ verticalAlign: 'middle' }}>
    <Link
      {...props}
      to={to}
      className={['text-secondary d-inline-block text-truncate', className]
        .filter((r) => !!r)
        .join(' ')}
    >
      {children}
    </Link>
  </td>
);

const GET_BOOKINGS_QUERY = gql`
  query getBookings(
    $first: Int
    $offset: Int
    $statusFilter: [String!]
    $tierFilter: [String!]
    $query: String
    $fastQuery: String
  ) {
    bookingsAsAdmin(
      first: $first
      offset: $offset
      statusFilter: $statusFilter
      tierFilter: $tierFilter
      query: $query
      fastQuery: $fastQuery
    ) {
      total
      edges {
        id
        status
        startAt
        address

        customer {
          firstName
          lastName
        }

        shootType {
          displayName
        }

        provider {
          firstName
          lastName
        }

        partner {
          name
        }

        partnerEndCustomer {
          firstName
          lastName
        }

        partnerRepresentative {
          firstName
          lastName
        }
      }
    }
  }
`;

const NotesModal = ({
  id,
  onDismiss,
}: {
  id: string;
  onDismiss: () => void;
}) => {
  const longDistanceTrackerComments = useQuery<
    {
      longDistanceBookingRequestById?: {
        id: string;
        comments?: {
          edges?: {
            id: string;
            text: string;
            createdAt?: string;
            createdBy: {
              id: string;
              fullName: string;
            };
          }[];
        };
      };
    },
    {
      id: string;
    }
  >(
    gql`
      query LongDistanceTrackerCommentsQuery($id: ID!) {
        longDistanceBookingRequestById(id: $id) {
          id
          comments {
            edges {
              id
              text
              createdAt
              createdBy {
                id
                fullName
              }
            }
          }
        }
      }
    `,
    {
      variables: {
        id: id,
      },
    }
  );

  const notes =
    longDistanceTrackerComments.data?.longDistanceBookingRequestById?.comments
      ?.edges;

  const [
    createCommentAsAdmin,
    { loading: createCommentAsAdminLoading },
  ] = useMutation(
    gql`
      mutation LongDistanceTrackerCommentLogCreateAsAdmin(
        $object: String!
        $objectId: ID!
        $text: String!
      ) {
        commentLogCreateAsAdmin(
          input: { object: $object, objectId: $objectId, text: $text }
        ) {
          commentLog {
            id
          }
        }
      }
    `
  );

  const onCommentSubmit = ({ comment: text }: { comment: string }) => {
    const object = 'booking_long_distance_tracker';
    createCommentAsAdmin({
      variables: { object, objectId: id, text },
    })
      .then(() => longDistanceTrackerComments.refetch())
      .catch((error) =>
        alert(
          `Unable to create a note. Please check the user's permissions and try again. If the problem persists, contact support.". ${error}`
        )
      );
  };

  return (
    <Modal onDismiss={onDismiss}>
      {longDistanceTrackerComments.loading === true ? (
        <LoadingSpinnerCentered />
      ) : (
        <div className="card my-4" style={{ minWidth: 712, maxWidth: 648 }}>
          <div className="card-header">
            <h4 className="mb-0">Notes</h4>
          </div>
          <NotesModalBody notes={notes} />

          <NotesModalForm
            disabled={
              createCommentAsAdminLoading || longDistanceTrackerComments.loading
            }
            onSubmit={({ newNote }) => onCommentSubmit({ comment: newNote })}
            onClose={onDismiss}
          />
        </div>
      )}
    </Modal>
  );
};

const BookingsLongDistanceList = ({
  rootBasePath,
  location,
  session,
  history,
}: {
  rootBasePath: string;
  location: RouteComponentProps['location'];
  session: {
    token: string;
    uid: string;
  };
  history: RouteComponentProps['history'];
}) => {
  const parsedSearch = qs.parse(location.search);

  const selectedStatus = React.useMemo(
    () =>
      ensureString(qs.parse(location.search)[STATUS] ?? null)?.split(',') ?? [],
    [location]
  );

  const selectedBookingStatus = React.useMemo(
    () =>
      ensureString(qs.parse(location.search)[BOOKING_STATUS] ?? null)?.split(
        ','
      ) ?? [],
    [location]
  );

  const partnerIdList = React.useMemo(
    () =>
      ensureString(qs.parse(location.search)[PARTNERS] ?? null)?.split(',') ??
      [],
    [location]
  );

  const assigneeIdList = React.useMemo(
    () =>
      ensureString(qs.parse(location.search)[ASSIGNEE] ?? null)?.split(',') ??
      [],
    [location]
  );

  const searchTerm = ensureString(parsedSearch['q']);

  const partnersQuery = useQuery<{
    getPartnersAsAdmin: {
      edges: {
        uid: string;
        name: string;
      }[];
    };
  }>(
    gql`
      query GetPartnersAsAdminForLongDistanceRequestsFilter {
        getPartnersAsAdmin {
          edges {
            uid
            name
          }
        }
      }
    `
  );

  const partnersList = partnersQuery?.data?.getPartnersAsAdmin.edges;

  const partnerOptions = React.useMemo(() => {
    return (
      partnersList?.map((partner) => ({
        label: partner.name,
        value: partner.uid,
      })) ?? []
    );
  }, [partnersList]);

  const assigneesQuery = useQuery<{
    longDistanceBookingAssigneeList: {
      edges: {
        id: string;
        fullName: string;
      }[];
    };
  }>(
    gql`
      query GetLongDistanceBookingAssigneeListFilter {
        longDistanceBookingAssigneeList {
          edges {
            id
            fullName
          }
        }
      }
    `
  );

  const assigneeList =
    assigneesQuery?.data?.longDistanceBookingAssigneeList.edges;

  const assigneeOptions = React.useMemo(() => {
    return (
      assigneeList?.map((assignee) => ({
        label: assignee.fullName,
        value: assignee.id,
      })) ?? []
    );
  }, [assigneeList]);

  const orderingValue = ensureString(parsedSearch['ordering']);

  const orderMapping = {
    'shoot-date': 'SHOOT_DATE',
    '-shoot_date': 'SHOOT_DATE_DESC',
    booking_created_at: 'BOOKING_CREATED_AT',
    '-booking_created_at': 'BOOKING_CREATED_AT_DESC',
  };

  const sortBy =
    orderingValue != null ? orderMapping[orderingValue] : undefined;

  const longDistanceBookingRequestListQuery = useQuery<
    {
      longDistanceBookingRequestList?: {
        total?: number;
        edges: {
          id: string;
          createdAt: string;
          assignee?: {
            id: string;
            fullName: string;
          };
          requestType?: string;
          status?: BookingLongDistanceTrackerStatusEnum;
          endCustomerName?: string;
          endCustomerPhone?: string;
          endCustomerEmail?: string;
          partner: {
            uid: string;
            name: string;
          };
          bookingAddress?: string;
          shootType?: {
            name: string;
            category: string;
          };
          duration?: number;
          booking?: {
            id: string;
            status: string;
            startAt: string;
            createdAt: string;
            provider?: {
              id: string;
              firstName?: string;
              lastName?: string;
            };
          };
          travelFee?: number;
          comments?: {
            edges?: {
              id: string;
              text: string;
            }[];
          };
        }[];
      };
    },
    {
      first: number;
      offset?: number;
      statusFilter?: string[];
      partnerFilter?: string[];
      assigneeFilter?: string[];
      bookingStatusFilter?: string[];
      search?: string;
      sort?: string;
    }
  >(
    gql`
      query LongDistanceBookingRequestList(
        $first: Int
        $statusFilter: [String]
        $partnerFilter: [String]
        $assigneeFilter: [String]
        $bookingStatusFilter: [String]
        $search: String
        $sort: BookingLongDistanceTrackerSortKeys
        $offset: Int
      ) {
        longDistanceBookingRequestList(
          first: $first
          statusFilter: $statusFilter
          partnerFilter: $partnerFilter
          assigneeFilter: $assigneeFilter
          bookingStatusFilter: $bookingStatusFilter
          search: $search
          sort: $sort
          offset: $offset
        ) {
          total
          edges {
            id
            createdAt
            assignee {
              id
              fullName
            }
            requestType
            status
            endCustomerName
            endCustomerPhone
            endCustomerEmail
            partner {
              uid
              name
            }
            bookingAddress
            shootType {
              name
              category
            }
            travelFee
            duration
            booking {
              id
              status
              startAt
              createdAt
              provider {
                id
                firstName
                lastName
              }
            }
            comments {
              edges {
                id
                text
              }
            }
          }
        }
      }
    `,
    {
      variables: {
        first: LONG_DISTANCE_REQUEST_BOOKINGS_PER_PAGE,
        statusFilter: selectedStatus,
        partnerFilter: partnerIdList,
        assigneeFilter: assigneeIdList,
        bookingStatusFilter: selectedBookingStatus,
        search: searchTerm,
        offset: parseInt(
          (qs.parse(location.search).offset as string) || '0',
          10
        ),
        ...(sortBy != null ? { sort: sortBy } : {}),
      },
    }
  );

  const [
    longDistanceBookingRequestClaim,
    longDistanceBookingRequestClaimMutation,
  ] = useMutation(gql`
    mutation LongDistanceBookingRequestClaimMutation(
      $requestId: ID!
      $assigneeId: ID!
    ) {
      longDistanceBookingRequestClaim(
        input: { requestId: $requestId, assigneeId: $assigneeId }
      ) {
        success
      }
    }
  `);

  const [
    longDistanceBookingRequestArchive,
    longDistanceBookingRequestArchiveMutation,
  ] = useMutation(gql`
    mutation LongDistanceBookingRequestArchiveMutation($requestId: ID!) {
      longDistanceBookingRequestArchive(input: { requestId: $requestId }) {
        success
        requestId
      }
    }
  `);

  const bookingStatusOptions = [
    { label: 'Created', value: 'created' },
    { label: 'Paid', value: 'paid' },
    { label: 'Paid pending schedule', value: 'paid_pending_schedule' },
    { label: 'Paid on hold', value: 'paid_on_hold' },
    { label: 'Completed', value: 'completed' },
    { label: 'Cancelled', value: 'cancelled' },
  ];

  const longDistanceBookingRequest =
    longDistanceBookingRequestListQuery?.data?.longDistanceBookingRequestList
      ?.edges ?? [];

  const longDistanceBookingRequestTotal =
    longDistanceBookingRequestListQuery?.data?.longDistanceBookingRequestList
      ?.total;

  return longDistanceBookingRequestListQuery.loading !== true &&
    longDistanceBookingRequest != null ? (
    <div>
      <div
        style={{
          marginTop: 24,
          marginBottom: 24,
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            display: 'flex',
          }}
        >
          <LongDistanceMultiSelectFilter
            filterName={STATUS}
            options={bookingLongDistanceStatusListValueLabel}
            location={location}
            history={history}
          />

          <LongDistanceMultiSelectFilter
            filterName={BOOKING_STATUS}
            options={bookingStatusOptions}
            location={location}
            history={history}
          />

          {partnerOptions != null && partnerOptions.length > 0 ? (
            <LongDistanceMultiSelectFilter
              filterName={PARTNERS}
              options={partnerOptions}
              location={location}
              history={history}
            />
          ) : null}

          {assigneeOptions != null && assigneeOptions.length > 0 ? (
            <LongDistanceMultiSelectFilter
              filterName={ASSIGNEE}
              options={assigneeOptions}
              location={location}
              history={history}
            />
          ) : null}
        </div>

        <SearchField
          onSearch={({ q }) =>
            history.push({
              ...location,
              search: qs.stringify({
                ...qs.parse(location.search),
                q: q || undefined,
              }),
            })
          }
          defaultValue={qs.parse(location.search).q || ''}
        />
      </div>

      <ModalRoute modalName="LONG_DISTANCE_REQUEST">
        {({ history, location, modalParams }) => {
          return (
            <LongDistanceRequestModal
              requestId={modalParams.requestId}
              onDismiss={() =>
                history.push(
                  locationForModal({
                    location,
                    modal: undefined,
                  })
                )
              }
              onContinue={() =>
                longDistanceBookingRequestListQuery.refetch().then(() => {
                  history.replace(
                    locationForModal({
                      location,
                      modal: undefined,
                    })
                  );
                })
              }
            />
          );
        }}
      </ModalRoute>

      <ModalRoute modalName="LONG_DISTANCE_TRACKER_NOTES">
        {({ history, location, modalParams }) => {
          return (
            <NotesModal
              id={modalParams.longDistanceRequestId}
              onDismiss={() =>
                history.push(
                  locationForModal({
                    location,
                    modal: undefined,
                  })
                )
              }
            />
          );
        }}
      </ModalRoute>

      <ModalRoute modalName="LONG_DISTANCE_TRACKER_CLOSEST_PROVIDERS">
        {({ history, location, modalParams }) => {
          return (
            <ClosestProvidersModal
              id={modalParams.longDistanceRequestId}
              location={location}
              history={history}
              modalParams={modalParams}
              onDismiss={() =>
                history.push(
                  locationForModal({
                    location,
                    modal: undefined,
                  })
                )
              }
            />
          );
        }}
      </ModalRoute>

      <div
        className="table-responsive"
        style={{
          position: 'relative',
        }}
      >
        <table className="table table-hover">
          <thead>
            <tr>
              <th className="text-muted text-truncate" />
              <th className="text-muted text-truncate">Assignee</th>
              <th className="text-muted text-truncate">Request Type</th>
              <th className="text-muted text-truncate">Request Status</th>
              <th className="text-muted text-truncate">End Customer</th>
              <th className="text-muted text-truncate">End Customer email</th>
              <th className="text-muted text-truncate">End Customer phone</th>
              <th className="text-muted text-truncate">Enterprise</th>
              <th className="text-muted text-truncate">Shoot address</th>
              <th className="text-muted text-truncate">Shoot Type</th>
              <th className="text-muted text-truncate">
                <OrderByLink attrName="shoot_date" location={location}>
                  Shoot Date
                </OrderByLink>
              </th>

              <th className="text-muted text-truncate">Travel budget</th>
              <th className="text-muted text-truncate">Duration</th>
              <th className="text-muted text-truncate">
                <OrderByLink attrName="booking_created_at" location={location}>
                  Deadline
                </OrderByLink>
              </th>
              <th className="text-muted text-truncate">Notes</th>
              <th className="text-muted text-truncate">Booking status</th>
              <th className="text-muted text-truncate">Booking UID</th>
              <th className="text-muted text-truncate">Photographer</th>
              <th className="text-muted text-truncate">
                Closest Photographers
              </th>
              <th className="text-muted text-truncate" />
              <th className="text-muted text-truncate" />
            </tr>
          </thead>

          <tbody>
            {longDistanceBookingRequest.map((request) => (
              <tr key={request?.id}>
                <td>
                  <button
                    type="button"
                    style={{ width: 86, fontSize: 14 }}
                    className={
                      request?.assignee != null
                        ? 'btn btn-outline-dark'
                        : 'btn btn-dark'
                    }
                    disabled={
                      longDistanceBookingRequestClaimMutation.loading ||
                      request?.assignee?.id === session.uid
                    }
                    onClick={() =>
                      longDistanceBookingRequestClaim({
                        variables: {
                          requestId: request?.id,
                          assigneeId: session.uid,
                        },
                      })
                        .then(() =>
                          longDistanceBookingRequestListQuery.refetch()
                        )
                        .catch((error) =>
                          alert(
                            `Unable to assign user to the request. Please check the user's permissions and try again. If the problem persists, contact support.". ${error}`
                          )
                        )
                    }
                  >
                    {request?.assignee != null ? 'Reassign' : 'Claim'}
                  </button>
                </td>
                <td>{request?.assignee?.fullName ?? '-'}</td>
                <td>{request?.requestType}</td>
                <td>
                  <span
                    className="badge badge-primary text-uppercase"
                    style={badgeBgColorStatus({
                      status: request?.status ?? '',
                    })}
                  >
                    {request?.status}
                  </span>
                </td>
                <td>{request?.endCustomerName}</td>
                <td>{request?.endCustomerEmail}</td>
                <td>{request?.endCustomerPhone}</td>
                <TdLink
                  to={`/enterprises/${request.partner.uid}`}
                  className="align-middle"
                  style={{ textDecoration: 'underline' }}
                >
                  {request.partner.name}
                </TdLink>
                <td style={{ minWidth: 200 }}>{request?.bookingAddress}</td>
                <td>{request?.shootType?.name}</td>

                <td>
                  {request?.booking != null
                    ? moment(request?.booking?.startAt).format('D, MMMM YYYY')
                    : '-'}
                </td>

                <td>
                  {request?.travelFee != null
                    ? formatCredits(request?.travelFee)
                    : '-'}
                </td>

                <td>
                  {request?.duration == null
                    ? '-'
                    : request?.duration < 60
                    ? `${request.duration} minute${
                        request.duration === 1 ? '' : 's'
                      }`
                    : request?.duration === 60
                    ? '1 hour'
                    : `${Math.floor(request.duration / 60)}${
                        request.duration % 60 !== 0
                          ? `.${(request.duration % 60) / 6}` // This converts the remaining minutes, 30 minutes => .5
                          : ''
                      } hours`}
                </td>

                <td>
                  {request?.createdAt != null
                    ? getDaysLeftUntilDeadline({
                        startDate: request?.createdAt,
                      })
                    : '-'}
                </td>

                <td
                  style={{
                    minWidth: 300,
                    maxWidth: 300,
                    display: 'flex',
                    flexDirection: 'column',
                    textDecoration: 'none',
                  }}
                >
                  {request?.comments?.edges != null &&
                  request?.comments?.edges?.length > 0 ? (
                    <span className="truncate-text">
                      {request?.comments?.edges[0].text}
                    </span>
                  ) : null}
                  <Link
                    style={{
                      color: '#1F6FFF',
                      textDecoration: 'underline',
                      textAlign: 'start',
                      padding: 0,
                    }}
                    className={`btn`}
                    to={locationForModal({
                      location,
                      modal: {
                        modalName: 'LONG_DISTANCE_TRACKER_NOTES',
                        longDistanceRequestId: request.id,
                      },
                    })}
                  >
                    Go to notes
                  </Link>
                </td>
                <td>
                  <BookingStatusBadge status={request?.booking?.status} />
                </td>
                <td style={{ minWidth: 300 }}>
                  <div style={{ display: 'flex', gap: 1 }}>
                    <CopyIdField id={request?.booking?.id} />
                    <div className="btn btn-dark">
                      <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        to={`bookings/${request?.booking?.id}`}
                        style={{
                          pointerEvents:
                            request?.booking?.id != null ? 'auto' : 'none',
                        }}
                      >
                        <i className="fa fa-external-link" aria-hidden="true" />
                      </Link>
                    </div>
                  </div>
                </td>
                <td style={{ minWidth: 200 }}>
                  {request.booking?.provider?.id != null ? (
                    <Link to={`/providers/${request.booking?.provider?.id}`}>
                      {`${request?.booking?.provider?.firstName} ${request?.booking?.provider?.lastName}`}
                    </Link>
                  ) : null}
                </td>

                <td
                  style={{
                    minWidth: 300,
                    maxWidth: 300,
                    display: 'flex',
                    flexDirection: 'column',
                    textDecoration: 'none',
                  }}
                >
                  <Link
                    style={{
                      color: '#1F6FFF',
                      textDecoration: 'underline',
                      textAlign: 'start',
                      padding: 0,
                    }}
                    className={`btn`}
                    to={locationForModal({
                      location,
                      modal: {
                        modalName: 'LONG_DISTANCE_TRACKER_CLOSEST_PROVIDERS',
                        longDistanceRequestId: request.id,
                      },
                    })}
                  >
                    View providers
                  </Link>
                </td>

                <td>
                  <Link
                    className={`btn`}
                    to={locationForModal({
                      location,
                      modal: {
                        modalName: 'LONG_DISTANCE_REQUEST',
                        requestId: request.id,
                      },
                    })}
                  >
                    <img src={pencilEdit} alt="edit" />
                  </Link>
                </td>
                <td>
                  <button
                    className="btn btn-link"
                    type="button"
                    disabled={longDistanceBookingRequestArchiveMutation.loading}
                    onClick={() => {
                      if (
                        window.confirm(
                          `Are you sure you want to archive this request?`
                        )
                      ) {
                        return longDistanceBookingRequestArchive({
                          variables: {
                            requestId: request?.id,
                          },
                        })
                          .then(() =>
                            longDistanceBookingRequestListQuery.refetch()
                          )
                          .catch((error) =>
                            alert(
                              `Unable to archive request. Please check the user's permissions and try again. If the problem persists, contact support.". ${error}`
                            )
                          );
                      }
                    }}
                  >
                    <img src={archiveIcon} alt="archive" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="d-flex justify-content-end ">
        <Pagination
          limit={LONG_DISTANCE_REQUEST_BOOKINGS_PER_PAGE}
          offset={parseInt(
            (qs.parse(location.search).offset as string) || '0',
            10
          )}
          count={longDistanceBookingRequestTotal}
          linkForOffset={(offset, { disabled, ...props }) => (
            <Link
              className="page-link"
              to={{
                ...location,
                search: qs.stringify({
                  ...qs.parse(location.search),
                  offset: offset || undefined,
                }),
              }}
              {...props}
            />
          )}
        />
      </div>

      <style jsx>{`
        .truncate-text {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          line-height: 25px;
          max-height: 50px;
          width: 300px;
        }
      `}</style>
    </div>
  ) : (
    <LoadingSpinnerCentered />
  );
};

const BookingsList = ({
  location,
  history,
  session,
  rootBasePath,
  totalBookings,
}: {
  location: RouteComponentProps['location'];
  history: RouteComponentProps['history'];
  session: {
    token: string;
    uid: string;
  };
  rootBasePath: string;
  totalBookings?: number;
}) => {
  const { data, loading } = useQuery<IGetBookingsQuery>(GET_BOOKINGS_QUERY, {
    variables: {
      first: BOOKINGS_PER_PAGE,
      offset: parseInt((qs.parse(location.search).offset as string) || '0', 10),
      statusFilter:
        (qs.parse(location.search).filter_status as string)?.split(',') ?? [],
      tierFilter:
        (qs.parse(location.search).filter_tier as string)?.split(',') ?? [],
      query: qs.parse(location.search).q,
      fastQuery: qs.parse(location.search).fq,
    },
  });

  const bookings = data?.bookingsAsAdmin?.edges;

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3 p-2">
        <div className="d-flex justify-content-between align-items-center">
          <Link to={`${BOOKINGS_PATH}/new`} className="btn btn-primary">
            New Booking
          </Link>
        </div>

        <div className="d-flex flex-row align-items-end">
          <Filters
            session={session}
            location={location}
            onFilter={({ filterCode, filterValue }) => {
              history.push({
                ...location,
                search: qs.stringify({
                  ...qs.parse(location.search),
                  [filterCode]: filterValue,
                }),
              });
            }}
          />

          <DeclarativeComponent
            search={qs.parse(location.search)}
            initialState={{ q: null }}
            didMount={({
              props: {
                search: { q },
              },
              setState,
            }) => setState({ q })}
            didUpdate={({
              props: {
                search: { q },
              },
              props,
              prevProps,
              setState,
            }) => props.search.q !== prevProps.search.q && setState({ q })}
          >
            {({ state, setState }) => (
              <form
                className="d-flex justify-content-end"
                onSubmit={(ev) => {
                  ev.preventDefault();
                  history.push({
                    ...location,
                    search: qs.stringify({
                      ...qs.parse(location.search),
                      q: state.q || undefined,
                      offset: undefined,
                    }),
                  });
                }}
              >
                <input
                  type="text"
                  className="form-control"
                  style={{ maxWidth: 300 }}
                  placeholder="Search"
                  value={state.q || ''}
                  onChange={({ target: { value } }) => setState({ q: value })}
                  disabled={qs.parse(location.search).fq != null}
                />
              </form>
            )}
          </DeclarativeComponent>

          <DeclarativeComponent
            search={qs.parse(location.search)}
            initialState={{ fq: null }}
            didMount={({
              props: {
                search: { fq },
              },
              setState,
            }) => setState({ fq })}
            didUpdate={({
              props: {
                search: { fq },
              },
              props,
              prevProps,
              setState,
            }) => {
              props.search.fq !== prevProps.search.fq && setState({ fq });
            }}
          >
            {({ state, setState }) => (
              <form
                className="d-flex justify-content-end ml-2"
                onSubmit={(ev) => {
                  ev.preventDefault();
                  history.push({
                    ...location,
                    search: qs.stringify({
                      ...qs.parse(location.search),
                      fq: state.fq || undefined,
                      offset: undefined,
                    }),
                  });
                }}
              >
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      <i className="fa fa-bolt" aria-hidden="true"></i>
                    </span>
                  </div>

                  <AutofocusInput
                    type="text"
                    className="form-control"
                    style={{ width: 300 }}
                    placeholder="Email or UID Search"
                    value={state.fq || ''}
                    onChange={({ target: { value } }) =>
                      setState({ fq: value })
                    }
                  />
                </div>
              </form>
            )}
          </DeclarativeComponent>
        </div>
      </div>

      <div
        className="table-responsive"
        style={{
          position: 'relative',
          // overflowX: 'auto'
        }}
      >
        <table className="table table-hover">
          <thead>
            <tr>
              <th className="text-muted text-truncate">Status</th>
              <th className="text-muted text-truncate">{'Date & time'}</th>
              <th className="text-muted text-truncate">Customer name</th>
              <th className="text-muted text-truncate">Partner</th>
              <th className="text-muted text-truncate">Shoot address</th>
              <th className="text-muted text-truncate">Shoot type</th>
              <th className="text-muted text-truncate">Photographer</th>
              <th className="text-muted text-truncate">Rep</th>
            </tr>
          </thead>

          <tbody>
            {bookings != null &&
              bookings.map((booking) => (
                <tr key={booking.id}>
                  <TdLink
                    title={booking.id}
                    to={`${rootBasePath}/${booking.id}`}
                  >
                    <BookingStatusBadge status={booking.status} />
                  </TdLink>
                  <TdLink
                    to={`${rootBasePath}/${booking.id}`}
                    title={booking.startAt ?? ''}
                  >
                    {booking.startAt &&
                      moment(booking.startAt).format('D, MMMM YYYY Z')}
                  </TdLink>
                  <TdLink to={`${rootBasePath}/${booking.id}`}>
                    {booking.customer != null
                      ? `${booking.customer?.firstName} ${
                          booking.customer?.lastName ?? ''
                        }`
                      : booking.partnerEndCustomer != null
                      ? `${booking.partnerEndCustomer?.firstName} ${
                          booking.partnerEndCustomer?.lastName ?? ''
                        }`
                      : ''}
                  </TdLink>
                  <TdLink to={`${rootBasePath}/${booking.id}`}>
                    {booking.partner != null ? booking.partner?.name : ''}
                  </TdLink>
                  <TdLink
                    to={`${rootBasePath}/${booking.id}`}
                    style={{ maxWidth: 300 }}
                  >
                    {booking.address}
                  </TdLink>
                  <TdLink to={`${rootBasePath}/${booking.id}`}>
                    {prettifyShoottype(booking.shootType?.displayName)}
                  </TdLink>
                  <TdLink to={`${rootBasePath}/${booking.id}`}>
                    {booking.provider != null
                      ? `${booking.provider?.firstName} ${booking.provider?.lastName}`
                      : ''}
                  </TdLink>
                  <TdLink to={`${rootBasePath}/${booking.id}`}>
                    {booking.partnerRepresentative != null
                      ? `${booking.partnerRepresentative?.firstName} ${booking.partnerRepresentative?.lastName}`
                      : ''}
                  </TdLink>
                </tr>
              ))}
          </tbody>
        </table>

        {loading && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(255,255,255,0.8)',
            }}
          >
            <p>Loading...</p>
          </div>
        )}
      </div>

      <div className="d-flex justify-content-end ">
        <Pagination
          limit={BOOKINGS_PER_PAGE}
          offset={parseInt(
            (qs.parse(location.search).offset as string) || '0',
            10
          )}
          count={data?.bookingsAsAdmin?.total}
          linkForOffset={(offset, { disabled, ...props }) => (
            <Link
              className="page-link"
              to={{
                ...location,
                search: qs.stringify({
                  ...qs.parse(location.search),
                  offset: offset || undefined,
                }),
              }}
              {...props}
            />
          )}
        />
      </div>
    </>
  );
};

function LongDistanceRequestModal({
  onDismiss,
  requestId,
  onContinue,
}: {
  onDismiss: () => void;
  requestId?: string;
  onContinue: () => void;
}) {
  const shootTypesOptions = useQuery<{
    getPublicShootTypes: {
      displayName: string;
      category: string;
    }[];
  }>(gql`
    query GetPublicShootTypesQueryInBookingLongDistanceRequests {
      getPublicShootTypes {
        category
        displayName
      }
    }
  `);

  const partnersAsAdmin = useQuery<{
    getPartnersAsAdmin: {
      edges: {
        uid: string;
        name: string;
      }[];
    };
  }>(
    gql`
      query GetPartnersAsAdminForLongDistanceRequests {
        getPartnersAsAdmin {
          edges {
            uid
            name
          }
        }
      }
    `
  );

  const longDistanceRequestResultQuery = useQuery<{
    longDistanceBookingRequestById: {
      id: string;
      assignee?: {
        id: string;
        fullName: string;
      };
      requestType?: string;
      status?: string;
      endCustomerName?: string;
      endCustomerPhone?: string;
      endCustomerEmail?: string;
      partner: {
        uid: string;
        name: string;
      };
      bookingAddress?: string;
      shootType?: {
        name: string;
      };
      duration?: number;
      booking?: {
        id: string;
      };
    };
  }>(
    gql`
      query LongDistanceBookingRequestByIdQuery($id: ID!) {
        longDistanceBookingRequestById(id: $id) {
          id
          assignee {
            id
            fullName
          }
          requestType
          status
          endCustomerName
          endCustomerPhone
          endCustomerEmail
          partner {
            uid
            name
          }
          bookingAddress
          shootType {
            name
            category
          }
          duration
          booking {
            id
          }
        }
      }
    `,
    {
      variables: {
        id: requestId,
      },
      skip: requestId == null,
    }
  );

  const [
    longDistanceBookingRequestUpdate,
    longDistanceBookingRequestUpdateMutation,
  ] = useMutation(gql`
    mutation LongDistanceBookingRequestUpdateMutation(
      $bookingAddress: String
      $bookingId: ID
      $duration: Int
      $endCustomerEmail: String
      $endCustomerName: String
      $endCustomerPhone: String
      $partnerId: ID!
      $requestId: ID!
      $shootType: String
      $status: BookingLongDistanceTrackerStatusEnum
    ) {
      longDistanceBookingRequestUpdate(
        input: {
          bookingAddress: $bookingAddress
          bookingId: $bookingId
          duration: $duration
          endCustomerEmail: $endCustomerEmail
          endCustomerName: $endCustomerName
          endCustomerPhone: $endCustomerPhone
          partnerId: $partnerId
          requestId: $requestId
          shootType: $shootType
          status: $status
        }
      ) {
        success
      }
    }
  `);

  const [
    longDistanceBookingRequestCreate,
    longDistanceBookingRequestCreateMutation,
  ] = useMutation(gql`
    mutation LongDistanceBookingRequestCreateMutation(
      $bookingAddress: String
      $bookingId: ID
      $duration: Int
      $endCustomerEmail: String
      $endCustomerName: String
      $endCustomerPhone: String
      $partnerId: ID!
      $shootType: String
      $status: BookingLongDistanceTrackerStatusEnum
    ) {
      longDistanceBookingRequestCreate(
        input: {
          bookingAddress: $bookingAddress
          bookingId: $bookingId
          duration: $duration
          endCustomerEmail: $endCustomerEmail
          endCustomerName: $endCustomerName
          endCustomerPhone: $endCustomerPhone
          partnerId: $partnerId
          shootType: $shootType
          status: $status
        }
      ) {
        success
      }
    }
  `);

  const longDistanceRequest =
    longDistanceRequestResultQuery.data?.longDistanceBookingRequestById;

  const partnersList = partnersAsAdmin?.data?.getPartnersAsAdmin.edges;

  const partnerOptions = React.useMemo(() => {
    return (
      partnersList?.map((partner) => ({
        label: partner.name,
        value: partner.uid,
      })) ?? []
    );
  }, [partnersList]);

  const initialValues = React.useMemo(
    () => ({
      bookingId: longDistanceRequest?.booking?.id ?? '',
      status: longDistanceRequest?.status ?? '',
      shootType: longDistanceRequest?.shootType?.name ?? '',
      address: longDistanceRequest?.bookingAddress ?? '',
      duration: longDistanceRequest?.duration ?? '',
      partnerId: longDistanceRequest?.partner?.uid ?? '',
      customerName: longDistanceRequest?.endCustomerName ?? '',
      phone: longDistanceRequest?.endCustomerPhone ?? '',
      email: longDistanceRequest?.endCustomerEmail ?? '',
    }),
    [
      longDistanceRequest?.booking?.id,
      longDistanceRequest?.bookingAddress,
      longDistanceRequest?.duration,
      longDistanceRequest?.endCustomerEmail,
      longDistanceRequest?.endCustomerName,
      longDistanceRequest?.endCustomerPhone,
      longDistanceRequest?.partner?.uid,
      longDistanceRequest?.shootType?.name,
      longDistanceRequest?.status,
    ]
  );

  const handleSubmit = async ({
    values,
  }: {
    values: LongDistanceRequestValues;
  }) => {
    const {
      status,
      customerName: endCustomerName,
      phone: endCustomerPhone,
      email: endCustomerEmail,
      partnerId,
      address: bookingAddress,
      shootType,
      duration: rawDuration,
      bookingId,
    } = values;

    const duration = Number(rawDuration);
    const variables = {
      status,
      endCustomerName,
      endCustomerPhone,
      endCustomerEmail,
      partnerId,
      bookingAddress,
      shootType,
      duration,
      bookingId,
    };

    try {
      if (requestId != null) {
        const { data } = await longDistanceBookingRequestUpdate({
          variables: { ...variables, requestId },
        });

        if (data.longDistanceBookingRequestUpdate.success === true) {
          onContinue();
          return;
        }
        alert(
          `Submission failed: Server error occurred. Please double-check the form values and try again later. longDistanceBookingRequestUpdate response: ${data.longDistanceBookingRequestUpdate.success}`
        );
      } else {
        const { data } = await longDistanceBookingRequestCreate({ variables });
        if (data.longDistanceBookingRequestCreate.success === true) {
          onContinue();
          return;
        }
        alert(
          `Submission failed: Server error occurred. Please double-check the form values and try again later. longDistanceBookingRequestUpdate response: ${data.longDistanceBookingRequestUpdate.success}`
        );
      }
    } catch (error) {
      alert(
        `Submission failed: Server error occurred. Please double-check the form values and try again later. ${error}`
      );
    }
  };

  return (
    <Modal onDismiss={onDismiss}>
      <div className="card" style={{ minWidth: 712 }}>
        <div className="px-4 py-3" style={{ backgroundColor: '#F6F9FF' }}>
          <h2
            style={{
              fontSize: 22,
              color: '#454F5C',
              fontWeight: 700,
              margin: 0,
            }}
          >
            {requestId != null ? 'Edit Request' : 'New Request'}
          </h2>
        </div>
        {longDistanceRequestResultQuery.loading === true ||
        shootTypesOptions.loading === true ||
        partnersAsAdmin.loading === true ? (
          <LoadingSpinnerCentered />
        ) : (
          <div className="p-4">
            <Formik
              initialValues={initialValues}
              onSubmit={(values) => handleSubmit({ values })}
            >
              {(formData) => (
                <>
                  <form onSubmit={formData.handleSubmit}>
                    <div>
                      <div className="form-group pb-3">
                        <label htmlFor="bookingId">Booking ID</label>
                        <input
                          id="bookingId"
                          name="bookingId"
                          value={formData.values.bookingId}
                          onChange={formData.handleChange}
                          type="text"
                          className="form-control"
                        />
                      </div>

                      <fieldset className="form-group pb-3">
                        <legend className="legend">Request information</legend>

                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <label htmlFor="status">Status</label>
                            <select
                              id="status"
                              name="status"
                              required
                              className="form-control d-inline"
                              value={formData.values.status}
                              onChange={formData.handleChange}
                            >
                              <option value="">Select</option>
                              {bookingLongDistanceStatusListValueLabel.map(
                                (status) => (
                                  <option
                                    key={status.value}
                                    value={status.value}
                                  >
                                    {status.label}
                                  </option>
                                )
                              )}
                            </select>
                          </div>

                          <div className="form-group col-md-6">
                            <label htmlFor="shootType">Shoot type</label>
                            <select
                              id="shootType"
                              name="shootType"
                              required
                              className="form-control d-inline"
                              value={formData.values.shootType}
                              onChange={formData.handleChange}
                            >
                              <option value="">Select</option>
                              {(
                                shootTypesOptions.data?.getPublicShootTypes ??
                                []
                              ).map((shootType) => (
                                <option
                                  key={shootType.category}
                                  value={shootType.category}
                                >
                                  {shootType.displayName}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <MapsLibLoader>
                          {({ maps: mapsLib, loading: loadingMaps }) => {
                            return (
                              <div className="form-group">
                                <label htmlFor="address">Shoot address</label>
                                {loadingMaps === true ? (
                                  <input
                                    type="text"
                                    className="form-control"
                                    value="Loading maps lib..."
                                    disabled
                                  />
                                ) : (
                                  <AddressAutocompleteInput
                                    onPlaceSelected={({ address }) =>
                                      formData.setFieldValue('address', address)
                                    }
                                    mapsLib={mapsLib}
                                    type="text"
                                    className={'form-control'}
                                    placeholder="Address"
                                    autoComplete="shipping street-address"
                                    value={formData.values.address}
                                    onChange={(value: string) =>
                                      formData.setFieldValue('address', value)
                                    }
                                  />
                                )}
                              </div>
                            );
                          }}
                        </MapsLibLoader>

                        <div style={{ height: 16, width: '100%' }} />

                        <label htmlFor="duration">Duration</label>
                        <select
                          id="duration"
                          name="duration"
                          required
                          className="form-control d-inline"
                          value={formData.values.duration}
                          onChange={formData.handleChange}
                        >
                          <option value="">Select</option>
                          {bookingLongDistanceDurationList.map((status) => (
                            <option key={status.value} value={status.value}>
                              {status.label}
                            </option>
                          ))}
                        </select>
                      </fieldset>

                      <fieldset className="form-group pb-3">
                        <legend className="legend">
                          Enterprise information
                        </legend>
                        <label htmlFor="partnerName">Name</label>
                        <select
                          id="partnerName"
                          name="partnerId"
                          required
                          className="form-control d-inline"
                          value={formData.values.partnerId}
                          onChange={formData.handleChange}
                        >
                          <option value="">Select</option>
                          {partnerOptions.map((partner) => (
                            <option key={partner.value} value={partner.value}>
                              {partner.label}
                            </option>
                          ))}
                        </select>
                      </fieldset>

                      <fieldset className="form-group pb-3">
                        <legend className="legend">
                          End customer information
                        </legend>
                        <label htmlFor="customerName">Name</label>
                        <input
                          id="customerName"
                          name="customerName"
                          value={formData.values.customerName}
                          onChange={formData.handleChange}
                          type="text"
                          className="form-control"
                        />
                        <div style={{ height: 16, width: '100%' }} />

                        <label htmlFor="phone">Phone</label>
                        <input
                          id="phone"
                          name="phone"
                          value={formData.values.phone}
                          onChange={formData.handleChange}
                          type="text"
                          className="form-control"
                        />

                        <div style={{ height: 16, width: '100%' }} />

                        <label htmlFor="email">Email</label>
                        <input
                          id="email"
                          name="email"
                          value={formData.values.email}
                          onChange={formData.handleChange}
                          type="email"
                          className="form-control"
                        />
                      </fieldset>
                    </div>
                    <div className="d-flex justify-content-between">
                      <button
                        type="button"
                        className="btn btn-secondary w-25"
                        onClick={onDismiss}
                      >
                        Close
                      </button>
                      <button className="btn btn-primary w-25" type="submit">
                        Save
                      </button>
                    </div>
                  </form>
                  <style jsx>{`
                    .legend {
                      font-size: 16px;
                      color: #71767e;
                    }
                    label {
                      font-size: 13px;
                      color: #6e757c;
                    }
                  `}</style>
                </>
              )}
            </Formik>
          </div>
        )}
      </div>
    </Modal>
  );
}

export const BookingsListRoute = ({
  history,
  session,
  location,
  rootBasePath,
}) => {
  const selectedTab =
    ensureString(qs.parse(location.search).selectedTab) ??
    TabCode.BOOKINGS_LIST;

  const hasUserAccessToLongDistanceTracker = checkIfCanAccess(
    session,
    'longDistanceTrackerView'
  );

  const activeTab = {
    [TabCode.BOOKINGS_LIST]: (
      <BookingsList
        location={location}
        history={history}
        session={session}
        rootBasePath={rootBasePath}
      />
    ),
    [TabCode.BOOKINGS_LONG_DISTANCE]: (
      <BookingsLongDistanceList
        rootBasePath={rootBasePath}
        location={location}
        session={session}
        history={history}
      />
    ),
  }[selectedTab];

  return (
    <div className="d-flex flex-column">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h2>Bookings</h2>

        {selectedTab === TabCode.BOOKINGS_LONG_DISTANCE ? (
          <Link
            className={`btn btn-primary`}
            to={locationForModal({
              location,
              modal: {
                modalName: 'LONG_DISTANCE_REQUEST',
              },
            })}
          >
            New Request
          </Link>
        ) : null}
      </div>

      <ContentWrapper>
        <div className="d-flex flex-column" style={{ minHeight: 400 }}>
          <div className="card flex-1" style={{ border: 'none' }}>
            <div className="card-header">
              <ul className="nav nav-tabs card-header-tabs">
                <li className="nav-item">
                  <Link
                    style={{
                      borderBottomRightRadius: 0,
                      borderBottomLeftRadius: 0,
                    }}
                    className={
                      'btn btn-link nav-link' +
                      (selectedTab !== TabCode.BOOKINGS_LIST ? '' : ' active')
                    }
                    to={{
                      ...location,
                      search: qs.stringify({
                        ...qs.parse(location.search),
                        selectedTab: TabCode.BOOKINGS_LIST,
                        q: undefined,
                      }),
                    }}
                  >
                    <strong className="mb-0">General</strong>
                  </Link>
                </li>
                {hasUserAccessToLongDistanceTracker === true ? (
                  <li className="nav-item">
                    <Link
                      style={{
                        borderBottomRightRadius: 0,
                        borderBottomLeftRadius: 0,
                      }}
                      className={
                        'btn btn-link nav-link' +
                        (selectedTab !== TabCode.BOOKINGS_LONG_DISTANCE
                          ? ''
                          : ' active')
                      }
                      to={{
                        ...location,
                        search: qs.stringify({
                          ...qs.parse(location.search),
                          selectedTab: TabCode.BOOKINGS_LONG_DISTANCE,
                          q: undefined,
                        }),
                      }}
                    >
                      <strong className="mb-0">Long-Distance</strong>
                    </Link>
                  </li>
                ) : null}
              </ul>
            </div>

            {activeTab}
          </div>
        </div>
      </ContentWrapper>
    </div>
  );
};
