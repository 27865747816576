import * as React from 'react';
import deleteIcon from './img/delete-item-button.svg';

export function DeleteItemButton({ onClick }) {
  return (
    <>
      <button onClick={onClick} className="buttonTransparent">
        <img src={deleteIcon} alt="delete item" />
      </button>
      <style jsx>{`
        .buttonTransparent {
          background-color: transparent;
          border: none;
          padding: 0px 0px 0px 5px;
          text-align: center;
          outline: none;
          color: #71767e;
        }
      `}</style>
    </>
  );
}
