import * as React from 'react';
import qs from 'query-string';
import InView from 'react-intersection-observer';

import { Switch, Route, RouteComponentProps, Link } from 'react-router-dom';
import Component2 from '@reach/component-component';
import Select from 'react-select';

import { gql, useMutation, useQuery } from '../components/ApolloClient';
import BaseLayout from '../components/BaseLayout';
import ContentWrapper from '../components/ContentWrapper';
import { formatMoneyCents, formatPercentage } from '../utils';
import { Modal } from '../components/Modal';
import { locationForModal, ModalRoute } from '../components/ModalRoute';
import { RainbowBorder } from '../components/RainbowBorder';
import coverageSuggestionsIcon from '../components/img/coverage-suggestions-icon.svg';
import { COLOR_PALLETTE } from '../constants';
import { LoadingSpinnerCentered } from '../components/LoadingSpinner';
import { Tooltip } from '../components/Tooltip';
import RcTooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';

const ELLIPSIS_STYLE = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
} as const;

const MIN_AVAILABILITY = 40;

const PgaCoverageTaskQuery = gql`
  query pgaCoverageTaskListAsAdminQuery($first: Int, $cursor: String) {
    pgaCoverageTaskListAsAdmin(first: $first, cursor: $cursor) {
      cursor
      edges {
        id
        location {
          id
          slug
          name
          state
          country
        }
        shootTypeGroup
        shootTypeGroupName
        actualRevenue
        potentialRevenue
        unrealizedRevenue
        source
        owner {
          id
          fullName
        }
        assignee {
          id
          fullName
        }
        status
        noCoverageEvents
        hasCoverageEvents
        totalCoverageEvents
        coveragePercentageEvents
        lostRevenue
      }
    }
  }
`;

type SnapprLocation = {
  id: string;
  slug?: string;
  name?: string;
  state?: string;
  country?: string;
};

type PgaCoverageTask = {
  id: string;
  location: SnapprLocation;
  shootTypeGroup?: string;
  shootTypeGroupName?: string;
  actualRevenue?: number;
  potentialRevenue?: number;
  unrealizedRevenue?: number;
  source?: string;
  owner?: { id: string; fullName: string };
  assignee?: { id: string; fullName: string };
  status?: string;
  noCoverageEvents?: number;
  hasCoverageEvents?: number;
  totalCoverageEvents?: number;
  coveragePercentageEvents?: number;
  lostRevenue?: number;
};

type PgaCoverageTaskConnection = {
  edges: PgaCoverageTask[];
  cursor: string;
};

type PgaCoverageTaskResponse = {
  pgaCoverageTaskListAsAdmin: PgaCoverageTaskConnection;
};

const MODAL_NAME_PGA_CREATE_COVERAGE_TASK_MODAL =
  'modal_name_pga_create_coverage_task_modal';

const MODAL_NAME_PGA_COVERAGE_SUGGESTIONS_MODAL =
  'modal_name_pga_coverage_suggestions_modal';

export const PGACoverageTaskListRoute = ({
  location,
  history,
  session,
}: {
  location: RouteComponentProps['location'];
  history: RouteComponentProps['history'];
  session: { token: string; uid: string };
}) => {
  const [cursors, setCursors] = React.useState<string[]>([]);
  const [modifiedAt, setModifiedAt] = React.useState<Date>(new Date());

  return (
    <>
      <div
        style={{
          paddingBottom: '10px',
        }}
      >
        <h2>Coverage</h2>
      </div>

      <ContentWrapper>
        <div className="d-flex justify-content-end">
          <Link
            className="d-flex btn btn-dark"
            to={locationForModal({
              location,
              modal: {
                modalName: MODAL_NAME_PGA_CREATE_COVERAGE_TASK_MODAL,
              },
            })}
          >
            Create task
          </Link>
        </div>
        <div className="table-responsive pt-4">
          <table className="table" style={{ position: 'relative' }}>
            <thead style={{ color: '#71767E' }}>
              <tr>
                <th style={ELLIPSIS_STYLE} scope="col">
                  Snappr Location
                </th>
                <th style={ELLIPSIS_STYLE} scope="col">
                  Shoot type group
                </th>
                <th style={ELLIPSIS_STYLE} scope="col" className="text-right">
                  Actual revenue
                  <Tooltip label={'Per month'}>
                    <i className="fa fa-info-circle ml-2" />
                  </Tooltip>
                </th>
                <th style={ELLIPSIS_STYLE} scope="col" className="text-right">
                  Lost revenue
                  <Tooltip label={'Last 30 days'}>
                    <i className="fa fa-info-circle ml-2" />
                  </Tooltip>
                </th>
                <th style={ELLIPSIS_STYLE} scope="col" className="text-right">
                  Unrealized revenue
                  <Tooltip label={'Per month'}>
                    <i className="fa fa-info-circle ml-2" />
                  </Tooltip>
                </th>
                <th style={ELLIPSIS_STYLE} scope="col" className="text-right">
                  Potential revenue
                  <Tooltip label={'Per month'}>
                    <i className="fa fa-info-circle ml-2" />
                  </Tooltip>
                </th>
                <th style={ELLIPSIS_STYLE} scope="col" className="text-right">
                  Coverage rate
                  <Tooltip label={'Last 30 days'}>
                    <i className="fa fa-info-circle ml-2" />
                  </Tooltip>
                </th>
                <th style={ELLIPSIS_STYLE} scope="col">
                  Assignee
                </th>
                <th style={ELLIPSIS_STYLE} scope="col">
                  Source
                </th>
                <th style={ELLIPSIS_STYLE} scope="col" />
              </tr>
            </thead>

            <tbody style={{ color: '#6C757D' }}>
              {[null, ...cursors].map((cursor, index) => (
                <DataRowsChunk
                  key={(cursor ?? '0') + modifiedAt.getTime()}
                  session={session}
                  history={history}
                  location={location}
                  cursor={cursor}
                  onMore={
                    index === cursors.length + 1 - 1
                      ? ({ nextCursor }) =>
                          setCursors((state) =>
                            state.includes(nextCursor)
                              ? state
                              : [...state, nextCursor]
                          )
                      : undefined
                  }
                />
              ))}
            </tbody>
          </table>
        </div>
      </ContentWrapper>

      <OnPgaCreateCoverageTaskModal
        onSave={() => {
          setCursors([]);
          setModifiedAt(new Date());
        }}
      />

      <OnPgaCoverageSuggestionsModal />
    </>
  );
};

const OnPgaCreateCoverageTaskModal = ({ onSave }: { onSave: () => void }) => {
  return (
    <ModalRoute
      modalName={MODAL_NAME_PGA_CREATE_COVERAGE_TASK_MODAL}
      render={({ history, location, modalParams }) => (
        <PgaCreateCoverageTaskModal
          onDismiss={() =>
            history.replace(
              locationForModal({
                location,
                modal: undefined,
              })
            )
          }
          location={location}
          history={history}
          onContinue={() => {
            onSave();

            history.replace(
              locationForModal({
                location,
                modal: undefined,
              })
            );
          }}
          modalParams={modalParams}
        />
      )}
    />
  );
};

const OnPgaCoverageSuggestionsModal = () => {
  return (
    <ModalRoute
      modalName={MODAL_NAME_PGA_COVERAGE_SUGGESTIONS_MODAL}
      render={({ history, location, modalParams }) => (
        <PgaCoverageSuggestionsModal
          onDismiss={() =>
            history.replace(
              locationForModal({
                location,
                modal: undefined,
              })
            )
          }
          modalParams={modalParams}
        />
      )}
    />
  );
};

type SelectedType = {
  label: string;
  value: string;
};

export const PgaCreateCoverageTaskModal = ({
  location,
  history,
  onDismiss,
  onContinue,
  modalParams,
}: {
  location: RouteComponentProps['location'];
  history: RouteComponentProps['history'];
  onDismiss: () => void;
  onContinue: () => void;
  modalParams: {
    locationId: string;
    locationName: string;
    shoottypeGroup: string;
    shoottypeName: string;
    revenue: string;
    description?: string;
    requestedByName: string;
    requestedById: string;
  };
}) => {
  const [taskAlreadyExists, setTaskAlreadyExists] = React.useState<boolean>(
    false
  );

  const query = useQuery<{
    snapprLocationsGetAsAdmin: {
      edges: { id: string; displayName?: string }[];
    };
    getShootTypes: {
      group: string;
      displayName: string;
    }[];
    snapprAdminUsersListAsAdmin: {
      edges: { id: string; email: string; fullName: string }[];
    };
  }>(
    gql`
      query GetLocationsAndShootTypesForCoverageTaskAsAdmin {
        snapprLocationsGetAsAdmin {
          edges {
            id
            displayName
          }
        }

        getShootTypes {
          group
          displayName
        }

        snapprAdminUsersListAsAdmin {
          edges {
            id
            email
            fullName
          }
        }
      }
    `
  );

  const locations = query.data?.snapprLocationsGetAsAdmin.edges;

  const locationOptions = (locations ?? []).map((location) => ({
    label: location.displayName,
    value: location.id,
  }));

  const shoottypes = query.data?.getShootTypes;

  const shoottypeOptions = (shoottypes ?? []).map((shoottype) => ({
    label: shoottype.displayName,
    value: shoottype.group,
  }));

  const adminUsers = query.data?.snapprAdminUsersListAsAdmin.edges;

  const requestedByOptions = (adminUsers ?? []).map((adminUser) => ({
    label: `${adminUser.fullName} <${adminUser.email}>`,
    value: adminUser.id,
  }));

  const [
    selectedLocation,
    setSelectedLocation,
  ] = React.useState<SelectedType | null>(
    modalParams.locationId != null
      ? {
          label: modalParams.locationName,
          value: modalParams.locationId,
        }
      : null
  );

  const [
    selectedShotType,
    setSelectedShotType,
  ] = React.useState<SelectedType | null>(
    modalParams.locationId != null
      ? {
          label: modalParams.shoottypeName,
          value: modalParams.shoottypeGroup,
        }
      : null
  );

  const [
    selectedRequestedBy,
    setSelectedRequestedBy,
  ] = React.useState<SelectedType | null>(
    modalParams.locationId != null
      ? {
          label: modalParams.requestedByName,
          value: modalParams.requestedById,
        }
      : null
  );

  const [commitPgaCreate, commitPgaCreateMutation] = useMutation<
    {
      pgaCoverageTaskCreate: {
        success: boolean;
        id?: string;
        existingTaskId?: string;
      };
    },
    {
      locationId: string;
      shoottypeGroup: string;
      revenue: number;
      description?: string;
      requestedBy: string;
    }
  >(
    gql`
      mutation CoverageTaskCreate(
        $locationId: ID!
        $shoottypeGroup: String!
        $revenue: Int!
        $description: String
        $requestedBy: ID!
      ) {
        pgaCoverageTaskCreate(
          input: {
            locationId: $locationId
            shoottypeGroup: $shoottypeGroup
            source: manual
            revenue: $revenue
            description: $description
            requestedBy: $requestedBy
          }
        ) {
          success
          id
          existingTaskId
        }
      }
    `
  );

  const handleLocationOnChange = (selectedValue: SelectedType) => {
    history.replace(
      locationForModal({
        location,
        modal: {
          ...modalParams,
          locationName: selectedValue.label,
          locationId: selectedValue.value,
        },
      })
    );
    setSelectedLocation(selectedValue);
  };

  const handleShoottypeOnChange = (selectedValue: SelectedType) => {
    history.replace(
      locationForModal({
        location,
        modal: {
          ...modalParams,
          shoottypeName: selectedValue.label,
          shoottypeGroup: selectedValue.value,
        },
      })
    );
    setSelectedShotType(selectedValue);
  };

  const handleRequestedByOnChange = (selectedValue: SelectedType) => {
    history.replace(
      locationForModal({
        location,
        modal: {
          ...modalParams,
          requestedByName: selectedValue.label,
          requestedById: selectedValue.value,
        },
      })
    );
    setSelectedRequestedBy(selectedValue);
  };

  const isFormFullyFilled =
    modalParams.locationId != null &&
    modalParams.shoottypeGroup != null &&
    modalParams.revenue != null &&
    modalParams.description != null &&
    modalParams.requestedById != null;

  React.useEffect(() => {
    if (
      requestedByOptions.length > 0 &&
      modalParams.requestedByName == null &&
      query.loading === false
    ) {
      history.replace(
        locationForModal({
          location,
          modal: {
            ...modalParams,
            requestedByName: requestedByOptions[0].label,
            requestedById: requestedByOptions[0].value,
          },
        })
      );
      setSelectedRequestedBy(requestedByOptions[0]);
    }
  }, [history, location, modalParams, query.loading, requestedByOptions]);

  return (
    <Modal onDismiss={onDismiss}>
      <div className="card">
        <RainbowBorder />

        <div className="card-body text-center">
          <div className="py-2" />
          <h4>Create task</h4>
          <span className="text-center">
            Select a Snappr location and a shoot type.
          </span>
        </div>

        <form
          className="card-body d-flex flex-column py-0"
          style={{ width: '520px' }}
          onSubmit={(e) => {
            e.preventDefault();

            commitPgaCreate({
              variables: {
                locationId: modalParams.locationId,
                shoottypeGroup: modalParams.shoottypeGroup,
                revenue: parseInt(modalParams.revenue, 10),
                description: modalParams.description,
                requestedBy: modalParams.requestedById,
              },
            }).then((res) => {
              if (res.data?.pgaCoverageTaskCreate.success !== true) {
                setTaskAlreadyExists(true);
              } else {
                onContinue();
              }
            });
          }}
        >
          <div style={{ position: 'relative' }}>
            <div className="row">
              <div className="form-group col">
                <label>
                  <p className="text-secondary mb-0">Snappr location</p>
                </label>

                <Select
                  value={selectedLocation}
                  options={locationOptions}
                  placeholder="e.g. San Francisco "
                  onChange={handleLocationOnChange}
                  isLoading={query.loading}
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col">
                <label>
                  <p className="text-secondary mb-0">Shoot type</p>
                </label>

                <Select
                  value={selectedShotType}
                  options={shoottypeOptions}
                  placeholder="Shoot type"
                  onChange={handleShoottypeOnChange}
                  isLoading={query.loading}
                />
              </div>

              <div className="form-group col">
                <label>
                  <p className="text-secondary mb-0">
                    Potential revenue (<u>Not</u> GMV)
                  </p>
                </label>

                <input
                  type="number"
                  className="form-control"
                  placeholder="e.g. $50,000"
                  value={modalParams.revenue ?? ''}
                  onChange={({ target: { value } }) =>
                    history.replace(
                      locationForModal({
                        location,
                        modal: {
                          ...modalParams,
                          revenue: value,
                        },
                      })
                    )
                  }
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col">
                <label>
                  <p className="text-secondary mb-0">Additional notes</p>
                </label>

                <textarea
                  value={modalParams.description}
                  onChange={({ target: { value } }) =>
                    history.replace(
                      locationForModal({
                        location,
                        modal: {
                          ...modalParams,
                          description: value,
                        },
                      })
                    )
                  }
                  placeholder="e.g. An enterprise needs to do a pilot so we need to recruit photographers."
                  className="form-control"
                  rows={3}
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col">
                <label>
                  <p className="text-secondary mb-0">Requested by</p>
                </label>

                <Select
                  value={selectedRequestedBy}
                  options={requestedByOptions}
                  placeholder="e.g John Smith"
                  onChange={handleRequestedByOnChange}
                  isLoading={query.loading}
                />
              </div>
            </div>
          </div>

          {taskAlreadyExists === true && (
            <small className="text-danger">
              There is already a task created for this location and shoot type
              group
              <p>
                {/* TODO: replace this with a link to the task */}
                TaskId:{' '}
                {
                  commitPgaCreateMutation.data?.pgaCoverageTaskCreate
                    .existingTaskId
                }
              </p>
            </small>
          )}

          <div className="mb-3 py-2">
            <button
              type="submit"
              className="btn btn-block btn-dark"
              disabled={
                commitPgaCreateMutation.loading || isFormFullyFilled !== true
              }
            >
              Create task
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export const PgaCoverageSuggestionsModal = ({
  onDismiss,
  modalParams,
}: {
  onDismiss: () => void;
  modalParams: {
    locationId: string;
    locationName: string;
    shootTypeGroup: string;
    shootTypeGroupName: string;
    source: string;
  };
}) => {
  const query = useQuery<{
    lowAvailability: {
      edges: { id: string }[];
      count: number;
    };
    otherShootTypes: {
      edges: { id: string }[];
      count: number;
    };
    inactive: {
      edges: { id: string }[];
      count: number;
    };
    inPipeline: {
      edges: { id: string }[];
      count: number;
    };
    getShootTypes: {
      group: string;
      name: string;
    }[];
  }>(
    gql`
      query GetPgsWithLowAvailabilityForCoverageSuggestionsModal(
        $locationId: ID!
        $includesShootTypeGroup: [String]
        $notIncludesShootTypeGroup: [String]
        $minAvailability: Int
      ) {
        lowAvailability: pgaCoverageTaskSuggestionsPgsAsAdmin(
          input: {
            locationId: $locationId
            includesShootTypeGroup: $includesShootTypeGroup
            minAvailability: $minAvailability
            status: active
            acceptingBookings: true
          }
        ) {
          edges {
            id
          }
          count
        }

        otherShootTypes: pgaCoverageTaskSuggestionsPgsAsAdmin(
          input: {
            locationId: $locationId
            notIncludesShootTypeGroup: $notIncludesShootTypeGroup
            status: active
            acceptingBookings: true
          }
        ) {
          edges {
            id
          }
          count
        }

        inactive: pgaCoverageTaskSuggestionsPgsAsAdmin(
          input: {
            locationId: $locationId
            includesShootTypeGroup: $includesShootTypeGroup
            status: active
            acceptingBookings: false
          }
        ) {
          edges {
            id
          }
          count
        }

        inPipeline: pgaCoverageTaskSuggestionsPgsAsAdmin(
          input: {
            locationId: $locationId
            status: pending
            includesShootTypeGroup: $includesShootTypeGroup
          }
        ) {
          edges {
            id
          }
          count
        }

        getShootTypes {
          group
          name
        }
      }
    `,
    {
      variables: {
        locationId: modalParams.locationId,
        includesShootTypeGroup: [modalParams.shootTypeGroup],
        notIncludesShootTypeGroup: [modalParams.shootTypeGroup],
        minAvailability: MIN_AVAILABILITY,
      },
    }
  );

  const pgsWithLowAvailabilityCount = query.data?.lowAvailability.count;
  const pgsWithOtherShootTypesCount = query.data?.otherShootTypes.count;
  const inactivePgsCount = query.data?.inactive.count;
  const pgsInPipeline = query.data?.inPipeline.count;

  const shootTypesInGroup =
    query.data?.getShootTypes.filter(
      (s) => s.group === modalParams.shootTypeGroup
    ) ?? [];

  const shootTypesInGroupJoinedByComma = shootTypesInGroup
    .map((shoottype) => shoottype.name)
    .filter((name) => name != null)
    .join(',');

  const inPipelineStatesJoinedByComma = [
    'applied',
    'approved',
    'setup',
    'interview',
    'backlink',
    'waitlist',
    'incomplete',
    'lead',
  ].join(',');

  return (
    <Modal onDismiss={onDismiss}>
      <div className="card" style={{ maxWidth: 520 }}>
        <RainbowBorder />

        {query.data == null ? (
          <div
            className="card-body text-center align-self-center"
            style={{ width: 520 }}
          >
            <LoadingSpinnerCentered />
          </div>
        ) : (
          <>
            <div className="card-body text-center align-self-center">
              <div className="py-2" />
              <h4>Suggestions</h4>
              <small className="text-center">
                According to our data, these are the suggested actions.
              </small>
            </div>

            <Link
              to={`/provider-applications?${qs.stringify({
                filter_accept_bookings: true,
                filter_city: modalParams.locationId,
                filter_shoottype: shootTypesInGroupJoinedByComma,
                filter_status: 'active',
                filter_availability_less_than: MIN_AVAILABILITY,
                limit: 100,
              })}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: 'none',
              }}
            >
              <div className="card-body d-flex flex-column px-4 py-2">
                <div
                  className="row d-flex flex-column p-3"
                  style={{
                    border: `1px solid ${COLOR_PALLETTE.GRAY_3}`,
                    borderRadius: 2,
                  }}
                >
                  <span style={{ color: COLOR_PALLETTE.PRIMARY }}>
                    Ask a photographer to extend their availability
                  </span>
                  <small style={{ color: COLOR_PALLETTE.GRAY_4 }}>
                    {`There are ${pgsWithLowAvailabilityCount ?? '-'} ${
                      modalParams.locationName
                    } ${modalParams.shootTypeGroupName.toLowerCase()} photographers with limited
              availability.`}
                  </small>
                </div>
              </div>
            </Link>

            <Link
              to={`/provider-applications?${qs.stringify({
                filter_accept_bookings: true,
                filter_city: modalParams.locationId,
                filter_not_shoottype: shootTypesInGroupJoinedByComma,
                filter_status: 'active',
                limit: 100,
              })}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: 'none',
              }}
            >
              <div className="card-body d-flex flex-column px-4 py-2">
                <div
                  className="row d-flex flex-column p-3"
                  style={{
                    border: `1px solid ${COLOR_PALLETTE.GRAY_3}`,
                    borderRadius: 2,
                  }}
                >
                  <span style={{ color: COLOR_PALLETTE.PRIMARY }}>
                    Ask a photographer to work other shoot types
                  </span>
                  <small style={{ color: COLOR_PALLETTE.GRAY_4 }}>
                    {`There are ${pgsWithOtherShootTypesCount ?? '-'} ${
                      modalParams.locationName
                    } photographers working other shoot
              types.`}
                  </small>
                </div>
              </div>
            </Link>

            <Link
              to={`/provider-applications?${qs.stringify({
                filter_accept_bookings: false,
                filter_city: modalParams.locationId,
                filter_shoottype: shootTypesInGroupJoinedByComma,
                filter_status: 'active',
                limit: 100,
              })}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: 'none',
              }}
            >
              <div className="card-body d-flex flex-column px-4 py-2">
                <div
                  className="row d-flex flex-column p-3"
                  style={{
                    border: `1px solid ${COLOR_PALLETTE.GRAY_3}`,
                    borderRadius: 2,
                  }}
                >
                  <span style={{ color: COLOR_PALLETTE.PRIMARY }}>
                    Ask an inactive photographer to receive shoots
                  </span>
                  <small style={{ color: COLOR_PALLETTE.GRAY_4 }}>
                    {`There are ${inactivePgsCount ?? '-'} inactive ${
                      modalParams.locationName
                    } ${modalParams.shootTypeGroupName.toLowerCase()} photographers.`}
                  </small>
                </div>
              </div>
            </Link>

            <Link
              to={`/provider-applications?${qs.stringify({
                filter_city: modalParams.locationId,
                filter_shoottype: shootTypesInGroupJoinedByComma,
                filter_status: inPipelineStatesJoinedByComma,
                limit: 100,
              })}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: 'none',
              }}
            >
              <div className="card-body d-flex flex-column px-4 py-2">
                <div
                  className="row d-flex flex-column p-3"
                  style={{
                    border: `1px solid ${COLOR_PALLETTE.GRAY_3}`,
                    borderRadius: 2,
                  }}
                >
                  <span style={{ color: COLOR_PALLETTE.PRIMARY }}>
                    Approve a candidate from the pipeline
                  </span>
                  <small style={{ color: COLOR_PALLETTE.GRAY_4 }}>
                    {`There are ${pgsInPipeline ?? '-'} ${
                      modalParams.locationName
                    } ${modalParams.shootTypeGroupName.toLowerCase()} photographers in the pipeline.`}
                  </small>
                </div>
              </div>
            </Link>

            <Link
              to={`/provider-applications`}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: 'none',
              }}
            >
              <div className="card-body d-flex flex-column px-4 py-2">
                <div
                  className="row d-flex flex-column p-3"
                  style={{
                    border: `1px solid ${COLOR_PALLETTE.GRAY_3}`,
                    borderRadius: 2,
                  }}
                >
                  <span style={{ color: COLOR_PALLETTE.PRIMARY }}>
                    Source a new photographer
                  </span>
                  <small style={{ color: COLOR_PALLETTE.GRAY_4 }}>
                    {`Recruit a new ${modalParams.shootTypeGroupName.toLowerCase()} photographer in ${
                      modalParams.locationName
                    }.`}
                  </small>
                </div>
              </div>
            </Link>
          </>
        )}
      </div>
    </Modal>
  );
};

function DataRowsChunk({
  cursor,
  session,
  history,
  onMore,
  location,
}: {
  cursor: string | null;
  onMore?: (p: { nextCursor: string }) => void;
  location: RouteComponentProps['location'];
  session: { token: string; uid: string };
  history: RouteComponentProps['history'];
}) {
  const pgaCoverageTaskListQuery = useQuery<PgaCoverageTaskResponse>(
    PgaCoverageTaskQuery,
    {
      variables: {
        cursor,
        first: cursor == null ? 20 : 40,
      },
      fetchPolicy: 'network-only',
    }
  );

  const pgaCoverageTaskList =
    pgaCoverageTaskListQuery.data?.pgaCoverageTaskListAsAdmin?.edges;
  const nextCursor =
    pgaCoverageTaskListQuery.data?.pgaCoverageTaskListAsAdmin?.cursor;

  const [saveAssignee, saveAssigneeMutation] = useMutation<{
    pgaCoverageTaskSaveAssigneeAsAdmin: {
      task: { id: string };
    };
  }>(
    gql`
      mutation PgaCoverageTaskSaveAssigneeAsAdmin(
        $taskId: ID!
        $assigneeId: ID!
      ) {
        pgaCoverageTaskSaveAssigneeAsAdmin(
          input: { taskId: $taskId, assigneeId: $assigneeId }
        ) {
          task {
            id
          }
        }
      }
    `
  );

  const [startProgress, startProgressMutation] = useMutation<{
    pgaCoverageTaskSaveAssigneeAsAdmin: {
      task: { id: string };
    };
  }>(
    gql`
      mutation PgaCoverageTaskStartProgressAsAdmin($taskId: ID!) {
        pgaCoverageTaskStartProgressAsAdmin(input: { taskId: $taskId }) {
          task {
            id
          }
        }
      }
    `
  );

  return (
    <>
      {onMore == null || nextCursor == null ? null : (
        <InView>
          {({ inView, ref }) => (
            <tr ref={ref}>
              {inView && <Component2 didMount={() => onMore({ nextCursor })} />}
            </tr>
          )}
        </InView>
      )}

      {pgaCoverageTaskList == null ||
      pgaCoverageTaskList.length === 0 ||
      pgaCoverageTaskListQuery.loading
        ? Array.from(Array(7)).map((_, index) => (
            <tr key={'loading-' + index}>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
            </tr>
          ))
        : pgaCoverageTaskList?.map((pgaCoverageTask) => (
            <tr key={pgaCoverageTask.id}>
              <td className="align-middle">
                {[
                  pgaCoverageTask.location.name,
                  pgaCoverageTask.location.state,
                  pgaCoverageTask.location.country,
                ]
                  .filter(Boolean)
                  .join(', ')}
              </td>

              <td className="align-middle">
                {pgaCoverageTask.shootTypeGroupName}
              </td>

              <td className="text-right">
                {formatMoneyCents(pgaCoverageTask.actualRevenue ?? 0, null)}
              </td>

              <td className="text-right">
                {formatMoneyCents(pgaCoverageTask.lostRevenue ?? 0, null)}
              </td>

              <td className="text-right">
                {formatMoneyCents(pgaCoverageTask.unrealizedRevenue ?? 0, null)}{' '}
              </td>

              <td className="text-right">
                {formatMoneyCents(pgaCoverageTask.potentialRevenue ?? 0, null)}{' '}
              </td>

              <td className="text-right">
                {pgaCoverageTask.coveragePercentageEvents
                  ? formatPercentage(pgaCoverageTask.coveragePercentageEvents)
                  : '-'}
                <RcTooltip
                  trigger={['click']}
                  placement="bottom"
                  overlay={
                    <>
                      <p>Coverage: {pgaCoverageTask.hasCoverageEvents}</p>
                      <p>No coverage: {pgaCoverageTask.noCoverageEvents}</p>
                      <p>Total events: {pgaCoverageTask.totalCoverageEvents}</p>
                    </>
                  }
                >
                  <i className="fa fa-info-circle ml-2" />
                </RcTooltip>
              </td>

              <td className="align-middle">
                {pgaCoverageTask.assignee?.fullName}
              </td>

              <td
                className="align-middle"
                style={{ textTransform: 'capitalize' }}
              >
                {pgaCoverageTask.source}
              </td>

              <td
                className="align-middle"
                style={{ textTransform: 'capitalize' }}
              >
                {pgaCoverageTask?.status === 'PENDING' && (
                  <button
                    className="btn btn-dark"
                    style={{ marginRight: '5px' }}
                    disabled={saveAssigneeMutation.loading}
                    onClick={() => {
                      saveAssignee({
                        variables: {
                          taskId: pgaCoverageTask?.id,
                          assigneeId: session.uid,
                        },
                      }).then(() => pgaCoverageTaskListQuery.refetch());
                    }}
                  >
                    Claim
                  </button>
                )}
                {pgaCoverageTask?.status === 'ASSIGNED' && (
                  <button
                    className="btn btn-dark"
                    style={{ marginRight: '5px' }}
                    disabled={startProgressMutation.loading}
                    onClick={() => {
                      startProgress({
                        variables: {
                          taskId: pgaCoverageTask?.id,
                        },
                      }).then(() => {
                        pgaCoverageTaskListQuery.refetch();
                        history.replace(
                          locationForModal({
                            location,
                            modal: {
                              modalName: MODAL_NAME_PGA_COVERAGE_SUGGESTIONS_MODAL,
                              locationId: pgaCoverageTask.location.id,
                              locationName: pgaCoverageTask.location.name,
                              shootTypeGroup: pgaCoverageTask.shootTypeGroup,
                              shootTypeGroupName:
                                pgaCoverageTask.shootTypeGroupName,
                            },
                          })
                        );
                      });
                    }}
                  >
                    Start
                  </button>
                )}
                {pgaCoverageTask?.status === 'IN_PROGRESS' && (
                  <button
                    disabled
                    className="btn btn-dark"
                    style={{ marginRight: '5px' }}
                    onClick={() => {}}
                  >
                    Mark as done
                  </button>
                )}
                <Link
                  className="btn btn-dark"
                  to={locationForModal({
                    location,
                    modal: {
                      modalName: MODAL_NAME_PGA_COVERAGE_SUGGESTIONS_MODAL,
                      locationId: pgaCoverageTask.location.id,
                      locationName: pgaCoverageTask.location.name,
                      shootTypeGroup: pgaCoverageTask.shootTypeGroup,
                      shootTypeGroupName: pgaCoverageTask.shootTypeGroupName,
                    },
                  })}
                >
                  <img
                    style={{ paddingBottom: '4px' }}
                    src={coverageSuggestionsIcon}
                    alt="suggestions"
                  />
                </Link>
              </td>
            </tr>
          ))}

      {onMore == null || nextCursor == null ? null : (
        <InView>
          {({ inView, ref }) => (
            <tr ref={ref}>
              {inView && <Component2 didMount={() => onMore({ nextCursor })} />}
            </tr>
          )}
        </InView>
      )}
    </>
  );
}

export function PGACoverageTaskRoute({
  session,
  match,
  match: { path: basePath },
}) {
  return (
    <BaseLayout session={session} match={match}>
      <Switch>
        <Route
          exact
          path={basePath}
          render={(props) => (
            <PGACoverageTaskListRoute
              session={session}
              location={props.location}
              history={props.history}
            />
          )}
        />
      </Switch>
    </BaseLayout>
  );
}
