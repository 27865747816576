import React from 'react';
import { Link } from 'react-router-dom';

import { BOOKING_STATUS, isPartnerBooking } from '../../selectors';
import { ModalRoute, locationForModal } from '../ModalRoute';
import { CancelOrRefundModal } from './CancelOrRefundModal';
import { CancellationModal } from './CancellationModal';
import { ChangesModal } from './ChangesModal';
import { infoForBooking } from './utils';
import {
  NEW_BOOKING_CANCELLATION_MODAL_CUSTOMER,
  NEW_BOOKING_CANCELLATION_MODAL_ENTERPRISE,
} from '../../../../../config';
export { WarningSection } from './ChangesModal';

const MODAL_NAME_AFTER_PAID_CHANGES = 'after-paid-changes';
const MODAL_NAME_AFTER_PAID_CANCEL = 'after-paid-cancel';
const MODAL_NAME_AFTER_PAID_CANCELLATION_NEW = 'after-paid-cancellation-new';

export const UpdateButton = ({
  disabled,
  booking,
  bookingClone,
  changedKeys,
  location,
}) => {
  const { label, primary } = infoForBooking({
    booking,
    bookingClone,
    changedKeys,
  });

  return bookingClone.provider_uid == null &&
    booking.status === BOOKING_STATUS.PAID ? null : (
    <Link
      className={`btn ${primary ? 'btn-primary' : 'btn-secondary'} ${
        disabled ? 'disabled' : ''
      }`}
      to={locationForModal({
        location,
        modal: { modalName: MODAL_NAME_AFTER_PAID_CHANGES },
      })}
    >
      {label}
    </Link>
  );
};

function showNewCancellationModal(booking) {
  const isBookingStatusPaid = [
    BOOKING_STATUS.PAID,
    BOOKING_STATUS.PAID_PENDING_SCHEDULE,
    BOOKING_STATUS.PAID_ON_HOLD,
  ].includes(booking.status);

  if (!isBookingStatusPaid) return false;

  if (
    isPartnerBooking({ booking }) &&
    NEW_BOOKING_CANCELLATION_MODAL_ENTERPRISE === true
  ) {
    return true;
  } else if (
    !isPartnerBooking({ booking }) &&
    NEW_BOOKING_CANCELLATION_MODAL_CUSTOMER === true
  ) {
    return true;
  }

  return false;
}

export const ConfirmAfterPaidChanges = (props) => (
  <>
    {![BOOKING_STATUS.CANCELLED, BOOKING_STATUS.REFUNDED].includes(
      props.booking.status
    ) && (
      <Link
        className={`btn btn-danger ${props.disabled ? 'disabled' : ''}`}
        to={locationForModal({
          location: props.location,
          modal: {
            modalName: showNewCancellationModal(props.booking)
              ? MODAL_NAME_AFTER_PAID_CANCELLATION_NEW
              : MODAL_NAME_AFTER_PAID_CANCEL,
          },
        })}
      >
        {[BOOKING_STATUS.PAID, BOOKING_STATUS.PAID_PENDING_SCHEDULE].includes(
          props.booking.status
        )
          ? 'Cancel'
          : 'Refund'}
      </Link>
    )}

    <ModalRoute
      modalName={MODAL_NAME_AFTER_PAID_CANCEL}
      render={({ history, location, modalParams }) => (
        <CancelOrRefundModal
          {...props}
          modalParams={modalParams}
          history={history}
          location={location}
          onDismiss={() =>
            history.replace(
              locationForModal({
                location,
                modal: undefined,
              })
            )
          }
        />
      )}
    />

    <ModalRoute
      modalName={MODAL_NAME_AFTER_PAID_CANCELLATION_NEW}
      render={({ history, location, modalParams }) => (
        <CancellationModal
          {...props}
          modalParams={modalParams}
          history={history}
          location={location}
          onDismiss={() =>
            history.replace(
              locationForModal({
                location,
                modal: undefined,
              })
            )
          }
        />
      )}
    />

    {(Object.keys(props.bookingChangedKeys).length > 0 ||
      props.booking.status === BOOKING_STATUS.PAID_ON_HOLD) && (
      <UpdateButton
        disabled={props.disabled}
        booking={props.booking}
        bookingClone={props.bookingClone}
        changedKeys={props.bookingChangedKeys}
        location={props.location}
      />
    )}

    <ModalRoute
      modalName={MODAL_NAME_AFTER_PAID_CHANGES}
      render={({ history, location }) => (
        <ChangesModal
          {...props}
          onDismiss={() =>
            history.replace(
              locationForModal({
                location,
                modal: undefined,
              })
            )
          }
        />
      )}
    />
  </>
);
