import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import BaseLayout from '../../components/BaseLayout';
import { BaseStyle } from '../../components/BaseStyle';

import { GalleryUploadRoute } from './gallery-upload';
import { EditingJobDetails } from './details';
import { EditingQueueRoute } from './editing-queue';

export const EditingJobsRoute = ({
  session,
  match,
  match: { path: basePath },
  history,
}) => (
  <BaseLayout session={session} match={match}>
    <BaseStyle className="d-flex flex-column flex-1">
      <Switch>
        <Route
          exact
          path={basePath}
          render={(props) => (
            <EditingQueueRoute
              location={props.location}
              session={session}
              history={history}
            />
          )}
        />

        <Route
          path={`${basePath}/:jobId/gallery-upload`}
          render={(props) => (
            <GalleryUploadRoute {...props} session={session} />
          )}
        />

        <Route
          path={`${basePath}/:jobId/details`}
          render={(props) => <EditingJobDetails {...props} session={session} />}
        />

        <Route
          render={({ location }) => (
            <Redirect to={{ ...location, pathname: basePath }} />
          )}
        />
      </Switch>
    </BaseStyle>
  </BaseLayout>
);
