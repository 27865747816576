import React from 'react';
import styled from 'styled-components';

const TABLE_COLOR = '#818a95';
const TABLE_HEADER_COLOR = TABLE_COLOR;

function getIcon(attrName, ordering) {
  const orderingList = (ordering && ordering.split(',')) || [];
  if (orderingList.indexOf(attrName) !== -1) {
    return 'sort-asc';
  } else if (orderingList.indexOf(`-${attrName}`) !== -1) {
    return 'sort-desc';
  }
  return 'sort';
}

export const TableHeaderAttr = ({
  attrName,
  label,
  onClick,
  ordering,
  thStyle = {},
}) => (
  <th style={thStyle} className="text-truncate">
    <button
      className="btn btn-link p-0 text-muted text-truncate font-weight-bold"
      onClick={onClick}
    >
      {label}
      <i
        aria-hidden="true"
        className={`fa fa-${getIcon(attrName, ordering)} ml-2`}
      />
    </button>
  </th>
);

export const StyledDatatable = styled('div')`
  color: #818a95;

  table {
    width: max-content;

    &.slim {
      margin: 0;
      td {
        padding: 0.4em 0px;
        border-bottom: none;
      }
    }

    thead {
      tr {
        line-height: 38px;
      }

      th {
        white-space: nowrap;
        padding: 0.4em;
      }
    }

    .tableHeader {
      color: #b2bcc7;
    }

    datacell {
      display: inline;
    }

    .tableEntry {
      .columnDataWrapper {
        white-space: nowrap;

        .columnDataEntry {
          > span {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 2.6em;
          }
        }

        &.customCommonStyle {
          line-height: 16px;
          vertical-align: middle;

          .columnDataEntry {
            display: inline-block;
            padding: 0 1em;
            color: white;
            text-align: center;
            border-radius: 0.5em;
            text-transform: uppercase;
            font-size: 0.5em;
            background-color: lightgrey;
          }
        }
      }
    }

    tr.linked {
      &:hover {
        opacity: 0.5;
      }
    }

    th {
      position: relative;

      .sortControl {
        padding-left: 5px;
        display: inline-block;
      }

      .columnLabel {
        display: inline-block;
      }
    }

    tbody {
      td {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
      }
      a {
        color: ${TABLE_COLOR};
        text-decoration: none;
      }
    }
  }

  nav.pagination {
    position: absolute;
    bottom: -60px;
    right: 15px;
    background-color: white;
    border-radius: 4px;

    .paginationSection {
      @mixin pagination-tabs() {
        &:first-child {
          border: none;
        }

        border-radius: 0;
        border-left: 2px solid #f4f4f4;
        background-color: transparent;
      }

      @include pagination-tabs();

      a {
        color: #818a95;
      }

      .pageTab {
        @include pagination-tabs();

        .activeTab {
          background-color: white;
          font-weight: bold;
          color: $snapprblue;
        }
      }
    }
  }
`;
