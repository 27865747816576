import { ZQueue } from 'zqueue';

import { gql, useQuery } from '../../../components/ApolloClient';
import { useUploadManager } from '../../../components/useUploadManager';

export function useEditingJobQuery({ jobId }: { jobId?: string }) {
  return useQuery<{
    editingJob?: {
      id: string;
      displayStatus: string;
      notes: string;
      partner: {
        uid: string;
        name: string;
      };
      booking?: { id: string };
      editor?: {
        id: string;
        fullName: string;
      };
      servicesSummary: {
        edges: {
          id: string;
          details: {
            notes?: string;
            backgroundRemovalType?: string;
            backgroundRemovalColor?: string;
            upscalingType?: string;
            upscalingCustomValue?: string;
            bgExtensionRatio?: string;
            bgExtensionHeight?: number;
            bgExtensionWidth?: number;
            bgExtensionNotes?: string;
            dropShadowNotes?: string;
            croppingOriginalDimensions?: string;
          };
          quantity: string;
          editingJobService?: {
            id: string;
            slug?: string;
            displayName?: string;
          };
        }[];
      };
      items: {
        cursor: string;
        edges: {
          id: string;
          services?: {
            edges: {
              details?: {
                virtualStagingRoom?: string;
                objectRemovalNotes?: string;
                productRetouchingNotes?: string;
                spotRemovalNotes?: string;
                faceRetouchingNotes?: string;
                brandRemovalNotes?: string;
              };
              editingJobService: {
                id: string;
                slug?: string;
                displayName?: string;
              };
            }[];
          };
          originalImage?: {
            id: string;
            medium?: {
              url?: string;
            };
            fileName?: string;
          };
          finalImage?: {
            id: string;
            medium?: {
              url?: string;
            };
          };
          refunded?: Date;
        }[];
      };
      referenceMedias?: {
        cursor: string;
        edges?: {
          id: string;
          referenceImage: {
            id: string;
            medium: { url: string };
            downloadUrl: string;
          };
        }[];
      };
    };
  }>(
    gql`
      query EditingJobOnDetailsScreen(
        $jobId: ID!
        $cursor: String
        $first: Int
      ) {
        editingJob: editingJobById(id: $jobId) {
          id
          booking {
            id
          }
          displayStatus
          notes
          partner {
            uid
            name
          }
          editor {
            id
            fullName
          }
          servicesSummary {
            edges {
              id
              details {
                notes
                backgroundRemovalType
                backgroundRemovalColor
                upscalingType
                upscalingCustomValue
                croppingRatio
                croppingWidth
                croppingHeight
                croppingLongestEdge
                virtualStagingStyle
                bgExtensionRatio
                bgExtensionHeight
                bgExtensionWidth
                bgBlurNotes
                skyReplacementNotes
                dropShadowNotes
                croppingOriginalDimensions
              }
              quantity
              editingJobService {
                id
                slug
                displayName
                description
                price
              }
            }
          }
          referenceMedias(first: $first, cursor: $cursor) {
            cursor
            edges {
              id
              referenceImage {
                id
                medium {
                  url
                }
                downloadUrl
              }
            }
          }
          items(first: $first, cursor: $cursor) {
            cursor
            edges {
              id
              services {
                edges {
                  id
                  details {
                    virtualStagingRoom
                    objectRemovalNotes
                    spotRemovalNotes
                    faceRetouchingNotes
                    productRetouchingNotes
                    brandRemovalNotes
                  }
                  editingJobService {
                    id
                    slug
                    displayName
                  }
                }
              }
              originalImage {
                id
                medium {
                  url
                }
                fileName
              }
              finalImage {
                id
                medium {
                  url
                }
              }
              refunded
            }
          }
        }
      }
    `,
    {
      variables: {
        first: 5000,
        jobId,
      },
      skip: jobId == null,
    }
  );
}

export function useEditingUploadManager<T>({
  token,
  queue,
}: {
  token: string;
  queue: ZQueue;
}) {
  return useUploadManager<T>({
    token,
    onUploadReady: () => {},
    queue,
  });
}
