import * as React from 'react';
import qs from 'query-string';
import { Switch, Route, RouteComponentProps, Link } from 'react-router-dom';
import Component2 from '@reach/component-component';
import BaseLayout from '../components/BaseLayout';
import { useQuery, gql } from '../components/ApolloClient';

import moment from 'moment-timezone';
import InView from 'react-intersection-observer';
import { ensureString } from '../utils/strings';
import ContentWrapper from '../components/ContentWrapper';

const ELLIPSIS_STYLE = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
} as const;

function TimeDisplay({
  callHistory,
  nowMoment,
}: {
  callHistory: CallHistory;
  nowMoment: moment.Moment;
}) {
  const formatted = React.useMemo(
    () => moment(callHistory.createdAt).tz('UTC').format('YYYY-MM-DD HH:mm'),
    [callHistory.createdAt]
  );

  const fromNow = React.useMemo(
    () => moment(callHistory.createdAt).from(nowMoment),
    [callHistory.createdAt, nowMoment]
  );

  return (
    <>
      {formatted} ({fromNow})
    </>
  );
}

function CopyIdFieldAndLink({ id, to }: { id: string; to: string }) {
  const inputRef = React.createRef<HTMLInputElement>();

  return (
    <div className="input-group">
      <input
        type="text"
        className="form-control"
        readOnly={true}
        ref={inputRef}
        value={id}
        style={{ maxWidth: 320, textOverflow: 'ellipsis' }}
      />
      <div className="input-group-append">
        <button
          style={{ zIndex: 0 }}
          className="btn btn-primary"
          onClick={() => {
            if (inputRef.current != null) {
              inputRef.current.select();
              document.execCommand('copy');
            }
          }}
        >
          <i className="fa fa-copy" aria-hidden="true" />
        </button>
        <div className="btn btn-dark">
          <Link target="_blank" rel="noopener noreferrer" to={to}>
            <i className="fa fa-external-link" aria-hidden="true" />
          </Link>
        </div>
      </div>
    </div>
  );
}

const CallHistoryList = gql`
  query callHistoryListAsAdminQuery(
    $phone: String
    $first: Int
    $cursor: String
  ) {
    callHistoryListAsAdmin(phone: $phone, first: $first, cursor: $cursor) {
      cursor
      edges {
        id
        from
        to
        status
        createdAt
        recordingUrl
        booking {
          id
        }
      }
    }
  }
`;

type CallHistory = {
  id: string;
  from?: number;
  to?: number;
  status?: string;
  createdAt?: string;
  recordingUrl?: string;
  booking?: { id: string };
};

type CallHistoryConnection = {
  edges: CallHistory[];
  cursor: string;
};

type CallHistoryResponse = {
  callHistoryListAsAdmin: CallHistoryConnection;
};

function DataRowsChunk({
  cursor,
  onMore,
  q,
}: {
  cursor: string | null;
  onMore?: (p: { nextCursor: string }) => void;
  q?: string | undefined;
}) {
  const callHistoryListQuery = useQuery<CallHistoryResponse>(CallHistoryList, {
    variables: {
      cursor,
      first: cursor == null ? 20 : 40,
      phone: q,
    },
  });

  const callHistoryList =
    callHistoryListQuery.data?.callHistoryListAsAdmin?.edges;
  const nextCursor = callHistoryListQuery.data?.callHistoryListAsAdmin?.cursor;

  const [nowMoment, setNowMoment] = React.useState(() => moment());

  React.useEffect(() => {
    const interval = setInterval(() => setNowMoment(moment()), 10 * 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {onMore == null || nextCursor == null ? null : (
        <InView>
          {({ inView, ref }) => (
            <tr ref={ref}>
              {inView && <Component2 didMount={() => onMore({ nextCursor })} />}
            </tr>
          )}
        </InView>
      )}

      {callHistoryList == null && callHistoryListQuery.loading
        ? Array.from(Array(7)).map((_, index) => (
            <tr key={'loading-' + index}>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
            </tr>
          ))
        : callHistoryList?.map((callHistory) => (
            <tr key={callHistory.id}>
              <td>{callHistory.from}</td>
              <td>{callHistory.to}</td>
              <td>{callHistory.status}</td>
              <td>
                {callHistory.booking?.id != null ? (
                  <CopyIdFieldAndLink
                    id={callHistory.booking?.id}
                    to={`bookings/${callHistory.booking.id}`}
                  />
                ) : (
                  '-'
                )}
              </td>
              <td title={callHistory.createdAt}>
                <TimeDisplay callHistory={callHistory} nowMoment={nowMoment} />
              </td>
              <td>
                {callHistory.recordingUrl ? (
                  <audio controls src={callHistory.recordingUrl}>
                    Your browser does not support the
                    <code>audio</code> element.
                  </audio>
                ) : (
                  '-'
                )}
              </td>
            </tr>
          ))}

      {onMore == null || nextCursor == null ? null : (
        <InView>
          {({ inView, ref }) => (
            <tr ref={ref}>
              {inView && <Component2 didMount={() => onMore({ nextCursor })} />}
            </tr>
          )}
        </InView>
      )}
    </>
  );
}

export const CallHistoryListRoute = ({
  location,
  history,
}: {
  location: RouteComponentProps['location'];
  history: RouteComponentProps['history'];
}) => {
  const [cursors, setCursors] = React.useState<string[]>([]);
  const q = ensureString(qs.parse(location.search).q);
  const [phone, setPhone] = React.useState(q || '');
  return (
    <div>
      <div
        style={{
          paddingBottom: '10px',
        }}
      >
        <h2>Call history list</h2>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="rounded bg-white mb-2 p-2">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setCursors([]);
                history.push({
                  ...location,
                  search: qs.stringify({
                    ...qs.parse(location.search),
                    q: phone?.trim().length > 0 ? phone.trim() : undefined,
                  }),
                });
              }}
              className="d-flex"
            >
              <input
                style={{ maxWidth: 400 }}
                className="form-control"
                type="text"
                onChange={(e) => setPhone(e.target.value)}
                value={phone}
                placeholder="Search by complete phone number"
              />
            </form>
          </div>
        </div>
      </div>

      <ContentWrapper>
        <div className="table-responsive">
          <table className="table" style={{ position: 'relative' }}>
            <thead style={{ color: '#71767E' }}>
              <tr>
                <th style={ELLIPSIS_STYLE} scope="col">
                  From
                </th>
                <th style={ELLIPSIS_STYLE} scope="col">
                  To
                </th>
                <th style={ELLIPSIS_STYLE} scope="col">
                  Status
                </th>
                <th style={ELLIPSIS_STYLE} scope="col">
                  Booking
                </th>
                <th style={ELLIPSIS_STYLE} scope="col">
                  Date (UTC)
                </th>
                <th style={ELLIPSIS_STYLE} scope="col">
                  Recording
                </th>
              </tr>
            </thead>

            <tbody style={{ color: '#6C757D' }}>
              {[null, ...cursors].map((cursor, index) => (
                <DataRowsChunk
                  key={cursor ?? '0'}
                  cursor={cursor}
                  onMore={
                    index === cursors.length + 1 - 1
                      ? ({ nextCursor }) =>
                          setCursors((state) =>
                            state.includes(nextCursor)
                              ? state
                              : [...state, nextCursor]
                          )
                      : undefined
                  }
                  q={q}
                />
              ))}
            </tbody>
          </table>
        </div>
      </ContentWrapper>
    </div>
  );
};

export function CallHistoryRoute({
  session,
  match,
  match: { path: basePath },
  history,
}) {
  return (
    <BaseLayout session={session} match={match}>
      <Switch>
        <Route
          exact
          path={basePath}
          render={(props) => (
            <CallHistoryListRoute location={props.location} history={history} />
          )}
        />
      </Switch>
    </BaseLayout>
  );
}
