import React from 'react';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';

import { PromiseButton } from '../../../../../components/PromiseButton';
import { Promiser } from '../../../../../components/Promiser';
import { Modal } from '../../../../../components/Modal';
import { apiFetch } from '../../../../../utils';
import { calculateChangesToPatch } from '../../utils';
import { isPartnerBooking } from '../../selectors';
import { ChangesSummarySection } from './ChangesSummarySection';
import { PaymentMethodSelector } from '../../components/PaymentMethodSelector';
import {
  formatCreditsOrMoney,
  notificationIsDismissed,
  infoForBooking,
} from './utils';

const DATE_TIME_DISPLAY_FORMAT_FOR_OVERLAPPING_EVENTS_ALERT =
  'MMM Do YYYY, h:mm:ss a zz';

const chargeIsDismissed = ({ charges, charge }) =>
  !charges.find(({ reason }) => reason === charge.reason);

const chargeIsOther = ({ charge }) => charge.reason === 'admin_other';

export const WarningSection = ({ booking, bookingClone, changedKeys }) => {
  const { warningMessage } = infoForBooking({
    booking,
    bookingClone,
    changedKeys,
  });
  return warningMessage ? (
    <div className="alert alert-warning">{warningMessage}</div>
  ) : null;
};

const Charge = ({ charge, removed, booking }) => (
  <li
    className="d-flex align-items-center"
    style={{ textDecoration: removed && 'line-through' }}
  >
    <span className={`badge badge-${charge.value > 0 ? 'success' : 'danger'}`}>
      {formatCreditsOrMoney({ booking, value: charge.value })}
    </span>

    <span className="ml-1">{charge.description}</span>
  </li>
);

type ChangesModalProps = {
  session: { token: string };
  booking?;
  bookingClone?;
  bookingChangedKeys?;
  onDismiss: () => void;
  onReload: () => Promise<void>;
};

type ChangesModalState = {
  validationPromise: Promise<never> | null;
  drop_charges: string[] | null;
  drop_notifications: string[] | null;
  other_charge: undefined | null;
  paymentMethodToken: string | null;
  providerReallocationReason: string | null;
  overlapping_consent: boolean | undefined;
  outOfProviderServiceAreaConsent: boolean | undefined;
  partyAtFault: string | undefined;
};

export class ChangesModal extends React.Component<
  ChangesModalProps,
  ChangesModalState
> {
  state: ChangesModalState = {
    validationPromise: null,
    drop_charges: null,
    drop_notifications: [],
    other_charge: null,

    paymentMethodToken: null,
    overlapping_consent: undefined,
    outOfProviderServiceAreaConsent: undefined,
    partyAtFault: undefined,
    providerReallocationReason: null,
  };

  componentDidMount() {
    const {
      booking,
      session: { token },
      bookingClone,
      bookingChangedKeys,
    } = this.props;
    this.setState({
      validationPromise: apiFetch(
        `/api/v2/admin/bookings/${booking.uid}/validate-changes`,
        {
          token,
          method: 'POST',
          body: JSON.stringify({
            changes: calculateChangesToPatch({
              bookingClone,
              bookingChangedKeys,
            }),
          }),
        }
      ),
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.drop_charges === this.state.drop_charges &&
      prevState.drop_notifications === this.state.drop_notifications &&
      prevState.other_charge === this.state.other_charge &&
      prevState.overlapping_consent === this.state.overlapping_consent &&
      prevState.outOfProviderServiceAreaConsent ===
        this.state.outOfProviderServiceAreaConsent &&
      prevState.partyAtFault === this.state.partyAtFault &&
      prevState.providerReallocationReason ===
        this.state.providerReallocationReason
    ) {
      return;
    }

    const {
      booking,
      bookingClone,
      session: { token },
      bookingChangedKeys,
    } = this.props;

    this.setState((currentState) => ({
      validationPromise: apiFetch(
        `/api/v2/admin/bookings/${booking.uid}/validate-changes`,
        {
          token,
          method: 'POST',
          body: JSON.stringify({
            changes: calculateChangesToPatch({
              bookingClone,
              bookingChangedKeys,
            }),
            drop_charges:
              this.state.drop_charges == null
                ? undefined
                : this.state.drop_charges,
            other_charge: currentState.other_charge ?? undefined,
            drop_notifications: currentState.drop_notifications,
            conflicting_events_consent: currentState.overlapping_consent,
            out_of_provider_service_area_consent:
              currentState.outOfProviderServiceAreaConsent,
            provider_reallocation_reason:
              currentState.providerReallocationReason ?? undefined,
          }),
        }
      ),
    }));
  }

  toggleCharge = ({ charge, charges }: { charge: any; charges: any }) => {
    this.setState((prevState: { drop_charges; other_charge }) =>
      chargeIsOther({ charge })
        ? {
            other_charge: null,
            drop_charges: prevState.drop_charges,
          }
        : {
            other_charge: prevState.other_charge,
            drop_charges: chargeIsDismissed({
              charge,
              charges,
            })
              ? (prevState.drop_charges ?? []).filter(
                  (reason) => reason !== charge.reason
                )
              : [...(prevState.drop_charges ?? []), charge.reason],
          }
    );
  };

  toggleNotification = ({ notification, notifications }) => {
    this.setState((prevState: { drop_notifications }) => ({
      drop_notifications: notificationIsDismissed({
        notification,
        notifications,
      })
        ? prevState.drop_notifications.filter(
            (type) => type !== notification.type
          )
        : [...prevState.drop_notifications, notification.type],
    }));
  };

  toggleOverlappingConsent = () => {
    this.setState((prevState: { overlapping_consent }) => ({
      overlapping_consent: !prevState.overlapping_consent,
    }));
  };

  toggleOutOfProviderServiceAreaConsent = () => {
    this.setState((prevState: { outOfProviderServiceAreaConsent }) => ({
      outOfProviderServiceAreaConsent: !prevState.outOfProviderServiceAreaConsent,
    }));
  };

  updatePartyAtFault = ({ partyAtFault }: { partyAtFault: string }) => {
    this.setState({ partyAtFault });
  };

  updateProviderReallocationReason = ({
    reason,
  }: {
    reason: string | null;
  }) => {
    this.setState({ providerReallocationReason: reason });
  };

  confirmChanges = ({ validationToken }) => {
    const {
      booking,
      bookingClone,
      session: { token },
      bookingChangedKeys,
      onReload,
      onDismiss,
    } = this.props;

    return apiFetch(`/api/v2/admin/bookings/${booking.uid}/commit-changes`, {
      token,
      method: 'POST',
      body: JSON.stringify({
        changes: calculateChangesToPatch({
          bookingClone,
          bookingChangedKeys,
        }),
        resume_shoot_from_on_hold:
          bookingChangedKeys.internal_notes != null ? false : undefined,
        drop_charges:
          this.state.drop_charges == null ? undefined : this.state.drop_charges,
        other_charge: this.state.other_charge ?? undefined,
        drop_notifications: this.state.drop_notifications,
        token: validationToken,
        paymentMethodToken: this.state.paymentMethodToken ?? undefined,
        provider_reallocation_reason:
          this.state.providerReallocationReason ?? undefined,
        conflicting_events_consent: this.state.overlapping_consent,
        out_of_provider_service_area_consent: this.state
          .outOfProviderServiceAreaConsent,
      }),
    }).then(
      () => onReload().then(() => onDismiss()),
      (err) =>
        err.json().then(
          (err) => {
            const errorMessage = err.errors
              ? err.errors.join('; ')
              : err.toString();
            alert(errorMessage);
          },
          () => {
            alert('Error. Please reload the page.');
          }
        )
    );
  };

  render() {
    const {
      onDismiss,
      bookingChangedKeys,
      booking,
      bookingClone,
      session,
    } = this.props;

    return (
      <Modal onDismiss={onDismiss}>
        <Promiser promise={this.state.validationPromise}>
          {({
            isPending: validationLoading,
            result: {
              charges: {
                original: original_charges = [],
                final: charges = [],
                total: total_charges,
              } = {} as { original?; final?; total? },
              notifications: {
                final: notifications = [],
                original: original_notifications = [],
              } = {},
              reallocation_reasons,
              token: validationToken,
              short_notice_reschedules,
              overlapping_resources: overlappingResources,
              in_provider_service_area: inProviderServiceArea,
              mobilephone_validated: mobilePhoneValidated,
            } = {} as {
              charges?;
              notifications?;
              reallocation_reasons?: {
                reasons: {
                  provider: string[];
                  customer: string[];
                  snappr: string[];
                };
                selected: string | undefined;
              };
              token?;
              short_notice_reschedules?;
              overlapping_resources?: {
                consent: boolean;
                bookings: {
                  id: string;
                  lowerBound: Date;
                  upperBound: Date;
                }[];
                exceptions: {
                  id: string;
                  lowerBound: Date;
                  upperBound: Date;
                }[];
                external_exceptions: {
                  id: string;
                  lowerBound: Date;
                  upperBound: Date;
                }[];
              };
              in_provider_service_area?: { value: boolean; consent?: boolean };
              mobilephone_validated?: string;
            },
          }) => (
            <div className="card my-4">
              <div className="card-header">
                <h4 className="mb-0">Save Changes</h4>
              </div>

              <div
                className="card-body d-flex flex-column"
                style={{ minWidth: '1000px', minHeight: '600px' }}
              >
                <div className="flex-1" style={{ position: 'relative' }}>
                  <WarningSection
                    booking={booking}
                    bookingClone={bookingClone}
                    changedKeys={bookingChangedKeys}
                  />

                  {validationLoading !== true &&
                    mobilePhoneValidated == null &&
                    bookingChangedKeys.partner_customer_mobilephone != null && (
                      <div className="alert alert-danger">
                        Invalid phone number
                      </div>
                    )}

                  <ChangesSummarySection
                    bookingChangedKeys={bookingChangedKeys}
                    booking={booking}
                    bookingClone={bookingClone}
                    session={session}
                  />

                  {this.state.validationPromise && (
                    <React.Fragment>
                      <div className="card mb-3">
                        <div className="card-body">
                          <h5>Charges</h5>

                          <ul className="list-unstyled mb-0">
                            {original_charges.map((charge: { reason? }) => (
                              <Charge
                                charge={charge}
                                booking={bookingClone}
                                key={charge.reason}
                                removed={
                                  !charges.find(
                                    ({ reason }) => reason === charge.reason
                                  )
                                }
                              />
                            ))}
                          </ul>
                        </div>
                      </div>

                      <div className="card mb-3">
                        <div className="card-body">
                          <h5>Information required</h5>

                          {original_charges.map(
                            (charge: { reason?; description? }) => (
                              <div className="form-check" key={charge.reason}>
                                <label className="form-check-label">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={
                                      !chargeIsDismissed({ charge, charges })
                                    }
                                    onChange={() =>
                                      this.toggleCharge({ charge, charges })
                                    }
                                  />

                                  <span>
                                    Charge {charge.description.toLowerCase()}
                                  </span>
                                </label>
                              </div>
                            )
                          )}

                          {!isPartnerBooking({ booking }) &&
                            bookingClone.customer_uid &&
                            total_charges !== 0 && (
                              <div className="mt-2 d-flex justify-content-start align-items-center">
                                <span>Payment method: </span>

                                <div className="w-50 d-inline ml-2">
                                  <PaymentMethodSelector
                                    bookingClone={bookingClone}
                                    session={session}
                                    selectedPaymentMethodToken={
                                      this.state.paymentMethodToken ?? undefined
                                    }
                                    onPaymentMethodTokenChange={({
                                      paymentMethodToken,
                                    }) => this.setState({ paymentMethodToken })}
                                  />
                                </div>
                              </div>
                            )}

                          {reallocation_reasons != null && (
                            <div className="mt-2">
                              <div>
                                <span>Party at fault:</span>

                                {Object.keys(reallocation_reasons.reasons).map(
                                  (partyAtFault: string) => (
                                    <div className="form-check form-check-inline ml-2">
                                      <input
                                        id={partyAtFault}
                                        className="form-check-input"
                                        type="radio"
                                        name={partyAtFault}
                                        value={partyAtFault}
                                        checked={
                                          this.state.partyAtFault ===
                                          partyAtFault
                                        }
                                        onChange={() =>
                                          this.updatePartyAtFault({
                                            partyAtFault,
                                          })
                                        }
                                      />
                                      <label
                                        htmlFor={partyAtFault}
                                        className="form-check-label text-capitalize"
                                      >
                                        {partyAtFault}
                                      </label>
                                    </div>
                                  )
                                )}
                              </div>

                              <div className="form-group mt-3">
                                <label>Reason</label>

                                <select
                                  className="form-control"
                                  value={
                                    this.state.providerReallocationReason ?? ''
                                  }
                                  disabled={this.state.partyAtFault == null}
                                  onChange={({ target: { value } }) =>
                                    this.updateProviderReallocationReason({
                                      reason: value === '' ? null : value,
                                    })
                                  }
                                >
                                  <option key="" value="">
                                    Select a reason
                                  </option>

                                  {this.state.partyAtFault == null
                                    ? null
                                    : reallocation_reasons.reasons[
                                        this.state.partyAtFault
                                      ].map((reason: string) => (
                                        <option key={reason} value={reason}>
                                          {reason}
                                        </option>
                                      ))}
                                </select>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="card mb-3">
                        <div className="card-body">
                          <h5>Notifications</h5>

                          {original_notifications &&
                            original_notifications.length > 0 &&
                            original_notifications.map(
                              (notification: { type?; description? }) => (
                                <div
                                  className="form-check"
                                  key={notification.type}
                                >
                                  <label className="form-check-label">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={
                                        !notificationIsDismissed({
                                          notification,
                                          notifications,
                                        })
                                      }
                                      onChange={() =>
                                        this.toggleNotification({
                                          notification,
                                          notifications,
                                        })
                                      }
                                    />

                                    <span>{notification.description}</span>
                                  </label>
                                </div>
                              )
                            )}
                        </div>
                      </div>
                      <div className="card mb-3">
                        <div className="card-body">
                          <h5>
                            Short notice reschedules:{' '}
                            {short_notice_reschedules ?? 0}
                          </h5>
                        </div>
                      </div>

                      {overlappingResources != null && (
                        <div
                          className="alert alert-danger"
                          style={{ color: '#721c24' }}
                        >
                          <span>
                            Watch out, this photographer has{' '}
                            {overlappingResources.bookings.length +
                              overlappingResources.exceptions.length +
                              overlappingResources.external_exceptions
                                .length}{' '}
                            overlapping event/booking(s) at:
                          </span>

                          <table
                            className="rounded my-2"
                            style={{
                              border: '1px',
                              borderStyle: 'solid',
                              padding: '10px',
                              fontSize: '14px',
                            }}
                          >
                            <thead>
                              <tr>
                                <th className="p-2 text-center">Type</th>
                                <th className="p-2 text-center">Start at</th>
                                <th className="p-2 text-center">End at</th>
                              </tr>
                            </thead>

                            <tbody>
                              {overlappingResources.bookings.map((b) => (
                                <tr
                                  style={{
                                    border: '1px',
                                    borderStyle: 'solid',
                                  }}
                                >
                                  <td className="p-2">{'Booking'}</td>
                                  <td className="p-2">
                                    {moment(b.lowerBound)
                                      .tz(booking.timezone)
                                      .format(
                                        DATE_TIME_DISPLAY_FORMAT_FOR_OVERLAPPING_EVENTS_ALERT
                                      )}
                                  </td>
                                  <td className="p-2">
                                    {moment(b.upperBound)
                                      .tz(booking.timezone)
                                      .format(
                                        DATE_TIME_DISPLAY_FORMAT_FOR_OVERLAPPING_EVENTS_ALERT
                                      )}
                                  </td>
                                </tr>
                              ))}

                              {overlappingResources.exceptions.map((e) => (
                                <tr
                                  style={{
                                    border: '1px',
                                    borderStyle: 'solid',
                                  }}
                                >
                                  <td className="p-2">{'Manual exceptions'}</td>
                                  <td className="p-2">
                                    {moment(e.lowerBound)
                                      .tz(booking.timezone)
                                      .format(
                                        DATE_TIME_DISPLAY_FORMAT_FOR_OVERLAPPING_EVENTS_ALERT
                                      )}
                                  </td>
                                  <td className="p-2">
                                    {moment(e.upperBound)
                                      .tz(booking.timezone)
                                      .format(
                                        DATE_TIME_DISPLAY_FORMAT_FOR_OVERLAPPING_EVENTS_ALERT
                                      )}
                                  </td>
                                </tr>
                              ))}

                              {overlappingResources.external_exceptions.map(
                                (ex) => (
                                  <tr
                                    style={{
                                      border: '1px',
                                      borderStyle: 'solid',
                                    }}
                                  >
                                    <td className="p-2">
                                      {'External exceptions'}
                                    </td>
                                    <td className="p-2">
                                      {moment(ex.lowerBound)
                                        .tz(booking.timezone)
                                        .format(
                                          DATE_TIME_DISPLAY_FORMAT_FOR_OVERLAPPING_EVENTS_ALERT
                                        )}
                                    </td>
                                    <td className="p-2">
                                      {moment(ex.upperBound)
                                        .tz(booking.timezone)
                                        .format(
                                          DATE_TIME_DISPLAY_FORMAT_FOR_OVERLAPPING_EVENTS_ALERT
                                        )}
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>

                          <span style={{ color: '#721c24' }}>
                            See more details in{' '}
                            <Link
                              to={`/providers/${bookingClone.provider_uid}`}
                            >
                              the photographer profile.
                            </Link>
                          </span>
                          <div>
                            <input
                              type="checkbox"
                              checked={overlappingResources.consent}
                              onChange={() => this.toggleOverlappingConsent()}
                            />
                            <label
                              style={{
                                color: '#454F5C',
                                marginLeft: '8px',
                              }}
                            >
                              I am aware that this booking will overlap with
                              other events/bookings, and I want to continue with
                              the change.
                            </label>
                          </div>
                        </div>
                      )}

                      {inProviderServiceArea != null &&
                        inProviderServiceArea.value === false && (
                          <div
                            className="alert alert-danger"
                            style={{ color: '#721c24' }}
                          >
                            <span>
                              Watch out, location selected for this booking is
                              out of the provider service coverage, see more
                              details in the {''}
                              <Link
                                to={`/providers/${bookingClone.provider_uid}`}
                              >
                                photographer profile.
                              </Link>
                            </span>

                            <div>
                              <input
                                type="checkbox"
                                checked={inProviderServiceArea.consent}
                                onChange={() =>
                                  this.toggleOutOfProviderServiceAreaConsent()
                                }
                              />
                              <label
                                style={{
                                  color: '#454F5C',
                                  marginLeft: '8px',
                                }}
                              >
                                I am aware that this booking is out of the
                                provider service area, and this could generate
                                an opt-out, still, I want to continue with the
                                change.
                              </label>
                            </div>
                          </div>
                        )}
                    </React.Fragment>
                  )}

                  {validationLoading && (
                    <div
                      className={[
                        'd-flex flex-columns',
                        'justify-content-center align-items-center',
                      ].join(' ')}
                      style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                      }}
                    >
                      <span>Loading...</span>
                    </div>
                  )}
                </div>

                <div className="d-flex justify-content-between mt-3">
                  <button
                    className="btn btn-secondary mr-2"
                    onClick={onDismiss}
                  >
                    Close
                  </button>

                  <PromiseButton
                    className="btn btn-primary"
                    disabled={
                      validationLoading ||
                      (!isPartnerBooking({ booking }) &&
                        total_charges !== 0 &&
                        this.state.paymentMethodToken == null) ||
                      overlappingResources?.consent === false ||
                      inProviderServiceArea?.consent === false ||
                      (reallocation_reasons != null &&
                        reallocation_reasons.selected == null)
                    }
                    pendingChildren="Processing..."
                    onClick={() => this.confirmChanges({ validationToken })}
                  >
                    Save & Charge{' '}
                    {total_charges != null &&
                      formatCreditsOrMoney({
                        booking: bookingClone,
                        value: total_charges,
                      })}
                  </PromiseButton>
                </div>
              </div>
            </div>
          )}
        </Promiser>
      </Modal>
    );
  }
}
