import moment from 'moment';

import { API_URL } from '../config';
export { apiFetch } from './apiFetch';

const USE_NEW_AUTHORIZATION_SYSTEM =
  JSON.parse(process.env.NEXT_PUBLIC_USE_NEW_AUTHORIZATION_SYSTEM ?? 'true') ===
  true;

export const checkIfSuperAdmin = ({ session }): boolean =>
  session &&
  session.user &&
  session.user.grants?.indexOf('beSuperAdmin') !== -1;

export const checkIfDeveloper = ({ session }): boolean =>
  session && session.user && session.user.grants?.indexOf('beDeveloper') !== -1;

export const checkIfAdmin = ({ session }): boolean =>
  session && session.user && session.user.grants?.indexOf('beAdmin') !== -1;

export const checkIfPhotoReviewer = ({ session }): boolean =>
  session &&
  session.user &&
  session.user.grants?.indexOf('bePhotoReviewer') !== -1;

export const checkIfEditorAssigner = ({ session }): boolean =>
  session &&
  session.user &&
  session.user.grants?.indexOf('beEditorAssigner') !== -1;

export const checkIfContractor = ({ session }): boolean =>
  session &&
  session.user &&
  session.user.grants?.indexOf('beContractor') !== -1;

export const checkIfRecruiter = ({ session }): boolean =>
  session && session.user && session.user.grants?.indexOf('beRecruiter') !== -1;

export const checkIfAdminExternal = ({ session }): boolean =>
  session &&
  session.user &&
  session.user.grants
    ?.map((g) => g.split(':')[0])
    .indexOf('beAdminExternal') !== -1;

export const checkIfAdminOrSuperAdmin = ({ session }): boolean =>
  checkIfAdmin({ session }) || checkIfSuperAdmin({ session });

export const checkIfCanAccess = (
  session: { user?: { IAM?: string[] } } | undefined,
  resource: string
): boolean => {
  if (USE_NEW_AUTHORIZATION_SYSTEM === true) {
    return session?.user?.IAM?.includes(resource) === true;
  }

  const permissionsMap = {
    iam: (session) => checkIfSuperAdmin({ session }),
    providers: (session) =>
      checkIfRecruiter({ session }) || checkIfAdminOrSuperAdmin({ session }),
    providerPoints: (session) => checkIfAdminOrSuperAdmin({ session }),
    bookings: (session) => checkIfAdminOrSuperAdmin({ session }),
    editingJobs: (session) => checkIfAdminOrSuperAdmin({ session }),
    workflowRunsView: (session) => checkIfAdminOrSuperAdmin({ session }),
    productsView: (session) => checkIfAdminOrSuperAdmin({ session }),
    editingTaskQueue: (session) => checkIfAdminOrSuperAdmin({ session }),
    contractorEditingTaskQueue: (session) => checkIfContractor({ session }),
    photoQCQueue: (session) =>
      checkIfPhotoReviewer({ session }) || checkIfSuperAdmin({ session }),
    customers: (session) => checkIfAdminOrSuperAdmin({ session }),
    coupons: (session) => checkIfAdminOrSuperAdmin({ session }),
    enterprise: (session) => checkIfAdminOrSuperAdmin({ session }),
    metrics: (session) =>
      checkIfSuperAdmin({ session }) || checkIfAdminExternal({ session }),
    salesTeam: (session) => checkIfAdminOrSuperAdmin({ session }),
    salesLeadQueue: (session) => checkIfAdminOrSuperAdmin({ session }),
    tools: (session) => checkIfAdminOrSuperAdmin({ session }),
    providerPayouts: (session) => checkIfSuperAdmin({ session }),
    salesClaims: (session) => checkIfSuperAdmin({ session }),
    imageSearch: (session) => checkIfAdminOrSuperAdmin({ session }),
    printJobs: (session) => checkIfAdminOrSuperAdmin({ session }),
    bookingGalleries: (session) => checkIfContractor({ session }),
    qualityControlView: (session) => checkIfAdminOrSuperAdmin({ session }),
  };

  return permissionsMap[resource](session);
};

export const checkIfCanSeeAnythingInTheAdmin = ({
  session,
}: {
  session?: { user?: { IAM?: string[] } };
}): boolean => {
  if (USE_NEW_AUTHORIZATION_SYSTEM === true) {
    return (session?.user?.IAM?.length ?? 0) > 0;
  }

  return (
    (session != null &&
      (checkIfSuperAdmin({ session }) ||
        checkIfDeveloper({ session }) ||
        checkIfAdmin({ session }) ||
        checkIfPhotoReviewer({ session }) ||
        checkIfEditorAssigner({ session }) ||
        checkIfAdminExternal({ session }) ||
        checkIfRecruiter({ session }))) ||
    checkIfContractor({ session })
  );
};

export const formatMoneyCents = (
  value,
  options: Pick<
    Intl.NumberFormatOptions,
    'currency' | 'minimumFractionDigits' | 'maximumFractionDigits'
  > | null
) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    ...(options ?? { currency: 'USD' }),
  });
  return formatter.format(value / 100);
};

export const formatPercentage = (value, options = {}) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'percent',
    ...options,
  });
  return formatter.format(value);
};

export const formatCredits = (value, options = {}) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    ...options,
  });
  return formatter.format(value / 100);
};

export const formatStarttime = (starttime, { format = '' } = {}) =>
  moment(parseInt(starttime, 10) * 1000).format(format);

export const getAvatarUrl = ({ uid }) => `${API_URL}/rpc/v1/avatar/${uid}`;

const URL_REGEX = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
export const stringIsUrl = (string) => URL_REGEX.test(string);

export function convertToCents(val?: number) {
  return val == null ? undefined : Math.round(val * 100);
}

export function formatTimestampToCountdown(props: { deadline: string }) {
  const now = moment();
  const deadline = moment(props.deadline);
  const duration = moment.duration(now.diff(deadline));

  const intervals = [
    { duration: Math.abs(duration.months()), unit: 'month' },
    { duration: Math.abs(duration.days()), unit: 'day' },
    { duration: Math.abs(duration.hours()), unit: 'hour' },
    { duration: Math.abs(duration.minutes()), unit: 'minute' },
  ];

  const relativeTimeString = intervals
    .map(({ duration, unit }) =>
      duration === 0
        ? ''
        : duration === 1
        ? `1 ${unit}`
        : `${duration} ${unit}s`
    )
    .join(' ');

  return now.isBefore(deadline) === true
    ? `in ${relativeTimeString}`
    : `${relativeTimeString} ago`;
}
