/* eslint no-nested-ternary: ['off'] */

import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { EditRoute } from './edit';

export const AbandonedCartsRoute = ({ session, match: { path: basePath } }) => (
  <Switch>
    <Route
      path={`${basePath}/:abandonedCartId`}
      render={(props) => <EditRoute {...props} session={session} />}
    />
  </Switch>
);
