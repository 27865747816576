import moment from 'moment';
import React, { useState } from 'react';

import { gql, useMutation, useQuery } from '../../components/ApolloClient';
import { formatCredits, formatMoneyCents } from '../../utils';
import { ENTERPRISE_AUTO_BILLING_ENABLED } from '../../config';

type PartnerUser = {
  uid: string;
  email: string;
  primaryBillingContactEnabledAt?: string | Date;
};

export function EnterpriseBillingSection({
  session,
  partner,
  location,
}: {
  session: { token: string };
  partner: { name: string; uid: string; currency: string };
  location;
}) {
  const partnerContractQuery = useQuery<{
    partnerById: {
      uid: string;
      activeContract: {
        id: string;
        discountPercentage: number;
        commissionPercentage?: number;
        commitmentCreditsCents: number;
        durationInMonths: string;
        startAt: string;
        expiresAt: string;
        paymentTerms: string;
        firstInvoiceDate: string;
        subscriptionPlanDiscountPercentage: number;
        billingFrequency: string;
        automaticBillingEnabledAt: boolean;
        subscriptionPlan: {
          displayName: string;
          price: number;
        };
      };
    };
  }>(
    gql`
      query PartnerActiveContractForAdmin($partnerId: UUID!) {
        partnerById(uid: $partnerId) {
          uid
          activeContract {
            id
            commitmentCreditsCents
            discountPercentage
            firstInvoiceDate
            billingFrequency
            commissionPercentage
            startAt
            expiresAt
            durationInMonths
            subscriptionFeeCents
            paymentTerms
            subscriptionPlanDiscountPercentage
            automaticBillingEnabledAt
            subscriptionPlan {
              displayName
              price
            }
          }
        }
      }
    `,
    { variables: { partnerId: partner.uid } }
  );
  const partnerUsers = useQuery<{
    partner?: {
      uid;
      users?: {
        edges: PartnerUser[];
      };
    };
  }>(
    gql`
      query PartnerUsersBillingTab($partnerId: UUID!) {
        partner: partnerById(uid: $partnerId) {
          uid
          users {
            edges {
              uid
              email
              primaryBillingContactEnabledAt
            }
          }
        }
      }
    `,
    {
      variables: { partnerId: partner.uid },
    }
  );
  const [
    partnerContractUpdateAsAdmin,
    partnerContractUpdateAsAdminMutation,
  ] = useMutation<
    {
      partnerContractUpdateAsAdmin: {
        success: boolean;
      };
    },
    {
      contractId: string;
      enableAutomaticBilling: boolean;
    }
  >(gql`
    mutation PartnerContractUpdateAsAdmin(
      $contractId: ID!
      $enableAutomaticBilling: Boolean!
    ) {
      partnerContractUpdateAsAdmin(
        input: {
          contractId: $contractId
          enableAutomaticBilling: $enableAutomaticBilling
        }
      ) {
        success
      }
    }
  `);

  const contract = partnerContractQuery.data?.partnerById.activeContract;
  const primaryBillingContact = partnerUsers.data?.partner?.users?.edges.find(
    (user) => user.primaryBillingContactEnabledAt != null
  );
  const [
    automaticallyGeneratedInvoices,
    setAutomaticallyGeneratedInvoices,
  ] = useState<boolean>();

  const isSomethingEdited =
    contract != null &&
    ((contract?.automaticBillingEnabledAt != null &&
      automaticallyGeneratedInvoices === false) ||
      (contract?.automaticBillingEnabledAt == null &&
        automaticallyGeneratedInvoices === true));

  return (
    <>
      <div
        className="mb-3 mt-3"
        style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}
      >
        {contract != null ? (
          <>
            <div className="m-3">
              <h4>Current contract</h4>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  gap: 16,
                }}
              >
                <div>
                  <label>
                    <small className="text-secondary">Contract ID</small>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    readOnly
                    value={contract?.id}
                    // @ts-ignore
                    onClick={(ev) => ev.target.select()}
                  />
                </div>
                <div></div>
                <div>
                  <label>
                    <small className="text-secondary">
                      Commitment in credits
                    </small>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    readOnly
                    value={formatCredits(contract?.commitmentCreditsCents)}
                    // @ts-ignore
                    onClick={(ev) => ev.target.select()}
                  />
                </div>
                <div>
                  <label>
                    <small className="text-secondary">
                      Commitment in {partner.currency}
                    </small>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    readOnly
                    value={formatMoneyCents(
                      (contract?.commitmentCreditsCents ?? 0) *
                        (1 - (contract?.discountPercentage ?? 0) / 100),
                      { currency: partner.currency }
                    )}
                    // @ts-ignore
                    onClick={(ev) => ev.target.select()}
                  />
                </div>
                <div>
                  <label>
                    <small className="text-secondary">Execution date</small>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    readOnly
                    value={moment(contract?.startAt).format('YYYY-MM-DD')}
                    // @ts-ignore
                    onClick={(ev) => ev.target.select()}
                  />
                </div>
                <div>
                  <label>
                    <small className="text-secondary">Term</small>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    readOnly
                    value={`${contract?.durationInMonths} months`}
                    // @ts-ignore
                    onClick={(ev) => ev.target.select()}
                  />
                </div>
                <div>
                  <label>
                    <small className="text-secondary">Credit discount</small>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    readOnly
                    value={`${contract?.discountPercentage} %`}
                    // @ts-ignore
                    onClick={(ev) => ev.target.select()}
                  />
                </div>
                <div>
                  <label>
                    <small className="text-secondary">
                      Referral commissions
                    </small>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    readOnly
                    value={`${contract?.commissionPercentage} %`}
                    // @ts-ignore
                    onClick={(ev) => ev.target.select()}
                  />
                </div>
                <div>
                  <label>
                    <small className="text-secondary">Subscription plan</small>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    readOnly
                    value={`${
                      contract?.subscriptionPlan?.displayName
                    } - ${formatMoneyCents(contract?.subscriptionPlan?.price, {
                      currency: partner.currency,
                    })} `}
                    // @ts-ignore
                    onClick={(ev) => ev.target.select()}
                  />
                </div>
                <div>
                  <label>
                    <small className="text-secondary">
                      Subscription plan discount
                    </small>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    readOnly
                    value={`${contract?.subscriptionPlanDiscountPercentage} %`}
                    // @ts-ignore
                    onClick={(ev) => ev.target.select()}
                  />
                </div>
              </div>
            </div>
            <div className="m-3">
              <h4>Payment details</h4>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  gap: 16,
                }}
              >
                <div>
                  <label>
                    <small className="text-secondary">First invoice date</small>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    readOnly
                    value={moment(contract?.firstInvoiceDate).format(
                      'YYYY-MM-DD'
                    )}
                    // @ts-ignore
                    onClick={(ev) => ev.target.select()}
                  />
                </div>
                <div>
                  <label>
                    <small className="text-secondary">Billing frequency</small>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    readOnly
                    value={contract?.billingFrequency}
                    // @ts-ignore
                    onClick={(ev) => ev.target.select()}
                  />
                </div>
                <div>
                  <label>
                    <small className="text-secondary">Payment terms</small>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    readOnly
                    value={`${contract?.paymentTerms} days`}
                    // @ts-ignore
                    onClick={(ev) => ev.target.select()}
                  />
                </div>
                {ENTERPRISE_AUTO_BILLING_ENABLED === true && (
                  <>
                    <div>
                      <label>
                        <small className="text-secondary">
                          Billing contact
                        </small>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        value={primaryBillingContact?.email ?? 'Not found'}
                        // @ts-ignore
                        onClick={(ev) => ev.target.select()}
                      />
                    </div>
                    <div>
                      <label>
                        <small className="text-secondary">
                          Automatically generated invoices
                        </small>
                      </label>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          type="checkbox"
                          checked={
                            automaticallyGeneratedInvoices != null
                              ? automaticallyGeneratedInvoices === true
                              : contract?.automaticBillingEnabledAt != null
                          }
                          onChange={(e) =>
                            setAutomaticallyGeneratedInvoices(
                              e.target.checked === true
                            )
                          }
                        />
                        <p style={{ margin: 0, paddingLeft: 8 }}>Enabled</p>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div />
            <div
              className="row justify-content-end"
              style={{ margin: 0, paddingRight: 16 }}
            >
              <button
                className="btn btn-primary px-5 "
                onClick={() => {
                  partnerContractUpdateAsAdmin({
                    variables: {
                      contractId: contract.id,
                      enableAutomaticBilling:
                        automaticallyGeneratedInvoices === true,
                    },
                  }).then(() => {
                    partnerContractQuery.refetch();
                  });
                }}
                disabled={
                  !isSomethingEdited ||
                  partnerContractUpdateAsAdminMutation.loading
                }
              >
                Save
              </button>
            </div>
          </>
        ) : partnerContractQuery.loading !== true ? (
          <div className="text-center">No active contract for this company</div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
