import React from 'react';
import styled from 'styled-components';

import searchImage from '../../components/img/search.svg';

const FILTER_INPUT_BORDER_RADIUS = '0.3em';
const FILTER_INPUT_BACKGROUND_COLOR = '#eef2f6';
const FILTER_TEXT_COLOR = '#818a95';

const SearchFieldStyle = styled('form')`
  display: flex;
  flex-wrap: wrap;

  .searchField,
  .magnifyingGlass {
    color: ${FILTER_TEXT_COLOR};
    background-color: ${FILTER_INPUT_BACKGROUND_COLOR};
  }

  .searchField {
    border: none;
    border-top-left-radius: ${FILTER_INPUT_BORDER_RADIUS};
    border-bottom-left-radius: ${FILTER_INPUT_BORDER_RADIUS};
  }

  .magnifyingGlass {
    background-image: url(${searchImage});
    background-repeat: no-repeat;
    background-position: center;
    padding: 1em;
    margin-left: -0.4em;
    border-top-right-radius: ${FILTER_INPUT_BORDER_RADIUS};
    border-bottom-right-radius: ${FILTER_INPUT_BORDER_RADIUS};
  }
`;

export class SearchField extends React.Component {
  render() {
    const { onSearch, ...props } = this.props;
    return (
      <SearchFieldStyle
        onSubmit={(ev) => {
          ev.preventDefault();
          onSearch({ q: this.searchInput.value });
        }}
      >
        <input
          type="text"
          ref={(input) => (this.searchInput = input)}
          placeholder="Search"
          className="form-control"
          {...props}
        />
      </SearchFieldStyle>
    );
  }
}

export const StyledTable = styled.table`
  width: max-content;
  font-size: 14px;

  th {
    color: #b2bcc7;
    white-space: nowrap;
  }

  td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;

    color: #818a95;
    border: none;
  }

  tbody tr:hover {
    background-color: #f3f3f3;
  }

  a {
    color: #1f6fff;
  }
`;
