import React from 'react';
import DeclarativeComponent from '@reach/component-component';
import Loadable from 'react-loadable';
import moment from 'moment-timezone';
import Switch from 'react-switch';

import { Fetcher } from '../../../../../components/Fetcher';
import { Modal } from '../../../../../components/Modal';
import { locationForModal } from '../ModalRoute';
import { SNAPPR_BLUE } from '../../../../../constants';
import { Tooltip } from '../../../../../components/Tooltip';
import { usePromise } from '../../../../../components/usePromise';
import { apiFetch } from '../../../../../utils';
import premiumPackageSvg from '../../../../../img/premium-crown-badge.svg';
import premiumPackageGoldSvg from '../../../../../img/premium-crown-gold.svg';

enum PROVIDER_LEVEL {
  DEFAULT = 'default',
  DISABLED = 'disabled',
  PREMIUM = 'premium',
}

const PhotographersAndAvailabilityGrid = Loadable({
  loader: () =>
    import('./PhotographersAndAvailabilityGrid').then(
      (res) => res.PhotographersAndAvailabilityGrid
    ),
  loading: () => <div className="flex-1" />,
});

const PhotographersAvailabilityLegend = Loadable({
  loader: () =>
    import('./PhotographersAndAvailabilityGrid').then(
      (res) => res.PhotographersAvailabilityLegend
    ),
  loading: () => <div className="flex-1" />,
});

const AvailableIcon = () => (
  <i className="fa fa-calendar text-primary" aria-hidden="true" />
);

const InServiceAreaIcon = () => (
  <i className="fa fa-location-arrow text-primary" aria-hidden="true" />
);

const SpecialistIcon = () => (
  <i className="fa fa-check-circle text-success" aria-hidden="true" />
);

const StarredIcon = () => (
  <i className="fa fa-star text-warning" aria-hidden="true" />
);

const VTHighFidelityIcon = () => (
  <i className="fa fa-cube" aria-hidden="true" />
);

const VTStandardFidelityIcon = () => (
  <i className="fa fa-cubes" aria-hidden="true" />
);

const VideographyIcon = () => (
  <i className="fa fa-play-circle" aria-hidden="true" />
);

const BannedIcon = () => (
  <i
    className="fa fa-ban fa-lg text-danger"
    title="Banned by Snappr"
    aria-hidden="true"
  />
);

const ProviderLevelIcon = () => (
  <i
    className="fa fa-power-off fa-lg text-danger"
    title="Disabled by the photographer"
    aria-hidden="true"
  />
);

const EnterpriseEnabledIcon = () => (
  <i className="fa fa-industry text-primary" aria-hidden="true" />
);

const wait = (time = 1) => new Promise((res) => setTimeout(res, time));
const nonBlockerReduce = async (list, fn, initialResult) => {
  let result = initialResult;

  for (const item of list) {
    await wait();
    result = fn(result, item);
  }

  return result;
};

const KM_TO_MILE = 0.621371;
const formatDistanceForBooking = ({ distance, booking }) =>
  ['United States', 'US'].includes(booking.country)
    ? `${((distance / 1000) * KM_TO_MILE).toFixed(2)} mi`
    : `${(distance / 1000).toFixed(2)} km`;

const EQUIPMENT = {
  smallproduct: 'Small product photography kit',
  largeproduct: 'Large product photography kit',
  offcameralight: 'Portable off camera lighting kit',
  duallight: 'Dual light studio kit',
  portablestudio: 'Portable studio kit',

  tripod: 'Tripod',
  'backdrop-white': 'White backdrop',
  'backdrop-black': 'Black or dark backdrop',
  'backdrop-color': 'Colored backdrop',
  'newborn-props': 'Props for newborn shoots',

  // OTHER_EQUIPMENT_AND_SERVICES
  videography: 'Videography equipment',
  drone: 'Drone photography/videography equipment',
  photobooth: 'Event photo booth',
  studio: 'Studio space with lighting and backdrop',
  '360degree': '360 degree photography equipment',
  floorplan: 'Real estate floorplans',
  matterport: 'Real estate 3D virtual walkthrough with Matterport',
  twilight: 'Real estate twilight photos',
};

const PhotographerRow = ({
  provider,
  selectedProviderUid,
  onPhotographerSelect,
  bookingClone,
  history,
  location,
  modalParams,
}) => {
  const selectedProviderIndex = (
    modalParams.providersForAutoReallocation || []
  ).findIndex((p) => p === provider.uid);

  return (
    <tr className={selectedProviderUid === provider.uid ? 'table-success' : ''}>
      <th>
        <div
          style={{
            display: 'flex',
            position: 'relative',
            alignItems: 'center',
            paddingTop: 5,
          }}
        >
          <input
            type="checkbox"
            checked={(modalParams.providersForAutoReallocation || []).includes(
              provider.uid
            )}
            onChange={({ target: { checked } }) => {
              history.replace(
                locationForModal({
                  location,
                  modal: {
                    ...modalParams,
                    providersForAutoReallocation: checked
                      ? [
                          ...(modalParams.providersForAutoReallocation || []),
                          provider.uid,
                        ]
                      : (modalParams.providersForAutoReallocation || []).filter(
                          (p) => p !== provider.uid
                        ),
                  },
                })
              );
            }}
          />

          <span
            style={{
              fontSize: 8,
              position: 'absolute',
              marginLeft: 15,
            }}
          >
            {selectedProviderIndex === -1 ? '' : selectedProviderIndex + 1}
          </span>
        </div>
      </th>

      <td>
        <button
          className="btn btn-link p-0 text-dark btn-block text-left"
          onClick={() => onPhotographerSelect({ provider_uid: provider.uid })}
          style={{ fontSize: 14 }}
        >
          {provider.name} {provider.available && <AvailableIcon />}{' '}
          {provider.in_service_area && <InServiceAreaIcon />}{' '}
          {provider.specialist && <SpecialistIcon />}{' '}
          {provider.starred && <StarredIcon />}{' '}
          {provider.banned && <BannedIcon />}{' '}
          {provider.provider_level === PROVIDER_LEVEL.DISABLED && (
            <ProviderLevelIcon />
          )}{' '}
          {provider.level === PROVIDER_LEVEL.PREMIUM && (
            <img
              src={premiumPackageGoldSvg}
              alt="premium"
              width={15}
              height={15}
              style={{ marginRight: '3px' }}
            />
          )}{' '}
          {provider.enterprise_enabled && <EnterpriseEnabledIcon />}{' '}
          {provider.virtual_tours_high_fidelity_available && (
            <VTHighFidelityIcon />
          )}{' '}
          {provider.virtual_tours_standard_fidelity_available && (
            <VTStandardFidelityIcon />
          )}{' '}
          {provider.videography_available && <VideographyIcon />}
        </button>
      </td>

      <td>
        <button
          className="btn btn-link p-0 text-dark btn-block text-left"
          onClick={() => onPhotographerSelect({ provider_uid: provider.uid })}
          style={{ fontSize: 14 }}
        >
          {provider.distance_to_shoot &&
            formatDistanceForBooking({
              distance: provider.distance_to_shoot,
              booking: bookingClone,
            })}
        </button>
      </td>

      <td>
        <button
          className="btn btn-link p-0 text-dark btn-block text-left"
          onClick={() => onPhotographerSelect({ provider_uid: provider.uid })}
          style={{ fontSize: 14 }}
        >
          {provider.product_rating == null ? (
            <small className="text-muted">N/A</small>
          ) : (
            provider.product_rating.toFixed(2)
          )}
        </button>
      </td>

      <td>
        <button
          className="btn btn-link p-0 text-dark btn-block text-left"
          onClick={() => onPhotographerSelect({ provider_uid: provider.uid })}
          style={{ fontSize: 14 }}
        >
          {provider.service_rating == null ? (
            <small className="text-muted">N/A</small>
          ) : (
            provider.service_rating.toFixed(2)
          )}
        </button>
      </td>

      <td>
        <button
          className="btn btn-link p-0 text-dark btn-block text-left"
          onClick={() => onPhotographerSelect({ provider_uid: provider.uid })}
          style={{ fontSize: 14 }}
        >
          {provider.provider_reschedule_score == null ? (
            <small className="text-muted">N/A</small>
          ) : (
            provider.provider_reschedule_score.toFixed(2)
          )}
        </button>
      </td>

      <td>
        <button
          className="btn btn-link p-0 text-dark btn-block text-left"
          onClick={() => onPhotographerSelect({ provider_uid: provider.uid })}
          style={{ fontSize: 14 }}
        >
          {provider.provider_cancellation_score == null ? (
            <small className="text-muted">N/A</small>
          ) : (
            provider.provider_cancellation_score.toFixed(2)
          )}
        </button>
      </td>

      <td>
        {provider.public_portfolio_url == null ? (
          <small className="text-dark text-muted" style={{ fontSize: 11.2 }}>
            N/A
          </small>
        ) : (
          <a
            href={provider.public_portfolio_url}
            target="_blank"
            rel="noopener noreferrer"
            style={{ fontSize: 14 }}
          >
            <i className="fa fa-external-link" aria-hidden="true" />
          </a>
        )}
      </td>

      <td align="right">{provider.points}</td>

      <td>{provider.email}</td>

      <td>{provider.mobilephone}</td>
    </tr>
  );
};

type PhotographerSelectionProps = {
  modalParams;
  bookingClone;
  session: { token: string };
  history;
  location;
  reloadBooking;
  onProviderChange: (p: { provider_uid: string }) => void;
};

export const PhotographerSelection: React.FC<PhotographerSelectionProps> = (
  props
) => {
  const {
    bookingClone,
    session,
    history,
    location,
    modalParams,
    onProviderChange,
    reloadBooking,
  } = props;

  const [q, setQ] = React.useState(props.modalParams.q);
  const [providerId, setProviderId] = React.useState(
    props.bookingClone.provider_uid
  );

  React.useEffect(() => {
    setQ(modalParams.q);
  }, [modalParams.q]);

  React.useEffect(() => {
    setProviderId(bookingClone.provider_uid);
  }, [bookingClone.provider_uid]);

  function onRequestClose() {
    history.replace(
      locationForModal({
        location,
        modal: undefined,
      })
    );
  }

  const triggerAutoAllocationPromiser = usePromise();

  const { showTimeAvailability } = modalParams;

  return (
    <Modal onDismiss={onRequestClose}>
      <div className="card my-4">
        <div
          className="card-body d-flex flex-column"
          style={{
            minWidth: '1100px',
            width: '80vw',
            minHeight: '600px',
            height: '80vh',
          }}
        >
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h3 className="m-0">Provider Selection</h3>

            <label
              htmlFor="availability-chart-switch"
              className="d-flex align-items-center"
            >
              <span className="mr-2">Display availability chart</span>

              <Switch
                onChange={(value) =>
                  history.replace(
                    locationForModal({
                      location,
                      modal: {
                        ...modalParams,
                        showTimeAvailability: value,
                      },
                    })
                  )
                }
                onColor={SNAPPR_BLUE}
                checked={showTimeAvailability}
                id="availability-chart-switch"
              />
            </label>
          </div>

          <form
            className="d-flex justify-content-between align-items-center mb-3 flex-column"
            onSubmit={(ev) => {
              ev.preventDefault();
              history.replace(
                locationForModal({
                  location,
                  modal: {
                    ...modalParams,
                    q: q || undefined,
                  },
                })
              );
            }}
          >
            <div className="d-flex justify-content-between align-items-baseline align-self-baseline">
              <span className="mr-2">Accessory:</span>

              <DeclarativeComponent
                initialState={{ accessory: null }}
                modalParams={modalParams}
                didMount={({
                  props: {
                    modalParams: { accessory },
                  },
                  setState,
                }) => setState({ accessory: accessory || null })}
                didUpdate={({
                  props: {
                    modalParams: { accessory },
                  },
                  prevProps,
                  setState,
                }) =>
                  accessory !== prevProps.modalParams.accessory &&
                  setState({ accessory: accessory || null })
                }
              >
                {({ state, props }) => (
                  <select
                    className="form-control mr-2"
                    value={state.accessory || ''}
                    onChange={({ target: { value } }) =>
                      history.replace(
                        locationForModal({
                          location,
                          modal: {
                            ...props.modalParams,
                            accessory: value || null,
                          },
                        })
                      )
                    }
                  >
                    <>
                      <option value="">None</option>

                      {Object.keys(EQUIPMENT).map((key) => (
                        <option key={key} value={key}>
                          {EQUIPMENT[key]}
                        </option>
                      ))}
                    </>
                  </select>
                )}
              </DeclarativeComponent>

              <input
                type="text"
                className="form-control"
                placeholder="Search"
                style={{ maxWidth: 300 }}
                value={q || ''}
                onChange={({ target: { value } }) => setQ(value)}
              />
            </div>

            <div className="flex-1 d-flex align-self-baseline mt-3">
              <span className="mr-2" style={{ width: '75px' }}>
                Filters:
              </span>
              <div>
                <div className="form-check form-check-inline mb-3">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={modalParams.available === false ? false : true}
                      onChange={() =>
                        history.replace(
                          locationForModal({
                            location,
                            modal: {
                              ...modalParams,
                              available:
                                modalParams.available === false
                                  ? undefined
                                  : false,
                            },
                          })
                        )
                      }
                    />
                    Available <AvailableIcon />
                  </label>
                </div>

                <div className="form-check form-check-inline mb-3">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={
                        modalParams.in_service_area === false ? false : true
                      }
                      onChange={() =>
                        history.replace(
                          locationForModal({
                            location,
                            modal: {
                              ...modalParams,
                              in_service_area:
                                modalParams.in_service_area === false
                                  ? undefined
                                  : false,
                            },
                          })
                        )
                      }
                    />
                    In service area <InServiceAreaIcon />
                  </label>
                </div>

                <div className="form-check form-check-inline mb-3">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={modalParams.specialist === false ? false : true}
                      onChange={() =>
                        history.replace(
                          locationForModal({
                            location,
                            modal: {
                              ...modalParams,
                              specialist:
                                modalParams.specialist === false
                                  ? undefined
                                  : false,
                            },
                          })
                        )
                      }
                    />
                    Specialist <SpecialistIcon />
                  </label>
                </div>

                <div className="form-check form-check-inline mb-3">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={
                        modalParams.premium === false ||
                        modalParams.premium == null
                          ? false
                          : true
                      }
                      onChange={() =>
                        history.replace(
                          locationForModal({
                            location,
                            modal: {
                              ...modalParams,
                              premium:
                                modalParams.premium === false ||
                                modalParams.premium == null
                                  ? true
                                  : false,
                            },
                          })
                        )
                      }
                    />
                    Premium{' '}
                    <img
                      src={premiumPackageSvg}
                      alt="premium"
                      width={18}
                      height={18}
                    />
                  </label>
                </div>

                <div className="form-check form-check-inline mb-3">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={
                        modalParams.enterprise_enabled === false ? false : true
                      }
                      onChange={() =>
                        history.replace(
                          locationForModal({
                            location,
                            modal: {
                              ...modalParams,
                              enterprise_enabled:
                                modalParams.enterprise_enabled === false
                                  ? undefined
                                  : false,
                            },
                          })
                        )
                      }
                    />
                    Enterprise <EnterpriseEnabledIcon />
                  </label>
                </div>

                <div className="form-check form-check-inline mb-3">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={modalParams.starred === true ? true : false}
                      onChange={() =>
                        history.replace(
                          locationForModal({
                            location,
                            modal: {
                              ...modalParams,
                              starred:
                                modalParams.starred === true ? false : true,
                            },
                          })
                        )
                      }
                    />
                    Starred <StarredIcon />
                  </label>
                </div>

                <div className="form-check form-check-inline mb-3">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={
                        modalParams.virtual_tours_high_fidelity_available
                      }
                      onChange={() =>
                        history.replace(
                          locationForModal({
                            location,
                            modal: {
                              ...modalParams,
                              virtual_tours_high_fidelity_available: !modalParams.virtual_tours_high_fidelity_available,
                            },
                          })
                        )
                      }
                    />
                    VT High Q <VTHighFidelityIcon />
                  </label>
                </div>

                <div className="form-check form-check-inline mb-3">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={
                        modalParams.virtual_tours_standard_fidelity_available
                      }
                      onChange={() =>
                        history.replace(
                          locationForModal({
                            location,
                            modal: {
                              ...modalParams,
                              virtual_tours_standard_fidelity_available: !modalParams.virtual_tours_standard_fidelity_available,
                            },
                          })
                        )
                      }
                    />
                    VT Standard Q <VTStandardFidelityIcon />
                  </label>
                </div>

                <div className="form-check form-check-inline ">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={modalParams.videography_available}
                      onChange={() =>
                        history.replace(
                          locationForModal({
                            location,
                            modal: {
                              ...modalParams,
                              videography_available: !modalParams.videography_available,
                            },
                          })
                        )
                      }
                    />
                    Videography <VideographyIcon />
                  </label>
                </div>
              </div>
            </div>
          </form>

          {bookingClone &&
          bookingClone.point &&
          bookingClone.shoottype &&
          bookingClone.start_at &&
          bookingClone.timezone &&
          bookingClone.duration ? (
            <Fetcher
              session={session}
              queryToFetch={{
                lat: bookingClone.point[0],
                lng: bookingClone.point[1],

                shoottype: bookingClone.shoottype,
                start_at: bookingClone.start_at,
                timezone: bookingClone.timezone,
                booking_duration: bookingClone.duration,

                premium:
                  modalParams.premium === false || modalParams.premium == null
                    ? false
                    : true,

                specialist: modalParams.specialist === false ? false : true,
                starred: modalParams.starred === true ? true : false,
                available: modalParams.available === false ? false : true,
                in_service_area:
                  modalParams.in_service_area === false ? false : true,

                accessory: modalParams.accessory || undefined,
                enterprise_enabled:
                  modalParams.enterprise_enabled === false ? false : true,
                virtual_tours_high_fidelity_available:
                  modalParams.virtual_tours_high_fidelity_available,
                virtual_tours_standard_fidelity_available:
                  modalParams.virtual_tours_standard_fidelity_available,
                videography_available: modalParams.videography_available,

                q: modalParams.q,
              }}
              urlToFetch="/api/v2/admin/providers/selection"
            >
              {({
                loading: providersLoading,
                response: { results: providers = [] } = {},
              }: {
                loading: boolean;
                response: { results?: { uid: string }[] };
              }) => (
                <Fetcher
                  disabled={!showTimeAvailability || providers.length === 0}
                  session={session}
                  queryToFetch={{
                    photographer_ids: providers.map((p) => p.uid).join(','),
                    start_at: bookingClone.start_at,
                    timezone: bookingClone.timezone,
                    booking_duration: bookingClone.duration,
                  }}
                  urlToFetch="/api/v2/admin/providers/time-availability"
                  onFulfilled={async ({ response }) => {
                    const bookingTimeStart = moment(bookingClone.start_at).tz(
                      bookingClone.timezone
                    );
                    const bookingTimeEnd = bookingTimeStart
                      .clone()
                      .add(bookingClone.duration, 'minutes');

                    return await nonBlockerReduce(
                      Object.keys(response),
                      (res, providerId) => ({
                        ...res,
                        [providerId]: response[providerId].map((slot) => {
                          const slotStartAt = moment(slot.time_range[0]).tz(
                            bookingClone.timezone
                          );
                          const slotEndAt = moment(slot.time_range[1]).tz(
                            bookingClone.timezone
                          );

                          return {
                            ...slot,
                            current_booking_time:
                              slotStartAt.isSameOrAfter(bookingTimeStart) &&
                              slotEndAt.isSameOrBefore(bookingTimeEnd),
                            display_start_time: slotStartAt.format('h:mm a'),
                            display_start_and_end_time: [
                              slotStartAt.format('h:mm a'),
                              slotEndAt.format('h:mm a'),
                            ].join(' - '),
                          };
                        }),
                      }),
                      {}
                    );
                  }}
                >
                  {({
                    loading: providersTimeAvailabilityLoading,
                    response: providersTimeAvailability = {},
                  }) =>
                    showTimeAvailability ? (
                      <PhotographersAndAvailabilityGrid
                        bookingClone={bookingClone}
                        providers={providers}
                        providersLoading={providersLoading}
                        providersTimeAvailability={providersTimeAvailability}
                        providersTimeAvailabilityLoading={
                          providersTimeAvailabilityLoading
                        }
                        selectedProviderUid={providerId}
                        onPhotographerSelect={({ provider_uid }) =>
                          setProviderId(provider_uid)
                        }
                      />
                    ) : (
                      <div
                        className="table-responsive flex-1"
                        style={{ position: 'relative', overflowY: 'auto' }}
                      >
                        <table className="table table-hover table-sm">
                          <thead>
                            <tr>
                              <th></th>
                              <th>Name</th>
                              <th>Distance to shoot</th>

                              {showTimeAvailability ? (
                                <th>Availability</th>
                              ) : (
                                <>
                                  <th>PQ rating</th>
                                  <th>CS rating</th>
                                  <th>Reschedule rating</th>
                                  <th>Completion score</th>
                                  <th>Portfolio</th>
                                  <th align="right">Points</th>
                                  <th>Email</th>
                                  <th>Phone</th>
                                </>
                              )}
                            </tr>
                          </thead>

                          <tbody>
                            {providers.map((provider) => (
                              <PhotographerRow
                                key={provider.uid}
                                history={history}
                                modalParams={modalParams}
                                location={location}
                                provider={provider}
                                bookingClone={bookingClone}
                                selectedProviderUid={providerId}
                                onPhotographerSelect={({ provider_uid }) =>
                                  setProviderId(provider_uid)
                                }
                              />
                            ))}
                          </tbody>
                        </table>

                        {providersLoading && (
                          <div
                            className={[
                              'd-flex flex-columns',
                              'justify-content-center align-items-center',
                            ].join(' ')}
                            style={{
                              position: 'absolute',
                              top: 0,
                              right: 0,
                              bottom: 0,
                              left: 0,
                              backgroundColor: 'rgba(255, 255, 255, 0.5)',
                            }}
                          >
                            <span>Loading...</span>
                          </div>
                        )}
                      </div>
                    )
                  }
                </Fetcher>
              )}
            </Fetcher>
          ) : (
            <div className="flex-1">
              <div className="alert alert-warning">
                Please pick the location, package and start time first.
              </div>
            </div>
          )}

          <div className="d-flex justify-content-between align-items-center mt-3">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onRequestClose}
            >
              Cancel
            </button>

            {showTimeAvailability && <PhotographersAvailabilityLegend />}

            <div className="d-flex justify-content-between align-items-center">
              {(modalParams.providersForAutoReallocation || []).length > 0 && (
                <>
                  <Tooltip
                    label="This will trigger a process like the short notice one to reallocate the shoot"
                    style={{
                      background: 'hsla(0, 0%, 0%, 0.75)',
                      color: 'white',
                      border: 'none',
                      borderRadius: '4px',
                      padding: '0.5em 1em',
                    }}
                    centered
                  >
                    <button
                      className="btn btn-primary"
                      disabled={triggerAutoAllocationPromiser.isPending}
                      onClick={() => {
                        if (
                          window.confirm(
                            'Do you want to initiate an auto-reallocation process for this booking with the selected photographers?'
                          )
                        ) {
                          triggerAutoAllocationPromiser.setPromise(
                            apiFetch(
                              `/api/v2/admin/bookings/${bookingClone.uid}/start-auto-allocation`,
                              {
                                method: 'POST',
                                token: session.token,
                                body: JSON.stringify({
                                  provider_uids:
                                    modalParams.providersForAutoReallocation,
                                }),
                              }
                            )
                              .then(reloadBooking)
                              .then(() =>
                                history.push({
                                  location,
                                  modal: undefined,
                                })
                              )
                          );
                        }
                      }}
                    >
                      {triggerAutoAllocationPromiser.isPending
                        ? 'Triggering...'
                        : 'Trigger auto-reallocation'}
                    </button>
                  </Tooltip>
                  <div style={{ width: 10 }} />
                </>
              )}

              <button
                className="btn btn-primary"
                onClick={() => {
                  onProviderChange({ provider_uid: providerId });
                  history.replace(
                    locationForModal({
                      location,
                      modal: undefined,
                    })
                  );
                }}
                disabled={providerId === bookingClone.provider_uid}
              >
                Select
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
