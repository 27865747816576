import React from 'react';
import qs from 'query-string';
import moment from 'moment-timezone';
import { Link, Route, useLocation } from 'react-router-dom';
import Component2 from '@reach/component-component';
import InView from 'react-intersection-observer';
import { RouteComponentProps } from 'react-router-dom';
import Select from 'react-select';
import produce from 'immer';
import Linkify from 'react-linkify';

import BaseLayout from '../components/BaseLayout';
import {
  gql,
  useMutation,
  useQuery,
  useQueryWithFocusRefresh,
} from '../components/ApolloClient';
import avatarPlaceholder from '../components/img/user-avatar-placeholder.svg';
import { BookingStatusBadge } from '../components/BookingStatusBadge';
import ContentWrapper from '../components/ContentWrapper';
import { ensureString, multiline } from '../utils/strings';
import { ActionsDropdown } from '../components/ActionsDropdown';
import { usePromise } from '../components/usePromise';
import { apiFetch, formatTimestampToCountdown } from '../utils';
import { PillBadgeList } from '../components/PillBadgeList';
import { locationForModal, ModalRoute } from '../components/ModalRoute';
import { Modal } from '../components/Modal';
import { LoadingSpinnerCentered } from '../components/LoadingSpinner';

const WIDTHS = { small: '150px', large: '300px', extraLarge: '400px' };
const META_FETCHING_INTERVAL_MS = 1000 * 60 * 5;

type BookingsWithReallocationPending = {
  id: string;
  customer?: { firstName?: string; lastName?: string };
  shootType?: { displayName: string };
  startAt?: string;
  status?: string;
  price?: number;
  autoAllocationStartedAt?: string;
  autoAllocationStatus?: string;
  slaDeadlineAt?: string;

  partner?: { name: string };

  endCustomerCompany?: string;

  provider?: {
    publicFullName: string;
    avatar?: { faceCentered: { url: string } };
  };
  region?: { currency: string };

  slaIssues?: {
    edges?: {
      type?: string;
      createdAt?: string;
      deadlineAt?: string;
    }[];
  };

  latestPutOnHoldDetails?: {
    id: string;
    operation: string;
    reason?: string;
    createdAt: string;
  };

  overduePopoverInformation: {
    edges?: {
      id: string;
      text: string;
      createdAt: string;
      actionRequiredResponseType?: string;
      type: string;
    }[];
  };
  slaTags?: {
    edges: {
      id: string;
      name: string;
      createdAt: string;
    }[];
  };

  autoReallocationAttempts?: {
    count: number;
    contactedProviders: {
      id: string;
    }[];
  };

  timezone?: string;
};

type ICommentLog = {
  id: string;
  text: string;
  createdAt: string;
  createdBy: {
    id: string;
    fullName: string;
  };
};

type SlaBookingAdminTag = {
  id: string;
  name: string;
};

enum BookingOnHoldReasonsEnum {
  PHOTOGRAPHER = 'PHOTOGRAPHER',
  END_CUSTOMER = 'END_CUSTOMER',
  ENTERPRISE_REP = 'ENTERPRISE_REP',
  UNKNOWN = 'UNKNOWN',
}

enum BookingIssueTypeEnum {
  OVERDUE = 'OVERDUE',
  NO_SHOW = 'NO_SHOW',
  _BLANK = '_BLANK',
  AUTO_REALLOCATION_FAILED = 'AUTO_REALLOCATION_FAILED',
  REALLOCATION_FAILED_MORE_THAN_48 = 'REALLOCATION_FAILED_MORE_THAN_48',
  AUTO_REALLOCATION_FAILED_MORE_THAN_12 = 'AUTO_REALLOCATION_FAILED_MORE_THAN_12',
}

enum BookingReallocationStatusEnum {
  PROVIDER_NOT_FOUND = 'PROVIDER_NOT_FOUND',
  PROVIDER_FOUND = 'PROVIDER_FOUND',
}

const BOOKING_ISSUE_TYPE_FILTER_CODE = 'filter_booking_issue_type';
const BOOKING_REALLOCATION_STATUS_FILTER_CODE =
  'filter_booking_reallocation_status';
const BOOKING_ADMIN_INCLUDE_TAGS_FILTER_CODE = 'booking_admin_include_tags';
const BOOKING_ADMIN_ON_HOLD_REASONS_FILTER_CODE =
  'filter_booking_on_hold_reasons';
const FILTER_ANY_BOOKING_WITH_COMMENTS = 'filter_any_booking_with_comments';
const FILTER_START_AT_LESS_THAN7_DAYS_AGO =
  'filter_start_at_less_than7_days_ago';

const CONTACTED_PROVIDERS_MODAL = 'CONTACTED_PROVIDERS_MODAL';
const BOOKING_CHANGE_RECOMMENDATION_MODAL =
  'BOOKING_CHANGE_RECOMMENDATION_MODAL';

const BOOKING_ID_SEARCH_QUERY_PARAM = 'booking_id_search';

const BOOKING_SLA_FILTERS = ({
  adminTags,
}: {
  adminTags: SlaBookingAdminTag[] | undefined;
}) => [
  {
    code: BOOKING_ADMIN_ON_HOLD_REASONS_FILTER_CODE,
    name: 'Party at fault',
    options: [
      {
        name: 'End customer',
        code: BookingOnHoldReasonsEnum.END_CUSTOMER,
      },
      {
        name: 'Enterprise rep',
        code: BookingOnHoldReasonsEnum.ENTERPRISE_REP,
      },
      {
        name: 'Photographer',
        code: BookingOnHoldReasonsEnum.PHOTOGRAPHER,
      },
      {
        name: 'Unknown',
        code: BookingOnHoldReasonsEnum.UNKNOWN,
      },
    ],
  },

  {
    code: BOOKING_ISSUE_TYPE_FILTER_CODE,
    name: 'Issue type',
    options: [
      {
        name: 'Overdue',
        code: BookingIssueTypeEnum.OVERDUE,
      },
      {
        name: 'No show',
        code: BookingIssueTypeEnum.NO_SHOW,
      },
      {
        name: 'Short notice reallocation failed',
        code: BookingIssueTypeEnum.AUTO_REALLOCATION_FAILED,
      },
      {
        name: 'Reallocation failed for cancellation with more than 48 hours',
        code: BookingIssueTypeEnum.REALLOCATION_FAILED_MORE_THAN_48,
      },
      {
        name: 'Auto reallocation failed for cancellation between 12 - 48 hours',
        code: BookingIssueTypeEnum.AUTO_REALLOCATION_FAILED_MORE_THAN_12,
      },

      {
        name: 'Blank',
        code: BookingIssueTypeEnum._BLANK,
      },
    ],
  },

  {
    code: BOOKING_REALLOCATION_STATUS_FILTER_CODE,
    name: 'Reallocation status',
    options: [
      {
        name: 'Provider found',
        code: BookingReallocationStatusEnum.PROVIDER_FOUND,
      },
      {
        name: 'Provider not found',
        code: BookingReallocationStatusEnum.PROVIDER_NOT_FOUND,
      },
    ],
  },

  {
    code: BOOKING_ADMIN_INCLUDE_TAGS_FILTER_CODE,
    name: 'Include tags',
    options:
      adminTags?.map((tag) => ({
        name: tag?.name,
        code: tag?.name.replace(' ', '_'),
      })) ?? [],
  },
];

const customStyles = {
  valueContainer: (provided, state) => ({
    ...provided,
    marginLeft: 150,
  }),
};

const MultiSelectFilter = ({
  filter,
  onFilter,
  location,
}: {
  filter: {
    name: string;
    code: string;
    options: { name: string; code: string }[];
  };
  location: RouteComponentProps['location'];
  onFilter: (p: { filterCode: string; filterValue?: string }) => void;
}) => {
  const options = filter.options.map((o) => ({ label: o.name, value: o.code }));
  const filterString = ensureString(qs.parse(location.search)[filter.code]);
  const selectedValues = filterString ? filterString.split(',') : [];
  const selectedOptions =
    selectedValues && options.filter((o) => selectedValues.includes(o.value));

  return (
    <div
      style={{
        minWidth: 150,
        marginRight: 16,
        position: 'relative',
        zIndex: 10,
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 7,
          left: 10,
          zIndex: 2,
          textTransform: 'capitalize',
          color: '#C1C8D4',
        }}
      >
        {filter.name}
      </div>
      <div style={{ minWidth: 100 }}>
        <Select
          isMulti
          value={selectedOptions}
          options={options}
          placeholder=""
          styles={customStyles}
          onChange={(selectedOptions) => {
            const selected = (selectedOptions ?? []).map((s) => s.value);

            onFilter({
              filterCode: filter.code,
              filterValue: selected.length > 0 ? selected.join(',') : undefined,
            });
          }}
        />
      </div>
    </div>
  );
};

const CommentLogInput = ({
  onSubmit,
  disabled,
}: {
  onSubmit;
  disabled: boolean;
}) => {
  const [textValue, setTextValue] = React.useState<string | undefined>(
    undefined
  );

  return (
    <div className="d-flex justify-content-between pt-3 align-items-center">
      <textarea
        className="form-control mr-2"
        name="comment"
        id="comment"
        value={textValue ?? ''}
        onChange={({ target }) => setTextValue(target.value)}
        disabled={disabled}
      />
      <button
        className="btn btn-success"
        disabled={disabled}
        onClick={() => {
          onSubmit({ value: textValue });
          setTextValue(undefined);
        }}
      >
        {'Comment'}
      </button>
    </div>
  );
};

const CommentLog = ({ commentLog }: { commentLog: ICommentLog }) => {
  return (
    <div
      key={commentLog.id}
      style={{
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid rgba(0, 0, 0, 0.125)',
        marginBottom: 16,
        borderRadius: 4,
      }}
    >
      <div
        className="d-flex justify-content-between mb-3 align-items-center"
        style={{
          backgroundColor: '#f1f8ff',
          padding: 6,
          flex: 1,
        }}
      >
        <p className="mb-0 text">{commentLog.createdBy.fullName}</p>
        <p className="mb-0 text-secondary">
          {moment(commentLog.createdAt).fromNow()}
        </p>
      </div>

      <div style={{ padding: 6 }}>
        {React.useMemo(
          () => (
            <Linkify>{multiline(commentLog.text)}</Linkify>
          ),
          [commentLog.text]
        )}
      </div>
    </div>
  );
};

export const Comments = ({
  bookingId,
  inView,
}: {
  bookingId: string;
  inView: boolean;
}) => {
  const [loadComments, setLoadComments] = React.useState(false);
  React.useEffect(() => {
    if (inView) setLoadComments(true);
  }, [inView]);

  const bookingSlaCommentsQuery = useQueryWithFocusRefresh<{
    bookingById?: {
      id: string;
      slaComments: { edges: ICommentLog[] };
    };
  }>(
    gql`
      query BookingSlaCommentsQueryAsAdmin($bookingId: ID!) {
        bookingById(id: $bookingId) {
          id
          slaComments {
            edges {
              id
              createdAt
              createdBy {
                fullName
              }
              text
            }
          }
        }
      }
    `,
    {
      variables: {
        bookingId,
      },
      skip: !loadComments,
      skipFocusRefresh: !inView,
    }
  );

  // const shouldPoll = useWindowFocus().focused === true && inView === true;

  // const queryRef = React.useRef(bookingSlaCommentsQuery);
  // React.useEffect(() => {
  //   queryRef.current = bookingSlaCommentsQuery;
  // }, [bookingSlaCommentsQuery]);

  // React.useEffect(() => {
  //   if (shouldPoll === true) queryRef.current.startPolling(5000);
  //   else queryRef.current.stopPolling();

  //   return () => queryRef.current.stopPolling();
  // }, [shouldPoll]);

  const [
    createCommentAsAdmin,
    { loading: createCommentAsAdminLoading },
  ] = useMutation(
    gql`
      mutation BookingSlaCommentLogCreateAsAdmin(
        $object: String!
        $objectId: ID!
        $text: String!
      ) {
        commentLogCreateAsAdmin(
          input: { object: $object, objectId: $objectId, text: $text }
        ) {
          commentLog {
            id
          }
        }
      }
    `
  );

  const commentLogs =
    bookingSlaCommentsQuery.data?.bookingById?.slaComments.edges;

  const onCommentSubmit = ({ comment: text }: { comment: string }) => {
    const object = 'sla_booking';
    createCommentAsAdmin({
      variables: { object, objectId: bookingId, text },
    }).then(() => bookingSlaCommentsQuery.refetch());
  };

  return (
    <div style={{ padding: 6, border: '1px solid rgba(0, 0, 0, 0.125)' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column-reverse',
          maxHeight: 400,
          overflow: 'scroll',
          padding: 6,
        }}
      >
        {commentLogs != null && commentLogs.length > 0
          ? commentLogs.map((commentLog) => (
              <CommentLog key={commentLog.id} commentLog={commentLog} />
            ))
          : null}
      </div>
      <CommentLogInput
        onSubmit={({ value }) => onCommentSubmit({ comment: value })}
        disabled={
          bookingSlaCommentsQuery.loading || createCommentAsAdminLoading
        }
      />
    </div>
  );
};

const CopyIdField = ({ id }: { id: string }) => {
  const editingJobIdInputRef = React.createRef<HTMLInputElement>();

  return (
    <div className="input-group">
      <input
        type="text"
        className="form-control"
        readOnly={true}
        ref={editingJobIdInputRef}
        value={id}
        style={{ maxWidth: 320, textOverflow: 'ellipsis' }}
      />
      <div className="input-group-append">
        <button
          className="btn btn-primary"
          onClick={() => {
            if (editingJobIdInputRef.current != null) {
              editingJobIdInputRef.current.select();
              document.execCommand('copy');
            }
          }}
        >
          <i className="fa fa-copy" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
};

function ContactedProvidersModal({ onDismiss }: { onDismiss: () => void }) {
  const location = useLocation();
  const modalParams = JSON.parse(
    ensureString(qs.parse(location.search).modal) || '{}'
  );

  const bookingsWithContactedProvidersQuery = useQuery<{
    bookingById: {
      id: string;
      autoReallocationAttempts: {
        count: number;
        contactedProviders: {
          id: string;
          publicFullName: string;
        }[];
      };
    };
  }>(
    gql`
      query BookingsWithContactedProvidersQuery($bookingId: ID!) {
        bookingById(id: $bookingId) {
          id
          autoReallocationAttempts {
            count
            contactedProviders {
              id
              publicFullName
            }
          }
        }
      }
    `,
    {
      variables: { bookingId: modalParams.bookingId },
      fetchPolicy: 'network-only',
    }
  );

  const bookingReallocationAttempts =
    bookingsWithContactedProvidersQuery.data?.bookingById
      .autoReallocationAttempts;

  return (
    <Modal onDismiss={onDismiss}>
      <div className="p-4 card" style={{ minWidth: 712 }}>
        <div>
          <h2>Contacted providers</h2>
          <h6
            style={{ paddingLeft: 2 }}
          >{`Booking id: ${modalParams.bookingId}`}</h6>

          <div style={{ height: 16, width: '100%' }} />

          <table className="table">
            <thead>
              <tr>
                <th className="text-muted text-truncate">Id</th>
                <th className="text-muted text-truncate">Name</th>
              </tr>
            </thead>
            <tbody>
              {bookingReallocationAttempts?.contactedProviders.map(
                (provider) => (
                  <tr style={{ whiteSpace: 'nowrap' }}>
                    <td>{provider.id}</td>
                    <td>{provider.publicFullName}</td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
    </Modal>
  );
}

function BookingChangeRecommendationsModal({
  onDismiss,
}: {
  onDismiss: () => void;
}) {
  const location = useLocation();
  const modalParams = JSON.parse(
    ensureString(qs.parse(location.search).modal) || '{}'
  );

  const providerRecommendedChangesListQuery = useQuery<{
    getJobChangeRecommendationAsAdminList: {
      edges: {
        id: string;
        autoAcceptAt?: string;
        createdAt?: string;
        status: string;
        job?: {
          timezone?: string;
        };
        operationDetails?: {
          reason?: string;
          startAt?: string;
        };
        type?: {
          type?: string;
        };
      }[];
    };
  }>(
    gql`
      query BookingRecommendedChangesList($bookingId: String) {
        getJobChangeRecommendationAsAdminList(bookingId: $bookingId) {
          edges {
            id
            autoAcceptAt
            createdAt
            status
            job {
              uid
              timezone
            }

            type {
              type
            }

            operationDetails {
              reason
              startAt
            }
          }
          cursor
        }
      }
    `,
    {
      variables: { bookingId: modalParams.bookingId },
    }
  );

  const providerRecommendedChanges =
    providerRecommendedChangesListQuery.data
      ?.getJobChangeRecommendationAsAdminList.edges;

  return (
    <Modal onDismiss={onDismiss}>
      <div className="p-4 card" style={{ minWidth: 712 }}>
        <div>
          <h2>Booking change recommendations</h2>
          <h6
            style={{ paddingLeft: 2 }}
          >{`Booking id: ${modalParams.bookingId}`}</h6>

          <div style={{ height: 16, width: '100%' }} />

          <table className="table">
            <thead>
              <tr>
                <th className="text-muted text-truncate">Created at</th>
                <th className="text-muted text-truncate">Type</th>
                <th className="text-muted text-truncate">Status</th>

                <th className="text-muted text-truncate">New start at</th>
                <th className="text-muted text-truncate">Reason</th>
              </tr>
            </thead>

            <tbody>
              {providerRecommendedChangesListQuery.loading === true ? (
                <tr>
                  <LoadingSpinnerCentered />
                </tr>
              ) : (
                providerRecommendedChanges?.map((recommendedChange) => (
                  <tr
                    key={recommendedChange.id}
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    <td>
                      {recommendedChange.createdAt != null
                        ? moment(recommendedChange.createdAt)
                            .tz(recommendedChange.job?.timezone ?? 'UTC')
                            .format('YYYY-MM-DD HH:MM z')
                        : 'N/A'}
                    </td>

                    <td>{recommendedChange.type?.type}</td>
                    <td>{recommendedChange.status}</td>

                    <td>
                      {recommendedChange.operationDetails?.startAt != null
                        ? moment(recommendedChange.operationDetails?.startAt)
                            .tz(recommendedChange.job?.timezone ?? 'UTC')
                            .format('YYYY-MM-DD HH:MM z')
                        : 'N/A'}
                    </td>
                    <td>{recommendedChange.operationDetails?.reason}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </Modal>
  );
}

export const BookingTags = ({
  bookingId,
  refetchTagsQuery,
}: {
  bookingId: string;
  refetchTagsQuery: () => void;
}) => {
  const bookingSlaTagsQuery = useQuery<{
    bookingById?: {
      id: string;
      slaTags: { edges: { id: string; name: string; createdAt: string }[] };
    };
  }>(
    gql`
      query getBookingSlaTagQuery($bookingId: ID!) {
        bookingById(id: $bookingId) {
          id
          slaTags {
            edges {
              id
              name
              createdAt
            }
          }
        }
      }
    `,
    {
      variables: {
        bookingId,
      },
    }
  );

  const [objectTagCreation, { loading: createLoading }] = useMutation<
    { data?: { success: boolean } },
    {
      name: string;
      object: string;
      objectId: string;
      type: string;
    }
  >(
    gql`
      mutation ObjectTagCreation(
        $name: String!
        $object: String!
        $objectId: ID!
        $type: String
      ) {
        objectTagCreation(
          input: {
            name: $name
            object: $object
            objectId: $objectId
            type: $type
          }
        ) {
          success
        }
      }
    `
  );

  const [objectTagDeletion, { loading: deleteLoading }] = useMutation<
    { data?: { success: boolean } },
    {
      objectTagId: string;
    }
  >(
    gql`
      mutation ObjectTagDeletion($objectTagId: ID!) {
        objectTagDeletion(input: { objectTagId: $objectTagId }) {
          success
        }
      }
    `
  );

  const loadingTags = deleteLoading || createLoading;

  const handleDeleteTagClick = (tag: {}) => {
    const tagBooking = bookingSlaTagsQuery.data?.bookingById?.slaTags.edges?.filter(
      (filteredBooking) => filteredBooking.name === tag
    );

    if (tagBooking !== undefined && tagBooking.length > 0) {
      objectTagDeletion({
        variables: {
          objectTagId: tagBooking[0].id,
        },
      }).then(() => {
        bookingSlaTagsQuery.refetch();
        refetchTagsQuery();
      });
    }
  };

  const bookingTags = bookingSlaTagsQuery.data?.bookingById?.slaTags.edges;

  return (
    <>
      {loadingTags ? (
        <LoadingSpinnerCentered />
      ) : (
        <PillBadgeList
          items={bookingTags?.map(({ name }) => name) ?? []}
          backgroundColor="#F6F9FF"
          placeholderWhite={true}
          onDelete={handleDeleteTagClick}
          onAdd={(value) =>
            objectTagCreation({
              variables: {
                name: value,
                object: 'booking',
                objectId: bookingSlaTagsQuery.data?.bookingById?.id ?? '',
                type: 'SLA_ADMIN_TAG',
              },
            }).then(() => {
              bookingSlaTagsQuery.refetch();
              refetchTagsQuery();
            })
          }
        />
      )}
    </>
  );
};

function DataRowsChunk({
  session,
  cursor,
  onMore,
  location,
  q,
  refetchTagsQuery,
  onResults,
  getFilteredResults,
  queryFilters,
  rowsCount,
}: {
  session;
  location: RouteComponentProps['location'];
  cursor: string | null;
  onMore?: (p: { nextCursor: string }) => void;
  q?: string;
  refetchTagsQuery: () => void;
  onResults: (p: { results?: BookingsWithReallocationPending[] }) => void;
  getFilteredResults: (p: {
    results: BookingsWithReallocationPending[];
  }) => { results: BookingsWithReallocationPending[] };

  queryFilters: {
    bookingAdminIncludesTagsFilter: string[];
    bookingOnHoldReasonsFilter: string[];
    hasCommentFilter?: boolean;
    omitNonRelevantBookingStatusFilter?: boolean;
  };
  rowsCount: number;
}) {
  const triggerAutoAllocationPromiser = usePromise();

  const bookingsQuery = useQueryWithFocusRefresh<{
    slaBookingsAsAdmin?: {
      cursor?: string;
      edges?: BookingsWithReallocationPending[];
    };
  }>(
    gql`
      query BookingsWithReallocationPending(
        # $query: String
        # $sort: BookingsAsAdminConnectionSortKeys
        $first: Int
        $cursor: String
        $bookingOnHoldReasonsFilter: [String!]
        $bookingAdminIncludesTagsFilter: [String!]
        $hasCommentFilter: Boolean
        $omitNonRelevantBookingStatusFilter: Boolean
      ) {
        slaBookingsAsAdmin(
          # query: $query
          # sort: $sort
          first: $first
          cursor: $cursor
          bookingOnHoldReasonsFilter: $bookingOnHoldReasonsFilter
          bookingAdminIncludesTagsFilter: $bookingAdminIncludesTagsFilter
          hasCommentFilter: $hasCommentFilter
          omitNonRelevantBookingStatusFilter: $omitNonRelevantBookingStatusFilter
        ) {
          cursor

          edges {
            id

            price

            startAt(forceShowAsAdmin: true)
            status
            autoAllocationStartedAt
            autoAllocationStatus
            slaDeadlineAt

            provider {
              publicFullName
              avatar {
                faceCentered {
                  url
                }
              }
            }

            endCustomerCompany

            partner {
              uid
              name
            }

            customer {
              id
              firstName
              lastName
            }
            shootType {
              displayName
            }
            region {
              currency
            }

            slaIssues {
              edges {
                type
                createdAt
                deadlineAt
              }
            }

            latestPutOnHoldDetails {
              id
              operation
              reason
              createdAt
            }

            overduePopoverInformation {
              edges {
                id
                text
                createdAt
                type
                actionRequiredResponseType
              }
            }

            slaTags {
              edges {
                id
                name
                createdAt
              }
            }

            autoReallocationAttempts {
              count
              contactedProviders {
                id
              }
            }
            timezone
          }
        }
      }
    `,
    {
      variables: {
        ...queryFilters,

        cursor,
        first: rowsCount,
      },
      fetchPolicy: 'network-only',
      pollInterval: META_FETCHING_INTERVAL_MS,
      pollIntervalAddJitter: true,
    }
  );

  const onResultsRef = React.useRef(onResults);
  React.useEffect(() => {
    onResultsRef.current = onResults;
  }, [onResults]);

  const results = bookingsQuery.data?.slaBookingsAsAdmin?.edges;

  React.useEffect(() => {
    onResultsRef.current({ results });
  }, [results]);

  const bookings = React.useMemo(
    () =>
      results == null
        ? undefined
        : getFilteredResults({
            results,
          }).results,
    [getFilteredResults, results]
  );

  const nextCursor = bookingsQuery.data?.slaBookingsAsAdmin?.cursor;

  return (
    <>
      {onMore == null || nextCursor == null ? null : (
        <InView>
          {({ inView, ref }) => (
            <tr ref={ref}>
              {inView && <Component2 didMount={() => onMore({ nextCursor })} />}
            </tr>
          )}
        </InView>
      )}

      {bookings == null && bookingsQuery.loading
        ? Array.from(Array(7)).map((i, index) => (
            <tr key={'loading-' + index}>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
            </tr>
          ))
        : bookings?.map((booking) => (
            <InView key={booking.id}>
              {({ inView, ref }) => (
                <tr ref={ref}>
                  {/* Booking id */}
                  <td>
                    <div style={{ display: 'flex' }}>
                      <CopyIdField id={booking?.id} />
                      <div className="btn btn-dark">
                        <Link
                          target="_blank"
                          rel="noopener noreferrer"
                          to={`/booking/${booking.id}`}
                        >
                          <i
                            className="fa fa-external-link"
                            aria-hidden="true"
                          />
                        </Link>
                      </div>
                    </div>
                  </td>

                  {/* Shoot details */}
                  <td>
                    <div className="d-flex flex-column">
                      {booking.partner?.name ? (
                        <div className="font-weight-bold">
                          {'Partner: ' + booking.partner?.name}
                        </div>
                      ) : (
                        <div>
                          <strong>Customer:</strong>{' '}
                          {booking?.customer?.firstName}
                        </div>
                      )}

                      <div>
                        <strong>Shoot time:</strong>{' '}
                        {booking?.startAt == null ? (
                          '-'
                        ) : (
                          <span title={booking?.startAt}>
                            {moment(booking?.startAt).fromNow()}
                          </span>
                        )}
                      </div>

                      <div>
                        <strong>Shoot status:</strong>{' '}
                        {booking?.status == null ? (
                          '-'
                        ) : (
                          <BookingStatusBadge status={booking?.status} />
                        )}
                      </div>

                      <div>
                        <strong>Local date and time</strong>{' '}
                        {booking?.timezone == null ? (
                          '-'
                        ) : (
                          <span>
                            {moment()
                              .tz(booking?.timezone ?? 'UTC')
                              .format('ddd, MMM Do YYYY, h:mm:ss a zz')}
                          </span>
                        )}
                      </div>

                      {booking?.endCustomerCompany && (
                        <div>
                          <strong>Company:</strong>{' '}
                          {booking?.endCustomerCompany}
                        </div>
                      )}

                      {booking?.slaDeadlineAt != null && (
                        <div>
                          <strong>Deadline: </strong>{' '}
                          <span title="Deadline">
                            {formatTimestampToCountdown({
                              deadline: booking?.slaDeadlineAt,
                            })}
                          </span>
                        </div>
                      )}
                    </div>
                  </td>

                  {/* Provider details */}
                  <td>
                    <div className="d-flex">
                      <div>
                        <img
                          style={{
                            objectFit: 'cover',
                            width: 80,
                            height: 80,
                            borderRadius: 40,
                          }}
                          src={
                            booking.provider?.avatar?.faceCentered.url ??
                            avatarPlaceholder
                          }
                          alt="avatar"
                        />
                      </div>

                      <div style={{ width: 32 }} />

                      <div className="flex-grow-1">
                        <div className="font-weight-bold">
                          {booking.provider?.publicFullName != null ? (
                            booking.provider?.publicFullName
                          ) : (
                            <span className="text-danger">
                              No provider allocated
                            </span>
                          )}
                        </div>
                        {/* <div className="font-weight-bold">
                      <strong>Phone:</strong>
                    </div>
                    <div className="font-weight-bold">
                      <strong>Email:</strong>
                    </div> */}
                      </div>
                    </div>
                  </td>

                  {/* Issues */}
                  <td>
                    <div className="d-flex flex-column">
                      {booking?.slaIssues?.edges?.map((slaIssue, index) => (
                        <div key={index} className="pb-3">
                          <strong>{slaIssue.type}</strong>
                          {slaIssue?.createdAt && (
                            <div>
                              <div className="d-inline">Reported at:</div>{' '}
                              <span title={slaIssue?.createdAt}>
                                {moment(slaIssue?.createdAt).fromNow()}
                              </span>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </td>

                  {/* Overdue information */}
                  <td>
                    <div className="d-flex flex-column">
                      {booking?.overduePopoverInformation?.edges?.map(
                        (comment) => (
                          <div key={comment.id} className="pb-3">
                            <div>
                              <strong>Photographer comment:</strong>
                            </div>
                            <span>{comment.text}</span>
                            {comment?.createdAt && (
                              <div>
                                <div>
                                  <strong>Reported at:</strong>
                                </div>
                                <span title={comment?.createdAt}>
                                  {moment(comment?.createdAt).fromNow()}
                                </span>
                              </div>
                            )}
                            {comment?.actionRequiredResponseType && (
                              <div>
                                <div>
                                  <strong>Action response type:</strong>
                                </div>
                                <span
                                  title={comment?.actionRequiredResponseType}
                                >
                                  {comment?.actionRequiredResponseType}
                                </span>
                              </div>
                            )}
                          </div>
                        )
                      )}
                    </div>
                  </td>

                  {/* Reallocation status */}
                  <td>
                    <div className="d-flex flex-column">
                      {booking?.autoAllocationStatus ??
                        booking?.autoAllocationStatus}
                      {booking?.autoAllocationStatus &&
                        booking.autoReallocationAttempts?.count != null && (
                          <span>{`Reallocation attempt: ${booking.autoReallocationAttempts?.count}`}</span>
                        )}

                      {booking.autoReallocationAttempts?.contactedProviders !=
                        null &&
                        booking.autoReallocationAttempts?.contactedProviders
                          .length > 0 && (
                          <Link
                            to={locationForModal({
                              location,
                              modal: {
                                modalName: CONTACTED_PROVIDERS_MODAL,
                                bookingId: booking.id,
                              },
                            })}
                          >
                            Providers
                          </Link>
                        )}
                    </div>
                  </td>

                  {/* Latest put on hold reasons */}
                  <td>
                    <div className="d-flex flex-column">
                      {booking?.latestPutOnHoldDetails != null && (
                        <div>
                          <strong>
                            {booking.latestPutOnHoldDetails.operation}
                          </strong>
                          <div>
                            <div>Created at:</div>{' '}
                            <span
                              title={booking.latestPutOnHoldDetails?.createdAt}
                            >
                              {moment(
                                booking.latestPutOnHoldDetails?.createdAt
                              ).fromNow()}
                            </span>
                          </div>
                          <div>
                            <div>Reason:</div>{' '}
                            {booking.latestPutOnHoldDetails?.reason}
                          </div>
                        </div>
                      )}
                    </div>
                  </td>

                  {/* Tags */}
                  <td>
                    <div className="d-flex flex-column">
                      <BookingTags
                        bookingId={booking.id}
                        refetchTagsQuery={refetchTagsQuery}
                      />
                    </div>
                  </td>

                  {/* Notes */}
                  <td>
                    <div className="d-flex flex-column">
                      <Comments bookingId={booking.id} inView={inView} />
                    </div>
                  </td>

                  <td>
                    <Link
                      to={locationForModal({
                        location,
                        modal: {
                          modalName: BOOKING_CHANGE_RECOMMENDATION_MODAL,
                          bookingId: booking.id,
                        },
                      })}
                    >
                      Recommendations
                    </Link>
                  </td>

                  {/* Actions */}
                  <td className="text-right">
                    <ActionsDropdown width={200}>
                      {({ onClose }) => (
                        <div style={{ textAlign: 'left' }}>
                          <Link
                            className="btn"
                            to={`/booking/${booking.id}`}
                            target="_blank"
                          >
                            Manual reallocate
                          </Link>
                          <button
                            className="btn"
                            disabled={
                              triggerAutoAllocationPromiser.isPending ||
                              bookingsQuery.loading
                            }
                            onClick={() => {
                              if (
                                window.confirm(
                                  'Do you want to initiate an auto-reallocation process for this booking?'
                                )
                              ) {
                                triggerAutoAllocationPromiser.setPromise(
                                  apiFetch(
                                    `/api/v2/admin/bookings/${booking.id}/start-auto-allocation`,
                                    { method: 'POST', token: session.token }
                                  ).then(bookingsQuery.refetch)
                                );
                              }
                            }}
                          >
                            Auto reallocate
                          </button>
                        </div>
                      )}
                    </ActionsDropdown>
                  </td>
                </tr>
              )}
            </InView>
          ))}

      {onMore == null || nextCursor == null ? null : (
        <InView>
          {({ inView, ref }) => (
            <tr ref={ref}>
              {inView && <Component2 didMount={() => onMore({ nextCursor })} />}
            </tr>
          )}
        </InView>
      )}
    </>
  );
}

export const BookingSlaQueueListRoute = ({
  location,
  session,
  history,
}: {
  location: RouteComponentProps['location'];
  session: { token: string; uid: string };
  history;
}) => {
  const [cursors, setCursors] = React.useState<string[]>([]);
  const [resultsPerCursor, setResultsPerCursor] = React.useState<{
    [cursor: string]:
      | { results?: BookingsWithReallocationPending[] }
      | undefined;
  }>({});

  const bookingIdSearch = (() => {
    const _q = ensureString(
      qs.parse(location.search)[BOOKING_ID_SEARCH_QUERY_PARAM]
    );

    return _q === '' ? undefined : _q;
  })();

  const bookingsCountQuery = useQueryWithFocusRefresh<{
    slaBookingsAsAdmin?: {
      total?: number;
    };
  }>(
    gql`
      query BookingsSlaCount {
        slaBookingsAsAdmin {
          total
        }
      }
    `,
    {
      pollInterval: META_FETCHING_INTERVAL_MS,
      pollIntervalAddJitter: true,
    }
  );

  const issueTypesInUrl = ensureString(
    qs.parse(location.search)[BOOKING_ISSUE_TYPE_FILTER_CODE]
  );
  const reallocationStatusInUrl = ensureString(
    qs.parse(location.search)[BOOKING_REALLOCATION_STATUS_FILTER_CODE]
  );

  const anyBookingWithComments =
    ensureString(
      qs.parse(location.search)[FILTER_ANY_BOOKING_WITH_COMMENTS]
    ) === 'true';

  const startAtLessThan7DaysAgo =
    ensureString(
      qs.parse(location.search)[FILTER_START_AT_LESS_THAN7_DAYS_AGO]
    ) === 'true';

  const bookingAdminIncludesTagsFilterString = ensureString(
    qs.parse(location.search)[BOOKING_ADMIN_INCLUDE_TAGS_FILTER_CODE]
  );
  const bookingOnHoldReasonsFilterString = ensureString(
    qs.parse(location.search).filter_booking_on_hold_reasons
  );

  const queryFilters = React.useMemo(
    () => ({
      bookingAdminIncludesTagsFilter:
        bookingAdminIncludesTagsFilterString?.replace('_', ' ').split(',') ??
        [],
      bookingOnHoldReasonsFilter:
        bookingOnHoldReasonsFilterString?.split(',') ?? [],
      hasCommentFilter: anyBookingWithComments,
      omitNonRelevantBookingStatusFilter: !anyBookingWithComments,
    }),
    [
      anyBookingWithComments,
      bookingAdminIncludesTagsFilterString,
      bookingOnHoldReasonsFilterString,
    ]
  );

  React.useEffect(() => {
    setCursors([]);
    setResultsPerCursor({});
  }, [queryFilters]);

  const getFilteredResults = React.useCallback(
    (r: { results: BookingsWithReallocationPending[] }) => {
      const issueTypesList = issueTypesInUrl?.split(',');
      const reallocationStatusList = reallocationStatusInUrl?.split(',');

      const _bookings = r.results;

      const bookingIds = bookingIdSearch?.split(',');

      return {
        results: _bookings
          ?.filter(
            (r) =>
              issueTypesList == null ||
              r.slaIssues?.edges?.find(
                (i) =>
                  i.type != null && issueTypesList?.includes(i.type) === true
              ) != null
          )
          ?.filter(
            (r) =>
              reallocationStatusList == null ||
              (r.autoAllocationStatus != null &&
                reallocationStatusList.includes(r.autoAllocationStatus) ===
                  true)
          )
          ?.filter(
            (r) =>
              bookingIds == null ||
              bookingIds.find((id) => r.id.includes(id)) != null
          )
          ?.filter((r) =>
            startAtLessThan7DaysAgo === false
              ? true
              : r.startAt != null &&
                moment().subtract(7, 'days').isBefore(moment(r.startAt))
          ),
      };
    },
    [
      issueTypesInUrl,
      reallocationStatusInUrl,
      bookingIdSearch,
      startAtLessThan7DaysAgo,
    ]
  );

  const sizeWithFilters = React.useMemo(
    () =>
      getFilteredResults({
        results: Object.values(resultsPerCursor).flatMap(
          (r) => r?.results ?? []
        ),
      }).results.length,
    [getFilteredResults, resultsPerCursor]
  );

  const slaBookingAdminTagsQuery = useQuery<{
    slaBookingAdminTagsAsAdmin?: {
      edges?: SlaBookingAdminTag[];
    };
  }>(gql`
    query SlaBookingAdminTagsAsAdmin {
      slaBookingAdminTagsAsAdmin {
        edges {
          id
          name
        }
      }
    }
  `);

  const adminTags =
    slaBookingAdminTagsQuery.data?.slaBookingAdminTagsAsAdmin?.edges;

  return (
    <div>
      <div style={{ fontWeight: 'bold', fontSize: 22, marginBottom: 16 }}>
        Bookings SLA queue
      </div>

      {/* <div className="row">
        <div className="col-12">
          <div className="rounded bg-white mb-2 p-2">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                history.push({
                  ...location,
                  search: qs.stringify({ q: searchInputRef.current?.value }),
                });
              }}
              className="d-flex"
            >
              <input
                defaultValue={q}
                type="text"
                className="form-control"
                style={{ maxWidth: 300 }}
                placeholder="Search "
                ref={searchInputRef}
              />
            </form>
          </div>
        </div>
      </div> */}

      <ContentWrapper>
        <div className="table-responsive">
          <div>
            <input
              type="text"
              className="form-control"
              style={{ maxWidth: 300 }}
              placeholder="Booking ID"
              value={bookingIdSearch ?? ''}
              onChange={({ target: { value } }) => {
                history.replace({
                  ...location,
                  search: qs.stringify({
                    ...qs.parse(location.search),
                    [BOOKING_ID_SEARCH_QUERY_PARAM]:
                      value === '' ? undefined : value,
                  }),
                });
              }}
            />
          </div>

          <div className="pt-2" />

          <div className="d-flex align-items-center justify-content-between pb-3">
            <div className="d-flex align-items-center">
              {BOOKING_SLA_FILTERS({ adminTags })?.map((filter) => (
                <MultiSelectFilter
                  key={filter.code}
                  onFilter={({ filterCode, filterValue }) => {
                    history.push({
                      ...location,
                      search: qs.stringify({
                        ...qs.parse(location.search),
                        [filterCode]: filterValue,
                      }),
                    });
                  }}
                  filter={filter}
                  location={location}
                />
              ))}

              <div className="d-flex flex-column pl-3">
                <div style={{ position: 'relative' }}>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="couponEnabledCheckbox"
                    checked={anyBookingWithComments}
                    onChange={({ target: { checked } }) =>
                      history.push({
                        ...location,
                        search: qs.stringify({
                          ...qs.parse(location.search),
                          [FILTER_ANY_BOOKING_WITH_COMMENTS]:
                            checked === true ? 'true' : undefined,
                        }),
                      })
                    }
                  />
                  <label
                    className="form-check-label"
                    htmlFor="couponEnabledCheckbox"
                  >
                    Show any booking with comments
                  </label>
                </div>

                <div style={{ position: 'relative' }}>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="filterStartAtLessThan7DaysAgo"
                    checked={startAtLessThan7DaysAgo}
                    onChange={({ target: { checked } }) =>
                      history.push({
                        ...location,
                        search: qs.stringify({
                          ...qs.parse(location.search),
                          [FILTER_START_AT_LESS_THAN7_DAYS_AGO]:
                            checked === true ? 'true' : undefined,
                        }),
                      })
                    }
                  />
                  <label
                    className="form-check-label"
                    htmlFor="filterStartAtLessThan7DaysAgo"
                  >
                    Start at less than 7 days ago
                  </label>
                </div>
              </div>
            </div>

            <div>
              <div>
                Size of queue:{' '}
                {bookingsCountQuery.data?.slaBookingsAsAdmin?.total}
              </div>

              <div>Size with filters: {sizeWithFilters}</div>
            </div>
          </div>

          <table
            className="table table-hover mb-0"
            style={{ overflowX: 'auto' }}
          >
            <thead>
              <tr>
                <th style={{ minWidth: WIDTHS.large }}>Booking id</th>
                <th style={{ minWidth: WIDTHS.large }}>Shoot details </th>
                <th style={{ minWidth: WIDTHS.large }}>Provider</th>
                <th style={{ minWidth: WIDTHS.small }}>Issue(s) </th>
                <th>Overdue information</th>
                <th style={{ minWidth: WIDTHS.small }}>Reallocation status</th>
                <th style={{ minWidth: WIDTHS.small }}>
                  Latest put on hold reason
                </th>
                <th style={{ minWidth: WIDTHS.large }}>Tags</th>
                <th style={{ minWidth: WIDTHS.extraLarge }}>Notes </th>
                <th>Change recommendations </th>
                <th className="text-right" style={{ minWidth: WIDTHS.small }}>
                  Actions
                </th>

                <th></th>
              </tr>
            </thead>

            <tbody>
              {[null, ...cursors].map((cursor, index) => (
                <DataRowsChunk
                  session={session}
                  key={cursor ?? '0'}
                  cursor={cursor}
                  location={location}
                  refetchTagsQuery={slaBookingAdminTagsQuery.refetch}
                  onMore={
                    index === cursors.length + 1 - 1
                      ? ({ nextCursor }) =>
                          setCursors((state) =>
                            state.includes(nextCursor)
                              ? state
                              : [...state, nextCursor]
                          )
                      : undefined
                  }
                  q={bookingIdSearch}
                  onResults={(p) => {
                    setResultsPerCursor((prev) =>
                      produce(prev, (next) => {
                        next[cursor ?? '0'] = {
                          results: p.results,
                        };
                      })
                    );
                  }}
                  getFilteredResults={getFilteredResults}
                  queryFilters={queryFilters}
                  // rowsCount={index === 0 ? 50 : 300}
                  rowsCount={50}
                />
              ))}
            </tbody>
          </table>

          <ModalRoute modalName={CONTACTED_PROVIDERS_MODAL}>
            {(routeProps) => {
              return (
                <ContactedProvidersModal
                  onDismiss={() =>
                    history.push(
                      locationForModal({
                        location,
                        modal: undefined,
                      })
                    )
                  }
                />
              );
            }}
          </ModalRoute>

          <ModalRoute modalName={BOOKING_CHANGE_RECOMMENDATION_MODAL}>
            {(routeProps) => (
              <BookingChangeRecommendationsModal
                onDismiss={() =>
                  history.push(
                    locationForModal({
                      location,
                      modal: undefined,
                    })
                  )
                }
              />
            )}
          </ModalRoute>
        </div>
      </ContentWrapper>
    </div>
  );
};

export function BookingSlaQueueRoute({
  session,
  match,
  match: { path: basePath, url: baseUrl },
  history,
}) {
  return (
    <BaseLayout session={session} match={match}>
      <Route
        path={basePath}
        render={(props) => (
          <BookingSlaQueueListRoute
            location={props.location}
            session={session}
            history={history}
          />
        )}
      />
    </BaseLayout>
  );
}
