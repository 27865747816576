import React, { Fragment } from 'react';
import qs from 'query-string';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';

import { BookingStatusBadge } from '../../components/BookingStatusBadge';
import { Fetcher } from '../../components/Fetcher';
import { Pagination } from '../../components/Pagination';
import ContentWrapper from '../../components/ContentWrapper';
import { SearchField, StyledTable } from './components';

const BOOKINGS_PER_PAGE = 20;

const BOOKINGS_PATH = '/bookings';

const StyledTd = ({ title, ...rest }) => (
  <td title={title} style={{ verticalAlign: 'middle' }}>
    <span className="text-secondary d-inline-block text-truncate" {...rest} />
  </td>
);

export const ListPartnersShootsRoute = ({ history, session, location }) => (
  <Fetcher
    urlToFetch="/api/v2/partners/bookings/summary"
    session={session}
    queryToFetch={{
      limit: BOOKINGS_PER_PAGE,
      q: qs.parse(location.search).q || undefined,
      offset: qs.parse(location.search).offset || undefined,
    }}
  >
    {({
      loading: bookingsLoading,
      response: { results: bookings = [], count: bookingsCount } = {},
    }) => (
      <Fragment>
        <ContentWrapper>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="d-flex justify-content-between align-items-center">
              <Link to={`${BOOKINGS_PATH}/new`} className="btn btn-primary">
                New Shoot
              </Link>
            </div>

            <SearchField
              onSearch={({ q }) =>
                history.push({
                  ...location,
                  search: qs.stringify({
                    ...qs.parse(location.search),
                    offset: undefined,
                    q: q || undefined,
                  }),
                })
              }
              defaultValue={qs.parse(location.search).q || ''}
            />
          </div>

          <div
            className="table-responsive"
            style={{
              position: 'relative',
            }}
          >
            <table className="table table-hover">
              <thead>
                <tr>
                  <th className="text-muted text-truncate">Status</th>
                  <th className="text-muted text-truncate">{'Date & time'}</th>
                  <th className="text-muted text-truncate">Partner</th>
                  <th className="text-muted text-truncate">Customer name</th>
                  <th className="text-muted text-truncate">Shoot Address</th>
                  <th className="text-muted text-truncate">Photographer</th>
                  <th className="text-muted text-truncate">Rep</th>
                  <th className="text-muted text-truncate">Company</th>
                </tr>
              </thead>

              <tbody>
                {bookings.map((bookingSummary) => (
                  <tr key={bookingSummary.uid}>
                    <StyledTd title={bookingSummary.uid}>
                      <BookingStatusBadge status={bookingSummary.status} />
                    </StyledTd>
                    <StyledTd title={bookingSummary.starttime}>
                      <Link
                        to={`${BOOKINGS_PATH}/${bookingSummary.uid}`}
                        className="text-muted"
                      >
                        <u>
                          {bookingSummary.starttime &&
                            moment(
                              parseInt(bookingSummary.starttime, 10) * 1000
                            ).format('D, MMMM YYYY Z')}
                        </u>
                      </Link>
                    </StyledTd>

                    <StyledTd>
                      <Link
                        to={`${BOOKINGS_PATH}/${bookingSummary.uid}`}
                        className="text-muted"
                      >
                        {bookingSummary.partner_name}
                      </Link>
                    </StyledTd>

                    <StyledTd>
                      <Link
                        to={`${BOOKINGS_PATH}/${bookingSummary.uid}`}
                        className="text-muted"
                      >
                        {bookingSummary.customer_name}
                      </Link>
                    </StyledTd>
                    <StyledTd>
                      <Link
                        to={`${BOOKINGS_PATH}/${bookingSummary.uid}`}
                        className="text-muted"
                      >
                        {bookingSummary.address}
                      </Link>
                    </StyledTd>
                    <StyledTd>
                      <Link
                        to={`${BOOKINGS_PATH}/${bookingSummary.uid}`}
                        className="text-muted"
                      >
                        {bookingSummary.photographer_name}
                      </Link>
                    </StyledTd>
                    <StyledTd>
                      <Link
                        to={`${BOOKINGS_PATH}/${bookingSummary.uid}`}
                        className="text-muted"
                      >
                        {bookingSummary.representative_name}
                      </Link>
                    </StyledTd>
                    <StyledTd>
                      <Link
                        to={`${BOOKINGS_PATH}/${bookingSummary.uid}`}
                        className="text-muted"
                      >
                        {bookingSummary.customer_company}
                      </Link>
                    </StyledTd>
                  </tr>
                ))}
              </tbody>
            </table>

            {bookingsLoading && (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'rgba(255,255,255,0.8)',
                  margin: '0 15px',
                }}
              >
                <p>Loading...</p>
              </div>
            )}
          </div>
        </ContentWrapper>

        <div className="d-flex justify-content-end">
          <Pagination
            limit={BOOKINGS_PER_PAGE}
            offset={parseInt(qs.parse(location.search).offset || '0', 10)}
            count={bookingsCount}
            linkForOffset={(offset, { disabled, ...props }) => (
              <Link
                className="page-link"
                to={{
                  ...location,
                  search: qs.stringify({
                    ...qs.parse(location.search),
                    offset: offset || undefined,
                  }),
                }}
                {...props}
              />
            )}
          />
        </div>
      </Fragment>
    )}
  </Fetcher>
);
