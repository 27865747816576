import React from 'react';
import { apiFetch } from '../utils';

export class Fetcher extends React.Component {
  state = {
    response: null,
    loading: !this.props.disabled,
    error: null,
    reloadAt: Date.now(),
  };

  componentDidMount() {
    this.fetch(this.props, this.state);
  }

  componentWillUpdate(nextProps, nextState) {
    this.fetch(nextProps, nextState);
  }

  componentWillUnmount() {
    this._key = null;
  }

  fetch(
    {
      disabled,
      session: { token },
      urlToFetch,
      queryToFetch = {},
      getKey = null,
    },
    { reloadAt }
  ) {
    if (disabled) return Promise.resolve();
    const key =
      (getKey
        ? getKey()
        : [urlToFetch, JSON.stringify(queryToFetch)].join(':')) + reloadAt;

    if (this._key === key) return Promise.resolve();
    this._key = key;

    this.setState({ loading: true });

    return apiFetch(urlToFetch, { token, query: queryToFetch })
      .then((response) => {
        if ('processResponse' in this.props) {
          return this.props.processResponse({ response });
        }
        return response;
      })
      .then((response) => {
        if (this._key !== key) return;
        if ('onFulfilled' in this.props) {
          return Promise.resolve()
            .then(() => this.props.onFulfilled({ response }))
            .then(
              (newResponse) =>
                this._key === key &&
                this.setState({
                  response: newResponse != null ? newResponse : response,
                  loading: false,
                })
            );
        }
        this.setState({ response, loading: false });
      })
      .catch((error) => this.setState({ error, loading: false }));
  }

  render() {
    const render = this.props.children || this.props.render;
    return render({
      response: this.state.response || undefined,
      loading: this.state.loading,
      error: this.state.error,
      reload: () => this.setState({ reloadAt: Date.now() }),
    });
  }
}
