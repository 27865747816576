import React from 'react';
import {
  gql,
  useMutation,
  useQuery,
} from '../../../../components/ApolloClient';
import { ReadOnlyInput } from './CustomerDetails';
import { LoadingSpinnerCentered } from '../../../../components/LoadingSpinner';

enum RequestParticipantDataFieldEnum {
  EMAIL = 'EMAIL',
  MOBILEPHONE_VALIDATED = 'MOBILEPHONE_VALIDATED',
  MOBILEPHONE = 'MOBILEPHONE',
}

export function PartnerBookingParticipants({
  bookingId,
}: {
  bookingId: string;
  onPartnerCustomerEmailChange: (p: { partner_customer_email: string }) => void;
  onPartnerCustomerMobilephoneChange: (p: {
    partner_customer_mobilephone: string;
  }) => void;
}) {
  const participantsQuery = useQuery<{
    bookingById?: {
      id: string;
      contacts: {
        id: string;
        email?: string;
        firstName?: string;
        role?: string;
        phoneNumberValidated?: string;
        lastName?: string;
        websiteUrl?: string;
        googleMapsUrl?: string;
        openingHours?: string[];
        internationalPhoneNumber?: string;
      }[];
    };
  }>(
    gql`
      query PartnerBookingParticipantsAsAdmin(
        $bookingId: ID!
        $filterSkippedContacts: Boolean
      ) {
        bookingById(id: $bookingId) {
          id

          contacts(filterSkippedContacts: $filterSkippedContacts) {
            id
            firstName
            lastName
            role
          }
        }
      }
    `,
    {
      variables: { bookingId, filterSkippedContacts: true },
      skip: bookingId == null,
    }
  );

  const participants = participantsQuery.data?.bookingById?.contacts?.filter(
    (contact) => contact.role !== 'Google maps details'
  );

  if (participants == null || participants.length < 1) {
    return participantsQuery.loading ? (
      <LoadingSpinnerCentered />
    ) : (
      <div className="card-body">
        <h3>Booking participants</h3>
        <div className="row">
          <div className="form-group col">
            No participants registered for this booking
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="card-body">
      <h3>Booking participants</h3>

      {participants?.map((participant) => (
        <>
          <div className="row">
            <div className="form-group col">
              <label>
                <small className="text-secondary">First name</small>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="End customer first name"
                value={participant.firstName ?? ''}
                readOnly
              />
            </div>

            <div className="form-group col">
              <label>
                <small className="text-secondary">Last name</small>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="End customer last name"
                value={participant.lastName ?? ''}
                readOnly
              />
            </div>
          </div>

          <div className="row">
            <RequestParticipantDataField
              bookingPartnerContactId={participant.id}
              label="Email"
              fieldToRequest={RequestParticipantDataFieldEnum.EMAIL}
            />

            <RequestParticipantDataField
              bookingPartnerContactId={participant.id}
              label="Mobile phone"
              fieldToRequest={
                RequestParticipantDataFieldEnum.MOBILEPHONE_VALIDATED
              }
            />
          </div>

          <div className="row">
            <div className="form-group col">
              <label>
                <small className="text-secondary">Role</small>
              </label>
              <input
                type="text"
                className="form-control"
                value={participant.role ?? ''}
                readOnly
              />
            </div>
          </div>

          {participants.indexOf(participant) !== participants.length - 1 && (
            <hr style={{ borderTopWidth: 3 }} />
          )}
        </>
      ))}
    </div>
  );
}

const RequestParticipantDataField = ({
  bookingPartnerContactId,
  fieldToRequest,
  label,
  placeholder = label,
}: {
  bookingPartnerContactId?: string;
  fieldToRequest: RequestParticipantDataFieldEnum;
  label: string;
  placeholder?: string;
}) => {
  const [requestData, { data }] = useMutation<
    {
      bookingPartnerContactRequestDataAsAdmin: {
        field?: string;
      };
    },
    {
      bookingPartnerContactId: string;
      field: RequestParticipantDataFieldEnum;
    }
  >(
    gql`
      mutation RequestParticipantDataAsAdmin(
        $bookingPartnerContactId: ID!
        $field: BookingPartnerContactRequestDataAsAdminFieldEnum!
      ) {
        bookingPartnerContactRequestDataAsAdmin(
          input: {
            bookingPartnerContactId: $bookingPartnerContactId
            field: $field
          }
        ) {
          field
        }
      }
    `
  );

  const customerField = data?.bookingPartnerContactRequestDataAsAdmin.field;

  if (bookingPartnerContactId == null) {
    return (
      <ReadOnlyInput label={label} placeholder={placeholder} value="Error" />
    );
  }

  if (customerField == null) {
    return (
      <div className="form-group col">
        <label>
          <small className="text-secondary">{label}</small>
        </label>

        <div className="input-group">
          <input
            type="text"
            className="form-control"
            readOnly={true}
            value="**********"
          />
          <div className="input-group-append">
            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={() =>
                requestData({
                  variables: { bookingPartnerContactId, field: fieldToRequest },
                })
              }
            >
              <i className="mr-2 fa fa-eye" />
              Show
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <ReadOnlyInput
      label={label}
      placeholder={placeholder}
      value={customerField}
    />
  );
};
