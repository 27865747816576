import moment from 'moment-timezone';
import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import Select from 'react-select';
import qs from 'query-string';
import Component2 from '@reach/component-component';

import { Descendant } from 'slate';
import { ActionsDropdown } from '../../../components/ActionsDropdown';
import { gql, useQuery } from '../../../components/ApolloClient';

import {
  EnterpriseGuidelineModal,
  serviceType,
} from '../../../components/EnterpriseGuidelineModal';
import { EnterpriseRichTextCloneGuidelineModal } from '../../../components/EnterpriseRichTextCloneGuidelineModal';
import { EnterpriseRichTextGuidelineModal } from '../../../components/EnterpriseRichTextGuidelineModal';
import { EnterpriseRichTextSelectPartnerModal } from '../../../components/EnterpriseRichTextSelectPartnerModal';
import { Modal } from '../../../components/Modal';
import { locationForModal, ModalRoute } from '../../../components/ModalRoute';
import { ensureString } from '../../../utils/strings';
import { LoadingSpinnerCentered } from '../../../components/LoadingSpinner';
import { ArchiveGuidelineModal } from '../../../components/EnterpriseGuidelineArchiveModal';
import { COLOR_PALLETTE, USER_TYPE } from '../../../constants';

const MODAL_NAME_ENTERPRISE_GUIDELINE_MODAL =
  'modal_name_enterprise_guideline_modal';

const MODAL_NAME_ENTERPRISE_GUIDELINE_VIDEO_MODAL =
  'modal_name_enterprise_guideline_video_modal';

const MODAL_NAME_ENTERPRISE_CREATE_RICH_TEXT_GUIDELINE_MODAL =
  'modal_name_enterprise_create_rich_text_guideline_modal';

const MODAL_NAME_ENTERPRISE_CLONE_RICH_TEXT_GUIDELINE_MODAL =
  'modal_name_enterprise_clone_rich_text_guideline_modal';

const MODAL_NAME_ENTERPRISE_SELECT_PARTNER_MODAL =
  'modal_name_enterprise_select_partner_modal';

const MODAL_NAME_ENTERPRISE_GUIDELINE_ARCHIVE_MODAL =
  'modal_name_enterprise_guideline_archive_modal';

type Package = {
  uid: string;
  duration: number;
  serviceType: string;
  shootType?: {
    category: string;
  };
};

type Guideline = {
  uid: string;
  userType: string;
  name: string;
  fileUrl?: string;
  videoId?: string;
  packages?: Package[];
  isReferral: boolean;
  updatedAt: string;
  archivedAt?: string;
  lastUpdatedBy?: {
    fullName: string;
  };
  shootType?: {
    category: string;
    displayName: string;
  };
  richTextGuidelines: {
    edges: { id: string }[];
  };
};

export const GUIDELINE_PLACE_HOLDER = (section: string): Descendant[] => [
  {
    id: '047ecb3f-3e69-4c4c-a3e6-5a90e85f6c84',
    url:
      'https://snappr-dev-media.s3.amazonaws.com/047ecb3f-3e69-4c4c-a3e6-5a90e85f6c84',
    type: 'image',
    width: 180,
    height: 344,
    children: [
      {
        text: '',
      },
    ],
  },
  {
    type: 'heading-one',
    align: 'left',
    children: [
      {
        text: 'Photographer shoot guide',
      },
    ],
  },
  {
    type: 'paragraph',
    align: 'left',
    children: [
      {
        bold: true,
        text:
          'This guide will help you get all the required shots and instill you with the confidence and artistic freedom to create photographs that inspire and inform customers.',
      },
    ],
  },
  {
    type: 'paragraph',
    align: 'left',
    children: [
      {
        text:
          'Use this paragraph to briefly talk about the enterprise. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
      },
    ],
  },
];

export const placeholderRichTextGuideline = {
  sections: [
    { id: '1', name: 'Shoot essentials', content: GUIDELINE_PLACE_HOLDER('1') },
    { id: '2', name: 'Introduction', content: GUIDELINE_PLACE_HOLDER('2') },
    { id: '3', name: 'Before the shoot', content: GUIDELINE_PLACE_HOLDER('3') },
  ],
};

const PackageLabel = ({
  backgroundColor = '#38C4F2',
  children,
}: {
  backgroundColor?: string;
  children: React.ReactNode;
}) => (
  <div
    style={{
      display: 'flex',
      height: 15,
      padding: '2px 4px',
      backgroundColor,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 1,
      marginRight: 10,
    }}
  >
    <span style={{ color: '#FFFFFF', fontSize: 10 }}>{children}</span>
  </div>
);

const Packages = ({
  packages,
  backgroundColor,
}: {
  packages: Package[];
  backgroundColor?: string;
}) => (
  <>
    {packages.map((pack, index) => (
      <PackageLabel
        backgroundColor={backgroundColor != null ? backgroundColor : undefined}
        key={index}
      >
        {pack.duration / 60} {pack.duration / 60 === 1 ? 'HOUR' : 'HOURS'}
      </PackageLabel>
    ))}
  </>
);

const ShowVideoGuideline = ({ videoId, onDismiss }: any) => {
  return (
    <Modal onDismiss={onDismiss}>
      <div className="card my-4" style={{ height: 500, width: 900 }}>
        <div className="card-body d-flex flex-column">
          <iframe
            title="guidelineVideo"
            src={`https://player.vimeo.com/video/${videoId}`}
            style={{ height: '100%', width: '100%' }}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          />
        </div>
      </div>
    </Modal>
  );
};

const VideoGuidelineModal = () => {
  return (
    <ModalRoute
      modalName={MODAL_NAME_ENTERPRISE_GUIDELINE_VIDEO_MODAL}
      render={({ history, location, modalParams }) => (
        <ShowVideoGuideline
          videoId={modalParams.videoId}
          onDismiss={() =>
            history.replace(
              locationForModal({
                location,
                modal: undefined,
              })
            )
          }
        />
      )}
    />
  );
};

function MultiSelectFiler({
  filterName,
  title,
  options,
  location,
  history,
}: {
  filterName: string;
  title: string;
  options: { value: string; label: string }[];
  location: RouteComponentProps['location'];
  history: RouteComponentProps['history'];
}) {
  const filterString = qs.parse(location.search)[filterName];
  const selectedValues = filterString
    ? ensureString(filterString ?? '')?.split(',')
    : [];

  const selectedOptions =
    selectedValues && options.filter((o) => selectedValues.includes(o.value));

  const customStyles = {
    valueContainer: (provided) => ({
      ...provided,
      marginLeft: 82,
    }),
  };

  return (
    <div
      style={{
        minWidth: 150,
        maxWidth: 350,
        marginRight: 8,
        position: 'relative',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 7,
          left: 10,
          zIndex: 2,
          textTransform: 'capitalize',
          color: '#C1C8D4',
        }}
      >
        {title}
      </div>

      <div style={{ minWidth: 100 }}>
        <Select
          isMulti
          value={selectedOptions}
          options={options}
          styles={customStyles}
          placeholder=""
          onChange={(selectedOptions) => {
            const selected = (selectedOptions || []).map((s) => s.value);

            history.push({
              ...location,
              search: qs.stringify({
                ...qs.parse(location.search),
                [filterName]:
                  selected.length > 0 ? selected.join(',') : undefined,
                ...(filterName === 'shootTypeFilter' && {
                  packageFilter: undefined,
                }),
              }),
            });
          }}
        />
      </div>
    </div>
  );
}

function ProductGuidelineList({
  location,
  history,
  partnerId,
  guidelineList,
}: {
  partnerId: string;
  guidelineList: Guideline[];
  history: RouteComponentProps['history'];
  location: RouteComponentProps['location'];
}) {
  const parsedSearch = React.useMemo(() => qs.parse(location.search), [
    location.search,
  ]);

  const shootTypesQuery = useQuery<{
    getPublicShootTypes: {
      displayName: string;
      category: string;
    }[];
  }>(gql`
    query GetPublicShootTypesForGuidelinesListFilterQuery {
      getPublicShootTypes {
        category
        displayName
      }
    }
  `);

  const shootTypes = shootTypesQuery.data?.getPublicShootTypes;

  const productsByShootTypeQuery = useQuery<{
    getPublicPackagesByShootTypeForGuidelineCreationAsAdmin?: {
      edges: Package[];
    };
  }>(
    gql`
      query GetProductsByShootTypeForGuidelinesListFilterQuery(
        $shootType: String!
        $partnerId: ID!
        $serviceType: String!
      ) {
        getPublicPackagesByShootTypeForGuidelineCreationAsAdmin(
          shootType: $shootType
          partnerId: $partnerId
          serviceType: $serviceType
        ) {
          edges {
            uid
            duration
            price
            type
            shootType {
              category
            }
          }
        }
      }
    `,
    {
      variables: {
        shootType: parsedSearch.shootTypeFilter,
        serviceType: parsedSearch.serviceType,
        partnerId,
      },
      skip:
        parsedSearch.shootTypeFilter == null ||
        parsedSearch.serviceType == null,
    }
  );

  const packages =
    productsByShootTypeQuery.data
      ?.getPublicPackagesByShootTypeForGuidelineCreationAsAdmin?.edges;

  const shoottypeOptions =
    shootTypes?.map((shootType) => ({
      label: shootType.displayName,
      value: shootType.category,
    })) ?? [];

  const packagesOptions = (packages ?? []).map((o) => ({
    label: `${o.duration / 60} ${o.duration / 60 === 1 ? 'Hour' : 'Hours'}`,
    value: o.uid,
  }));

  const userTypeOptions = [
    {
      label: 'Artists',
      value: 'artists',
    },
    {
      label: 'End customer',
      value: 'end_customer',
    },
  ];

  return (
    <>
      <Component2
        didMount={() =>
          history.replace({
            ...location,
            search: qs.stringify({
              ...parsedSearch,
            }),
          })
        }
      />

      <div className="d-flex flex-direction-row justify-content-between">
        <div className="d-flex justify-content-end align-items-center m-3">
          <MultiSelectFiler
            title={'Shoot type'}
            filterName={'shootTypeFilter'}
            options={shoottypeOptions}
            location={location}
            history={history}
          />
          <div className="p-2" />

          <MultiSelectFiler
            title={'Packages'}
            filterName={'packageFilter'}
            options={packagesOptions}
            location={location}
            history={history}
          />

          <div className="p-2" />

          <MultiSelectFiler
            title={'User type'}
            filterName={'userTypeFilter'}
            options={userTypeOptions}
            location={location}
            history={history}
          />
        </div>
        <div className="d-flex justify-content-end align-items-center mb-3 mt-3">
          <Link
            className="btn btn-dark btn-sm mr-3"
            to={locationForModal({
              location,
              modal: {
                modalName: MODAL_NAME_ENTERPRISE_GUIDELINE_MODAL,
                modalType: 'create',
              },
            })}
          >
            Create PDF guideline
          </Link>

          <Link
            className="btn btn-dark btn-sm mr-3"
            to={locationForModal({
              location,
              modal: {
                modalName: MODAL_NAME_ENTERPRISE_CREATE_RICH_TEXT_GUIDELINE_MODAL,
              },
            })}
          >
            Create rich text guideline
          </Link>
        </div>
      </div>
      <div
        className="table-responsive p-2"
        style={{ overflow: 'scroll', minHeight: 400 }}
      >
        <table className="table table-hover mb-0">
          <thead>
            <tr>
              <th className="text-muted text-truncate">Name</th>
              <th className="text-muted text-truncate">Shoot type</th>
              <th className="text-muted text-truncate">Packages</th>
              <th className="text-muted text-truncate">User type</th>
              <th className="text-muted text-truncate">Assets</th>
              <th className="text-muted text-truncate">Service type</th>
              <th className="text-muted text-truncate">Last updated</th>
              <th className="text-muted text-truncate">Last updated by</th>
              <th className="text-muted text-truncate" />
            </tr>
          </thead>

          <tbody>
            {(guidelineList ?? []).map((partnerGuideline) => (
              <tr
                key={partnerGuideline.uid}
                style={{
                  whiteSpace: 'nowrap',
                  background:
                    partnerGuideline.archivedAt != null
                      ? COLOR_PALLETTE.GRAY_1
                      : '',
                  color:
                    partnerGuideline.archivedAt != null
                      ? COLOR_PALLETTE.GRAY_3
                      : '',
                }}
              >
                <td>
                  {(partnerGuideline.richTextGuidelines.edges ?? []).length <=
                  0 ? (
                    partnerGuideline.name
                  ) : (
                    <Link
                      to={`/enterprises/${partnerId}/guidelines/${partnerGuideline.uid}/versions`}
                    >
                      {partnerGuideline.name}
                    </Link>
                  )}
                </td>

                <td>{partnerGuideline.shootType?.displayName}</td>

                <td
                  style={{
                    maxWidth: '300px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      paddingTop: 6,
                      flexDirection: 'row',
                    }}
                  >
                    {partnerGuideline.isReferral === true ? (
                      <PackageLabel backgroundColor="#2FB67D">
                        REFERRALS
                      </PackageLabel>
                    ) : partnerGuideline.shootType?.category ===
                      'realestate' ? (
                      <PackageLabel>REAL ESTATE - ALL PACKAGES</PackageLabel>
                    ) : (
                      <Packages
                        backgroundColor={
                          partnerGuideline.archivedAt != null
                            ? COLOR_PALLETTE.GRAY_3
                            : undefined
                        }
                        packages={partnerGuideline.packages ?? []}
                      />
                    )}
                  </div>
                </td>

                <td>{USER_TYPE[partnerGuideline.userType].name}</td>

                <td>
                  {partnerGuideline.fileUrl != null && (
                    <a
                      href={partnerGuideline.fileUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: 'underline', marginRight: 10 }}
                    >
                      PDF
                    </a>
                  )}

                  {partnerGuideline.videoId != null && (
                    <Link
                      style={{ marginRight: 10 }}
                      to={locationForModal({
                        location,
                        modal: {
                          modalName: MODAL_NAME_ENTERPRISE_GUIDELINE_VIDEO_MODAL,
                          videoId: partnerGuideline.videoId,
                        },
                      })}
                    >
                      Video
                    </Link>
                  )}

                  {(partnerGuideline.richTextGuidelines.edges ?? []).length >
                    0 && (
                    <Link
                      to={`/enterprises/${partnerId}/guidelines/${partnerGuideline.uid}/versions`}
                    >
                      RichText
                    </Link>
                  )}
                </td>

                <td>
                  {(partnerGuideline.packages ?? []).find(
                    (p) => p.serviceType === serviceType.videography
                  ) != null
                    ? 'Videography'
                    : 'Photography'}
                </td>

                <td>{moment(partnerGuideline.updatedAt).format('D MMM YY')}</td>

                <td>{partnerGuideline.lastUpdatedBy?.fullName}</td>

                <td className="text-right">
                  <ActionsDropdown>
                    {() => (
                      <div
                        style={{ textAlign: 'left', flexDirection: 'column' }}
                      >
                        <Link
                          className="d-flex btn"
                          to={locationForModal({
                            location,
                            modal: {
                              modalName: MODAL_NAME_ENTERPRISE_SELECT_PARTNER_MODAL,
                              guidelineId: partnerGuideline.uid,
                            },
                          })}
                        >
                          Duplicate guideline
                        </Link>

                        {partnerGuideline.fileUrl == null &&
                        partnerGuideline.videoId == null ? (
                          <Link
                            className="d-flex btn"
                            to={`/enterprises/${partnerId}/guidelines/${partnerGuideline.uid}/versions`}
                          >
                            Edit guideline
                          </Link>
                        ) : (
                          <Link
                            className="d-flex btn"
                            to={locationForModal({
                              location,
                              modal: {
                                modalName: MODAL_NAME_ENTERPRISE_GUIDELINE_MODAL,
                                modalType: 'edit',
                                guidelineId: partnerGuideline.uid,
                              },
                            })}
                          >
                            Edit guideline
                          </Link>
                        )}

                        {partnerGuideline.fileUrl != null && (
                          <a
                            className="d-flex btn"
                            target="blank"
                            referrerPolicy="no-referrer"
                            href={`${partnerGuideline.fileUrl}`}
                          >
                            Download PDF
                          </a>
                        )}

                        {partnerGuideline.videoId != null && (
                          <Link
                            className="d-flex btn"
                            to={locationForModal({
                              location,
                              modal: {
                                modalName: MODAL_NAME_ENTERPRISE_GUIDELINE_VIDEO_MODAL,
                                videoId: partnerGuideline.videoId,
                              },
                            })}
                          >
                            Watch video
                          </Link>
                        )}

                        {partnerGuideline.archivedAt == null && (
                          <Link
                            className="d-flex btn"
                            to={locationForModal({
                              location,
                              modal: {
                                modalName: MODAL_NAME_ENTERPRISE_GUIDELINE_ARCHIVE_MODAL,
                                guidelineId: partnerGuideline.uid,
                              },
                            })}
                          >
                            Archive guideline
                          </Link>
                        )}
                      </div>
                    )}
                  </ActionsDropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export const OnEnterpriseGuidelineModal = ({
  partnerId,
  session,
  reloadGuidelines,
}: {
  partnerId: string;
  session: { token: string };
  reloadGuidelines: () => Promise<any>;
}) => {
  return (
    <ModalRoute
      modalName={MODAL_NAME_ENTERPRISE_GUIDELINE_MODAL}
      render={({ history, location, modalParams }) => (
        <EnterpriseGuidelineModal
          onDismiss={() =>
            history.replace(
              locationForModal({
                location,
                modal: undefined,
              })
            )
          }
          session={session}
          location={location}
          history={history}
          partnerId={partnerId}
          onContinue={() =>
            reloadGuidelines().then(() => {
              history.replace(
                locationForModal({
                  location,
                  modal: undefined,
                })
              );
            })
          }
          modalParams={modalParams}
        />
      )}
    />
  );
};

export function ProductGuidelinesSection({
  session,
  partner,
  location,
  history,
}: {
  session: { token: string };
  partner: { name: string; uid: string };
  location: RouteComponentProps['location'];
  history: RouteComponentProps['history'];
}) {
  const { shootTypeFilter, packageFilter, userTypeFilter } = qs.parse(
    location.search
  ) as {
    shootTypeFilter: string;
    packageFilter: string;
    userTypeFilter: string;
  };

  const partnerGuidelinesQuery = useQuery<{
    partnerGuidelinesGetListByPartnerId: {
      edges: Guideline[];
    };
  }>(
    gql`
      query PartnerGuidelinesListQuery2(
        $partnerId: ID!
        $shootTypeFilter: [String]
        $packageFilter: [String]
        $userTypeFilter: [String]
      ) {
        partnerGuidelinesGetListByPartnerId(
          partnerId: $partnerId
          shootTypeFilter: $shootTypeFilter
          packageFilter: $packageFilter
          userTypeFilter: $userTypeFilter
        ) {
          edges {
            uid
            userType
            name
            fileUrl
            isReferral
            updatedAt
            archivedAt
            videoId
            lastUpdatedBy {
              id
              fullName
            }
            packages {
              uid
              duration
              price
              serviceType
            }
            shootType {
              displayName
              category
            }
            richTextGuidelines {
              edges {
                id
              }
            }
          }
        }
      }
    `,
    {
      variables: {
        partnerId: partner.uid,
        shootTypeFilter: shootTypeFilter?.split(','),
        packageFilter: packageFilter?.split(','),
        userTypeFilter: userTypeFilter?.split(','),
      },
      fetchPolicy: 'network-only',
    }
  );

  const partnerGuidelines =
    partnerGuidelinesQuery.data?.partnerGuidelinesGetListByPartnerId.edges;

  const onDismissModal = (history) =>
    history.replace(
      locationForModal({
        location,
        modal: undefined,
      })
    );

  return (
    <>
      {partnerGuidelines != null && partnerGuidelinesQuery.loading !== true ? (
        <ProductGuidelineList
          partnerId={partner.uid}
          guidelineList={partnerGuidelines}
          location={location}
          history={history}
        />
      ) : (
        <LoadingSpinnerCentered />
      )}

      <OnEnterpriseGuidelineModal
        partnerId={partner.uid}
        session={session}
        reloadGuidelines={partnerGuidelinesQuery.refetch}
      />

      <ModalRoute
        modalName={MODAL_NAME_ENTERPRISE_CREATE_RICH_TEXT_GUIDELINE_MODAL}
        render={({ history, location, modalParams }) => (
          <EnterpriseRichTextGuidelineModal
            onDismiss={() => onDismissModal(history)}
            location={location}
            history={history}
            partnerId={partner.uid}
            onContinue={({ guidelineId }) =>
              history.push(
                `/enterprises/${partner.uid}/guidelines/${guidelineId}/versions`
              )
            }
            modalParams={modalParams}
          />
        )}
      />

      <ModalRoute
        modalName={MODAL_NAME_ENTERPRISE_SELECT_PARTNER_MODAL}
        render={({ history, location, modalParams }) => (
          <EnterpriseRichTextSelectPartnerModal
            history={history}
            location={location}
            session={session}
            modalParams={modalParams}
            onDismiss={() => onDismissModal(history)}
            onContinue={({ partnerId, guidelineId }) =>
              history.replace(
                locationForModal({
                  location,
                  modal: {
                    modalName: MODAL_NAME_ENTERPRISE_CLONE_RICH_TEXT_GUIDELINE_MODAL,
                    guidelineId,
                    partnerId,
                  },
                })
              )
            }
          />
        )}
      />

      <ModalRoute
        modalName={MODAL_NAME_ENTERPRISE_GUIDELINE_ARCHIVE_MODAL}
        render={({ history, modalParams }) => (
          <ArchiveGuidelineModal
            modalParams={modalParams}
            onDismiss={() => onDismissModal(history)}
            onContinue={() =>
              partnerGuidelinesQuery.refetch().then(() => {
                history.replace(
                  locationForModal({
                    location,
                    modal: undefined,
                  })
                );
              })
            }
          />
        )}
      />

      <ModalRoute
        modalName={MODAL_NAME_ENTERPRISE_CLONE_RICH_TEXT_GUIDELINE_MODAL}
        render={({ history, location, modalParams }) => (
          <EnterpriseRichTextCloneGuidelineModal
            onDismiss={() =>
              history.replace(
                locationForModal({
                  location,
                  modal: undefined,
                })
              )
            }
            location={location}
            history={history}
            partnerId={modalParams.partnerId}
            guidelineId={modalParams.guidelineId}
            onContinue={({ guidelineId }) =>
              history.push(
                `/enterprises/${modalParams.partnerId}/guidelines/${guidelineId}/versions`
              )
            }
            modalParams={modalParams}
          />
        )}
      />

      <VideoGuidelineModal />
    </>
  );
}
