export const SNAPPR_BLUE = '#2b87f9';
export const COLOR_PRIMARY = SNAPPR_BLUE;
export const COLOR_SUCCESS = '#7ed321';
export const COLOR_DANGER = 'darkred';
export const COLOR_WARNING = '#e0a800';

export const COLOR_PALLETTE = {
  GRAY_1: '#F6F9FF',
  GRAY_2: '#E8EDF5',
  GRAY_3: '#C1C8D4',
  GRAY_4: '#71767E',
  GRAY_5: '#454F5C',
  PRIMARY: '#141B24',
  SUCCESS: '#2FB67D',
  WARNING: '#FFBB00',
  DANGER: '#E11F59',
  MUTED: '#6c757d',
  TEAL: '#38C4F2',
  PURPLE: '#7D2EFF',
  BLUE: '#2b87f9',
};

export const GRID_BREAKPOINTS = {
  xs: '0',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
};

export const BOOKING_STATUS = {
  PAID: 'paid',
  PAID_PENDING_SCHEDULE: 'paid_pending_schedule',
  PAID_ON_HOLD: 'paid_on_hold',
  PAID_DEPOSIT: 'paid_deposit',
  PENDING_SCHEDULE: 'pending_schedule',
  COMPLETED: 'completed',
  REFUNDED: 'refunded',
  CANCELLED: 'cancelled',
};

export const SHOOTING_ELEMENTS_OPTIONS = {
  clothing: {
    slug: 'clothing',
    name: 'Clothing',
  },
  furniture: {
    slug: 'furniture',
    name: 'Furniture',
  },
  'health-beauty': {
    slug: 'health-beauty',
    name: 'Health & Beauty',
  },
  jewelry: {
    slug: 'jewelry',
    name: 'Jewelry',
  },
  'pet-supplies': {
    slug: 'pet-supplies',
    name: 'Pet Supplies',
  },
  'shoes-bags-hats': {
    slug: 'shoes-bags-hats',
    name: 'Shoes, Bags & Hats',
  },
  tech: {
    slug: 'tech',
    name: 'Tech',
  },
  toys: {
    slug: 'toys',
    name: 'Toys',
  },
  watches: {
    slug: 'watches',
    name: 'Watches',
  },
  other: {
    slug: 'other',
    name: 'Other',
  },
};

export const STYLE_OPTIONS = {
  studio: {
    slug: 'studio',
    name: 'Studio',
  },
  'natural-backdrop': {
    slug: 'natural-backdrop',
    name: 'Natural backdrop',
  },
  'being-used-person': {
    slug: 'being-used-person',
    name: 'Being used in person',
  },
  'stylized-props': {
    slug: 'stylized-props',
    name: 'Stylized with curated props',
  },
};

export const BACKGROUND_OPTIONS = {
  textured: {
    slug: 'textured',
    name: 'Textured white backdrop',
  },
  pure: {
    slug: 'pure',
    name: 'Pure white background - Pixel perfect',
  },
  'auto-editing': {
    slug: 'auto-editing',
    name: 'Pure white background - Automated',
  },
};
export const SIZE_OPTIONS = {
  small: {
    slug: 'small',
    name: 'Small',
  },
  standard: {
    slug: 'standard',
    name: 'Standard',
  },
  large: {
    slug: 'large',
    name: 'Large',
  },
  oversized: {
    slug: 'oversized',
    name: 'Oversized',
  },
};

export const ANGLE_OPTIONS = {
  'hero-view': {
    slug: 'hero-view',
    name: 'Hero view',
  },
  'left-view': {
    slug: 'left-view',
    name: 'Left view',
  },
  '45-left-view': {
    slug: '45-left-view',
    name: '45° left view',
  },
  'straight-front-view': {
    slug: 'straight-front-view',
    name: 'Straight front view',
  },
  '45-right-view': {
    slug: '45-right-view',
    name: '45° right view',
  },
  'right-view': {
    slug: 'right-view',
    name: 'Right view',
  },
  'back-view': {
    slug: 'back-view',
    name: 'Back view',
  },
  'isometric-view': {
    slug: 'isometric-view',
    name: 'Isometric view',
  },
  macro: {
    slug: 'macro',
    name: 'Macro',
  },
  other: {
    slug: 'other',
    name: 'Other',
  },
};

export const PRODUCT_TIERS = {
  value: 'value',
  allInclusive: 'allInclusive',
  premium: 'premium',
};

export const PORTFOLIO_LEVELS = {
  TOP: 'top',
  DEFAULT: 'default',
  BANNED: 'banned',
  PENDING_REVIEW: 'pending_review',
  PREMIUM: 'premium',
};

export const USER_TYPE = {
  artists: { value: 'ARTISTS', name: 'Artists' },
  end_customer: { value: 'END_CUSTOMER', name: 'End customer' },
  editor: { value: 'EDITOR', name: 'Editor' },
  referral_customer: { value: 'REFERRAL_CUSTOMER', name: 'Referral customer' },
  editor_vendor: { value: 'EDITOR_VENDOR', name: 'Editor vendor' },
};

export const UUID_REGEX =
  '[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}';
