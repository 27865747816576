import React from 'react';
import { gql, useQuery } from '../../../../components/ApolloClient';
import { BookingEditRouteType } from '../types';

export function CallMaskingLogs({
  booking,
}: {
  booking: BookingEditRouteType;
}) {
  const callMaskingLogsQuery = useQuery<{
    bookingById?: {
      callMaskingLogsAsAdmin: {
        id: string;
        recordingUrl?: string;
        createdAt?: string;
        caller?: string;
        calledMasked?: string;
      }[];
      callMaskingSessionAsAdmin?: {
        id: string;
        phoneNumber?: string;
        deactivatedAt?: string;
      };
    };
  }>(
    gql`
      query CallMaskingLogs($bookingId: ID!) {
        bookingById(id: $bookingId) {
          id
          callMaskingLogsAsAdmin {
            id
            recordingUrl
            createdAt(format: "MMM Do YYYY, h:mm:ss a zz")
            caller
            calledMasked
          }
          callMaskingSessionAsAdmin {
            id
            phoneNumber
            deactivatedAt
          }
        }
      }
    `,
    { skip: booking.uid == null, variables: { bookingId: booking.uid } }
  );

  const callMaskingLogs =
    callMaskingLogsQuery.data?.bookingById?.callMaskingLogsAsAdmin;

  const callMaskingSession =
    callMaskingLogsQuery.data?.bookingById?.callMaskingSessionAsAdmin;

  return (
    <div
      className="card-body"
      style={{ maxHeight: '400px', overflow: 'scroll' }}
    >
      <div className="table-responsive">
        {callMaskingSession?.phoneNumber != null && (
          <p>
            <b>Booking masked number: </b>

            <span>{callMaskingSession?.phoneNumber} </span>

            <span
              className={
                callMaskingSession?.deactivatedAt != null
                  ? 'text-danger'
                  : 'text-success'
              }
            >
              {callMaskingSession?.deactivatedAt != null
                ? 'Deactivated'
                : 'Active'}
            </span>
          </p>
        )}

        <table className="table table-hover mb-0">
          <thead>
            <tr>
              <th className="text-muted text-truncate">About</th>
              <th className="text-muted text-truncate">Recording</th>
            </tr>
          </thead>
          <tbody>
            {(callMaskingLogs ?? [])
              .filter((item) => item.recordingUrl != null)
              .map((item) => (
                <>
                  <tr key={item.id} style={{ whiteSpace: 'nowrap' }}>
                    <td className="d-flex flex-column">
                      <span>{item.createdAt}</span>

                      <div className="d-flex flex-row align-items-center mt-2">
                        <span>Caller:</span>
                        <span className="badge badge-pill badge-light">
                          {item.caller ?? 'none'}
                        </span>
                      </div>

                      <div className="d-flex flex-row align-items-center mt-2">
                        <span>Called (masked):</span>
                        <span className="badge badge-pill badge-light">
                          {item.calledMasked ?? 'none'}
                        </span>
                      </div>
                    </td>
                    <td>
                      <audio controls src={item.recordingUrl}>
                        Your browser does not support the
                        <code>audio</code> element.
                      </audio>
                    </td>
                  </tr>
                </>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
