import * as React from 'react';

import { Fetcher } from '../../../../../components/Fetcher';
import { packageDescription } from '../PackageDetails';
import { BOOKING_CHANGED_KEYS_TO_OMIT } from '../../utils';
import { gql, useQuery } from '../../../../../components/ApolloClient';

const Provider = ({ provider_uid, session }) =>
  provider_uid != null ? (
    <Fetcher
      urlToFetch={`/api/v2/admin/providers/${provider_uid}`}
      session={session}
    >
      {({ response: provider }) => (
        <td title={provider_uid}>
          {provider &&
            [provider.firstname, provider.surname].filter((r) => !!r).join(' ')}
        </td>
      )}
    </Fetcher>
  ) : (
    <td>None</td>
  );

function Package({
  booking,
}: {
  booking: { region_uid?: string; product_uid?: string };
}) {
  const packageQuery = useQuery<{
    getPackageById?: {
      uid: string;
      duration?: number;
      includedPhotos?: number;
      price?: number;
      isActive?: boolean;

      virtualTourProductType?: string;
      virtualTourProductTier?: string;
    };
  }>(
    gql`
      query ChangesSummarySectionGetPackage($productId: ID!) {
        getPackageById(id: $productId, activeOnly: false) {
          uid
          duration
          includedPhotos
          price
          isActive

          virtualTourProductType
          virtualTourProductTier
        }
      }
    `,
    {
      variables: { productId: booking.product_uid },
      skip: booking.product_uid == null,
    }
  );

  return booking.region_uid == null ? (
    <td>Error: booking is missing region</td>
  ) : packageQuery.data?.getPackageById ? (
    <td title={booking.product_uid}>
      {packageDescription({
        booking,
        duration: packageQuery.data.getPackageById.duration,
        included: packageQuery.data.getPackageById.includedPhotos,
        price: packageQuery.data.getPackageById.price,
        is_active: packageQuery.data.getPackageById.isActive,

        virtualTourProductType:
          packageQuery.data.getPackageById.virtualTourProductType,
        virtualTourProductTier:
          packageQuery.data.getPackageById.virtualTourProductTier,
      })}
    </td>
  ) : (
    <td>None</td>
  );
}

const specialChangeRows = {
  provider_uid: ({ booking, bookingClone, session, changedKey }) => {
    return (
      <tr>
        <td>Provider</td>
        <Provider provider_uid={booking.provider_uid} session={session} />
        <Provider provider_uid={bookingClone.provider_uid} session={session} />
      </tr>
    );
  },

  product_uid: ({ booking, bookingClone, session, changedKey }) => {
    return (
      <tr>
        <td>Package</td>
        <Package booking={booking} />
        <Package booking={bookingClone} />
      </tr>
    );
  },
};

const toSentenceCase = (str) => {
  const cleaned = str.replace(/_/g, ' ');
  return cleaned.charAt(0).toUpperCase() + cleaned.slice(1);
};

const ChangeRow = ({ booking, bookingClone, session, changedKey }) =>
  (specialChangeRows[changedKey] &&
    React.createElement(
      specialChangeRows[changedKey],
      { booking, bookingClone, session, changedKey },
      null
    )) || (
    <tr>
      <td>{toSentenceCase(changedKey)}</td>
      <td>{booking[changedKey]}</td>
      <td>{bookingClone[changedKey]}</td>
    </tr>
  );

export const ChangesSummarySection = ({
  bookingChangedKeys,
  booking,
  bookingClone,
  session,
}) => (
  <div className="card mb-3">
    <div className="card-body">
      <h5>Summary</h5>

      <table className="table mb-0">
        <thead>
          <tr>
            <th>Field</th>
            <th>Previous value</th>
            <th>New value</th>
          </tr>
        </thead>

        <tbody>
          {Object.keys(bookingChangedKeys)
            .filter((key) => !BOOKING_CHANGED_KEYS_TO_OMIT.includes(key))
            .map((key) => (
              <ChangeRow
                key={key}
                changedKey={key}
                booking={booking}
                bookingClone={bookingClone}
                session={session}
              />
            ))}
        </tbody>
      </table>
    </div>
  </div>
);
