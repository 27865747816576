import React from 'react';
import { Fetcher } from '../../../../components/Fetcher';

type PaymentMethod = {
  token?: string;
  cardType?: string;
  maskedNumber?: string;
  email?: string;
};

export const PaymentMethodSelector = ({
  bookingClone,
  session,
  selectedPaymentMethodToken,
  onPaymentMethodTokenChange,
}: {
  bookingClone;
  session: { token: string };
  selectedPaymentMethodToken?: string;
  onPaymentMethodTokenChange;
}) => (
  <Fetcher
    urlToFetch={`/api/v2/customers/${bookingClone.customer_uid}/payment-methods`}
    onFulfilled={({
      response: { results: paymentMethods = [] },
    }: {
      response: { results: PaymentMethod[] };
    }) =>
      onPaymentMethodTokenChange({
        paymentMethodToken:
          paymentMethods.length > 0 ? paymentMethods[0].token : null,
      })
    }
    session={session}
  >
    {({
      loading: paymentMethodsLoading,
      response,
    }: {
      loading: boolean;
      response: { results: PaymentMethod[] | undefined } | undefined;
    }) => (
      <select
        className="custom-select font-weight-bold w-100"
        style={{
          backgroundColor: '#D3D8DC',
          WebkitAppearance: 'none', // REMOVE ME when you add postcss loader on webpack
        }}
        value={selectedPaymentMethodToken || ''}
        onChange={({ target: { value } }) =>
          onPaymentMethodTokenChange({
            paymentMethodToken: value === '' ? null : value,
          })
        }
      >
        {paymentMethodsLoading ? (
          <option value="">Loading...</option>
        ) : (
          <React.Fragment>
            <option value="">None</option>

            {(response?.results ?? []).map((paymentMethod) => (
              <option key={paymentMethod.token} value={paymentMethod.token}>
                {paymentMethod.cardType != null
                  ? `${paymentMethod.cardType} - ${paymentMethod.maskedNumber}`
                  : `Paypal account - ${paymentMethod.email}`}
              </option>
            ))}
          </React.Fragment>
        )}
      </select>
    )}
  </Fetcher>
);
