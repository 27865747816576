import React, { Component } from 'react';

import {
  AddressAutocompleteInput,
  MapsLibLoader,
} from '../../../../components';

export class LocationDetails extends Component {
  onPlaceSelected = ({
    coords,
    address,
    country,
    timezone,
    suburb,
    city,
    state,
  }) => {
    this.props.onPlaceChange({
      point: coords,
      address,
      country,
      timezone,
      suburb,
      city,
      state,
    });
  };

  render() {
    const {
      bookingClone,
      booking,
      onLocationNoteChange,
      bookingChangedKeys,
    } = this.props;

    const locationChanged = bookingClone.address !== booking.address;

    return (
      <MapsLibLoader>
        {({ maps: mapsLib, loading: loadingMaps }) => (
          <div className="col d-flex flex-column" style={{ minWidth: 0 }}>
            <div className="card flex-1">
              <div
                className={`card-body d-flex flex-column ${
                  locationChanged ? 'border border-success' : ''
                }`}
              >
                <h3>Location</h3>

                <div className="form-group">
                  <label>
                    <small className="text-secondary">Address</small>
                  </label>

                  {loadingMaps ? (
                    <input
                      type="text"
                      className="form-control"
                      value="Loading maps lib..."
                      disabled
                    />
                  ) : (
                    <AddressAutocompleteInput
                      onPlaceSelected={this.onPlaceSelected}
                      mapsLib={mapsLib}
                      type="text"
                      className={'form-control'}
                      placeholder="Address"
                      autoComplete="shipping street-address"
                      defaultValue={bookingClone.address}
                    />
                  )}
                </div>

                <div className="form-group flex-1 d-flex flex-column">
                  <label>
                    <small className="text-secondary">Location Notes</small>
                  </label>

                  <textarea
                    rows="3"
                    className={`form-control flex-1 ${
                      bookingChangedKeys.location_note ? 'is-valid' : ''
                    }`}
                    value={bookingClone.location_note || ''}
                    onChange={({ target: { value } }) =>
                      onLocationNoteChange({
                        location_note: value || undefined,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </MapsLibLoader>
    );
  }
}
