import download from 'downloadjs';
import moment from 'moment';
import qs from 'query-string';
import React, { useState } from 'react';
import { Promiser } from 'react-prometo';
import Textarea from 'react-textarea-autosize';
import { Fetcher } from '../../components/Fetcher';
import { ErrorProcessor } from '../../components/ErrorProcessor';
import { usePromise } from '../../components/usePromise';
import { API_URL } from '../../config';
import { apiFetch } from '../../utils';

const CONTACT_VISIBLE_TO_ARTIST_TYPE = [
  { internal_value: 'CAN_READ_END_CUSTOMER', display_value: 'End customer' },
  {
    internal_value: 'CREATOR_OF_JOB',
    display_value: 'Contact details of team member who created job',
  },

  {
    internal_value: 'ALWAYS_SAME_CONTACT',
    display_value: 'Always the same contact details',
  },
];

class Uploader extends React.Component {
  state = {
    axiosPromise: null,
    file: null,
    uploadPromise: null,
    uploadProgress: null,
  };

  file = null;

  componentDidMount() {
    this.setState({
      axiosPromise: import('axios').then(({ default: axios }) => axios),
    });
  }

  componentWillUnmount() {
    this.file = null;
  }

  render() {
    const { token } = this.props;
    const { axiosPromise, uploadPromise, file, uploadProgress } = this.state;
    return (
      <Promiser promise={axiosPromise}>
        {({ isPending: axiosIsPending, result: axios }) => (
          <Promiser promise={uploadPromise}>
            {({
              isPending: uploadIsPending,
              error: uploadError,
              result: { url: publicAssetUrl } = {},
            }) => (
              <form
                className="d-flex"
                onSubmit={(ev) => {
                  ev.preventDefault();
                  const axiosCancelSource = axios.CancelToken.source();
                  this.setState({
                    uploadPromise: apiFetch(
                      `/api/v2/media/presigned-post-public-asset`,
                      {
                        token,
                        method: 'POST',
                        body: JSON.stringify({
                          fileType: file.type,
                          fileName: file.name,
                        }),
                      }
                    ).then(
                      ({ signedUrl, key }) =>
                        this.file === file &&
                        axios
                          .put(signedUrl, file, {
                            onUploadProgress: (progressEvent) => {
                              if (this.file !== file) {
                                return axiosCancelSource.cancel();
                              }

                              this.setState({
                                uploadProgress: Math.round(
                                  (progressEvent.loaded * 100) /
                                    progressEvent.total
                                ),
                              });
                            },
                            cancelToken: axiosCancelSource.token,
                            headers: { 'Content-Type': file.type },
                          })
                          .then(
                            () =>
                              this.file === file &&
                              apiFetch(
                                `/api/v2/media/get-public-asset-url/${key}`,
                                {
                                  token,
                                }
                              )
                          )
                    ),
                  });
                }}
              >
                {uploadPromise ? (
                  uploadIsPending ? (
                    <input
                      type="text"
                      className="form-control"
                      readOnly
                      value={
                        uploadProgress === null
                          ? 'Starting upload...'
                          : uploadProgress !== 100
                          ? `Uploading (${uploadProgress}%)`
                          : 'Finishing...'
                      }
                    />
                  ) : uploadError ? (
                    <input
                      type="text"
                      className="form-control"
                      readOnly
                      value={uploadError}
                    />
                  ) : (
                    publicAssetUrl && (
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        value={publicAssetUrl}
                        onClick={(ev) => ev.target.select()}
                      />
                    )
                  )
                ) : (
                  <div className="custom-file">
                    <input
                      type="file"
                      className="custom-file-input"
                      accept=".jpg, .jpeg, .png, .pdf"
                      disabled={axiosIsPending}
                      onChange={({
                        target: {
                          files: [file],
                        },
                      }) => {
                        console.log(file);
                        this.file = file;
                        this.setState({ file });
                      }}
                    />

                    <label className="custom-file-label">
                      {axiosIsPending
                        ? 'Loading...'
                        : file
                        ? file.name
                        : 'Choose file'}
                    </label>
                  </div>
                )}

                <button
                  className="btn btn-secondary ml-2"
                  disabled={!file}
                  onClick={() => {
                    this.setState({ file: null, uploadPromise: null });
                    this.file = null;
                  }}
                >
                  {uploadPromise && !uploadIsPending ? 'Clear' : 'Cancel'}
                </button>

                <button
                  type="submit"
                  className="btn btn-primary ml-2"
                  disabled={!file || uploadIsPending}
                >
                  Upload
                </button>
              </form>
            )}
          </Promiser>
        )}
      </Promiser>
    );
  }
}

function DownloadButton(props) {
  const { setPromise, isPending: downloadPending } = usePromise();

  {
    const {
      url,
      session: { token },
      children,
      ...rest
    } = props;

    return (
      <button
        {...rest}
        onClick={() =>
          setPromise(
            fetch(API_URL + url, {
              headers: {
                Authorization: token && `Bearer ${token}`,
              },
            })
              .then((res) => res.blob())
              .then((blob) =>
                download(blob, props.name || 'Export.csv', 'text/csv')
              )
          )
        }
        disabled={downloadPending}
      >
        {downloadPending ? 'Downloading...' : 'Download CSV'}
      </button>
    );
  }
}

function ActivityExportButton(props) {
  const { partner, session } = props;
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  return (
    <form className="d-flex flex-column">
      <div className="d-flex">
        <label className="form-group flex-1">
          From
          <input
            className="form-control"
            type="date"
            value={fromDate}
            onChange={(event) => {
              setFromDate(event.target.value);
            }}
          />
        </label>

        <div className="p-2"></div>

        <label className="form-group flex-1">
          To
          <input
            className="form-control flex-1"
            type="date"
            value={toDate}
            onChange={(event) => {
              setToDate(event.target.value);
            }}
          />
        </label>
      </div>

      <DownloadButton
        name={`${partner.name}-credit-transactions.csv`}
        className="btn btn-secondary"
        url={[
          `/api/v2/partners/${partner.uid}/credit-transactions`,
          '?',
          qs.stringify({
            filetype: 'csv',
            fromDate: moment(fromDate).toISOString(),
            toDate: moment(toDate).toISOString(),
          }),
        ].join('')}
        session={session}
      />
    </form>
  );
}

export class EditRoute extends React.Component {
  state = {
    default_shoottype: undefined,
    value_pricing: undefined,
    strict_mode: undefined,
    preapproved_providers: undefined,
    shoot_guide_url: '',
    end_customer_shoot_guide_url: '',
    contract_url: '',
    savePromise: null,
    shoot_essentials: '',

    cc_email: null,
    custom_discount_percentage: null,
    end_customer_reminders_enabled: undefined,

    use_partner_details_override: false,
    override_firstname: null,
    override_last_name: null,
    override_mobilephone: null,
    override_email: null,

    contact_details_visible_to_artist_type: null,

    contact_details_visible_to_artist_first_name: null,
    contact_details_visible_to_artist_last_name: null,
    contact_details_visible_to_artist_phone_number: null,
  };

  onChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  render() {
    const {
      session,
      match: {
        params: { partnerId },
      },
    } = this.props;

    const {
      default_shoottype,
      value_pricing,
      strict_mode,
      preapproved_providers,
      raw_upload,
      shoot_guide_url,
      end_customer_shoot_guide_url,
    } = this.state;
    return (
      <div className="d-flex justify-content-between align-items-stretch">
        <Fetcher
          session={session}
          urlToFetch={`/api/v2/partners/${partnerId}`}
          onFulfilled={({ response: partner }) => {
            if (partner) {
              this.setState({
                default_shoottype: partner.default_shoottype,
                value_pricing: partner.value_pricing,
                raw_upload: partner.raw_upload,
                strict_mode: partner.strict_mode,
                preapproved_providers: partner.preapproved_providers,
                shoot_guide_url: partner.shoot_guide_url,
                end_customer_shoot_guide_url:
                  partner.end_customer_shoot_guide_url,
                contract_url: partner.contract_url,
                cc_email: partner.cc_email,
                shoot_essentials: partner.shoot_essentials,

                custom_discount_percentage: partner.custom_discount_percentage,
                end_customer_reminders_enabled:
                  partner.end_customer_reminders_enabled,

                use_partner_details_override:
                  partner.use_partner_details_override,
                override_firstname: partner.override_firstname,
                override_last_name: partner.override_last_name,
                override_mobilephone: partner.override_mobilephone,
                override_email: partner.override_email,
                half_hour_shoot_disabled: partner.half_hour_shoot_disabled,

                contact_details_visible_to_artist_type:
                  partner.contact_details_visible_to_artist_type,

                contact_details_visible_to_artist_first_name:
                  partner.contact_details_visible_to_artist_first_name,
                contact_details_visible_to_artist_last_name:
                  partner.contact_details_visible_to_artist_last_name,
                contact_details_visible_to_artist_phone_number:
                  partner.contact_details_visible_to_artist_phone_number,
              });
            }
          }}
        >
          {({ loading, response: partner, reload: reloadPartner }) => (
            <React.Fragment>
              <div className="flex-1">
                <Promiser promise={this.state.savePromise}>
                  {({
                    isPending: saving,
                    result: savedPartner = {},
                    error: savingError,
                  }) => (
                    <React.Fragment>
                      <form
                        className="bg-white rounded p-5"
                        onSubmit={(e) => {
                          e.preventDefault();
                          const savePromise = apiFetch(
                            `/api/v2/partners/${partnerId}/config-variables`,
                            {
                              token: session.token,
                              method: 'PUT',
                              body: JSON.stringify({
                                value_pricing,
                                strict_mode,
                                raw_upload,
                                preapproved_providers,
                                shoot_guide_url,
                                end_customer_shoot_guide_url,
                                custom_discount_percentage: this.state
                                  .custom_discount_percentage,

                                cc_email: this.state.cc_email,
                                contract_url: this.state.contract_url,
                                shoot_essentials: this.state.shoot_essentials,
                                end_customer_reminders_enabled: this.state
                                  .end_customer_reminders_enabled,
                                default_shoottype:
                                  default_shoottype !== 'none'
                                    ? default_shoottype
                                    : null,

                                use_partner_details_override: this.state
                                  .use_partner_details_override,
                                override_firstname: this.state
                                  .override_firstname,
                                override_last_name: this.state
                                  .override_last_name,
                                override_mobilephone: this.state
                                  .override_mobilephone,
                                override_email: this.state.override_email,
                                half_hour_shoot_disabled: this.state
                                  .half_hour_shoot_disabled,

                                contact_details_visible_to_artist_type: this
                                  .state.contact_details_visible_to_artist_type,

                                contact_details_visible_to_artist_first_name: this
                                  .state
                                  .contact_details_visible_to_artist_first_name,
                                contact_details_visible_to_artist_last_name: this
                                  .state
                                  .contact_details_visible_to_artist_last_name,
                                contact_details_visible_to_artist_phone_number: this
                                  .state
                                  .contact_details_visible_to_artist_phone_number,
                              }),
                            }
                          ).then(() => reloadPartner());

                          this.setState({ savePromise });
                        }}
                      >
                        <h2>Partner</h2>
                        {loading ? (
                          <div className="d-flex flex-1 p-5 justify-content-center">
                            <div className="load-spinner">Loading...</div>
                          </div>
                        ) : (
                          <React.Fragment>
                            <div className="row">
                              <div className="form-group col-6">
                                <label htmlFor="">Name</label>

                                <input
                                  className="form-control"
                                  type="text"
                                  value={partner.name}
                                  readOnly
                                />
                              </div>

                              <div className="form-group col-6">
                                <label htmlFor="">Account manager</label>

                                <input
                                  className="form-control"
                                  type="text"
                                  value={partner.account_manager_name}
                                  readOnly
                                />
                              </div>
                            </div>

                            <div className="row">
                              <div className="form-group col-6">
                                <label htmlFor="">
                                  Photographer shoot guide url
                                </label>

                                <input
                                  className="form-control"
                                  name="shoot_guide_url"
                                  type="text"
                                  value={shoot_guide_url}
                                  onChange={this.onChange}
                                />
                              </div>

                              <div className="form-group col-6">
                                <label htmlFor="">
                                  End-customer shoot guide url
                                </label>

                                <input
                                  className="form-control"
                                  name="end_customer_shoot_guide_url"
                                  type="text"
                                  value={end_customer_shoot_guide_url}
                                  onChange={this.onChange}
                                />
                              </div>
                            </div>

                            <div className="row">
                              <div className="form-group col-6">
                                <Fetcher
                                  session={session}
                                  urlToFetch={`/api/v2/products/shoottypes`}
                                >
                                  {({
                                    loading,
                                    response: { results: shoottypes } = {},
                                  }) =>
                                    loading ? (
                                      <div className="d-flex flex-1 justify-content-center">
                                        <div className="load-spinner">
                                          Loading...
                                        </div>
                                      </div>
                                    ) : (
                                      <React.Fragment>
                                        <label htmlFor="">
                                          Default Shoottype
                                        </label>

                                        <select
                                          className="form-control"
                                          name="default_shoottype"
                                          type="text"
                                          onChange={this.onChange}
                                          value={default_shoottype || undefined}
                                        >
                                          <option value="none">None</option>
                                          {shoottypes.map((s) => (
                                            <option key={s.name} value={s.name}>
                                              {s.display_name}
                                            </option>
                                          ))}
                                        </select>
                                      </React.Fragment>
                                    )
                                  }
                                </Fetcher>
                              </div>

                              <div className="form-group col-6">
                                <label htmlFor="contract-url">
                                  Contract url
                                </label>

                                <input
                                  className="form-control"
                                  name="contract_url"
                                  id="contract-url"
                                  type="text"
                                  value={this.state.contract_url}
                                  onChange={(event) =>
                                    this.setState({
                                      contract_url: event.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>

                            <div className="row mb-4">
                              <div className="form-group col-6">
                                <label htmlFor="cc-email">CC email</label>

                                <input
                                  id="cc-email"
                                  className="form-control"
                                  name="cc_email"
                                  type="email"
                                  value={this.state.cc_email || ''}
                                  onChange={({ target: { value } }) => {
                                    this.setState({
                                      cc_email: (value = '' ? null : value),
                                    });
                                  }}
                                />
                              </div>

                              <div className="form-group col-6">
                                <label htmlFor="custom-discount-percentage">
                                  Custom discount
                                </label>

                                <input
                                  id="custom-discount-percentage"
                                  className="form-control"
                                  placeholder="10%"
                                  name="custom_discount_percentage"
                                  type="number"
                                  min="0"
                                  max="100"
                                  value={
                                    this.state.custom_discount_percentage ==
                                    null
                                      ? ''
                                      : this.state.custom_discount_percentage
                                  }
                                  onChange={({ target: { value } }) => {
                                    this.setState({
                                      custom_discount_percentage:
                                        value === '' ? null : Number(value),
                                    });
                                  }}
                                />
                              </div>
                            </div>

                            <div className="row">
                              <div className="form-group col-6">
                                <label htmlFor="shoot-essentials">
                                  Shoot essentials
                                </label>

                                <Textarea
                                  className="form-control"
                                  name="shoot_essentials"
                                  id="shoot-essentials"
                                  value={this.state.shoot_essentials}
                                  onChange={(event) =>
                                    this.setState({
                                      shoot_essentials: event.target.value,
                                    })
                                  }
                                />
                              </div>

                              <div className="col-6">
                                <div className="custom-control custom-checkbox mb-2">
                                  <input
                                    id="strict-mode"
                                    name="strict_mode"
                                    className="custom-control-input"
                                    type="checkbox"
                                    onChange={this.onChange}
                                    checked={strict_mode}
                                  />

                                  <label
                                    htmlFor="strict-mode"
                                    className=" custom-control-label"
                                  >
                                    Strict mode
                                  </label>
                                </div>

                                <div className="custom-control custom-checkbox mb-2">
                                  <input
                                    id="value-pricing"
                                    name="value_pricing"
                                    className="custom-control-input"
                                    type="checkbox"
                                    onChange={this.onChange}
                                    checked={value_pricing}
                                  />

                                  <label
                                    htmlFor="value-pricing"
                                    className="custom-control-label"
                                  >
                                    Value Pricing
                                  </label>
                                </div>

                                <div className="custom-control custom-checkbox mb-2">
                                  <input
                                    id="preapproved-photographers"
                                    name="preapproved_providers"
                                    className="custom-control-input"
                                    type="checkbox"
                                    onChange={this.onChange}
                                    checked={preapproved_providers}
                                  />

                                  <label
                                    htmlFor="preapproved-photographers"
                                    className="custom-control-label"
                                  >
                                    Preapproved photographers
                                  </label>
                                </div>

                                <div className="custom-control custom-checkbox mb-2">
                                  <input
                                    id="raw-upload"
                                    name="raw_upload"
                                    className="custom-control-input"
                                    type="checkbox"
                                    onChange={this.onChange}
                                    checked={raw_upload}
                                  />

                                  <label
                                    htmlFor="raw-upload"
                                    className="custom-control-label"
                                  >
                                    Central editing
                                  </label>
                                </div>

                                <div className="custom-control custom-checkbox mb-2">
                                  <input
                                    id="end-customer-reminders-enabled"
                                    name="end_customer_reminders_enabled"
                                    className="custom-control-input"
                                    type="checkbox"
                                    onChange={this.onChange}
                                    checked={
                                      this.state.end_customer_reminders_enabled
                                    }
                                  />

                                  <label
                                    htmlFor="end-customer-reminders-enabled"
                                    className="custom-control-label"
                                  >
                                    End-customer reminders enabled
                                  </label>
                                </div>

                                <div className="custom-control custom-checkbox mb-2">
                                  <input
                                    id="half-hour-shoot-disabled"
                                    name="half_hour_shoot_disabled"
                                    className="custom-control-input"
                                    type="checkbox"
                                    onChange={(event) => {
                                      this.setState({
                                        half_hour_shoot_disabled:
                                          event.target.checked,
                                      });
                                    }}
                                    checked={
                                      this.state.half_hour_shoot_disabled
                                    }
                                  />

                                  <label
                                    htmlFor="half-hour-shoot-disabled"
                                    className="custom-control-label"
                                  >
                                    Half hour shoot disabled
                                  </label>
                                </div>
                              </div>
                            </div>

                            <div className="row  mb-4">
                              <div className="col">
                                <div className="card">
                                  <div className="card-body">
                                    <div className="row">
                                      <div className="col">
                                        <div className="custom-control custom-checkbox mb-2">
                                          <input
                                            id="override-rep-details"
                                            className="custom-control-input"
                                            type="checkbox"
                                            onChange={({
                                              target: { checked },
                                            }) =>
                                              this.setState({
                                                use_partner_details_override: !!checked,
                                              })
                                            }
                                            checked={
                                              this.state
                                                .use_partner_details_override
                                            }
                                          />

                                          <label
                                            htmlFor="override-rep-details"
                                            className="custom-control-label"
                                          >
                                            Use global partner details for
                                            end-customer messaging
                                          </label>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="form-group col-6">
                                        <label htmlFor="override_firstname">
                                          First name
                                        </label>

                                        <input
                                          disabled={
                                            !this.state
                                              .use_partner_details_override
                                          }
                                          id="override_firstname"
                                          className="form-control"
                                          type="text"
                                          value={
                                            this.state.override_firstname || ''
                                          }
                                          onChange={({ target: { value } }) =>
                                            this.setState({
                                              override_firstname:
                                                value === '' ? null : value,
                                            })
                                          }
                                        />
                                      </div>

                                      <div className="form-group col-6">
                                        <label htmlFor="override_last_name">
                                          Last name
                                        </label>

                                        <input
                                          disabled={
                                            !this.state
                                              .use_partner_details_override
                                          }
                                          id="override_last_name"
                                          className="form-control"
                                          type="text"
                                          value={
                                            this.state.override_last_name || ''
                                          }
                                          onChange={({ target: { value } }) =>
                                            this.setState({
                                              override_last_name:
                                                value === '' ? null : value,
                                            })
                                          }
                                        />
                                      </div>

                                      <div className="form-group col-6">
                                        <label htmlFor="override_mobilephone">
                                          Mobile phone
                                        </label>

                                        <input
                                          disabled={
                                            !this.state
                                              .use_partner_details_override
                                          }
                                          id="override_mobilephone"
                                          className="form-control"
                                          type="text"
                                          value={
                                            this.state.override_mobilephone ||
                                            ''
                                          }
                                          onChange={({ target: { value } }) =>
                                            this.setState({
                                              override_mobilephone:
                                                value === '' ? null : value,
                                            })
                                          }
                                        />
                                      </div>

                                      <div className="form-group col-6 mb-0">
                                        <label htmlFor="override_email">
                                          Email
                                        </label>

                                        <input
                                          disabled={
                                            !this.state
                                              .use_partner_details_override
                                          }
                                          id="override_email"
                                          className="form-control"
                                          type="text"
                                          value={
                                            this.state.override_email || ''
                                          }
                                          onChange={({ target: { value } }) =>
                                            this.setState({
                                              override_email:
                                                value === '' ? null : value,
                                            })
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col">
                                <div className="card">
                                  <div className="card-body">
                                    <div className="row form-group">
                                      <div className="col">
                                        <label htmlFor="">
                                          Contact details visible to artists
                                        </label>

                                        <select
                                          className="form-control"
                                          name="contact_details_visible_to_artist_type"
                                          type="text"
                                          onChange={this.onChange}
                                          value={
                                            this.state
                                              .contact_details_visible_to_artist_type ||
                                            'CREATOR_OF_JOB'
                                          }
                                        >
                                          {CONTACT_VISIBLE_TO_ARTIST_TYPE.map(
                                            (type) => (
                                              <option
                                                key={type.internal_value}
                                                value={type.internal_value}
                                              >
                                                {type.display_value}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="form-group col-6">
                                        <label htmlFor="contact_details_visible_to_artist_first_name">
                                          First name
                                        </label>

                                        <input
                                          disabled={
                                            this.state
                                              .contact_details_visible_to_artist_type !==
                                            'ALWAYS_SAME_CONTACT'
                                          }
                                          id="contact_details_visible_to_artist_first_name"
                                          className="form-control"
                                          type="text"
                                          value={
                                            this.state
                                              .contact_details_visible_to_artist_first_name ||
                                            ''
                                          }
                                          onChange={({ target: { value } }) =>
                                            this.setState({
                                              contact_details_visible_to_artist_first_name:
                                                value === '' ? null : value,
                                            })
                                          }
                                        />
                                      </div>

                                      <div className="form-group col-6">
                                        <label htmlFor="contact_details_visible_to_artist_last_name">
                                          Last name
                                        </label>

                                        <input
                                          disabled={
                                            this.state
                                              .contact_details_visible_to_artist_type !==
                                            'ALWAYS_SAME_CONTACT'
                                          }
                                          id="contact_details_visible_to_artist_last_name"
                                          className="form-control"
                                          type="text"
                                          value={
                                            this.state
                                              .contact_details_visible_to_artist_last_name ||
                                            ''
                                          }
                                          onChange={({ target: { value } }) =>
                                            this.setState({
                                              contact_details_visible_to_artist_last_name:
                                                value === '' ? null : value,
                                            })
                                          }
                                        />
                                      </div>

                                      <div className="form-group col-6">
                                        <label htmlFor="contact_details_visible_to_artist_phone_number">
                                          Mobile phone
                                        </label>

                                        <input
                                          disabled={
                                            this.state
                                              .contact_details_visible_to_artist_type !==
                                            'ALWAYS_SAME_CONTACT'
                                          }
                                          id="contact_details_visible_to_artist_phone_number"
                                          className="form-control"
                                          type="text"
                                          value={
                                            this.state
                                              .contact_details_visible_to_artist_phone_number ||
                                            ''
                                          }
                                          onChange={({ target: { value } }) =>
                                            this.setState({
                                              contact_details_visible_to_artist_phone_number:
                                                value === '' ? null : value,
                                            })
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="mb-4"></div>

                            {!saving && savingError != null && (
                              <div className="alert alert-danger">
                                <ErrorProcessor error={savingError}>
                                  {({ result }) => result || 'Unknown error'}
                                </ErrorProcessor>
                              </div>
                            )}

                            <button
                              type="submit"
                              className="btn btn-primary px-5"
                              disabled={
                                saving ||
                                (partner.default_shoottype ===
                                  default_shoottype &&
                                  partner.strict_mode === strict_mode &&
                                  partner.value_pricing === value_pricing &&
                                  partner.raw_upload === raw_upload &&
                                  partner.end_customer_shoot_guide_url ===
                                    end_customer_shoot_guide_url &&
                                  partner.shoot_guide_url === shoot_guide_url &&
                                  partner.preapproved_providers ===
                                    preapproved_providers &&
                                  partner.contract_url ===
                                    this.state.contract_url &&
                                  partner.cc_email === this.state.cc_email &&
                                  partner.custom_discount_percentage ===
                                    this.state.custom_discount_percentage &&
                                  partner.shoot_essentials ===
                                    this.state.shoot_essentials &&
                                  partner.end_customer_reminders_enabled ===
                                    this.state.end_customer_reminders_enabled &&
                                  // Global partner details for end-customer messaging
                                  partner.use_partner_details_override ===
                                    this.state.use_partner_details_override &&
                                  partner.override_firstname ===
                                    this.state.override_firstname &&
                                  partner.override_last_name ===
                                    this.state.override_last_name &&
                                  partner.override_mobilephone ===
                                    this.state.override_mobilephone &&
                                  partner.half_hour_shoot_disabled ===
                                    this.state.half_hour_shoot_disabled &&
                                  partner.override_email ===
                                    this.state.override_email &&
                                  partner.contact_details_visible_to_artist_type ===
                                    this.state
                                      .contact_details_visible_to_artist_type &&
                                  partner.contact_details_visible_to_artist_first_name ===
                                    this.state
                                      .contact_details_visible_to_artist_first_name &&
                                  partner.contact_details_visible_to_artist_last_name ===
                                    this.state
                                      .contact_details_visible_to_artist_last_name &&
                                  partner.contact_details_visible_to_artist_phone_number ===
                                    this.state
                                      .contact_details_visible_to_artist_phone_number)
                              }
                            >
                              Save
                            </button>
                          </React.Fragment>
                        )}
                      </form>
                    </React.Fragment>
                  )}
                </Promiser>
              </div>

              <div className="p-2" />

              <div className="flex-1">
                <div className="p-5 bg-white rounded h-100">
                  <div>
                    <h4>File uploader</h4>

                    <Uploader token={session.token} />
                  </div>

                  <div className="p-3"></div>

                  <div>
                    <h4>Activity export</h4>
                    {partner != null && (
                      <ActivityExportButton
                        session={session}
                        partner={partner}
                      />
                    )}
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
        </Fetcher>
      </div>
    );
  }
}
