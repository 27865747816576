import React from 'react';
import { Switch, Route, NavLink } from 'react-router-dom';
import styled from 'styled-components';

import BaseLayout from '../../components/BaseLayout';
import { BaseStyle } from '../../components/BaseStyle';

import { ListPartnersRoute } from './list';
import { ListPartnersShootsRoute } from './shoots';
import { EditRoute } from './edit';
import { checkIfDeveloper } from '../../utils';
import { AdjustmentRoute } from './charges';

const HeaderLinks = styled.div`
  a {
    text-decoration: none;
    margin-bottom: 2rem;

    h2 {
      margin: 0;
    }

    &.active {
      border-bottom: 3px #1f6fff solid;
    }
  }
`;

export const PartnersRoute = ({
  session,
  match,
  match: { path: basePath },
  location,
  history,
}) => (
  <BaseLayout session={session} match={match}>
    <BaseStyle className="flex flex-column flex-1">
      <div className="flex flex-column flex-1">
        <HeaderLinks className="d-flex">
          <NavLink exact to={basePath}>
            <h2>Partners</h2>
          </NavLink>

          <h2 style={{ marginLeft: 10, marginRight: 10, fontWeight: 'normal' }}>
            |
          </h2>

          <NavLink to={`${basePath}/shoots`}>
            <h2>Shoots</h2>
          </NavLink>
        </HeaderLinks>

        <Switch>
          <Route
            exact
            path={basePath}
            render={(props) => (
              <ListPartnersRoute {...props} session={session} />
            )}
          />

          <Route
            exact
            path={`${basePath}/shoots`}
            render={(props) => (
              <ListPartnersShootsRoute {...props} session={session} />
            )}
          />

          {checkIfDeveloper({ session }) && (
            <Route
              exact
              path={`${basePath}/adjustments`}
              render={(props) => (
                <AdjustmentRoute {...props} session={session} />
              )}
            />
          )}

          <Route
            exact
            path={`${basePath}/:partnerId`}
            render={(props) => <EditRoute {...props} session={session} />}
          />
        </Switch>
      </div>
    </BaseStyle>
  </BaseLayout>
);
