import React from 'react';

const ContentWrapper = ({ style = {}, ...props }) => (
  <div
    style={{
      borderRadius: 4,
      padding: '1em',
      backgroundColor: '#fff',
      boxShadow: '0 0 1.625em rgba(0,0,0,.05)',
      marginBottom: 20,
      overflowX: 'auto',
      ...style,
    }}
    {...props}
  />
);

export default ContentWrapper;
