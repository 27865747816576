import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import moment from 'moment-timezone';
import isFinite from 'lodash/isFinite';
import { gql, useQuery } from './ApolloClient';

import { apiFetch, formatMoneyCents } from '../utils';
import { Modal } from './Modal';

import { usePromise } from './usePromise';
import { locationForModal } from './ModalRoute';

import pdfIcon from './img/pdf-icon.svg';

const CONTRACT_DURATION = [
  { value: 'MONTHS_1', text: '1 month' },
  { value: 'MONTHS_2', text: '2 months' },
  { value: 'MONTHS_3', text: '3 months' },
  { value: 'MONTHS_12', text: '12 months' },
  { value: 'MONTHS_24', text: '24 months' },
];

type EnterpriseContractProps = {
  partnerId: string;
  currency: string;
  session: { token: string };
  onDismiss: () => void;
  onContinue: () => void;
  location;
  history;
  modalParams: {
    modalName: string;
    type?: string;
    commitmentCreditsCents?: string;
    discountPercentage?: string;
    startAt?: string;
    durationInMonths?: string;
    referralCommissionsPercentage?: string;
    subscriptionPlan?: string;
    subscriptionPlanDiscountPercentage?: string;
    pdfUrl?: string;
    pdfFileName?: string;
  };
};

type FormValues = {
  type: string;
  commitmentCreditsCents: number | '';
  discountPercentage: number | '';
  commitmentDollars: number | '';
  startAt: string;
  durationInMonths: string;
  referralCommissionsPercentage: number | '';
  subscriptionPlan: string;
  subscriptionPlanDiscountPercentage: number | '';
  pdfUrl: string;
  pdfFileName: string;
};

function validate(values) {
  const errors: {
    type?: string;
    commitmentCreditsCents?: string;
    startAt?: string;
    durationInMonths?: string;
    discountPercentage?: string;
    referralCommissionsPercentage?: string;
    subscriptionPlan?: string;
    subscriptionPlanDiscountPercentage?: string;
    pdfUrl?: string;
  } = {};

  if (values.type == null || values.type === '') {
    errors.type = 'Required';
  } else if (['MSA_SOW', 'SOW'].includes(values.type) === false) {
    errors.type = 'Invalid contract type';
  }

  if (
    values.commitmentCreditsCents == null ||
    values.commitmentCreditsCents === ''
  ) {
    errors.commitmentCreditsCents = 'Required';
  } else if (isFinite(values.commitmentCreditsCents) === false) {
    errors.commitmentCreditsCents = 'Must be a valid number';
  }

  if (values.discountPercentage == null || values.discountPercentage === '') {
    errors.discountPercentage = 'Required';
  } else if (isFinite(values.discountPercentage) === false) {
    errors.discountPercentage = 'Must be a valid number';
  } else if (values.discountPercentage < 0 || values.discountPercentage > 100) {
    errors.discountPercentage = 'Must be between 0 and 100';
  }

  if (values.startAt == null || values.startAt === '') {
    errors.startAt = 'Required';
  } else if (moment(values.startAt).isValid() === false) {
    errors.startAt = 'Invalid date';
  }

  const validTermValues = CONTRACT_DURATION.map((durationInMonths) =>
    durationInMonths.value.toString()
  );
  if (values.durationInMonths == null || values.durationInMonths === '') {
    errors.durationInMonths = 'Required';
  } else if (validTermValues.includes(values.durationInMonths) === false) {
    errors.durationInMonths = 'Invalid durationInMonths';
  }

  if (
    values.referralCommissionsPercentage !== '' &&
    isFinite(values.referralCommissionsPercentage) === false
  ) {
    errors.referralCommissionsPercentage = 'Must be a valid number';
  } else if (
    values.referralCommissionsPercentage < 0 ||
    values.referralCommissionsPercentage > 100
  ) {
    errors.referralCommissionsPercentage = 'Must be between 0 and 100';
  }

  if (values.subscriptionPlan == null || values.subscriptionPlan === '') {
    errors.subscriptionPlan = 'Required';
  }

  if (
    values.subscriptionPlanDiscountPercentage == null ||
    values.subscriptionPlanDiscountPercentage === ''
  ) {
    errors.subscriptionPlanDiscountPercentage = 'Required';
  } else if (isFinite(values.subscriptionPlanDiscountPercentage) === false) {
    errors.subscriptionPlanDiscountPercentage = 'Must be a valid number';
  } else if (
    values.subscriptionPlanDiscountPercentage < 0 ||
    values.subscriptionPlanDiscountPercentage > 100
  ) {
    errors.subscriptionPlanDiscountPercentage = 'Must be between 0 and 100';
  }

  if (values.pdfUrl == null || values.pdfUrl === '') {
    errors.pdfUrl = 'Required';
  }

  return errors;
}

export const EnterpriseContractModalFirst = ({
  partnerId,
  currency,
  session,
  location,
  history,
  onDismiss,
  onContinue,
  modalParams,
}: EnterpriseContractProps) => {
  const [uploadProgress, setUploadProgress] = React.useState<
    number | undefined
  >(undefined);

  const promiserDropZone = usePromise();

  function onSubmit(values) {
    onContinue();
  }

  const partnerSubscriptionsQuery = useQuery<{
    partnerById: {
      availableSubscriptions: {
        edges: {
          id: string;
          slug: string;
          description: string;
          displayName: string;
          tier: string;
          price: number;
        }[];
      };
    };
  }>(
    gql`
      query partnerSubscriptionsQuery($partnerId: UUID!) {
        partnerById(uid: $partnerId) {
          availableSubscriptions {
            edges {
              id
              slug
              description
              displayName
              tier
              price
            }
          }
        }
      }
    `,
    {
      variables: {
        partnerId,
      },
      skip: partnerId == null,
    }
  );

  const subscriptionPlans =
    partnerSubscriptionsQuery?.data?.partnerById?.availableSubscriptions?.edges;

  const sortedPlans =
    subscriptionPlans != null
      ? [...subscriptionPlans]?.sort((a, b) => (a.price > b.price ? -1 : 1))
      : null;

  const commitmentDollars =
    modalParams.commitmentCreditsCents != null &&
    modalParams.discountPercentage != null
      ? parseInt(modalParams.commitmentCreditsCents) /
        parseFloat(modalParams.discountPercentage)
      : '';

  const initialValues: FormValues = {
    type: modalParams.type ?? '',
    commitmentCreditsCents:
      modalParams.commitmentCreditsCents != null
        ? parseInt(modalParams.commitmentCreditsCents)
        : '',
    startAt: modalParams.startAt ?? '',
    durationInMonths: modalParams.durationInMonths ?? '',
    discountPercentage:
      modalParams.discountPercentage != null
        ? parseFloat(modalParams.discountPercentage)
        : '',
    referralCommissionsPercentage:
      modalParams.referralCommissionsPercentage != null
        ? parseFloat(modalParams.referralCommissionsPercentage)
        : '',

    subscriptionPlanDiscountPercentage:
      modalParams.subscriptionPlanDiscountPercentage != null
        ? parseFloat(modalParams.subscriptionPlanDiscountPercentage)
        : '',
    subscriptionPlan: modalParams.subscriptionPlan ?? '',
    pdfUrl: modalParams.pdfUrl ?? '',
    pdfFileName: modalParams.pdfFileName ?? '',
    commitmentDollars,
  };

  return (
    <Modal onDismiss={onDismiss}>
      <div className="card my-4">
        <h4 className="mb-0 ml-4 mt-4">Create a contract</h4>

        <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={onSubmit}
        >
          {({
            values,
            touched,
            errors,
            isValid,
            handleChange,
            setFieldValue,
          }) => (
            <Form
              className="card-body d-flex flex-column"
              style={{ width: '1000px' }}
            >
              <div className="flex-1" style={{ position: 'relative' }}>
                <div className="row">
                  <div className="form-group col-12">
                    <label
                      className={
                        touched.type && errors.type
                          ? 'text-danger'
                          : 'text-secondary'
                      }
                    >
                      Type
                    </label>

                    <div className="form-check">
                      <Field
                        name="type"
                        type="radio"
                        id="type-MSA-SOW"
                        className="form-check-input"
                        value="MSA_SOW"
                        onChange={(e) => {
                          handleChange(e);
                          history.replace(
                            locationForModal({
                              location,
                              modal: {
                                ...modalParams,
                                type: e.target.value,
                              },
                            })
                          );
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="type-MSA-SOW"
                      >
                        Master Service Agreement (MSA) and Statement of Work
                        (SOW)
                      </label>
                    </div>
                    <div className="form-check">
                      <Field
                        name="type"
                        type="radio"
                        id="type-SOW"
                        className="form-check-input"
                        value="SOW"
                        onChange={(e) => {
                          handleChange(e);
                          history.replace(
                            locationForModal({
                              location,
                              modal: {
                                ...modalParams,
                                type: e.target.value,
                              },
                            })
                          );
                        }}
                      />
                      <label className="form-check-label" htmlFor="type-SOW">
                        Statement of Work (SOW)
                      </label>
                    </div>

                    <ErrorMessage name="type">
                      {(msg) => (
                        <p className="form-group mb-0 text-danger">{msg}</p>
                      )}
                    </ErrorMessage>
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col">
                    <label
                      className={
                        touched.commitmentCreditsCents &&
                        errors.commitmentCreditsCents
                          ? 'text-danger'
                          : 'text-secondary'
                      }
                    >
                      Commitment in credits
                    </label>

                    <Field
                      name="commitmentCreditsCents"
                      type="number"
                      className={`form-control ${
                        touched.commitmentCreditsCents &&
                        errors.commitmentCreditsCents
                          ? 'border-danger'
                          : ''
                      }`}
                      placeholder="e.g. 100,000"
                      onChange={(e) => {
                        handleChange(e);
                        history.replace(
                          locationForModal({
                            location,
                            modal: {
                              ...modalParams,
                              commitmentCreditsCents: e.target.value,
                            },
                          })
                        );
                      }}
                    />
                    <ErrorMessage name="commitmentCreditsCents">
                      {(msg) => <p className="text-danger mt-2 mb-0">{msg}</p>}
                    </ErrorMessage>
                  </div>

                  <div className="form-group col">
                    <label
                      className={
                        touched.discountPercentage && errors.discountPercentage
                          ? 'text-danger'
                          : 'text-secondary'
                      }
                    >
                      Credit discount (%)
                    </label>

                    <Field
                      name="discountPercentage"
                      type="number"
                      className={`form-control ${
                        touched.discountPercentage && errors.discountPercentage
                          ? 'border-danger'
                          : ''
                      }`}
                      placeholder="e.g. 10%"
                      onChange={(e) => {
                        handleChange(e);
                        history.replace(
                          locationForModal({
                            location,
                            modal: {
                              ...modalParams,
                              discountPercentage: e.target.value,
                            },
                          })
                        );
                      }}
                    />
                    <ErrorMessage name="discountPercentage">
                      {(msg) => <p className="text-danger mt-2 mb-0">{msg}</p>}
                    </ErrorMessage>
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col">
                    <label
                      className={
                        touched.startAt && errors.startAt
                          ? 'text-danger'
                          : 'text-secondary'
                      }
                    >
                      Execution date
                    </label>

                    <Field
                      name="startAt"
                      type="date"
                      className={`form-control ${
                        touched.startAt && errors.startAt ? 'border-danger' : ''
                      }`}
                      onChange={(e) => {
                        handleChange(e);
                        history.replace(
                          locationForModal({
                            location,
                            modal: {
                              ...modalParams,
                              startAt: e.target.value,
                            },
                          })
                        );
                      }}
                    />
                    <ErrorMessage name="startAt">
                      {(msg) => <p className="text-danger mt-2 mb-0">{msg}</p>}
                    </ErrorMessage>
                  </div>

                  <div className="form-group col">
                    <label
                      className={
                        touched.durationInMonths && errors.durationInMonths
                          ? 'text-danger'
                          : 'text-secondary'
                      }
                    >
                      Term
                    </label>

                    <Field
                      name="durationInMonths"
                      as="select"
                      className={`form-control ${
                        touched.durationInMonths && errors.durationInMonths
                          ? 'border-danger'
                          : ''
                      }`}
                      onChange={(e) => {
                        handleChange(e);
                        history.replace(
                          locationForModal({
                            location,
                            modal: {
                              ...modalParams,
                              durationInMonths: e.target.value,
                            },
                          })
                        );
                      }}
                    >
                      <option key="default" value="">
                        Select a term
                      </option>
                      {CONTRACT_DURATION.map((duration) => (
                        <option key={duration.value} value={duration.value}>
                          {duration.text}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage name="durationInMonths">
                      {(msg) => <p className="text-danger mt-2 mb-0">{msg}</p>}
                    </ErrorMessage>
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col">
                    <label
                      className={
                        touched.subscriptionPlan && errors.subscriptionPlan
                          ? 'text-danger'
                          : 'text-secondary'
                      }
                    >
                      Plan
                    </label>

                    <Field
                      name="subscriptionPlan"
                      as="select"
                      className={`form-control ${
                        touched.subscriptionPlan && errors.subscriptionPlan
                          ? 'border-danger'
                          : ''
                      }`}
                      onChange={(e) => {
                        handleChange(e);
                        history.replace(
                          locationForModal({
                            location,
                            modal: {
                              ...modalParams,
                              subscriptionPlan: e.target.value,
                            },
                          })
                        );
                      }}
                    >
                      <option key="default" value="">
                        Select a plan
                      </option>
                      {sortedPlans?.map((plan) => (
                        <option key={plan.id} value={plan.id}>
                          {plan.displayName} -{' '}
                          {formatMoneyCents(plan.price, { currency })}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage name="subscriptionPlan">
                      {(msg) => <p className="text-danger mt-2 mb-0">{msg}</p>}
                    </ErrorMessage>
                  </div>

                  <div className="form-group col">
                    <label
                      className={
                        touched.subscriptionPlanDiscountPercentage &&
                        errors.subscriptionPlanDiscountPercentage
                          ? 'text-danger'
                          : 'text-secondary'
                      }
                    >
                      Plan discount
                    </label>

                    <Field
                      name="subscriptionPlanDiscountPercentage"
                      type="number"
                      className={`form-control ${
                        touched.subscriptionPlanDiscountPercentage &&
                        errors.subscriptionPlanDiscountPercentage
                          ? 'border-danger'
                          : ''
                      }`}
                      placeholder="e.g. 10%"
                      onChange={(e) => {
                        handleChange(e);
                        history.replace(
                          locationForModal({
                            location,
                            modal: {
                              ...modalParams,
                              subscriptionPlanDiscountPercentage:
                                e.target.value,
                            },
                          })
                        );
                      }}
                    />
                    <ErrorMessage name="subscriptionPlanDiscountPercentage">
                      {(msg) => <p className="text-danger mt-2 mb-0">{msg}</p>}
                    </ErrorMessage>
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col">
                    <label
                      className={
                        touched.referralCommissionsPercentage &&
                        errors.referralCommissionsPercentage
                          ? 'text-danger'
                          : 'text-secondary'
                      }
                    >
                      Referral commissions (%) (Optional)
                    </label>

                    <Field
                      name="referralCommissionsPercentage"
                      type="number"
                      className={`form-control ${
                        touched.referralCommissionsPercentage &&
                        errors.referralCommissionsPercentage
                          ? 'border-danger'
                          : ''
                      }`}
                      placeholder="e.g. 10%"
                      onChange={(e) => {
                        handleChange(e);
                        history.replace(
                          locationForModal({
                            location,
                            modal: {
                              ...modalParams,
                              referralCommissionsPercentage: e.target.value,
                            },
                          })
                        );
                      }}
                    />
                    <ErrorMessage name="referralCommissionsPercentage">
                      {(msg) => <p className="text-danger mt-2 mb-0">{msg}</p>}
                    </ErrorMessage>
                  </div>

                  <div className="form-group col-6">
                    <label className="text-secondary">
                      Commitment in {currency}
                    </label>

                    <Field
                      name="commitmentDollars"
                      type="text"
                      className="form-control"
                      value={
                        isFinite(values.commitmentCreditsCents) === true &&
                        isFinite(values.discountPercentage) === true
                          ? parseInt(values.commitmentCreditsCents) *
                            (1 - parseFloat(values.discountPercentage) / 100)
                          : undefined
                      }
                      placeholder="e.g. 90,000.00 USD"
                      readOnly={true}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-12">
                    <label className="text-secondary">PDF:</label>

                    {modalParams.pdfFileName != null &&
                    modalParams.pdfUrl != null ? (
                      <div
                        className="form-control"
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          backgroundColor: '#F6F9FF',
                          border: '1px solid #C1C8D4',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div className="d-flex text-center">
                          <img
                            src={pdfIcon}
                            alt="pdf"
                            style={{
                              width: 35,
                            }}
                          />

                          <div className="d-flex" style={{ width: 250 }}>
                            <span
                              style={{
                                textOverflow: 'ellipsis',
                                display: 'inline-block',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                paddingRight: '0.5em',
                              }}
                            >
                              {modalParams.pdfFileName}
                            </span>
                          </div>
                        </div>

                        <button
                          style={{
                            backgroundColor: 'transparent',
                            borderWidth: 0,
                            paddingTop: 4,
                          }}
                          onClick={(event) => {
                            setUploadProgress(undefined);
                            history.replace(
                              locationForModal({
                                location,
                                modal: {
                                  ...modalParams,
                                  pdfUrl: undefined,
                                  pdfFileName: undefined,
                                },
                              })
                            );
                          }}
                          className="d-flex text-center align-center"
                        >
                          <i
                            className="fa fa-times text-muted fa-lg"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    ) : (
                      <Dropzone
                        accept="application/pdf"
                        style={{
                          border: '1px dashed #C1C8D4',
                          borderRadius: '4px',
                          height: 160,
                        }}
                        multiple={false}
                        disabled={promiserDropZone.isPending}
                        onDrop={(acceptedFiles) => {
                          if (acceptedFiles.length === 0) return;

                          const axiosCancelSource = axios.CancelToken.source();
                          promiserDropZone.setPromise(
                            apiFetch(
                              `/api/v2/media/presigned-post-public-asset`,
                              {
                                token: session.token,
                                method: 'POST',
                                body: JSON.stringify({
                                  fileType: acceptedFiles[0].type,
                                  fileName: acceptedFiles[0].name,
                                }),
                              }
                            )
                              .then(
                                ({ signedUrl, key }) =>
                                  acceptedFiles != null &&
                                  axios
                                    .put(signedUrl, acceptedFiles[0], {
                                      onUploadProgress: (progressEvent) => {
                                        setUploadProgress(
                                          Math.round(
                                            (progressEvent.loaded * 100) /
                                              progressEvent.total
                                          )
                                        );
                                      },
                                      cancelToken: axiosCancelSource.token,
                                      headers: {
                                        'Content-Type': acceptedFiles[0].type,
                                      },
                                    })
                                    .then(() =>
                                      apiFetch(
                                        `/api/v2/media/get-public-asset-url/${encodeURIComponent(
                                          key
                                        )}`,
                                        {
                                          token: session.token,
                                        }
                                      )
                                    )
                              )
                              .then(({ url }) => {
                                setFieldValue('pdfUrl', url);
                                setFieldValue(
                                  'pdfFileName',
                                  acceptedFiles[0].name
                                );

                                history.replace(
                                  locationForModal({
                                    location,
                                    modal: {
                                      ...modalParams,
                                      pdfUrl: url,
                                      pdfFileName: acceptedFiles[0].name,
                                    },
                                  })
                                );
                              })
                          );
                        }}
                      >
                        {({ isDragReject, rejectedFiles }) => (
                          <div
                            className="card-body text-center"
                            style={{ marginTop: 30 }}
                          >
                            {uploadProgress == null ? (
                              <div
                                className={
                                  isDragReject === true ||
                                  rejectedFiles.length > 0
                                    ? 'text-danger'
                                    : ''
                                }
                              >
                                <p>
                                  Drag and drop your PDF here, <br />
                                  <span style={{ textDecoration: 'underline' }}>
                                    or browse
                                  </span>
                                </p>
                                {(isDragReject === true ||
                                  rejectedFiles.length > 0) && (
                                  <p>Only PDF files are supported.</p>
                                )}
                              </div>
                            ) : (
                              <span className="text-secondary">
                                Progress: {uploadProgress}%
                              </span>
                            )}
                          </div>
                        )}
                      </Dropzone>
                    )}
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-between mt-3">
                <button
                  type="submit"
                  className="btn btn-block btn-dark"
                  disabled={isValid === false}
                >
                  Continue
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      <style jsx>{`
        label.text-secondary,
        label.text-danger {
          font-size: 0.8rem;
        }

        p.text-danger {
          font-size: 0.8rem;
        }
      `}</style>
    </Modal>
  );
};
