import React from 'react';
import { Formik, Form, Field, ErrorMessage, FormikProps } from 'formik';
import moment from 'moment-timezone';
import qs from 'query-string';
import { Link, Switch, Route } from 'react-router-dom';
import Component2 from '@reach/component-component';
import Select from 'react-select';

import { useQuery, gql, useMutation } from '../components/ApolloClient';
import BaseLayout from '../components/BaseLayout';
import { LoadingSpinnerCentered } from '../components/LoadingSpinner';
import { Pagination } from '../components/Pagination';
import { ensureString } from '../utils/strings';
import { APP_URL } from '../config';

const LISTING_SIMULATIONS_PER_PAGE = 50;

const ListingSimulationsList = gql`
  query ListingSimulationGetAllFromAdmin(
    $offset: Int
    $limit: Int
    $search: String
    $status: String
  ) {
    listingSimulationsGetAll(
      offset: $offset
      limit: $limit
      search: $search
      status: $status
    ) {
      edges {
        id
        name
        createdAt
        listingUrl
        editingJob {
          id
        }
        companyName
        status
        rendererUrl
        createdBy {
          fullName
        }
      }
      count
    }
  }
`;

type ListingSimulationQuery = {
  offset: string;
  q: string;
  limit: string;
  status: string;
};

type ListingSimulation = {
  id?: string;
  name?: string;
  createdAt?: string;
  listingUrl?: string;
  editingJob?: { id: string };
  companyName?: string;
  status?: string;
  rendererUrl?: string;
  createdBy: { fullName: string };
};

type ListingSimulationConnection = {
  edges: [ListingSimulation];
  count: number;
};
type ListingSimulationResponse = {
  listingSimulationsGetAll: ListingSimulationConnection;
};

function ListingSimulationListRoute({
  session,
  match,
  location,
  history,
  rootBaseUrl,
}) {
  const { offset, q, status, limit: limitString } = qs.parse(
    location.search
  ) as ListingSimulationQuery;
  const limit = parseInt(limitString ?? LISTING_SIMULATIONS_PER_PAGE, 10);
  const { data, loading, error, refetch } = useQuery<ListingSimulationResponse>(
    ListingSimulationsList,
    {
      variables: {
        offset: parseInt(offset, 10),
        limit: limit,
        search: q,
        status: status,
      },
    }
  );

  const statusOptions = [
    { label: 'CREATED', value: 'CREATED' },
    { label: 'CRAWLING_IMAGES', value: 'CRAWLING_IMAGES' },
    { label: 'EDITING_JOB_CREATED', value: 'EDITING_JOB_CREATED' },
    { label: 'COMPLETED', value: 'COMPLETED' },
  ];

  const [enableListingPublicGallery] = useMutation(gql`
    mutation ListingSimulationEnablePublicGalleryFromAdmin(
      $listingSimulationId: String!
    ) {
      listingSimulationEnablePublicGallery(
        input: { listingSimulationId: $listingSimulationId }
      ) {
        listingSimulation {
          id
        }
      }
    }
  `);

  async function handleEnablePublicGallery(listingSimulationId: string) {
    await enableListingPublicGallery({
      variables: {
        listingSimulationId,
      },
    });
    await refetch();
  }

  const [refreshListingStatus] = useMutation(gql`
    mutation ListingSimulationRefreshStatusFromAdmin(
      $listingSimulationId: String!
    ) {
      listingSimulationRefreshStatus(
        input: { listingSimulationId: $listingSimulationId }
      ) {
        listingSimulation {
          id
        }
      }
    }
  `);

  async function handleRefreshStatus(listingSimulationId: string) {
    await refreshListingStatus({
      variables: {
        listingSimulationId,
      },
    });
    await refetch();
  }

  const { uid, token, expire } = session;
  const authToken = btoa([uid, token, expire].join(':'));

  const customStyles = {
    valueContainer: (provided, state) => ({
      ...provided,
      marginLeft: 70,
    }),
  };

  const MultiSelectFiler = ({
    filterName = 'status',
    options,
    location,
    history,
  }: {
    filterName?: string;
    options;
    location;
    history;
  }) => {
    const filterString = qs.parse(location.search)[filterName];
    const selectedValues = filterString
      ? ensureString(filterString ?? '')?.split(',')
      : [];

    const selectedOptions =
      selectedValues && options.filter((o) => selectedValues.includes(o.value));
    return (
      <div
        style={{
          minWidth: 150,
          marginRight: 16,
          position: 'relative',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: 7,
            left: 10,
            zIndex: 2,
            textTransform: 'capitalize',
            color: '#C1C8D4',
          }}
        >
          {filterName}
        </div>
        <div style={{ minWidth: 100 }}>
          <Select
            isMulti
            value={selectedOptions}
            options={options}
            styles={customStyles}
            placeholder=""
            onChange={(selectedOptions) => {
              const selected = (selectedOptions || []).map((s) => s.value);

              history.push({
                ...location,
                search: qs.stringify({
                  ...qs.parse(location.search),
                  [filterName]:
                    selected.length > 0 ? selected.join(',') : undefined,
                }),
              });
            }}
          />
        </div>
      </div>
    );
  };

  if (loading) return <LoadingSpinnerCentered />;
  if (error) return <p>Error</p>;

  return (
    <>
      <h2>Listing simulations</h2>

      <div className="row">
        <div className="col-12">
          <div className="d-flex justify-content-between align-items-center bg-white rounded mb-2 p-2">
            <div className="d-flex align-items-center">
              <Link className="btn btn-primary" to={`${rootBaseUrl}/new`}>
                New listing simulation
              </Link>
            </div>

            <div className="d-flex justify-content-between">
              <MultiSelectFiler
                options={statusOptions}
                location={location}
                history={history}
              />
              <Component2
                search={qs.parse(location.search)}
                initialState={{ q: null }}
                didMount={({
                  props: {
                    search: { q },
                  },
                  setState,
                }) => setState({ q })}
                didUpdate={({
                  props: {
                    search: { q },
                  },
                  props,
                  prevProps,
                  setState,
                }) => props.search.q !== prevProps.search.q && setState({ q })}
              >
                {({ state, setState }) => (
                  <form
                    className="d-flex justify-content-end"
                    onSubmit={(ev) => {
                      ev.preventDefault();
                      history.push({
                        ...location,
                        search: qs.stringify({
                          ...qs.parse(location.search),
                          q: state.q || undefined,
                        }),
                      });
                    }}
                  >
                    <input
                      type="text"
                      className="form-control"
                      style={{ maxWidth: 300 }}
                      placeholder="Search"
                      value={state.q || ''}
                      onChange={({ target: { value } }) =>
                        setState({ q: value })
                      }
                    />
                  </form>
                )}
              </Component2>
              <div className="d-inline-flex ml-2">
                <select
                  className="custom-select"
                  style={{
                    appearance: 'none',
                    WebkitAppearance: 'none',
                    MozAppearance: 'none',
                  }}
                  defaultValue={
                    qs.parse(location.search).limit ||
                    LISTING_SIMULATIONS_PER_PAGE
                  }
                  onChange={({ target: { value } }) =>
                    history.push({
                      ...location,
                      search: qs.stringify({
                        ...qs.parse(location.search),
                        limit: Number(value),
                        providers: undefined,
                      }),
                    })
                  }
                >
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={30}>30</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="d-flex bg-white p-2 flex-column position-relative">
            <div className="table-responsive " style={{ position: 'relative' }}>
              <table className="table table-hover mb-0">
                <thead>
                  <tr>
                    <th className="text-muted text-truncate">Name</th>
                    <th className="text-muted text-truncate">Company</th>
                    <th className="text-muted text-truncate">Listing url</th>
                    <th className="text-muted text-truncate">Editing job id</th>
                    <th className="text-muted text-truncate">
                      Editing job links
                    </th>
                    <th className="text-muted text-truncate">Status</th>
                    <th className="text-muted text-truncate">Created by</th>
                    <th className="text-muted text-truncate">Created at</th>
                    <th className="text-muted text-truncate">
                      Publish gallery
                    </th>
                    <th className="text-muted text-truncate">Public Gallery</th>
                    <th className="text-muted text-truncate"></th>
                  </tr>
                </thead>

                <tbody style={{ color: '#6C757D' }}>
                  {data?.listingSimulationsGetAll?.edges?.map?.(
                    ({
                      id,
                      name,
                      createdAt,
                      listingUrl,
                      editingJob,
                      companyName,
                      status,
                      rendererUrl,
                      createdBy,
                    }) => (
                      <React.Fragment key={id}>
                        <tr>
                          <td className="align-middle">
                            {rendererUrl !== '' ? (
                              <a href={rendererUrl} target="_blank">
                                {name}
                              </a>
                            ) : (
                              name
                            )}
                          </td>
                          <td className="align-middle">{companyName}</td>
                          <td
                            className="align-middle"
                            style={{ whiteSpace: 'nowrap' }}
                          >
                            <a href={listingUrl} target="_blank">
                              Link
                            </a>
                          </td>

                          <td
                            className="align-middle"
                            style={{ whiteSpace: 'nowrap' }}
                          >
                            {editingJob?.id}
                          </td>
                          <td
                            className="align-middle"
                            style={{ whiteSpace: 'nowrap' }}
                          >
                            {editingJob?.id != null ? (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                              >
                                <Link
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  to={`editing-jobs/${editingJob.id}/details`}
                                >
                                  Admin Link
                                </Link>
                                <a
                                  // href={`${APP_URL}/auth-and-redirect?${qs.stringify(
                                  //   {
                                  //     authToken,
                                  //     next: `/enterprise/jobs/e/${editingJob?.id}?partner=${partnerId}`,
                                  //   }
                                  // )}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Portal Link
                                </a>
                              </div>
                            ) : null}
                          </td>
                          <td className="align-middle">{status}</td>
                          <td
                            className="align-middle"
                            style={{ whiteSpace: 'nowrap' }}
                          >
                            {createdBy?.fullName}
                          </td>
                          <td
                            className="align-middle"
                            style={{ whiteSpace: 'nowrap' }}
                          >
                            {moment(createdAt).format('DD MMMM YYYY, HH:MM Z')}
                          </td>

                          <td className="align-middle">
                            {id != null && (
                              <button
                                className="btn btn-primary mx-2"
                                onClick={() => handleEnablePublicGallery(id)}
                                disabled={status != 'COMPLETED'}
                              >
                                Enable
                              </button>
                            )}
                          </td>
                          <td className="align-middle">
                            {editingJob?.id !== null ? (
                              <a
                                href={`${APP_URL}/editing-job-gallery/${editingJob?.id}`}
                                target="_blank"
                              >
                                Link
                              </a>
                            ) : null}
                          </td>
                          <td className="align-middle">
                            {id != null && (
                              <button
                                className="btn btn-secondary mx-2"
                                onClick={() => handleRefreshStatus(id)}
                              >
                                Refresh
                              </button>
                            )}
                          </td>
                        </tr>
                      </React.Fragment>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="d-flex justify-content-end mt-4">
            <Pagination
              limit={limit}
              offset={parseInt(
                ensureString(qs.parse(location.search).offset) || '0',
                10
              )}
              count={data?.listingSimulationsGetAll?.count}
              linkForOffset={(offset, { disabled, ...props }) => (
                <Link
                  className="page-link"
                  to={{
                    ...location,
                    search: qs.stringify({
                      ...qs.parse(location.search),
                      offset: offset || undefined,
                    }),
                  }}
                  {...props}
                />
              )}
            />
          </div>
        </div>
      </div>
    </>
  );
}

type ListingSimulationCreation = {
  listingUrl: string;
  companyName: string;
  name: string;
};

function validate(values: ListingSimulationCreation) {
  const errors: {
    listingUrl?: string;
    companyName?: string;
    name?: string;
  } = {};

  if (values.listingUrl == null || values.listingUrl === '') {
    errors.listingUrl = 'Required';
  }

  if (values.companyName == null || values.companyName === '') {
    errors.companyName = 'Required';
  }
  if (values.name == null || values.name === '') {
    errors.name = 'Required';
  }

  return errors;
}

function ListingSimulationRoute({
  match: {
    params: { listingSimulationId },
  },
  session: { token } = {},
  history,
  location,
  rootBaseUrl,
}: {
  match;
  history;
  location;
  rootBaseUrl;
  session;
}) {
  const [processingResponse, setProcessingResponse] = React.useState<boolean>(
    false
  );

  const [
    createListingSimulation,
    { data, loading: creationLoading, error: creationError },
  ] = useMutation(gql`
    mutation ListingSimulationCreateFromAdmin(
      $listingUrl: String!
      $companyName: String!
      $name: String!
    ) {
      listingSimulationCreate(
        input: {
          listingUrl: $listingUrl
          companyName: $companyName
          name: $name
        }
      ) {
        listingSimulation {
          id
        }
      }
    }
  `);

  async function onSubmit(values: ListingSimulationCreation) {
    setProcessingResponse(true);

    await createListingSimulation({
      variables: {
        listingUrl: values.listingUrl,
        companyName: values.companyName,
        name: values.name,
      },
    });

    if (creationError == null) {
      window.location.assign(rootBaseUrl);
    } else {
      setProcessingResponse(false);
    }
  }

  const initialValues: ListingSimulationCreation = {
    listingUrl: '',
    companyName: '',
    name: '',
  };

  const loading = creationLoading || processingResponse;

  return (
    <>
      {!loading && creationError && (
        <div className="alert alert-danger">
          {creationError?.graphQLErrors[0]?.message ??
            'An unexpected error has ocurred'}
        </div>
      )}

      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmit}
      >
        {(formData: FormikProps<ListingSimulationCreation>) => (
          <Form
            className="card-body d-flex flex-column"
            style={{ width: '1000px' }}
          >
            <div className="row mb-4 d-flex align-items-stretch">
              <div className="col-6 d-flex flex-column">
                <div className="card flex-1">
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between mb-2">
                      <h3 className="mb-0">Listing simulation</h3>
                    </div>

                    <div className="form-group">
                      <label>
                        <small className="text-secondary">
                          Listing simulation name
                        </small>
                      </label>

                      <Field
                        type="text"
                        className={`form-control ${
                          formData.touched.name && formData.errors.name
                            ? 'border-danger'
                            : ''
                        }`}
                        disabled={loading}
                        onChange={formData.handleChange}
                        onBlur={formData.handleBlur}
                        name="name"
                        value={formData.values.name || ''}
                        style={{ backgroundImage: 'none' }}
                        placeholder="e.g. My Listing Simulation Name"
                      />
                      <ErrorMessage name="name">
                        {(msg) => (
                          <p className="text-danger mt-2 mb-0">{msg}</p>
                        )}
                      </ErrorMessage>
                    </div>

                    <div className="form-group">
                      <label>
                        <small className="text-secondary">Company name</small>
                      </label>

                      <Field
                        type="text"
                        className={`form-control ${
                          formData.touched.companyName &&
                          formData.errors.companyName
                            ? 'border-danger'
                            : ''
                        }`}
                        disabled={loading}
                        onChange={formData.handleChange}
                        onBlur={formData.handleBlur}
                        name="companyName"
                        value={formData.values.companyName || ''}
                        style={{ backgroundImage: 'none' }}
                        placeholder="e.g. My Company Name"
                      />
                      <ErrorMessage name="companyName">
                        {(msg) => (
                          <p className="text-danger mt-2 mb-0">{msg}</p>
                        )}
                      </ErrorMessage>
                    </div>

                    <div className="form-group">
                      <label>
                        <small className="text-secondary">Listing URL</small>
                      </label>

                      <Field
                        type="text"
                        disabled={loading}
                        onChange={formData.handleChange}
                        onBlur={formData.handleBlur}
                        name="listingUrl"
                        value={formData.values.listingUrl || ''}
                        className={`form-control ${
                          formData.touched.companyName &&
                          formData.errors.companyName
                            ? 'border-danger'
                            : ''
                        }`}
                        style={{ backgroundImage: 'none' }}
                        placeholder="e.g. https://company.listing.com/listingId"
                      />
                      <ErrorMessage name="listingUrl">
                        {(msg) => (
                          <p className="text-danger mt-2 mb-0">{msg}</p>
                        )}
                      </ErrorMessage>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="bg-white p-3"
              style={{
                position: 'fixed',
                bottom: 0,
                right: 0,
                left: 0,
                marginLeft: 200,
                boxShadow: '0 0 1.625em rgba(0,0,0,.05)',
              }}
            >
              <div className="d-flex align-items-baseline justify-content-between">
                <div />

                <button
                  className="btn btn-success"
                  disabled={loading}
                  type="submit"
                >
                  {loading ? 'Creating...' : 'Create'}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

export function ListingSimulationsRoute({
  session,
  match,
  match: { path: basePath, url: baseUrl },
}) {
  return (
    <BaseLayout session={session} match={match}>
      <Switch>
        <Route
          exact
          path={basePath}
          render={(props) => (
            <ListingSimulationListRoute
              {...props}
              session={session}
              rootBaseUrl={baseUrl}
            />
          )}
        />

        <Route
          path={`${basePath}/:listingSimulationId`}
          render={(props) => (
            <ListingSimulationRoute
              {...props}
              session={session}
              rootBaseUrl={baseUrl}
            />
          )}
        />
      </Switch>
    </BaseLayout>
  );
}
