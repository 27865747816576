import React from 'react';
import qs from 'query-string';
import { Promiser } from 'react-prometo';

import { apiFetch } from '../utils';

export class Fetcher extends React.Component {
  state = { fetchPromise: null };

  componentDidMount() {
    this.linkPromise(this.props);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      ['urlToFetch', 'disabled'].find(
        (propName) => prevProps[propName] !== this.props[propName]
      ) ||
      qs.stringify(prevProps.queryToFetch) !==
        qs.stringify(this.props.queryToFetch)
    ) {
      this.linkPromise(this.props);
    }
  }

  linkPromise({
    urlToFetch,
    queryToFetch = {},
    session: { token } = {},
    disabled,
  }) {
    const fetchPromise = disabled
      ? null
      : apiFetch(urlToFetch, { token, query: queryToFetch });
    this.setState({ fetchPromise });
    return fetchPromise;
  }

  render() {
    return (
      <Promiser
        promise={this.state.fetchPromise}
        onFulfilled={this.props.onFulfilled}
      >
        {(props) =>
          this.props.children({
            ...props,
            reload: () => this.linkPromise(this.props),
            disabled: this.props.disabled,
            isPending:
              props.isPending ||
              (this.state.fetchPromise == null &&
                !this.props.disabled &&
                this.props.urlToFetch != null),
            promise: this.state.fetchPromise,
          })
        }
      </Promiser>
    );
  }
}
