import * as React from 'react';
import {
  ANGLE_OPTIONS,
  BACKGROUND_OPTIONS,
  SHOOTING_ELEMENTS_OPTIONS,
  SIZE_OPTIONS,
  STYLE_OPTIONS,
} from '../../../../constants';
import { BookingEditRouteType } from '../types';
import { APP_URL } from '../../../../config';

export function BookingExtraDetails({
  booking,
  ecommerceData,
  workflowRunExtraDetails,
  editingExtraDetails,
  pgGuideline,
}: {
  booking: BookingEditRouteType;
  ecommerceData?: {
    shootingElements?: string[];
    style?: string;
    background?: string;
    size?: string;
    numberOfUniqueItems?: number;
    angles?: string[];
    numberOfMacroShots?: number;
    numberOfOtherShots?: number;
  };
  workflowRunExtraDetails?: string;
  editingExtraDetails?: {
    isCentrallyEdited?: boolean;
    rawUploadStartedOrCompletedAt?: string;
    notesToEditor?: string;
    complianceIssuesNotes?: string;
  };
  pgGuideline?: { id: string };
}) {
  const angles = ecommerceData?.angles
    ?.map((element) => {
      return element === 'macro'
        ? `${ecommerceData?.numberOfMacroShots}
        ${ANGLE_OPTIONS[element]?.name}`
        : element === 'other'
        ? `${ecommerceData?.numberOfOtherShots} :
        ${ANGLE_OPTIONS[element]?.name}`
        : `1 ${ANGLE_OPTIONS[element]?.name}`;
    })
    .join(', ');

  return (
    <div className="col d-flex flex-column">
      <div className="card flex-1">
        <div className="card-body">
          <h3 className="d-flex justify-content-between align-items-baseline">
            <span>Extra details</span>
          </h3>
          {ecommerceData == null &&
          workflowRunExtraDetails == null &&
          editingExtraDetails == null &&
          pgGuideline == null ? (
            <p>None</p>
          ) : (
            <React.Fragment>
              {workflowRunExtraDetails != null && (
                <div>
                  <small className="text-secondary">
                    Central editing details
                  </small>
                  <p>{workflowRunExtraDetails}</p>
                </div>
              )}

              {ecommerceData != null && (
                <div>
                  {ecommerceData?.shootingElements != null && (
                    <div>
                      <small className="text-secondary">Product type</small>
                      <p>
                        {ecommerceData?.shootingElements
                          ?.map(
                            (element) =>
                              SHOOTING_ELEMENTS_OPTIONS[element]?.name
                          )
                          .join(', ')}
                      </p>
                    </div>
                  )}
                  {ecommerceData?.style != null && (
                    <div>
                      <small className="text-secondary">Shoot style</small>
                      <p>{STYLE_OPTIONS[ecommerceData?.style]?.name}</p>
                    </div>
                  )}
                  {ecommerceData?.background != null && (
                    <div>
                      <small className="text-secondary">Background type</small>
                      <p>
                        {BACKGROUND_OPTIONS[ecommerceData?.background]?.name}
                      </p>
                    </div>
                  )}
                  {ecommerceData?.size != null && (
                    <div>
                      <small className="text-secondary">Product size</small>
                      <p>{SIZE_OPTIONS[ecommerceData?.size]?.name}</p>
                    </div>
                  )}
                  {ecommerceData?.numberOfUniqueItems != null && (
                    <div>
                      <small className="text-secondary">Number of items</small>
                      <p>{ecommerceData?.numberOfUniqueItems}</p>
                    </div>
                  )}
                  {ecommerceData?.angles != null && (
                    <div>
                      <small className="text-secondary">Shots per item</small>
                      <p>{angles}</p>
                    </div>
                  )}
                  {booking?.details != null && (
                    <div>
                      <small className="text-secondary">Shoot details</small>
                      <p>{booking?.details}</p>
                    </div>
                  )}
                </div>
              )}

              {editingExtraDetails != null && (
                <div>
                  {editingExtraDetails?.notesToEditor != null && (
                    <div>
                      <small className="text-secondary">Notes to editor</small>
                      <p>{editingExtraDetails?.notesToEditor}</p>
                    </div>
                  )}

                  {editingExtraDetails?.rawUploadStartedOrCompletedAt !==
                    null && (
                    <>
                      <div>
                        <small className="text-secondary">
                          All requested shots taken
                        </small>
                        <p>
                          {editingExtraDetails?.complianceIssuesNotes != null
                            ? 'No'
                            : 'Yes'}
                        </p>
                      </div>

                      <div>
                        <small className="text-secondary">
                          Compliance issues notes (Missing shots / reasons)
                        </small>
                        <p>
                          {editingExtraDetails?.complianceIssuesNotes != null
                            ? editingExtraDetails?.complianceIssuesNotes
                            : 'None'}
                        </p>
                      </div>
                    </>
                  )}
                </div>
              )}

              {pgGuideline != null && (
                <>
                  <small className="text-secondary">PG Guideline</small>
                  <p>
                    <a
                      href={`${APP_URL}/guidelines/${pgGuideline.id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Preview
                    </a>
                  </p>
                </>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
}
