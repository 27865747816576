import React from 'react';
import { Switch, Route } from 'react-router-dom';

import BaseLayout from '../../components/BaseLayout';
import { BaseStyle } from '../../components/BaseStyle';

import { ListEnterprisesRoute } from './list';
import { EnterpriseDetailsRoute } from './details';
import { ListGuidelineVersionsRoute } from './guidelines/versions';
import { UpdateGuidelinesRoute } from './guidelines/update';

export const EnterprisesRoute = ({
  session,
  match,
  match: { path: basePath },
  location,
  history,
}) => (
  <BaseLayout
    session={session}
    match={match}
    bodyStyle={{ overflow: undefined }}
  >
    <BaseStyle className="d-flex flex-column flex-1">
      <Switch>
        <Route
          exact
          path={basePath}
          render={(props) => (
            <ListEnterprisesRoute {...props} session={session} />
          )}
        />

        <Route
          exact
          path={`${basePath}/:partnerId`}
          render={(props) => (
            <EnterpriseDetailsRoute {...props} session={session} />
          )}
        />

        <Route
          exact
          path={`${basePath}/:partnerId/guidelines/:guidelineId/versions`}
          render={(props) => <ListGuidelineVersionsRoute {...props} />}
        />

        <Route
          exact
          path={`${basePath}/:partnerId/guidelines/:guidelineId/version/:versionId/edit`}
          render={(props) => (
            <UpdateGuidelinesRoute session={session} {...props} />
          )}
        />
      </Switch>
    </BaseStyle>
  </BaseLayout>
);
