import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { RouteComponentProps } from 'react-router-dom';
import download from 'downloadjs';
import { usePromise } from '../../components/usePromise';
import { API_URL } from '../../config';
import { apiFetch } from '../../utils';
import BaseLayout from '../../components/BaseLayout';

import csvIcon from '../../img/csv-icon.svg';

export function GoogleAdsBids({
  history,
  location,
  match,
  session,
}: {
  history: RouteComponentProps['history'];
  location: RouteComponentProps['location'];
  match: RouteComponentProps['match'];
  session: { token: string; uid: string };
}) {
  const { token } = session;

  const [date, setDate] = useState('');

  const [cvsFileName, setCvsFileName] = useState<string | undefined>(undefined);

  const [csvFile, setCsvFile] = useState<File | undefined>(undefined);

  const dateInputRef = React.useRef<HTMLInputElement>(null);

  const generateReportPromiser = usePromise();

  const promiserDropZone = usePromise();

  function onGenerateReport({ date }: { date: string }) {
    const url = `${API_URL}/api/v2/google-ads/bids/${date}?file_type=csv`;

    generateReportPromiser.setPromise(
      fetch(url, {
        headers: {
          Authorization: token && `Bearer ${token}`,
        },
      })
        .then((res) => res.blob())
        .then((blob) =>
          download(blob, `google-ads-bids-report-${date}.csv`, 'text/csv')
        )
    );
  }

  return (
    <>
      <BaseLayout session={session} match={match}>
        <h2>Google ads bids</h2>

        <div className="row mt-3">
          <div className="col-12">
            <div className="bg-white p-2">
              <div className="table-responsive">
                <div className="form-row" style={{ margin: '0.5rem' }}>
                  <div
                    className="form-group col-3"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <input
                      defaultValue={date}
                      name="day"
                      type="date"
                      className="form-control"
                      ref={dateInputRef}
                    />
                  </div>
                  <div
                    className="form-group col-2"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <button
                      type="submit"
                      className="btn btn-block btn-dark"
                      disabled={generateReportPromiser.isPending}
                      onClick={() => {
                        const date = dateInputRef.current?.value;

                        if (date != null && date !== '') {
                          setDate(date);
                          onGenerateReport({ date });
                        }
                      }}
                    >
                      Generate CSV
                    </button>
                  </div>

                  <div
                    className="form-group col-2"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <button
                      type="submit"
                      className="mr-2 btn btn-block btn-primary"
                      disabled={
                        promiserDropZone.isPending ||
                        cvsFileName == null ||
                        csvFile == null
                      }
                      onClick={() => {
                        const formData = new FormData();

                        formData.append('csv', csvFile ?? '');

                        promiserDropZone.setPromise(
                          apiFetch(`/api/v2/google-ads/bids/reject`, {
                            token: session.token,
                            method: 'POST',
                            body: formData,
                          }).catch((err) => {
                            console.error(err);
                          })
                        );
                      }}
                    >
                      Apply Bids
                    </button>
                  </div>
                </div>

                <div className="form-row" style={{ margin: '0.5rem' }}>
                  <div className="form-group col-5">
                    {cvsFileName != null ? (
                      <div
                        className="form-control"
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          backgroundColor: '#F6F9FF',
                          border: '1px solid #C1C8D4',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div className="d-flex text-center">
                          <img
                            src={csvIcon}
                            alt="csv"
                            style={{
                              width: 35,
                            }}
                          />

                          <div className="d-flex" style={{ width: 250 }}>
                            <span
                              style={{
                                textOverflow: 'ellipsis',
                                display: 'inline-block',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                paddingRight: '0.5em',
                              }}
                            >
                              {cvsFileName}
                            </span>
                          </div>
                        </div>

                        <button
                          style={{
                            backgroundColor: 'transparent',
                            borderWidth: 0,
                            paddingTop: 4,
                          }}
                          onClick={(event) => {
                            setCvsFileName(undefined);
                          }}
                          className="d-flex text-center align-center"
                        >
                          <i
                            className="fa fa-times text-muted fa-lg"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    ) : (
                      <Dropzone
                        accept="text/csv"
                        style={{
                          border: '1px dashed #C1C8D4',
                          borderRadius: '4px',
                          height: 150,
                        }}
                        multiple={false}
                        disabled={false}
                        onDrop={(acceptedFiles) => {
                          if (acceptedFiles.length === 0) return;

                          const file = acceptedFiles[0];

                          setCsvFile(file);

                          setCvsFileName(file.name);
                        }}
                      >
                        {({ isDragReject, rejectedFiles }) => (
                          <div
                            className="card-body text-center"
                            style={{ marginTop: 30 }}
                          >
                            <div
                              className={
                                isDragReject === true ||
                                rejectedFiles.length > 0
                                  ? 'text-danger'
                                  : ''
                              }
                            >
                              <p>
                                Drag and drop your CSV file here, <br />
                                <span style={{ textDecoration: 'underline' }}>
                                  or browse
                                </span>
                              </p>
                              {(isDragReject === true ||
                                rejectedFiles.length > 0) && (
                                <p>Only CSV files are supported.</p>
                              )}
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    )}
                  </div>
                </div>

                {generateReportPromiser.isPending === true && (
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: 'rgba(255,255,255,0.8)',
                    }}
                  >
                    <p>Loading...</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </BaseLayout>
    </>
  );
}
