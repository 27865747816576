import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import moment from 'moment-timezone';
import ContentWrapper from '../../components/ContentWrapper';
import Component2 from '@reach/component-component';
import { gql, useMutation, useQuery } from '../../components/ApolloClient';
import InView from 'react-intersection-observer';
import qs from 'query-string';
import { ensureString } from '../../utils/strings';
import Select from 'react-select';
import { OrderByLink } from '../../components/OrderByLink';
import { SearchField } from '../partners/components';
import { TdLink } from '../../components';
import { ApolloQueryResult } from '@apollo/client';

const ELLIPSIS_STYLE = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
} as const;

const STATUS = 'status';
const PARTNERS = 'partners';

const customStyles = {
  valueContainer: (provided, state) => ({
    ...provided,
    marginLeft: 70,
  }),
};

const WorkflowRunStatus = [
  'SOURCE',
  'ACTION',
  'DESTINATION',
  'COMPLETED',
  'ERROR',
  'STOPPED',
  'AWAITING_SOURCE',
];

// Types and queries
type WorkflowRun = {
  id: string;
  partner?: {
    name: string;
  };
  workflowDefinition?: {
    name: string;
  };
  status?: string;
  lastProcessedAt?: string;
  createdAt?: string;
  bookingId?: string;
  editingJobId?: string;
};

type workflowRunsQueryType = {
  workflowRunList: {
    cursor?: string;
    edges: WorkflowRun[];
  };
};

type PartnersTypeQuery = {
  workflowRunPartnerList: {
    edges?: {
      id: string;
      name: string;
    }[];
  };
};

const gqlWorkflowRunsQuery = gql`
  query workflowListQuery(
    $first: Int
    $cursor: String
    $status: [String]
    $partnerIdList: [String]
    $sort: WorkflowRunConnectionSortKeys
    $query: String
  ) {
    workflowRunList(
      first: $first
      cursor: $cursor
      status: $status
      partnerIdList: $partnerIdList
      sort: $sort
      query: $query
    ) {
      edges {
        id
        partner {
          name
        }
        workflowDefinition {
          name
        }
        status
        lastProcessedAt
        createdAt
        bookingId
        editingJobId
      }
      cursor
    }
  }
`;

const gqlWorkflowRunPartnersQuery = gql`
  query WorkflowRunPartnerList {
    workflowRunPartnerList {
      edges {
        id: uid
        name
      }
      total
    }
  }
`;

// Option objects
const createStatusOptions = WorkflowRunStatus.map((status) => ({
  label: status,
  value: status,
}));

const createPartnersOptions = (partnerQuery: PartnersTypeQuery | undefined) => {
  if (!partnerQuery) return [];
  return partnerQuery?.workflowRunPartnerList.edges?.map((partnerType) => ({
    label: partnerType.name,
    value: partnerType.id,
  }));
};

// Filters
const MultiSelectFilter = ({
  filterName,
  options,
  location,
  history,
}: {
  filterName: string;
  options: { value: string; label: string }[];
  location: RouteComponentProps['location'];
  history: RouteComponentProps['history'];
}) => {
  const filterString = qs.parse(location.search)[filterName];
  const selectedValues = filterString
    ? ensureString(filterString ?? '')?.split(',')
    : [];

  const selectedOptions =
    selectedValues && options.filter((o) => selectedValues.includes(o.value));

  return (
    <div
      style={{
        minWidth: 150,
        marginRight: 16,
        position: 'relative',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 7,
          left: 10,
          zIndex: 2,
          textTransform: 'capitalize',
          color: '#C1C8D4',
        }}
      >
        {filterName != 'dueAt' ? filterName : 'Due in'}
      </div>
      <div style={{ minWidth: 100 }}>
        <Select
          isMulti
          value={selectedOptions}
          options={options}
          styles={customStyles}
          placeholder=""
          onChange={(selectedOptions) => {
            const selected = (selectedOptions || []).map((s) => s.value);

            history.push({
              ...location,
              search: qs.stringify({
                ...qs.parse(location.search),
                [filterName]:
                  selected.length > 0 ? selected.join(',') : undefined,
              }),
            });
          }}
        />
      </div>
    </div>
  );
};

function HeaderTableRow({
  location,
}: {
  location: RouteComponentProps['location'];
}) {
  return (
    <tr>
      <th style={ELLIPSIS_STYLE} scope="col">
        Workflow Run UID
      </th>
      <th style={ELLIPSIS_STYLE} scope="col">
        Partner Name
      </th>
      <th style={ELLIPSIS_STYLE} scope="col">
        Workflow Name
      </th>
      <th style={ELLIPSIS_STYLE} scope="col">
        Status
      </th>
      <th style={ELLIPSIS_STYLE} scope="col">
        Booking
      </th>
      <th style={ELLIPSIS_STYLE} scope="col">
        Editing
      </th>
      <th style={ELLIPSIS_STYLE} scope="col">
        Last processed at
      </th>
      <th style={ELLIPSIS_STYLE} scope="col">
        <OrderByLink attrName="created_at" location={location}>
          Created At
        </OrderByLink>
      </th>
    </tr>
  );
}

function LoadingTableRows({ rows, cols }: { rows: Number; cols: Number }) {
  return (
    <>
      {Array.from(Array(rows)).map((i, indexRows) => (
        <tr key={indexRows}>
          {Array.from(Array(cols)).map((i, indexCols) => (
            <td key={indexCols}>...</td>
          ))}
        </tr>
      ))}
    </>
  );
}

function FormattedTableRows({
  workflowRuns,
  refetch,
}: {
  workflowRuns: WorkflowRun[] | undefined;
  refetch: () => Promise<ApolloQueryResult<workflowRunsQueryType>>;
}) {
  const [
    workflowRunDestinationErrorReTriggerAsAdmin,
    workflowRunDestinationErrorReTriggerAsAdminMutation,
  ] = useMutation<
    {
      workflowRunDestinationErrorReTriggerAsAdmin: {
        workflowRun: { id: string };
      };
    },
    { workflowRunId: string }
  >(
    gql`
      mutation WorkflowRunDestinationErrorReTriggerAsAdmin(
        $workflowRunId: ID!
      ) {
        workflowRunDestinationErrorReTriggerAsAdmin(
          input: { workflowRunId: $workflowRunId }
        ) {
          workflowRun {
            id
          }
        }
      }
    `
  );

  const [
    workflowRunDestinationCompletedReTriggerAsAdmin,
    workflowRunDestinationCompletedReTriggerAsAdminMutation,
  ] = useMutation<
    {
      workflowRunDestinationCompletedReTriggerAsAdmin: {
        workflowRun: { id: string };
      };
    },
    { workflowRunId: string }
  >(
    gql`
      mutation WorkflowRunDestinationCompletedReTriggerAsAdmin(
        $workflowRunId: ID!
      ) {
        workflowRunDestinationCompletedReTriggerAsAdmin(
          input: { workflowRunId: $workflowRunId }
        ) {
          workflowRun {
            id
          }
        }
      }
    `
  );

  const tableRows = workflowRuns?.map((workflowRun) => (
    <tr key={workflowRun?.id}>
      <td>{workflowRun?.id}</td>
      <td>{workflowRun?.partner?.name}</td>
      <td>{workflowRun?.workflowDefinition?.name}</td>
      <td>
        <span
          className={
            'badge text-uppercase' +
            ({
              SOURCE: ' badge-success',
              ACTION: ' badge-success',
              DESTINATION: ' badge-success',
              COMPLETED: ' badge-success',
              ERROR: ' badge-danger',
              STOPPED: ' badge-danger',
              AWAITING_SOURCE: ' badge-warning',
            }[workflowRun?.status ?? ''] ?? '')
          }
        >
          {workflowRun?.status}
        </span>
      </td>
      <TdLink
        to={`/bookings/${workflowRun?.bookingId}`}
        className="align-middle"
        style={{ textDecoration: 'underline' }}
      >
        {workflowRun?.bookingId != null ? 'Go to booking' : ''}
      </TdLink>
      <TdLink
        to={`editing-jobs/e-${workflowRun?.editingJobId}/details`}
        className="align-middle"
        style={{ textDecoration: 'underline' }}
      >
        {workflowRun?.editingJobId != null ? 'Go to editing job' : ''}
      </TdLink>
      <td>
        {workflowRun.lastProcessedAt &&
          moment(workflowRun.lastProcessedAt).format('MM/DD/YYYY hh:mm')}
      </td>
      <td>{moment(workflowRun.createdAt).format('MM/DD/YYYY hh:mm')}</td>
      <td>
        {workflowRun?.status === 'ERROR' && (
          <button
            disabled={
              workflowRunDestinationErrorReTriggerAsAdminMutation.loading
            }
            onClick={async () => {
              const confirmation = window.confirm(
                `Are you sure you want to retrigger the workflow run ${workflowRun.id}?`
              );

              if (confirmation !== true) return;

              const response = await workflowRunDestinationErrorReTriggerAsAdmin(
                {
                  variables: { workflowRunId: workflowRun.id },
                }
              ).catch(() => {
                alert(
                  'This workflow can not be re-trigger make sure it matches the criteria'
                );

                return { data: null };
              });

              if (
                response.data?.workflowRunDestinationErrorReTriggerAsAdmin
                  ?.workflowRun?.id != null
              ) {
                refetch();
              }
            }}
            type="button"
            className="btn  btn-danger"
          >
            <i className={`icon fa fa-refresh`} />
          </button>
        )}
        {workflowRun?.status === 'COMPLETED' && (
          <button
            disabled={
              workflowRunDestinationCompletedReTriggerAsAdminMutation.loading
            }
            onClick={async () => {
              const confirmation = window.confirm(
                `Are you sure you want to retrigger the workflow run ${workflowRun.id}?`
              );

              if (confirmation !== true) return;

              const response = await workflowRunDestinationCompletedReTriggerAsAdmin(
                {
                  variables: { workflowRunId: workflowRun.id },
                }
              ).catch(() => {
                alert(
                  'This workflow can not be re-trigger make sure it matches the criteria'
                );

                return { data: null };
              });

              if (
                response.data?.workflowRunDestinationCompletedReTriggerAsAdmin
                  ?.workflowRun?.id != null
              ) {
                refetch();
              }
            }}
            type="button"
            className="btn btn-danger"
          >
            <i className={`icon fa fa-refresh`} />
          </button>
        )}
      </td>
    </tr>
  ));
  return <>{tableRows}</>;
}

function DataRowsChunk({
  cursor,
  onMore,
  selectedStatus,
  partnerIdList,
  sortByKey,
  search,
}: {
  cursor: string | null;
  onMore?: (p: { nextCursor: string }) => void;
  selectedStatus?: string[];
  partnerIdList?: string[];
  sortByKey: string;
  search?: string;
}) {
  const workflowRunsQuery = useQuery<workflowRunsQueryType>(
    gqlWorkflowRunsQuery,
    {
      variables: {
        cursor: cursor,
        first: cursor === null ? 20 : 40,
        status: selectedStatus,
        partnerIdList: partnerIdList,
        sort: sortByKey,
        query: search,
      },
    }
  );

  const workflowRuns = workflowRunsQuery.data?.workflowRunList?.edges;
  const nextCursor = workflowRunsQuery.data?.workflowRunList?.cursor;
  const contentNotReady = workflowRuns === null || workflowRunsQuery.loading;

  return (
    <>
      {onMore == null || nextCursor == null ? null : (
        <InView>
          {({ inView, ref }) => (
            <tr ref={ref}>
              {inView && <Component2 didMount={() => onMore({ nextCursor })} />}
            </tr>
          )}
        </InView>
      )}
      {contentNotReady ? (
        <LoadingTableRows rows={7} cols={7} />
      ) : (
        <FormattedTableRows
          workflowRuns={workflowRuns}
          refetch={workflowRunsQuery.refetch}
        />
      )}
      {onMore == null || nextCursor == null ? null : (
        <InView>
          {({ inView, ref }) => (
            <tr ref={ref}>
              {inView && <Component2 didMount={() => onMore({ nextCursor })} />}
            </tr>
          )}
        </InView>
      )}
    </>
  );
}

export function WorkflowRunsView({
  location,
  session,
  history,
}: {
  location: RouteComponentProps['location'];
  session: { token: string; uid: string };
  history;
}) {
  const parsedSearch = qs.parse(location.search);
  // State
  const [cursors, setCursors] = React.useState<string[]>([]);

  const partnersQuery = useQuery<PartnersTypeQuery>(
    gqlWorkflowRunPartnersQuery
  );

  const statusOptions = createStatusOptions;
  const partnerOptions = createPartnersOptions(partnersQuery.data);

  const selectedStatus = React.useMemo(
    () =>
      ensureString(qs.parse(location.search)[STATUS] ?? null)?.split(',') ?? [],
    [location]
  );

  const partnerIdList = React.useMemo(
    () =>
      ensureString(qs.parse(location.search)[PARTNERS] ?? null)?.split(',') ??
      [],
    [location]
  );

  const sortByCreatedAt =
    ensureString(parsedSearch['ordering']) == null
      ? 'CREATED_AT_DESC'
      : ensureString(parsedSearch['ordering']) === '-created_at'
      ? 'CREATED_AT_DESC'
      : 'CREATED_AT';

  const searchTerm = ensureString(parsedSearch['q']);

  return (
    <section>
      <h2> Workflow Runs View </h2>
      <ContentWrapper>
        <div className="table-responsive">
          <div
            style={{
              margin: 8,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ margin: 8, display: 'flex' }}>
              <MultiSelectFilter
                filterName={STATUS}
                options={statusOptions}
                location={location}
                history={history}
              />
              {partnerOptions != null && partnerOptions.length > 0 ? (
                <MultiSelectFilter
                  filterName={PARTNERS}
                  options={partnerOptions}
                  location={location}
                  history={history}
                />
              ) : null}
            </div>
            <SearchField
              onSearch={({ q }) =>
                history.push({
                  ...location,
                  search: qs.stringify({
                    ...qs.parse(location.search),
                    q: q || undefined,
                  }),
                })
              }
              defaultValue={qs.parse(location.search).q || ''}
            />
          </div>

          <table className="table" style={{ position: 'relative' }}>
            <thead style={{ color: '#71767E' }}>
              <HeaderTableRow location={location} />
            </thead>
            <tbody style={{ color: '#6C757D' }}>
              {[null, ...cursors].map((cursor, index) => (
                <DataRowsChunk
                  key={cursor ?? '1'}
                  cursor={cursor}
                  selectedStatus={selectedStatus}
                  partnerIdList={partnerIdList}
                  sortByKey={sortByCreatedAt}
                  search={searchTerm}
                  onMore={
                    index === cursors.length
                      ? ({ nextCursor }) =>
                          setCursors((state) =>
                            state.includes(nextCursor)
                              ? state
                              : [...state, nextCursor]
                          )
                      : undefined
                  }
                />
              ))}
            </tbody>
          </table>
        </div>
      </ContentWrapper>
    </section>
  );
}
