import {
  locationForModal,
  ModalRoute,
} from '../../../../components/ModalRoute';
import React, { useState } from 'react';
import { Modal } from '../../../../components/Modal';
import { useHistory, useLocation } from 'react-router-dom';
import { RatingInput } from '../../../../components/RatingInput';
import { gql, useMutation } from '../../../../components/ApolloClient';

export const MODAL_NAME_RATE_GALLERY = 'rate-gallery';

type IModalParams = {
  modalName: string;
  currentBookingId?: string;
};

function RateGalleryInner(props: {
  modalParams: IModalParams;
  onDismiss: () => void;
  onRate: () => Promise<void>;
}) {
  const [rate, setRate] = useState(0);

  const bookingId = props.modalParams.currentBookingId;

  const handleInputChange = ({ target: { name }, target }) => {
    const value = target.type === 'checkbox' ? target.checked : target.value;
    setRate(value);
  };

  const [rateBooking, rateBookingMutation] = useMutation<
    {},
    { bookingId: string; rating: number }
  >(gql`
    mutation EditorRatingGallery($bookingId: ID!, $rating: Int) {
      bookingRateAsEditor(input: { bookingId: $bookingId, rating: $rating }) {
        booking {
          id
        }
      }
    }
  `);

  let invalidRate = rate === 0;

  const sendRateBooking = () => {
    if (bookingId == null || rate == null || rate === 0) {
      return;
    }
    rateBooking({
      variables: { bookingId: bookingId, rating: rate },
    }).then((res) => props.onDismiss());
  };

  return (
    <div className="card my-4 position-relative">
      <div
        className="card-body d-flex flex-column"
        style={{
          minWidth: '500px',
          width: '40vw',
          minHeight: '100px',
          padding: 32,
        }}
      >
        <h2 className="m-0 text-center">Rate Gallery</h2>

        <p className="text-secondary text-center m-0">
          Do these RAWs set you up to deliver WOW factor to the client?
        </p>

        <div style={{ marginTop: 10, transform: 'scale(1.5)' }}>
          <RatingInput
            value={rate}
            setReview={handleInputChange}
            inputName="provider_score"
            disabled={false}
          />
        </div>
      </div>

      {bookingId != null && (
        <footer
          style={{
            position: 'sticky',
            bottom: 0,
            background: 'white',
            padding: 32,
            borderTop: `1px solid #E8EDF5`,
            justifyContent: 'center',
          }}
          className="d-flex flex-row"
        >
          <button
            disabled={rateBookingMutation.loading}
            className="btn btn-danger mr-3"
            onClick={props.onDismiss}
          >
            Cancel
          </button>
          <button
            disabled={invalidRate || rateBookingMutation.loading}
            className="btn btn-primary mr-3"
            onClick={sendRateBooking}
          >
            Accept
          </button>
        </footer>
      )}
    </div>
  );
}

export function RateGallery() {
  const history = useHistory();
  const location = useLocation();

  return (
    <ModalRoute
      modalName={MODAL_NAME_RATE_GALLERY}
      render={({ modalParams }: { modalParams: IModalParams }) => {
        const onDismiss = () =>
          history.replace(
            locationForModal({
              location,
              modal: undefined,
            })
          );

        return (
          <Modal onDismiss={onDismiss}>
            <RateGalleryInner
              key={modalParams.currentBookingId}
              modalParams={modalParams}
              onDismiss={onDismiss}
              onRate={async () => {
                history.replace(
                  locationForModal({
                    location,
                    modal: { ...modalParams, step: 'rated' } as IModalParams,
                  })
                );
              }}
            />
          </Modal>
        );
      }}
    />
  );
}
