export const badgeBgColorStatus = (status: string) => {
  switch (status.trim().toLowerCase()) {
    case 'paid':
      return { background: '#38C4F2' };
    case 'created':
      return { background: '#7d2eff' };
    case 'completed':
      return { background: '#2FB67D' };
    default:
      return { background: '#C1C8D4' };
  }
};

export const qualityControlStatus = {
  completed: 'completed',
  paid: 'pending qc',
  created: 'created',
};

export function formatBytes({
  bytes,
  decimals = 2,
}: {
  bytes: number;
  decimals?: number;
}) {
  if (!+bytes) return '0 Bytes';

  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}
