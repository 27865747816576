import moment from 'moment';
import React, { useState } from 'react';

import { useFetch } from '../../../../components/useFetch';
import { formatMoneyCents } from '../../../../utils';
import { locationForModal, ModalRoute } from './ModalRoute';
import { RefundTransactionModal } from './RefundTransaction';

const MODAL_NAME_TRANSACTION_REFUND = 'transaction-refund';

export function TransactionRow({
  booking,
  location,
  transaction,
  history,
  session,
  setCurrentTransaction,
}: {
  booking;
  location;
  transaction;
  history;
  session;
  setCurrentTransaction;
}) {
  const {
    result: { transaction: braintreeTransaction } = {},
    isPending: transactionLoading,
  } = useFetch<{
    transaction?: {
      refund_ids?: string[];
      id: string;
      type: string;
      amount: number;
      created_at: Date;
      refunded_transactions?: {
        id: string;
        type: string;
        amount: number;
        created_at: Date;
      }[];
      refunded_total?: number;
    };
  }>({
    urlToFetch: `/api/v2/admin/bookings/${booking?.uid}/available-transaction-refunds/${transaction.id}`,
    session: session,
    disabled: booking?.uid == null,
  });

  return (
    <tr>
      <td className="align-middle">{transaction.id}</td>
      <td className="align-middle">
        {formatMoneyCents(transaction.amount, {
          currency: booking.currency,
        })}{' '}
        {!transactionLoading &&
          braintreeTransaction &&
          (braintreeTransaction.refunded_total ?? 0) > 0 && (
            <span className="text-danger">
              (-
              {formatMoneyCents(braintreeTransaction.refunded_total, {
                currency: booking.currency,
              })}
              )
            </span>
          )}
      </td>
      <td className="align-middle">
        {transaction.created_at &&
          moment(transaction.created_at).format(
            'ddd, MMM Do YYYY, h:mm:ss a zz'
          )}
      </td>
      <td className="align-middle text-capitalize">{transaction?.status}</td>
      <td className="align-middle">
        {!transactionLoading && (
          <button
            className="btn btn-danger btn-block"
            disabled={transactionLoading}
            onClick={() => {
              setCurrentTransaction(braintreeTransaction);
              history.replace(
                locationForModal({
                  location,
                  modal: {
                    modalName: MODAL_NAME_TRANSACTION_REFUND,
                  },
                })
              );
            }}
          >
            Refund
          </button>
        )}
      </td>
    </tr>
  );
}

export function TransactionDetails({
  session,
  booking,
  location,
  hide = true,
  history,
}) {
  const [currentTransaction, setCurrentTransaction] = useState(null);
  const {
    result: { transactions } = {},
    isPending: transactionsLoading,
    reload: transactionsReload,
  } = useFetch<{
    transactions?: {
      id: string;
      created_at: string;
      amount: number;
      status: string;
    }[];
  }>({
    urlToFetch: `/api/v2/admin/bookings/${booking?.uid}/transactions`,
    session: session,
    disabled: booking?.uid == null,
  });

  return (
    <div
      className={'card-body flex-column' + (hide ? ' d-none' : ' d-flex')}
      style={{ minHeight: '350px' }}
    >
      <div>
        {!transactionsLoading && (
          <table className="table table-hover mb-0">
            <thead>
              <tr>
                <th className="text-muted text-truncate">Transaction Id</th>

                <th className="text-muted text-truncate">Amount</th>
                <th className="text-muted text-truncate">Creation Date</th>
                <th className="text-muted text-truncate">Status</th>
                <th className="text-muted text-truncate">Actions</th>
              </tr>
            </thead>

            <tbody>
              {(transactions ?? []).map((transaction) => (
                <TransactionRow
                  key={transaction.id}
                  booking={booking}
                  location={location}
                  transaction={transaction}
                  history={history}
                  session={session}
                  setCurrentTransaction={setCurrentTransaction}
                />
              ))}
            </tbody>
          </table>
        )}
        <ModalRoute
          modalName={MODAL_NAME_TRANSACTION_REFUND}
          render={({ history, location, modalParams }) => (
            <RefundTransactionModal
              modalParams={modalParams}
              session={session}
              history={history}
              location={location}
              booking={booking}
              currentTransaction={currentTransaction}
              onReload={() => {
                transactionsReload().then(() =>
                  history.push(
                    locationForModal({
                      location,
                      modal: undefined,
                    })
                  )
                );
              }}
              onDismiss={() =>
                history.push(
                  locationForModal({
                    location,
                    modal: undefined,
                  })
                )
              }
            />
          )}
        />
      </div>
    </div>
  );
}
