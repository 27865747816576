import { NavLink, Route, RouteComponentProps, Switch } from 'react-router-dom';
import styled from 'styled-components';

import BaseLayout from '../../components/BaseLayout';
import { BaseStyle } from '../../components/BaseStyle';
import { CompletionLeadQueue } from './completion-lead-queue';
import { CompletionTeamPerformanceRoute } from './performance';
import { ENABLE_COMPLETION_TEAM_DASHBOARD } from '../../config';

const HeaderLinks = styled.div`
  a {
    text-decoration: none;
    margin-bottom: 2rem;

    h2 {
      margin: 0;
    }

    &.active {
      border-bottom: 3px #1f6fff solid;
    }
  }
`;

export function CompletionLeadQueueRoute({
  session,
  match,
}: {
  session: { token: string; uid: string };
  match: RouteComponentProps['match'];
}) {
  return (
    <BaseLayout session={session} match={match}>
      <BaseStyle className="d-flex flex-column flex-1">
        <HeaderLinks className="d-flex">
          <NavLink exact to={match.path}>
            <h2>Queue</h2>
          </NavLink>

          {ENABLE_COMPLETION_TEAM_DASHBOARD === true && (
            <>
              <h2
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  fontWeight: 'normal',
                }}
              >
                |
              </h2>

              <NavLink exact to={`${match.path}/performance`}>
                <h2>Performance</h2>
              </NavLink>
            </>
          )}
        </HeaderLinks>
        <Switch>
          <Route
            exact
            path={match.path}
            render={() => <CompletionLeadQueue />}
          />

          {ENABLE_COMPLETION_TEAM_DASHBOARD && (
            <Route
              exact
              path={`${match.path}/performance`}
              render={(props) => (
                <CompletionTeamPerformanceRoute {...props} session={session} />
              )}
            />
          )}
        </Switch>
      </BaseStyle>
    </BaseLayout>
  );
}
