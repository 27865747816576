import React from 'react';
import moment from 'moment-timezone';

import { apiFetch } from '../../../../utils';

export class AvailabilityFetcher extends React.Component {
  state = {
    providersAvailability: null,
    providersAvailabilityLoading: false,
  };

  _availabilityCache = {};

  componentDidMount() {
    if (
      this.props.coords &&
      this.props.timezone &&
      this.props.selectedDate &&
      this.props.duration &&
      this.props.shoottype
    ) {
      this.fetchAvailability({
        coords: this.props.coords,
        timezone: this.props.timezone,
        selectedDate: this.props.selectedDate,
        duration: this.props.duration,
        shoottype: this.props.shoottype,
        excludeBookingId: this.props.excludeBookingId,
      });
    }
  }

  componentWillUpdate(nextProps, nextState) {
    if (
      nextProps.coords &&
      nextProps.timezone &&
      nextProps.selectedDate &&
      nextProps.duration &&
      nextProps.shoottype
    ) {
      this.fetchAvailability({
        coords: nextProps.coords,
        timezone: nextProps.timezone,
        selectedDate: nextProps.selectedDate,
        duration: nextProps.duration,
        shoottype: nextProps.shoottype,
        excludeBookingId: this.props.excludeBookingId,
      });
    }
  }

  componentWillUnmount() {
    this._fetchAvailabilityKey = null;
  }

  fetchAvailability = async ({
    coords: [lat, lng],
    timezone,
    selectedDate,
    duration,
    shoottype,
    excludeBookingId,
  }) => {
    const {
      session: { token },
    } = this.props;

    const startAt = moment(selectedDate).tz(timezone).startOf('week');

    const fetchAvailabilityKey = [
      lat,
      lng,
      timezone,
      startAt.format(),
      duration,
      shoottype,
      excludeBookingId,
    ].join(':');

    if (this._fetchAvailabilityKey === fetchAvailabilityKey) {
      return null;
    }
    this._fetchAvailabilityKey = fetchAvailabilityKey;
    this.setState({ providersAvailabilityLoading: true });

    const { results } = await apiFetch(`/api/v2/providers/availability`, {
      token,
      query: {
        days: 8,
        lat,
        lng,
        timezone,
        booking_duration: duration,
        start_at: startAt,
        shoottype,

        exclude_booking_id: excludeBookingId,
      },
    });

    const providersAvailability = results.map(
      ({
        time_range: [start, end],
        available_providers_uids,
        available_specialist_providers_uids,
      }) => {
        const startDate = moment(start).tz(timezone);
        return {
          startDate,
          availableProvidersUids: available_providers_uids,
          availableSpecialistProvidersUids: available_specialist_providers_uids,
        };
      }
    );

    if (this._fetchAvailabilityKey !== fetchAvailabilityKey) return;
    this.setState({
      providersAvailabilityLoading: false,
      providersAvailability,
    });
  };

  render() {
    const render = this.props.children || this.props.render;
    return render({
      providersAvailabilityLoading: this.state.providersAvailabilityLoading,
      providersAvailability: this.state.providersAvailability || [],
    });
  }
}
