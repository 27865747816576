import React, { Component } from 'react';
import styled from 'styled-components';
import { API_URL } from '../../../config';
import { ImageLoadIndicator } from './ImageLoadIndicator';
import { Link } from 'react-router-dom';
import qs from 'query-string';

const measureImage = ({ src }) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      return resolve({
        width: this.width,
        height: this.height,
        ratio: this.height / this.width,
      });
    };
    img.onerror = () => reject(new Error('Problem measuring image'));
    img.src = src;
  });

function chunkArray(array, chunk) {
  let i, j, temparray;
  const res = [];
  for (i = 0, j = array.length; i < j; i += chunk) {
    temparray = array.slice(i, i + chunk);
    res.push(temparray);
  }

  return res;
}

const MEASURER_BATCH = 6;
class ImagesMeasurer extends Component {
  state = {
    toMeasureImages: this.props.images,
    measuredImages: [],
    loading: true,
  };

  componentDidMount() {
    this.measureImages();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.images.length !== this.props.images.length) {
      this.setState(
        {
          toMeasureImages: this.props.images,
          measuredImages: [],
          loading: true,
        },
        () => this.measureImages()
      );
    }
  }

  measureImages = async () => {
    const { toMeasureImages } = this.state;
    const chunks = chunkArray([...this.state.toMeasureImages], MEASURER_BATCH);
    for (const chunk of chunks) {
      const measuredChunk = await Promise.all(
        chunk.map((image) =>
          image.width != null && image.height != null
            ? {
                ...image,
                ratio: image.height / image.width,
              }
            : measureImage({
                src:
                  image.src ??
                  `${API_URL}/rpc/v1/image/${image.media}?size=gallerythumb`,
              }).then(({ width, height, ratio }) => ({
                ...image,
                width,
                height,
                ratio,
              }))
        )
      );

      if (this.stop || toMeasureImages !== this.state.toMeasureImages) {
        return;
      }

      const filteredChunk = measuredChunk.filter((measured) => !!measured);
      this.setState((state) => ({
        measuredImages: [...state.measuredImages, ...filteredChunk],
      }));
    }

    this.setState({ loading: false });
  };

  componentWillUnmount() {
    this.stop = true;
  }

  render() {
    const render = this.props.children || this.props.render;
    return render({
      measuredImages: this.state.measuredImages,
      loading: this.state.loading,
    });
  }
}

export class MyWidth extends Component {
  state = { width: null };

  componentDidMount() {
    this.setState({ width: this.div.offsetWidth });
    window.addEventListener('resize', this.resize);
  }

  resize = () => {
    this.setState({ width: this.div.offsetWidth });
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  render() {
    return (
      <div ref={(div) => (this.div = div)} style={{ position: 'relative' }}>
        {this.props.children({ width: this.state.width })}
      </div>
    );
  }
}

class Placer extends Component {
  render() {
    const { images, containerWidth, columnCount } = this.props;

    const columnWidth = containerWidth / columnCount;

    const columns = images.reduce(
      (acu, image, index) => {
        const minHeight = Math.min(...acu.map((acu) => acu.height));

        const currentColIndex = acu.findIndex(
          ({ height }) => height === minHeight
        );

        const images = [...acu[currentColIndex].images, image];
        acu[currentColIndex] = {
          images,
          height: images.reduce(
            (sum, { ratio }) => sum + ratio * columnWidth,
            0
          ),
        };

        return acu;
      },
      Array.from(Array(columnCount)).map(() => ({
        images: [],
        height: 0,
      }))
    );

    return this.props.children({ columns });
  }
}

export class MasonryGallery extends Component {
  render() {
    const { images, columnCount = 3 } = this.props;

    if (!images.length) {
      return this.props.children({ columns: [], loading: true });
    }

    return (
      <MyWidth>
        {({ width }) => (
          <ImagesMeasurer images={images}>
            {({ measuredImages, loading }) => (
              <Placer
                images={measuredImages}
                containerWidth={width}
                columnCount={columnCount}
              >
                {({ columns }) => this.props.children({ columns, loading })}
              </Placer>
            )}
          </ImagesMeasurer>
        )}
      </MyWidth>
    );
  }
}

const ImageAnimatedLoadStyle = styled.img`
  transition: opacity 1s;
  opacity: ${(props) => (props.loaded ? 1 : 0)};
`;

const GrowHoverButton = styled.button`
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: scale(1.2);
  }
`;

export const prettifyShoottype = (shoottype) => {
  return (
    { teamAndOffice: 'Team and Office', realestate: 'Real Estate' }[
      shoottype
    ] || shoottype.charAt(0).toUpperCase() + shoottype.slice(1)
  );
};

export const ImageAnimatedLoad = ({
  mediaId,
  size = 'gallerythumb',
  ...props
}) => {
  const src = `${API_URL}/rpc/v1/image/${mediaId}?size=${size}`;
  return (
    <ImageLoadIndicator
      src={src}
      render={({ loaded, error }) => (
        <ImageAnimatedLoadStyle loaded={loaded} src={src} {...props} />
      )}
    />
  );
};

export class GalleryImage extends React.Component {
  render() {
    const {
      img: { media },
      setFavorite,
      size = 'lightbox',
      isFavorite,
      location,
      location: { search },
    } = this.props;
    return (
      <div className="image-cell">
        <div style={{ position: 'absolute' }} className="p-3">
          <GrowHoverButton
            type="button"
            className="btn btn-link"
            onClick={() => setFavorite(media)}
          >
            {isFavorite ? (
              <i className="fa fa-star text-warning fa-lg" aria-hidden="true" />
            ) : (
              <i className="fa fa-star text-muted fa-lg" aria-hidden="true" />
            )}
          </GrowHoverButton>
        </div>

        <Link
          to={{
            ...location,
            search: qs.stringify({
              ...qs.parse(search),
              photo: media,
            }),
          }}
        >
          <ImageAnimatedLoad
            mediaId={media}
            size={size}
            alt=""
            className="mb-3 rounded"
            style={{ width: '100%' }}
          />
        </Link>
      </div>
    );
  }
}

export const GridButton = ({ viewMode, setViewMode }) => {
  const row = (
    <div>
      <div />
      <div />
      <div />
    </div>
  );
  return (
    <div
      className={`grid-button${viewMode === 'grid' ? ' active' : ''}`}
      onClick={() => {
        setViewMode('grid');
      }}
    >
      {row}
      {row}
      {row}
    </div>
  );
};

export const ListButton = ({ viewMode, setViewMode }) => {
  return (
    <div
      className={`list-button${viewMode === 'list' ? ' active' : ''}`}
      onClick={() => {
        setViewMode('list');
      }}
    >
      <div />
      <div />
    </div>
  );
};
