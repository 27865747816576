import React from 'react';
import BaseCalendar from 'rc-calendar';
import styled from 'styled-components';
import moment, { Moment } from 'moment-timezone';

import { Tooltip } from '../../../../components/Tooltip';

import 'rc-calendar/assets/index.css';

const LoadingModal = ({
  text = '',
  style = {},
  children,
  ...rest
}: {
  text?: string;
  style?;
  children?;
} & React.HTMLAttributes<HTMLDivElement>) => (
  <div
    style={{
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(255,255,255,0.8)',
      zIndex: 1000,
      ...style,
    }}
    {...rest}
  >
    {children || <p>Loading {text}...</p>}
  </div>
);

const Calendar = styled(BaseCalendar)`
  box-shadow: none;
  width: 100%;
  height: 31.5em;
  font-size: 1rem;
  font-family: inherit;
  border: 1px solid rgba(0, 0, 0, 0.125);

  &,
  .rc-calendar-panel,
  .rc-calendar-date-panel,
  .rc-calendar-body,
  .rc-calendar-table {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .rc-calendar-header {
    height: inherit;
    padding: 0.75rem 1.25rem;
    line-height: inherit;

    a {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .rc-calendar-body {
    padding: 0;
  }

  .rc-calendar-table {
    thead {
      display: flex;
      flex-direction: column;
      tr {
        background-color: #b2bcc7;
        color: white;
        display: flex;
        justify-content: space-evenly;
      }
      th {
        padding: 0;
        line-height: 2.1em;
        font-size: 80%;
        font-weight: 400;
      }
    }
    tbody {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      flex: 1;

      tr {
        display: flex;
        justify-content: space-evenly;
        flex: 1;
        td {
          display: flex;
          flex-direction: column;
          flex: 1;
          align-items: center;
          width: 70%;
          &.rc-calendar-disabled-cell {
            width: inherit;
            align-items: stretch;
          }
          div {
            align-items: center;
            flex: 1;
            margin: inherit;
            width: inherit;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
        }
      }
    }
  }

  .rc-calendar-date {
    color: #b2bcc7;
    font-weight: bold;
  }
  .rc-calendar-next-month-btn-day .rc-calendar-date {
    color: #ddd;
  }

  .rc-calendar-selected-day .rc-calendar-date {
    background-color: #2b87f9;
    border: none;
    color: white;
  }

  .rc-calendar-prev-year-btn,
  .rc-calendar-next-year-btn {
    display: none;
  }

  .rc-calendar-prev-month-btn {
    left: 0;
  }
  .rc-calendar-next-month-btn {
    right: 0;
  }

  .rc-calendar-footer {
    border-top: 1px solid rgba(0, 0, 0, 0.125);
  }
`;

const Times = styled.div`
  .card-header {
    background-color: white;
  }
  .col {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

type IDatetimeSlot = {
  startDate: moment.Moment;
  availableProvidersUids: string[];
  availableSpecialistProvidersUids: string[];
};

function divideProvidersAvailability({
  selectedDate,
  providersAvailability,
}: {
  selectedDate: moment.Moment;
  providersAvailability: IDatetimeSlot[];
}) {
  const currentDayStart = selectedDate.clone().startOf('day');
  const currentDayEnd = selectedDate.clone().endOf('day');

  const currentDayAvailability = providersAvailability.filter(
    ({ startDate }) => startDate > currentDayStart && startDate < currentDayEnd
  );

  const leftStartDate = selectedDate.clone().startOf('day').add(6, 'hour');
  const leftEndDate = selectedDate.clone().startOf('day').add(11.5, 'hour');

  const middleStartDate = leftEndDate;
  const middleEndDate = selectedDate.clone().startOf('day').add(17.5, 'hour');

  const rightStartDate = middleEndDate;
  const rightEndDate = selectedDate.clone().startOf('day').add(23.5, 'hour');

  const leftDatesAvailability = currentDayAvailability.filter(
    ({ startDate }) => startDate >= leftStartDate && startDate <= leftEndDate
  );
  const middleDatesAvailability = currentDayAvailability.filter(
    ({ startDate }) => startDate > middleStartDate && startDate <= middleEndDate
  );
  const rightDatesAvailability = currentDayAvailability.filter(
    ({ startDate }) => startDate > rightStartDate && startDate <= rightEndDate
  );

  return {
    leftDatesAvailability,
    middleDatesAvailability,
    rightDatesAvailability,
    currentDayAvailability,
  };
}

function TimeButton({
  timeSlot: {
    startDate,
    availableProvidersUids,
    availableSpecialistProvidersUids,
  },
  selectedProvider,
  noDatetimeRestrictions,
  onDatetimeSelect,
  selectedDatetime,
  showAvailableInGreen,
}: {
  timeSlot: IDatetimeSlot;
  selectedProvider?: string;
  onDatetimeSelect: (
    p: IDatetimeSlot & { providerNotAvailable: boolean }
  ) => void;
  noDatetimeRestrictions: boolean;
  selectedDatetime;
  showAvailableInGreen?: boolean;
}) {
  const isDisabled = noDatetimeRestrictions
    ? false
    : availableProvidersUids.length === 0;

  const isSelectedDatetime =
    selectedDatetime != null && selectedDatetime.isSame(startDate);

  const providerNotAvailable = !(
    selectedProvider == null ||
    availableProvidersUids.includes(selectedProvider)
  );

  const buttonProps = {
    onClick: () =>
      onDatetimeSelect({
        startDate,
        availableProvidersUids,
        availableSpecialistProvidersUids,

        providerNotAvailable,
      }),
    className: `btn btn-block btn-sm ${
      isSelectedDatetime
        ? 'btn-primary'
        : providerNotAvailable === false
        ? showAvailableInGreen === true
          ? 'btn-outline-success'
          : 'btn-outline-secondary'
        : 'btn-outline-danger'
    }`,
    disabled: isDisabled,
    children: startDate.format('h:mm a'),
  };

  return (
    <>
      {providerNotAvailable ? (
        <Tooltip
          label="Warning: you will have to pick another photographer"
          style={{
            background: 'hsla(0, 0%, 0%, 0.75)',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            padding: '0.5em 1em',
          }}
          centered
        >
          <button {...buttonProps} />
        </Tooltip>
      ) : (
        <button {...buttonProps} />
      )}

      <style jsx>{`
        button {
          font-size: 10px;
        }

        button.btn-outline-secondary:disabled {
          background: #eef2f6;
          border: 1px #eef2f6 solid;
          color: #d3d8dc;
        }
      `}</style>
    </>
  );
}

export function DateTimeSelector(props: {
  disabledDate: () => boolean;
  onDateSelect: (d: Moment | null) => void;
  onDatetimeSelect: (
    p: IDatetimeSlot & { providerNotAvailable: boolean }
  ) => void;
  providersAvailability;
  providersAvailabilityDailyLoading?;
  providersAvailabilityLoading;
  selectedDate: Moment;
  selectedDatetime;
  selectedPlaceAndTimezone?;
  noDatetimeRestrictions: boolean;
  selectedProvider?: string;
}) {
  const {
    disabledDate,
    onDateSelect,
    onDatetimeSelect,
    providersAvailability,
    providersAvailabilityDailyLoading,
    providersAvailabilityLoading,
    selectedDate,
    selectedDatetime,
    selectedPlaceAndTimezone,
    noDatetimeRestrictions = false,
    selectedProvider,
  } = props;

  const {
    leftDatesAvailability,
    middleDatesAvailability,
    rightDatesAvailability,
    currentDayAvailability,
  } = divideProvidersAvailability({ selectedDate, providersAvailability });

  const currentDayHasAnySlotWithoutSelectedProvider =
    selectedProvider != null &&
    currentDayAvailability.find(
      (slot) => slot.availableProvidersUids.includes(selectedProvider) === false
    ) != null;

  return (
    <div className="row">
      <div className="col">
        <Calendar
          showDateInput={false}
          value={selectedDate}
          onSelect={(date) => onDateSelect(date)}
          onChange={(date) => onDateSelect(date)}
          disabledDate={disabledDate}
        />
        {selectedPlaceAndTimezone !== null &&
          providersAvailabilityDailyLoading && (
            <LoadingModal text="availability" />
          )}
      </div>

      <Times className="col">
        <div
          className={`card ${noDatetimeRestrictions ? 'border-danger' : ''}`}
          style={{ height: '31.5em' }}
        >
          <div className="card-header text-center">
            <p className="font-weight-bold m-0">
              {selectedDate.format('D MMM YYYY')}
            </p>
          </div>
          <div
            className={`row text-center ${
              noDatetimeRestrictions ? ' bg-danger' : ''
            }`}
            style={{
              backgroundColor: '#b2bcc7',
              color: 'white',
              marginRight: 0,
              marginLeft: 0,
            }}
          >
            <div className="col">
              <small style={{ lineHeight: '2.1em' }}>Morning</small>
            </div>

            <div className="col">
              <small style={{ lineHeight: '2.1em' }}>Afternoon</small>
            </div>

            <div className="col">
              <small style={{ lineHeight: '2.1em' }}>Evening</small>
            </div>
          </div>

          <div className="card-body" style={{ position: 'relative' }}>
            <div className="row">
              {[
                leftDatesAvailability,
                middleDatesAvailability,
                rightDatesAvailability,
              ].map((availabilitySlotList, index) => (
                <div className="col" key={index}>
                  {availabilitySlotList.map((timeSlot) => (
                    <TimeButton
                      key={timeSlot.startDate.toISOString()}
                      selectedProvider={selectedProvider}
                      noDatetimeRestrictions={noDatetimeRestrictions}
                      onDatetimeSelect={onDatetimeSelect}
                      selectedDatetime={selectedDatetime}
                      timeSlot={timeSlot}
                      showAvailableInGreen={
                        currentDayHasAnySlotWithoutSelectedProvider
                      }
                    />
                  ))}
                </div>
              ))}
            </div>

            {providersAvailabilityLoading && (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'rgba(255,255,255,0.8)',
                }}
              >
                <p>Loading availability...</p>
              </div>
            )}
          </div>
        </div>
      </Times>
    </div>
  );
}
