import React from 'react';
import { Promiser } from 'react-prometo';

export const ImageRowBase = ({
  isUpload,
  sizeUploaded,
  fileSize,
  preSignedUrlPromise,
  uploadPromise,
  mediaCreationPromise,

  children,
}) => (
  <Promiser promise={preSignedUrlPromise}>
    {({ result: preSignedUrlReady }) => (
      <Promiser promise={uploadPromise}>
        {({
          isPending: uploadPending,
          result: uploadReady,
          error: uploadError,
        }) => (
          <Promiser promise={mediaCreationPromise}>
            {({ result: mediaCreationReady }) =>
              children({
                status: isUpload ? (
                  uploadError ? null : mediaCreationReady ? (
                    'Ready'
                  ) : uploadReady ? (
                    'Creating media...'
                  ) : preSignedUrlReady ? (
                    `Uploading`
                  ) : (
                    <span style={{ marginRight: '0.5rem' }}>Queued</span>
                  )
                ) : null,

                progress: uploadError
                  ? null
                  : mediaCreationReady
                  ? null
                  : uploadReady
                  ? null
                  : preSignedUrlReady
                  ? sizeUploaded != null && fileSize != null
                    ? ` ${((sizeUploaded / fileSize) * 100).toFixed(1)}%`
                    : ''
                  : null,

                preSignedUrlReady,
                uploadError,
                uploadPending,
              })
            }
          </Promiser>
        )}
      </Promiser>
    )}
  </Promiser>
);
