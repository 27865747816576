import React from 'react';
import { Link } from 'react-router-dom';

import { Promiser } from '../../../../components/Promiser';
import { Modal } from '../../../../components/Modal';
import { LoadingModal } from '../../../../components/LoadingModal';
import { isPartnerBooking, isBookingReadyForDepositCharge } from '../selectors';
import { ModalRoute, locationForModal } from './ModalRoute';
import { apiFetch } from '../../../../utils';
import { PaymentMethodSelector } from './PaymentMethodSelector';
import { formatMoneyCents } from '../../../../utils';
import { CreditCardLoader, CreditCardInputs } from './CreditCardLoader';

import ccSvg from './img/cc-icon.svg';
import visaSvg from './img/visa.svg';
import mastercardSvg from './img/mastercard.svg';

const MODAL_NAME_DEPOSIT_PAYMENT = 'deposit-payment';

class PaymentErrors extends React.Component {
  render() {
    const { paymentErrors, currency } = this.props;

    const message = (() => {
      if (
        !paymentErrors ||
        !paymentErrors.errors ||
        !(paymentErrors.errors.length > 0)
      ) {
        return ['There was an error with payment.', 'Please try again.'].join(
          ' '
        );
      }

      const [mainError] = paymentErrors.errors;

      if (typeof mainError === 'string') return mainError;

      const code = mainError.code;
      if (code === 'COUPON_PURCHASE_VALUE_MUST_BE_HIGHER') {
        return [
          'Your order value has to be at least',
          formatMoneyCents(mainError.meta.minimum_purchase, {
            currency,
          }),
          'to use this coupon.',
        ].join(' ');
      } else if (code === 'COUPON_HAS_NO_USES_LEFT') {
        return 'This coupon has no uses left.';
      } else if (code === 'COUPON_HAS_EXPIRED') {
        return 'This coupon has expired.';
      }

      return [
        'There was an error with payment.',
        'Please try again or contact support.',
      ].join(' ');
    })();

    return (
      <div className="alert alert-danger" role="alert">
        {message}
      </div>
    );
  }
}

class ChargeModal extends React.Component {
  state = {
    paymentMethodToken: null,
    payWithCreditCard: false,
    depositPrice: 9900,
  };

  pay = ({ paymentMethodToken }) => {
    const {
      session: { token },
      booking,
      onDismiss,
    } = this.props;

    return apiFetch(
      `/api/v2/admin/bookings/${booking.uid}/charge-customer-deposit`,
      {
        token,
        method: 'POST',
        body: JSON.stringify({
          paymentMethodToken,
          deposit_price: this.state.depositPrice,
        }),
      }
    ).then(
      () => onDismiss(),
      (error) => error.json().then((res) => Promise.reject(res))
    );
  };

  render() {
    const { onDismiss, booking, bookingClone, session } = this.props;
    const { paymentMethodToken, payWithCreditCard } = this.state;

    return (
      <Promiser promise={this.state.paymentPromise}>
        {({
          isPending: processingPayment,
          error: paymentErrors,
          result: paymentResult,
        }) => (
          <Modal onDismiss={onDismiss}>
            <div className="card my-4">
              <div className="card-header">
                <h4 className="mb-0">Charge Deposit</h4>
              </div>

              <div
                className="card-body d-flex flex-column"
                style={{ width: '600px', minHeight: '350px' }}
              >
                {!processingPayment && paymentErrors && (
                  <PaymentErrors
                    paymentErrors={paymentErrors}
                    currency={(booking && booking.currency) || 'USD'}
                  />
                )}

                <div className="form-group">
                  <label>
                    <small className="text-secondary">Deposit</small>
                  </label>

                  <input
                    type="text"
                    className="form-control"
                    // readOnly
                    value={this.state.depositPrice / 100 || ''}
                    onChange={({ target: { value } }) =>
                      this.setState({
                        depositPrice: value === '' ? null : value * 100,
                      })
                    }
                  />
                </div>

                <p className="text-left">
                  <strong>Pay with credit card </strong>
                  <span>
                    <img src={ccSvg} alt="credit card" />
                  </span>

                  <span>
                    <img src={visaSvg} alt="visa" className="mx-2" />
                  </span>

                  <span>
                    <img src={mastercardSvg} alt="mastercard" />
                  </span>
                </p>

                {!isPartnerBooking({ booking }) &&
                bookingClone.customer_uid &&
                !payWithCreditCard ? (
                  <div className="flex-1 d-flex flex-column">
                    <div className="flex-1" style={{ position: 'relative' }}>
                      <PaymentMethodSelector
                        bookingClone={bookingClone}
                        session={session}
                        selectedPaymentMethodToken={
                          this.state.paymentMethodToken
                        }
                        onPaymentMethodTokenChange={({ paymentMethodToken }) =>
                          this.setState({ paymentMethodToken })
                        }
                      />

                      <button
                        className="btn btn-link py-2 px-0 mt-3 mb-5 font-weight-normal"
                        onClick={() => {
                          this.setState({ payWithCreditCard: true });
                        }}
                      >
                        Pay with another credit card
                      </button>
                    </div>

                    <div className="d-flex justify-content-between mt-3">
                      <button className="btn btn-secondary" onClick={onDismiss}>
                        Close
                      </button>

                      <button
                        className="btn btn-primary"
                        disabled={!paymentMethodToken || processingPayment}
                        onClick={() =>
                          this.setState({
                            paymentPromise: this.pay({
                              paymentMethodToken: this.state.paymentMethodToken,
                            }),
                          })
                        }
                      >
                        {processingPayment
                          ? 'processing ...'
                          : `Charge ${formatMoneyCents(
                              this.state.depositPrice,
                              { currency: booking.currency }
                            )}`}
                      </button>
                    </div>
                  </div>
                ) : (
                  <CreditCardLoader
                    bookingId={booking.uid}
                    booking={booking}
                    session={session}
                    onProcessing={(processingPayment) =>
                      this.setState({ processingPayment })
                    }
                  >
                    {({
                      tokenize,
                      cardType,
                      cvvValid,
                      expirationDateValid,
                      formValid,
                      loading,
                      numberValid,
                    }) => (
                      <form
                        className="flex-1 d-flex flex-column"
                        onSubmit={(ev) => {
                          ev.preventDefault();
                          this.setState({
                            paymentPromise: tokenize().then(
                              ({ paymentMethodToken }) =>
                                this.pay({ paymentMethodToken })
                            ),
                          });
                        }}
                      >
                        <div
                          className="flex-1"
                          style={{ position: 'relative' }}
                        >
                          <CreditCardInputs
                            numberValid={numberValid}
                            expirationDateValid={expirationDateValid}
                            cvvValid={cvvValid}
                            cardType={cardType}
                            country={booking.country}
                          />

                          <button
                            type="button"
                            className="btn btn-link px-0 pt-0"
                            onClick={() =>
                              this.setState({ payWithCreditCard: false })
                            }
                          >
                            Show saved payment methods
                          </button>

                          {loading && <LoadingModal text="payment form" />}
                        </div>

                        <div className="d-flex justify-content-between mt-3">
                          <button
                            className="btn btn-secondary"
                            onClick={onDismiss}
                          >
                            Close
                          </button>

                          <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={
                              loading || !formValid || processingPayment
                            }
                          >
                            {processingPayment
                              ? 'processing ...'
                              : `Charge ${formatMoneyCents(
                                  this.state.depositPrice,
                                  { currency: booking.currency }
                                )}`}
                          </button>
                        </div>
                      </form>
                    )}
                  </CreditCardLoader>
                )}
              </div>
            </div>
          </Modal>
        )}
      </Promiser>
    );
  }
}

export const ChargeCustomerDepositButton = ({
  session,
  booking,
  bookingClone,
  bookingChangedKeys,
  bookingHasChanges,
  location,
  onReload,
  disabled,
}) => (
  <React.Fragment>
    {!isPartnerBooking({ booking: bookingClone }) &&
      isBookingReadyForDepositCharge({ booking }) &&
      booking.shoottype === 'premium-wedding' &&
      !bookingHasChanges && (
        <Link
          className={`btn btn-success ${disabled ? 'disabled' : ''}`}
          to={locationForModal({
            location,
            modal: { modalName: MODAL_NAME_DEPOSIT_PAYMENT },
          })}
        >
          Charge deposit to the customer
        </Link>
      )}

    <ModalRoute
      modalName={MODAL_NAME_DEPOSIT_PAYMENT}
      render={({ history, location }) => (
        <ChargeModal
          session={session}
          booking={booking}
          bookingClone={bookingClone}
          bookingChangedKeys={bookingChangedKeys}
          bookingHasChanges={bookingHasChanges}
          onDismiss={() => {
            history.replace(
              locationForModal({
                location,
                modal: undefined,
              })
            );

            // reload to fetch new sales agent info in the booking
            // (in case a sales agent was newly set)
            onReload();
          }}
        />
      )}
    />
  </React.Fragment>
);
