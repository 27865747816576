import React from 'react';
import { Link } from 'react-router-dom';

import { gql, useQuery } from '../../../components/ApolloClient';
import { locationForModal, ModalRoute } from '../../../components/ModalRoute';
import { EnterpriseContractModalFirst } from '../../../components/EnterpriseContractModalFirst';
import { EnterpriseContractModalSecond } from '../../../components/EnterpriseContractModalSecond';
import { PartnerContractList } from './list';
import { ContractType } from './utils';
import { EnterpriseContractCancelModal } from '../../../components/EnterpriseContractModalCancel';

const MODAL_NAME_ENTERPRISE_CONTRACT_MODAL_FIRST =
  'modal_name_enterprise_contract_modal_first';
const MODAL_NAME_ENTERPRISE_CONTRACT_MODAL_SECOND =
  'modal_name_enterprise_contract_modal_second';

export const MODAL_NAME_ENTERPRISE_CONTRACT_MODAL_CANCEL =
  'modal_name_enterprise_contract_modal_cancel';

export const OnEnterpriseContractCancelModal = ({
  partnerId,
  session,
  reloadContracts,
}: {
  partnerId: string;
  session: { token: string };
  reloadContracts: () => Promise<any>;
}) => {
  return (
    <ModalRoute
      modalName={MODAL_NAME_ENTERPRISE_CONTRACT_MODAL_CANCEL}
      render={({ history, location, modalParams }) => (
        <EnterpriseContractCancelModal
          onDismiss={() =>
            history.replace(
              locationForModal({
                location,
                modal: undefined,
              })
            )
          }
          session={session}
          location={location}
          history={history}
          partnerId={partnerId}
          onContinue={() =>
            reloadContracts().then(() => {
              history.replace(
                locationForModal({
                  location,
                  modal: undefined,
                })
              );
            })
          }
          modalParams={{
            ...modalParams,
          }}
        />
      )}
    />
  );
};

export const NewContractModal = ({
  partnerId,
  currency,
  session,
  onSuccess,
}: {
  partnerId: string;
  currency: string;
  session: { token: string };
  onSuccess: () => Promise<any>;
}) => {
  return (
    <>
      <ModalRoute
        modalName={MODAL_NAME_ENTERPRISE_CONTRACT_MODAL_FIRST}
        render={({ history, location, modalParams }) => (
          <EnterpriseContractModalFirst
            session={session}
            location={location}
            history={history}
            partnerId={partnerId}
            currency={currency}
            modalParams={modalParams}
            onContinue={() =>
              history.push(
                locationForModal({
                  location,
                  modal: {
                    ...modalParams,
                    modalName: MODAL_NAME_ENTERPRISE_CONTRACT_MODAL_SECOND,
                  },
                })
              )
            }
            onDismiss={() =>
              history.replace(
                locationForModal({
                  location,
                  modal: undefined,
                })
              )
            }
          />
        )}
      />
      <ModalRoute
        modalName={MODAL_NAME_ENTERPRISE_CONTRACT_MODAL_SECOND}
        render={({ history, location, modalParams }) => (
          <EnterpriseContractModalSecond
            session={session}
            location={location}
            history={history}
            partnerId={partnerId}
            currency={currency}
            modalParams={modalParams}
            onDismiss={() =>
              history.replace(
                locationForModal({
                  location,
                  modal: undefined,
                })
              )
            }
            onContinue={() => {
              onSuccess().then(() =>
                history.replace(
                  locationForModal({
                    location,
                    modal: undefined,
                  })
                )
              );
            }}
          />
        )}
      />
    </>
  );
};

export function EnterpriseContractSection({
  session,
  partner,
  location,
}: {
  session: { token: string };
  partner: { name: string; uid: string; currency: string };
  location;
}) {
  const partnerContractsQuery = useQuery<{
    partnerById: {
      contracts: { edges: ContractType[] };
    };
  }>(
    gql`
      query PartnerContractsListQueryForAdmin($partnerId: UUID!) {
        partnerById(uid: $partnerId) {
          contracts {
            edges {
              id
              type
              commitmentCreditsCents
              discountPercentage
              commissionPercentage
              startAt
              expiresAt
              durationInMonths
              status
              remainingTime(unit: "months")
              pdfUrl
              subscriptionPlan {
                id
                displayName
              }
              subscriptionPlanDiscountPercentage
            }
          }
        }
      }
    `,
    { variables: { partnerId: partner.uid } }
  );

  const contracts =
    partnerContractsQuery.data?.partnerById.contracts.edges ?? [];

  return (
    <>
      <div className="d-flex justify-content-end align-items-center mb-3 mt-3">
        <Link
          className="btn btn-dark btn-sm mr-3"
          to={locationForModal({
            location,
            modal: {
              modalName: MODAL_NAME_ENTERPRISE_CONTRACT_MODAL_FIRST,
            },
          })}
        >
          Create contract
        </Link>
      </div>

      <PartnerContractList
        contractList={contracts}
        location={location}
        currency={partner.currency}
      />

      <NewContractModal
        partnerId={partner.uid}
        currency={partner.currency}
        session={session}
        onSuccess={partnerContractsQuery.refetch}
      />

      <OnEnterpriseContractCancelModal
        partnerId={partner.uid}
        session={session}
        reloadContracts={partnerContractsQuery.refetch}
      />
    </>
  );
}
