import React from 'react';
import { Link } from 'react-router-dom';

export { Promiser } from './Promiser';
export {
  timezoneByPoint,
  TimezoneFromCoordsFetcher,
  MapsLibLoader,
  AddressAutocompleteInput,
  MyMap,
  Marker,
  Circle,
} from './AddressAutocompleteInput';

export const TdLink = ({
  title,
  className,
  tdClassName = '',
  tdStyle = {},
  style = {},
  ...props
}: {
  title?;
  className?;
  tdClassName?;
  tdStyle?;
  to;
  children;
  style?;
}) => (
  <td title={title} className={'align-middle ' + tdClassName} style={tdStyle}>
    <Link
      className={['link d-inline-block text-truncate', className]
        .filter((r) => !!r)
        .join(' ')}
      style={{ ...style, color: style?.color ?? '#494f54' }}
      {...props}
    />
  </td>
);
