import React from 'react';
import { createPortal } from 'react-dom';

export class Modal extends React.Component {
  componentDidMount() {
    const body = document.body;
    body.className = [...body.className.split(' '), 'modal-open'].join(' ');

    this.cleanupBodyClass = () => {
      body.className = body.className
        .split(' ')
        .filter((c) => c !== 'modal-open')
        .join(' ');
    };
  }

  componentWillUnmount() {
    this.cleanupBodyClass();
  }

  render() {
    const { onDismiss, children, backgroundColor, outline } = this.props;
    return createPortal(
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          alignItems: 'center',
          overflowY: 'auto',
          zIndex: 99999999,
        }}
      >
        <button
          style={{
            backgroundColor: backgroundColor ?? 'rgba(0,0,0,0.5)',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            border: 'none',
            width: '100%',
            outline: outline != null ? outline : undefined,
          }}
          onClick={onDismiss}
        />
        <div
          style={{
            maxHeight: '100vh',
            margin: '0 auto',
          }}
        >
          {children}
        </div>
      </div>,
      document.getElementById('app-popovers')
    );
  }
}
