import React from 'react';

import { isPartnerBooking, isBookingReadyForCharge } from '../selectors';
import { formatCredits } from '../../../../utils';

export const PartnerChargeButton = ({
  booking,
  bookingClone,
  bookingIsPending,
  disabled,
  chargePartnerBooking,
  chargePartnerBookingPromiser,
  bookingHasChanges,
}) =>
  isPartnerBooking({ booking: bookingClone }) &&
  !bookingHasChanges &&
  isBookingReadyForCharge({ booking }) ? (
    <button
      className="btn btn-warning"
      disabled={bookingIsPending || disabled}
      onClick={chargePartnerBooking}
    >
      {chargePartnerBookingPromiser.isPending
        ? 'Charging...'
        : `Charge ${formatCredits(booking.final_price)} credits`}
    </button>
  ) : null;
