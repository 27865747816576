import React from 'react';
import { gql, useMutation } from '../../../../components/ApolloClient';

export const SetFromPaidPendingScheduleToPaid = ({
  booking,
  onReload,
  disabled,
}: {
  booking?;
  onReload: () => void;
  disabled?: boolean;
}) => {
  const [setToPaid, setToPaidMutation] = useMutation(gql`
    mutation SetBookingFromPaidPendingScheduleToPaidAsAdmin($bookingId: ID!) {
      bookingSetToPaidFromPaidPendingAsAdmin(input: { bookingId: $bookingId }) {
        success
      }
    }
  `);

  return (
    <>
      <button
        className={`btn btn-success`}
        disabled={setToPaidMutation.loading || disabled}
        onClick={() =>
          setToPaid({
            variables: {
              bookingId: booking.uid,
            },
          }).then(() => onReload())
        }
      >
        Change from paid pending schedule to paid
      </button>
    </>
  );
};
