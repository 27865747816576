import React, { Component } from 'react';
import DeclarativeComponent from '@reach/component-component';
import { Link } from 'react-router-dom';

import { Fetcher } from '../../../../../components/Fetcher';
import { locationForModal } from '../ModalRoute';
import { Modal } from '../../../../../components/Modal';
import { MODAL_NAME_CUSTOMER_CREATION } from './utils';

const CustomerRow = ({ customer, selectedCustomerUid, onCustomerSelect }) => (
  <tr className={selectedCustomerUid === customer.uid ? 'table-success' : ''}>
    <td>
      <button
        className="btn btn-link p-0 text-dark btn-block text-left"
        onClick={() => onCustomerSelect({ customer_uid: customer.uid })}
        style={{ fontSize: 14 }}
        title={customer.uid}
      >
        {customer.firstname}
      </button>
    </td>

    <td>
      <button
        className="btn btn-link p-0 text-dark btn-block text-left"
        onClick={() => onCustomerSelect({ customer_uid: customer.uid })}
        style={{ fontSize: 14 }}
        title={customer.uid}
      >
        {customer.surname}
      </button>
    </td>

    <td>
      <button
        className="btn btn-link p-0 text-dark btn-block text-left"
        onClick={() => onCustomerSelect({ customer_uid: customer.uid })}
        style={{ fontSize: 14 }}
        title={customer.uid}
      >
        {customer.email}
      </button>
    </td>

    <td>
      <button
        className="btn btn-link p-0 text-dark btn-block text-left"
        onClick={() => onCustomerSelect({ customer_uid: customer.uid })}
        style={{ fontSize: 14 }}
        title={customer.uid}
      >
        {customer.mobilephone}
      </button>
    </td>
  </tr>
);

export class CustomerSelectionModal extends Component {
  state = { customer_uid: null };

  componentDidMount() {
    this.setState({ customer_uid: this.props.bookingClone.customer_uid });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.bookingClone.customer_uid !==
      prevProps.bookingClone.customer_uid
    ) {
      this.setState({ customer_uid: this.props.bookingClone.customer_uid });
    }
  }

  render() {
    const {
      onDismiss,
      session,
      modalParams,
      location,
      history,
      bookingClone,
      onCustomerChange,
    } = this.props;

    return (
      <Modal onDismiss={onDismiss}>
        <div className="card my-4">
          <div className="card-header">
            <h4 className="mb-0">Customer Selection</h4>
          </div>

          <div
            className="card-body d-flex flex-column"
            style={{ minWidth: '1000px', minHeight: '600px' }}
          >
            <div className="d-flex justify-content-between mb-3">
              <Link
                to={locationForModal({
                  location,
                  modal: {
                    modalName: MODAL_NAME_CUSTOMER_CREATION,
                  },
                })}
                className="btn btn-primary"
              >
                Create customer
              </Link>

              <DeclarativeComponent
                modalParams={modalParams}
                initialState={{ q: null }}
                didMount={({
                  props: {
                    modalParams: { q },
                  },
                  setState,
                }) => setState({ q })}
                didUpdate={({
                  props: {
                    modalParams: { q },
                  },
                  props,
                  prevProps,
                  setState,
                }) =>
                  props.modalParams.q !== prevProps.modalParams.q &&
                  setState({ q })
                }
              >
                {({ state, setState }) => (
                  <form
                    onSubmit={(ev) => {
                      ev.preventDefault();
                      history.push(
                        locationForModal({
                          location,
                          modal: {
                            ...modalParams,
                            q: state.q || undefined,
                          },
                        })
                      );
                    }}
                  >
                    <input
                      type="text"
                      className="form-control"
                      style={{ maxWidth: 300 }}
                      placeholder="Search"
                      value={state.q || ''}
                      onChange={({ target: { value } }) =>
                        setState({ q: value })
                      }
                    />
                  </form>
                )}
              </DeclarativeComponent>
            </div>

            <Fetcher
              urlToFetch="/api/v2/customers"
              queryToFetch={{ q: modalParams.q }}
              session={session}
            >
              {({
                loading: customersLoading,
                response: { results: customers = [] } = {},
              }) => (
                <div
                  className="table-responsive flex-1"
                  style={{ position: 'relative', overflowY: 'auto' }}
                >
                  <table className="table table-hover table-sm">
                    <thead>
                      <tr>
                        <th>First name</th>
                        <th>Surname</th>
                        <th>Email</th>
                        <th>Phone</th>
                      </tr>
                    </thead>

                    <tbody>
                      {customers.map((customer) => (
                        <CustomerRow
                          key={customer.uid}
                          customer={customer}
                          selectedCustomerUid={this.state.customer_uid}
                          onCustomerSelect={({ customer_uid }) =>
                            this.setState({ customer_uid })
                          }
                        />
                      ))}
                    </tbody>
                  </table>

                  {customersLoading && (
                    <div
                      className={[
                        'd-flex flex-columns',
                        'justify-content-center align-items-center',
                      ].join(' ')}
                      style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                      }}
                    >
                      <span>Loading...</span>
                    </div>
                  )}
                </div>
              )}
            </Fetcher>

            <div className="d-flex justify-content-between">
              <button className="btn btn-secondary" onClick={onDismiss}>
                Close
              </button>

              <button
                className="btn btn-primary"
                disabled={this.state.customer_uid === bookingClone.customer_uid}
                onClick={() => {
                  onCustomerChange({ customer_uid: this.state.customer_uid });
                  onDismiss();
                }}
              >
                Select
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
