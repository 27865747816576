import React from 'react';
import { STANDARD_BORDER } from '.';

export const SimpleLine = () => (
  <hr
    style={{
      flex: 1,
      border: 'none',
      borderTop: STANDARD_BORDER,
      marginBottom: 16,
    }}
  />
);
