import { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  white-space: nowrap;

  .slider-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    transition: ${(props) => props.animate && 'transform ease-out 0.3s'};
    transform: translateX(${(props) => props.translateValue}px);

    .slide {
      display: inline-block;
      height: 100%;
      width: 100%;
    }
  }
`;

export const TopControls = styled.div`
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5), transparent);
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  z-index: 1;
`;

export const BottomControls = styled.div`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), transparent);
  position: absolute;
  width: 100%;
  bottom: 0;
  right: 0;
  z-index: 1;

  @media (min-width: 640px) {
    background-image: none;
    bottom: 45%;
  }
`;

export class Slider extends Component {
  state = {
    index: this.props.index || 0,
    animate: false,
  };

  static propTypes = {
    images: PropTypes.array.isRequired,
    index: PropTypes.number,
  };

  componentDidMount() {
    this.preselectIndex(this.props.index || 0);
  }

  preselectIndex = (index) => {
    this.setState({ index }, () => this.setState({ animate: true }));
  };

  onSlideRef = (slideRef) => {
    this.slideRef = slideRef;
  };

  prev = () => {
    const { images } = this.props;
    const { index } = this.state;
    if (this.state.index === 0) {
      return this.setState({ index: images.length - 1 });
    }

    this.setState({
      index: index - 1,
    });
  };

  next = () => {
    const { images } = this.props;
    const { index } = this.state;

    if (this.state.index === images.length - 1) {
      return this.setState({
        index: 0,
      });
    }

    this.setState({
      index: index + 1,
    });
  };

  slideWidth = () => {
    return (this.slideRef && this.slideRef.clientWidth) || 0;
  };

  render() {
    const { index, animate } = this.state;
    const translateValue = -(index * this.slideWidth());
    const render = this.props.children || this.props.render;

    return render({
      index,
      translateValue,
      animate,
      next: this.next,
      prev: this.prev,
      onSlideRef: this.onSlideRef,
    });
  }
}

export class KeyboardControlEvents extends Component {
  componentDidMount() {
    window.addEventListener('keydown', this.addKeyboardControls);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.addKeyboardControls);
  }

  addKeyboardControls = (event) => {
    const { right, left, esc } = this.props;
    const { key } = event;
    if (key === 'ArrowRight') right && right();
    if (key === 'ArrowLeft') left && left();
    if (key === 'Escape') esc && esc();
  };

  render() {
    const render = this.props.children || this.props.render;
    return render();
  }
}
