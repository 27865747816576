import React from 'react';
import Dropzone from 'react-dropzone';
import { Link } from 'react-router-dom';
import { gql, useApolloClient } from '../../../components/ApolloClient';
import { apiFetch } from '../../../utils/apiFetch';
import {
  backgroundRemovalAutoIconPath,
  bgBlurIconPath,
  bgExtensionIconPath,
  bgRemovalIconPath,
  brandRemovalIconPath,
  commentsIconPath,
  croppingAndResizingIconPath,
  dropShadowIconPath,
  faceRetouchingIconPath,
  formatIconPath,
  imageEnhancementAutoIconPath,
  imgEnhancementIconPath,
  objectClassificationAutoIconPath,
  objectClassificationIconPath,
  objectRemovalIconPath,
  perspectiveCorrectionIconPath,
  productRetouchingIconPath,
  referenceImgIconPath,
  skyReplacementIconPath,
  spotRemovalIconPath,
  upscalingAutoIconPath,
  upscallingIconPath,
  virtualStagingIconPath,
} from '../components/img';
import { useEditingJobQuery, useEditingUploadManager } from './utils';

enum EditingServicesEnum {
  ImageEnhancement = 'image-enhancement',
  CroppingAndResizing = 'cropping-and-resizing',
  Upscaling = 'upscaling',
  ObjectRemoval = 'object-removal',
  BackgroundRemoval = 'background-removal',
  BackgroundBlur = 'background-blur',
  SkyReplacement = 'sky-replacement',
  BackgroundExtension = 'background-extension',
  SpotRemoval = 'spot-removal',
  ObjectClassification = 'object-classification',
  BackgroundRemovalAuto = 'background-removal-auto',
  ImageEnhancementAuto = 'image-enhancement-auto',
  UpscalingAuto = 'upscaling-auto',
  ObjectClassificationAuto = 'object-classification-auto',
  VirtualStaging = 'virtual-staging',
  FaceRetouching = 'face-retouching',
  PerspectiveCorrection = 'perspective-correction',
  DropShadow = 'drop-shadow',
  ProductRetouching = 'product-retouching',
  BrandRemoval = 'brand-removal',
}
const iconPaths = {
  [EditingServicesEnum.ImageEnhancement]: imgEnhancementIconPath,
  [EditingServicesEnum.CroppingAndResizing]: croppingAndResizingIconPath,
  [EditingServicesEnum.Upscaling]: upscallingIconPath,
  [EditingServicesEnum.FaceRetouching]: faceRetouchingIconPath,
  [EditingServicesEnum.BackgroundRemoval]: bgRemovalIconPath,
  [EditingServicesEnum.BackgroundBlur]: bgBlurIconPath,
  [EditingServicesEnum.ObjectRemoval]: objectRemovalIconPath,
  [EditingServicesEnum.SkyReplacement]: skyReplacementIconPath,
  [EditingServicesEnum.VirtualStaging]: virtualStagingIconPath,
  [EditingServicesEnum.ObjectClassification]: objectClassificationIconPath,
  [EditingServicesEnum.BackgroundExtension]: bgExtensionIconPath,
  [EditingServicesEnum.SpotRemoval]: spotRemovalIconPath,
  [EditingServicesEnum.BackgroundRemovalAuto]: backgroundRemovalAutoIconPath,
  [EditingServicesEnum.ImageEnhancementAuto]: imageEnhancementAutoIconPath,
  [EditingServicesEnum.UpscalingAuto]: upscalingAutoIconPath,
  [EditingServicesEnum.ObjectClassificationAuto]: objectClassificationAutoIconPath,
  [EditingServicesEnum.PerspectiveCorrection]: perspectiveCorrectionIconPath,
  [EditingServicesEnum.DropShadow]: dropShadowIconPath,
  [EditingServicesEnum.ProductRetouching]: productRetouchingIconPath,
  [EditingServicesEnum.BrandRemoval]: brandRemovalIconPath,
};

interface IEditingJobServiceDetails {
  notes?: string;
  backgroundRemovalType?: string;
  backgroundRemovalColor?: string;
  upscalingType?: string;
  upscalingCustomValue?: string;
  croppingRatio?: string;
  croppingWidth?: number;
  croppingHeight?: number;
  croppingLongestEdge?: number;
  croppingOriginalDimensions?: string;
  virtualStagingStyle?: string;
  bgExtensionRatio?: string;
  bgExtensionHeight?: number;
  bgExtensionWidth?: number;
  bgExtensionNotes?: string;
  bgBlurNotes?: string;
  skyReplacementNotes?: string;
  dropShadowNotes?: string;
}

export enum BackgroundRemovalTypes {
  WhiteBackground = 'white-background',
  TransparentBackground = 'transparent-background',
  BlackBackground = 'black-background',
  CustomColorBackground = 'custom-color-background',
}

export enum UpscalingType {
  TwiceSize = 'twice-size',
  ThreeTimesSize = 'three-times-size',
  CustomSize = 'custom-size',
}

export const BG_REMOVAL_TYPE_LABEL = {
  [BackgroundRemovalTypes.WhiteBackground]: 'White background',
  [BackgroundRemovalTypes.TransparentBackground]: 'Transparent background',
  [BackgroundRemovalTypes.BlackBackground]: 'Black background',
  [BackgroundRemovalTypes.CustomColorBackground]: 'Custom color',
};

export const UPSCALING_TYPE_LABEL = {
  [UpscalingType.TwiceSize]: 'Twice the size',
  [UpscalingType.ThreeTimesSize]: 'Three times size',
  [UpscalingType.CustomSize]: 'Custom scale',
};

const removeFileExtensionFromFileName = ({
  fileName,
}: {
  fileName: string | undefined;
}) => {
  const fileNameSplitByDots = fileName?.split('.');
  fileNameSplitByDots?.pop();

  return fileNameSplitByDots?.join('');
};

export const formatTooltipMessage = (value: IEditingJobServiceDetails) => {
  if (value?.backgroundRemovalType != null) {
    return `${BG_REMOVAL_TYPE_LABEL[value?.backgroundRemovalType] ?? ''} ${
      value?.backgroundRemovalColor ?? ''
    }`;
  }

  if (value?.upscalingType != null) {
    return `${UPSCALING_TYPE_LABEL[value?.upscalingType] ?? ''} ${
      value?.upscalingCustomValue ?? ''
    }`;
  }

  if (value?.virtualStagingStyle != null) {
    return `${value?.virtualStagingStyle[0].toUpperCase()}${value?.virtualStagingStyle.slice(
      1
    )} style`;
  }

  if (value?.bgExtensionRatio != null) {
    return value?.bgExtensionRatio === 'custom'
      ? `${value?.bgExtensionWidth}:${value?.bgExtensionHeight} ratio`
      : `${value?.bgExtensionRatio} ratio`;
  }

  if (value?.bgBlurNotes != null) {
    return `${value?.bgBlurNotes} `;
  }

  if (value?.skyReplacementNotes != null) {
    return `${value?.skyReplacementNotes} `;
  }

  if (value?.dropShadowNotes != null) {
    return `${value?.dropShadowNotes} `;
  }

  if (value?.notes != null || value?.croppingRatio != null) {
    return [
      value.notes != null && value.notes !== ''
        ? `Notes: ${value.notes}`
        : null,
      value?.croppingRatio != null
        ? `Cropping ratio: ${value.croppingRatio}`
        : null,
      value?.croppingWidth != null && value?.croppingWidth !== 0
        ? `Width: ${value.croppingWidth}`
        : null,
      value?.croppingHeight != null && value?.croppingHeight !== 0
        ? `Height: ${value.croppingHeight}`
        : null,
      value?.croppingLongestEdge != null && value?.croppingLongestEdge !== 0
        ? `Longest edge: ${value.croppingLongestEdge}`
        : null,
      value?.croppingOriginalDimensions != null &&
      value?.croppingOriginalDimensions !== ''
        ? `Resizing type: ${value.croppingOriginalDimensions}`
        : null,
    ]
      .filter((m) => m != null)
      .join(', ');
  }
};

const LabelIcon = ({
  label,
  description,
  icon,
  iconAlt,
  total,
  link,
}: {
  label?: string;
  description?: string;
  icon?: string;
  iconAlt?: string;
  total?: number;
  link?: string;
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginTop: 8,
        marginBottom: 24,
      }}
    >
      <div style={{ position: 'relative', marginRight: 12, marginLeft: 2 }}>
        <img
          style={{
            width: 18,
            height: 18,
          }}
          src={icon}
          alt={iconAlt}
        />
        {total != null && (
          <div
            style={{
              backgroundColor: '#454F5C',
              width: 14,
              height: 14,
              color: '#ffffff',
              position: 'absolute',
              fontSize: 8,
              borderRadius: 7,
              textAlign: 'center',
              lineHeight: 2,
              right: -7,
              top: 14,
            }}
          >
            {total}
          </div>
        )}
      </div>
      <div>
        <div
          style={{
            fontSize: 16,
            fontWeight: 700,
            lineHeight: '16px',
            letterSpacing: '2%',
            marginTop: 3,
          }}
        >
          {label}
        </div>
        {description != null &&
          (link != null ? (
            <Link to={link} target="_blank">
              <div style={{ fontSize: 14, color: '#C1C8D4', marginTop: 4 }}>
                {description}
              </div>
            </Link>
          ) : (
            <div style={{ fontSize: 14, color: '#C1C8D4', marginTop: 4 }}>
              {description}
            </div>
          ))}
      </div>
    </div>
  );
};

export function EditingJobDetailsSection<T extends { itemId: string }>({
  session: { token },
  reload,
  editingJobQuery,
  editingUploadManager,
}: {
  editingJobQuery: ReturnType<typeof useEditingJobQuery>;
  editingUploadManager: ReturnType<typeof useEditingUploadManager>;

  session: { token: string };
  reload;
}) {
  const editingJob = editingJobQuery.data?.editingJob;

  const apolloClient = useApolloClient();

  return (
    <>
      <h3
        style={{
          color: '#454F5C',
          fontSize: 26,
          fontStyle: 'bold',
          lineHeight: '33,8px',
        }}
      >
        Editing services
      </h3>

      <div
        style={{
          display: 'grid',
          margin: 24,
          gridTemplateColumns: '1fr 1fr',
        }}
      >
        <div>
          {editingJob?.servicesSummary?.edges?.map((service) => (
            <LabelIcon
              icon={iconPaths[service?.editingJobService?.slug ?? '']}
              key={service.id}
              iconAlt={service?.editingJobService?.displayName}
              label={service?.editingJobService?.displayName}
              description={formatTooltipMessage(service?.details)}
              total={+service?.quantity}
            />
          ))}
        </div>

        <div>
          {editingJob?.notes != null && (
            <LabelIcon
              icon={commentsIconPath}
              iconAlt="title"
              label={'Additional instructions'}
              description={editingJob?.notes ?? ''}
            />
          )}

          <LabelIcon
            icon={formatIconPath}
            iconAlt="title"
            label={'File format'}
            description={'JPG'}
          />

          {editingJob?.booking != null && (
            <LabelIcon
              icon={commentsIconPath}
              iconAlt="title"
              label={'Related booking'}
              description={editingJob?.booking.id ?? ''}
              link={`/booking/${editingJob.booking?.id}`}
            />
          )}

          {editingJob?.referenceMedias?.edges &&
          editingJob?.referenceMedias?.edges?.length > 0 ? (
            <>
              <LabelIcon
                icon={referenceImgIconPath}
                iconAlt="Reference images"
                label="Reference images"
              />

              <div
                style={{
                  display: 'flex',
                  maxWidth: '300',
                  overflowX: 'scroll',
                  overflow: 'hidden',
                }}
              >
                {editingJob?.referenceMedias?.edges?.map((image) =>
                  image?.referenceImage != null ? (
                    <div>
                      <img
                        loading="lazy"
                        width={104}
                        height={104}
                        alt={'reference'}
                        key={image.id}
                        style={{
                          marginTop: 16,
                          marginRight: 16,
                          borderRadius: 2,
                          objectFit: 'cover',
                        }}
                        src={image.referenceImage?.medium.url}
                      />

                      <div>
                        <a
                          style={{
                            border: 'none',
                            backgroundColor: 'transparent',
                          }}
                          href={image.referenceImage.downloadUrl}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i
                            className="fa fa-download fa-lg"
                            aria-hidden="true"
                          />
                        </a>
                      </div>
                    </div>
                  ) : null
                )}
              </div>
            </>
          ) : null}
        </div>
      </div>

      <Dropzone
        style={{
          height: 136,
          borderWidth: 1,
          borderColor: '#C1C8D4',
          borderStyle: 'dashed',
          borderRadius: 2,
          backgroundColor: '#F6F9FF',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        accept="image/jpeg"
        onDrop={(acceptedFiles) => {
          const toUpload = [...acceptedFiles].reduce((acc, file) => {
            const item = editingJob?.items.edges.find((i) => {
              const originalFileExtension = i.originalImage?.fileName
                ?.split('.')
                .pop();

              const fileHasSupportedExtension = ['jpg', 'jpeg', 'png'].includes(
                originalFileExtension?.toLowerCase() ?? ''
              );

              const fileNameMatchesOriginal = fileHasSupportedExtension
                ? i.originalImage?.fileName === file.name
                : removeFileExtensionFromFileName({
                    fileName: i.originalImage?.fileName,
                  }) ===
                  removeFileExtensionFromFileName({
                    fileName: file.name,
                  });

              return (
                i.finalImage == null &&
                editingUploadManager.uploadsList.find(
                  (upload) => (upload.customData as T).itemId === i.id
                ) == null &&
                fileNameMatchesOriginal
              );
            });

            if (item != null) {
              acc.push({ file, customData: { itemId: item.id } as T });
            }

            return acc;
          }, [] as { file: File; customData: T }[]);

          editingUploadManager.addFiles({
            filesWithCustomData: toUpload,

            onUploadReadyCustom: ({ fileName, mediaUid, remove, customData }) =>
              apiFetch('/api/v2/media/create-from-temp', {
                token,
                method: 'POST',
                body: JSON.stringify({
                  tempMediaUid: mediaUid,
                  fileName,
                }),
              }).then(() =>
                Promise.resolve()
                  .then(() =>
                    customData == null
                      ? null
                      : apolloClient.mutate({
                          mutation: gql`
                            mutation EditingJobSaveImagesUpdateOnImagesTable2(
                              $editingJobId: ID!
                              $originalMediaId: ID!
                              $finalMediaId: ID
                            ) {
                              editingJobUpdateItem(
                                input: {
                                  editingJobId: $editingJobId
                                  originalMediaId: $originalMediaId
                                  finalMediaId: $finalMediaId
                                }
                              ) {
                                item {
                                  id
                                }
                              }
                            }
                          `,

                          variables: {
                            editingJobId: editingJob?.id,
                            originalMediaId: editingJob?.items.edges.find(
                              (i) => i.id === (customData as T).itemId
                            )?.originalImage?.id,
                            finalMediaId: mediaUid,
                          },
                        })
                  )
                  .then(() => reload())
                  .then(() => remove())
              ),
          });
        }}
      >
        <p
          style={{
            width: 176,
            fontWeight: 400,
            fontSize: 12,
            lineHeight: '16px',
            letterSpacing: '2%',
            color: '#454F5C',
            textAlign: 'center',
            marginBottom: 0,
          }}
        >
          Drag and drop your final images here, or{' '}
          <button
            style={{
              textDecoration: 'underline',
              cursor: 'pointer',
              background: 'transparent',
              border: 'none',
              fontWeight: 400,
              fontSize: 12,
              lineHeight: '16px',
              letterSpacing: '2%',
              color: '#454F5C',
              textAlign: 'center',
            }}
          >
            browse
          </button>
        </p>
      </Dropzone>
    </>
  );
}
