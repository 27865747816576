import React, { FormEvent } from 'react';

import {
  gql,
  useLazyQuery,
  useMutation,
  useQuery,
} from '../../../components/ApolloClient';

import { apiFetch } from '../../../utils/apiFetch';
import { Link, RouteComponentProps } from 'react-router-dom';
import { ModalRoute, locationForModal } from '../../../components/ModalRoute';
import { Modal } from '../../../components/Modal';

import { usePromise } from '../../../components/usePromise';
import { checkIfSuperAdmin } from '../../../utils';

import { APARSNIP_URL } from '../../../config';
import { AddSceneToProduct, UpgradeScenePackage } from '../components';
import { Fetcher } from '../../../components/Fetcher';

const MODAL_NAME_ASSIGN_USER = 'assign-user';
const MODAL_NAME_ADD_SCENE = 'add_scene';
const MODAL_NAME_UPGRADE_PACKAGE = 'upgrade_package';

const UserRow = ({
  user,
  selectedUserId,
  onUserSelect,
}: {
  user: { id: string; firstName?: string; lastName?: string; email?: string };
  selectedUserId: string;
  onUserSelect: (params: { userId: string }) => void;
}) => {
  const handleClick = () => {
    onUserSelect({ userId: user.id });
  };
  return (
    <tr className={selectedUserId === user.id ? 'table-success' : ''}>
      <td>
        <button
          className="btn btn-link p-0 text-dark btn-block text-left"
          onClick={handleClick}
          style={{ fontSize: 14 }}
          title={user.id}
        >
          {user.firstName}
        </button>
      </td>

      <td>
        <button
          className="btn btn-link p-0 text-dark btn-block text-left"
          onClick={handleClick}
          style={{ fontSize: 14 }}
          title={user.id}
        >
          {user.lastName}
        </button>
      </td>

      <td>
        <button
          className="btn btn-link p-0 text-dark btn-block text-left"
          onClick={handleClick}
          style={{ fontSize: 14 }}
          title={user.id}
        >
          {user.email}
        </button>
      </td>
    </tr>
  );
};

export const AssignJobToUserModal = ({
  modalParams,
  productJobId,
  onDismiss,
  refetch,
}: {
  modalParams?: {
    productJobId?: string;
  };
  productJobId?: string;
  onDismiss: () => void;
  refetch?: () => void;
}) => {
  const [selectedUserId, setSelectedUserID] = React.useState('');
  const [search, setSearch] = React.useState('');

  const updatePromise = usePromise();
  const createUserPromise = usePromise();

  const [listOfCustomers, listOfCustomersQuery] = useLazyQuery<{
    iamUserList?: {
      edges?: {
        id: string;
        firstName?: string;
        lastName?: string;
        email?: string;
      }[];
    };
  }>(
    gql`
      query IamUserSearchQueryForAIProduct($search: String) {
        iamUserList(filterHasAnyRole: false, search: $search) {
          edges {
            id
            firstName
            lastName
            email
          }
        }
      }
    `
  );

  const listOfCustomersFound = listOfCustomersQuery.data?.iamUserList?.edges;

  const loadingUsers = listOfCustomersQuery.loading;

  const [assignUserToProductJob] = useMutation<
    {
      aiProductJobAssignUser: {
        success: boolean;
        aiProductJobUser: {
          id: string;
          email: string;
        };
      };
    },
    {
      aiProductJobId: string;
      userId: string;
    }
  >(
    gql`
      mutation assignUserToProductJobAsAdmin(
        $aiProductJobId: ID!
        $userId: ID!
      ) {
        aiProductJobAssignUser(
          input: { aiProductJobId: $aiProductJobId, userId: $userId }
        ) {
          success
          aiProductJobUser {
            id
            email
          }
        }
      }
    `
  );

  const [userCreationInfo, setUserCreationInfo] = React.useState<{
    emailInput: string;
    firstnameInput: string;
    surnameInput: string;
    mobilePhoneInput: string;
    mobilePhoneCountryInput: string;
  }>({
    emailInput: '',
    firstnameInput: '',
    surnameInput: '',
    mobilePhoneInput: '',
    mobilePhoneCountryInput: 'US',
  });

  const [isEmailValid, setIsEmailValid] = React.useState<boolean>(true);

  const [emailExists, emailExistsQuery] = useLazyQuery<{
    validatedUser?: {
      exists: boolean;
    };
  }>(
    gql`
      query EmailExistsOnNewUserCreationForAiProductJobAsAdmin(
        $email: String!
      ) {
        validatedUser: validateIfUserExists(email: $email) {
          exists
        }
      }
    `,
    {
      variables: { email: userCreationInfo.emailInput },
    }
  );
  const [userCreationErrors, setUserCreationErrors] = React.useState<
    Array<any>
  >();

  const [
    userCreationSuccess,
    setUserCreationSuccess,
  ] = React.useState<boolean>();

  const createNewUser = async (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();

    resetValues();

    await emailExists({
      variables: { email: userCreationInfo.emailInput },
    });

    await createUserPromise.setPromise(
      apiFetch('/api/v2/customers', {
        method: 'POST',
        body: JSON.stringify({
          email: userCreationInfo.emailInput,
          firstname: userCreationInfo.firstnameInput,
          surname: userCreationInfo.surnameInput,
          mobilephone: userCreationInfo.mobilePhoneInput,
          mobilephone_country: userCreationInfo.mobilePhoneCountryInput,
          password: Math.random().toString(36).substring(4),
          self_generated_password: true,
        }),
      })
        .then((res) => {
          setUserCreationSuccess(true);
        })
        .catch(async (response) => {
          const json = await response.json();
          setUserCreationErrors(json.errors);
        })
    );
  };

  const isCreateUserLoading =
    createUserPromise.isPending ||
    emailExistsQuery.loading ||
    updatePromise.isPending;

  const resetValues = () => {
    setUserCreationErrors([]);
    setUserCreationSuccess(false);
    setSelectedUserID('');
  };

  return (
    <Modal onDismiss={onDismiss}>
      <div className="card my-4">
        <div className="card-header">
          <h4 className="mb-0">Select a user to assign the job</h4>
        </div>
        <div
          className="card-body d-flex flex-column"
          style={{ minWidth: '1000px', minHeight: '600px' }}
        >
          <form
            className="d-flex justify-content-between mb-3"
            onSubmit={async (ev) => {
              ev.preventDefault();
              resetValues();
              await listOfCustomers({
                variables: {
                  first: 200,
                  offset: 0,
                  search: search || undefined,
                },
              });
            }}
          >
            <div className="form-row" style={{ width: '100%' }}>
              <div
                style={{
                  display: 'flex',
                  gap: 16,
                  width: '100%',
                  padding: 16,
                  alignItems: 'center',
                }}
              >
                <label>Search for a user by name or email:</label>
                <input
                  type="text"
                  className="form-control"
                  style={{ maxWidth: 250 }}
                  placeholder="Search"
                  value={search}
                  onChange={({ target: { value } }) => setSearch(value)}
                  disabled={updatePromise.isPending}
                />
              </div>
            </div>
          </form>

          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <div
              className="table-responsive"
              style={{
                position: 'relative',
                overflowY: 'auto',
                flex: 'none',
              }}
            >
              {loadingUsers && (
                <div
                  className={[
                    'd-flex flex-columns',
                    'justify-content-center align-items-center',
                  ].join(' ')}
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                  }}
                >
                  <span>Loading...</span>
                </div>
              )}
              {listOfCustomersFound?.length === 0 ? (
                <>
                  <p>User not found, you can create a new user now:</p>
                  <h5>Create new user</h5>
                </>
              ) : (
                <table className="table table-hover table-sm">
                  <thead>
                    <tr>
                      <th>First name</th>
                      <th>Surname</th>
                      <th>Email</th>
                    </tr>
                  </thead>

                  <tbody>
                    {listOfCustomersFound?.map((user) => (
                      <UserRow
                        key={user.id}
                        user={user}
                        selectedUserId={selectedUserId}
                        onUserSelect={({ userId }) => {
                          setSelectedUserID(userId);
                        }}
                      />
                    ))}
                  </tbody>
                </table>
              )}
            </div>

            {listOfCustomersFound?.length === 0 && (
              <form
                onSubmit={createNewUser}
                style={{ display: 'flex', flexDirection: 'column', flex: 1 }}
              >
                <div style={{ flex: 1 }}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <input
                      value={userCreationInfo.emailInput}
                      onChange={({ target: { value } }) => {
                        setIsEmailValid(true);
                        setUserCreationInfo({
                          ...userCreationInfo,
                          emailInput: value,
                        });
                      }}
                      type="text"
                      className="form-control mb-2"
                      placeholder="Email"
                      required
                      disabled={isCreateUserLoading}
                    />

                    <div className="row">
                      <div className="col">
                        <input
                          value={userCreationInfo.firstnameInput}
                          onChange={({ target: { value } }) =>
                            setUserCreationInfo({
                              ...userCreationInfo,
                              firstnameInput: value,
                            })
                          }
                          type="text"
                          className="form-control mb-2"
                          placeholder="First Name"
                          required
                          disabled={isCreateUserLoading}
                        />
                      </div>

                      <div className="col">
                        <input
                          value={userCreationInfo.surnameInput}
                          onChange={({ target: { value } }) =>
                            setUserCreationInfo({
                              ...userCreationInfo,
                              surnameInput: value,
                            })
                          }
                          type="text"
                          className="form-control mb-2"
                          placeholder="Last Name"
                          required
                          disabled={isCreateUserLoading}
                        />
                      </div>
                    </div>

                    <input
                      value={userCreationInfo.mobilePhoneInput}
                      onChange={({ target: { value } }) => {
                        setUserCreationInfo({
                          ...userCreationInfo,
                          mobilePhoneInput: value,
                        });
                      }}
                      type="text"
                      className="form-control mb-2"
                      placeholder="Phone number"
                      required
                      disabled={isCreateUserLoading}
                    />
                  </div>

                  {userCreationErrors && userCreationErrors.length > 0 && (
                    <div className="alert alert-danger">
                      {userCreationErrors.map((error) => (
                        <div key={error}>
                          {error.code === 'EMAIL_NOT_UNIQUE'
                            ? 'Email already exists'
                            : error.code}
                        </div>
                      ))}
                    </div>
                  )}

                  {userCreationSuccess && userCreationSuccess === true && (
                    <div className="alert alert-success">
                      <div>
                        User created successfully, use the search field to find
                        the user and assign the job
                      </div>
                    </div>
                  )}
                </div>

                <div className="d-flex justify-content-between">
                  <button className="btn btn-secondary" onClick={onDismiss}>
                    Close
                  </button>

                  <button
                    disabled={isEmailValid === false || isCreateUserLoading}
                    type="submit"
                    className="btn btn-primary"
                  >
                    Create
                  </button>
                </div>
              </form>
            )}

            {selectedUserId.length > 0 && listOfCustomersFound?.length !== 0 && (
              <form
                onSubmit={(ev) => {
                  ev.preventDefault();
                  if (
                    productJobId == null &&
                    modalParams?.productJobId == null
                  ) {
                    throw new Error('Product job id is missing');
                  }
                  assignUserToProductJob({
                    variables: {
                      aiProductJobId:
                        productJobId ?? modalParams?.productJobId ?? '',
                      userId: selectedUserId,
                    },
                  }).then(() => {
                    refetch != null && refetch();
                    onDismiss();
                  });
                }}
                style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <div className="d-flex justify-content-between">
                  <button className="btn btn-secondary" onClick={onDismiss}>
                    Close
                  </button>

                  <button
                    disabled={
                      updatePromise.isPending === true ||
                      (productJobId == null &&
                        modalParams?.productJobId == null)
                    }
                    type="submit"
                    className="btn btn-primary"
                  >
                    Assign user
                  </button>
                </div>
              </form>
            )}

            {(listOfCustomersFound == null ||
              listOfCustomersFound?.length !== 0) &&
              selectedUserId.length === 0 && (
                <div>
                  <button className="btn btn-secondary" onClick={onDismiss}>
                    Close
                  </button>
                </div>
              )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

const OpenActionModalButton = ({
  location,
  modalName,
  buttonLabel,
  sceneId,
  partnerId,
}: {
  location: RouteComponentProps['location'];
  modalName: string;
  buttonLabel: string;
  sceneId?: string;
  partnerId?: string;
}) => (
  <div className="tw-flex tw-items-center tw-justify-center tw-px-3 tw-rounded tw-bg-transparent tw-text-blue-500 tw-border tw-border-solid  tw-border-blue-500">
    <Link
      to={locationForModal({
        location,
        modal: { modalName: modalName, sceneId: sceneId, partnerId: partnerId },
      })}
      className="tw-no-underline tw-text-blue-500"
      replace
    >
      {buttonLabel}
    </Link>
  </div>
);

const ProductHeader = ({
  session,
  location,
  aiProductJobByIdForAdmin,
  productId,
  externalId,
}: {
  session: { token: string };
  location: RouteComponentProps['location'];
  productId?: string;
  externalId?: string;
  aiProductJobByIdForAdmin: {
    data?: {
      ecommerceProductByServerId?: {
        id: string;
        name?: string;
        customer?: { id: string };
      };
    };
    refetch: () => void;
  };
}) => {
  const customer =
    aiProductJobByIdForAdmin?.data?.ecommerceProductByServerId?.customer;

  const customerInfo = useQuery<{
    iamUserById?: {
      id: string;
      email?: string;
    };
  }>(
    gql`
      query GetUserInfoOnAiProductJobAsAdmin($userId: ID!) {
        iamUserById(id: $userId) {
          id
          email
        }
      }
    `,
    { variables: { userId: customer?.id }, skip: customer == null }
  );

  const [getLoginLink, getLoginLinkMutation] = useMutation<
    {
      aiProductGenerateLoginLink: {
        link: string;
      };
    },
    {
      aiProductJobId: string;
    }
  >(
    gql`
      mutation aiProductGenerateLoginLink($aiProductJobId: ID!) {
        aiProductGenerateLoginLink(input: { aiProductJobId: $aiProductJobId }) {
          link
        }
      }
    `
  );

  return (
    <div className="tw-flex ">
      <div>
        <div className="tw-text-2xl tw-font-semibold">
          Product name :{' '}
          {aiProductJobByIdForAdmin?.data?.ecommerceProductByServerId?.name}
        </div>

        <div className="tw-text-md tw-mt-4">
          <span className="tw-font-bold">Customer: </span>
          <span>{customerInfo.data?.iamUserById?.email}</span>
        </div>
      </div>

      <div className="tw-ml-auto tw-flex tw-gap-3 tw-h-9">
        {checkIfSuperAdmin({ session }) && (
          <button
            className="tw-flex tw-items-center tw-justify-center tw-bg-transparent tw-text-blue-500 tw-border tw-border-solid  tw-border-blue-500 tw-rounded  tw-p-1 tw-px-3 tw-align-middle"
            disabled={getLoginLinkMutation.loading}
            onClick={() => {
              productId != null &&
                getLoginLink({
                  variables: {
                    aiProductJobId: productId,
                  },
                }).then(async (res) => {
                  const loginLink = res?.data?.aiProductGenerateLoginLink?.link;
                  if (loginLink == null || !navigator?.clipboard) {
                    window.alert(
                      'Failed generating login link, please check your permissions'
                    );
                  } else {
                    await navigator.clipboard.writeText(loginLink);
                    window.alert('Login link copied to clipboard');
                  }
                });
            }}
          >
            Generate login link
          </button>
        )}

        <OpenActionModalButton
          location={location}
          modalName={MODAL_NAME_ADD_SCENE}
          buttonLabel="Add scene"
        />

        {externalId != null && (
          <a
            href={`${APARSNIP_URL}/ecommerce/${externalId}`}
            target="_blank"
            rel="noreferrer"
            className="tw-text-blue-500 tw-block  tw-text=center tw-border tw-border-solid  tw-border-blue-500 tw-rounded tw-items-center tw-justify-center tw-px-4 tw-pt-1"
          >
            View product in AParsnip
          </a>
        )}
      </div>
    </div>
  );
};

export const AiProductJobDetails = ({
  match,
  session,
  session: { token },
  history,
  location,
}: {
  match;
  session: {
    token: string;
    uid: string;
    user?: { IAM?: string[] };
  };
  history: RouteComponentProps['history'];
  location: RouteComponentProps['location'];
}) => {
  const {
    params: { jobId },
  } = match;

  const aiProductJobByIdForAdmin = useQuery<{
    ecommerceProductByServerId: {
      id: string;
      customer?: {
        id: string;
      };
      partner?: {
        uid: string;
        name?: string;
      };
      jobs?: {
        edges?: {
          id: string;
          status?: string;
          package?: { id: string; price?: number; displayName?: string };
        }[];
      };
      name?: string;
      externalId?: string;
      slug?: string;
    };
  }>(
    gql`
      query aiProductJobByIdForAdmin($id: ID!) {
        ecommerceProductByServerId(id: $id) {
          id
          partner {
            uid
            name
          }
          jobs {
            edges {
              id
              status
              package {
                id
                price
                displayName
              }
            }
          }
          customer {
            id
            firstName
            lastName
            email
          }
          name
          externalId
          slug
        }
      }
    `,
    {
      variables: {
        id: jobId,
      },
      skip: jobId == null,
    }
  );

  const jobs =
    aiProductJobByIdForAdmin?.data?.ecommerceProductByServerId?.jobs?.edges;

  const initExternalId =
    aiProductJobByIdForAdmin?.data?.ecommerceProductByServerId?.externalId;

  const [extId, setExtId] = React.useState<string | undefined>(initExternalId);

  const initSlug =
    aiProductJobByIdForAdmin?.data?.ecommerceProductByServerId?.slug;

  const [slug, setSlug] = React.useState<string | undefined>(initSlug);

  const initPartnerId =
    aiProductJobByIdForAdmin?.data?.ecommerceProductByServerId?.partner?.uid;

  const [partnerId, setPartnerId] = React.useState<string | undefined>(
    initPartnerId
  );

  React.useEffect(() => {
    setExtId(initExternalId);
  }, [initExternalId]);

  React.useEffect(() => {
    setSlug(initSlug);
  }, [initSlug]);

  React.useEffect(() => {
    setPartnerId(initPartnerId);
  }, [initPartnerId]);

  const isSuperAdmin = checkIfSuperAdmin({ session });

  const [setExternalId, setExternalIdMutation] = useMutation<
    {
      ecommerceProductSetExternalId: {
        success: boolean;
      };
    },
    {
      aiProductJobId: string;
      externalId: string;
    }
  >(
    gql`
      mutation setEcommerceProductExternalId(
        $aiProductJobId: ID!
        $externalId: ID!
      ) {
        ecommerceProductSetExternalId(
          input: { aiProductJobId: $aiProductJobId, externalId: $externalId }
        ) {
          success
        }
      }
    `
  );

  const [setPublicSlug, setPublicSlugMutation] = useMutation<
    {
      ecommerceProductSetExternalId: {
        success: boolean;
      };
    },
    {
      aiProductJobId: string;
      slug: string;
    }
  >(
    gql`
      mutation setEcommerceProductSlug($aiProductJobId: ID!, $slug: String!) {
        ecommerceProductSetPublicSlug(
          input: { aiProductJobId: $aiProductJobId, slug: $slug }
        ) {
          success
        }
      }
    `
  );

  const [setPartner, setPartnerMutation] = useMutation<
    {
      ecommerceProductSetPartner: {
        success: boolean;
      };
    },
    {
      aiProductJobId: string;
      partnerId: string;
    }
  >(
    gql`
      mutation ecommerceProductSetPartner(
        $aiProductJobId: ID!
        $partnerId: ID!
      ) {
        ecommerceProductSetPartner(
          input: { aiProductJobId: $aiProductJobId, partnerId: $partnerId }
        ) {
          success
        }
      }
    `
  );

  return (
    <div className="tw-mb-24">
      <ProductHeader
        session={session}
        location={location}
        aiProductJobByIdForAdmin={aiProductJobByIdForAdmin}
        productId={jobId}
        externalId={initExternalId}
      />

      <div className="tw-grid tw-grid-cols-2 tw-mt-4">
        <div className="tw-text-md  ">
          <label className="tw-font-bold">External Id:</label>

          <input
            onChange={(e) => setExtId(e.target.value)}
            value={extId ?? ''}
            type="text"
            className="tw-w-96"
            readOnly={isSuperAdmin === false}
          />
          {extId !== initExternalId && extId != null && (
            <button
              type="button"
              className="tw-bg-slate-900 tw-text-white tw-ml-2"
              disabled={
                setExternalIdMutation.loading ||
                extId === initExternalId ||
                extId == null
              }
              onClick={() => {
                extId != null &&
                  setExternalId({
                    variables: { aiProductJobId: jobId, externalId: extId },
                  }).then(() => aiProductJobByIdForAdmin.refetch());
              }}
            >
              Save changes
            </button>
          )}
        </div>

        <div className="tw-text-md">
          <label className="tw-font-bold">Public page slug:</label>

          <input
            onChange={(e) => setSlug(e.target.value)}
            value={slug ?? ''}
            type="text"
            className="tw-w-96"
          />
          {slug !== initSlug && slug != null && (
            <button
              type="button"
              className="tw-bg-slate-900 tw-text-white  tw-ml-2"
              disabled={
                setPublicSlugMutation.loading ||
                slug === initSlug ||
                slug == null
              }
              onClick={() => {
                slug != null &&
                  setPublicSlug({
                    variables: { aiProductJobId: jobId, slug: slug },
                  }).then(() => aiProductJobByIdForAdmin.refetch());
              }}
            >
              Save changes
            </button>
          )}
        </div>
      </div>

      <div className="tw-mt-2 tw-flex">
        <Fetcher urlToFetch="/api/v2/partners/summary" session={session}>
          {({
            response: { results: partners = [] } = {},
            loading: partnersLoading,
          }) => (
            <div>
              <label className="tw-font-bold tw-mr-4">Partner: </label>
              <select
                value={partnerId ?? ''}
                onChange={({ target: { value } }) => setPartnerId(value)}
                disabled={initPartnerId != null}
              >
                {partnersLoading ? (
                  <option value="">Loading...</option>
                ) : (
                  <>
                    <option value="">None</option>
                    {partners.map(({ uid, name }) => (
                      <option key={uid} value={uid}>
                        {name}
                      </option>
                    ))}
                  </>
                )}
              </select>
            </div>
          )}
        </Fetcher>
        {partnerId !== initPartnerId && partnerId != null && (
          <button
            type="button"
            className="tw-bg-slate-900 tw-text-white  tw-ml-2"
            disabled={
              setPartnerMutation.loading ||
              partnerId === initPartnerId ||
              partnerId == null
            }
            onClick={() => {
              partnerId != null &&
                setPartner({
                  variables: { aiProductJobId: jobId, partnerId: partnerId },
                }).then(() => aiProductJobByIdForAdmin.refetch());
            }}
          >
            Save changes
          </button>
        )}
      </div>

      {jobs?.map((job, index) => (
        <div
          className="tw-p-4  tw-border tw-border-solid tw-border-gray-300 tw-m-2 tw-rounded tw-bg-white"
          key={job.id}
        >
          <div className="tw-flex">
            Scene {index + 1}
            <div className="tw-ml-auto tw-flex tw-bg-blue-500 tw-text-white tw-rounded tw-px-2">
              {job.status}
            </div>
          </div>

          <div className="tw-flex">
            Package: {job.package?.displayName}
            <div className="tw-w-8" />
            {job?.package?.price === 0 && partnerId != null && (
              <OpenActionModalButton
                location={location}
                modalName={MODAL_NAME_UPGRADE_PACKAGE}
                buttonLabel="Assign package"
                sceneId={job.id}
                partnerId={partnerId}
              />
            )}
          </div>

          <a
            href={`${APARSNIP_URL}/ecommerce-scene/${job.id}`}
            target="_blank"
            rel="noreferrer"
            className="tw-text-blue-500 tw-block  tw-text=center"
          >
            View scene details in AParsnip
          </a>
        </div>
      ))}

      <ModalRoute
        modalName={MODAL_NAME_ASSIGN_USER}
        render={(props) => (
          <AssignJobToUserModal
            productJobId={jobId}
            onDismiss={() =>
              Promise.resolve().then(() =>
                history.push(locationForModal({ location, modal: undefined }))
              )
            }
          />
        )}
      />

      <ModalRoute
        modalName={MODAL_NAME_ADD_SCENE}
        render={(props) => (
          <AddSceneToProduct
            productName={
              aiProductJobByIdForAdmin?.data?.ecommerceProductByServerId
                ?.name ?? ''
            }
            productJobId={initExternalId}
            onDismiss={() =>
              Promise.resolve()
                .then(() =>
                  history.push(locationForModal({ location, modal: undefined }))
                )
                .then(() => aiProductJobByIdForAdmin.refetch())
            }
          />
        )}
      />

      {
        <ModalRoute
          modalName={MODAL_NAME_UPGRADE_PACKAGE}
          render={(props) => (
            <UpgradeScenePackage
              modalParams={props.modalParams}
              onDismiss={() =>
                Promise.resolve()
                  .then(() =>
                    history.push(
                      locationForModal({ location, modal: undefined })
                    )
                  )
                  .then(() => aiProductJobByIdForAdmin.refetch())
              }
            />
          )}
        />
      }
    </div>
  );
};
