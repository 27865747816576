import moment from 'moment';
import React, { useState } from 'react';
import { LoadingSpinnerCentered } from '../../../../../components/LoadingSpinner';
import { Modal } from '../../../../../components/Modal';
import { usePromise } from '../../../../../components/usePromise';
import { apiFetch, formatMoneyCents } from '../../../../../utils';

export function RefundTransactionModal(props) {
  const [isPartial, setIsPartial] = useState(false);
  const [refundAmount, setRefundAmount] = useState(null);

  const {
    onDismiss,
    booking,
    session: { token },
    onReload,
    currentTransaction,
  } = props;

  const {
    setPromise: setRefundPromise,
    isPending: savingRefundPending,
    error: refundError,
  } = usePromise();

  return (
    <Modal onDismiss={onDismiss}>
      <div className="card my-4">
        <div className="card-header">
          <h4 className="mb-0">Refund Transaction</h4>
        </div>

        <div
          className="card-body d-flex flex-column"
          style={{ width: '1000px', minHeight: '300px' }}
        >
          <div className="flex-1" style={{ position: 'relative' }}>
            <div className="alert alert-warning">
              Warning: Refunds require manager approval. Do not proceed without
              approval.
            </div>
            {currentTransaction ? (
              <div className="card mb-3">
                <div className="card-body">
                  <h4>Transaction Details</h4>
                  <div>
                    {formatMoneyCents(currentTransaction.amount, {
                      currency: booking.currency,
                    })}
                  </div>
                  <div>
                    <span>Refund amount:</span>
                    <div className="form-check form-check-inline ml-2">
                      <label className="form-check-label">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="refundAmountOptions"
                          checked={!isPartial}
                          onChange={() => {
                            setIsPartial(false);
                            setRefundAmount(null);
                          }}
                        />
                        Full
                      </label>
                    </div>

                    <div className="form-check form-check-inline">
                      <label className="form-check-label">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="refundAmountOptions"
                          checked={isPartial}
                          onChange={() => {
                            setIsPartial(true);
                            setRefundAmount(0);
                          }}
                        />
                        Partial
                      </label>
                    </div>

                    <span>{'$ '}</span>
                    <input
                      type="number"
                      min="1"
                      step="any"
                      className="form-control d-inline-block"
                      placeholder="Amount"
                      style={{ width: 200 }}
                      disabled={!isPartial}
                      value={refundAmount == null ? '' : refundAmount}
                      onFocus={(ev) => ev.target.select()}
                      onChange={({ target: { value } }) =>
                        setRefundAmount(value)
                      }
                      onBlur={({ target: { value } }) => {
                        const parsedValue = parseFloat(value) || 0;
                        if (parsedValue < 0) {
                          alert('Refund amount can not be less than 0.');
                          setRefundAmount(0);
                          return;
                        }

                        setRefundAmount(value || 0);
                      }}
                    />
                  </div>

                  {currentTransaction.refunded_transactions.length > 0 && (
                    <React.Fragment>
                      <h5>Transaction Refunds</h5>

                      <table className="table table-hover mb-0">
                        <thead>
                          <tr>
                            <th className="text-muted text-truncate">
                              Transaction Id
                            </th>

                            <th className="text-muted text-truncate">Amount</th>
                            <th className="text-muted text-truncate">
                              Creation Date
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {currentTransaction.refunded_transactions &&
                            currentTransaction.refunded_transactions.map(
                              (refund) => (
                                <tr key={refund.id}>
                                  <td>{refund.id}</td>

                                  <td>
                                    {formatMoneyCents(refund.amount, {
                                      currency: booking.currency,
                                    })}
                                  </td>
                                  <td>
                                    {refund.created_at &&
                                      moment(refund.created_at).format(
                                        'ddd, MMM Do YYYY, h:mm:ss a zz'
                                      )}
                                  </td>
                                </tr>
                              )
                            )}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td>Total</td>
                            <td>
                              {formatMoneyCents(
                                currentTransaction.refunded_total,
                                {
                                  currency: booking.currency,
                                }
                              )}
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </React.Fragment>
                  )}

                  {refundError && (
                    <div className="alert alert-danger">
                      There was an error with the refund
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <LoadingSpinnerCentered />
            )}
          </div>

          <div className="d-flex justify-content-between mt-3">
            <button className="btn btn-secondary mr-2" onClick={onDismiss}>
              Close
            </button>
            <button
              className="btn btn-primary"
              disabled={
                savingRefundPending ||
                (currentTransaction &&
                  currentTransaction.amount ===
                    currentTransaction.refunded_total)
              }
              onClick={() =>
                setRefundPromise(
                  apiFetch(
                    `/api/v2/admin/bookings/${props.booking.uid}/commit-transaction-refund`,
                    {
                      method: 'POST',
                      body: JSON.stringify({
                        transaction_id: currentTransaction.id,
                        refund_amount: refundAmount ? refundAmount * 100 : null,
                        is_partial: isPartial,
                      }),
                      token,
                    }
                  ).then(() => onReload())
                )
              }
            >
              {savingRefundPending ? 'Refunding...' : 'Refund'}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
