import React from 'react';
import { Route, Switch } from 'react-router-dom';

import BaseLayout from '../../components/BaseLayout';
import { BaseStyle } from '../../components/BaseStyle';
import { QualityControlView } from './quality-control-view';
import { QualityControlDetails } from './details';
import { QualityControlGallery } from './quality-control-gallery';

export const QualityControlViewRoute = ({
  session,
  match,
  match: { path: basePath },
  history,
}) => (
  <BaseLayout session={session} match={match}>
    <BaseStyle className="d-flex flex-column flex-1">
      <Switch>
        <Route
          exact
          path={basePath}
          render={(props) => (
            <QualityControlView
              {...props}
              session={session}
              history={history}
            />
          )}
        />
        <Route
          path={`${basePath}/:qualityControlJobId/details`}
          render={(props) => (
            <QualityControlDetails {...props} session={session} />
          )}
        />
        <Route
          path={`${basePath}/:qualityControlJobId/gallery`}
          render={(props) => <QualityControlGallery {...props} />}
        />
      </Switch>
    </BaseStyle>
  </BaseLayout>
);
