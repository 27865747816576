import moment from 'moment-timezone';

const COMPLETION_TEAM_DASH_TIMEZONE = 'America/Los_Angeles';

export const COMPLETION_TEAM_DASH_FILTERS = [
  {
    id: 'today',
    label: 'Today',
    fromDate: moment
      .tz(COMPLETION_TEAM_DASH_TIMEZONE)
      .startOf('day')
      .toISOString(),
    toDate: moment.tz(COMPLETION_TEAM_DASH_TIMEZONE).toISOString(),
  },
  {
    id: 'yesterday',
    label: 'Yesterday',
    fromDate: moment
      .tz(COMPLETION_TEAM_DASH_TIMEZONE)
      .subtract(1, 'day')
      .startOf('day')
      .toISOString(),
    toDate: moment
      .tz(COMPLETION_TEAM_DASH_TIMEZONE)
      .startOf('day')
      .toISOString(),
  },

  {
    id: 'this-week',
    label: 'This week',
    fromDate: moment
      .tz(COMPLETION_TEAM_DASH_TIMEZONE)
      .startOf('isoWeek')
      .toISOString(),
    toDate: moment.tz(COMPLETION_TEAM_DASH_TIMEZONE).toISOString(),
  },
  {
    id: 'last-week',
    label: 'Last week',
    fromDate: moment
      .tz(COMPLETION_TEAM_DASH_TIMEZONE)
      .subtract(1, 'week')
      .startOf('isoWeek')
      .toISOString(),
    toDate: moment
      .tz(COMPLETION_TEAM_DASH_TIMEZONE)
      .startOf('isoWeek')
      .toISOString(),
  },

  {
    id: 'before-last-week',
    label: 'Week before last',
    fromDate: moment
      .tz(COMPLETION_TEAM_DASH_TIMEZONE)
      .subtract(1 + 1, 'week')
      .startOf('isoWeek')
      .toISOString(),
    toDate: moment
      .tz(COMPLETION_TEAM_DASH_TIMEZONE)
      .subtract(0 + 1, 'week')
      .startOf('isoWeek')
      .toISOString(),
  },

  {
    id: 'three-weeks-ago',
    label: 'Three weeks ago',
    fromDate: moment
      .tz(COMPLETION_TEAM_DASH_TIMEZONE)
      .subtract(1 + 2, 'week')
      .startOf('isoWeek')
      .toISOString(),
    toDate: moment
      .tz(COMPLETION_TEAM_DASH_TIMEZONE)
      .subtract(0 + 2, 'week')
      .startOf('isoWeek')
      .toISOString(),
  },

  {
    id: 'four-weeks-ago',
    label: 'Four weeks ago',
    fromDate: moment
      .tz(COMPLETION_TEAM_DASH_TIMEZONE)
      .subtract(1 + 3, 'week')
      .startOf('isoWeek')
      .toISOString(),
    toDate: moment
      .tz(COMPLETION_TEAM_DASH_TIMEZONE)
      .subtract(0 + 3, 'week')
      .startOf('isoWeek')
      .toISOString(),
  },

  {
    id: 'this-month',
    label: 'This month',
    fromDate: moment
      .tz(COMPLETION_TEAM_DASH_TIMEZONE)
      .startOf('month')
      .toISOString(),
    toDate: moment.tz(COMPLETION_TEAM_DASH_TIMEZONE).toISOString(),
    showQuota: true,
  },
  {
    id: 'last-month',
    label: 'Last month',
    fromDate: moment
      .tz(COMPLETION_TEAM_DASH_TIMEZONE)
      .startOf('month')
      .subtract(1, 'month')
      .toISOString(),
    toDate: moment
      .tz(COMPLETION_TEAM_DASH_TIMEZONE)
      .startOf('month')
      .toISOString(),
    showQuota: true,
  },
  {
    id: 'month-before-last',
    label: 'Month before last',
    fromDate: moment
      .tz(COMPLETION_TEAM_DASH_TIMEZONE)
      .startOf('month')
      .subtract(2, 'month')
      .toISOString(),
    toDate: moment
      .tz(COMPLETION_TEAM_DASH_TIMEZONE)
      .startOf('month')
      .subtract(1, 'month')
      .toISOString(),
    showQuota: true,
  },

  {
    id: 'this-year',
    label: 'This year',
    fromDate: moment
      .tz(COMPLETION_TEAM_DASH_TIMEZONE)
      .startOf('year')
      .toISOString(),
    toDate: moment.tz(COMPLETION_TEAM_DASH_TIMEZONE).toISOString(),
  },
  {
    id: 'last-year',
    label: 'Last year',
    fromDate: moment
      .tz(COMPLETION_TEAM_DASH_TIMEZONE)
      .subtract(1, 'year')
      .startOf('year')
      .toISOString(),
    toDate: moment
      .tz(COMPLETION_TEAM_DASH_TIMEZONE)
      .startOf('year')
      .toISOString(),
  },

  {
    id: 'last-7-days',
    label: 'Last 7 days',
    fromDate: moment
      .tz(COMPLETION_TEAM_DASH_TIMEZONE)
      .subtract(7, 'days')
      .toISOString(),
    toDate: moment.tz(COMPLETION_TEAM_DASH_TIMEZONE).toISOString(),
  },
  {
    id: 'last-30-days',
    label: 'Last 30 days',
    fromDate: moment
      .tz(COMPLETION_TEAM_DASH_TIMEZONE)
      .subtract(30, 'days')
      .toISOString(),
    toDate: moment.tz(COMPLETION_TEAM_DASH_TIMEZONE).toISOString(),
  },
];
