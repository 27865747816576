import React from 'react';
import { COLOR_PALLETTE } from '../constants';

export function LinkButton({
  active,
  label,
  onClick,
  isFirst,
  isLast,
}: {
  active?: boolean;
  label: string;
  isFirst?: boolean;
  isLast?: boolean;
  onClick: () => void;
}) {
  return (
    <>
      <button className={active ? 'active-button' : 'button'} onClick={onClick}>
        {label}
      </button>

      <style jsx>{`
        .button {
          padding: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid ${COLOR_PALLETTE.GRAY_2};
          color: ${COLOR_PALLETTE.GRAY_4};
          font-weight: bold;
          background-color: white;
          text-decoration: none;
          outline: none;
          font-size: 16px;
          border-radius: ${isFirst
            ? '2px 0px 0px 2px'
            : isLast
            ? '0px 2px 2px 0px'
            : '0px'};
        }

        .active-button {
          padding: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid ${COLOR_PALLETTE.PRIMARY};
          color: ${COLOR_PALLETTE.PRIMARY};
          font-weight: bold;
          background-color: white;
          text-decoration: none;
          outline: none;
          border-radius: ${isFirst
            ? '2px 0px 0px 2px'
            : isLast
            ? '0px 2px 2px 0px'
            : '0px'};
        }
      `}</style>
    </>
  );
}
