import * as React from 'react';
import qs from 'query-string';
import Dropzone from 'react-dropzone';

import {
  IUpload,
  ProgressIndicator,
  UploadImagePreview,
} from '../../../components/useUploadManager';
import { apiFetch } from '../../../utils';
import { SimpleLine } from '../components/SimpleLine';
import { API_URL } from '../../../config';
import { Tooltip } from '../../../components/Tooltip';
import { gql, useMutation } from '../../../components/ApolloClient';
import { deleteIconPath } from '../components/img';
import { useEditingJobQuery, useEditingUploadManager } from './utils';
import { RawImagePlaceholder } from '../../../components/RawImagesPlaceholder';

const SPECIFIC_SERVICES = [
  'virtualStagingRoom',
  'objectRemovalNotes',
  'faceRetouchingNotes',
  'spotRemovalNotes',
  'productRetouchingNotes',
  'brandRemovalNotes',
];

function ImageRow<T extends { itemId: string }>({
  item,
  session: { token },
  editingJobId,
  reload,
  editingUploadManager,
  setImageToRefund,
  isSelectedToRefund,
}: {
  item: {
    id: string;
    services?: {
      edges: {
        details?: {
          virtualStagingRoom?: string;
          objectRemovalNotes?: string;
          spotRemovalNotes?: string;
          faceRetouchingNotes?: string;
          productRetouchingNotes?: string;
          brandRemovalNotes?: string;
        };
        editingJobService: {
          id: string;
          slug?: string;
          displayName?: string;
        };
      }[];
    };
    finalImage?: {
      id: string;
      medium?: {
        url?: string;
      };
    };
    refunded?: Date;
    originalImage?: {
      id: string;
      fileName?: string;
      medium?: {
        url?: string;
      };
    };
  } & (
    | { upload: IUpload<T> }
    | {
        originalImage?: {
          id: string;
          medium?: {
            url?: string;
          };
        };
      }
  );
  session: { token: string };
  editingJobId: string;
  reload;
  editingUploadManager: ReturnType<typeof useEditingUploadManager>;
  setImageToRefund: () => void;
  isSelectedToRefund: boolean;
}) {
  const [saveFinals, saveFinalsMutation] = useMutation<
    any,
    {
      editingJobId: string;
      originalMediaId?: string;
      finalMediaId: string | null;
    }
  >(
    gql`
      mutation EditingJobSaveImagesUpdateOnImagesTable(
        $editingJobId: ID!
        $originalMediaId: ID!
        $finalMediaId: ID
      ) {
        editingJobUpdateItem(
          input: {
            editingJobId: $editingJobId
            originalMediaId: $originalMediaId
            finalMediaId: $finalMediaId
          }
        ) {
          item {
            id
          }
        }
      }
    `
  );
  const { uploadsList, addFiles } = editingUploadManager;

  const currentItemUpload = uploadsList.find(
    (i) => (i.customData as T)?.itemId === item.id
  );

  return (
    <div
      style={{
        display: 'grid',
        justifyContent: 'space-between',
        gridTemplateColumns: '.3fr 1fr 1fr 1fr 1fr',
      }}
    >
      <div
        className="form-check form-check-inline mb-3"
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <input
          style={item.refunded != null ? {} : { cursor: 'pointer' }}
          className="form-check-input"
          type="checkbox"
          checked={!!item.refunded || isSelectedToRefund}
          onChange={setImageToRefund}
          disabled={item.refunded != null}
        />
      </div>
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          flexBasis: 0,
          flexGrow: 10,
          position: 'relative',
        }}
      >
        {'upload' in item ? (
          <div style={{ position: 'relative', width: 224, height: 136 }}>
            <UploadImagePreview
              file={item.upload.file}
              alt="preview"
              width="224"
              height="136"
              style={{ objectFit: 'cover', opacity: 0.3 }}
            />

            {item.upload.sizeUploaded != null && item.upload.fileSize != null && (
              <div
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  top: '50%',
                }}
              >
                <ProgressIndicator
                  progress={item.upload.sizeUploaded / item.upload.fileSize}
                />
              </div>
            )}
          </div>
        ) : item?.originalImage?.medium?.url != null ? (
          <img
            loading="lazy"
            alt="originalImage"
            src={item?.originalImage?.medium?.url}
            width="224"
            height="136"
            style={{ objectFit: 'cover' }}
          />
        ) : (
          <RawImagePlaceholder
            iconSize="80px"
            containerWidth="224px"
            border="1px dashed #C1C8D4"
          />
        )}
      </div>

      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          flexBasis: 0,
          flexGrow: 10,
          position: 'relative',
        }}
      >
        {item.finalImage == null ? (
          currentItemUpload != null ? (
            <div style={{ position: 'relative', width: 224, height: 136 }}>
              <UploadImagePreview
                file={currentItemUpload.file}
                alt="preview"
                width="224"
                height="136"
                style={{ objectFit: 'cover' }}
              />

              {currentItemUpload.sizeUploaded != null &&
                currentItemUpload.fileSize != null && (
                  <div
                    style={{
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      top: '50%',
                    }}
                  >
                    <ProgressIndicator
                      progress={
                        currentItemUpload.sizeUploaded /
                        currentItemUpload.fileSize
                      }
                    />
                  </div>
                )}
            </div>
          ) : (
            'originalImage' in item && (
              <Dropzone
                disabled={saveFinalsMutation.loading || !!item.refunded}
                style={{
                  width: 224,
                  height: 136,
                  borderWidth: 1,
                  borderColor: '#C1C8D4',
                  borderStyle: 'dashed',
                  borderRadius: 2,
                  backgroundColor: '#F6F9FF',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                accept="image/jpeg"
                multiple={false}
                onDrop={(acceptedFiles) =>
                  addFiles({
                    filesWithCustomData: [
                      {
                        file: acceptedFiles[0],
                        customData: { itemId: item.id },
                      },
                    ],

                    onUploadReadyCustom: ({
                      fileName,
                      mediaUid,
                      remove,
                      customData,
                    }) =>
                      apiFetch('/api/v2/media/create-from-temp', {
                        token,
                        method: 'POST',
                        body: JSON.stringify({
                          tempMediaUid: mediaUid,
                          fileName,
                        }),
                      }).then(() =>
                        Promise.resolve()
                          .then(() =>
                            customData == null
                              ? null
                              : saveFinals({
                                  variables: {
                                    editingJobId,
                                    originalMediaId: item.originalImage?.id,
                                    finalMediaId: mediaUid ?? '',
                                  },
                                })
                          )
                          .then(() => reload())
                          .then(() => remove())
                      ),
                  })
                }
              >
                <p
                  style={{
                    width: 176,
                    fontWeight: 400,
                    fontSize: 12,
                    lineHeight: '16px',
                    letterSpacing: '2%',
                    color: '#454F5C',
                    textAlign: 'center',
                    marginBottom: 0,
                  }}
                >
                  Drag and drop your image here, or{' '}
                  <button
                    style={{
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      background: 'transparent',
                      border: 'none',
                      fontWeight: 400,
                      fontSize: 12,
                      lineHeight: '16px',
                      letterSpacing: '2%',
                      color: '#454F5C',
                      textAlign: 'center',
                    }}
                  >
                    browse
                  </button>
                </p>
              </Dropzone>
            )
          )
        ) : (
          <>
            <div className="final-image-container">
              <img
                className="final-image"
                loading="lazy"
                alt="final"
                src={item?.finalImage?.medium?.url}
              />

              <div className="overlay">
                <div className="overlay-content">
                  <button
                    className="delete-button"
                    onClick={() => {
                      if (window.confirm(`Do you want to remove this image?`)) {
                        saveFinals({
                          variables: {
                            editingJobId,
                            originalMediaId: item?.originalImage?.id,
                            finalMediaId: null,
                          },
                        }).then(() => reload());
                      }
                    }}
                  >
                    <img
                      src={deleteIconPath}
                      alt="action"
                      style={{ width: 18, height: 18 }}
                    />
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div
        style={{
          color: '#454F5C',
          fontSize: 14,
          maxWidth: 250,
          textAlign: 'center',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        {item.originalImage?.fileName}
      </div>
      <div style={{ textAlign: 'center', color: '#454F5C' }}>
        {SPECIFIC_SERVICES.map((specService) => {
          return (
            <div key={specService}>
              {item.services?.edges?.map((service) =>
                service?.details?.[specService] != null ? (
                  <div key={service?.details?.[specService]}>
                    {service?.editingJobService?.displayName}
                    <br></br>
                    {service?.details?.[specService]}
                  </div>
                ) : null
              )}
            </div>
          );
        })}
      </div>

      <style jsx>{`
        .final-image-container {
          position: relative;
          height: 136px;
          width: 224px;
        }

        .final-image-container:hover .overlay {
          opacity: 1;
        }

        .final-image {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .overlay {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          height: 100%;
          width: 100%;
          opacity: 0;
          transition: 0.5s ease;
          background: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
        }

        .overlay-content {
          display: flex;
          justify-content: flex-end;
        }

        .overlay-content .delete-button {
          background: none;
          border: none;
        }

        .overlay-content .delete-button:focus {
          outline: none;
        }
      `}</style>
    </div>
  );
}

export function EditingJobsImagesTableSection({
  editingJobQuery,
  session,
  reload,
  editingUploadManager,
  imagesToRefund,
  setImagesToRefund,
}: {
  editingJobQuery: ReturnType<typeof useEditingJobQuery>;
  editingUploadManager: ReturnType<typeof useEditingUploadManager>;
  imagesToRefund: string[];
  setImagesToRefund: (imagesIds: string[]) => void;
  session: { token: string };
  reload;
}) {
  const editingJob = editingJobQuery.data?.editingJob;

  const [downloadWithRefunds, setDownloadWithRefunds] = React.useState<boolean>(
    false
  );

  const editedImagesQty = editingJob?.items.edges.filter(
    (image) => image?.finalImage != null
  )?.length;

  const isImageSelectedToRefund = (originalImageId: string | undefined) =>
    imagesToRefund.some((imageId) => imageId === originalImageId);

  const setImageToRefund = (originalImageId: string) => {
    if (isImageSelectedToRefund(originalImageId)) {
      setImagesToRefund([
        ...imagesToRefund.filter(
          (imageToRefundId) => imageToRefundId !== originalImageId
        ),
      ]);
    } else {
      setImagesToRefund([...imagesToRefund, originalImageId]);
    }
  };

  const mergedItems = React.useMemo(
    () => [
      ...(editingJob?.items?.edges ?? []).map((i) => ({
        id: i.id,
        originalImage: i.originalImage,
        finalImage: i.finalImage,
        services: i.services,
        refunded: i.refunded,
      })),
    ],
    [editingJob?.items]
  );

  return editingJob == null ? null : (
    <>
      <SimpleLine />

      <div className="d-flex align-items-center justify-content-between">
        <h3
          style={{
            fontWeight: 700,
            fontSize: 26,
            lineHeight: '33.8px',
            color: '#454F5C',
          }}
        >
          Gallery submission
        </h3>
        <div>
          <button
            className="btn btn-dark"
            onClick={() =>
              window.open(
                `${API_URL}/api/v2/editing-jobs/${
                  editingJob.id
                }/download-gallery?${qs.stringify({
                  access_token: session.token,
                  only_originals_enabled: true,
                  include_refunded: downloadWithRefunds,
                })}`
              )
            }
          >
            Download original images
          </button>
          <div className="ml-4">
            <input
              className="form-check-input"
              type="checkbox"
              checked={downloadWithRefunds}
              onChange={() => setDownloadWithRefunds((prev) => !prev)}
            />
            <span>Include refunded</span>
          </div>
        </div>
      </div>

      <div className="pt-2" />

      <div className="images-table-section">
        <div className="images-table-row">
          <div className="images-table-header" style={{ flexGrow: 3 }}>
            <div className="images-table-header-container">
              <h5 className="images-table-header-text">{'Refund'}</h5>
            </div>
            <div className="flex-100">
              <SimpleLine />
            </div>
          </div>
          <div className="images-table-header">
            <div className="images-table-header-container">
              <h5 className="images-table-header-text">
                {`Original images (${editingJob?.items?.edges?.length ?? '0'})`}
              </h5>
            </div>
            <div className="flex-100">
              <SimpleLine />
            </div>
          </div>

          <div className="images-table-header">
            <div className="images-table-header-container">
              <h5 className="images-table-header-text">
                {`Edited images (${editedImagesQty ?? '0'})`}
              </h5>{' '}
              <Tooltip
                label={`We use the file names to match the
                   originals and the finals. In order for the
                   images to be paired, do not change the
                    file names after downloading them.`}
                style={{
                  background: 'hsla(0, 0%, 0%, 0.75)',
                  color: 'white',
                  border: 'none',
                  borderRadius: '4px',
                  padding: '0.5em 1em',
                  zIndex: '999999999',
                }}
              >
                <i aria-hidden="true" className="fa fa-info-circle ml-2" />
              </Tooltip>
            </div>

            <div className="flex-100">
              <SimpleLine />
            </div>
          </div>
          <div className="images-table-header">
            <div className="images-table-header-container">
              <h5 className="images-table-header-text">{'File name'}</h5>
            </div>
            <div className="flex-100">
              <SimpleLine />
            </div>
          </div>
          <div className="images-table-header">
            <div className="images-table-header-container">
              <h5 className="images-table-header-text">
                {'Specific services'}
              </h5>
            </div>
            <div className="flex-100">
              <SimpleLine />
            </div>
          </div>
        </div>

        {mergedItems.map((item) => (
          <div key={item.id}>
            <ImageRow
              editingJobId={editingJob.id}
              reload={reload}
              item={item}
              session={session}
              editingUploadManager={editingUploadManager}
              isSelectedToRefund={isImageSelectedToRefund(
                item.originalImage?.id
              )}
              setImageToRefund={() =>
                item.originalImage?.id &&
                setImageToRefund(item.originalImage.id)
              }
            />

            <div style={{ height: 10 }} />
            <SimpleLine />
            <div style={{ height: 10 }} />
          </div>
        ))}
      </div>

      <style jsx>{`
        .images-table-section {
          background-color: white;
          flex: 1;
          display: flex;
          flex-direction: column;
          padding: 20px;
        }

        .images-table-section .images-table-row {
          flex: 1;
          display: flex;
          justify-content: space-between;
          flex-direction: row;
        }

        .images-table-section .images-table-header {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          flex-basis: 0;
          flex-grow: 10;
        }

        .images-table-section .images-table-header-container {
          display: flex;
        }

        .images-table-section
          .images-table-header-container
          .images-table-header-text {
          font-size: 16px;
          line-height: 16px;
          font-weight: bold;
          color: #71767e;
        }

        .images-table-section .flex-100 {
          display: flex;
          flex: 1;
          width: 100%;
        }
      `}</style>
    </>
  );
}
