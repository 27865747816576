import * as React from 'react';
import RawIcon from './img/raw-icon.svg';

export const RawImagePlaceholder = ({
  imageName,
  iconSize = '50%',
  truncateWidth,
  nameSize = 16,
  bgColor,
  containerWidth,
  border,
}: {
  imageName?: string;
  iconSize?: string;
  truncateWidth?: string;
  nameSize?: number;
  bgColor?: string;
  containerWidth?: string;
  border?: string;
}) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: bgColor ?? '#E8EDF5',
      border: border ?? 'none',

      width: containerWidth ?? 'auto',
      height: '100%',
      position: 'relative',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    }}
  >
    <img
      style={{
        height: iconSize,
      }}
      src={RawIcon}
      alt="raw icon"
    />

    <div style={{ height: 10 }} />

    {imageName != null ? (
      <p
        className={truncateWidth != null ? 'truncate' : ''}
        style={{
          color: '#454F5C',
          maxWidth: '80%',
          textAlign: 'center',
          fontSize: nameSize,
          margin: 0,
        }}
      >
        {imageName}
      </p>
    ) : null}

    <style jsx>{`
      .truncate {
        width: ${truncateWidth};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    `}</style>
  </div>
);
