import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Promiser } from '../../../../../components/Promiser';
import { locationForModal } from '../ModalRoute';
import { Modal } from '../../../../../components/Modal';
import { MODAL_NAME_CUSTOMER_SELECTION } from './utils';
import { apiFetch } from '../../../../../utils';

const Input = ({
  placeholder,
  label = placeholder,
  extraClassName = '',
  ...rest
}) => (
  <div className={'form-group ' + extraClassName}>
    {label && (
      <label>
        <small className="text-secondary">{label}</small>
      </label>
    )}
    <input className="form-control" placeholder={placeholder} {...rest} />
  </div>
);

const makeRandomString = () => Math.random().toString(36).substring(4);

export class CustomerCreationModal extends Component {
  state = {
    firstname: '',
    surname: '',
    email: '',
    mobilephone: '',

    customerCreationPromise: null,
  };

  render() {
    const {
      onDismiss,
      session: { token },
      location,
      onCustomerChange,
    } = this.props;

    return (
      <Modal onDismiss={onDismiss}>
        <div className="card my-4">
          <div className="card-header">
            <h4 className="mb-0">Customer Creation</h4>
          </div>

          <Promiser promise={this.state.customerCreationPromise}>
            {({ isPending: customerCreationIsPending }) => (
              <form
                className="card-body d-flex flex-column"
                style={{ minWidth: '800px' }}
                onSubmit={(ev) => {
                  ev.preventDefault();

                  this.setState({
                    customerCreationPromise: apiFetch(`/api/v2/customers`, {
                      method: 'POST',
                      token,

                      body: JSON.stringify({
                        firstname: this.state.firstname,
                        surname: this.state.surname,
                        email: this.state.email,
                        mobilephone: this.state.mobilephone,

                        password: makeRandomString(),
                        self_generated_password: true,
                      }),
                    }).then(
                      ({ uid: customer_uid }) => {
                        onCustomerChange({ customer_uid });
                        onDismiss();
                      },
                      (err) =>
                        err.json().then(
                          (err) => {
                            const errorMessage = err.errors
                              ? err.errors.length === 1 &&
                                err.errors[0].code === 'EMAIL_NOT_UNIQUE'
                                ? 'A customer with this email already exist.'
                                : err.errors.join('; ')
                              : err.toString();
                            alert(errorMessage);
                          },
                          () => {
                            alert('Error. Please reload the page.');
                          }
                        )
                    ),
                  });
                }}
              >
                <div className="d-flex justify-content-between mb-3">
                  <Link
                    to={locationForModal({
                      location,
                      modal: {
                        modalName: MODAL_NAME_CUSTOMER_SELECTION,
                      },
                    })}
                    className="btn btn-primary"
                  >
                    Search customers
                  </Link>
                </div>

                <div className="flex-1">
                  <div className="row">
                    <Input
                      extraClassName="col"
                      placeholder="First name"
                      value={this.state.firstname}
                      disabled={customerCreationIsPending}
                      onChange={({ target: { value } }) =>
                        this.setState({ firstname: value })
                      }
                    />
                    <Input
                      extraClassName="col"
                      placeholder="Surname"
                      value={this.state.surname}
                      disabled={customerCreationIsPending}
                      onChange={({ target: { value } }) =>
                        this.setState({ surname: value })
                      }
                    />
                  </div>

                  <div className="row">
                    <Input
                      extraClassName="col"
                      placeholder="Email"
                      type="email"
                      disabled={customerCreationIsPending}
                      value={this.state.email}
                      onChange={({ target: { value } }) =>
                        this.setState({ email: value })
                      }
                    />
                    <Input
                      extraClassName="col"
                      placeholder="Phone"
                      type="tel"
                      disabled={customerCreationIsPending}
                      value={this.state.mobilephone}
                      onChange={({ target: { value } }) =>
                        this.setState({ mobilephone: value })
                      }
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-between">
                  <button className="btn btn-secondary" onClick={onDismiss}>
                    Close
                  </button>

                  <button
                    className="btn btn-primary"
                    disabled={
                      !(
                        this.state.firstname &&
                        this.state.surname &&
                        this.state.email &&
                        this.state.mobilephone
                      ) || customerCreationIsPending
                    }
                    type="submit"
                  >
                    {customerCreationIsPending
                      ? 'Creating...'
                      : 'Create and select'}
                  </button>
                </div>
              </form>
            )}
          </Promiser>
        </div>
      </Modal>
    );
  }
}
