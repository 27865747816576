import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import BaseLayout from '../../components/BaseLayout';
import { BaseStyle } from '../../components/BaseStyle';

import { BookingRoute } from './edit';
import { BookingsListRoute } from './list';
import { GalleryRoute } from './gallery';
import { GalleryUploadRoute } from './gallery-upload';
import { ChatRoute } from './chat';
import { checkIfCanAccess } from '../../utils';
import { RawPhotoPreviewRoute } from './raw-photo-preview';

export const BookingsRoute = ({
  session,
  match,
  match: { path: basePath },
}) => (
  <BaseLayout session={session} match={match}>
    <BaseStyle className="d-flex flex-column flex-1">
      <Switch>
        {checkIfCanAccess(session, 'bookings') && (
          <Route
            exact
            path={basePath}
            render={(props) => (
              <BookingsListRoute
                {...props}
                session={session}
                rootBasePath={basePath}
              />
            )}
          />
        )}

        {(checkIfCanAccess(session, 'bookings') ||
          checkIfCanAccess(session, 'bookingGalleries')) && (
          <Route
            path={`${basePath}/:bookingId/gallery`}
            render={(props) => (
              <GalleryRoute
                {...props}
                session={session}
                rootBasePath={basePath}
              />
            )}
          />
        )}

        {(checkIfCanAccess(session, 'bookings') ||
          checkIfCanAccess(session, 'editingTaskQueue') ||
          checkIfCanAccess(session, 'contractorEditingTaskQueue')) && (
          <Route
            path={`${basePath}/:bookingId/gallery-upload`}
            render={(props) => (
              <GalleryUploadRoute
                {...props}
                session={session}
                rootBasePath={basePath}
              />
            )}
          />
        )}

        {(checkIfCanAccess(session, 'bookings') ||
          checkIfCanAccess(session, 'editingTaskQueue') ||
          checkIfCanAccess(session, 'contractorEditingTaskQueue')) && (
          <Route
            exact
            path={`${match.path}/:bookingId/raw-photo-preview`}
            render={(props) => (
              <RawPhotoPreviewRoute {...props} session={session} />
            )}
          />
        )}

        {checkIfCanAccess(session, 'bookings') && (
          <Route
            path={`${basePath}/:bookingId/chat`}
            render={(props) => (
              <ChatRoute {...props} session={session} rootBasePath={basePath} />
            )}
          />
        )}

        {checkIfCanAccess(session, 'bookings') && (
          <Route
            path={`${basePath}/:bookingId`}
            render={(props) => (
              <BookingRoute
                {...props}
                session={session}
                rootBasePath={basePath}
              />
            )}
          />
        )}

        <Route
          render={({ location }) => (
            <Redirect to={{ ...location, pathname: basePath }} />
          )}
        />
      </Switch>
    </BaseStyle>
  </BaseLayout>
);
