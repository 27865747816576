import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Select from 'react-select';

import { gql, useQuery } from './ApolloClient';
import { Modal } from './Modal';
import { locationForModal } from './ModalRoute';
import { usePromise } from './usePromise';
import { formatCredits } from '../utils';

type EnterpriseRichTextGuidelineProps = {
  partnerId: string;
  onDismiss: () => void;
  location: RouteComponentProps['location'];
  history: RouteComponentProps['history'];
  title?: string;
  buttonLabel?: string;
  onSubmit: (
    args: GuidelineSubmitPayload
  ) => Promise<{ guidelineId: string | undefined }>;
  onContinue: (p: { guidelineId: string }) => void;
  modalParams: {
    modalName: string;
    guidelineProductIds?: string[] | null;
    guidelineId?: string;
    shootType?: string;
    isReferral?: boolean;
    guidelineName?: string;
    userType?: 'artist' | 'end_customer';
    serviceType: string;
    addonIds?: string[];
  };
};

type Package = {
  uid: string;
  duration: number;
  price: number;
  type?: string;
  shootType: {
    category: string;
  };
};

type Addon = {
  uid: string;
  displayName?: number;
  price?: number;
};

export type GuidelineSubmitPayload = {
  isReferral: boolean;
  name: string;
  shootType: string;
  productIds: string[];
  userType: string;
  partnerId: string;
  serviceType: string;
  addonIds?: string[];
};

export const EnterpriseRichTextGuidelineBaseModal = ({
  partnerId,
  location,
  history,
  title = 'Create rich text guideline',
  buttonLabel = 'Create',
  onSubmit,
  onDismiss,
  onContinue,
  modalParams,
}: EnterpriseRichTextGuidelineProps) => {
  const formSubmissionPromise = usePromise();

  const shootTypesQuery = useQuery<{
    getPublicShootTypes: {
      displayName: string;
      category: string;
    }[];
  }>(gql`
    query GetPublicShootTypesQueryForRichText {
      getPublicShootTypes {
        category
        displayName
      }
    }
  `);

  const productsByShootTypeQuery = useQuery<{
    getPublicPackagesByShootTypeForGuidelineCreationAsAdmin?: {
      edges: Package[];
    };
    getAddonsForGuidelineCreation: {
      edges?: Addon[];
    };
  }>(
    gql`
      query GetProductsByShootTypeOnRichText(
        $shootType: String!
        $partnerId: ID!
        $serviceType: String!
      ) {
        getPublicPackagesByShootTypeForGuidelineCreationAsAdmin(
          shootType: $shootType
          partnerId: $partnerId
          serviceType: $serviceType
        ) {
          edges {
            uid
            duration
            price
            type
            shootType {
              category
            }
          }
        }

        getAddonsForGuidelineCreation(
          partnerId: $partnerId
          shootType: $shootType
          serviceType: $serviceType
        ) {
          edges {
            uid
            displayName
            price
          }
        }
      }
    `,
    {
      variables: {
        shootType: modalParams.shootType,
        serviceType: modalParams.serviceType,
        partnerId,
      },
      skip: modalParams.shootType == null || modalParams.serviceType == null,
    }
  );

  const ALL_PACKAGES_OPTION = { value: 'ALL_PACKAGES', label: 'All packages' };

  const packages =
    productsByShootTypeQuery.data
      ?.getPublicPackagesByShootTypeForGuidelineCreationAsAdmin?.edges;

  const options = (packages ?? []).map((o) => ({
    label: `${o.duration / 60} ${o.duration / 60 === 1 ? 'Hour' : 'Hours'}`,
    value: o.uid,
  }));

  async function onSubmitHandler(event) {
    event.preventDefault();

    if (createGuidelineValid !== true) {
      return;
    }

    formSubmissionPromise.setPromise(
      onSubmit({
        partnerId: partnerId,
        isReferral: modalParams.isReferral ?? false,
        name: modalParams.guidelineName!,
        shootType: modalParams.shootType!,
        productIds: modalParams.guidelineProductIds?.includes(
          ALL_PACKAGES_OPTION.value
        )
          ? options.map((o) => o.value)
          : modalParams.guidelineProductIds!,
        userType: modalParams.userType!,
        serviceType: modalParams.serviceType,
        addonIds: modalParams.addonIds,
      }).then(({ guidelineId }) => {
        if (guidelineId == null) return;
        onContinue({ guidelineId });
      })
    );
  }

  const shootTypes = shootTypesQuery.data?.getPublicShootTypes;

  const selectedProductIdsOptions =
    modalParams.shootType === 'realestate'
      ? [ALL_PACKAGES_OPTION]
      : modalParams.guidelineProductIds &&
        [...options, ALL_PACKAGES_OPTION].filter((o) =>
          modalParams.guidelineProductIds?.includes(o.value)
        );

  const addons = (
    productsByShootTypeQuery.data?.getAddonsForGuidelineCreation.edges ?? []
  ).map((addon) => ({
    value: addon.uid,
    label: `${addon.displayName} (${formatCredits(addon.price)} credits)`,
  }));

  const selectedAddons =
    addons?.filter((addon) => modalParams.addonIds?.includes(addon.value)) ??
    [];

  const serviceTypeSelectOptions = [
    { label: 'Photography', value: 'photography' },
    { label: 'Videography', value: 'videography' },
  ];

  const createGuidelineValid =
    modalParams.userType != null &&
    modalParams.guidelineName != null &&
    modalParams.shootType != null &&
    modalParams.guidelineProductIds != null &&
    modalParams.guidelineProductIds.length > 0;

  const loading = formSubmissionPromise.isPending;

  return (
    <Modal onDismiss={onDismiss}>
      <div className="card my-4">
        <div className="card-header">
          <h4 className="mb-0">{title}</h4>
        </div>

        <form
          className="card-body d-flex flex-column"
          style={{ width: '1000px' }}
          onSubmit={onSubmitHandler}
        >
          <div className="flex-1" style={{ position: 'relative' }}>
            <div className="row">
              <div className="form-group col">
                <div className="form-check">
                  <input
                    id="isReferralGuideline"
                    className="form-check-input"
                    type="checkbox"
                    disabled={loading || modalParams.guidelineId != null}
                    checked={modalParams.isReferral ?? false}
                    onChange={({ target: { checked } }) =>
                      history.replace(
                        locationForModal({
                          location,
                          modal: {
                            ...modalParams,
                            isReferral: checked,
                          },
                        })
                      )
                    }
                  />
                  <label
                    className="form-check-label"
                    htmlFor="isReferralGuideline"
                  >
                    This guideline is for referral campaigns
                  </label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="form-group col">
                <label>
                  <small className="text-secondary">Guideline name</small>
                </label>

                <input
                  type="text"
                  className="form-control"
                  disabled={loading}
                  value={modalParams.guidelineName ?? ''}
                  onChange={({ target: { value } }) =>
                    history.replace(
                      locationForModal({
                        location,
                        modal: {
                          ...modalParams,
                          guidelineName: value,
                        },
                      })
                    )
                  }
                />
              </div>

              <div className="form-group col">
                <label>
                  <small className="text-secondary">Guideline user type</small>
                </label>

                <select
                  className="form-control d-inline"
                  value={modalParams.userType}
                  disabled={loading}
                  onChange={({ target: { value } }) => {
                    history.replace(
                      locationForModal({
                        location,
                        modal: {
                          ...modalParams,
                          userType: value,
                        },
                      })
                    );
                  }}
                >
                  <option value="">Select user type</option>
                  <option value="END_CUSTOMER">End customer</option>
                  <option value="ARTISTS">Photographer</option>
                </select>
              </div>
            </div>

            <div className="row">
              <div className="form-group col">
                <label>
                  <small className="text-secondary">Service type</small>
                </label>

                <select
                  className="form-control d-inline"
                  value={modalParams.serviceType}
                  disabled={loading}
                  onChange={({ target: { value } }) => {
                    history.replace(
                      locationForModal({
                        location,
                        modal: {
                          ...modalParams,
                          serviceType: value,
                        },
                      })
                    );
                  }}
                >
                  <option value="">Select service type</option>
                  {serviceTypeSelectOptions.map((serviceType) => (
                    <option key={serviceType.value} value={serviceType.value}>
                      {serviceType.label}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group col">
                <label>
                  <small className="text-secondary">Shoot type</small>
                </label>

                <select
                  className="form-control d-inline"
                  value={modalParams.shootType}
                  disabled={loading}
                  onChange={({ target: { value } }) => {
                    history.replace(
                      locationForModal({
                        location,
                        modal: {
                          ...modalParams,
                          shootType: value,
                          guidelineProductIds:
                            value === 'realestate'
                              ? [ALL_PACKAGES_OPTION.value]
                              : [],
                        },
                      })
                    );
                  }}
                >
                  <option value="">Select shoot type</option>
                  {(shootTypes ?? []).map((shootType) => (
                    <option key={shootType.category} value={shootType.category}>
                      {shootType.displayName}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="row">
              <div className="form-group col">
                <label>
                  <small className="text-secondary">Packages</small>
                </label>

                <Select
                  isMulti
                  isDisabled={
                    packages == null ||
                    packages.length === 0 ||
                    modalParams.shootType === 'realestate' ||
                    loading
                  }
                  value={selectedProductIdsOptions}
                  placeholder="Select"
                  onChange={(selectedOptions) => {
                    const selected = (selectedOptions ?? []).map(
                      (s) => s.value
                    );

                    history.replace(
                      locationForModal({
                        location,
                        modal: {
                          ...modalParams,
                          guidelineProductIds:
                            selected.length > 0
                              ? selected.includes(ALL_PACKAGES_OPTION.value)
                                ? [ALL_PACKAGES_OPTION.value]
                                : selected
                              : undefined,
                        },
                      })
                    );
                  }}
                  options={[ALL_PACKAGES_OPTION, ...options]}
                />
              </div>

              <div className="form-group col">
                <label>
                  <small className="text-secondary">Pre-selected addons</small>
                </label>

                <Select
                  options={addons}
                  value={selectedAddons}
                  isDisabled={
                    loading ||
                    addons.length === 0 ||
                    modalParams.serviceType == null ||
                    modalParams.shootType == null ||
                    (modalParams.guidelineProductIds ?? []).length === 0
                  }
                  isMulti
                  onChange={(selectedOptions) => {
                    history.replace(
                      locationForModal({
                        location,
                        modal: {
                          ...modalParams,
                          addonIds: selectedOptions?.map((o) => o.value),
                        },
                      })
                    );
                  }}
                />
              </div>
            </div>
          </div>

          <div className="p-2" />

          <div className="d-flex justify-content-between mt-3">
            <button
              type="submit"
              className="btn btn-block btn-dark"
              disabled={createGuidelineValid !== true || loading === true}
            >
              {buttonLabel}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
