import React from 'react';

import { useInBrowser } from '../reactify/src/components/useInBrowser';
import { BaseMarkup } from '../reactify/src/components/BaseMarkup';

import { App } from '../reactify/src';

export default function Index() {
  const inBrowser = useInBrowser();

  return <BaseMarkup>{!inBrowser ? null : <App />}</BaseMarkup>;
}
