import { useState, useEffect, useRef } from 'react';

const hasFocus = () => typeof document !== 'undefined' && document.hasFocus();

// Originally taken (with a few modifications) from:
// https://github.com/jpalumickas/use-window-focus

export function useWindowFocus(props?: { onFocus?: () => void }) {
  const [focused, setFocused] = useState(hasFocus); // Focus for first render

  const onFocusRef = useRef(props?.onFocus);
  useEffect(() => {
    onFocusRef.current = props?.onFocus;
  }, [props?.onFocus]);

  useEffect(() => {
    setFocused(hasFocus()); // Focus for additional renders

    const onFocus = () => {
      setFocused(true);

      onFocusRef.current?.();
    };
    const onBlur = () => setFocused(false);

    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);

    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  }, []);

  return { focused };
}
