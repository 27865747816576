import React from 'react';
import Head from 'next/head';

export function BaseMarkup({ children }) {
  return (
    <>
      <Head>
        <title>Snappr Backoffice</title>

        <>
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />

          <link rel="preconnect" href="https://use.typekit.net" />

          <script
            dangerouslySetInnerHTML={{
              __html: `
(function(d) {
  var config = {
    kitId: 'sbj6yvh',
    scriptTimeout: 3000,
    async: true
  },
  h=d.documentElement,t=setTimeout(function(){h.className=h.className.replace(/\bwf-loading\b/g,"")+" wf-inactive";},config.scriptTimeout),tk=d.createElement("script"),f=false,s=d.getElementsByTagName("script")[0],a;h.className+=" wf-loading";tk.src='https://use.typekit.net/'+config.kitId+'.js';tk.async=true;tk.onload=tk.onreadystatechange=function(){a=this.readyState;if(f||a&&a!="complete"&&a!="loaded")return;f=true;clearTimeout(t);try{Typekit.load(config)}catch(e){}};s.parentNode.insertBefore(tk,s)
})(document);
`,
            }}
          />
        </>

        {/* <script defer src="https://use.fontawesome.com/dca45c6c77.js" /> */}
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
          integrity="sha256-eZrrJcwDc/3uDhsdt61sL2oOBY362qM3lon1gyExkL0="
          crossOrigin="anonymous"
          defer
        />
      </Head>

      {children}

      <div id="app-popovers" style={{ height: '100%', display: 'block' }} />
    </>
  );
}
