import React from 'react';

// import { STANDARD_BORDER } from '../UI';

// import { ButtonBase } from '../UI/ButtonBase';
import actionsIcon from './img/actions.svg';

const ActionsDropdownContext = React.createContext<{ onClose: () => void }>({
  onClose: () => {},
});

export function ActionsDropdown({
  children,
  textAlign,
  defaultOpen = false,
  direction = 'right',
  disabled = false,
  paddingRight = 13,
  width,
  placeOnTop = false,
  customTransform,
}: {
  children: (p: { onClose: () => void }) => React.ReactNode;
  textAlign?: 'right' | 'left' | 'center';
  width?: number;
  defaultOpen?: boolean;
  direction?: string;
  disabled?: boolean;
  paddingRight?: number;
  placeOnTop?: boolean;
  customTransform?: string;
}) {
  const color = '#454F5C';
  const bgColor = 'transparent';
  const bgColorHover = 'transparent';

  const [open, setOpen] = React.useState(defaultOpen);

  const menuWrapperRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (open === false) return;

    function handleClickOutside(event) {
      if (
        menuWrapperRef.current &&
        !menuWrapperRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    }

    document.addEventListener('pointerdown', handleClickOutside);
    return () => {
      document.removeEventListener('pointerdown', handleClickOutside);
    };
  }, [open]);

  const onClose = React.useCallback(() => setOpen(false), []);

  return (
    <div
      style={{
        position: 'relative',
        width,
        ...(textAlign != null ? { textAlign } : {}),
      }}
    >
      <button
        className={`btn`}
        style={{ backgroundColor: bgColor, color }}
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        onClick={() => setOpen((state) => !state)}
      >
        <img src={actionsIcon} alt="action" />
      </button>
      {open && (
        <div
          ref={menuWrapperRef}
          style={{
            position: 'absolute',
            bottom: -12,

            transform: placeOnTop
              ? `translateY(-100%)`
              : customTransform
              ? customTransform
              : `translateY(100%)`,
            background: 'white',
            borderRadius: 2,
            [direction]: 0,
            border: '1px solid #C1C8D4',

            zIndex: 99,
          }}
        >
          <div>
            <ActionsDropdownContext.Provider value={{ onClose }}>
              {children({ onClose })}
            </ActionsDropdownContext.Provider>
          </div>
        </div>
      )}
    </div>
  );
}
