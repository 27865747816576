import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ENABLE_GEO_BOOKING_MAP } from '../config';

import { checkIfAdminExternal, checkIfCanAccess } from '../utils';

import snapprLogoSrc from './img/snappr-logo.svg';

const SideNavLi = styled.li`
  a {
    &.disabled {
      color: rgba(0, 0, 0, 0.2) !important;
      pointer-events: none;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
`;

const UniversalLink = ({ nativeLink, to, ...rest }) =>
  // eslint-disable-next-line jsx-a11y/anchor-has-content
  nativeLink ? <a href={to} {...rest} /> : <Link to={to} {...rest} />;

const SideNavLink = ({
  href,
  icon,
  label,
  disabled,
  clientRoute,
  activeRoute,
  postFixChildren,
}: {
  href;
  icon;
  label;
  disabled?: boolean;
  clientRoute?: boolean;
  activeRoute;
  postFixChildren?;
}) => (
  <SideNavLi>
    <UniversalLink
      nativeLink={!clientRoute}
      to={href}
      className={[
        activeRoute === href ? 'bg-primary text-white' : 'text-secondary',
        'p-2 d-flex align-items-center font-weight-bold',
        disabled ? 'disabled' : '',
      ].join(' ')}
      style={{ textDecoration: 'none' }}
    >
      <div className="text-center" style={{ width: 35 }}>
        <i aria-hidden="true" className={`fa fa-${icon} px-2`} />
      </div>

      <span>{label}</span>

      {postFixChildren}
    </UniversalLink>
  </SideNavLi>
);

const SideNav = ({ activeRoute, session }) => (
  <div
    className="bg-white"
    style={{
      marginTop: 54,
      paddingTop: 20,
      position: 'fixed',
      width: 200,
      zIndex: 99999,

      borderTop: `solid 1 px black`,
      overflow: 'auto',
      height: '100%',
      paddingBottom: 50,
    }}
  >
    <ul className="list-unstyled">
      {checkIfCanAccess(session, 'iam') && (
        <SideNavLink
          clientRoute
          activeRoute={activeRoute}
          href="/iam"
          label="IAM"
          icon="lock"
        />
      )}
      {checkIfCanAccess(session, 'providers') && (
        <SideNavLink
          clientRoute
          href="/provider-applications"
          activeRoute={activeRoute}
          label={<span>Photographers</span>}
          icon="user-plus"
        />
      )}
      {ENABLE_GEO_BOOKING_MAP && checkIfCanAccess(session, 'providers') && (
        <SideNavLink
          clientRoute
          href="/geo-booking-assignment"
          activeRoute={activeRoute}
          label={<span>Geo Booking Assignment</span>}
          icon="map"
        />
      )}
      {ENABLE_GEO_BOOKING_MAP && checkIfCanAccess(session, 'providers') && (
        <SideNavLink
          clientRoute
          href="/pgs-bookings-map"
          activeRoute={activeRoute}
          label={<span>Photographers and Bookings Map</span>}
          icon="map"
        />
      )}
      {checkIfCanAccess(session, 'providerPoints') && (
        <SideNavLink
          clientRoute
          href="/provider-points"
          activeRoute={activeRoute}
          label="Provider Points"
          icon="trophy"
        />
      )}
      {checkIfCanAccess(session, 'bookings') && (
        <SideNavLink
          clientRoute
          activeRoute={activeRoute}
          href="/bookings"
          label="Bookings"
          icon="calendar"
        />
      )}
      {checkIfCanAccess(session, 'editingJobs') && (
        <SideNavLink
          clientRoute
          activeRoute={activeRoute}
          href="/editing-jobs"
          label="Editing jobs"
          icon="calendar"
        />
      )}
      {checkIfCanAccess(session, 'productsView') && (
        <SideNavLink
          clientRoute
          activeRoute={activeRoute}
          href="/products-view"
          label="Products"
          icon="cube"
        />
      )}
      {checkIfCanAccess(session, 'editingTaskQueue') && (
        <SideNavLink
          clientRoute
          href="/editing-queue"
          activeRoute={activeRoute}
          label="Editing Task Queue"
          icon="pencil"
        />
      )}
      {checkIfCanAccess(session, 'contractorEditingTaskQueue') && (
        <SideNavLink
          clientRoute
          href="/contractor-editing-queue"
          activeRoute={activeRoute}
          label="Contractor Editing Task Queue"
          icon="pencil"
        />
      )}
      {checkIfCanAccess(session, 'photoQCQueue') && (
        <SideNavLink
          clientRoute
          href="/photo-review-queue"
          activeRoute={activeRoute}
          label="Photo QA Queue"
          icon="shield"
        />
      )}
      {checkIfCanAccess(session, 'qualityControlView') && (
        <SideNavLink
          clientRoute
          href="/quality-control-view"
          activeRoute={activeRoute}
          label="Workflows QC Queue"
          icon="shield"
        />
      )}
      {checkIfCanAccess(session, 'bookings') && (
        <SideNavLink
          clientRoute
          href="/tickets"
          activeRoute={activeRoute}
          label={<span>Product Requests</span>}
          icon="ticket"
        />
      )}
      {checkIfCanAccess(session, 'printJobs') && (
        <SideNavLink
          clientRoute
          href="/print-jobs"
          activeRoute={activeRoute}
          label="Print Jobs"
          icon="print"
        />
      )}
      {checkIfCanAccess(session, 'chatChannels') && (
        <SideNavLink
          clientRoute
          href="/chat-channels"
          activeRoute={activeRoute}
          label="Chat Channels"
          icon="comment"
        />
      )}
      {checkIfCanAccess(session, 'customers') && (
        <SideNavLink
          clientRoute
          activeRoute={activeRoute}
          href="/customers"
          label="Customers"
          icon="users"
        />
      )}
      {/*TODO: add gift cards access check*/}
      {checkIfCanAccess(session, 'coupons') && (
        <SideNavLink
          href="/gift-cards"
          label="Gift Cards"
          icon="gift"
          clientRoute
          activeRoute={activeRoute}
        />
      )}
      {checkIfCanAccess(session, 'coupons') && (
        <SideNavLink
          href="/coupons"
          label="Coupons"
          icon="usd"
          clientRoute
          activeRoute={activeRoute}
        />
      )}
      {/* {checkIfCanAccess(session, 'enterprise') && (
        <SideNavLink
          clientRoute
          activeRoute={activeRoute}
          href="/partners"
          label="Partners"
          icon="briefcase"
        />
      )} */}
      {checkIfCanAccess(session, 'enterprise') && (
        <SideNavLink
          clientRoute
          activeRoute={activeRoute}
          href="/enterprises"
          label="Enterprises"
          icon="briefcase"
        />
      )}
      {/* {checkIfCanAccess(session, 'enterprise') && (
        <SideNavLink
          clientRoute
          activeRoute={activeRoute}
          href="/enterprises"
          label="Enterprises"
          icon="briefcase"
        />
      )} */}
      {checkIfCanAccess(session, 'metrics') && (
        <SideNavLink
          clientRoute
          activeRoute={activeRoute}
          href="/metrics"
          label="Metrics"
          icon="bar-chart"
        />
      )}
      {checkIfCanAccess(session, 'salesTeam') && (
        <SideNavLink
          clientRoute
          href="/sales-team"
          activeRoute={activeRoute}
          label="Sales Team"
          icon="bar-chart"
        />
      )}
      {checkIfCanAccess(session, 'salesLeadQueue') && (
        <SideNavLink
          clientRoute
          href="/sales-lead-queue"
          activeRoute={activeRoute}
          label="Sales Lead Queue"
          icon="money"
        />
      )}
      {checkIfCanAccess(session, 'completionLeadQueue') && (
        <SideNavLink
          clientRoute
          href="/completion-lead-queue"
          activeRoute={activeRoute}
          label="Completion Lead Queue"
          icon="money"
        />
      )}
      {checkIfCanAccess(session, 'tools') && (
        <SideNavLink
          clientRoute
          activeRoute={activeRoute}
          href="/tools"
          label="Tools"
          icon="wrench"
        />
      )}
      {checkIfCanAccess(session, 'providerPayouts') && (
        <SideNavLink
          clientRoute
          activeRoute={activeRoute}
          href="/provider-payouts"
          label="Provider payouts"
          icon="wrench"
        />
      )}
      {checkIfCanAccess(session, 'salesClaims') && (
        <SideNavLink
          clientRoute
          activeRoute={activeRoute}
          href="/sales-claims"
          label="Sales Claims"
          icon="pie-chart"
        />
      )}
      {checkIfCanAccess(session, 'imageSearch') && (
        <SideNavLink
          clientRoute
          activeRoute={activeRoute}
          href="/super-image-search"
          label="Image Search"
          icon="picture-o"
        />
      )}
      {checkIfCanAccess(session, 'pilotTracker') && (
        <SideNavLink
          clientRoute
          activeRoute={activeRoute}
          href="/enterprise-tracker"
          label="Enterprise Client Tracker"
          icon="plane"
        />
      )}
      {checkIfCanAccess(session, 'userDeactivation') && (
        <SideNavLink
          clientRoute
          activeRoute={activeRoute}
          href="/user-deactivation"
          label="User Deactivation"
          icon="user-times"
        />
      )}
      {checkIfCanAccess(session, 'callHistory') && (
        <SideNavLink
          clientRoute
          activeRoute={activeRoute}
          href="/call-history"
          label="Call History"
          icon="phone"
        />
      )}
      {checkIfCanAccess(session, 'iam') && (
        <SideNavLink
          clientRoute
          activeRoute={activeRoute}
          href="/bull-queues"
          label="Bull queues"
          icon="wrench"
        />
      )}
      {checkIfCanAccess(session, 'providerCoverageTask') && (
        <SideNavLink
          clientRoute
          activeRoute={activeRoute}
          href="/coverage"
          label="Coverage"
          icon="map"
        />
      )}

      {checkIfCanAccess(session, 'workflowRunsView') && (
        <SideNavLink
          clientRoute
          href="/workflow-runs-view"
          activeRoute={activeRoute}
          label="Workflow Runs"
          icon="wrench"
        />
      )}

      {checkIfCanAccess(session, 'aiPortraitJob') && (
        <SideNavLink
          clientRoute
          href="/ai-portrait-job"
          activeRoute={activeRoute}
          label="Headshots AI"
          icon="camera"
        />
      )}

      <SideNavLink
        clientRoute
        href="/ai-ecommerce-job"
        activeRoute={activeRoute}
        label="Ecommerce AI"
        icon="camera"
      />

      {checkIfCanAccess(session, 'headshotQualityControl') && (
        <SideNavLink
          clientRoute
          href="/headshot-quality-control"
          activeRoute={activeRoute}
          label="Headshots QC"
          icon="shield"
        />
      )}
    </ul>
  </div>
);

const Header = ({ session: { user, token } = {}, session }: { session? }) => (
  <header
    className="d-flex justify-content-between align-items-center bg-white px-3"
    style={{
      position: 'fixed',
      width: '100%',
      zIndex: 9999,
      height: 53,

      boxShadow: '0 0 1.625em rgba(0,0,0,.05)',
    }}
  >
    <div>
      <img src={snapprLogoSrc} alt="Logo" style={{ height: 25 }} />
      {checkIfAdminExternal({ session }) && (
        <React.Fragment>
          <small className="mx-3 font-garamond font-italic">
            Investor access
          </small>
          <span className="badge badge-secondary">Confidential</span>
        </React.Fragment>
      )}
    </div>

    {user && token && (
      <div className="d-flex align-items-center">
        <a href="?wowGalleryId" className="btn btn-outline-success btn-sm mr-3">
          Wow Factor
        </a>

        <Link to={`/bookings/new`} className="btn btn-primary btn-sm mr-3">
          New Booking
        </Link>

        <a href="/logout">Logout</a>

        <span className="ml-3">{user.firstname}</span>
      </div>
    )}
  </header>
);

const BaseLayout = ({
  session,
  children,
  match,
  bodyStyle = {},
  contentInsetPx = 30,
}: {
  session;
  children: React.ReactNode;
  match;
  bodyStyle?;
  contentInsetPx?: number;
}) => (
  <>
    <Header session={session} />

    <SideNav activeRoute={match.path} session={session} />

    <div
      className="d-flex flex-column flex-1 w-100"
      style={{
        paddingTop: 52 + contentInsetPx,
        paddingLeft: 200 + contentInsetPx,
        paddingRight: contentInsetPx,
        paddingBottom: contentInsetPx,
        overflow: 'hidden',
        ...bodyStyle,
      }}
    >
      {children}
    </div>
  </>
);

export default BaseLayout;
