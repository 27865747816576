export const prettifyShoottype = (shoottype) =>
  shoottype &&
  ({ teamAndOffice: 'Team and Office', realestate: 'Real Estate' }[shoottype] ||
    shoottype.charAt(0).toUpperCase() + shoottype.slice(1));

export function createCSV({ data }) {
  const labels = data[0];
  const firstRow = Object.keys(labels).join(',');

  const rowValues = data.map((row) => {
    return Object.values(row).join(',');
  });

  return firstRow + '\n' + rowValues.join('\n');
}
