import React from 'react';

import { gql, useMutation } from './ApolloClient';

import { placeholderRichTextGuideline } from '../routes/enterprises/guidelines';
import { EnterpriseRichTextGuidelineBaseModal } from './EnterpriseRichTextGuidelineBaseModal';

type EnterpriseRichTextGuidelineProps = {
  partnerId: string;
  onDismiss: () => void;
  location;
  history;
  onContinue: (p: { guidelineId: string }) => void;
  modalParams: {
    modalName: string;
    guidelineProductIds?: string[];
    guidelineId?: string;
    shootType?: string;
    isReferral?: boolean;
    guidelineName?: string;
    userType?: 'artist' | 'end_customer';
    serviceType: string;
  };
};

export const EnterpriseRichTextGuidelineModal = ({
  partnerId,
  location,
  history,
  onDismiss,
  onContinue,
  modalParams,
}: EnterpriseRichTextGuidelineProps) => {
  const [commitCreatePartnerGuidelineAsAdmin] = useMutation<
    { partnerGuidelineCreateAsAdmin: { guidelines?: { uid: string }[] } },
    {
      partnerId: string;
      isReferral: boolean;
      name: string;
      shootType: string;
      productIds: string[];
      userType: string;
      fileUrl?: string;
      sourceFileUrl?: string;
      videoId?: string;
      serviceType: string;
      addonIds?: string[];
    }
  >(
    gql`
      mutation NewPartnerGuidelineCreateAsAdminRichText(
        $partnerId: ID!
        $isReferral: Boolean!
        $name: String!
        $shootType: String!
        $productIds: [ID!]!
        $userType: PartnerGuidelineUserTypeAsAdmin!
        $fileUrl: String
        $sourceFileUrl: String
        $videoId: String
        $serviceType: String!
        $addonIds: [ID!]
      ) {
        partnerGuidelineCreateAsAdmin(
          input: {
            partnerId: $partnerId
            isReferral: $isReferral
            name: $name
            shootType: $shootType
            productIds: $productIds
            userType: $userType
            fileUrl: $fileUrl
            sourceFileUrl: $sourceFileUrl
            videoId: $videoId
            serviceType: $serviceType
            addonIds: $addonIds
          }
        ) {
          guidelines {
            uid
          }
        }
      }
    `
  );

  const [commitCreateRichTextGuidelineAsAdmin] = useMutation<
    {
      richTextGuideline: { id: string };
    },
    {
      partnerGuidelineId: string;
      content: string;
    }
  >(
    gql`
      mutation NewPartnerRichTextGuidelineCreateAsAdmin(
        $partnerGuidelineId: ID!
        $content: String!
      ) {
        richTextGuidelineCreate(
          input: { partnerGuidelineId: $partnerGuidelineId, content: $content }
        ) {
          richTextGuideline {
            id
          }
        }
      }
    `
  );

  function onSubmit(guidelinePayload) {
    return commitCreatePartnerGuidelineAsAdmin({
      variables: guidelinePayload,
    }).then(async (resp) => {
      const guidelineId =
        resp?.data?.partnerGuidelineCreateAsAdmin?.guidelines?.[0]?.uid;

      if (guidelineId == null) return { guidelineId: undefined };

      await commitCreateRichTextGuidelineAsAdmin({
        variables: {
          content: JSON.stringify(placeholderRichTextGuideline),
          partnerGuidelineId: guidelineId,
        },
      });

      return { guidelineId };
    });
  }

  return (
    <EnterpriseRichTextGuidelineBaseModal
      partnerId={partnerId}
      location={location}
      history={history}
      onDismiss={onDismiss}
      onContinue={onContinue}
      modalParams={modalParams}
      onSubmit={onSubmit}
    />
  );
};
