import React from 'react';
import { Switch, Route } from 'react-router-dom';

import BaseLayout from '../../components/BaseLayout';
import { BaseStyle } from '../../components/BaseStyle';
import { WorkflowRunsView } from './workflow-runs-view';

export const WorflowRunsViewRoute = ({
  session,
  match,
  match: { path: basePath },
  history,
}) => (
  <BaseLayout session={session} match={match}>
    <BaseStyle className="d-flex flex-column flex-1">
      <Switch>
        <Route
          exact
          path={basePath}
          render={(props) => (
            <WorkflowRunsView {...props} session={session} history={history} />
          )}
        />
      </Switch>
    </BaseStyle>
  </BaseLayout>
);
