import React from 'react';

import { Modal } from '../../../../../components/Modal';
import { useQuery, gql } from '../../../../../components/ApolloClient';
import { LoadingSpinnerCentered } from '../../../../../components/LoadingSpinner';

const categoryEmojis = {
  'Product, Food and Fashion': '🛍️',
  Portrait: '🖼️',
  'Family and Travel': '👪',
  'Real Estate and Automotive': '🏘️',
  'Event, Party and Graduation': '🥂',
  'Couple (including Wedding)': '🧑‍🤝‍🧑',
  Corporate: '👔',
  Other: '🔀',
};

const categoryContent = {
  'Product, Food and Fashion': ['food', 'product', 'fashion'],
  Portrait: ['profile', 'dating'],
  'Family and Travel': ['family', 'travel', 'pet', 'kids'],
  'Real Estate and Automotive': ['realestate', 'automotive'],
  'Event, Party and Graduation': ['event', 'party', 'graduation'],
  'Couple (including Wedding)': [
    'engagement',
    'wedding',
    'anniversary',
    'premium-wedding',
  ],
  Corporate: ['teamAndOffice', 'media', 'commercial'],
  Other: ['sport', 'religious', 'landscape', 'boat', 'boudoir'],
};

export function NextBooking({ onDismiss, booking }) {
  const bookingRebookingQuery = useQuery<{
    bookingById?: {
      rebookingPrediction?: {
        rebookingProbability: number;
        nextMostLikelyShoottypeGroup: [{ value: number; name: string }];
        bookingsToDate: number;
      };
    };
  }>(
    gql`
      query bookingRebookingByIdQuery($bookingId: ID!) {
        bookingById(id: $bookingId) {
          rebookingPrediction {
            rebookingProbability
            bookingsToDate
            nextMostLikelyShoottypeGroup {
              value
              name
            }
          }
        }
      }
    `,
    {
      variables: {
        bookingId: booking.uid,
      },
    }
  );

  const data = bookingRebookingQuery?.data?.bookingById?.rebookingPrediction;
  const rebookingProb = Math.round((data?.rebookingProbability ?? 0) * 100);
  const categoryArray = data?.nextMostLikelyShoottypeGroup;
  const total =
    categoryArray != null
      ? categoryArray.map((cat) => cat.value).reduce((a, b) => a + b, 0)
      : 1;

  return (
    <Modal onDismiss={onDismiss}>
      <div className="card my-4">
        <div className="mt-5">
          <h3 className="font-weight-bold text-center">
            Most likely next booking
          </h3>
        </div>
        <div
          className="card-body text-justify grid-content"
          style={{ width: '1000px', minHeight: '200px' }}
        >
          {data == null ? (
            <div className="centerInGrid">
              <LoadingSpinnerCentered />
            </div>
          ) : (
            <>
              <div className="font-weight-bold">Rebooking probability </div>
              <div>{rebookingProb}% </div>
              <div className="font-weight-bold">Most likely next booking </div>
              <div>
                {categoryArray != null &&
                  categoryArray.map((category, index) => (
                    <div className="hover-parent">
                      {categoryEmojis[category.name]} {category.name}{' '}
                      {Math.round((category.value / total) * 100)}%
                      <div className="hover-tooltip">
                        {categoryContent[category.name].map((name) => (
                          <div>{name}</div>
                        ))}
                      </div>
                    </div>
                  ))}
              </div>
              <div className="font-weight-bold">Bookings to date </div>
              <div>{data?.bookingsToDate}</div>
            </>
          )}
        </div>

        <button className="btn btn-secondary mr-2 close" onClick={onDismiss}>
          X
        </button>
      </div>
      <style jsx>{`
        .grid-content {
          display: grid;
          grid-template-columns: 200px 1fr;
        }

        .centerInGrid {
          position: absolute;
          margin-left: auto;
          margin-right: auto;
          left: 0;
          right: 0;
          text-align: center;
        }

        .btn-black {
          color: white;
          background-color: black;
          margin: 16px;
        }

        .close {
          width: 100px;
          position: absolute;
          right: 10px;
          top: 10px;
        }

        .hover-parent {
          position: relative;
        }

        .hover-tooltip {
          position: absolute;
          padding-left: 8px;
          font-size: 1rem;
          background-color: white;
          z-index: 2;
          width: 200px;
          left: 70px;
          top: 0px;
          opacity: 0;
          transition: all 0.2s;
          border: 1px solid #000;
          border-radius: 2px;
        }

        .hover-tooltip {
          cursor: none;
        }

        .hover-parent:hover .hover-tooltip {
          opacity: 1;
        }
      `}</style>
    </Modal>
  );
}
