import React, { useEffect, useMemo, useState } from 'react';
import { Switch, Route, Link, RouteComponentProps } from 'react-router-dom';
import qs from 'query-string';
import moment from 'moment-timezone';
import Select from 'react-select';
import Component2 from '@reach/component-component';
import InView from 'react-intersection-observer';

import { gql, useMutation, useQuery } from '../components/ApolloClient';
import { BookingStatusBadge } from '../components/BookingStatusBadge';
import { CopyIdField } from '../components/CopyIdField';
import { formatTimestampToCountdown } from '../utils';
import { ensureString } from '../utils/strings';
import BaseLayout from '../components/BaseLayout';

enum UpcomingBookingNoShowFlagEnum {
  REQUEST_CHANGE = 'REQUEST_CHANGE',
  SUPPORT_TICKET = 'SUPPORT_TICKET',
  NO_PG_COMMUNICATION = 'NO_PG_COMMUNICATION',
}

function NoShowFlagBadge({
  flag,
  marginTop,
}: {
  flag: UpcomingBookingNoShowFlagEnum;
  marginTop?: React.CSSProperties['marginTop'];
}) {
  const flagColors = {
    [UpcomingBookingNoShowFlagEnum.REQUEST_CHANGE]: 'purple',
    [UpcomingBookingNoShowFlagEnum.SUPPORT_TICKET]: 'teal',
    [UpcomingBookingNoShowFlagEnum.NO_PG_COMMUNICATION]: 'red',
  };

  return (
    <span
      className="text-uppercase badge"
      style={{
        display: 'inline-block',
        color: '#FFF',
        backgroundColor: flagColors[flag],
        marginTop,
      }}
    >
      {flag.split('_').join(' ')}
    </span>
  );
}

function MultiSelectFiler({
  title,
  options,
  selectedOptions,
  onChange,
}: {
  title: string;
  options: { code: string; name: string }[];
  selectedOptions: { code: string; name: string }[] | undefined;
  onChange: (p: {
    options: Readonly<{ code: string; name: string }[]>;
  }) => void;
}) {
  return (
    <div style={{ minWidth: 150, marginRight: 16 }}>
      <Select
        isMulti
        getOptionValue={({ code }) => code}
        getOptionLabel={({ name }) => name}
        value={selectedOptions}
        options={options}
        placeholder={title}
        onChange={(options) => onChange({ options: options ?? [] })}
      />
    </div>
  );
}

function DataRowsChunk({
  cursor,
  location,
  onMore,
}: {
  cursor: string | null;
  location: { search: string };
  onMore?: (p: { nextCursor: string }) => void;
}) {
  const {
    statusFilter,
    shootTypeFilter,
    partnerFilter,
    countryFilter,
    searchFilter,
  } = qs.parse(location.search) as {
    statusFilter?: string;
    shootTypeFilter?: string;
    partnerFilter?: string;
    countryFilter?: string;
    searchFilter?: string;
  };

  const upcomingBookingNoShowQuery = useQuery<
    {
      upcomingBookingNoShows?: {
        cursor?: string;
        edges?: {
          id: string;
          flags?: UpcomingBookingNoShowFlagEnum[];
          numberOfNoShows?: number;
          booking?: {
            id: string;
            shootType?: {
              displayName?: string;
            };
            status?: string;
            startAt?: string;
            paidAt?: string;
            timezone?: string;
            partner?: {
              uid: string;
              name?: string;
            };
            provider?: {
              id: string;
              publicFullName?: string;
              matchingAlgorithmScore?: {
                cancellation?: number;
              };
            };
            region?: {
              uid: string;
              name?: string;
            };
          };
          claimedBy?: {
            id: string;
            fullName: string;
          };
        }[];
      };
    },
    {
      cursor?: string;
      first?: number;
      statusFilter?: string[];
      shootTypeFilter?: string[];
      partnerFilter?: string[];
      countryFilter?: string[];
      searchFilter?: string;
    }
  >(
    gql`
      query upcomingBookingNoShowList(
        $first: Int
        $cursor: String
        $statusFilter: [String!]
        $shootTypeFilter: [String!]
        $partnerFilter: [String!]
        $countryFilter: [String!]
        $searchFilter: String
      ) {
        upcomingBookingNoShows: upcomingBookingNoShowListAsAdmin(
          first: $first
          cursor: $cursor
          statusFilter: $statusFilter
          shootTypeFilter: $shootTypeFilter
          partnerFilter: $partnerFilter
          countryFilter: $countryFilter
          searchFilter: $searchFilter
        ) {
          cursor
          edges {
            id
            flags
            numberOfNoShows

            booking {
              id
              shootType {
                displayName
              }
              status
              startAt(forceShowAsAdmin: true)
              paidAt
              timezone
              partner {
                uid
                name
              }
              provider {
                id
                publicFullName
                matchingAlgorithmScore {
                  cancellation
                }
              }
              region {
                uid
                name
              }
            }

            claimedBy {
              id
              fullName
            }
          }
        }
      }
    `,
    {
      variables: {
        first: cursor == null ? 20 : 40,
        cursor: cursor != null ? cursor : undefined,
        statusFilter: statusFilter?.split(','),
        shootTypeFilter: shootTypeFilter?.split(','),
        partnerFilter: partnerFilter?.split(','),
        countryFilter: countryFilter?.split(','),
        searchFilter,
      },
    }
  );

  const [claimBooking, claimBookingMutation] = useMutation<
    {
      upcomingBookingNoShowClaimAsAdmin: {
        upcomingBookingNoShow: {
          id: string;
          claimedBy: {
            id: string;
            fullName: string;
          };
        };
      };
    },
    { bookingId: string }
  >(
    gql`
      mutation PossibleBookingNoShowClaim($bookingId: ID!) {
        upcomingBookingNoShowClaimAsAdmin(input: { bookingId: $bookingId }) {
          upcomingBookingNoShow {
            id
            claimedBy {
              id
              fullName
            }
          }
        }
      }
    `
  );

  const upcomingBookingNoShows =
    upcomingBookingNoShowQuery.data?.upcomingBookingNoShows?.edges ?? [];

  const nextCursor =
    upcomingBookingNoShowQuery.data?.upcomingBookingNoShows?.cursor;

  if (upcomingBookingNoShowQuery.error) return <p>Error</p>;

  return (
    <>
      {upcomingBookingNoShowQuery.loading
        ? Array.from(Array(5)).map((i, index) => (
            <tr key={'loading-' + index}>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
            </tr>
          ))
        : upcomingBookingNoShows?.map(
            ({ id, booking, flags, numberOfNoShows, claimedBy }) => (
              <tr key={id}>
                <td style={{ whiteSpace: 'nowrap', minWidth: 300 }}>
                  <div className="d-flex">
                    <CopyIdField id={id} />

                    <div className="btn btn-dark btn-sm ml-1">
                      <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        to={`/bookings/${booking?.id}`}
                      >
                        <i className="fa fa-external-link" aria-hidden="true" />
                      </Link>
                    </div>
                  </div>
                </td>

                <td>{booking?.shootType?.displayName}</td>

                <td style={{ minWidth: 150 }}>
                  {booking?.partner?.name ?? '-'}
                </td>

                <td>
                  <BookingStatusBadge
                    status={booking?.status?.split('_').join(' ')}
                  />
                </td>

                <td
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                  }}
                >
                  {flags?.map((flag, index) => (
                    <NoShowFlagBadge
                      key={flag}
                      flag={flag}
                      marginTop={index !== 0 ? '0.5rem' : 0}
                    />
                  ))}
                </td>

                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {numberOfNoShows ?? '-'}
                </td>

                {booking?.startAt != null && (
                  <td style={{ whiteSpace: 'nowrap' }}>
                    <span className="d-block">
                      {formatTimestampToCountdown({
                        deadline: booking.startAt,
                      })}
                    </span>

                    <span
                      className="d-block mt-2 text-secondary"
                      style={{
                        fontSize: 12,
                      }}
                    >
                      {moment(booking.startAt)
                        .tz(booking?.timezone ?? 'UTC')
                        .format('MM/DD/YYYY hh:mm Z z')}
                    </span>
                  </td>
                )}

                <td style={{ whiteSpace: 'nowrap' }}>
                  <span className="d-block">
                    {moment(booking?.paidAt)
                      .tz(booking?.timezone ?? 'UTC')
                      .format('MM/DD/YYYY')}
                  </span>

                  <span
                    className="d-block mt-2 text-secondary"
                    style={{
                      fontSize: 12,
                    }}
                  >
                    {moment(booking?.paidAt)
                      .tz(booking?.timezone ?? 'UTC')
                      .format('MM/DD/YYYY hh:mm Z z')}
                  </span>
                </td>

                <td style={{ whiteSpace: 'nowrap' }}>
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    to={`/providers/${booking?.provider?.id}`}
                  >
                    {booking?.provider?.publicFullName}{' '}
                    <i className="fa fa-external-link" aria-hidden="true" />
                  </Link>
                </td>

                <td>
                  {booking?.provider?.matchingAlgorithmScore == null ? (
                    <small className="text-muted">N/A</small>
                  ) : (
                    booking?.provider?.matchingAlgorithmScore.cancellation?.toFixed(
                      2
                    )
                  )}
                </td>

                <td>{booking?.region?.name}</td>

                <td>
                  {claimedBy != null ? (
                    <span>{claimedBy?.fullName}</span>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary"
                      disabled={claimBookingMutation.loading}
                      onClick={() =>
                        claimBooking({
                          variables: {
                            bookingId: booking?.id ?? '',
                          },
                        })
                      }
                    >
                      Claim
                    </button>
                  )}
                </td>
              </tr>
            )
          )}

      {onMore == null || nextCursor == null ? null : (
        <InView>
          {({ inView, ref }) => (
            <tr ref={ref}>
              {inView && <Component2 didMount={() => onMore({ nextCursor })} />}
            </tr>
          )}
        </InView>
      )}
    </>
  );
}

function UpcomingNoShowsListRoute({
  location,
  history,
}: {
  location: { search: string };
  history: RouteComponentProps['history'];
}) {
  const [cursors, setCursors] = useState<string[]>([]);
  const parsedSearch = useMemo(() => qs.parse(location.search), [
    location.search,
  ]);
  const [isExplanationOpened, setIsExplanationOpened] = useState(false);

  useEffect(() => {
    setCursors([]);
  }, [parsedSearch]);

  const upcomingBookingNoShowListAsAdminMetadataQuery = useQuery<{
    upcomingBookingNoShowListAsAdminMetadata: {
      filters: {
        code: string;
        name: string;
        options: {
          code: string;
          name: string;
        }[];
      }[];
    };
  }>(
    gql`
      query upcomingBookingNoShowListAsAdminMetadata {
        upcomingBookingNoShowListAsAdminMetadata {
          filters {
            code
            name
            options {
              code
              name
            }
          }
        }
      }
    `
  );

  const onFilterChange = ({
    filterName,
    selectedOptions,
  }: {
    filterName: string;
    selectedOptions: Readonly<
      {
        code: string;
        name: string;
      }[]
    >;
  }) => {
    const selected = (selectedOptions || []).map((s) => s.code);

    history.push({
      ...location,
      search: qs.stringify({
        ...qs.parse(location.search),
        [filterName]: selected.length > 0 ? selected.join(',') : undefined,
      }),
    });
  };

  const getSelectedOptions = ({
    filterName,
    options,
  }: {
    filterName: string;
    options: {
      code: string;
      name: string;
    }[];
  }) => {
    const selectedValues = ensureString(
      qs.parse(location.search)[filterName] ?? ''
    )?.split(',');

    return selectedValues != null
      ? options.filter((o: { code: string }) => selectedValues.includes(o.code))
      : [];
  };

  const statusFilter = upcomingBookingNoShowListAsAdminMetadataQuery.data?.upcomingBookingNoShowListAsAdminMetadata.filters.filter(
    (f) => f.code === 'statusFilter'
  );

  const shootTypeFilter = upcomingBookingNoShowListAsAdminMetadataQuery.data?.upcomingBookingNoShowListAsAdminMetadata.filters.filter(
    (f) => f.code === 'shootTypeFilter'
  );

  const partnerFilter = upcomingBookingNoShowListAsAdminMetadataQuery.data?.upcomingBookingNoShowListAsAdminMetadata.filters.filter(
    (f) => f.code === 'partnerFilter'
  );

  const countryFilter = upcomingBookingNoShowListAsAdminMetadataQuery.data?.upcomingBookingNoShowListAsAdminMetadata.filters.filter(
    (f) => f.code === 'countryFilter'
  );

  return (
    <>
      <Component2
        didMount={() => {
          const shouldRedirectOnMount = parsedSearch.statusFilter === undefined;

          if (shouldRedirectOnMount) {
            return history.replace({
              ...location,
              search: qs.stringify({
                ...parsedSearch,
                statusFilter: parsedSearch.statusFilter ?? 'paid',
              }),
            });
          }
        }}
      />

      <h2>
        Potential no-shows{' '}
        <button
          className="btn btn-link btn-small"
          type="button"
          onClick={() => setIsExplanationOpened(!isExplanationOpened)}
        >
          {`How do we predict no-shows? (${
            isExplanationOpened ? 'Hide' : 'Show'
          })`}
        </button>
      </h2>

      {isExplanationOpened === true && (
        <div className="row">
          <div className="col-12">
            <div className="d-flex bg-white p-3 flex-column position-relative">
              <h4>How do we calculate potential no-shows?</h4>
              <p>
                Potential no-shows are bookings that meet the following
                conditions:
              </p>

              <ol>
                <li>
                  Shoot is in <pre className="d-inline">status = 'paid'</pre>.
                </li>
                <li>The shoot is happening within the next 7 days.</li>
                <li>
                  The PG has not contacted the customer (self-reported in the PG
                  app).
                </li>
                <li>Have one of the following "flags".</li>
              </ol>

              <div>
                <h5>Flags</h5>
                <div className="d-flex flex-column">
                  <span>
                    <NoShowFlagBadge
                      flag={UpcomingBookingNoShowFlagEnum.NO_PG_COMMUNICATION}
                    />{' '}
                    = No call recordings or sms messages (via call masking) and
                    no in-app chat messages.
                  </span>
                  <span>
                    <NoShowFlagBadge
                      flag={UpcomingBookingNoShowFlagEnum.REQUEST_CHANGE}
                    />{' '}
                    = A change has been requested by the PG via a self-service
                    flow and has not been approved nor rejected.
                  </span>
                  <span>
                    <NoShowFlagBadge
                      flag={UpcomingBookingNoShowFlagEnum.SUPPORT_TICKET}
                    />{' '}
                    = A provider-generated support ticket has been created.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="row">
        <div className="col-12">
          <div className="d-flex bg-white p-3 flex-column position-relative">
            <div className="d-flex align-items-center pb-3">
              {statusFilter != null && (
                <MultiSelectFiler
                  key={'statusFilter'}
                  title={statusFilter[0].name}
                  options={statusFilter[0].options}
                  selectedOptions={getSelectedOptions({
                    filterName: 'statusFilter',
                    options: statusFilter[0].options,
                  })}
                  onChange={({ options }) => {
                    onFilterChange({
                      filterName: 'statusFilter',
                      selectedOptions: options ?? [],
                    });
                  }}
                />
              )}

              {shootTypeFilter != null && (
                <MultiSelectFiler
                  key={'shootTypeFilter'}
                  title={shootTypeFilter[0].name}
                  options={shootTypeFilter[0].options}
                  selectedOptions={getSelectedOptions({
                    filterName: 'shootTypeFilter',
                    options: shootTypeFilter[0].options,
                  })}
                  onChange={({ options }) => {
                    onFilterChange({
                      filterName: 'shootTypeFilter',
                      selectedOptions: options ?? [],
                    });
                  }}
                />
              )}

              {partnerFilter != null && (
                <MultiSelectFiler
                  key={'partnerFilter'}
                  title={partnerFilter[0].name}
                  options={partnerFilter[0].options}
                  selectedOptions={getSelectedOptions({
                    filterName: 'partnerFilter',
                    options: partnerFilter[0].options,
                  })}
                  onChange={({ options }) =>
                    onFilterChange({
                      filterName: 'partnerFilter',
                      selectedOptions: options ?? [],
                    })
                  }
                />
              )}

              {countryFilter != null && (
                <MultiSelectFiler
                  key={'countryFilter'}
                  title={countryFilter[0].name}
                  options={countryFilter[0].options}
                  selectedOptions={getSelectedOptions({
                    filterName: 'countryFilter',
                    options: countryFilter[0].options,
                  })}
                  onChange={({ options }) =>
                    onFilterChange({
                      filterName: 'countryFilter',
                      selectedOptions: options ?? [],
                    })
                  }
                />
              )}

              <form
                className="d-flex justify-content-end"
                onSubmit={(ev) => {
                  ev.preventDefault();

                  const target = ev.target as typeof ev.target & {
                    providerId: { value: string };
                  };

                  history.push({
                    ...location,
                    search: qs.stringify({
                      ...qs.parse(location.search),
                      searchFilter: target.providerId.value || undefined,
                    }),
                  });
                }}
              >
                <input
                  name="providerId"
                  type="text"
                  className="form-control"
                  style={{ maxWidth: 500 }}
                  placeholder="Booking ID / Provider ID"
                  defaultValue={qs.parse(location.search)['searchFilter'] || ''}
                />
              </form>
            </div>

            <div className="table-responsive" style={{ position: 'relative' }}>
              <table className="table table-hover mb-0">
                <thead>
                  <tr>
                    <th className="text-muted text-truncate">Booking</th>
                    <th className="text-muted text-truncate">Shoot type</th>
                    <th className="text-muted text-truncate">Partner</th>
                    <th className="text-muted text-truncate">Status</th>
                    <th className="text-muted text-truncate">Flags</th>
                    <th className="text-muted text-truncate"># no-shows</th>
                    <th className="text-muted text-truncate">Start</th>
                    <th className="text-muted text-truncate">Paid</th>
                    <th className="text-muted text-truncate">Photographer</th>
                    <th className="text-muted text-truncate">
                      Photographer completion score
                    </th>
                    <th className="text-muted text-truncate">Country</th>
                    <th className="text-muted text-truncate">Support Agent</th>
                  </tr>
                </thead>
                <tbody style={{ color: '#6C757D' }}>
                  {[null, ...cursors].map((cursor, index) => (
                    <DataRowsChunk
                      key={index}
                      cursor={cursor}
                      location={location}
                      onMore={
                        index === cursors.length
                          ? ({ nextCursor }) =>
                              setCursors((state) =>
                                state.includes(nextCursor)
                                  ? state
                                  : [...state, nextCursor]
                              )
                          : undefined
                      }
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export function UpcomingNoShowsRoute({
  session,
  match,
  match: { path: basePath },
}) {
  return (
    <BaseLayout session={session} match={match}>
      <Switch>
        <Route
          exact
          path={basePath}
          render={(props) => <UpcomingNoShowsListRoute {...props} />}
        />
      </Switch>
    </BaseLayout>
  );
}
