import * as React from 'react';
import { createRef } from 'react';
import Select from 'react-select';
import moment from 'moment-timezone';
import qs from 'query-string';
import InView from 'react-intersection-observer';
import { Link, RouteComponentProps } from 'react-router-dom';
import Component2 from '@reach/component-component';
import ContentWrapper from '../../components/ContentWrapper';
import { gql, useQuery, useMutation } from '../../components/ApolloClient';
import { ensureString } from '../../utils/strings';
import { QualityControlStatusEnum } from './types';
import { badgeBgColorStatus, qualityControlStatus } from './utils';
import { SearchField } from '../partners/components';
import { OrderByLink } from '../../components/OrderByLink';
import { locationForModal, ModalRoute } from '../../components/ModalRoute';
import {
  AssignSpecialistModal,
  RemoveSpecialistModal,
} from './components/modals';

const ELLIPSIS_STYLE = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
} as const;

const STATUS = 'status';
const PARTNERS = 'partners';
const MODAL_NAME_ASSIGN_SPECIALIST = 'assign-specialist';
const MODAL_NAME_REMOVE_SPECIALIST = 'remove-specialist';

const dueAtOptions = [
  { label: 'Overdue', value: 'overdue' },
  { label: '< 8 hours', value: 'less_8_hours' },
  { label: '8 to 16 hours', value: 'less_16_hours' },
  { label: '16 to 48 hours', value: 'less_48_hours' },
  { label: '48 to 72 hours', value: 'less_72_hours' },
  { label: '> 72 hours', value: 'greater_72_hours' },
];

// Types and queries
type IQualityControlForListType = {
  id: string;
  dueAt?: string;
  createdAt: string;
  status: QualityControlStatusEnum;
  name?: string;
  partner: {
    uid: string;
    name: string;
  };
  createdBy: {
    uid: string;
  };
  specialist?: {
    id: string;
    fullName: string;
  };
  criteria?: {
    edges?: {
      id: string;
      description: string;
    }[];
    total?: number;
  };
  vertical: string;
  medias?: {
    total: number;
    edges: {
      criteria: {
        edges: {
          id: string;
        }[];
      };
    }[];
  };
  total;
  cursor;
};

type QualityControlQueryType = {
  edges: IQualityControlForListType[];
  total?: number;
  cursor?: string;
  dueAt?: string[];
};

type PartnersTypeQuery = {
  qualityControlPartnerList: {
    edges?: {
      id: string;
      name: string;
    }[];
    total?: number;
  };
};

function useQualityControlListAsAdminQuery({
  cursor,
  status,
  partnerIdList,
  sort,
  query,
  dueAt,
  first,
}: {
  cursor: string | null;
  status?: string[];
  partnerIdList?: string[];
  sort?: string;
  query?: string;
  dueAt?: string[];
  first: number;
}) {
  return useQuery<{
    qualityControlListAsAdmin: QualityControlQueryType;
  }>(
    gql`
      query QualityControlListAsAdminQuery(
        $first: Int
        $cursor: String
        $status: [String]
        $query: String
        $partnerIdList: [String]
        $dueAt: [String]
        $sort: QualityControlConnectionSortKeys
      ) {
        qualityControlListAsAdmin(
          first: $first
          cursor: $cursor
          status: $status
          partnerIdList: $partnerIdList
          query: $query
          dueAt: $dueAt
          sort: $sort
        ) {
          edges {
            id
            createdAt
            dueAt
            status
            name
            partner {
              uid
              name
            }
            createdBy {
              uid
            }
            specialist {
              id
              fullName
            }
            criteria {
              edges {
                id
                description
              }
              total
            }
            vertical
            medias {
              edges {
                criteria {
                  edges {
                    id
                  }
                }
              }
              total
            }
          }
          total
          cursor
        }
      }
    `,
    {
      variables: {
        cursor,
        first,
        status,
        partnerIdList: partnerIdList,
        query,
        dueAt,
        sort,
      },
    }
  );
}

// Option objects to use in the filter
const createStatusOptions = Object.entries(qualityControlStatus).map(
  ([value, label]) => ({
    label,
    value,
  })
);

const gqlQualityControlPartnersQuery = gql`
  query QualityControlPartnerList {
    qualityControlPartnerList {
      edges {
        id: uid
        name
      }
      total
    }
  }
`;

const CopyIdField = ({ id }: { id: string }) => {
  const qualityControlIdInputRef = createRef<HTMLInputElement>();

  return (
    <div className="input-group">
      <input
        type="text"
        className="form-control"
        readOnly={true}
        ref={qualityControlIdInputRef}
        value={id}
        style={{ maxWidth: 320, textOverflow: 'ellipsis' }}
      />
      <div className="input-group-append">
        <button
          className="btn btn-primary"
          onClick={() => {
            if (qualityControlIdInputRef.current != null) {
              qualityControlIdInputRef.current.select();
              document.execCommand('copy');
            }
          }}
        >
          <i className="fa fa-copy" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
};

const customStyles = {
  valueContainer: (provided, state) => ({
    ...provided,
    marginLeft: 70,
  }),
};

const createPartnersOptions = (partnerQuery: PartnersTypeQuery | undefined) => {
  if (!partnerQuery) return [];
  return partnerQuery?.qualityControlPartnerList.edges?.map((partnerType) => ({
    label: partnerType.name,
    value: partnerType.id,
  }));
};

// Filters
const MultiSelectFilter = ({
  filterName,
  options,
  location,
  history,
}: {
  filterName: string;
  options: { value: string; label: string }[];
  location: RouteComponentProps['location'];
  history: RouteComponentProps['history'];
}) => {
  const filterString = qs.parse(location.search)[filterName];
  const selectedValues = filterString
    ? ensureString(filterString ?? '')?.split(',')
    : [];

  const selectedOptions =
    selectedValues && options.filter((o) => selectedValues.includes(o.value));

  return (
    <div
      style={{
        minWidth: 150,
        marginRight: 16,
        position: 'relative',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 7,
          left: 10,
          zIndex: 2,
          textTransform: 'capitalize',
          color: '#C1C8D4',
        }}
      >
        {filterName !== 'dueAt' ? filterName : 'Due in'}
      </div>
      <div style={{ minWidth: 100 }}>
        <Select
          isMulti
          value={selectedOptions}
          options={options}
          styles={customStyles}
          placeholder=""
          onChange={(selectedOptions) => {
            const selected = (selectedOptions || []).map((s) => s.value);

            history.push({
              ...location,
              search: qs.stringify({
                ...qs.parse(location.search),
                [filterName]:
                  selected.length > 0 ? selected.join(',') : undefined,
              }),
            });
          }}
        />
      </div>
    </div>
  );
};

function HeaderTableRow({
  location,
}: {
  location: RouteComponentProps['location'];
}) {
  return (
    <tr>
      <th />
      <th style={ELLIPSIS_STYLE} scope="col">
        Job name
      </th>
      <th style={ELLIPSIS_STYLE} scope="col">
        Partner
      </th>
      <th style={ELLIPSIS_STYLE} scope="col">
        Vertical
      </th>
      <th style={ELLIPSIS_STYLE} scope="col">
        Status
      </th>
      <th style={ELLIPSIS_STYLE} scope="col">
        Number of assets
      </th>
      <th style={ELLIPSIS_STYLE} scope="col">
        <OrderByLink attrName="created_at" location={location}>
          Due in
        </OrderByLink>
      </th>
      <th style={ELLIPSIS_STYLE} scope="col">
        QC specialist
      </th>
      <th style={ELLIPSIS_STYLE} scope="col">
        Quality control job ID
      </th>
    </tr>
  );
}

function LoadingTableRows({ rows, cols }: { rows: Number; cols: Number }) {
  return (
    <>
      {Array.from(Array(rows)).map((i, indexRows) => (
        <tr key={indexRows}>
          {Array.from(Array(cols)).map((i, indexCols) => (
            <td key={indexCols}>...</td>
          ))}
        </tr>
      ))}
    </>
  );
}

function FormattedTableRows({
  qualityControlList,
  setSelectedIds,
  selectedIds,
  session,
}: {
  qualityControlList: IQualityControlForListType[] | undefined;
  setSelectedIds: (p) => void;
  selectedIds: string[];
  session: { token: string; uid: string };
}) {
  const [
    qualityControlSpecialistAssign,
    qualityControlSpecialistAssignMutation,
  ] = useMutation<
    {
      qualityControlSpecialistAssign: {
        qualityControlList: {
          edges: {
            id: string;
            specialist: {
              id: string;
            };
          }[];
        };
      };
    },
    { qualityControlListId: string[]; specialistId: string }
  >(
    gql`
      mutation qualityControlSpecialistAssignAtQualityControlView(
        $qualityControlListId: [ID]!
        $specialistId: ID!
      ) {
        qualityControlSpecialistAssign(
          input: {
            qualityControlListId: $qualityControlListId
            specialistId: $specialistId
          }
        ) {
          qualityControlList {
            edges {
              id
              specialist {
                id
              }
            }
          }
        }
      }
    `
  );

  const tableRows = qualityControlList?.map((qualityControlJob) => {
    const firstImageWithCriteriaId =
      qualityControlJob.medias?.edges?.[0]?.criteria?.edges?.[0]?.id;
    return (
      <tr key={qualityControlJob?.id}>
        <td>
          <input
            checked={selectedIds.includes(qualityControlJob?.id)}
            type="checkbox"
            disabled={
              qualityControlJob.status !== QualityControlStatusEnum.paid
            }
            onChange={() =>
              setSelectedIds((prev) =>
                prev.includes(qualityControlJob?.id)
                  ? prev.filter((id) => id !== qualityControlJob?.id)
                  : [...prev, qualityControlJob?.id]
              )
            }
          />
        </td>
        <td>{qualityControlJob?.name}</td>
        <td>{qualityControlJob?.partner.name}</td>
        <td>{qualityControlJob?.vertical}</td>
        <td>
          <span
            className="badge badge-primary text-uppercase"
            style={badgeBgColorStatus(qualityControlJob?.status ?? '')}
          >
            {qualityControlStatus[qualityControlJob?.status]}
          </span>
        </td>
        <td>{qualityControlJob?.medias?.total}</td>
        <td
          style={{
            color:
              moment(qualityControlJob?.dueAt) < moment() &&
              qualityControlJob.status !== 'completed'
                ? 'red'
                : '',
          }}
        >
          {qualityControlJob?.dueAt != null
            ? moment(qualityControlJob?.dueAt).fromNow()
            : '-'}
        </td>
        <td>
          {qualityControlJob?.specialist?.fullName != null ? (
            qualityControlJob?.specialist?.fullName
          ) : qualityControlJob.status === QualityControlStatusEnum.paid ? (
            <button
              type="button"
              className={'btn btn-primary mr-2'}
              onClick={async () => {
                await qualityControlSpecialistAssign({
                  variables: {
                    qualityControlListId: [qualityControlJob.id],
                    specialistId: session.uid,
                  },
                });
              }}
              disabled={qualityControlSpecialistAssignMutation.loading === true}
            >
              Claim
            </button>
          ) : null}
        </td>
        <td>
          <div style={{ display: 'flex' }}>
            <CopyIdField id={qualityControlJob?.id} />
            <div className="btn btn-dark">
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to={
                  qualityControlJob?.status ===
                    QualityControlStatusEnum.completed &&
                  firstImageWithCriteriaId != null
                    ? `quality-control-view/${qualityControlJob.id}/gallery?selectedTab=ALL`
                    : `quality-control-view/${qualityControlJob.id}/details?id=${firstImageWithCriteriaId}`
                }
              >
                <i className="fa fa-external-link" aria-hidden="true" />
              </Link>
            </div>
          </div>
        </td>
      </tr>
    );
  });
  return <>{tableRows}</>;
}

function DataRowsChunk({
  cursor,
  onMore,
  selectedStatus,
  partnerIdList,
  sortByKey,
  search,
  dueAt,
  setSelectedIds,
  selectedIds,
  session,
}: {
  cursor: string | null;
  onMore?: (p: { nextCursor: string }) => void;
  selectedStatus?: string[];
  partnerIdList?: string[];
  sortByKey?: string;
  search?: string;
  dueAt?: string[];
  setSelectedIds: (p) => void;
  selectedIds: string[];
  session: { token: string; uid: string };
}) {
  const qualityControlQuery = useQualityControlListAsAdminQuery({
    cursor: cursor,
    first: cursor === null ? 20 : 40,
    status: selectedStatus,
    partnerIdList: partnerIdList,
    query: search,
    dueAt,
    sort: sortByKey,
  });

  const qualityControlList =
    qualityControlQuery.data?.qualityControlListAsAdmin.edges;
  const contentNotReady =
    qualityControlList === null || qualityControlQuery.loading;
  const nextCursor =
    qualityControlQuery.data?.qualityControlListAsAdmin?.cursor;

  return (
    <>
      {onMore == null || nextCursor == null ? null : (
        <InView>
          {({ inView, ref }) => (
            <tr ref={ref}>
              {inView && <Component2 didMount={() => onMore({ nextCursor })} />}
            </tr>
          )}
        </InView>
      )}
      {contentNotReady ? (
        <LoadingTableRows rows={7} cols={7} />
      ) : (
        <FormattedTableRows
          qualityControlList={qualityControlList}
          setSelectedIds={setSelectedIds}
          selectedIds={selectedIds}
          session={session}
        />
      )}
      {onMore == null || nextCursor == null ? null : (
        <InView>
          {({ inView, ref }) => (
            <tr ref={ref}>
              {inView && <Component2 didMount={() => onMore({ nextCursor })} />}
            </tr>
          )}
        </InView>
      )}
    </>
  );
}

export function QualityControlView({
  location,
  history,
  session,
}: {
  location: RouteComponentProps['location'];
  history: RouteComponentProps['history'];
  session: { token: string; uid: string };
}) {
  const parsedSearch = qs.parse(location.search);
  // State

  const partnersQuery = useQuery<PartnersTypeQuery>(
    gqlQualityControlPartnersQuery
  );

  const [selectedIds, setSelectedIds] = React.useState<string[]>([]);

  const statusOptions = createStatusOptions;
  const partnerOptions = createPartnersOptions(partnersQuery.data);

  const selectedStatus = React.useMemo(
    () =>
      ensureString(qs.parse(location.search)[STATUS] ?? null)?.split(',') ?? [],
    [location]
  );

  const partnerIdList = React.useMemo(
    () =>
      ensureString(qs.parse(location.search)[PARTNERS] ?? null)?.split(',') ??
      [],
    [location]
  );

  const selectedDueAt = React.useMemo(
    () =>
      ensureString(qs.parse(location.search)['dueAt'] ?? null)?.split(',') ??
      [],
    [location]
  );

  const sortByCreatedAt =
    ensureString(parsedSearch['ordering']) == null
      ? 'CREATED_AT_DESC'
      : ensureString(parsedSearch['ordering']) === '-created_at'
      ? 'CREATED_AT_DESC'
      : 'CREATED_AT';

  const searchTerm = ensureString(parsedSearch['q']);

  const [cursors, setCursors] = React.useState<string[]>([]);

  React.useEffect(() => {
    setCursors([]);
  }, [selectedStatus, partnerIdList, selectedDueAt]);

  const AssignSpecialistButton = ({
    location,
    selectedIds,
    session,
  }: {
    location: RouteComponentProps['location'];
    selectedIds: string[];
    session: { token: string; uid: string };
  }) => (
    <React.Fragment>
      <Link
        to={locationForModal({
          location,
          modal: { modalName: MODAL_NAME_ASSIGN_SPECIALIST },
        })}
        className={
          'btn btn-secondary mr-2' +
          (selectedIds.length === 0 ? ' disabled' : '')
        }
        replace
      >
        Assign specialist
      </Link>

      <ModalRoute modalName={MODAL_NAME_ASSIGN_SPECIALIST}>
        {({ location, history }) => (
          <AssignSpecialistModal
            selectedIds={selectedIds}
            onDismiss={() =>
              Promise.resolve().then(() =>
                history.push(locationForModal({ location, modal: undefined }))
              )
            }
          />
        )}
      </ModalRoute>
    </React.Fragment>
  );

  const RemoveSpecialistButton = ({
    location,
    selectedIds,
    session,
  }: {
    location: RouteComponentProps['location'];
    selectedIds: string[];
    session: { token: string; uid: string };
  }) => (
    <React.Fragment>
      <Link
        to={locationForModal({
          location,
          modal: { modalName: MODAL_NAME_REMOVE_SPECIALIST },
        })}
        className={
          'btn btn-danger mr-2' + (selectedIds.length === 0 ? ' disabled' : '')
        }
        replace
      >
        Remove specialist
      </Link>

      <ModalRoute modalName={MODAL_NAME_REMOVE_SPECIALIST}>
        {({ location, history }) => (
          <RemoveSpecialistModal
            selectedIds={selectedIds}
            onDismiss={() =>
              Promise.resolve().then(() =>
                history.push(locationForModal({ location, modal: undefined }))
              )
            }
          />
        )}
      </ModalRoute>
    </React.Fragment>
  );

  return (
    <section>
      <h2>Workflows QC Queue</h2>
      <ContentWrapper>
        <Component2
          didMount={() => {
            const shouldRedirectOnMount =
              parsedSearch.filter_editing_status === undefined;

            if (shouldRedirectOnMount) {
              return history.replace({
                ...location,
                search: qs.stringify({
                  ...parsedSearch,

                  status: parsedSearch.status || 'paid',
                }),
              });
            }
          }}
        />

        <div className="table-responsive">
          <div
            style={{
              margin: 8,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ margin: 8, display: 'flex' }}>
              <MultiSelectFilter
                filterName={STATUS}
                options={statusOptions}
                location={location}
                history={history}
              />
              {partnerOptions != null && partnerOptions.length > 0 ? (
                <MultiSelectFilter
                  filterName={PARTNERS}
                  options={partnerOptions}
                  location={location}
                  history={history}
                />
              ) : null}
              <MultiSelectFilter
                filterName={'dueAt'}
                options={dueAtOptions}
                location={location}
                history={history}
              />

              <SearchField
                onSearch={({ q }) =>
                  history.push({
                    ...location,
                    search: qs.stringify({
                      ...qs.parse(location.search),
                      q: q || undefined,
                    }),
                  })
                }
                defaultValue={qs.parse(location.search).q || ''}
              />
            </div>

            <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
              <RemoveSpecialistButton
                location={location}
                selectedIds={selectedIds}
                session={session}
              />

              <AssignSpecialistButton
                location={location}
                selectedIds={selectedIds}
                session={session}
              />
            </div>
          </div>

          <table className="table" style={{ position: 'relative' }}>
            <thead style={{ color: '#71767E' }}>
              <HeaderTableRow location={location} />
            </thead>
            <tbody style={{ color: '#6C757D' }}>
              {[null, ...cursors].map((cursor, index) => (
                <DataRowsChunk
                  key={cursor ?? '1'}
                  cursor={cursor}
                  selectedStatus={selectedStatus}
                  partnerIdList={partnerIdList}
                  search={searchTerm}
                  sortByKey={sortByCreatedAt}
                  dueAt={selectedDueAt}
                  setSelectedIds={setSelectedIds}
                  selectedIds={selectedIds}
                  onMore={
                    index === cursors.length
                      ? ({ nextCursor }) =>
                          setCursors((state) =>
                            state.includes(nextCursor)
                              ? state
                              : [...state, nextCursor]
                          )
                      : undefined
                  }
                  session={session}
                />
              ))}
            </tbody>
          </table>
        </div>
      </ContentWrapper>
    </section>
  );
}
