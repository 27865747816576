import React, { useState, useEffect, useMemo } from 'react';
import Dropzone from 'react-dropzone';
import qs from 'query-string';
import { Link, Redirect, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment-timezone';
import Component2 from '@reach/component-component';
import Textarea from 'react-textarea-autosize';
import Tooltip from 'rc-tooltip';
import ReactImageMagnify from 'react-image-magnify';

import {
  apiFetch,
  checkIfPhotoReviewer,
  checkIfSuperAdmin,
  checkIfCanAccess,
} from '../../../utils';
import { ensureString, multiline } from '../../../utils/strings';
import { API_URL } from '../../../config';
import {
  ANGLE_OPTIONS,
  BACKGROUND_OPTIONS,
  BOOKING_STATUS,
  SHOOTING_ELEMENTS_OPTIONS,
  SIZE_OPTIONS,
  STYLE_OPTIONS,
} from '../../../constants';
import { BookingStatusBadge } from '../../../components/BookingStatusBadge';
import { PromiseButton } from '../../../components/PromiseButton';
import { LoadingSpinnerCentered } from '../../../components/LoadingSpinner';
import { DeleteGalleryButton } from '../components/DeleteGalleryButton';
import {
  UploadImagePreview,
  isVideo,
  isVideoByFileName,
} from '../../../components/UploadManager';
import { ImageRowBase } from './components/ImageRowBase';
import { locationForModal } from '../../../components/ModalRoute';
import { MODAL_NAME_RATE_GALLERY } from './components/RateGallery';
import { gql, useMutation, useQuery } from '../../../components/ApolloClient';
import { IFetcher, useFetch } from '../../../components/useFetch';
import { MasonryGallery } from '../components/MasonryGallery';
import { Modal } from '../../../components/Modal';
import { BottomControls, KeyboardControlEvents } from '../components/Slider';
import leftArrow from '../../../components/img/white-left-arrow.png';
import rightArrow from '../../../components/img/white-right-arrow.png';
import {
  MODAL_NAME_REPORT_EDITING_ISSUES,
  ReportEditingIssues,
} from './components/ReportEditingIssues';
import { formatBytes } from '../../quality-control/utils';
import { useUploadManager } from '../../../components/useUploadManager';
import { usePromise } from '../../../components/usePromise';

function filterSelectedImageIds({
  selectedImageIds,
  imagesWithFeedback,
}: {
  selectedImageIds: string[];
  imagesWithFeedback: useSubmissionFeedbackManagerReturnType['images'];
}) {
  return selectedImageIds.filter((uid) =>
    imagesWithFeedback.find((i) => i.image.media_uid === uid)
  );
}

const SubmissionImageRow = ({
  item: {
    isUpload,
    isHero,
    isVideo,

    preSignedUrlPromise,
    uploadPromise,
    mediaCreationPromise,

    onRestart,
    axiosCancelSource,

    fileSize,
    sizeUploaded,

    uid,
    fileName,
    previewUrl,
    largeUrl,
    downloadUrl,
    candidate1Url,
    file,

    Feedback,
    reviewerMode,
    previous_feedback,
    getFeedbackTextareaProps,
    getFileNameInputProps,
    width,
    height,
  },
  onDeleteFromGallery,
  hideDelete = false,

  toggleImageSelected,
  imageSelected,
  onSetAsHero,
  focusedImageId,
}) => {
  return (
    <ImageRowBase
      isUpload={isUpload}
      sizeUploaded={sizeUploaded}
      fileSize={fileSize}
      preSignedUrlPromise={preSignedUrlPromise}
      uploadPromise={uploadPromise}
      mediaCreationPromise={mediaCreationPromise}
    >
      {({
        status,
        progress,

        preSignedUrlReady,
        uploadError,
        uploadPending,
      }) => (
        <tr>
          <td>
            <input
              type="checkbox"
              disabled={(!hideDelete && !isUpload) === false}
              checked={imageSelected}
              onChange={() => toggleImageSelected()}
            />
          </td>

          <td>
            {isVideo ? null : (
              <button
                className="btn btn-link text-warning pt-0"
                onClick={onSetAsHero}
              >
                {isHero ? (
                  <i className="fa fa-star" aria-hidden="true" />
                ) : (
                  <i className="fa fa-star-o" aria-hidden="true" />
                )}
              </button>
            )}
          </td>

          <td>
            {isVideo ? (
              <video width="300" height="200" controls>
                <source src={downloadUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : isUpload ? (
              <UploadImagePreview
                file={file}
                alt="preview"
                width="300"
                height="200"
                style={{ objectFit: 'contain' }}
              />
            ) : (
              <a href={largeUrl} target="_blank" rel="noopener noreferrer">
                <img
                  src={previewUrl}
                  alt="preview"
                  width="300"
                  height="200"
                  style={{ objectFit: 'contain' }}
                />
              </a>
            )}
          </td>

          <td>
            {candidate1Url && (
              <a href={candidate1Url} target="_blank" rel="noopener noreferrer">
                <img
                  src={candidate1Url}
                  alt="preview"
                  width="300"
                  height="200"
                  style={{ objectFit: 'contain' }}
                />
              </a>
            )}
          </td>

          <td>
            {isUpload ? (
              fileName
            ) : (
              <input
                {...getFileNameInputProps({
                  className: 'form-control',
                  style: { width: 200 },
                })}
              />
            )}
            <span>Size: {formatBytes({ bytes: fileSize })}</span>

            <br />

            <span>
              Dimensions: {width} x {height}
            </span>
          </td>

          <td>
            {status}

            <span
              style={{
                width: '5rem',
                textAlign: 'right',
                fontFamily: 'Arial',
              }}
            >
              {progress}
            </span>
          </td>

          <td>
            {isUpload ? null : (
              <>
                {previous_feedback != null && (
                  <Tooltip
                    placement="right"
                    overlay={multiline(previous_feedback)}
                  >
                    <span className="mb-2 d-inline-block">
                      Previous feedback{' '}
                      <i className="fa fa-sticky-note text-primary" />
                    </span>
                  </Tooltip>
                )}

                <Textarea
                  {...getFeedbackTextareaProps({
                    className: 'form-control',
                    maxRows: 8,
                    style: { width: 200, background: 'none' },
                    autoFocus: uid === focusedImageId ? true : false,
                  })}
                />
              </>
            )}
          </td>

          <td className="text-right">
            {uploadError ? (
              <button className="btn btn-link btn-sm py-0" onClick={onRestart}>
                Restart
              </button>
            ) : null}

            {preSignedUrlReady && uploadPending && (
              <button
                className="btn btn-link text-danger btn-sm py-0"
                onClick={() => axiosCancelSource.cancel()}
              >
                Cancel
              </button>
            )}

            {!hideDelete && !isUpload && (
              <button
                className="btn btn-link text-danger btn-sm py-0"
                onClick={() => {
                  if (
                    window.confirm(
                      `Do you want to remove this ${
                        isVideo ? 'video' : 'image'
                      }?`
                    )
                  ) {
                    return onDeleteFromGallery();
                  }
                }}
              >
                Delete
              </button>
            )}
          </td>
        </tr>
      )}
    </ImageRowBase>
  );
};

function submissionsMerger({ imagesWithFeedback, uploadsList }) {
  return [
    ...imagesWithFeedback.map(
      ({
        image: {
          media_uid,
          gallerythumb_url,
          lightbox_url,
          public_gallery_thumb,
          large_gallery_thumb,
          download_url,
          candidate_1_url,
          file_name: fileName,
          previous_feedback,
          hero,
          width,
          height,
          file_size,
        },
        reviewerMode,
        Feedback,
        getFeedbackTextareaProps,
        getFileNameInputProps,
        file,
      }) => ({
        uid: media_uid,
        fileName,
        previewUrl: gallerythumb_url,
        midUrl: public_gallery_thumb,
        largeUrl: large_gallery_thumb || lightbox_url,
        downloadUrl: download_url,
        previous_feedback,
        width,
        height,
        fileSize: file_size,

        candidate1Url: candidate_1_url,

        isUpload: false,
        isHero: hero,
        isVideo: isVideoByFileName(fileName),

        file,
        reviewerMode,
        Feedback,
        getFeedbackTextareaProps,
        getFileNameInputProps,
      })
    ),
    ...uploadsList.map(({ clientId, previewUrl, fileName, file, ...rest }) => ({
      ...rest,
      uid: clientId,
      fileName,
      isUpload: true,
      file,
      isVideo: isVideo(file),
    })),
  ];
}

const NoHoverLink = styled(Link)`
  :hover {
    text-decoration: none;
  }
`;

type useSubmissionFeedbackManagerReturnType = ReturnType<
  typeof useSubmissionFeedbackManager
>;
function useSubmissionFeedbackManager({
  images = [],
  bookingId,
  submissionId,
  session: { token },
  onReload,
  reviewerMode,
}: {
  images?: {
    media_uid: string;
    booking_submission_media_uid: string;
    file_name: string;
    feedback?: string;
    previous_feedback?: string;
    hero: boolean;
  }[];
  bookingId;
  submissionId;
  session;
  onReload;
  reviewerMode: boolean;
}) {
  const [imagesFeedback, setImagesFeedback] = useState({});
  const [imagesFileName, setImagesFileName] = useState({});

  const saveFileNamePromise = usePromise();
  const saveFeedbackPromise = usePromise();

  useEffect(() => {
    setImagesFeedback((state) =>
      (images || []).reduce(
        (
          acc,
          { booking_submission_media_uid, feedback, previous_feedback }
        ) => ({
          ...acc,
          [booking_submission_media_uid]: { feedback, previous_feedback },
        }),
        state
      )
    );

    setImagesFileName((state) =>
      (images || []).reduce(
        (acc, { booking_submission_media_uid, file_name }) => ({
          ...acc,
          [booking_submission_media_uid]: { file_name },
        }),
        state
      )
    );
  }, [images]);

  const modifiedImages = images.filter(
    (image) =>
      (imagesFeedback[image.booking_submission_media_uid] || {}).feedback !==
      image.feedback
  );

  const modifiedImagesFileNames = images.filter(
    (image) =>
      (imagesFileName[image.booking_submission_media_uid] || {}).file_name !==
      image.file_name
  );

  return {
    hasUnsavedChanges: modifiedImages.length > 0,
    hasUnsavedFileNameChanges: modifiedImagesFileNames.length > 0,

    onSaveFeedback: () => {
      const promise = apiFetch(
        `/api/v2/bookings/${bookingId}/submissions/${submissionId}/feedback`,
        {
          token,
          method: 'PUT',
          body: JSON.stringify({
            booking_submission_media: modifiedImages.map((image) => ({
              uid: image.booking_submission_media_uid,
              feedback: (
                imagesFeedback[image.booking_submission_media_uid] || {}
              ).feedback,
            })),
          }),
        }
      ).then(() => onReload());

      saveFeedbackPromise.setPromise(promise);

      return promise;
    },

    onSaveFileNames: () => {
      const promise = apiFetch(
        `/api/v2/bookings/${bookingId}/submissions/${submissionId}/medias/file-name`,
        {
          token,
          method: 'PATCH',
          body: JSON.stringify({
            media: modifiedImagesFileNames.map((image) => {
              return {
                uid: image.media_uid,
                file_name: (
                  imagesFileName[image.booking_submission_media_uid] || {}
                ).file_name,
              };
            }),
          }),
        }
      ).then(() => onReload());

      saveFileNamePromise.setPromise(promise);

      return promise;
    },

    images: images.map((image) => {
      const clonedImage =
        imagesFeedback[image.booking_submission_media_uid] || {};

      const clonedImageFileName =
        imagesFileName[image.booking_submission_media_uid] || {};

      return {
        image,
        clonedImage,

        reviewerMode,
        getFeedbackTextareaProps: ({ className = '', ...props } = {}) => ({
          ...props,
          className:
            className +
            (modifiedImages.find(
              (modifiedImage) =>
                image.booking_submission_media_uid ===
                modifiedImage.booking_submission_media_uid
            )
              ? ' is-valid'
              : ''),

          value: clonedImage.feedback || '',
          onChange: ({ target: { value } }) =>
            setImagesFeedback((state) => ({
              ...state,
              [image.booking_submission_media_uid]: {
                ...clonedImage,
                feedback: value === '' ? null : value,
              },
            })),
          readOnly: reviewerMode === false,
          disabled: saveFeedbackPromise.isPending,
        }),

        getFileNameInputProps: ({ className = '', ...props } = {}) => ({
          ...props,
          className:
            className +
            (modifiedImagesFileNames.find(
              (modifiedImage) =>
                image.booking_submission_media_uid ===
                modifiedImage.booking_submission_media_uid
            )
              ? ' is-valid'
              : ''),

          value: clonedImageFileName.file_name || '',
          onChange: ({ target: { value } }) =>
            setImagesFileName((state) => ({
              ...state,
              [image.booking_submission_media_uid]: {
                ...clonedImageFileName,
                file_name: value === '' ? null : value,
              },
            })),
          disabled: saveFileNamePromise.isPending,
        }),
      };
    }),
  };
}

const MAX_BYTES_PER_IMAGE = 2e6;

function TopBar({
  history,
  location,

  bookingFetch: {
    reload: bookingReload,
    result: booking,
    result: { uid: bookingId },
  },

  bookingGallery,
  bookingGalleryReload,

  session: { token },
  session,

  submissionFetch: {
    isPending: submissionPending,
    result: submission,
    reload: submissionReload,
  },

  submissionsListFetch: {
    isPending: submissionsListPending,
    reload: submissionsListReload,
    result: { results: submissionsList } = {},
  },

  reviewerMode,
  imagesWithFeedback,

  selectedImageIds,
  isMediaUploading,
}: {
  history: RouteComponentProps['history'];
  location: RouteComponentProps['location'];

  bookingFetch;
  bookingGallery;
  bookingGalleryReload;
  session;
  submissionFetch: IFetcher<{
    uid: string;
    submitted_at?: string;
    reviewed_at?: string;
    raw_previews_created_at?: string;
  }>;
  submissionsListFetch: IFetcher<{
    results?: {
      uid: string;
      raw_previews_created_at?: string;
    }[];
  }>;
  reviewerMode: boolean;
  imagesWithFeedback: useSubmissionFeedbackManagerReturnType['images'];
  selectedImageIds: string[];
  isMediaUploading: boolean;
}) {
  const notifyBookingPromise = usePromise();

  const contractorMode =
    session.user.IAM.length === 1 &&
    session.user.IAM[0] === 'contractorEditingTaskQueue';

  const submissionId = submission?.uid;

  const editorSubmissions = (submissionsList ?? []).filter(
    (submission) => submission.raw_previews_created_at == null
  );

  const { loading, data } = useQuery<
    {
      booking: { id: string; rawPreviews: { cursor: string; total: number } };
    },
    { bookingId: string }
  >(
    gql`
      query RAWPhotoPreviewGetAllFromSubmission($bookingId: ID!) {
        booking: bookingById(id: $bookingId) {
          id
          rawPreviews(first: 200) {
            cursor
            total
          }
        }
      }
    `,
    { variables: { bookingId } }
  );

  const rawPreviews = data?.booking?.rawPreviews.total;

  return submission == null ? null : (
    <div className="d-flex align-items-center justify-content-between">
      <h3>
        <NoHoverLink
          to={
            contractorMode
              ? `/contractor-editing-queue`
              : `/bookings/${bookingId}`
          }
        >
          <i className="fa fa-angle-left text-muted" aria-hidden="true" />{' '}
        </NoHoverLink>{' '}
        {submission.raw_previews_created_at != null
          ? 'RAW Previews'
          : 'Gallery Submission '}
        {submissionsListPending ||
        submissionPending ||
        submission.raw_previews_created_at != null
          ? ''
          : (editorSubmissions || []).findIndex((s) => s.uid === submissionId) +
            1}
        {(bookingGallery || null) && (
          <span>
            {' '}
            ({bookingGallery.length}{' '}
            {bookingGallery.length === 1 ? 'photo' : 'photos'})
          </span>
        )}{' '}
        <small className="align-self-center">
          <BookingStatusBadge status={booking.status} />
        </small>
      </h3>

      <div className="d-flex align-items-center">
        {rawPreviews != null && rawPreviews > 0 && (
          <Link
            className="btn btn-outline-secondary mr-3"
            to={`/bookings/${bookingId}/raw-photo-preview`}
          >
            Show raw previews
          </Link>
        )}

        {contractorMode ? null : (
          <Link
            className="btn btn-primary mr-3"
            to={locationForModal({
              location,
              modal: {
                modalName: MODAL_NAME_RATE_GALLERY,
                currentBookingId: bookingId,
              },
            })}
          >
            Rate Gallery
          </Link>
        )}
        <a
          className="btn btn-outline-primary mr-3"
          href={`${API_URL}/api/v2/bookings/${bookingId}/submissions/${submissionId}/photos/download?${qs.stringify(
            { access_token: token }
          )}`}
        >
          Download all files
        </a>

        <a
          className="btn btn-outline-primary mr-3"
          href={`${API_URL}/api/v2/bookings/${bookingId}/submissions/${submissionId}/photos/download?${qs.stringify(
            { access_token: token, max_bytes_per_image: MAX_BYTES_PER_IMAGE }
          )}`}
        >
          Download all compressed
        </a>

        {booking.status === 'paid' ? (
          submission.submitted_at != null ? (
            <>
              {(reviewerMode || submission.reviewed_at != null) && (
                <PromiseButton
                  className="btn btn-primary"
                  onClick={() =>
                    apiFetch(`/api/v2/bookings/${bookingId}/submissions`, {
                      token,
                      method: 'POST',
                      body: JSON.stringify({
                        base_submission_uid: submissionId,
                      }),
                    })
                      .then(({ uid }) =>
                        history.replace({
                          ...location,
                          search: qs.stringify({
                            ...qs.parse(location.search),
                            submission_uid: uid,
                          }),
                        })
                      )
                      .then(() => submissionsListReload())
                  }
                >
                  Submit new photos
                </PromiseButton>
              )}

              {submission.raw_previews_created_at == null && (
                <span
                  className="ml-3"
                  title={moment(submission.submitted_at).format()}
                >
                  Submitted for review{' '}
                  {moment(submission.submitted_at).fromNow()}
                </span>
              )}
            </>
          ) : (
            <>
              <PromiseButton
                className="btn btn-danger mr-2"
                disabled={(imagesWithFeedback || []).length === 0}
                onClick={() =>
                  Promise.resolve().then(
                    async () =>
                      window.confirm(
                        selectedImageIds.length <= 0
                          ? 'Do you want to delete the full gallery?'
                          : `Do you want to delete ${
                              selectedImageIds.length
                            } photo${selectedImageIds.length === 1 ? '' : 's'}?`
                      ) &&
                      apiFetch(
                        `/api/v2/bookings/${bookingId}/submissions/${submissionId}/photos`,
                        {
                          token,
                          method: 'DELETE',
                          body: JSON.stringify({
                            media_uids:
                              selectedImageIds.length <= 0
                                ? imagesWithFeedback.map(
                                    ({ image }) => image.media_uid
                                  )
                                : selectedImageIds,
                          }),
                        }
                      ).then(() =>
                        Promise.all([
                          submissionReload(),
                          bookingGalleryReload(),
                        ])
                      )
                  )
                }
              >
                {selectedImageIds.length <= 0
                  ? 'Delete all photos'
                  : `Delete ${selectedImageIds.length} photo${
                      selectedImageIds.length === 1 ? '' : 's'
                    }`}
              </PromiseButton>

              <PromiseButton
                className="btn btn-primary"
                onClick={async () => {
                  if (
                    imagesWithFeedback.find((i) => i.image.hero === true) ==
                    null
                  ) {
                    return alert(
                      'Please select a Hero image before submitting (by using the ⭐️)'
                    );
                  }

                  await apiFetch(
                    `/api/v2/bookings/${bookingId}/submissions/${submissionId}/submit-for-review`,
                    { token, method: 'POST' }
                  ).then(() => submissionReload());
                }}
                pendingChildren="Submitting..."
              >
                Submit for Review
              </PromiseButton>
            </>
          )
        ) : booking.status === 'completed' ? (
          <button
            className="btn btn-primary"
            style={{ width: 200 }}
            disabled={
              isMediaUploading === true ||
              notifyBookingPromise.isPending ||
              notifyBookingPromise.result != null
            }
            onClick={() =>
              notifyBookingPromise.setPromise(
                apiFetch(
                  `/api/v2/admin/bookings/${bookingId}/notify-photos-uploaded`,
                  { token, method: 'POST' }
                ).then(() => bookingReload())
              )
            }
          >
            {isMediaUploading === true
              ? 'Notify (media uploading...)'
              : notifyBookingPromise.result != null
              ? 'Notified'
              : notifyBookingPromise.isPending
              ? 'Notify...'
              : 'Notify media added'}
          </button>
        ) : null}

        <DeleteGalleryButton
          session={session}
          booking={booking}
          location={location}
          onReload={() =>
            Promise.all([bookingReload(), bookingGalleryReload()])
          }
        />
      </div>
    </div>
  );
}

export function SubmissionsGalleryUploadRouteInner({
  history,
  location,
  session,
  session: { token },

  bookingFetch,
  bookingFetch: {
    result: booking,
    result: { uid: bookingId },
    reload: bookingReload,
  },

  submissionId,
  submissionFetch,
  submissionFetch: {
    isPending: submissionPending,
    result: submission,
    reload: submissionReload,
  },

  submissionsListFetch,
  submissionsListFetch: {
    isPending: submissionListPending,
    result: { results: submissionList } = {},
    error: submissionListError,
  },
}: {
  session;
  bookingFetch;
  submissionId;
  submissionFetch: IFetcher<{
    uid: string;
    submitted_at?: string;
    reviewed_at?: string;
    feedback?: string;
    previous_feedback?: string;
    main_issue?: string;
    previous_main_issue?: string;
    other_issues?: string;
    previous_other_issues?: string;
    submitted_by_photographer?: boolean;
    delivery_status?: {
      status: string;
      createdAt: string;
      createdBy: string;
    };
  }>;
  submissionsListFetch: IFetcher<{
    results?: {
      uid: string;
      raw_previews_created_at?: string;
    }[];
  }>;
} & RouteComponentProps) {
  const reviewerMode =
    checkIfPhotoReviewer({ session }) || checkIfSuperAdmin({ session });

  const submissionListReversed = [...(submissionList || [])].reverse();

  const [selectedImageIds, setSelectedImageIds] = React.useState<string[]>([]);
  const [deliveryQAStatus, setDeliveryQAStatus] = React.useState<string>('');

  useEffect(() => {
    setDeliveryQAStatus(submission?.delivery_status?.status ?? '');
  }, [submission?.delivery_status?.status]);

  const contractorMode = checkIfCanAccess(
    session,
    'contractorEditingTaskQueue'
  );

  const {
    result: { results: bookingGallery } = {},
    reload: bookingGalleryReload,
  } = useFetch<{ results: any }>({
    urlToFetch: `/api/v2/bookings/${bookingId}/submissions/${submissionId}/photos`,
    session,
  });

  const { addFiles, uploadsList } = useUploadManager({
    token: session.token,
    onUploadReady: ({ fileName, mediaUid, remove, isVideo }) =>
      apiFetch('/api/v2/media/create-from-temp', {
        token,
        method: 'POST',
        body: JSON.stringify({
          tempMediaUid: mediaUid,
          fileName,
          isVideo,
        }),
      })
        .then(() =>
          apiFetch(
            `/api/v2/bookings/${bookingId}/submissions/${submissionId}/${
              isVideo ? 'videos' : 'photos'
            }`,
            {
              token,
              method: 'POST',
              body: JSON.stringify({
                media_uid: mediaUid,
              }),
            }
          )
        )
        .then(() => bookingGalleryReload())
        .then(() => remove()),
  });

  const {
    images: imagesWithFeedback,
    onSaveFeedback,
    onSaveFileNames,
    hasUnsavedFileNameChanges,
  } = useSubmissionFeedbackManager({
    images: bookingGallery,
    bookingId,
    submissionId,
    session,
    reviewerMode,
    onReload: () => Promise.all([bookingGalleryReload(), submissionReload()]),
  });

  const mergedMedias = useMemo(
    () =>
      submissionsMerger({
        imagesWithFeedback: imagesWithFeedback || [],
        uploadsList,
      }),
    [imagesWithFeedback, uploadsList]
  );
  const mediaUploading = useMemo(
    () => mergedMedias.filter((upload) => upload.isUpload).length > 0,
    [mergedMedias]
  );

  const { data: uploadData } = useQuery(
    gql`
      query VideoUploadData($bookingId: ID!) {
        bookingById(id: $bookingId) {
          id
          rawPhotosSubmittedAtAsAdmin
          videoUploadData {
            id
            folderId
          }
        }
      }
    `,
    {
      variables: { bookingId: booking.uid },
    }
  );

  const videoEnabled = uploadData?.bookingById?.videoUploadData != null;

  const query = useQuery<{
    bookingById?: {
      id: string;
      partner: {
        uid: string;
        pilotModeEnabled: boolean;
        enforceEditingQAEnabled: boolean;
      };
    };

    editingComplianceIssueOptionsList: {
      value: string;
      label: string;
    }[];
  }>(
    gql`
      query GetBookingPartnerByBookingIdForDeliveryQA($bookingId: ID!) {
        bookingById(id: $bookingId) {
          id

          partner {
            uid
            pilotModeEnabled
            enforceEditingQAEnabled
          }
        }

        editingComplianceIssueOptionsList {
          value
          label
        }
      }
    `,
    {
      variables: { bookingId },
      skip: bookingId == null,
    }
  );

  const complianceIssueOptions = query.data?.editingComplianceIssueOptionsList;

  const bookingPartnerInPilotMode =
    query.data?.bookingById?.partner?.pilotModeEnabled === true;

  const partnerInEnforceQAMode =
    query.data?.bookingById?.partner?.enforceEditingQAEnabled === true;

  const requiresQualityControl =
    bookingPartnerInPilotMode === true || partnerInEnforceQAMode === true;

  const deliveryQAStatusOptions = [
    {
      value: 'pending',
      label: 'Pending',
    },
    {
      value: 'approved',
      label: 'Approved',
    },
    {
      value: 'rejected',
      label: 'Rejected',
    },
  ];

  const [bookingSubmissionDeliveryQAStatusUpdate] = useMutation<{
    bookingSubmissionDeliveryStatusUpdateAsAdmin: {
      bookingSubmission: { id: string };
    };
  }>(
    gql`
      mutation bookingSubmissionDeliveryQAStatusUpdate(
        $bookingSubmissionId: ID!
        $status: String!
      ) {
        bookingSubmissionDeliveryStatusUpdateAsAdmin(
          input: { bookingSubmissionId: $bookingSubmissionId, status: $status }
        ) {
          bookingSubmission {
            id
          }
        }
      }
    `
  );

  const bookingDetailsQuery = useQuery<{
    bookingById?: {
      id: string;
      notesToEditor?: string;
      complianceIssuesNotes?: string;

      ecommerceData?: {
        shootingElements?: string[];
        style?: string;
        background?: string;
        size?: string;
        numberOfUniqueItems?: number;
        angles?: string[];
        numberOfMacroShots?: number;
        numberOfOtherShots?: number;
      };
      referenceImages?: {
        edges?: {
          id: string;
          small?: {
            url: string;
          };
        }[];
      };
    };
  }>(
    gql`
      query BookingExtraDetailsSubmissionsPageQuery($bookingId: ID!) {
        bookingById(id: $bookingId) {
          id
          complianceIssuesNotes
          notesToEditor

          ecommerceData {
            id
            shootingElements
            style
            background
            size
            numberOfUniqueItems
            angles
            numberOfMacroShots
            numberOfOtherShots
          }
          referenceImages {
            edges {
              id
              small {
                url
              }
            }
          }
        }
      }
    `,
    {
      variables: { bookingId: booking.uid },
      skip: booking.uid == null,
    }
  );

  const ecommerceData = bookingDetailsQuery.data?.bookingById?.ecommerceData;
  const bookingDetailsData = bookingDetailsQuery.data?.bookingById;
  const referenceImages =
    bookingDetailsQuery.data?.bookingById?.referenceImages?.edges;

  const angles = ecommerceData?.angles
    ?.map((element) => {
      return element === 'macro'
        ? `${ecommerceData?.numberOfMacroShots}
        ${ANGLE_OPTIONS[element]?.name}`
        : element === 'other'
        ? `${ecommerceData?.numberOfOtherShots} :
        ${ANGLE_OPTIONS[element]?.name}`
        : `1 ${ANGLE_OPTIONS[element]?.name}`;
    })
    .join(', ');

  const galleryViewToggle = ensureString(qs.parse(location.search).galleryView);

  const { photo, focusedImageId, ...cleanedSearch } = qs.parse(location.search);

  const galleryModalImages = (images) => {
    return images.map((item) => ({
      ...item,
      media: item.uid,
      src: item.previewUrl,
      width: item.width,
      height: item.height,
    }));
  };

  const mainIssueLabel = (field: string) =>
    complianceIssueOptions?.find(({ value }) => value === field)?.label;

  const otherIssuesLabel = (field?: string) => {
    const parsedField = JSON.parse(field ?? '[]');

    if (parsedField.length < 1) return 'None';

    return complianceIssueOptions
      ?.filter(({ value }) => parsedField.includes(value))
      .map(({ label }) => label)
      .join(', ');
  };

  const submissionRaw = submissionListReversed.filter(
    (submission) => submission.raw_previews_created_at != null
  );

  const editorSubmissions = submissionListReversed.filter(
    (submission) => submission.raw_previews_created_at == null
  );

  return submissionPending && submission == null ? (
    <LoadingSpinnerCentered />
  ) : submission == null ? (
    submissionListPending && submissionList == null ? (
      <LoadingSpinnerCentered />
    ) : submissionListError ? (
      <div className="alert alert-danger" role="alert">
        There was an error. Please reload the page
      </div>
    ) : submissionList == null || submissionList.length === 0 ? (
      booking.status === BOOKING_STATUS.PAID ? (
        <Component2
          didMount={() =>
            apiFetch(`/api/v2/bookings/${bookingId}/submissions`, {
              method: 'POST',
              token,
            }).then(({ uid }) =>
              history.replace({
                ...location,
                search: qs.stringify({
                  ...qs.parse(location.search),
                  submission_uid: uid,
                }),
              })
            )
          }
        />
      ) : (
        <Redirect
          to={{
            ...location,
            search: qs.stringify({
              ...qs.parse(location.search),
              submission_uid: undefined,
            }),
          }}
        />
      )
    ) : (
      <Redirect
        to={{
          ...location,
          search: qs.stringify({
            ...qs.parse(location.search),
            submission_uid: submissionList[submissionList.length - 1].uid,
          }),
        }}
      />
    )
  ) : (
    <>
      <TopBar
        history={history}
        location={location}
        session={session}
        bookingGallery={bookingGallery}
        bookingGalleryReload={bookingGalleryReload}
        bookingFetch={bookingFetch}
        submissionFetch={submissionFetch}
        submissionsListFetch={submissionsListFetch}
        reviewerMode={reviewerMode}
        imagesWithFeedback={imagesWithFeedback}
        selectedImageIds={filterSelectedImageIds({
          selectedImageIds,
          imagesWithFeedback,
        })}
        isMediaUploading={mediaUploading}
      />

      <div className="pt-3" />

      {ecommerceData != null ||
      bookingDetailsData?.notesToEditor != null ||
      bookingDetailsData?.complianceIssuesNotes != null ||
      (referenceImages != null && referenceImages.length > 0) ? (
        <details
          open={true}
          style={{
            backgroundColor: '#fff',
            border: '1px solid #E8EDF5',
          }}
        >
          <summary
            className="extra-details-header"
            style={{
              borderBottom: '1px solid #E8EDF5',
              padding: 16,
            }}
          >
            <h4 className="mb-0">Shoot details</h4>
          </summary>
          <div className="d-flex flex-row" style={{ padding: 24 }}>
            {ecommerceData != null && (
              <div style={{ flex: 1 }}>
                {ecommerceData?.shootingElements != null && (
                  <div>
                    <p className="extra-details-title">Product type</p>
                    <p className="extra-details-description">
                      {ecommerceData?.shootingElements
                        ?.map(
                          (element) => SHOOTING_ELEMENTS_OPTIONS[element]?.name
                        )
                        .join(', ')}
                    </p>
                  </div>
                )}
                {ecommerceData?.style != null && (
                  <div>
                    <p className="extra-details-title">Shoot style</p>
                    <p className="extra-details-description">
                      {STYLE_OPTIONS[ecommerceData?.style]?.name}
                    </p>
                  </div>
                )}
                {ecommerceData?.background != null && (
                  <div>
                    <p className="extra-details-title">Background type</p>
                    <p className="extra-details-description">
                      {BACKGROUND_OPTIONS[ecommerceData?.background]?.name}
                    </p>
                  </div>
                )}
                {ecommerceData?.size != null && (
                  <div>
                    <p className="extra-details-title">Product size</p>
                    <p className="extra-details-description">
                      {SIZE_OPTIONS[ecommerceData?.size]?.name}
                    </p>
                  </div>
                )}
                {ecommerceData?.numberOfUniqueItems != null && (
                  <div>
                    <p className="extra-details-title">Number of items</p>
                    <p className="extra-details-description">
                      {ecommerceData?.numberOfUniqueItems}
                    </p>
                  </div>
                )}
                {ecommerceData?.angles != null && (
                  <div>
                    <p className="extra-details-title">Shots per item</p>
                    <p className="extra-details-description">{angles}</p>
                  </div>
                )}
                {booking?.details != null && (
                  <div>
                    <p className="extra-details-title">Shoot details</p>
                    <p className="extra-details-description">
                      {booking?.details}
                    </p>
                  </div>
                )}
              </div>
            )}

            {bookingDetailsData?.notesToEditor != null ||
            bookingDetailsData?.complianceIssuesNotes != null ? (
              <div style={{ flex: 1 }}>
                {bookingDetailsData?.notesToEditor != null && (
                  <div>
                    <p className="extra-details-title">Notes to editor</p>
                    <p className="extra-details-description">
                      {bookingDetailsData?.notesToEditor}
                    </p>
                  </div>
                )}

                {bookingDetailsData?.complianceIssuesNotes != null && (
                  <div>
                    <p className="extra-details-title">
                      All requested shots taken
                    </p>
                    <p className="extra-details-description">
                      {bookingDetailsData?.complianceIssuesNotes != null
                        ? 'No'
                        : 'Yes'}
                    </p>
                  </div>
                )}

                {bookingDetailsData?.complianceIssuesNotes != null && (
                  <div>
                    <p className="extra-details-title">
                      Compliance issues notes (Missing shots / reasons)
                    </p>
                    <p className="extra-details-description">
                      {bookingDetailsData?.complianceIssuesNotes != null
                        ? bookingDetailsData?.complianceIssuesNotes
                        : 'None'}
                    </p>
                  </div>
                )}
              </div>
            ) : null}

            {referenceImages != null && referenceImages.length > 0 ? (
              <div style={{ flex: 1 }}>
                <p className="extra-details-title">Reference images</p>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fill, 150px)',
                    gridAutoRows: '150px',
                    gap: '0.5rem',
                  }}
                >
                  {referenceImages.map((image, index) => {
                    return (
                      <img
                        style={{
                          position: 'relative',
                          width: '100%',
                          height: '100%',
                          display: 'block',
                          objectFit: 'contain',
                          backgroundColor: '#E8EDF5',
                        }}
                        src={image.small?.url}
                        alt={`reference-${index + 1}`}
                      />
                    );
                  })}
                </div>
              </div>
            ) : null}
          </div>
        </details>
      ) : null}

      <div className="pt-2" />

      <div
        className="card mb-3 p-4"
        style={{ border: '1px solid #E11F59', color: '#E11F59' }}
      >
        <p className="mb-4" style={{ fontSize: 18, fontWeight: 700 }}>
          Submission Feedback
        </p>
        <div className="d-flex flex-row" style={{ gap: 8 }}>
          <div style={{ flex: 1 }}>
            <div className="form-group mb-0">
              <label style={{ fontSize: 14, margin: 0 }}>
                <small className="submission-feedback-title">
                  Main issue{' '}
                  {submission.previous_main_issue != null && (
                    <Tooltip
                      placement="right"
                      overlay={multiline(
                        mainIssueLabel(submission.previous_main_issue)
                      )}
                    >
                      <span
                        className="d-inline-block"
                        style={{
                          fontSize: 12,
                          fontWeight: 'lighter',
                          margin: 0,
                        }}
                      >
                        Previous{' '}
                        <i
                          className="fa fa-info-circle"
                          style={{ fontSize: 14 }}
                        />
                      </span>
                    </Tooltip>
                  )}
                </small>
              </label>

              <Textarea
                className="form-control"
                style={{
                  background: 'none',
                  border: 'none',
                  padding: 0,
                  color: '#E11F59',
                  fontSize: 14,
                }}
                value={
                  submission?.main_issue != null
                    ? mainIssueLabel(submission?.main_issue)
                    : 'None'
                }
                readOnly
              />
            </div>
          </div>

          <div style={{ flex: 1 }}>
            <div className="form-group mb-0">
              <label style={{ fontSize: 14, margin: 0 }}>
                <small className="submission-feedback-title">
                  Other issues{' '}
                  {submission.previous_other_issues != null && (
                    <Tooltip
                      placement="right"
                      overlay={multiline(
                        otherIssuesLabel(submission?.previous_other_issues)
                      )}
                    >
                      <span
                        className="d-inline-block"
                        style={{
                          fontSize: 12,
                          fontWeight: 'lighter',
                          margin: 0,
                        }}
                      >
                        Previous{' '}
                        <i
                          className="fa fa-info-circle"
                          style={{ fontSize: 14 }}
                        />
                      </span>
                    </Tooltip>
                  )}
                </small>
              </label>

              <Textarea
                className="form-control"
                style={{
                  background: 'none',
                  border: 'none',
                  padding: 0,
                  color: '#E11F59',
                  fontSize: 14,
                }}
                value={
                  submission?.other_issues !== null
                    ? otherIssuesLabel(submission?.other_issues)
                    : 'None'
                }
                readOnly
              />
            </div>
          </div>

          <div style={{ flex: 1 }}>
            <div className="form-group mb-0">
              <label style={{ fontSize: 14, margin: 0 }}>
                <small className="submission-feedback-title">
                  Feedback details{' '}
                  {submission.previous_feedback != null && (
                    <Tooltip
                      placement="right"
                      overlay={multiline(submission.previous_feedback)}
                    >
                      <span
                        className="d-inline-block"
                        style={{
                          fontSize: 12,
                          fontWeight: 'lighter',
                          margin: 0,
                        }}
                      >
                        Previous{' '}
                        <i
                          className="fa fa-info-circle"
                          style={{ fontSize: 14 }}
                        />
                      </span>
                    </Tooltip>
                  )}
                </small>
              </label>

              <Textarea
                className="form-control"
                style={{
                  background: 'none',
                  border: 'none',
                  padding: 0,
                  color: '#E11F59',
                  fontSize: 14,
                }}
                value={submission.feedback ?? 'None'}
                readOnly
              />
            </div>
          </div>
        </div>
      </div>

      {uploadData?.bookingById?.rawPhotosSubmittedAtAsAdmin == null && (
        <>
          <div className="form-control border-danger text-center text-danger">
            Warning, the RAW media submission has not been completed by the
            photographer yet.
          </div>
          <div className="pt-3" />
        </>
      )}

      {(reviewerMode || submission.submitted_at == null) && (
        <Dropzone
          className="card bg-transparent mb-3"
          accept={videoEnabled ? 'image/jpeg, video/mp4' : 'image/jpeg'}
          onDrop={(acceptedFiles) => addFiles({ acceptedFiles })}
        >
          <div className="card-body text-center">
            <h5>Drop your images {videoEnabled ? ' or videos ' : ' '}</h5>

            <button className="btn btn-outline-primary mt-2">
              or click here to upload
            </button>
          </div>
        </Dropzone>
      )}

      {((bookingGallery && bookingGallery.length > 0) ||
        uploadsList.length > 0) && (
        <div className="card mb-3">
          <div className="card-body table-responsive">
            <div className="d-flex align-items-center justify-content-center mb-3">
              <button
                className={`btn shadow-none p-2 px-4 ${
                  galleryViewToggle === 'true'
                    ? 'btn-outline-secondary'
                    : 'btn-dark'
                }`}
                style={{ borderRadius: '0.25rem 0 0 0.25rem' }}
                onClick={() =>
                  history.replace({
                    ...location,
                    search: qs.stringify({
                      ...qs.parse(location.search),
                      galleryView: undefined,
                      focusedImageId: undefined,
                    }),
                  })
                }
              >
                Default
              </button>

              <button
                className={`btn shadow-none p-2 px-4 ${
                  galleryViewToggle === 'true'
                    ? 'btn-dark'
                    : 'btn-outline-secondary'
                }`}
                style={{ borderRadius: '0 0.25rem 0.25rem 0' }}
                onClick={() =>
                  history.replace({
                    ...location,
                    search: qs.stringify({
                      ...qs.parse(location.search),
                      galleryView: true,
                      focusedImageId: undefined,
                    }),
                  })
                }
                disabled={uploadsList.length > 0}
              >
                Gallery
              </button>
            </div>

            {galleryViewToggle == null ? (
              <table
                className={`table table-hover mb-0 ${
                  galleryViewToggle == null ? '' : 'd-none'
                }`}
              >
                <thead>
                  <tr>
                    <th>
                      <input
                        type="checkbox"
                        checked={
                          filterSelectedImageIds({
                            selectedImageIds,
                            imagesWithFeedback,
                          }).length === imagesWithFeedback.length
                        }
                        onChange={() =>
                          setSelectedImageIds((prev) =>
                            prev.length === imagesWithFeedback.length
                              ? []
                              : imagesWithFeedback.map((i) => i.image.media_uid)
                          )
                        }
                      />
                    </th>
                    <th />
                    <th style={{ width: 50 }} />
                    <th style={{ width: 50 }} />
                    <th>File name</th>
                    <th style={{ width: '20%' }}>Status</th>

                    <th>Reviewer notes</th>

                    <th style={{ width: '10%' }} />
                  </tr>
                </thead>

                <tbody>
                  {mergedMedias.map((item) => (
                    <SubmissionImageRow
                      key={item.uid}
                      item={item}
                      onDeleteFromGallery={() =>
                        apiFetch(
                          `/api/v2/bookings/${bookingId}/submissions/${submissionId}/photos`,
                          {
                            token,
                            method: 'DELETE',
                            body: JSON.stringify({
                              media_uids: [item.uid],
                            }),
                          }
                        ).then(() => bookingGalleryReload())
                      }
                      hideDelete={
                        !(reviewerMode || submission?.submitted_at == null)
                      }
                      imageSelected={filterSelectedImageIds({
                        selectedImageIds,
                        imagesWithFeedback,
                      }).includes(item.uid)}
                      toggleImageSelected={() =>
                        setSelectedImageIds((prev) =>
                          prev.includes(item.uid)
                            ? prev.filter((uid) => uid !== item.uid)
                            : [...prev, item.uid]
                        )
                      }
                      onSetAsHero={() =>
                        apiFetch(
                          `/api/v2/bookings/${bookingId}/submissions/${submissionId}/photos/${item.uid}/hero`,
                          { token, method: 'PUT' }
                        ).then(() => bookingGalleryReload())
                      }
                      focusedImageId={focusedImageId}
                    />
                  ))}
                </tbody>
              </table>
            ) : (
              <MasonryGallery
                images={mergedMedias
                  .filter((item) => item.previewUrl != null)
                  .map((item) => ({
                    ...item,
                    media: item.uid,
                    src: item.previewUrl,
                  }))}
                columnCount={5}
              >
                {({ columns }) => (
                  <div
                    className="d-flex justify-content-space-around pt-3"
                    style={{ borderTop: '1px solid #ddd' }}
                  >
                    {columns.map((column, key) => (
                      <div key={key} className="flex-1 mx-1">
                        {column.images.map((img) => (
                          <Link
                            key={img.media}
                            to={{
                              ...location,
                              search: qs.stringify({
                                ...qs.parse(location.search),
                                photo: img.media,
                              }),
                            }}
                          >
                            {img.isVideo ? (
                              <video style={{ width: '100%' }} controls>
                                <source
                                  src={img.downloadUrl}
                                  type="video/mp4"
                                />
                                Your browser does not support the video tag.
                              </video>
                            ) : (
                              <img
                                className="mb-2 rounded"
                                style={{ width: '100%' }}
                                src={img.midUrl}
                                alt=""
                              />
                            )}
                          </Link>
                        ))}
                      </div>
                    ))}
                  </div>
                )}
              </MasonryGallery>
            )}
          </div>
        </div>
      )}

      {photo && (
        <GalleryModal
          location={location}
          history={history}
          images={galleryModalImages(mergedMedias)}
          preselectedImage={galleryModalImages(mergedMedias).findIndex(
            ({ uid }) => uid === photo
          )}
          onCloseRequest={() =>
            history.push({
              ...location,
              search: qs.stringify(cleanedSearch),
            })
          }
        />
      )}

      <>
        <div style={{ height: 50 }} />

        <div
          className="bg-white p-3 d-flex justify-content-end align-items-center"
          style={{
            position: 'fixed',
            bottom: 0,
            right: 0,
            left: 0,
            marginLeft: 200,
            boxShadow: '0 0 1.625em rgba(0,0,0,.05)',
          }}
        >
          {hasUnsavedFileNameChanges && (
            <PromiseButton
              className="btn btn-outline-primary mr-3"
              onClick={() => onSaveFileNames()}
            >
              Save file names
            </PromiseButton>
          )}

          <a
            href={
              contractorMode
                ? `/contractor-editing-queue/${bookingId}/auto-editing-contractor`
                : `/editing-queue/${bookingId}/auto-editing`
            }
            className="btn btn-outline-dark mr-3"
          >
            Return to Auto Editing
          </a>

          <a
            href={`${location.pathname}?${qs.stringify({
              ...qs.parse(location.search),
              submission_uid: null,
            })}`}
            className="btn btn-outline-primary"
          >
            Go to latest submission
          </a>

          <select
            className="form-control ml-3"
            style={{ width: 200 }}
            value={submissionId}
            onChange={({ target: { value } }) =>
              history.replace({
                ...location,
                search: qs.stringify({
                  ...qs.parse(location.search),
                  submission_uid: value,
                }),
              })
            }
          >
            {editorSubmissions.map((submission, index) => (
              <option key={submission.uid} value={submission.uid}>
                Submission {editorSubmissions.length - index}
              </option>
            ))}

            {submissionRaw.map((submission, index) => (
              <option key={submission.uid} value={submission.uid}>
                RAW previews
              </option>
            ))}
          </select>

          {reviewerMode === true && (
            <>
              {bookingPartnerInPilotMode === true && (
                <>
                  <select
                    className="form-control ml-3"
                    style={{ width: 200 }}
                    onChange={({ target: { value } }) => {
                      setDeliveryQAStatus(value);
                    }}
                    disabled={checkIfCanAccess(session, 'deliveryQA') === false}
                    value={deliveryQAStatus}
                  >
                    <option value="" disabled>
                      {checkIfCanAccess(session, 'deliveryQA') === false
                        ? 'Not sent'
                        : 'Set delivery status'}
                    </option>
                    {deliveryQAStatusOptions.map((status) => (
                      <option key={status.value} value={status.value}>
                        {status.label}
                      </option>
                    ))}
                  </select>

                  {submissionFetch.result?.delivery_status?.status !==
                    deliveryQAStatus &&
                    deliveryQAStatus !== '' && (
                      <button
                        className="btn btn-primary ml-3"
                        onClick={async () =>
                          onSaveFeedback()
                            .then(() =>
                              bookingSubmissionDeliveryQAStatusUpdate({
                                variables: {
                                  bookingSubmissionId: submissionId,
                                  status: deliveryQAStatus,
                                },
                              })
                            )
                            .then(() => submissionReload())
                        }
                      >
                        Save
                      </button>
                    )}

                  {checkIfCanAccess(session, 'deliveryQA') === false && (
                    <button
                      className="btn btn-primary ml-3"
                      disabled={submissionFetch.result?.submitted_at == null}
                      onClick={async () =>
                        onSaveFeedback()
                          .then(() =>
                            bookingSubmissionDeliveryQAStatusUpdate({
                              variables: {
                                bookingSubmissionId: submissionId,
                                status: 'pending',
                              },
                            })
                          )
                          .then(() => submissionReload())
                          .then(() => history.push('/photo-review-queue'))
                      }
                    >
                      Send to delivery QA
                    </button>
                  )}
                </>
              )}
              {(requiresQualityControl === false ||
                checkIfCanAccess(session, 'deliveryQA') === true) && (
                <PromiseButton
                  className="btn btn-primary ml-3"
                  disabled={
                    bookingPartnerInPilotMode === true &&
                    submission.delivery_status?.status !== 'approved'
                  }
                  onClick={async () => {
                    if (
                      imagesWithFeedback.find((i) => i.image.hero === true) ==
                      null
                    ) {
                      return alert(
                        'Please select a Hero image before sending to client (by using the ⭐️)'
                      );
                    }

                    await apiFetch(
                      `/api/v2/bookings/${bookingId}/submissions/${submissionId}/complete-and-send-booking`,
                      { token, method: 'POST' }
                    )
                      .then(() => bookingReload())
                      .then(() =>
                        history.push({
                          ...location,
                          search: qs.stringify({
                            ...qs.parse(location.search),
                            submission_uid: undefined,
                          }),
                        })
                      );
                  }}
                >
                  Send to client
                </PromiseButton>
              )}

              <Link
                className="btn btn-warning ml-3"
                to={locationForModal({
                  location,
                  modal: {
                    modalName: MODAL_NAME_REPORT_EDITING_ISSUES,
                    submissionId,
                  },
                })}
              >
                Return to{' '}
                {submission.submitted_by_photographer
                  ? 'photographer'
                  : 'editor'}
              </Link>
            </>
          )}
        </div>

        <ReportEditingIssues
          onSubmit={() =>
            onSaveFeedback()
              .then(() =>
                apiFetch(
                  `/api/v2/bookings/${bookingId}/submissions/${submissionId}/return-to-submitter`,
                  { token, method: 'POST' }
                )
              )
              .then(() => history.push('/photo-review-queue'))
          }
          complianceIssueOptions={complianceIssueOptions ?? []}
        />
      </>
      <style jsx>{`
        .extra-details-title {
          margin-bottom: 0;
          color: #71767e;
        }
        .extra-details-description {
          color: #454f5c;
          font-weight: 700;
        }

        .extra-details-header {
          position: relative;
        }
        .extra-details-header::marker {
          content: none;
        }

        .extra-details-header::after {
          content: '';
        }

        .extra-details-header::after {
          position: absolute;
          top: 50%;
          right: 16px;
          border: solid #141b24;
          border-width: 0 3px 3px 0;
          display: inline-block;
          padding: 3px;
          transform: rotate(45deg);
        }

        [open] .extra-details-header::after {
          transform: rotate(-135deg);
        }

        .submission-feedback-title {
          font-weight: 700;
          font-size: 14px;
        }
      `}</style>
    </>
  );
}

const ImageActions = ({
  fileName,
  media,
  location,
}: {
  fileName: string;
  media: string;
  location: RouteComponentProps['location'];
}) => {
  const fileNameInputRef = React.createRef<HTMLInputElement>();

  return (
    <div className="d-flex flex-col justify-content-center align-items-center pt-4">
      <input
        ref={fileNameInputRef}
        style={{
          color: 'white',
          background: 'none',
          borderRadius: '5px',
          textAlign: 'center',
          outline: 'none',
        }}
        value={fileName}
        readOnly
      />

      <div className="p-2" />

      <div className="input-group-append">
        <button
          className="btn btn-outline-secondary"
          onClick={() => {
            if (fileNameInputRef.current != null) {
              fileNameInputRef.current.select();
              document.execCommand('copy');
            }
          }}
        >
          <i className="fa fa-copy" aria-hidden="true" />
        </button>
      </div>

      <div className="p-2" />

      <div className="input-group-append">
        <button className="btn btn-outline-secondary">
          <Link
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
            }}
            key={media}
            to={{
              ...location,
              search: qs.stringify({
                ...qs.parse(location.search),
                galleryView: undefined,
                photo: undefined,
                focusedImageId: media,
              }),
            }}
          />
          <i className="fa fa-commenting-o" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
};

function preloadImage(src: string) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      resolve(img);
    };
    img.onerror = img.onabort = function () {
      reject(src);
    };
    img.src = src;
  });
}

function useImagePreloader(imageList: string[]) {
  const [imagesPreloaded, setImagesPreloaded] = useState<boolean>(false);

  useEffect(() => {
    async function effect() {
      const imagesPromiseList = imageList.map((img) => preloadImage(img));
      await Promise.all(imagesPromiseList);

      setImagesPreloaded(true);
    }

    effect();
  }, [imageList]);

  return { imagesPreloaded };
}

const GalleryModal = ({
  images,
  preselectedImage,
  location,
  history,
  onCloseRequest,
}: {
  images: {
    media: string;
    fileName: string;
    largeUrl: string;
    previewUrl: string;
    midUrl: string;
    downloadUrl: string;
    width: string;
    height: string;
    isVideo: boolean;
  }[];
  preselectedImage: number;
  location: RouteComponentProps['location'];
  history: RouteComponentProps['history'];
  onCloseRequest: () => void;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const preloadMidImages = useImagePreloader(
    images.filter(({ isVideo }) => isVideo !== true).map(({ midUrl }) => midUrl)
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const preloadLargeImages = useImagePreloader(
    images
      .filter(({ isVideo }) => isVideo !== true)
      .map(({ largeUrl }) => largeUrl)
  );
  const prevId =
    images[preselectedImage - 1]?.media ?? images[images.length - 1]?.media;
  const nextId = images[preselectedImage + 1]?.media ?? images[0]?.media;

  const next = () =>
    history.push({
      ...location,
      search: qs.stringify({
        ...qs.parse(location.search),
        photo: nextId,
      }),
    });

  const prev = () =>
    history.push({
      ...location,
      search: qs.stringify({
        ...qs.parse(location.search),
        photo: prevId,
      }),
    });

  return (
    <Modal backgroundColor="black" outline="none">
      <button
        className="btn p-4 m-2"
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          color: 'white',
        }}
        onClick={onCloseRequest}
      >
        Close
      </button>

      <div className="container">
        <KeyboardControlEvents right={next} left={prev} esc={onCloseRequest}>
          {() => (
            <>
              <div
                style={{
                  position: 'relative',
                  width: '100%',
                  margin: '0 auto',
                }}
              >
                <div className="text-center">
                  {images &&
                    images.map(
                      ({
                        media,
                        fileName,
                        largeUrl,
                        midUrl,
                        downloadUrl,
                        width,
                        height,
                        isVideo,
                      }) => (
                        <div
                          key={media}
                          className={
                            images[preselectedImage]?.media === media
                              ? undefined
                              : 'd-none'
                          }
                        >
                          {images[preselectedImage]?.media !== media ? (
                            <>
                              <img
                                style={{
                                  maxWidth: '100%',
                                  height: 'auto',
                                  maxHeight: '80vh',
                                  zIndex: 10000,
                                }}
                                src={largeUrl}
                                alt=""
                              />
                            </>
                          ) : isVideo ? (
                            <video style={{ width: '100%' }} controls>
                              <source src={downloadUrl} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                          ) : (
                            <ReactImageMagnify
                              imageStyle={{
                                maxWidth: '100%',
                                height: 'auto',
                                maxHeight: '80vh',
                              }}
                              {...{
                                smallImage: {
                                  isFluidWidth: true,
                                  src: midUrl,
                                },
                                largeImage: {
                                  src: largeUrl,
                                  width,
                                  height,
                                },
                                enlargedImagePosition: 'over',
                                hoverDelayInMs: 0,
                                hoverOffDelayInMs: 0,
                                fadeDurationInMs: 0,
                              }}
                            />
                          )}

                          <ImageActions
                            fileName={fileName}
                            media={media}
                            location={location}
                          />
                        </div>
                      )
                    )}
                </div>
              </div>

              <BottomControls
                className="d-flex justify-content-end align-items-center p-4"
                style={{ bottom: 0 }}
              >
                <button onClick={prev} className="btn btn-link">
                  <img
                    src={leftArrow}
                    style={{ width: '20px', height: '20px' }}
                    alt="left"
                  />
                </button>

                <span style={{ color: 'white' }}>
                  {preselectedImage + 1} of {images.length}
                </span>

                <button onClick={next} className="btn btn-link">
                  <img
                    src={rightArrow}
                    style={{ width: '20px', height: '20px' }}
                    alt="right"
                  />
                </button>
              </BottomControls>
            </>
          )}
        </KeyboardControlEvents>
      </div>
    </Modal>
  );
};

export function SubmissionsGalleryUploadRoute(props) {
  const {
    match: {
      params: { bookingId },
    },
    session,
    bookingFetch: { isPending: bookingPending, result: booking },
    submissionId,
  } = props;

  const submissionsListFetch = useFetch({
    urlToFetch: `/api/v2/bookings/${bookingId}/submissions`,
    session,
  });

  const submissionFetch = useFetch({
    urlToFetch: `/api/v2/bookings/${bookingId}/submissions/${submissionId}`,
    session,
    disabled: submissionId == null,
  });

  return bookingPending && booking == null ? (
    <LoadingSpinnerCentered />
  ) : (
    <SubmissionsGalleryUploadRouteInner
      {...props}
      submissionsListFetch={submissionsListFetch}
      submissionFetch={submissionFetch}
    />
  );
}
