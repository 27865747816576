import React from 'react';
import qs from 'query-string';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import Select from 'react-select';

import { Fetcher } from '../../components/Fetcher';
import ContentWrapper from '../../components/ContentWrapper';
import { SearchField } from './components';
import { ModalRoute, locationForModal } from '../../components/ModalRoute';
import { Modal } from '../../components/Modal';
import { useFetch } from '../../components/useFetch';
import { usePromise } from '../../components/usePromise';

import { formatCredits, apiFetch } from '../../utils';
import { APP_URL } from '../../config';

import godModeIcon from '../../components/img/god-mode-icon.svg';

const PARTNERS_PER_PAGE = 499;

const StyledTd = ({ title, ...rest }) => (
  <td title={title} style={{ verticalAlign: 'middle' }}>
    <span className="text-secondary d-inline-block text-truncate" {...rest} />
  </td>
);

const GALLERY_CSV_EXPORT_MODAL_NAME = 'gallery_csv_export';

function GodModeLink({ session, partnerId }) {
  const { uid, token, expire } = session;

  const authToken = btoa([uid, token, expire].join(':'));

  const linkUrl = `${APP_URL}/auth-and-redirect?${qs.stringify({
    authToken,
    next: `/enterprise?partner=${partnerId}`,
  })}`;

  return (
    <a href={linkUrl} target="_blank" rel="noopener noreferrer">
      <img src={godModeIcon} alt="god mode" />
    </a>
  );
}

function GalleryCSVExportModal({
  session,
  onDismiss,
  modalParams,
  history,
  location,
}) {
  const {
    result: { results: partners } = {},
    isPending: partnersPending,
  } = useFetch({
    urlToFetch: '/api/v2/partners/summary',
    session,
  });

  const {
    setPromise: setStartPromise,
    isPending: startPending,
    result: startResult,
  } = usePromise();

  const partnerOptions = (partners || []).map((partner) => ({
    label: partner.name,
    value: partner.uid,
  }));

  const partnerIds = modalParams.partner_ids || [];

  return (
    <Modal onDismiss={onDismiss}>
      <div className="card">
        <div
          className="card-body d-flex flex-column"
          style={{ minWidth: 600, minHeight: 300 }}
        >
          <h3 className="text-center">Gallery CSV Export</h3>

          {startResult == null ? (
            <>
              <Select
                className="w-100 pb-3"
                placeholder={partnersPending ? 'Loading...' : 'Select partners'}
                isLoading={partnersPending}
                options={partnerOptions}
                value={partnerIds.map((uid) =>
                  partnerOptions.find((p) => p.value === uid)
                )}
                onChange={(value) => {
                  history.replace(
                    locationForModal({
                      location,
                      modal: {
                        ...modalParams,
                        partner_ids:
                          (value || undefined) && value.map((p) => p.value),
                      },
                    })
                  );
                }}
                isSearchable
                isMulti
              />

              <div className="flex-grow-1" />

              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-primary"
                  onClick={() =>
                    setStartPromise(
                      apiFetch(`/api/v2/partners/gallery-csv-export`, {
                        token: session.token,
                        method: 'POST',
                        body: JSON.stringify({
                          partner_ids: partnerIds.join(','),
                        }),
                      })
                    )
                  }
                  disabled={startPending || partnerIds.length === 0}
                >
                  Start
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="text-success text-center align-self-center">
                <strong>Process started successfully</strong>
                <br />
                You will receive an email shortly with your CSV files.
              </div>

              <div className="flex-grow-1" />

              <div className="d-flex justify-content-end">
                <button className="btn btn-outline-primary" onClick={onDismiss}>
                  Close
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
}

function GalleryCSVExportButton({ session, location }) {
  return (
    <>
      <Link
        className="btn btn-outline-primary"
        to={locationForModal({
          location,
          modal: { modalName: GALLERY_CSV_EXPORT_MODAL_NAME },
        })}
      >
        Gallery CSV export
      </Link>

      <ModalRoute
        modalName={GALLERY_CSV_EXPORT_MODAL_NAME}
        render={(props) => (
          <GalleryCSVExportModal
            {...props}
            session={session}
            onDismiss={() => {
              props.history.replace(
                locationForModal({
                  location,
                  modal: undefined,
                })
              );
            }}
          />
        )}
      />
    </>
  );
}

export const ListPartnersRoute = ({ history, session, location }) => (
  <ContentWrapper>
    <div className="d-flex justify-content-between align-items-center mb-3">
      <GalleryCSVExportButton session={session} location={location} />

      <SearchField
        onSearch={({ q }) =>
          history.push({
            ...location,
            search: qs.stringify({
              ...qs.parse(location.search),
              q: q || undefined,
            }),
          })
        }
        defaultValue={qs.parse(location.search).q || ''}
      />
    </div>

    <Fetcher
      urlToFetch="/api/v2/partners/summary"
      session={session}
      queryToFetch={{
        limit: PARTNERS_PER_PAGE,
        q: qs.parse(location.search).q || undefined,
      }}
    >
      {({ loading, response: { results } = {} }) =>
        loading ? (
          <p>Loading...</p>
        ) : (
          <div
            className="table-responsive"
            style={{
              position: 'relative',
            }}
          >
            <table className="table table-hover">
              <thead>
                <tr>
                  <th />
                  <th className="text-muted text-truncate">Company name</th>
                  <th className="text-muted text-truncate">Account manager</th>
                  <th className="text-muted text-truncate"># of users</th>
                  <th className="text-muted text-truncate">Shoot Guide</th>
                  <th className="text-muted text-truncate">
                    End Customer Shoot Guide
                  </th>
                  <th className="text-muted text-truncate">Balance</th>
                  <th className="text-muted text-truncate">Last recharge</th>
                  <th className="text-muted text-truncate">
                    # of shoots past 30 days
                  </th>
                  <th className="text-muted text-truncate">
                    # of shoots next 30 days
                  </th>
                  <th className="text-muted text-truncate">
                    Default Shoottype
                  </th>
                  <th className="text-muted text-truncate">Strict mode</th>
                  <th className="text-muted text-truncate">Value pricing</th>
                </tr>
              </thead>

              <tbody>
                {results.map((partnerSummary) => (
                  <tr key={partnerSummary.uid}>
                    <td>
                      <GodModeLink
                        session={session}
                        partnerId={partnerSummary.uid}
                      />
                    </td>

                    <StyledTd>
                      <Link to={`/partners/${partnerSummary.uid}`}>
                        {partnerSummary.name}
                      </Link>
                    </StyledTd>
                    <StyledTd>{partnerSummary.account_manager_name}</StyledTd>
                    <StyledTd>{partnerSummary.users_count}</StyledTd>
                    <StyledTd>
                      {partnerSummary.shoot_guide_url && (
                        <a href={partnerSummary.shoot_guide_url} download>
                          Download
                        </a>
                      )}
                    </StyledTd>
                    <StyledTd>
                      {partnerSummary.end_customer_shoot_guide_url && (
                        <a
                          href={partnerSummary.end_customer_shoot_guide_url}
                          download
                        >
                          Download
                        </a>
                      )}
                    </StyledTd>
                    <StyledTd>
                      {partnerSummary.credits &&
                        formatCredits(partnerSummary.credits)}{' '}
                      credits
                    </StyledTd>
                    <StyledTd title={partnerSummary.last_recharged_at}>
                      {partnerSummary.last_recharged_at &&
                        moment(partnerSummary.last_recharged_at).format(
                          'D, MMMM YYYY Z'
                        )}
                    </StyledTd>
                    <StyledTd>{partnerSummary.bookings_past_30_count}</StyledTd>
                    <StyledTd>{partnerSummary.bookings_next_30_count}</StyledTd>
                    <StyledTd>
                      {partnerSummary.default_shoottype_display_name}
                    </StyledTd>
                    <StyledTd>
                      {partnerSummary.strict_mode ? 'Yes' : ''}
                    </StyledTd>
                    <StyledTd>
                      {partnerSummary.value_pricing ? 'Yes' : ''}
                    </StyledTd>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )
      }
    </Fetcher>
  </ContentWrapper>
);
