import React from 'react';
import { Link } from 'react-router-dom';

import { Promiser } from '../../../../components/Promiser';
import { PromiseButton } from '../../../../components/PromiseButton';
import { Modal } from '../../../../components/Modal';
import { isPartnerBooking, isBookingReadyForCharge } from '../selectors';
import { ModalRoute, locationForModal } from './ModalRoute';
import { apiFetch } from '../../../../utils';

const MODAL_NAME_PAYMENT_LINK = 'payment-link';

const ReadOnlyInput = ({ label, placeholder = label, ...rest }) => (
  <div className="form-group">
    {label && (
      <label>
        <small className="text-secondary">{label}</small>
      </label>
    )}

    <input
      type="text"
      className="form-control"
      readOnly
      placeholder={placeholder}
      onClick={(ev) => ev.target.select()}
      {...rest}
    />
  </div>
);

class PaymentLinkModal extends React.Component {
  state = { paymentLinkPromise: null };

  componentDidMount() {
    const {
      session: { token },
      booking,
    } = this.props;
    this.setState({
      paymentLinkPromise: apiFetch(
        `/api/v2/bookings/${booking.uid}/get-payment-link`,
        {
          token,
          method: 'POST',
        }
      ).catch((err) => err.json().then((res) => Promise.reject(res))),
    });
  }

  render() {
    const {
      onDismiss,
      session: { token },
      booking,
    } = this.props;
    return (
      <Promiser promise={this.state.paymentLinkPromise}>
        {({
          isPending: paymentLinkLoading,
          result: { paymentLink } = {},
          error: paymentLinkError,
        }) => (
          <Modal onDismiss={onDismiss}>
            <div className="card my-4">
              <div className="card-header">
                <h4 className="mb-0">Payment link</h4>
              </div>

              <div
                className="card-body d-flex flex-column"
                style={{ width: '1000px', minHeight: '600px' }}
              >
                <div className="flex-1" style={{ position: 'relative' }}>
                  {!paymentLinkLoading &&
                    (paymentLinkError ? (
                      <div className="alert alert-danger">
                        {(paymentLinkError.errors &&
                          paymentLinkError.errors.join('.\n')) ||
                          'Unknown error'}
                      </div>
                    ) : (
                      <React.Fragment>
                        <ReadOnlyInput value={paymentLink} />

                        <div className="card mb-3">
                          <div className="card-body">
                            <p>
                              If you would like to take the customers credit
                              card details over the phone, open the above link
                              in a <b>private/incognito browser</b> (if you open
                              it in a normal browser it may cause issues).
                            </p>
                            <p className="mb-0">
                              To have a payment link emailed to the customer for
                              them to complete, click the button below. They
                              will receive an email immediately, with automated
                              follow-up at 24h, 48h and 70h if they have not
                              paid.
                            </p>
                          </div>
                        </div>
                      </React.Fragment>
                    ))}

                  {paymentLinkLoading && (
                    <div
                      className={[
                        'd-flex flex-columns',
                        'justify-content-center align-items-center',
                      ].join(' ')}
                      style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                      }}
                    >
                      <span>Loading...</span>
                    </div>
                  )}
                </div>

                <div className="d-flex justify-content-between mt-3">
                  <button className="btn btn-secondary" onClick={onDismiss}>
                    Close
                  </button>

                  <PromiseButton
                    className="btn btn-primary"
                    pendingChildren="Processing..."
                    onClick={() =>
                      apiFetch(
                        `/api/v2/bookings/${booking.uid}/send-payment-link`,
                        { token, method: 'POST' }
                      ).then(() => onDismiss())
                    }
                  >
                    Send email to customer
                  </PromiseButton>
                </div>
              </div>
            </div>
          </Modal>
        )}
      </Promiser>
    );
  }
}

export const PaymentLinkButton = ({
  session,
  booking,
  bookingClone,
  bookingChangedKeys,
  bookingHasChanges,
  location,
  onReload,
  disabled,
}) => (
  <React.Fragment>
    {!isPartnerBooking({ booking: bookingClone }) &&
      isBookingReadyForCharge({ booking }) &&
      !bookingHasChanges &&
      booking.product?.type !== 'experimentalPricing' && (
        <Link
          className={`btn btn-primary ${disabled ? 'disabled' : ''}`}
          to={locationForModal({
            location,
            modal: { modalName: MODAL_NAME_PAYMENT_LINK },
          })}
        >
          Payment link
        </Link>
      )}

    <ModalRoute
      modalName={MODAL_NAME_PAYMENT_LINK}
      render={({ history, location }) => (
        <PaymentLinkModal
          session={session}
          booking={booking}
          bookingClone={bookingClone}
          bookingChangedKeys={bookingChangedKeys}
          bookingHasChanges={bookingHasChanges}
          onDismiss={() => {
            history.replace(
              locationForModal({
                location,
                modal: undefined,
              })
            );

            // reload to fetch new sales agent info in the booking
            // (in case a sales agent was newly set)
            onReload();
          }}
        />
      )}
    />
  </React.Fragment>
);
