import * as React from 'react';
import { Link } from 'react-router-dom';
import { ZQueue } from 'zqueue';

import { gql, useMutation } from '../../../components/ApolloClient';
import { LoadingSpinnerCentered } from '../../../components/LoadingSpinner';
import { EditingJobsImagesTableSection } from './images-table-section';
import { EditingJobDetailsSection } from './editing-jobs-details-section';
import { BottomSectionWrapper } from '../../../components/BottomSectionWrapper';
import { useEditingJobQuery, useEditingUploadManager } from './utils';
import { useState } from 'react';

enum EditingJobStatusEnum {
  CREATED = 'created',
  EDITING = 'editing',
  PAID = 'paid',
  COMPLETED = 'completed',
  REFUNDED = 'refunded',
  CANCELLED = 'cancelled',
}

export const badgeBgColorStatus = (status: string) => {
  switch (status.trim().toLowerCase()) {
    case EditingJobStatusEnum.CREATED:
      return { background: '#C1C8D4' };
    case EditingJobStatusEnum.EDITING:
      return { background: '#38C4F2' };
    case EditingJobStatusEnum.PAID:
      return { background: '#C1C8D4' };
    case EditingJobStatusEnum.COMPLETED:
      return { background: '#2FB67D' };
    case EditingJobStatusEnum.REFUNDED:
      return { background: '#C1C8D4' };
    case EditingJobStatusEnum.CANCELLED:
      return { background: '#C1C8D4' };
    default:
      return { background: '#C1C8D4' };
  }
};

export const EditingJobDetails = ({
  match,
  session,
  session: { token },
}: {
  match;
  session: { token: string; uid: string };
}) => {
  const {
    params: { jobId },
  } = match;

  const editingJobIdInputRef = React.createRef<HTMLInputElement>();
  const [imagesToRefund, setImagesToRefund] = useState<string[]>([]);
  const [queue] = React.useState(() => new ZQueue({ max: 3 }));

  const editingUploadManager = useEditingUploadManager({ token, queue });
  const editingJobQuery = useEditingJobQuery({ jobId });

  const editingJob = editingJobQuery.data?.editingJob;

  const [saveEditor, saveEditorMutation] = useMutation<{
    editingJobSaveEditorAsAdmin: {
      edges: { id: string }[];
    };
  }>(
    gql`
      mutation EditingJobDetailsSaveEditor(
        $editingJobIds: [ID]!
        $editorId: ID
      ) {
        editingJobSaveEditorAsAdmin(
          input: { editingJobIds: $editingJobIds, editorId: $editorId }
        ) {
          edges {
            id
          }
        }
      }
    `
  );

  const [refundImages, refundImagesMutation] = useMutation<{
    editingJobRefundImagesAsAdminUser: {
      success: boolean;
    };
  }>(
    gql`
      mutation EditingJobRefundImagesAsAdminUser(
        $mediaIds: [ID]!
        $editingJobId: String!
      ) {
        editingJobRefundImagesAsAdminUser(
          input: { mediaIds: $mediaIds, editingJobId: $editingJobId }
        ) {
          success
        }
      }
    `
  );

  const [submitGallery, submitGalleryMutation] = useMutation<{
    editingJobSubmitGalleryAsAdminUser: {
      editingJob: { id: string };
    };
  }>(
    gql`
      mutation EditingJobDetailsSubmitGallery($editingJobId: ID!) {
        editingJobSubmitGalleryAsAdminUser(
          input: { editingJobId: $editingJobId }
        ) {
          editingJob {
            id
          }
        }
      }
    `
  );

  return editingJobQuery != null && editingJob != null ? (
    editingJob ? (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <h4
              style={{
                color: '#1F6FFF',
                fontWeight: 'bold',
                fontSize: '22px',
                lineHeight: '29px',
              }}
            >
              <Link to={`editing-jobs`}>Editing jobs queue</Link>
              <span style={{ color: '#141B24' }}>
                / {editingJob?.partner?.name}
              </span>{' '}
              <span
                className="badge badge-primary text-uppercase"
                style={badgeBgColorStatus(editingJob?.displayStatus)}
              >
                {editingJob.displayStatus}
              </span>
            </h4>

            <div className="input-group mb-3 mt-2">
              <input
                type="text"
                className="form-control"
                readOnly={true}
                ref={editingJobIdInputRef}
                value={editingJob.id}
                style={{ maxWidth: 320 }}
              />
              <div className="input-group-append">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    if (editingJobIdInputRef.current != null) {
                      editingJobIdInputRef.current.select();
                      document.execCommand('copy');
                    }
                  }}
                >
                  <i className="fa fa-copy" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>

          {editingJob?.editor?.fullName != null ? (
            <div>
              <span style={{ fontSize: 12, fontWeight: 400 }}>Editor</span>
              <p
                style={{
                  fontSize: 16,
                  fontWeight: 700,
                  lineHeight: '16px',
                  marginTop: 4,
                }}
              >
                {' '}
                {editingJob?.editor?.fullName}
              </p>
            </div>
          ) : null}
        </div>

        <div className="card">
          <div className="card-body">
            <EditingJobDetailsSection
              session={session}
              editingJobQuery={editingJobQuery}
              editingUploadManager={editingUploadManager}
              reload={editingJobQuery.refetch}
            />

            <EditingJobsImagesTableSection
              session={session}
              editingJobQuery={editingJobQuery}
              editingUploadManager={editingUploadManager}
              reload={editingJobQuery.refetch}
              imagesToRefund={imagesToRefund}
              setImagesToRefund={(imagesIds) => setImagesToRefund(imagesIds)}
            />
          </div>
        </div>

        <BottomSectionWrapper>
          <div
            style={{
              justifyContent: 'flex-end',
              display: 'flex',
              width: '100%',
            }}
          >
            {(editingJob?.displayStatus === 'editing' ||
              editingJob.displayStatus === 'completed') &&
            imagesToRefund.length > 0 ? (
              <button
                className="btn btn-dark mr-1"
                disabled={refundImagesMutation.loading}
                onClick={() =>
                  refundImages({
                    variables: {
                      mediaIds: imagesToRefund,
                      editingJobId: editingJob?.id,
                    },
                  }).then(() => {
                    editingJobQuery.refetch();
                    setImagesToRefund([]);
                  })
                }
              >
                Refund images
              </button>
            ) : null}
            {editingJob?.editor == null ? (
              <button
                className="btn btn-dark"
                disabled={saveEditorMutation.loading}
                onClick={() =>
                  saveEditor({
                    variables: {
                      editingJobIds: [editingJob?.id],
                      editorId: session.uid,
                    },
                  })
                }
              >
                Claim job
              </button>
            ) : null}

            {editingJob?.displayStatus === 'editing' ? (
              <button
                className="btn btn-dark ml-1"
                title={
                  imagesToRefund.length > 0
                    ? 'Can`t submit without pending images for refund'
                    : ''
                }
                disabled={
                  submitGalleryMutation.loading || imagesToRefund.length > 0
                }
                onClick={() =>
                  submitGallery({
                    variables: {
                      editingJobId: editingJob?.id,
                    },
                  })
                    .catch((err) =>
                      window.alert(
                        'There are some finals images missing in the Editing Job '
                      )
                    )
                    .then(() => editingJobQuery.refetch())
                }
              >
                Submit gallery
              </button>
            ) : null}
          </div>
        </BottomSectionWrapper>
      </>
    ) : (
      // TODO: improve
      <div>ERROR</div>
    )
  ) : (
    <LoadingSpinnerCentered />
  );
};
