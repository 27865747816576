import React from 'react';
import { Route, Switch } from 'react-router-dom';

import BaseLayout from '../../components/BaseLayout';
import { BaseStyle } from '../../components/BaseStyle';

import qs from 'query-string';

import { RouteComponentProps } from 'react-router-dom';
import ContentWrapper from '../../components/ContentWrapper';
import { SearchField } from '../partners/components';
import { gql, useQuery } from '../../components/ApolloClient';
import { ensureString } from '../../utils/strings';

const ELLIPSIS_STYLE = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
} as const;

type sendgridActivity = {
  messageId: string;
  subject?: string;
  fromEmail?: string;
  status?: string;
  opensCount?: number;
  clicksCount?: number;
  lastEventTime?: string;
};

function useSendgridActivityByEmailSearchQuery({
  userEmail,
}: {
  userEmail?: string;
}) {
  return useQuery<{
    sendgridActivityByEmailSearch: {
      total: number;
      edges?: sendgridActivity[];
    };
  }>(
    gql`
      query SendgridActivityByEmailSearch($userEmail: String!) {
        sendgridActivityByEmailSearch(userEmail: $userEmail) {
          total
          edges {
            messageId
            subject
            fromEmail
            status
            opensCount
            clicksCount
            lastEventTime
          }
        }
      }
    `,
    {
      variables: {
        userEmail,
      },
      skip: userEmail == null,
    }
  );
}

function HeaderTableRow() {
  return (
    <tr>
      <th style={ELLIPSIS_STYLE} scope="col">
        Message Id
      </th>
      <th style={ELLIPSIS_STYLE} scope="col">
        Subject
      </th>
      <th style={ELLIPSIS_STYLE} scope="col">
        Status
      </th>
      <th style={ELLIPSIS_STYLE} scope="col">
        Opens count
      </th>
      <th style={ELLIPSIS_STYLE} scope="col">
        Clicks count
      </th>
      <th style={ELLIPSIS_STYLE} scope="col">
        Last event time
      </th>
    </tr>
  );
}

function LoadingTableRows({ rows, cols }: { rows: Number; cols: Number }) {
  return (
    <>
      {Array.from(Array(rows)).map((i, indexRows) => (
        <tr key={indexRows}>
          {Array.from(Array(cols)).map((i, indexCols) => (
            <td key={indexCols}>...</td>
          ))}
        </tr>
      ))}
    </>
  );
}

function FormattedTableRows({
  sendgridActivityList,
}: {
  sendgridActivityList: sendgridActivity[] | undefined;
}) {
  const tableRows = sendgridActivityList?.map((sendgridActivity) => {
    return (
      <tr key={sendgridActivity?.messageId}>
        <td>{sendgridActivity.messageId}</td>
        <td>{sendgridActivity?.subject}</td>
        <td>{sendgridActivity?.status}</td>
        <td>{sendgridActivity?.opensCount}</td>
        <td>{sendgridActivity.clicksCount}</td>
        <td>{sendgridActivity?.lastEventTime}</td>
      </tr>
    );
  });
  return <>{tableRows}</>;
}

function DataRowsChunk({ search }: { search?: string }) {
  const sendgridActivityQuery = useSendgridActivityByEmailSearchQuery({
    userEmail: search,
  });

  const sendgridActivityList =
    sendgridActivityQuery.data?.sendgridActivityByEmailSearch.edges;
  const contentNotReady =
    sendgridActivityList === null || sendgridActivityQuery.loading;

  return (
    <>
      {contentNotReady ? (
        <LoadingTableRows rows={7} cols={7} />
      ) : (
        <FormattedTableRows sendgridActivityList={sendgridActivityList} />
      )}
    </>
  );
}

export function SendgridActivity({
  location,
  history,
}: {
  location: RouteComponentProps['location'];
  history: RouteComponentProps['history'];
}) {
  const parsedSearch = qs.parse(location.search);

  const searchTerm = ensureString(parsedSearch['q']);

  return (
    <section>
      <ContentWrapper>
        <div className="table-responsive">
          <div
            style={{
              margin: 8,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ margin: 8, display: 'flex', alignItems: 'center' }}>
              <label
                style={{
                  fontWeight: 700,
                  marginRight: 10,
                  color: '#71767E',
                  fontSize: 14,
                }}
              >
                Enter the user email with no spaces:
              </label>
              <SearchField
                onSearch={({ q }) =>
                  history.push({
                    ...location,
                    search: qs.stringify({
                      ...qs.parse(location.search),
                      q: q || undefined,
                    }),
                  })
                }
                defaultValue={qs.parse(location.search).q || ''}
              />
            </div>
          </div>

          <table className="table" style={{ position: 'relative' }}>
            <thead style={{ color: '#71767E' }}>
              <HeaderTableRow />
            </thead>
            <tbody style={{ color: '#6C757D' }}>
              <DataRowsChunk search={searchTerm} />
            </tbody>
          </table>
        </div>
      </ContentWrapper>
    </section>
  );
}
