import { useState, useEffect } from 'react';
import { lessThan24ForShoot } from './utils';

export default function MoreThan24Warning({ booking }) {
  const [hideWarning, setHideWarning] = useState<boolean>(
    booking.start_at != null && lessThan24ForShoot(booking)
  );

  useEffect(() => {
    const recalculateWarning = () => {
      const hideWarningNew =
        booking.start_at != null && lessThan24ForShoot(booking);

      if (hideWarningNew !== hideWarning) {
        setHideWarning(hideWarningNew);
      }
    };

    const interval = setInterval(recalculateWarning, 5000);
    const deactivate = () => clearInterval(interval);

    return deactivate();
  }, []);

  if (hideWarning) return null;

  return (
    <div className="alert alert-warning">
      Caution: If the customer wants to reschedule but is unsure of the new
      date, pick a date far into the future rather than cancelling.
    </div>
  );
}
