import React, { useRef } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Select from 'react-select';
import moment from 'moment-timezone';
import { isEqual, upperCase } from 'lodash';
import Component2 from '@reach/component-component';

import { ActionsDropdown } from '../../../components/ActionsDropdown';
import { gql, useMutation, useQuery } from '../../../components/ApolloClient';
import ContentWrapper from '../../../components/ContentWrapper';
import { OrderByLink } from '../../../components/OrderByLink';
import { usePromise } from '../../../components/usePromise';

type Partner = {
  uid: string;
  name: string;
};

type Package = {
  uid: string;
  duration: number;
};

type RichTextGuideline = {
  id: string;
  versionName: string;
  document: {
    id: string;
    content: string;
    createdAt?: string;
    createdBy?: { id: string; fullName: string };
  };
  status: string;
};

const serviceTypeSelectOptions = [
  { label: 'Photography', value: 'photography' },
  { label: 'Videography', value: 'videography' },
];

export const StatusLabel = ({ status }: { status: string }) => (
  <div
    style={{
      display: 'flex',
      height: 15,
      padding: '2px 4px',
      backgroundColor: status === 'active' ? '#2FB67D' : '#C1C8D4',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 1,
      marginRight: 10,
    }}
  >
    <span style={{ color: '#FFFFFF', fontSize: 10 }}>
      {status === 'active' ? 'ACTIVE' : 'INACTIVE'}
    </span>
  </div>
);

export function ListGuidelineVersionsRoute({
  match: {
    params: { partnerId, guidelineId },
  },
  location,
}: {
  match: {
    params: { partnerId: string; guidelineId: string };
  };
  location;
}) {
  const [selectedServiceType, setSelectedServiceType] = React.useState<string>(
    ''
  );
  const [selectedShootType, setSelectedShootType] = React.useState<string>('');

  const [
    selectedGuidelineProductValues,
    setSelectedGuidelineProductValues,
  ] = React.useState<string[]>([]);

  const [selectedUserType, setSelectedUserType] = React.useState<string>('');

  const [selectedIsReferral, setSelectedIsReferral] = React.useState<boolean>(
    false
  );

  const [guidelineName, setGuidelineName] = React.useState<string>('');

  const [
    isEditingGuidelineName,
    setIsEditingGuidelineName,
  ] = React.useState<boolean>(false);

  const guidelineNameInput = useRef<HTMLInputElement>(null);

  const { setPromise: setCreatingGuideline } = usePromise();

  const query = useQuery<
    {
      guideline: {
        uid: string;
        name: string;
        userType: string;
        isReferral?: boolean;
        archivedAt?: string;
        shootType?: {
          category: string;
        };
        packages?: {
          uid: string;
          serviceType: string;
          duration: number;
        }[];
        serviceType: string;
        richTextGuidelines: { edges: RichTextGuideline[] };
      };

      getPublicShootTypes: {
        displayName: string;
        category: string;
      }[];

      partnerById: Partner;
    },
    { guidelineId?: string; partnerId?: string }
  >(
    gql`
      query GetGuidelineOnRichTextVersionsByIdAsAdmin(
        $guidelineId: ID!
        $partnerId: UUID!
      ) {
        guideline: partnerGuidelineByIdAsAdmin(guidelineId: $guidelineId) {
          uid
          name
          userType
          isReferral
          archivedAt

          shootType {
            category
          }

          packages {
            uid
            serviceType
            duration
          }

          richTextGuidelines {
            edges {
              id
              versionName
              status
              document {
                id
                content
                createdAt
                createdBy {
                  id
                  fullName
                }
              }
            }
          }
        }

        partnerById(uid: $partnerId) {
          uid
          name
        }

        getPublicShootTypes {
          category
          displayName
        }
      }
    `,
    {
      variables: { guidelineId, partnerId },
      onCompleted: (data) => {
        setSelectedServiceType(data.guideline.packages?.[0]?.serviceType ?? '');
        setSelectedShootType(data.guideline.shootType?.category ?? '');
        setSelectedGuidelineProductValues(
          data?.guideline.packages?.map(({ uid }) => uid) ?? []
        );
        setSelectedUserType(data.guideline.userType.toUpperCase());
        setSelectedIsReferral(data.guideline.isReferral ?? false);
      },
    }
  );

  const originalData = query.data?.guideline;

  const productsByShootTypeQuery = useQuery<
    {
      getPublicPackagesByShootTypeForGuidelineCreationAsAdmin?: {
        edges: Package[];
      };
    },
    {
      shootType: string;
      partnerId: string;
      serviceType: string;
    }
  >(
    gql`
      query GetProductsByShootTypeOnRichTextVersions(
        $shootType: String!
        $partnerId: ID!
        $serviceType: String!
      ) {
        getPublicPackagesByShootTypeForGuidelineCreationAsAdmin(
          shootType: $shootType
          partnerId: $partnerId
          serviceType: $serviceType
        ) {
          edges {
            uid
            duration
          }
        }
      }
    `,
    {
      variables: {
        shootType: selectedShootType ?? originalData?.shootType?.category,
        serviceType:
          selectedServiceType ?? originalData?.packages?.[0]?.serviceType,
        partnerId,
      },
      skip:
        originalData?.shootType?.category == null && selectedShootType == null,
    }
  );

  const partner = query.data?.partnerById;

  const guideline = query.data?.guideline;

  const richTextGuidelines = query.data?.guideline?.richTextGuidelines?.edges;

  const shootTypes = query.data?.getPublicShootTypes;

  const packages =
    productsByShootTypeQuery.data
      ?.getPublicPackagesByShootTypeForGuidelineCreationAsAdmin?.edges;

  const [
    commitPartnerGuidelineUpdateAsAdmin,
    commitPartnerGuidelineUpdateAsAdminMutation,
  ] = useMutation<
    {
      partnerGuidelineUpdateAsAdmin: {
        guideline: {
          uid: string;
          name: string;
        };
      };
    },
    {
      guidelineId: string;
      partnerId: string;
      isReferral?: boolean;
      shootType?: string;
      productIds?: string[];
      userType?: string;
      name?: string;
    }
  >(
    gql`
      mutation PartnerGuidelineOnRichTextVersionsUpdateAsAdmin(
        $guidelineId: ID!
        $partnerId: ID!
        $isReferral: Boolean
        $shootType: String
        $productIds: [ID!]
        $userType: PartnerGuidelineUserTypeAsAdmin
        $name: String
      ) {
        partnerGuidelineUpdateAsAdmin(
          input: {
            guidelineId: $guidelineId
            partnerId: $partnerId
            isReferral: $isReferral
            shootType: $shootType
            productIds: $productIds
            userType: $userType
            name: $name
          }
        ) {
          guideline {
            uid
            name
            userType
            isReferral
          }
        }
      }
    `
  );

  const [duplicateVersion, duplicateVersionMutation] = useMutation<
    {
      richTextGuideline: {
        id: string;
      };
    },
    {
      partnerGuidelineId: string;
      content: string;
      versionName?: string;
    }
  >(gql`
    mutation RichTextGuidelineVersionDuplicationCreate(
      $partnerGuidelineId: ID!
      $content: String!
    ) {
      richTextGuidelineCreate(
        input: { partnerGuidelineId: $partnerGuidelineId, content: $content }
      ) {
        richTextGuideline {
          id
        }
      }
    }
  `);

  const ALL_PACKAGES_OPTION = { value: 'ALL_PACKAGES', label: 'All packages' };

  const options = (packages ?? []).map((o) => ({
    label: `${o.duration / 60} ${o.duration / 60 === 1 ? 'Hour' : 'Hours'}`,
    value: o.uid,
  }));

  const selectedProductValuesOptions =
    selectedShootType === 'realestate'
      ? [ALL_PACKAGES_OPTION]
      : [...options, ALL_PACKAGES_OPTION].filter((o) =>
          selectedGuidelineProductValues.includes(o.value)
        );

  const loading =
    query.loading ||
    commitPartnerGuidelineUpdateAsAdminMutation.loading ||
    duplicateVersionMutation.loading;

  function onSubmit(event) {
    event.preventDefault();

    commitPartnerGuidelineUpdateAsAdmin({
      variables: {
        guidelineId: guidelineId,
        partnerId: partnerId,
        ...(selectedShootType !== originalData?.shootType?.category
          ? { shootType: selectedShootType }
          : {}),
        ...(isEqual(
          selectedGuidelineProductValues,
          originalData?.packages?.map(({ uid }) => uid) ?? []
        ) === false
          ? {
              productIds: selectedGuidelineProductValues?.includes(
                ALL_PACKAGES_OPTION.value
              )
                ? options.map((o) => o.value)
                : selectedGuidelineProductValues,
            }
          : {}),
        ...(selectedUserType !== upperCase(originalData?.userType)
          ? { userType: selectedUserType }
          : {}),
        ...(selectedIsReferral !== originalData?.isReferral
          ? { isReferral: selectedIsReferral }
          : {}),
      },
    });
  }

  const handleSaveName = async () => {
    await commitPartnerGuidelineUpdateAsAdmin({
      variables: {
        guidelineId: guidelineId,
        partnerId: partnerId,
        name: guidelineName,
      },
    });

    await query.refetch();

    setIsEditingGuidelineName(false);
  };

  React.useEffect(() => {
    if (guideline?.name != null) {
      setGuidelineName(guideline.name);
    }
  }, [guideline?.name]);

  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <NavLink className="mb-0" exact to={'/enterprises'}>
          <h2>Enterprise</h2>
        </NavLink>

        <h2 style={{ marginLeft: 10, marginRight: 10, fontWeight: 'normal' }}>
          /
        </h2>

        <NavLink
          className="mb-0"
          exact
          to={`/enterprises/${partner?.uid}?selectedTab=GUIDELINES_AND_PACKAGES`}
        >
          <h2>{partner?.name}</h2>
        </NavLink>

        <h2 style={{ marginLeft: 10, marginRight: 10, fontWeight: 'normal' }}>
          /
        </h2>

        {guideline?.name != null && (
          <div className="d-flex flex-row align-items-center">
            <div
              style={{
                width: isEditingGuidelineName ? '100%' : 'fit-content',
                paddingLeft: 6,
                paddingRight: 12,
                borderRadius: 4,
              }}
            >
              {isEditingGuidelineName === true ? (
                <Component2
                  didMount={() => {
                    guidelineNameInput?.current?.focus();
                  }}
                >
                  <form
                    className="d-flex align-items-center"
                    onSubmit={(e) => {
                      e.preventDefault();

                      if (guidelineName === '') return;

                      handleSaveName();
                    }}
                  >
                    <input
                      ref={guidelineNameInput}
                      type="text"
                      size={15}
                      style={{
                        width: '100%',
                        fontSize: 32,
                        color: '#141B24',
                        border: 'none',
                        padding: 0,
                        paddingBottom: 8,
                        maxHeight: 38,
                      }}
                      className="form-control"
                      placeholder="Version name"
                      name="guideline name"
                      value={guidelineName ?? ''}
                      onChange={(e) =>
                        setGuidelineName(
                          e.target.value === '' ? '' : e.target.value
                        )
                      }
                      required
                    />

                    <button
                      type="submit"
                      className="btn btn-primary ml-2"
                      disabled={
                        commitPartnerGuidelineUpdateAsAdminMutation.loading ||
                        guidelineName === ''
                      }
                    >
                      Save
                    </button>
                  </form>
                </Component2>
              ) : (
                <div className="d-flex align-items-center">
                  <h2
                    style={{
                      padding: 0,
                    }}
                  >
                    {guideline.name}{' '}
                    <span className="text-muted">
                      {guideline?.archivedAt != null && '(archived)'}
                    </span>
                  </h2>

                  <button
                    onClick={() => {
                      setIsEditingGuidelineName(true);
                    }}
                    style={{
                      backgroundColor: 'transparent',
                      color: 'gray',
                      border: 'none',
                      outline: 'none',
                      paddingBottom: 10,
                      maxHeight: 42,
                      marginLeft: 8,
                    }}
                  >
                    <i aria-hidden="true" className="fa fa-pencil fa-lg" />
                  </button>
                </div>
              )}
            </div>
          </div>
        )}

        <style jsx>{`
          a {
            text-decoration: none;
            margin-bottom: 2rem;
            h2 {
              margin: 0;
            }
            &.active {
              border-bottom: 3px #1f6fff solid;
            }
          }
        `}</style>
      </div>

      <ContentWrapper style={{ backgroundColor: '#FFF' }}>
        <div className="d-flex flex-column">
          <h2 className="p-2">Guideline details</h2>

          <form id="guideline-form" onSubmit={(e) => onSubmit(e)}>
            <div
              className="d-flex flex-row align-items-center p-2"
              style={{ gap: 16 }}
            >
              <div style={{ width: '100%' }}>
                <select
                  className="form-control d-inline"
                  value={selectedServiceType}
                  disabled={loading}
                  onChange={({ target: { value } }) => {
                    setSelectedServiceType(value);
                  }}
                >
                  <option value="">Select service type</option>
                  {serviceTypeSelectOptions.map((serviceType) => (
                    <option key={serviceType.value} value={serviceType.value}>
                      {serviceType.label}
                    </option>
                  ))}
                </select>
              </div>

              <div style={{ width: '100%' }}>
                <select
                  className="form-control d-inline"
                  value={selectedShootType}
                  disabled={loading}
                  onChange={({ target: { value } }) => {
                    setSelectedShootType(value);

                    if (value === 'realestate') {
                      setSelectedGuidelineProductValues([
                        ALL_PACKAGES_OPTION.value,
                      ]);
                    } else {
                      setSelectedGuidelineProductValues([]);
                    }
                  }}
                >
                  <option value="">Select shoot type</option>
                  {(shootTypes ?? []).map((shootType) => (
                    <option key={shootType.category} value={shootType.category}>
                      {shootType.displayName}
                    </option>
                  ))}
                </select>
              </div>

              <div style={{ width: '100%' }}>
                <Select
                  isMulti
                  isDisabled={
                    packages == null ||
                    packages?.length === 0 ||
                    originalData?.shootType?.category === 'realestate' ||
                    loading
                  }
                  menuPosition="fixed"
                  value={selectedProductValuesOptions}
                  placeholder="Select packages"
                  onChange={(selectedOptions) => {
                    const selected = (selectedOptions ?? []).map(
                      (s) => s.value
                    );
                    const packagesToUpdate =
                      selected.length > 0
                        ? selected.includes(ALL_PACKAGES_OPTION.value)
                          ? [ALL_PACKAGES_OPTION.value]
                          : selected
                        : [];

                    setSelectedGuidelineProductValues(packagesToUpdate);
                  }}
                  options={[ALL_PACKAGES_OPTION, ...options]}
                />
              </div>

              <div style={{ width: '100%' }}>
                <select
                  className="form-control d-inline"
                  value={selectedUserType}
                  disabled={loading}
                  onChange={({ target: { value } }) =>
                    setSelectedUserType(value)
                  }
                >
                  <option value="">Select user type</option>
                  <option value="END_CUSTOMER">End customer</option>
                  <option value="ARTISTS">Photographer</option>
                </select>
              </div>

              <div className="d-flex flex-row align-items-center">
                <input
                  id="isReferralGuideline"
                  className="mr-2"
                  type="checkbox"
                  disabled={loading}
                  checked={selectedIsReferral}
                  onChange={({ target: { checked } }) =>
                    setSelectedIsReferral(checked)
                  }
                />

                <label
                  htmlFor="isReferralGuideline"
                  className="text-secondary text-nowrap mb-0"
                >
                  This guideline is for referral campaigns
                </label>
              </div>
            </div>
          </form>
        </div>
      </ContentWrapper>

      <ContentWrapper style={{ backgroundColor: '#FFF' }}>
        {query.loading || query.data == null ? (
          <p>Loading...</p>
        ) : (
          <div
            className="d-flex flex-column align-items-center"
            style={{ minHeight: 300 }}
          >
            <div className="table-responsive p-2" style={{ minHeight: 400 }}>
              <table className="table table-hover mb-0">
                <thead>
                  <tr>
                    <th className="text-muted text-truncate">
                      <OrderByLink attrName="Version" location={location}>
                        Version
                      </OrderByLink>
                    </th>

                    <th className="text-muted text-truncate">
                      <OrderByLink attrName="status" location={location}>
                        Status
                      </OrderByLink>
                    </th>

                    <th className="text-muted text-truncate">
                      <OrderByLink attrName="last_updated" location={location}>
                        Last updated
                      </OrderByLink>
                    </th>

                    <th className="text-muted text-truncate">
                      <OrderByLink
                        attrName="last_updated_by"
                        location={location}
                      >
                        Last updated by
                      </OrderByLink>
                    </th>

                    <th className="text-muted text-truncate" />
                  </tr>
                </thead>

                <tbody>
                  {(richTextGuidelines ?? []).map((richTextGuideline) => (
                    <tr
                      key={richTextGuideline.id}
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      <td>
                        <Link
                          to={`/enterprises/${partnerId}/guidelines/${guidelineId}/version/${richTextGuideline.id}/edit`}
                        >
                          {richTextGuideline.versionName}
                        </Link>
                      </td>

                      <td
                        style={{
                          maxWidth: '300px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            paddingTop: 6,
                            flexDirection: 'row',
                          }}
                        >
                          <StatusLabel status={richTextGuideline.status} />
                        </div>
                      </td>

                      <td>
                        {richTextGuideline.document.createdAt != null
                          ? moment(richTextGuideline.document.createdAt).format(
                              'D MMM YY'
                            )
                          : '-'}
                      </td>

                      <td>
                        {richTextGuideline.document.createdBy?.fullName ?? '-'}
                      </td>

                      <td className="text-right">
                        <ActionsDropdown>
                          {({ onClose }) => (
                            <div
                              style={{
                                textAlign: 'left',
                                flexDirection: 'column',
                              }}
                            >
                              <button
                                className="d-flex btn"
                                onClick={() =>
                                  setCreatingGuideline(
                                    duplicateVersion({
                                      variables: {
                                        partnerGuidelineId: guidelineId,
                                        content:
                                          richTextGuideline.document?.content,
                                      },
                                    })
                                      .then(() => query.refetch())
                                      .then(() => onClose())
                                  )
                                }
                              >
                                Duplicate guideline
                              </button>
                            </div>
                          )}
                        </ActionsDropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </ContentWrapper>

      <div
        className="bg-white p-3 px-4 d-flex justify-content-end "
        style={{
          position: 'fixed',
          bottom: 0,
          right: 0,
          left: 0,
          marginLeft: 200,
          boxShadow: '0 0 1.625em rgba(0,0,0,.05)',
          borderTop: '1px solid #E6E9F2',
          zIndex: 1,
        }}
      >
        <button
          className="btn btn-dark"
          disabled={
            selectedShootType === originalData?.shootType?.category &&
            isEqual(
              selectedGuidelineProductValues,
              originalData?.packages?.map(({ uid }) => uid)
            ) &&
            selectedUserType === originalData?.userType.toUpperCase() &&
            selectedIsReferral === originalData?.isReferral
          }
          type="submit"
          form="guideline-form"
        >
          {commitPartnerGuidelineUpdateAsAdminMutation.loading
            ? 'Saving...'
            : 'Save changes'}
        </button>
      </div>
    </>
  );
}
