import { gql, useQuery } from '../../../components/ApolloClient';
import {
  QualityControlMediaCriteriaStatusEnum,
  QualityControlMediaStatusEnum,
  QualityControlStatusEnum,
} from '../types';

export type IQualityControl = {
  id: string;
  dueAt?: string;
  createdAt: string;
  status: QualityControlStatusEnum;
  name?: string;
  partner: {
    uid: string;
    name: string;
  };
  createdBy: {
    uid: string;
  };
  specialist?: {
    id: string;
    fullName: string;
  };
  criteria?: {
    edges?: {
      id: string;
      description: string;
    }[];
    total?: number;
  };
  vertical: string;
  medias?: {
    edges?: {
      id: string;
      status: QualityControlMediaStatusEnum;
      large?: {
        url: string;
      };
      fileName: string;
      width: number;
      height: number;
      fileSize: number;
      // parse mime type
      type: string;
      criteria?: {
        edges?: {
          id: string;
          criteriaId: string;
          status?: QualityControlMediaCriteriaStatusEnum;
        }[];
        total: number;
      };
    }[];
    total: number;
  };
  totalImagesAccepted?: number;
  totalImagesRejected?: number;
  totalImagesPending?: number;
};

export function useQualityControlByIdQuery({
  qualityControlJobId,
  mediaStatus,
}: {
  qualityControlJobId: string | undefined;
  mediaStatus?: QualityControlMediaStatusEnum | undefined;
}) {
  return useQuery<{
    qualityControlByIdAsAdmin: IQualityControl;
  }>(
    gql`
      query QualityControlByIdAsAdminQuery(
        $id: ID!
        $mediaStatus: QualityControlMediaStatusEnum
      ) {
        qualityControlByIdAsAdmin(id: $id) {
          id
          partner {
            uid
            name
          }
          status
          name
          totalImagesAccepted
          totalImagesRejected
          totalImagesPending
          criteria {
            edges {
              id
              description
            }
            total
          }
          medias(status: $mediaStatus) {
            edges {
              id
              status
              large {
                url
              }
              fileName
              width
              height
              fileSize
              type
              criteria {
                edges {
                  id
                  criteriaId
                  status
                }
                total
              }
            }
            total
          }
        }
      }
    `,
    {
      variables: { id: qualityControlJobId, mediaStatus },
    }
  );
}
