import * as React from 'react';

export const NotesModalForm = ({
  onSubmit,
  onClose,
  disabled,
}: {
  onSubmit: ({ newNote }: { newNote: string }) => void;
  onClose: () => void;
  disabled: boolean;
}) => {
  const [note, setNote] = React.useState<string | undefined>();

  return (
    <form
      className="p-3"
      style={{ borderTop: '1px solid #C1C8D4' }}
      onSubmit={(e) => {
        e.preventDefault();
        if (note == null) {
          return;
        }
        onSubmit({ newNote: note.trim() });
        setNote('');
      }}
    >
      <textarea
        disabled={disabled}
        rows={3}
        className="form-control"
        placeholder="Add your comment"
        value={note}
        onChange={(e) => setNote(e.target.value)}
      />

      <div style={{ height: 16, width: '100%' }} />

      <div className="d-flex justify-content-between">
        <button
          type="button"
          disabled={disabled}
          className="btn btn-secondary w-25"
          onClick={onClose}
        >
          Close
        </button>
        <button
          className="btn btn-primary w-25"
          type="submit"
          disabled={disabled || note == null || note.trim() === ''}
        >
          Save
        </button>
      </div>
    </form>
  );
};

export const NotesModalBody = ({
  notes,
}: {
  notes?: {
    id: string;
    text: string;
    createdAt?: string;
    createdBy: {
      id: string;
      fullName: string;
    };
  }[];
}) => {
  return (
    <div
      className="card-body d-flex flex-column p-3"
      style={{ height: 448, overflow: 'scroll' }}
    >
      {notes != null && notes?.length > 0 ? (
        notes?.map((note) => {
          return (
            <div
              key={note.id}
              style={{
                padding: 16,
                border: '1px solid #C1C8D4',
                marginBottom: 16,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  color: '#71767E',
                }}
              >
                <p>{note.createdBy.fullName}</p>
                <p>{note.createdAt}</p>
              </div>
              <div style={{ color: '#141B24' }}>{note.text}</div>
            </div>
          );
        })
      ) : (
        <div
          className="text-center"
          style={{ margin: 'auto', color: '#C1C8D4', fontSize: 22 }}
        >
          No notes
        </div>
      )}
    </div>
  );
};
