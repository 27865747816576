import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import Textarea from 'react-textarea-autosize';

import BaseLayout from '../components/BaseLayout';
import { Promiser } from '../components/Promiser';
import { apiFetch } from '../utils';
import { useMutation, gql, useLazyQuery } from '../components/ApolloClient';
import { ensureString } from '../utils/strings';

type IState = {
  file: null | File;
  axiosPromise: null | Promise<{}>;
  uploadPromise: null | Promise<{}>;
  uploadProgress: null | number;
};

interface IAdminAutoEditingJob {
  id: string;
  name?: string;
  items: {
    edges: {
      id: string;
      finalImage?: {
        uid: string;
        large?: { url?: string };
        medium?: { url?: string };
      };

      originalImage?: {
        uid: string;
        large?: { url?: string };
        medium?: { url?: string };
      };
    }[];
  };
}

class Uploader extends React.Component<{ token: string }, IState> {
  state: IState = {
    axiosPromise: null,
    file: null,
    uploadPromise: null,
    uploadProgress: null,
  };

  file: null | File = null;

  componentDidMount() {
    this.setState({
      axiosPromise: import('axios').then(({ default: axios }) => axios),
    });
  }

  componentWillUnmount() {
    this.file = null;
  }

  render() {
    const { token } = this.props;
    const { axiosPromise, uploadPromise, file, uploadProgress } = this.state;
    return (
      <Promiser promise={axiosPromise}>
        {({ isPending: axiosIsPending, result: axios }) => (
          <Promiser promise={uploadPromise}>
            {({
              isPending: uploadIsPending,
              error: uploadError,
              result: media,
            }) => (
              <form
                className="d-flex"
                onSubmit={(ev) => {
                  ev.preventDefault();
                  const axiosCancelSource = axios.CancelToken.source();
                  this.setState({
                    uploadPromise: apiFetch(`/api/v2/media/presigned-post`, {
                      token,
                      method: 'POST',
                      body: JSON.stringify({ fileType: file?.type }),
                    }).then(
                      ({ signedUrl, mediaUid }) =>
                        this.file === file &&
                        axios
                          .put(signedUrl, file, {
                            onUploadProgress: (progressEvent) => {
                              if (this.file !== file) {
                                return axiosCancelSource.cancel();
                              }

                              this.setState({
                                uploadProgress: Math.round(
                                  (progressEvent.loaded * 100) /
                                    progressEvent.total
                                ),
                              });
                            },
                            cancelToken: axiosCancelSource.token,
                            headers: { 'Content-Type': file?.type },
                          })
                          .then(
                            () =>
                              this.file === file &&
                              apiFetch('/api/v2/media/create-from-temp', {
                                token,
                                method: 'POST',
                                body: JSON.stringify({
                                  tempMediaUid: mediaUid,
                                  fileName: file?.name,
                                }),
                              })
                          )
                    ),
                  });
                }}
              >
                {uploadPromise ? (
                  uploadIsPending ? (
                    <input
                      type="text"
                      className="form-control"
                      readOnly
                      value={
                        uploadProgress === null
                          ? 'Starting upload...'
                          : uploadProgress !== 100
                          ? `Uploading (${uploadProgress}%)`
                          : 'Finishing...'
                      }
                    />
                  ) : uploadError ? (
                    <input
                      type="text"
                      className="form-control"
                      readOnly
                      value={uploadError}
                    />
                  ) : (
                    media && (
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        value={media.uid}
                        onClick={(ev) =>
                          // @ts-ignore
                          ev.target.select()
                        }
                      />
                    )
                  )
                ) : (
                  <div className="custom-file">
                    <input
                      type="file"
                      className="custom-file-input"
                      accept=".jpg, .jpeg, .png"
                      disabled={axiosIsPending}
                      onChange={({ target: { files } }) => {
                        const file = files?.[0] ?? null;
                        this.file = file;
                        this.setState({ file });
                      }}
                    />

                    <label className="custom-file-label">
                      {axiosIsPending
                        ? 'Loading...'
                        : file
                        ? file.name
                        : 'Choose file'}
                    </label>
                  </div>
                )}

                <button
                  className="btn btn-secondary ml-2"
                  disabled={!file}
                  onClick={() => {
                    this.setState({ file: null, uploadPromise: null });
                    this.file = null;
                  }}
                >
                  {uploadPromise && !uploadIsPending ? 'Clear' : 'Cancel'}
                </button>

                <button
                  type="submit"
                  className="btn btn-primary ml-2"
                  disabled={!file || uploadIsPending}
                >
                  Upload
                </button>
              </form>
            )}
          </Promiser>
        )}
      </Promiser>
    );
  }
}

function PushSender() {
  const [sendPush, sendPushMutation] = useMutation<
    { sendPushMessageAsAdmin: { devicesCount: number } },
    {
      emails: string[];
      title: string;
      body?: string;
    }
  >(
    gql`
      mutation SendPush($emails: [String]!, $title: String!, $body: String) {
        sendPushMessageAsAdmin(
          input: { emails: $emails, title: $title, body: $body }
        ) {
          devicesCount
        }
      }
    `
  );

  const emailRef = React.useRef<HTMLInputElement>(null);
  const titleRef = React.useRef<HTMLInputElement>(null);
  const bodyRef = React.useRef<HTMLTextAreaElement>(null);

  return (
    <>
      {!sendPushMutation.loading && sendPushMutation.data != null && (
        <div className="alert alert-success" role="alert">
          Sent to {sendPushMutation.data.sendPushMessageAsAdmin.devicesCount}{' '}
          {sendPushMutation.data.sendPushMessageAsAdmin.devicesCount === 1
            ? 'device'
            : 'devices'}
        </div>
      )}

      {!sendPushMutation.loading &&
        sendPushMutation.error?.graphQLErrors?.[0]?.extensions
          ?.notFoundEmails != null && (
          <div className="alert alert-danger" role="alert">
            No user found for emails:{' '}
            {(sendPushMutation.error
              ?.graphQLErrors as any)[0].extensions?.notFoundEmails.join(', ')}
          </div>
        )}

      <form
        onSubmit={(ev) => {
          ev.preventDefault();

          sendPush({
            variables: {
              emails: emailRef.current!.value.split(','),
              title: titleRef.current!.value,
              body: bodyRef.current!.value,
            },
          });
        }}
      >
        <div className="form-group">
          <label htmlFor="pushEmail">
            User email <small>(if more than one separate by comma)</small>
          </label>

          <input
            ref={emailRef}
            type="text"
            className="form-control"
            id="pushEmail"
          />
        </div>

        <div className="form-group">
          <label htmlFor="pushTitle">Title</label>

          <input
            ref={titleRef}
            type="text"
            className="form-control"
            id="pushTitle"
          />
        </div>

        <div className="form-group">
          <label htmlFor="pushBody">Body</label>

          <textarea ref={bodyRef} className="form-control" id="pushBody" />
        </div>

        <div className="d-flex justify-content-end">
          <button
            className="btn btn-primary"
            disabled={sendPushMutation.loading}
          >
            Send
          </button>
        </div>
      </form>
    </>
  );
}

function EmailTester() {
  const location = useLocation();
  const history = useHistory();

  const [sendNotification, sendNotificationMutation] = useMutation<
    { sendNotificationAsAdmin: { success: boolean } },
    {
      name: string;
      jsonPayload: string;
    }
  >(
    gql`
      mutation RandomSendNotification($name: String!, $jsonPayload: String!) {
        sendNotificationAsAdmin(
          input: { name: $name, jsonPayload: $jsonPayload }
        ) {
          success
        }
      }
    `
  );

  const emailTesterQueryData: {
    name?: string;
    jsonPayload?: string;
  } = (() => {
    try {
      return (
        JSON.parse(
          ensureString(qs.parse(location.search).emailTester) ?? '{}'
        ) ?? {}
      );
    } catch (err) {
      return {};
    }
  })();

  return (
    <>
      <form
        onSubmit={(ev) => {
          ev.preventDefault();

          sendNotification({
            variables: {
              name: emailTesterQueryData.name!,
              jsonPayload: emailTesterQueryData.jsonPayload!,
            },
          });
        }}
      >
        <div className="form-group">
          <label htmlFor="emailTesterEmail">Notification name</label>

          <input
            type="text"
            className="form-control"
            id="emailTesterEmail"
            value={emailTesterQueryData.name ?? ''}
            onChange={({ target: { value } }) =>
              history.replace({
                ...location,
                search: qs.stringify({
                  ...qs.parse(location.search),
                  emailTester: JSON.stringify({
                    ...emailTesterQueryData,
                    name: value === '' ? undefined : value,
                  }),
                }),
              })
            }
          />
        </div>

        <div className="form-group">
          <label htmlFor="emailTesterJSON">JSON</label>

          <Textarea
            className="form-control"
            id="emailTesterJSON"
            minRows={6}
            type="text"
            value={emailTesterQueryData.jsonPayload ?? ''}
            onChange={({ target: { value } }) =>
              history.replace({
                ...location,
                search: qs.stringify({
                  ...qs.parse(location.search),
                  emailTester: JSON.stringify({
                    ...emailTesterQueryData,
                    jsonPayload: value === '' ? undefined : value,
                  }),
                }),
              })
            }
          />
        </div>

        <div className="d-flex justify-content-end">
          <button
            className="btn btn-primary"
            // disabled={sendPushMutation.loading}
          >
            Send
          </button>
        </div>
      </form>
    </>
  );
}

export const RandomRoute = ({ session, match }) => (
  <BaseLayout session={session} match={match}>
    <h2>Random</h2>

    <div className="d-flex">
      <div className="card mt-3 flex-1" style={{ minWidth: 0 }}>
        <div className="card-body">
          <h4>Media uploader</h4>

          <Uploader token={session.token} />
        </div>
      </div>

      <div className="mr-3" />

      <div className="card mt-3 flex-1" style={{ minWidth: 0 }}>
        <div className="card-body">
          <h4>Push sender</h4>

          <PushSender />
        </div>
      </div>
    </div>

    <div className="d-flex">
      <div className="card mt-3 flex-1" style={{ minWidth: 0 }}>
        <div className="card-body">
          <h4>Email tester</h4>

          <EmailTester />
        </div>
      </div>

      <div className="mr-3" />

      <div className="card mt-3 flex-1" style={{ minWidth: 0 }} />
    </div>

    <div className="d-flex">
      <div className="card mt-3 flex-1" style={{ minWidth: 0 }}>
        <div className="card-body">
          <h4>Admin Auto editing trigger</h4>
          <AutoEditing />
        </div>
      </div>
    </div>

    <div className="d-flex">
      <div className="card mt-3 flex-1" style={{ minWidth: 0 }}>
        <div className="card-body">
          <h4>Admin Auto editing jobs grid</h4>
          <AdminAutoEditingJobsGrid />
        </div>
      </div>
    </div>
  </BaseLayout>
);

function AutoEditing({}) {
  const originalMediaIdInputRef = React.useRef<HTMLTextAreaElement>(null);
  const presetsUrlsInputRef = React.useRef<HTMLTextAreaElement>(null);

  const [editMedia, editMediaMutation] = useMutation<
    {
      adminAutoEditingCreateAsAdmin: {
        adminAutoEditingJobs?: IAdminAutoEditingJob[];
      };
    },
    { originalMediasId: string[]; xmpPresetsUrl?: string[] }
  >(
    gql`
      mutation AdminAutoEditingCreateAsAdmin(
        $originalMediasId: [String]!
        $xmpPresetsUrl: [String]
      ) {
        adminAutoEditingCreateAsAdmin(
          input: {
            originalMediasId: $originalMediasId
            xmpPresetsUrl: $xmpPresetsUrl
          }
        ) {
          adminAutoEditingJobs {
            id
          }
        }
      }
    `
  );

  const adminAutoEditingJobIdsResult = editMediaMutation.data?.adminAutoEditingCreateAsAdmin.adminAutoEditingJobs
    ?.map((e) => e.id)
    ?.join(',');

  return (
    <>
      <form
        onSubmit={(event) => {
          event.preventDefault();

          editMedia({
            variables: {
              originalMediasId: originalMediaIdInputRef.current?.value.split(
                ','
              ) ?? [''],
              xmpPresetsUrl:
                presetsUrlsInputRef.current?.value != null &&
                presetsUrlsInputRef.current?.value !== ''
                  ? presetsUrlsInputRef.current?.value.split(',')
                  : undefined,
            },
          });
        }}
      >
        <div className="form-group">
          <label htmlFor="media-ids">Media ids you want to edit</label>

          <textarea
            ref={originalMediaIdInputRef}
            rows={2}
            className="form-control"
            id="media-ids"
          />

          {/* <select  /> */}
        </div>

        <div className="form-group">
          <label htmlFor="preset-url">Preset urls</label>

          <textarea
            ref={presetsUrlsInputRef}
            rows={2}
            className="form-control"
            id="preset-url"
          />
        </div>

        <div className="d-flex justify-content-end">
          <button
            className="btn btn-primary"
            disabled={editMediaMutation.loading}
          >
            {editMediaMutation.loading === true ? 'Editing...' : 'Edit'}
          </button>
        </div>

        <div className="form-group">
          <label htmlFor="admin-auto-editing-jobs-id-results">
            AutoEditingJobs results
          </label>

          <textarea
            value={adminAutoEditingJobIdsResult}
            rows={2}
            className="form-control"
            id="admin-auto-editing-jobs-id-results"
            readOnly
          />

          {/* <select  /> */}
        </div>
      </form>
    </>
  );
}

function AdminAutoEditingJobsGrid() {
  const adminAutoEditingJobsIdInputRef = React.useRef<HTMLTextAreaElement>(
    null
  );

  const [
    getAdminAutoEditingJobsQuery,
    getAdminAutoEditingJobsQueryState,
  ] = useLazyQuery<{
    getAdminAutoEditingJobsById?: IAdminAutoEditingJob[];
  }>(
    gql`
      query GetAdminAutoEditingJobsById($ids: [ID]!) {
        getAdminAutoEditingJobsById(ids: $ids) {
          id
          name
          items {
            edges {
              id
              finalImage {
                uid
                large {
                  url
                }
                medium {
                  url
                }
              }
              originalImage {
                uid
                large {
                  url
                }
                medium {
                  url
                }
              }
            }
          }
        }
      }
    `
  );

  const editResults =
    getAdminAutoEditingJobsQueryState.data?.getAdminAutoEditingJobsById;

  const [hiddenSet, setHiddenSet] = React.useState<Set<string>>(
    () => new Set()
  );

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();

        getAdminAutoEditingJobsQuery({
          variables: {
            ids: adminAutoEditingJobsIdInputRef.current?.value.split(','),
          },
        });
      }}
    >
      <div className="form-group">
        <label htmlFor="result-media-ids">Admin Auto Job Ids</label>

        <textarea
          ref={adminAutoEditingJobsIdInputRef}
          rows={1}
          className="form-control"
          id="result-media-ids"
          // value={editResults?.map((result) => result.id).join(',')}
        />
      </div>

      <div className="d-flex justify-content-end">
        <button
          className="btn btn-primary"
          disabled={getAdminAutoEditingJobsQueryState.loading}
        >
          {getAdminAutoEditingJobsQueryState.loading === true
            ? 'Loading...'
            : 'Load results'}
        </button>
      </div>

      <div className="table-responsive">
        <table className="table table-hover mt-3 mb-0">
          <thead>
            <tr>
              <th>Original media</th>
              {editResults
                ?.filter(
                  (r) => r.name != null && hiddenSet.has(r.name) === false
                )
                .map((editResults) => (
                  <th>
                    Final media job {editResults.name}{' '}
                    <button
                      className="btn btn-sm btn-secondary"
                      onClick={() =>
                        setHiddenSet((prevSet) => {
                          if (editResults.name == null) return prevSet;

                          const newSet = new Set(prevSet).add(editResults.name);

                          return newSet;
                        })
                      }
                    >
                      Hide
                    </button>
                  </th>
                ))}
            </tr>
          </thead>

          <tbody>
            {getAdminAutoEditingJobsQueryState.loading === true &&
            editResults == null ? (
              <tr>Loading...</tr>
            ) : (
              editResults?.[0].items.edges.map(
                (editFirstResultItems, itemIndex) => (
                  <tr>
                    <td
                      key={editFirstResultItems.id + 'original'}
                      // @ts-ignore
                      style={{ contentVisibility: 'auto' }}
                    >
                      <a
                        href={editFirstResultItems?.originalImage?.large?.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={editFirstResultItems?.originalImage?.medium?.url}
                          width={400}
                          alt="original"
                          loading="lazy"
                        />
                      </a>
                    </td>

                    {editResults
                      ?.filter(
                        (r) => r.name != null && hiddenSet.has(r.name) === false
                      )
                      .map((adminAutoEditingJob) => (
                        <td
                          key={adminAutoEditingJob.items.edges[itemIndex].id}
                          // @ts-ignore
                          style={{ contentVisibility: 'auto' }}
                        >
                          <a
                            href={
                              adminAutoEditingJob.items.edges[itemIndex]
                                .finalImage?.large?.url
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src={
                                adminAutoEditingJob.items.edges[itemIndex]
                                  ?.finalImage?.medium?.url
                              }
                              width={400}
                              alt="final"
                              loading="lazy"
                            />
                          </a>
                        </td>
                      ))}
                  </tr>
                )
              )
            )}
          </tbody>
        </table>
      </div>
    </form>
  );
}
