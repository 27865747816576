import React from 'react';

export const LoadingModal = ({ text, style = {}, children, ...rest }) => {
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255,255,255,0.8)',
        zIndex: 1000,
        ...style,
      }}
      {...rest}
    >
      {children ||
        (text != null && (
          <p>
            Loading {text}
            ...
          </p>
        )) || <p>Loading...</p>}
    </div>
  );
};
