import * as React from 'react';

export const RainbowBorder = () => {
  return (
    <div
      style={{
        background: `linear-gradient(
      to left,
      #2fb67d 16.66%,
      #1f6fff 16.66%,
      #1f6fff 33.33%,
      #38c4f2 33.33%,
      #38c4f2 50%,
      #4b0096 50%,
      #4b0096 66.66%,
      #e11f59 66.66%,
      #e11f59 83.33%,
      #ffbb00 83.33%
    )`,
        content: '',
        height: '2px',

        left: 0,
        bottom: 0,
        right: 0,
      }}
    />
  );
};
