import { gql, useMutation, useQuery } from '../../../components/ApolloClient';
import * as React from 'react';
import { Modal } from '../../../components/Modal';
import Select from 'react-select';

export function AssignSpecialistModal({
  onDismiss,
  selectedIds,
}: {
  onDismiss: () => void;
  selectedIds: string[];
}) {
  const qualityControlSpecialistListAsAdminQuery = useQuery<{
    qualityControlSpecialistListAsAdmin: {
      edges: {
        fullName;
        id;
      }[];
    };
  }>(gql`
    query QualityControlSpecialistListAsAdmin {
      qualityControlSpecialistListAsAdmin {
        edges {
          fullName
          id
        }
      }
    }
  `);

  const [
    qualityControlSpecialistAssign,
    qualityControlSpecialistAssignMutation,
  ] = useMutation<
    {
      qualityControlSpecialistAssign: {
        qualityControlList: {
          edges: {
            id: string;
            specialist: {
              id: string;
            };
          }[];
        };
      };
    },
    { qualityControlListId: string[]; specialistId: string }
  >(
    gql`
      mutation QualityControlSpecialistAssign(
        $qualityControlListId: [ID]!
        $specialistId: ID!
      ) {
        qualityControlSpecialistAssign(
          input: {
            qualityControlListId: $qualityControlListId
            specialistId: $specialistId
          }
        ) {
          qualityControlList {
            edges {
              id
              specialist {
                id
              }
            }
          }
        }
      }
    `
  );

  const specialistList =
    qualityControlSpecialistListAsAdminQuery.data
      ?.qualityControlSpecialistListAsAdmin.edges;

  const [selectedSpecialist, setSelectedSpecialist] = React.useState<{
    value: string;
    label: string;
  }>();

  return (
    <Modal onDismiss={onDismiss}>
      <div className="card">
        <div className="card-body d-flex flex-column" style={{ minWidth: 600 }}>
          <h3>
            Assign specialist to {selectedIds.length}{' '}
            {selectedIds.length === 1 ? 'job' : 'jobs'}
          </h3>

          <form
            className="d-flex flex-column mt-4"
            onSubmit={async (ev) => {
              ev.preventDefault();

              if (selectedSpecialist != null) {
                await qualityControlSpecialistAssign({
                  variables: {
                    qualityControlListId: selectedIds,
                    specialistId: selectedSpecialist.value,
                  },
                }).then(() => onDismiss());
              }
            }}
          >
            {specialistList == null ? (
              <div>loading...</div>
            ) : (
              <React.Fragment>
                <Select
                  className="w-100 pb-4"
                  placeholder={
                    qualityControlSpecialistListAsAdminQuery.loading
                      ? 'Loading...'
                      : 'Select specialist'
                  }
                  isLoading={qualityControlSpecialistListAsAdminQuery.loading}
                  options={specialistList.map((specialist) => ({
                    label: specialist.fullName,
                    value: specialist.id,
                  }))}
                  value={selectedSpecialist}
                  onChange={(value: { label: string; value: string }) => {
                    setSelectedSpecialist(value);
                  }}
                  isSearchable
                  styles={{
                    menu: (provided, state) => ({
                      ...provided,
                      marginTop: 0,
                      top: 48,
                    }),
                    option: (styles, { data, isSelected }) => ({
                      ...styles,
                      backgroundColor: isSelected ? 'black' : undefined,
                      color: isSelected ? 'white' : undefined,
                    }),
                  }}
                />
                <div className="row">
                  <div className="col">
                    <button
                      type="button"
                      className="btn btn-outline-secondary btn-block"
                      onClick={onDismiss}
                    >
                      Cancel
                    </button>
                  </div>

                  <div className="col">
                    <button
                      type="submit"
                      className="btn btn-dark btn-block"
                      disabled={
                        selectedSpecialist == null ||
                        qualityControlSpecialistAssignMutation.loading
                      }
                    >
                      {qualityControlSpecialistAssignMutation.loading
                        ? 'Processing...'
                        : 'Confirm'}
                    </button>
                  </div>
                </div>{' '}
              </React.Fragment>
            )}
          </form>
        </div>
      </div>
    </Modal>
  );
}

export function RemoveSpecialistModal({
  onDismiss,
  selectedIds,
}: {
  onDismiss: () => void;
  selectedIds: string[];
}) {
  const [
    qualityControlSpecialistRemove,
    qualityControlSpecialistRemoveMutation,
  ] = useMutation<
    {
      qualityControlSpecialistRemove: {
        qualityControlList: {
          edges: {
            id: string;
            specialist: {
              id: string;
            };
          }[];
        };
      };
    },
    { qualityControlListId: string[] }
  >(
    gql`
      mutation qualityControlSpecialistRemove($qualityControlListId: [ID]!) {
        qualityControlSpecialistRemove(
          input: { qualityControlListId: $qualityControlListId }
        ) {
          qualityControlList {
            edges {
              id
              specialist {
                id
              }
            }
          }
        }
      }
    `
  );

  return (
    <Modal onDismiss={onDismiss}>
      <div className="card">
        <div className="card-body d-flex flex-column" style={{ minWidth: 600 }}>
          <h3>
            Remove specialist from {selectedIds.length}{' '}
            {selectedIds.length === 1 ? 'job' : 'jobs'}
          </h3>

          <form
            className="d-flex flex-column mt-5"
            onSubmit={async (ev) => {
              ev.preventDefault();

              await qualityControlSpecialistRemove({
                variables: {
                  qualityControlListId: selectedIds,
                },
              }).then(() => onDismiss());
            }}
          >
            <div className="row">
              <div className="col">
                <button
                  type="button"
                  className="btn btn-outline-secondary btn-block"
                  onClick={onDismiss}
                >
                  Cancel
                </button>
              </div>

              <div className="col">
                <button
                  type="submit"
                  className="btn btn-danger btn-block"
                  disabled={qualityControlSpecialistRemoveMutation.loading}
                >
                  Confirm
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}
