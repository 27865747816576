import moment from 'moment';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { gql, useQuery } from '../../../../components/ApolloClient';
import { ENABLE_ENTERPRISE_BOOKING_TRANSACTION_REFUNDS } from '../../../../config';
import { formatCredits } from '../../../../utils';
import { BOOKING_STATUS } from '../selectors';
import { ModalRoute, locationForModal } from './ModalRoute';
import { RefundEnterpriseTransaction } from './RefundEnterpriseTransaction';

const MODAL_CREDIT_TRANSACTION_REFUND = 'credit-transaction-refund';

export function TransactionRow({
  booking,
  transaction,

  history,
  location,
}: {
  booking: { status: string; currency: string };
  transaction: {
    id: string;
    reason: string;
    amount: number;
    createdAt: string;
  };

  history: RouteComponentProps['history'];
  location: RouteComponentProps['location'];
}) {
  return (
    <tr>
      <td className="align-middle">{transaction.reason}</td>
      <td
        className={`align-middle ${
          transaction.amount > 0 ? 'text-danger' : ''
        }`}
      >
        {formatCredits(transaction.amount * -1)} credits
      </td>
      <td className="align-middle">
        {transaction.createdAt &&
          moment(transaction.createdAt).format(
            'ddd, MMM Do YYYY, h:mm:ss a zz'
          )}
      </td>
      <td className="align-middle">
        <button
          className="btn btn-danger btn-block"
          disabled={
            ENABLE_ENTERPRISE_BOOKING_TRANSACTION_REFUNDS !== true ||
            transaction.amount * -1 < 0 ||
            ![
              BOOKING_STATUS.CANCELLED,
              BOOKING_STATUS.REFUNDED,
              BOOKING_STATUS.COMPLETED,
              BOOKING_STATUS.WITHDRAWN,
            ].includes(booking.status)
          }
          onClick={() => {
            history.replace(
              locationForModal({
                location,
                modal: {
                  modalName: MODAL_CREDIT_TRANSACTION_REFUND,
                  modalParams: { transactionId: transaction.id },
                },
              })
            );
          }}
        >
          Refund
        </button>
      </td>
    </tr>
  );
}

export function EnterpriseTransactionDetails({
  session,
  booking,
  location,
  hide = true,
  history,
}) {
  const { data, refetch, loading: transactionsLoading } = useQuery<{
    creditTransactionsByBookingIdAsAdmin?: {
      edges: {
        id: string;
        reason: string;
        amount: number;
        createdAt: string;
      }[];
    };
  }>(
    gql`
      query GetCreditTransactionsByBookingIdAsAdmin($bookingId: ID!) {
        creditTransactionsByBookingIdAsAdmin(bookingId: $bookingId) {
          edges {
            id
            reason
            amount
            createdAt
          }
        }
      }
    `,
    { variables: { bookingId: booking?.uid } }
  );

  const transactions = data?.creditTransactionsByBookingIdAsAdmin?.edges;

  return (
    <div
      className={'card-body flex-column' + (hide ? ' d-none' : ' d-flex')}
      style={{ minHeight: '350px' }}
    >
      <div>
        {!transactionsLoading && (
          <table className="table table-hover mb-0">
            <thead>
              <tr>
                <th className="text-muted text-truncate">Reason</th>
                <th className="text-muted text-truncate">Amount</th>
                <th className="text-muted text-truncate">Timestamp</th>
                <th className="text-muted text-truncate">Actions</th>
              </tr>
            </thead>

            <tbody>
              {(transactions ?? []).map((transaction) => (
                <TransactionRow
                  key={transaction.id}
                  history={history}
                  location={location}
                  booking={booking}
                  transaction={transaction}
                />
              ))}
            </tbody>
          </table>
        )}
      </div>
      <ModalRoute
        modalName={MODAL_CREDIT_TRANSACTION_REFUND}
        render={({ history, location, modalParams }) => (
          <RefundEnterpriseTransaction
            modalParams={modalParams}
            session={session}
            history={history}
            location={location}
            booking={booking}
            refetchTransactions={() => {
              refetch();
            }}
            onDismiss={() =>
              history.push(
                locationForModal({
                  location,
                  modal: undefined,
                })
              )
            }
          />
        )}
      />
    </div>
  );
}
