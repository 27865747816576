import React from 'react';
import Loadable from 'react-loadable';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import dynamic from 'next/dynamic';
import Component2 from '@reach/component-component';
import * as Sentry from '@sentry/nextjs';

import { checkIfCanAccess, checkIfDeveloper } from '../utils';
import CustomersRoute from './customers';
import { AbandonedCartsRoute } from './abandoned-carts';
import { EnterprisesRoute } from './enterprises';
import { PartnersRoute } from './partners';
import { BookingsRoute } from './bookings';
import { RandomRoute } from './random';
import { ToolsRoute } from './tools';
import BaseLayout from '../components/BaseLayout';
import { LoadingModal } from '../components/LoadingModal';
import { EditingJobsRoute } from './editing-jobs';
import { HotJarLoader } from '../components/HotJarLoader';
import { ListingSimulationsRoute } from './listing-simulator';
import { ENABLE_GEO_BOOKING_MAP, ENABLE_LISTING_SIMULATOR } from '../config';
import { BookingSlaQueueRoute } from './booking-sla-queue';
import { EnterpriseTrackerRoute } from './enterprise-tracker';
import { CallHistoryRoute } from './call-history';
import { UpcomingNoShowsRoute } from './upcoming-no-shows';
import { PGACoverageTaskRoute } from './coverage';
import { BullQueuesRoute } from './bull-queues';
import { ProductsViewRoute } from './products';
import { WorflowRunsViewRoute } from './workflows';
import { QualityControlViewRoute } from './quality-control';
import { CompletionLeadQueueRoute } from './completion-lead-queue';
import { AiPortraitsJobRoute } from './ai-portrait-job';
import { AiProductJobRoute } from './ai-product-job';
import { GoogleAdsBidRoute } from './google-ads-bid';
import { HeadShotsQCRoute } from './headshot-qc-queue';

const LoadingRouteIndicator = ({ pastDelay }) =>
  pastDelay && (
    <div className="flex-1 d-flex flex-column justify-content-center align-items-center">
      <LoadingModal />
    </div>
  );

const SalesTeamRouteAsync = Loadable({
  loader: () =>
    import(/* webpackPrefetch: true */ './sales-team').then(
      (res) => res.SalesTeamRoute
    ),
  loading: LoadingRouteIndicator,
});

const MetricsRouteAsync = Loadable({
  loader: () =>
    import(/* webpackPrefetch: true */ './metrics').then(
      (res) => res.MetricsRoute
    ),
  loading: LoadingRouteIndicator,
});

const SalesClaimsRoute = Loadable({
  loader: () =>
    import(/* webpackPrefetch: true */ './sales-claims').then(
      (res) => res.SalesClaimsRoute
    ),
  loading: LoadingRouteIndicator,
});

const ProviderApplicationsRoute = Loadable({
  loader: () =>
    import(/* webpackPrefetch: true */ './provider-applications').then(
      (res) => res.ProviderApplicationsRoute
    ),
  loading: LoadingRouteIndicator,
});

const GeoBookingAssignmentRoute = Loadable({
  loader: () =>
    import(/* webpackPrefetch: true */ './geo-booking-assignment').then(
      (res) => res.GeoBookingAssignmentRoute
    ),
  loading: LoadingRouteIndicator,
});

const PgsAndBookingsMapRoute = Loadable({
  loader: () =>
    import(/* webpackPrefetch: true */ './pgs-bookings-map').then(
      (res) => res.PgsAndBookingsMapRoute
    ),
  loading: LoadingRouteIndicator,
});

const IAMRouteAsync = Loadable({
  loader: () =>
    import(/* webpackPrefetch: true */ './iam').then((res) => res.IamRoute),
  loading: LoadingRouteIndicator,
});

const SalesLeadQueueRoute = Loadable({
  loader: () =>
    import(/* webpackPrefetch: true */ './sales-lead-queue').then(
      (res) => res.SalesLeadQueueRoute
    ),
  loading: LoadingRouteIndicator,
});

const ProvidersRoute = Loadable({
  loader: () =>
    import(/* webpackPrefetch: true */ './providers').then(
      (res) => res.ProvidersRoute
    ),
  loading: LoadingRouteIndicator,
});

const ProviderPointsRoute = Loadable({
  loader: () =>
    import(/* webpackPrefetch: true */ './provider-points').then(
      (res) => res.ProviderPointsRoute
    ),
  loading: LoadingRouteIndicator,
});

const EditingQueueRoute = Loadable({
  loader: () =>
    import(/* webpackPrefetch: true */ './editing-queue').then(
      (res) => res.EditingQueueRoute
    ),
  loading: LoadingRouteIndicator,
});

const ContractorEditingQueueRoute = Loadable({
  loader: () =>
    import(/* webpackPrefetch: true */ './contractor-editing-queue').then(
      (res) => res.ContractorEditingQueueRoute
    ),
  loading: LoadingRouteIndicator,
});

const PhotoReviewQueueRoute = Loadable({
  loader: () =>
    import(/* webpackPrefetch: true */ './photo-review-queue').then(
      (res) => res.PhotoReviewQueueRoute
    ),
  loading: LoadingRouteIndicator,
});

const SuperImageSearchRoute = Loadable({
  loader: () =>
    import(/* webpackPrefetch: true */ './super-image-search').then(
      (res) => res.SuperImageSearchRoute
    ),
  loading: LoadingRouteIndicator,
});

const CouponsRoute = Loadable({
  loader: () =>
    import(/* webpackPrefetch: true */ './coupons').then(
      (res) => res.CouponsRoute
    ),
  loading: LoadingRouteIndicator,
});

const GiftCardsRoute = Loadable({
  loader: () =>
    import(/* webpackPrefetch: true */ './gift-cards').then(
      (res) => res.GiftCardsRoute
    ),
  loading: LoadingRouteIndicator,
});

const EcommerceGuidelinesRoute = Loadable({
  loader: () =>
    import(/* webpackPrefetch: true */ './ecommerce-guideline').then(
      (res) => res.EcommerceGuidelinesRoute
    ),
  loading: LoadingRouteIndicator,
});

const AuditRoute = Loadable({
  loader: () =>
    import(/* webpackPrefetch: true */ './audit').then((res) => res.AuditRoute),
  loading: LoadingRouteIndicator,
});

const ProviderRecommendedChangesRoute = Loadable({
  loader: () =>
    import(/* webpackPrefetch: true */ './provider-recommended-changes').then(
      (res) => res.ProviderRecommendedChangesRoute
    ),
  loading: LoadingRouteIndicator,
});

const WowFactor = dynamic(() =>
  import('../components/WowFactor').then((res) => res.WowFactor)
);

const RateGallery = dynamic(() =>
  import('./bookings/gallery-upload/components/RateGallery').then(
    (res) => res.RateGallery
  )
);

const ProviderPayouts = Loadable({
  loader: () =>
    import(/* webpackPrefetch: true */ './provider-payouts').then(
      (res) => res.ProviderPayouts
    ),
  loading: LoadingRouteIndicator,
});

const PrintJobsRoute = Loadable({
  loader: () =>
    import(/* webpackPrefetch: true */ './print-jobs').then(
      (res) => res.PrintJobsRoute
    ),
  loading: LoadingRouteIndicator,
});

const ChatChannelsRoute = Loadable({
  loader: () =>
    import(/* webpackPrefetch: true */ './chat-channels').then(
      (res) => res.ChatChannelsRoute
    ),
  loading: LoadingRouteIndicator,
});

const TicketsRoute = Loadable({
  loader: () =>
    import(/* webpackPrefetch: true */ './tickets').then(
      (res) => res.TicketsRoute
    ),
  loading: LoadingRouteIndicator,
});

const UserDeactivationRoute = Loadable({
  loader: () =>
    import(/* webpackPrefetch: true */ './user-deactivation').then(
      (res) => res.UserDeactivationRoute
    ),
  loading: LoadingRouteIndicator,
});

const DesignRoute = Loadable({
  loader: () =>
    import(/* webpackPrefetch: true */ './_design').then(
      (res) => res.DesignRoute
    ),
  loading: LoadingRouteIndicator,
});

const CopilotButton = Loadable({
  loader: () =>
    import(/* webpackPrefetch: true */ './admin-copilot').then(
      (res) => res.CopilotButton
    ),
  loading: LoadingRouteIndicator,
});

export const Routes = ({
  session,
}: {
  session: {
    uid: string;
    token: string;
    user?: { email?: string; IAM?: string[] };
  };
}) => (
  <Router>
    <HotJarLoader
      hotJarId={process.env.NEXT_PUBLIC_HOTJAR_ID}
      userId={session.uid}
    />

    <Component2
      didMount={() => {
        Sentry.setUser({ id: session.uid, email: session.user?.email });
      }}
    />

    <Switch>
      <Route
        exact
        path="/"
        render={(props) => (
          <BaseLayout {...props} session={session}>
            {null}
          </BaseLayout>
        )}
      />
      <Route
        exact
        path="/dashboard"
        render={(props) => (
          <BaseLayout {...props} session={session}>
            <h1 className="tw-text-snappr-blue">Snappr admin</h1>
          </BaseLayout>
        )}
      />
      {checkIfCanAccess(session, 'customers') && (
        <Route
          path="/customers"
          render={(props) => <CustomersRoute {...props} session={session} />}
        />
      )}
      <Route
        path="/abandoned-carts"
        render={(props) => <AbandonedCartsRoute {...props} session={session} />}
      />
      {checkIfDeveloper({ session }) && (
        <Route
          path="/partners"
          render={(props) => <PartnersRoute {...props} session={session} />}
        />
      )}
      {checkIfCanAccess(session, 'enterprise') && (
        <Route
          path="/enterprises"
          render={(props) => <EnterprisesRoute {...props} session={session} />}
        />
      )}
      <Route
        path="/bookings"
        render={(props) => <BookingsRoute {...props} session={session} />}
      />
      {checkIfCanAccess(session, 'productsView') && (
        <Route
          path="/products-view"
          render={(props) => <ProductsViewRoute {...props} session={session} />}
        />
      )}
      {checkIfCanAccess(session, 'editingJobs') && (
        <Route
          path="/editing-jobs"
          render={(props) => <EditingJobsRoute {...props} session={session} />}
        />
      )}
      {checkIfCanAccess(session, 'editingTaskQueue') && (
        <Route
          path="/editing-queue"
          render={(props) => <EditingQueueRoute {...props} session={session} />}
        />
      )}
      {checkIfCanAccess(session, 'contractorEditingTaskQueue') && (
        <Route
          path="/contractor-editing-queue"
          render={(props) => (
            <ContractorEditingQueueRoute {...props} session={session} />
          )}
        />
      )}
      {checkIfCanAccess(session, 'imageSearch') && (
        <Route
          path="/super-image-search"
          render={(props) => (
            <SuperImageSearchRoute {...props} session={session} />
          )}
        />
      )}
      {checkIfCanAccess(session, 'photoQCQueue') && (
        <Route
          path="/photo-review-queue"
          render={(props) => (
            <PhotoReviewQueueRoute {...props} session={session} />
          )}
        />
      )}
      {checkIfCanAccess(session, 'salesTeam') && (
        <Route
          path="/sales-team"
          render={(props) => (
            <SalesTeamRouteAsync {...props} session={session} />
          )}
        />
      )}
      {checkIfCanAccess(session, 'tools') && (
        <Route
          path="/tools"
          render={(props) => <ToolsRoute {...props} session={session} />}
        />
      )}
      {checkIfCanAccess(session, 'salesClaims') && (
        <Route
          path="/sales-claims"
          render={(props) => <SalesClaimsRoute {...props} session={session} />}
        />
      )}
      {checkIfCanAccess(session, 'salesLeadQueue') && (
        <Route
          path="/sales-lead-queue"
          render={(props) => (
            <SalesLeadQueueRoute {...props} session={session} />
          )}
        />
      )}
      {checkIfCanAccess(session, 'completionLeadQueue') && (
        <Route
          path="/completion-lead-queue"
          render={(props) => (
            <CompletionLeadQueueRoute {...props} session={session} />
          )}
        />
      )}
      {checkIfCanAccess(session, 'metrics') && (
        <Route
          path="/metrics"
          render={(props) => <MetricsRouteAsync {...props} session={session} />}
        />
      )}
      {checkIfCanAccess(session, 'providerPoints') && (
        <Route
          path="/provider-points"
          render={(props) => (
            <ProviderPointsRoute {...props} session={session} />
          )}
        />
      )}
      {checkIfCanAccess(session, 'providers') && (
        <Route
          path="/provider-applications"
          render={(props) => (
            <ProviderApplicationsRoute {...props} session={session} />
          )}
        />
      )}
      {ENABLE_GEO_BOOKING_MAP && checkIfCanAccess(session, 'providers') && (
        <Route
          path="/geo-booking-assignment"
          render={(props) => (
            <GeoBookingAssignmentRoute {...props} session={session} />
          )}
        />
      )}
      {ENABLE_GEO_BOOKING_MAP && checkIfCanAccess(session, 'providers') && (
        <Route
          path="/pgs-bookings-map"
          render={(props) => (
            <PgsAndBookingsMapRoute {...props} session={session} />
          )}
        />
      )}
      {checkIfCanAccess(session, 'iam') && (
        <Route
          path="/iam"
          render={(props) => <IAMRouteAsync {...props} session={session} />}
        />
      )}
      {checkIfCanAccess(session, 'providerPayouts') && (
        <Route
          path="/provider-payouts"
          render={(props) => <ProviderPayouts {...props} session={session} />}
        />
      )}
      {checkIfCanAccess(session, 'providers') && (
        <Route
          path="/providers"
          render={(props) => <ProvidersRoute {...props} session={session} />}
        />
      )}
      {checkIfCanAccess(session, 'coupons') && (
        <Route
          path="/coupons"
          render={(props) => <CouponsRoute {...props} session={session} />}
        />
      )}
      {checkIfCanAccess(session, 'coupons') && (
        <Route
          path="/gift-cards"
          render={(props) => <GiftCardsRoute {...props} session={session} />}
        />
      )}
      {checkIfCanAccess(session, 'workflowRunsView') && (
        <Route
          path="/workflow-runs-view"
          render={(props) => (
            <WorflowRunsViewRoute {...props} session={session} />
          )}
        />
      )}
      {checkIfCanAccess(session, 'qualityControlView') && (
        <Route
          path="/quality-control-view"
          render={(props) => (
            <QualityControlViewRoute {...props} session={session} />
          )}
        />
      )}
      {checkIfCanAccess(session, 'aiPortraitJob') && (
        <Route
          path="/ai-portrait-job"
          render={(props) => (
            <AiPortraitsJobRoute {...props} session={session} />
          )}
        />
      )}
      {checkIfCanAccess(session, 'aiProductJob') && (
        <Route
          path="/ai-ecommerce-job"
          render={(props) => <AiProductJobRoute {...props} session={session} />}
        />
      )}
      {checkIfCanAccess(session, 'aiProductJob') && (
        <Route
          path="/ai-ecommerce-job/category"
          render={(props) => <AiProductJobRoute {...props} session={session} />}
        />
      )}

      <Route
        path="/ecommerce-guideline"
        render={(props) => (
          <EcommerceGuidelinesRoute {...props} session={session} />
        )}
      />
      <Route
        path="/audit"
        render={(props) => <AuditRoute {...props} session={session} />}
      />
      <Route
        path="/provider-recommended-changes"
        render={(props) => (
          <ProviderRecommendedChangesRoute {...props} session={session} />
        )}
      />
      <Route
        path="/booking-sla-queue"
        render={(props) => (
          <BookingSlaQueueRoute {...props} session={session} />
        )}
      />
      <Route
        path="/__random"
        render={(props) => <RandomRoute {...props} session={session} />}
      />
      <Route
        path="/tickets"
        render={(props) => <TicketsRoute {...props} session={session} />}
      />
      {/* Add redirect for old links */}
      <Route
        path="/beta"
        render={({ location }) => (
          <Redirect
            to={{
              ...location,
              pathname: location.pathname.replace('/beta', ''),
            }}
          />
        )}
      />
      {/* Add redirect for old booking links */}
      <Route
        path="/booking/:id"
        render={({ location }) => (
          <Redirect
            to={{
              ...location,
              pathname: location.pathname.replace('/booking', '/bookings'),
            }}
          />
        )}
      />
      <Route
        path="/print-jobs"
        render={(props) => <PrintJobsRoute {...props} session={session} />}
      />
      <Route
        path="/chat-channels"
        render={(props) => <ChatChannelsRoute {...props} session={session} />}
      />
      {/* Listing simulator */}
      {ENABLE_LISTING_SIMULATOR === true &&
        checkIfCanAccess(session, 'editingJobs') && (
          <Route
            path="/listing-simulator"
            render={(props) => (
              <ListingSimulationsRoute {...props} session={session} />
            )}
          />
        )}
      {/* Enterprise tracker - former Pilot tracker */}
      {checkIfCanAccess(session, 'pilotTracker') && (
        <Route
          path="/enterprise-tracker"
          render={(props) => (
            <EnterpriseTrackerRoute {...props} session={session} />
          )}
        />
      )}
      {/* Upcoming no-shows queue */}
      {checkIfCanAccess(session, 'bookings') && (
        <Route
          path="/potential-no-shows"
          render={(props) => (
            <UpcomingNoShowsRoute {...props} session={session} />
          )}
        />
      )}
      {checkIfCanAccess(session, 'userDeactivation') && (
        <Route
          path="/user-deactivation"
          render={(props) => (
            <UserDeactivationRoute {...props} session={session} />
          )}
        />
      )}
      {checkIfCanAccess(session, 'iam') && (
        <Route
          path="/bull-queues"
          render={(props) => <BullQueuesRoute {...props} session={session} />}
        />
      )}
      <Route
        exact
        path="/call-history"
        render={(props) => <CallHistoryRoute {...props} session={session} />}
      />
      <Route
        exact
        path="/coverage"
        render={(props) => (
          <PGACoverageTaskRoute {...props} session={session} />
        )}
      />
      <Route
        path="/_design"
        render={(props) => <DesignRoute {...props} session={session} />}
      />
      <Route
        path="/ai-portrait-job"
        render={(props) => <AiPortraitsJobRoute {...props} session={session} />}
      />
      <Route
        path="/ai-ecommerce-job"
        render={(props) => <AiProductJobRoute {...props} session={session} />}
      />
      <Route
        path="/headshot-quality-control"
        render={(props) => <HeadShotsQCRoute {...props} session={session} />}
      />
      <Route
        path="/google-ads-bid"
        render={(props) => <GoogleAdsBidRoute {...props} session={session} />}
      />
    </Switch>

    <WowFactor />

    <RateGallery />

    {checkIfCanAccess(session, 'adminCopilot') && (
      <CopilotButton session={session} />
    )}
  </Router>
);
