import React from 'react';
import DeclarativeComponent from '@reach/component-component';

export const Dropdown = ({
  children,
  title,
  extraClassName,
}: {
  children;
  title;
  extraClassName?;
}) => (
  <DeclarativeComponent initialState={{ shoMenu: false }}>
    {({ state, setState }) => (
      <div className="dropdown d-inline" style={{ zIndex: 99999999999 }}>
        <button
          className={`btn btn-secondary dropdown-toggle ${extraClassName}`}
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          onClick={() =>
            setState((prevState) => ({
              showMenu: !prevState.showMenu,
            }))
          }
        >
          {title}
        </button>

        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: state.showMenu ? 'flex' : 'none',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 100,
          }}
        >
          <button
            style={{
              background: 'transparent',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              border: 'none',
              width: '100%',
            }}
            onClick={() =>
              setState((prevState) => ({
                showMenu: !prevState.showMenu,
              }))
            }
          />
        </div>

        <div
          className={`dropdown-menu dropdown-menu-right ${
            state.showMenu ? 'show' : ''
          }`}
          style={{ zIndex: 200 }}
        >
          {children}
        </div>
      </div>
    )}
  </DeclarativeComponent>
);
