import React from 'react';
import { Promiser } from 'react-prometo';
import { Link } from 'react-router-dom';

import { Modal } from '../../../components/Modal';
import { ModalRoute, locationForModal } from '../edit/components';
import { apiFetch } from '../../../utils';
import { bookingHasGallery } from '../edit/selectors';

const MODAL_NAME_DELETE_GALLERY = 'delete-gallery';

class DeleteGalleryModal extends React.Component {
  state = { deleteGalleryPromise: null };

  render() {
    const {
      onDismiss,
      afterDelete,
      session: { token },
      booking,
    } = this.props;
    return (
      <Promiser promise={this.state.deleteGalleryPromise}>
        {({
          isPending: deleteGalleryLoading,
          result,
          error: deleteGalleryError,
        }) => (
          <Modal onDismiss={onDismiss}>
            <div className="card my-4">
              <div className="card-header">
                <h4 className="mb-0">Delete gallery</h4>
              </div>

              <div
                className="card-body d-flex flex-column"
                style={{ width: '600px' }}
              >
                <div className="flex-1" style={{ position: 'relative' }}>
                  {!deleteGalleryLoading &&
                    (deleteGalleryError ? (
                      <div className="alert alert-danger">
                        {(deleteGalleryError.errors &&
                          deleteGalleryError.errors.join('.\n')) ||
                          'Unknown error'}
                      </div>
                    ) : (
                      <React.Fragment>
                        <div className="card mb-3">
                          <div className="card-body">
                            <p>
                              Are you sure you want to delete the gallery for
                              this booking?
                            </p>
                            <p className="mb-0">
                              Doing it will delete the gallery from the booking
                              and will set the booking back to paid so the
                              photographer can upload the photos again.
                            </p>
                          </div>
                        </div>
                      </React.Fragment>
                    ))}

                  {deleteGalleryLoading && (
                    <div
                      className={[
                        'd-flex flex-columns',
                        'justify-content-center align-items-center',
                      ].join(' ')}
                      style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                      }}
                    >
                      <span>Loading...</span>
                    </div>
                  )}
                </div>

                <div className="d-flex justify-content-between mt-3">
                  <button className="btn btn-secondary" onClick={onDismiss}>
                    Hell no! do not delete the gallery
                  </button>

                  <button
                    className="btn btn-danger"
                    disabled={deleteGalleryLoading}
                    onClick={() =>
                      this.setState({
                        deleteGalleryPromise: apiFetch(
                          `/api/v2/admin/bookings/${booking.uid}/delete-gallery`,
                          { token, method: 'POST' }
                        ).then(() => afterDelete()),
                      })
                    }
                  >
                    {deleteGalleryLoading
                      ? 'Deleting the gallery...'
                      : 'Yes, delete the gallery'}
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        )}
      </Promiser>
    );
  }
}

export const DeleteGalleryButton = ({
  session,
  booking,
  location,
  onReload,
}) => (
  <React.Fragment>
    {bookingHasGallery({ booking }) && (
      <>
        <div className="p-1" />

        <Link
          className="btn btn-danger"
          to={locationForModal({
            location,
            modal: { modalName: MODAL_NAME_DELETE_GALLERY },
          })}
        >
          Delete Gallery
        </Link>
      </>
    )}

    <ModalRoute
      modalName={MODAL_NAME_DELETE_GALLERY}
      render={({ history, location }) => (
        <DeleteGalleryModal
          session={session}
          booking={booking}
          onDismiss={() => {
            history.replace(
              locationForModal({
                location,
                modal: undefined,
              })
            );

            onReload();
          }}
          afterDelete={() =>
            onReload().then(() => {
              history.replace(
                locationForModal({
                  location,
                  modal: undefined,
                  search: { submission_uid: null },
                })
              );
            })
          }
        />
      )}
    />
  </React.Fragment>
);
