import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { gql, useMutation } from './ApolloClient';

import { EnterpriseRichTextGuidelineBaseModal } from './EnterpriseRichTextGuidelineBaseModal';

type EnterpriseRichTextGuidelineProps = {
  guidelineId: string;
  partnerId: string;
  onDismiss: () => void;
  location: RouteComponentProps['location'];
  history: RouteComponentProps['history'];
  onContinue: (p: { guidelineId: string }) => void;
  modalParams: {
    modalName: string;
    guidelineProductIds?: string[];
    guidelineId?: string;
    shootType?: string;
    isReferral?: boolean;
    guidelineName?: string;
    userType?: 'artist' | 'end_customer';
    serviceType: string;
  };
};

export const EnterpriseRichTextCloneGuidelineModal = ({
  partnerId,
  guidelineId,
  location,
  history,
  onDismiss,
  onContinue,
  modalParams,
}: EnterpriseRichTextGuidelineProps) => {
  const [duplicatePartnerGuidelineAsAdmin] = useMutation<
    { partnerGuidelineDuplicateAsAdmin: { guideline?: { uid: string } } },
    {
      guidelineId: string;
      partnerId: string;
      isReferral: boolean;
      name: string;
      shootType: string;
      productIds: string[];
      userType: string;
      fileUrl?: string;
      sourceFileUrl?: string;
      videoId?: string;
      serviceType: string;
    }
  >(
    gql`
      mutation NewPartnerGuidelineDuplicateAsAdmin(
        $guidelineId: ID!
        $partnerId: ID!
        $isReferral: Boolean!
        $name: String!
        $shootType: String!
        $productIds: [ID!]!
        $userType: PartnerGuidelineUserTypeAsAdmin!
        $fileUrl: String
        $sourceFileUrl: String
        $videoId: String
        $serviceType: String!
      ) {
        partnerGuidelineDuplicateAsAdmin(
          input: {
            guidelineId: $guidelineId
            partnerId: $partnerId
            isReferral: $isReferral
            name: $name
            shootType: $shootType
            productIds: $productIds
            userType: $userType
            fileUrl: $fileUrl
            sourceFileUrl: $sourceFileUrl
            videoId: $videoId
            serviceType: $serviceType
          }
        ) {
          guideline {
            uid
          }
        }
      }
    `
  );

  function onSubmit(guidelinePayload) {
    return duplicatePartnerGuidelineAsAdmin({
      variables: {
        ...guidelinePayload,
        guidelineId,
      },
    }).then((resp) => ({
      guidelineId: resp?.data?.partnerGuidelineDuplicateAsAdmin?.guideline?.uid,
    }));
  }

  return (
    <EnterpriseRichTextGuidelineBaseModal
      partnerId={partnerId}
      location={location}
      history={history}
      modalParams={modalParams}
      title="Clone rich text guideline"
      buttonLabel="Clone"
      onDismiss={onDismiss}
      onContinue={onContinue}
      onSubmit={onSubmit}
    />
  );
};
