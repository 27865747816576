import premiumCrownWhiteSvg from '../img/premium-crown-white.svg';

export const PremiumLabel = ({
  position,
  top,
}: {
  position?: 'static' | 'relative' | 'absolute' | 'sticky' | 'fixed';
  top?: string;
}) => {
  return (
    <span
      className="badge w-auto text-uppercase"
      style={{
        backgroundColor: '#AE9145',
        color: '#FFF',
        fontSize: '10px',
        fontWeight: 300,
        lineHeight: '14px',
        padding: '0 4px 0 3px',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '10px',
        marginTop: '8px',
        height: '14px',
        borderRadius: '0px',
        position: position || 'static',
        top: top || 0,
      }}
    >
      <img
        alt="premium"
        style={{
          marginRight: '3px',
        }}
        src={premiumCrownWhiteSvg}
      />
      premium
    </span>
  );
};
