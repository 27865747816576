import { Modal } from './Modal';

export const ConfirmBookingConfirmationModal = ({
  onDismiss,
  confirmBooking,
}: {
  onDismiss: () => void;
  confirmBooking: () => void;
}) => {
  return (
    <Modal>
      <div className="card my-4">
        <div className="card-header">
          <h4 className="mb-0">Booking Confirmation</h4>
        </div>
        <div
          className="card-body d-flex flex-column"
          style={{
            width: 648,
            paddingTop: 32,
            paddingBottom: 24,
          }}
        >
          <p className="w-100" style={{ marginBottom: 32 }}>
            Did the customer give his consent to confirm this booking?
          </p>
          <div className="d-flex justify-content-between">
            <button className="btn btn-secondary" onClick={onDismiss}>
              Close
            </button>

            <button
              className="btn btn-primary"
              onClick={() => {
                confirmBooking();
                onDismiss();
              }}
            >
              Yes, confirm booking
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
