import React, { useEffect, useState } from 'react';

import { Modal } from '../../../../../components/Modal';
import { apiFetch } from '../../../../../utils';
import {
  formatCreditsOrMoney,
  notificationIsDismissed,
  chargeIsDismissed,
} from './utils';
import { usePromise } from '../../../../../components/usePromise';
import { isPartnerBooking } from '../../selectors';
import { PaymentMethodSelector } from '../../components/PaymentMethodSelector';

const PARTY_AT_FAULT = {
  CUSTOMER: 'customer',
  PROVIDER: 'provider',
  SNAPPR: 'snappr',
};

const FOLLOW_UP_DATE = {
  ONE_WEEK: 'ONE_WEEK',
  ONE_MONTH: 'ONE_MONTH',
  TWO_MONTHS: 'TWO_MONTHS',
  DO_NOT_FOLLOW_UP: 'DO_NOT_FOLLOW_UP',
};

type PutOnHoldProps = {
  booking;
  session: { token: string };
  onDismiss: () => void;
  onReload: () => Promise<any>;
};

type Notification = {
  type: string;
  description: string;
};

type ChargeType = {
  reason: string;
  description: string;
  value: any;
  product_uid: any;
};

type ValidationResult = {
  notifications: {
    final: Notification[];
    original: Notification[];
  };
  charges: {
    original: ChargeType[];
    final: ChargeType[];
    total: ChargeType[];
  };
  on_hold_reasons: {
    reasons: {
      code: string;
      name: string;
    }[];
    selected?: string;
  };
  token: string;
  short_notice_reschedules?: number;
};

const Charge = ({ charge, removed, booking }) => (
  <li
    className="d-flex align-items-center"
    style={{ textDecoration: removed && 'line-through' }}
  >
    <span className={`badge badge-${charge.value > 0 ? 'success' : 'danger'}`}>
      {formatCreditsOrMoney({ booking, value: charge.value })}
    </span>

    <span className="ml-1">{charge.description}</span>
  </li>
);

export const PutOnHold = ({
  onDismiss,
  booking,
  session,
  onReload,
}: PutOnHoldProps) => {
  const {
    setPromise: setValidationPromise,
    isPending: validationPending,
    result: resultValidation,
  } = usePromise<ValidationResult>();

  const {
    setPromise: setCommitPromise,
    isPending: commitIsPending,
  } = usePromise();

  const [dropNotifications, setDropNotifications] = useState<string[]>([]);
  const [dropCharges, setDropCharges] = useState<string[] | null>(null);
  const [partyAtFault, setPartyAtFault] = useState<string | null>(null);
  const [onHoldReason, setOnHoldReason] = useState<string | null>(null);
  const [onHoldDetails, setOnHoldDetails] = useState<string>();
  const [paymentMethodToken, setPaymentMethodToken] = useState<string>();
  const [followUpDate, setFollowUpDate] = useState<string | null>(null);

  const totalCharges = resultValidation?.charges.total || 0;
  const paymentMethodPending =
    !isPartnerBooking({ booking }) &&
    totalCharges !== 0 &&
    paymentMethodToken == null;

  useEffect(() => {
    setValidationPromise(
      apiFetch(`/api/v2/admin/bookings/${booking.uid}/validate-put-on-hold`, {
        token: session.token,
        method: 'POST',
        body: JSON.stringify({
          on_hold_reason: onHoldReason,
          drop_notifications: dropNotifications,
          drop_charges: dropCharges,
          refund_customer: false,
          follow_up_date: followUpDate,
        }),
      })
    );
  }, [
    booking.uid,
    dropNotifications,
    session.token,
    setValidationPromise,
    dropCharges,
    onHoldReason,
    followUpDate,
  ]);

  const toggleNotification = ({
    notification,
  }: {
    notification: Notification;
  }) => {
    setDropNotifications((state) =>
      notificationIsDismissed({
        notification,
        notifications: resultValidation?.notifications?.final,
      })
        ? state.filter((type) => type !== notification.type)
        : [...state, notification.type]
    );
  };

  const toggleCharge = ({ charge }: { charge: ChargeType }) => {
    setDropCharges((state) =>
      chargeIsDismissed({
        charge,
        charges: resultValidation?.charges?.final,
      })
        ? (state ?? []).filter((reason) => reason !== charge.reason)
        : [...(state ?? []), charge.reason]
    );
  };

  return (
    <Modal onDismiss={onDismiss}>
      <div className="card my-4">
        <div className="card-header">
          <h4 className="mb-0">Put on hold</h4>
        </div>

        <div
          className="card-body d-flex flex-column"
          style={{ width: '1000px', minHeight: '600px' }}
        >
          <div className="flex-1" style={{ position: 'relative' }}>
            <div className="card mb-3">
              <div className="card-body d-flex">
                <div className="flex-1">
                  <h5>Information required</h5>

                  <div>
                    <span>Party at fault:</span>

                    <div className="form-check form-check-inline ml-2">
                      <input
                        id="customerAtFault"
                        className="form-check-input"
                        type="radio"
                        name="partyAtFaultOptions"
                        value={PARTY_AT_FAULT.CUSTOMER}
                        checked={partyAtFault === PARTY_AT_FAULT.CUSTOMER}
                        onChange={({ target: { value } }) => {
                          setPartyAtFault(value);
                          setOnHoldReason(
                            resultValidation?.on_hold_reasons.reasons[value][0]
                              .code
                          );
                        }}
                      />
                      <label
                        htmlFor="customerAtFault"
                        className="form-check-label"
                      >
                        Customer
                      </label>
                    </div>

                    <div className="form-check form-check-inline">
                      <input
                        id="photographerAtFault"
                        className="form-check-input"
                        type="radio"
                        name="partyAtFaultOptions"
                        value={PARTY_AT_FAULT.PROVIDER}
                        checked={partyAtFault === PARTY_AT_FAULT.PROVIDER}
                        onChange={({ target: { value } }) => {
                          setPartyAtFault(value);
                          setOnHoldReason(
                            resultValidation?.on_hold_reasons.reasons[value][0]
                              .code
                          );
                        }}
                      />
                      <label
                        htmlFor="photographerAtFault"
                        className="form-check-label"
                      >
                        Photographer
                      </label>
                    </div>

                    <div className="form-check form-check-inline">
                      <input
                        id="snapprAtFault"
                        className="form-check-input"
                        type="radio"
                        name="partyAtFaultOptions"
                        value={PARTY_AT_FAULT.SNAPPR}
                        checked={partyAtFault === PARTY_AT_FAULT.SNAPPR}
                        onChange={({ target: { value } }) => {
                          setPartyAtFault(value);
                          setOnHoldReason(
                            resultValidation?.on_hold_reasons.reasons[value][0]
                              .code
                          );
                        }}
                      />
                      <label
                        htmlFor="snapprAtFault"
                        className="form-check-label"
                      >
                        Snappr
                      </label>
                    </div>
                  </div>

                  <div className="form-group mt-3">
                    <label>Reason</label>

                    <select
                      className="form-control"
                      value={onHoldReason ?? ''}
                      disabled={partyAtFault == null}
                      onChange={({ target: { value } }) =>
                        setOnHoldReason(value)
                      }
                    >
                      <option key="" value="">
                        Select a reason
                      </option>
                      {resultValidation?.on_hold_reasons.reasons != null &&
                        resultValidation?.on_hold_reasons.reasons[
                          partyAtFault ?? PARTY_AT_FAULT.CUSTOMER
                        ].map((reason) => (
                          <option key={reason.code} value={reason.code}>
                            {reason.name}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="form-group mt-3">
                    <label htmlFor="exampleFormControlTextarea1">
                      Extra details
                    </label>
                    <textarea
                      onChange={(ev) => setOnHoldDetails(ev.target.value)}
                      value={onHoldDetails}
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows={3}
                    ></textarea>
                  </div>

                  <h5>
                    Short notice reschedules:{' '}
                    {resultValidation?.short_notice_reschedules ?? 0}
                  </h5>

                  <h5>Follow up date </h5>
                  <div className="form-check">
                    <div className="form-check">
                      <input
                        id="oneWeek"
                        className="form-check-input"
                        type="radio"
                        name="followUpOptions"
                        value={FOLLOW_UP_DATE.ONE_WEEK}
                        checked={followUpDate === FOLLOW_UP_DATE.ONE_WEEK}
                        onChange={({ target: { value } }) => {
                          setFollowUpDate(value);
                        }}
                      />
                      <label htmlFor="oneWeek" className="form-check-label">
                        In around one week
                      </label>
                    </div>

                    <div className="form-check">
                      <input
                        id="oneMonth"
                        className="form-check-input"
                        type="radio"
                        name="followUpOptions"
                        value={FOLLOW_UP_DATE.ONE_MONTH}
                        checked={followUpDate === FOLLOW_UP_DATE.ONE_MONTH}
                        onChange={({ target: { value } }) => {
                          setFollowUpDate(value);
                        }}
                      />
                      <label htmlFor="oneMonth" className="form-check-label">
                        In around two weeks
                      </label>
                    </div>

                    <div className="form-check">
                      <input
                        id="twoMonths"
                        className="form-check-input"
                        type="radio"
                        name="followUpOptions"
                        value={FOLLOW_UP_DATE.TWO_MONTHS}
                        checked={followUpDate === FOLLOW_UP_DATE.TWO_MONTHS}
                        onChange={({ target: { value } }) => {
                          setFollowUpDate(value);
                        }}
                      />
                      <label htmlFor="twoMonths" className="form-check-label">
                        In around two months
                      </label>
                    </div>

                    <div className="form-check">
                      <input
                        id="never"
                        className="form-check-input"
                        type="radio"
                        name="followUpOptions"
                        value={FOLLOW_UP_DATE.DO_NOT_FOLLOW_UP}
                        checked={
                          followUpDate === FOLLOW_UP_DATE.DO_NOT_FOLLOW_UP
                        }
                        onChange={({ target: { value } }) => {
                          setFollowUpDate(value);
                        }}
                      />
                      <label htmlFor="never" className="form-check-label">
                        The customer will contact, do not follow up
                      </label>
                    </div>
                  </div>
                </div>

                <div className="px-3" />

                <div className="flex-1">
                  <div className="card mb-3">
                    <div className="card-body">
                      <h5>Charges</h5>

                      <ul className="list-unstyled mb-0">
                        {resultValidation?.charges?.original.map((charge) => (
                          <Charge
                            charge={charge}
                            booking={booking}
                            key={charge.reason}
                            removed={
                              !resultValidation.charges.final.find(
                                ({ reason }) => reason === charge.reason
                              )
                            }
                          />
                        ))}
                      </ul>
                    </div>
                  </div>

                  <div className="card mb-3">
                    <div className="card-body">
                      <h5>Information required</h5>

                      {resultValidation?.charges?.original.map((charge) => (
                        <div className="form-check" key={charge.reason}>
                          <label className="form-check-label">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={
                                !chargeIsDismissed({
                                  charge,
                                  charges: resultValidation.charges.final,
                                })
                              }
                              onChange={() => toggleCharge({ charge })}
                            />

                            <span>
                              Charge {charge.description.toLowerCase()}
                            </span>
                          </label>
                        </div>
                      ))}

                      {!isPartnerBooking({ booking }) &&
                        booking.customer_uid &&
                        totalCharges !== 0 && (
                          <div className="mt-2 d-flex justify-content-start align-items-center">
                            <span>Payment method: </span>

                            <div className="w-50 d-inline ml-2">
                              <PaymentMethodSelector
                                bookingClone={booking}
                                session={session}
                                selectedPaymentMethodToken={paymentMethodToken}
                                onPaymentMethodTokenChange={({
                                  paymentMethodToken: newToken,
                                }) => setPaymentMethodToken(newToken)}
                              />
                            </div>
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="card mb-3">
                    <div className="card-body">
                      <h5>Notifications</h5>
                      {resultValidation &&
                      resultValidation.notifications.original.length > 0
                        ? resultValidation.notifications.original.map(
                            (notification) => (
                              <div
                                className="form-check"
                                key={notification.type}
                              >
                                <label className="form-check-label">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={
                                      resultValidation.notifications.final.find(
                                        (n) => n.type === notification.type
                                      ) != null
                                    }
                                    onChange={() =>
                                      toggleNotification({ notification })
                                    }
                                  />

                                  <span>{notification.description}</span>
                                </label>
                              </div>
                            )
                          )
                        : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {validationPending && (
              <div
                className={[
                  'd-flex flex-columns',
                  'justify-content-center align-items-center',
                ].join(' ')}
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  backgroundColor: 'rgba(255, 255, 255, 0.5)',
                }}
              >
                <span>Loading...</span>
              </div>
            )}
          </div>

          <div className="d-flex justify-content-between mt-3">
            <button className="btn btn-secondary mr-2" onClick={onDismiss}>
              Close
            </button>

            <button
              className="btn btn-primary"
              disabled={
                validationPending ||
                commitIsPending ||
                paymentMethodPending ||
                onHoldReason == null ||
                followUpDate == null
              }
              onClick={() =>
                setCommitPromise(
                  apiFetch(
                    `/api/v2/admin/bookings/${booking.uid}/commit-put-on-hold`,
                    {
                      token: session.token,
                      method: 'POST',
                      body: JSON.stringify({
                        drop_notifications: dropNotifications,
                        drop_charges: dropCharges,
                        token: resultValidation?.token,
                        on_hold_reason: onHoldReason,
                        on_hold_details:
                          onHoldDetails != null && onHoldDetails !== ''
                            ? onHoldDetails
                            : undefined,
                        paymentMethodToken: paymentMethodToken ?? undefined,
                        follow_up_date: followUpDate,
                      }),
                    }
                  )
                    .then(() => onReload())
                    .then(() => onDismiss())
                )
              }
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
