import React from 'react';

export const BottomSectionWrapper = ({ children }) => (
  <div
    className="bg-white p-3"
    style={{
      position: 'fixed',
      bottom: 0,
      right: 0,
      left: 0,
      marginLeft: 200,
      boxShadow: '0 0 1.625em rgba(0,0,0,.05)',
      minHeight: 70,
    }}
  >
    <div className="d-flex">{children}</div>
  </div>
);
