import bgBlurIconPath from './bg-blur.svg';
import bgRemovalIconPath from './bg-removal.svg';
import croppingAndResizingIconPath from './cropping-and-resizing.svg';
import imgEnhancementIconPath from './image-enhancement.svg';
import objectRemovalIconPath from './object-removal.svg';
import skyReplacementIconPath from './sky-replacement.svg';
import upscallingIconPath from './upscalling.svg';
import virtualStagingIconPath from './virtual-staging.svg';
import emailIconPath from './mail-blue.svg';
import timeIconPath from './turnaround-time.svg';
import formatIconPath from './file-format.svg';
import commentsIconPath from './comments.svg';
import referenceImgIconPath from './reference-images.svg';
import photoSVGIconPath from './illustration-picture.svg';
import deleteIconPath from './delete.svg';
import spotRemovalIconPath from './spot-removal.svg';
import objectClassificationIconPath from './object-classification.svg';
import bgExtensionIconPath from './bg-extension.svg';
import backgroundRemovalAutoIconPath from './bg-removal-auto.svg';
import imageEnhancementAutoIconPath from './image-enhancement-auto.svg';
import upscalingAutoIconPath from './upscaling-auto.svg';
import objectClassificationAutoIconPath from './detection-auto.svg';
import faceRetouchingIconPath from './face-retouching.svg';
import perspectiveCorrectionIconPath from './perspective-correction.svg';
import dropShadowIconPath from './drop-shadow.svg';
import productRetouchingIconPath from './product-retouching.svg';
import brandRemovalIconPath from './brand-removal.svg';

export {
  bgBlurIconPath,
  faceRetouchingIconPath,
  bgRemovalIconPath,
  croppingAndResizingIconPath,
  imgEnhancementIconPath,
  objectRemovalIconPath,
  skyReplacementIconPath,
  upscallingIconPath,
  virtualStagingIconPath,
  emailIconPath,
  timeIconPath,
  formatIconPath,
  commentsIconPath,
  referenceImgIconPath,
  photoSVGIconPath,
  deleteIconPath,
  spotRemovalIconPath,
  objectClassificationIconPath,
  bgExtensionIconPath,
  backgroundRemovalAutoIconPath,
  imageEnhancementAutoIconPath,
  upscalingAutoIconPath,
  objectClassificationAutoIconPath,
  perspectiveCorrectionIconPath,
  dropShadowIconPath,
  productRetouchingIconPath,
  brandRemovalIconPath,
};
