import React from 'react';
import { Switch, Route } from 'react-router-dom';

import CustomersListRoute from './list';
import CustomerRoute from './detail';

import BaseLayout from '../../components/BaseLayout';

const CustomersRoute = ({ session, match }) => (
  <BaseLayout session={session} match={match}>
    <Switch>
      <Route
        exact
        path={match.path}
        render={(props) => (
          <CustomersListRoute
            {...props}
            session={session}
            basePath={match.path}
          />
        )}
      />
      <Route
        path={`${match.path}/:customer_id`}
        render={(props) => <CustomerRoute {...props} session={session} />}
      />
    </Switch>
  </BaseLayout>
);

export default CustomersRoute;
