import { useCallback, useEffect, useState } from 'react';

function useImageLoadIndicator({ src }: { src: string }) {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [width, setWidth] = useState<number | null>(null);
  const [height, setHeight] = useState<number | null>(null);

  const deactivate = useCallback(() => {
    let disabled = false;

    const img = new Image();
    img.onload = function (this, event) {
      if (disabled === true) return;

      setLoaded(true);
      // @ts-ignore
      setWidth(this.width);
      // @ts-ignore
      setHeight(this.height);
    };
    img.onerror = () => {
      if (disabled === true) return;
      setError(true);
    };
    img.src = src;

    return () => (disabled = true);
  }, [src]);

  useEffect(() => {
    deactivate();

    return () => {
      deactivate();
    };
  }, [deactivate]);

  return {
    loaded,
    error,
    width,
    height,
  };
}

function useKeyboardControlEvents({
  right,
  left,
  esc,
}: {
  right?: () => void;
  left?: () => void;
  esc?: () => void;
}) {
  const addKeyboardEvent = useCallback(
    ({ key }) => {
      if (key === 'ArrowRight') right && right();
      if (key === 'ArrowLeft') left && left();
      if (key === 'Escape') esc && esc();
    },
    [right, left, esc]
  );

  useEffect(() => {
    window.addEventListener('keydown', addKeyboardEvent);
    return () => window.removeEventListener('keydown', addKeyboardEvent);
  }, [addKeyboardEvent, right, left, esc]);
}

function ImageAnimatedLoadStyle({
  loaded,
  ...props
}: { loaded: boolean } & React.ImgHTMLAttributes<HTMLImageElement>) {
  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/alt-text */}
      <img {...props} />
      <style jsx>{`
        img {
          transition: opacity 1s;
          opacity: ${loaded ? 1 : 0};
        }
      `}</style>
    </>
  );
}

function Photo({
  image,
  imageIndex,
  currentImageIndex,
  nextImageIndex,
  prevImageIndex,
  className,
}: {
  image: { id: string; src: string };
  imageIndex: number;
  currentImageIndex: number;
  nextImageIndex: number;
  prevImageIndex: number;
  className?: string;
}) {
  const { loaded } = useImageLoadIndicator({ src: image.src });

  return (
    <div className={className}>
      {(imageIndex === currentImageIndex ||
        imageIndex === nextImageIndex ||
        imageIndex === prevImageIndex) && (
        <>
          {loaded === false && (
            <div className="tw-absolute tw-inset-0 tw-flex tw-justify-center tw-items-center">
              <i className="fa fa-spinner fa-spin fa-2x fa-fw text-light" />
            </div>
          )}

          <ImageAnimatedLoadStyle
            loaded={loaded}
            className="tw-object-contain tw-w-full tw-h-full"
            src={image.src}
            alt="gallery"
          />
        </>
      )}
    </div>
  );
}

export function EfficientGalleryPreview({
  currentImageId,
  images,
  className,
  onImageIdChange,
}: {
  currentImageId: string;
  images: { id: string; src: string }[];
  className?: string;
  onImageIdChange?: (props: { imageId: string | undefined }) => void;
}) {
  const currentImageIndex = images.findIndex(({ id }) => id === currentImageId);

  const prevImageIndex =
    currentImageIndex <= 0 ? images.length - 1 : currentImageIndex - 1;
  const nextImageIndex =
    currentImageIndex >= images.length - 1 ? 0 : currentImageIndex + 1;

  useEffect(() => {
    if (currentImageIndex === -1) {
      onImageIdChange?.({ imageId: undefined });
    }
  }, [currentImageIndex, onImageIdChange]);

  useKeyboardControlEvents({
    left: () => onImageIdChange?.({ imageId: images[prevImageIndex].id }),
    right: () => onImageIdChange?.({ imageId: images[nextImageIndex].id }),
    esc: () => onImageIdChange?.({ imageId: undefined }),
  });

  return images.length === 0 || currentImageIndex === -1 ? null : (
    <div
      className={`${className} tw-w-full tw-h-full tw-flex tw-flex-col tw-justify-start tw-gap-3 tw-p-4`}
    >
      <div className="tw-relative tw-flex tw-justify-center tw-items-center tw-gap-x-5">
        <button
          className="tw-p-2 tw-bg-transparent tw-text-inherit tw-border-none"
          onClick={() =>
            onImageIdChange?.({ imageId: images[prevImageIndex].id })
          }
        >
          <i className="fa fa-arrow-left" />
        </button>
        <p className="tw-m-0 tw-font-bold">
          {currentImageIndex + 1}/{images.length}
        </p>
        <button
          className="tw-p-2 tw-bg-transparent tw-text-inherit tw-border-none"
          onClick={() =>
            onImageIdChange?.({ imageId: images[nextImageIndex].id })
          }
        >
          <i className="fa fa-arrow-right" />
        </button>
        <button
          type="button"
          className="tw-absolute tw-inset-y-0 tw-right-0 tw-bg-transparent tw-text-inherit tw-border-none"
          aria-label="Close"
          onClick={() => onImageIdChange?.({ imageId: undefined })}
        >
          <i className="fa fa-times" />
        </button>
      </div>

      <div className="tw-flex-1 tw-w-full tw-h-full tw-overflow-hidden">
        <div
          className="tw-flex tw-h-full"
          style={{
            transform: `translateX(${currentImageIndex * -100}%)`,
            transition: 'transform 0.3s',
          }}
        >
          {images.map((image, imageIndex) => (
            <Photo
              key={image.id}
              className="tw-relative tw-basis-full tw-flex-shrink-0 tw-flex tw-justify-center tw-items-center"
              image={image}
              imageIndex={imageIndex}
              currentImageIndex={currentImageIndex}
              nextImageIndex={nextImageIndex}
              prevImageIndex={prevImageIndex}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
