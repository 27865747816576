import * as React from 'react';
import {
  ApolloClient,
  DocumentNode,
  InMemoryCache,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  TypedDocumentNode,
  useQuery,
} from '@apollo/client';

import { API_URL } from '../config';
import { useWindowFocus } from './useWindowFocus';

export {
  ApolloProvider,
  useQuery,
  useLazyQuery,
  useMutation,
  useApolloClient,
  gql,
} from '@apollo/client';

let _noTokenApolloClient;
let _apolloClientHash: { [k: string]: ApolloClient<any> } = {};
export function getApolloClient({
  token,
}: {
  token?: string;
} = {}): ApolloClient<any> {
  if (token == null && _noTokenApolloClient != null) {
    return _noTokenApolloClient;
  } else if (token != null && _apolloClientHash[token] != null) {
    return _apolloClientHash[token];
  }

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    uri: `${API_URL}/api/graphql`,
    headers: token == null ? undefined : { Authorization: `Bearer ${token}` },
  });

  client.defaultOptions = {
    watchQuery: { fetchPolicy: 'cache-and-network' },
  };

  const inBrowser = typeof window !== 'undefined';

  if (inBrowser) {
    if (token == null) {
      _noTokenApolloClient = client;
    } else {
      _apolloClientHash[token] = client;
    }
  }

  return client;
}

export function useQueryWithFocusRefresh<
  TData = any,
  TVariables = OperationVariables
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  {
    skipFocusRefresh,
    pollInterval,
    pollIntervalAddJitter,
    ...options
  }: QueryHookOptions<TData, TVariables> & {
    skipFocusRefresh?: boolean;
    pollIntervalAddJitter?: boolean;
  } = {}
): QueryResult<TData, TVariables> {
  const res = useQuery<TData, TVariables>(query, options);

  const { focused } = useWindowFocus({
    onFocus: () => {
      if (res.loading === true || skipFocusRefresh === true) return;

      res.refetch();
    },
  });

  const refetchRef = React.useRef(res.refetch);
  React.useEffect(() => {
    refetchRef.current = res.refetch;
  }, [res.refetch]);

  React.useEffect(() => {
    if (pollInterval == null || pollInterval <= 0 || focused !== true) return;

    const intervalDuration =
      pollIntervalAddJitter === true
        ? Math.round(
            pollInterval *
              (1 + Math.random() * 0.3 * (Math.random() > 0.5 ? -1 : 1))
          )
        : pollInterval;

    const id = setInterval(() => {
      refetchRef.current?.();
    }, intervalDuration);

    return () => clearInterval(id);
  }, [focused, pollInterval, pollIntervalAddJitter]);

  return res;
}
