import produce from 'immer';
import React, { useRef } from 'react';
import { NavLink } from 'react-router-dom';
import type { Descendant } from 'slate';
import Component2 from '@reach/component-component';

import { gql, useMutation, useQuery } from '../../../components/ApolloClient';
import ContentWrapper from '../../../components/ContentWrapper';
import { RichTextInput } from '../../../components/RichTextInput';
import { SectionList } from './components/section-list';
import { StatusLabel } from './versions';

type Partner = {
  uid: string;
  name: string;
};

type RichTextGuideline = {
  id: string;
  versionName: string;
  status: string;
  document: { id: string; content: string };
  updatedAt: string;
  updatedBy: string;
};

type Guideline = {
  uid: string;
  name: string;
  archivedAt?: string;
};

export function UpdateGuidelinesRoute({
  session,
  history,
  location,
  match: {
    params: { partnerId, guidelineId, versionId },
  },
}: {
  session;
  history;
  location;
  match: {
    params: { partnerId: string; guidelineId: string; versionId: string };
  };
}) {
  const [guidelineName, setGuidelineName] = React.useState<string>('');

  const [sections, setSections] = React.useState<
    { id: string; name: string; content: Descendant[] }[] | undefined
  >();

  const [activeSectionId, setActiveSectionId] = React.useState<
    string | undefined
  >();

  const [
    isEditingGuidelineName,
    setIsEditingGuidelineName,
  ] = React.useState<boolean>(false);

  const guidelineNameInput = useRef<HTMLInputElement>(null);

  const partnerGuidelinesVersionsQuery = useQuery<{
    partnerById: Partner;
    guideline: Guideline;
    richTextGuidelineById: RichTextGuideline;
  }>(
    gql`
      query PartnerByIdOnGuidelineUpdateScreenAsAdmin(
        $partnerId: UUID!
        $guidelineId: ID!
        $richTextGuidelineId: ID!
      ) {
        partnerById(uid: $partnerId) {
          uid
          name
        }

        guideline: partnerGuidelineByIdAsAdmin(guidelineId: $guidelineId) {
          uid
          name
          archivedAt
        }

        richTextGuidelineById(id: $richTextGuidelineId) {
          id
          versionName
          status
          document {
            id
            content
          }
        }
      }
    `,
    {
      variables: { partnerId, guidelineId, richTextGuidelineId: versionId },
      fetchPolicy: 'network-only',
    }
  );

  const partner = partnerGuidelinesVersionsQuery.data?.partnerById;

  const guideline = partnerGuidelinesVersionsQuery.data?.guideline;

  const guidelineVersion =
    partnerGuidelinesVersionsQuery.data?.richTextGuidelineById;

  const [
    commitUpdateRichTextGuidelineAsAdmin,
    commitUpdateRichTextGuidelineAsAdminMutation,
  ] = useMutation<
    {
      richTextGuideline: { id: string };
    },
    {
      richTextGuidelineId: string;
      content?: string;
      versionName?: string;
    }
  >(
    gql`
      mutation PartnerRichTextGuidelineUpdateAsAdmin(
        $richTextGuidelineId: ID!
        $content: String
        $versionName: String
      ) {
        richTextGuidelineUpdate(
          input: {
            richTextGuidelineId: $richTextGuidelineId
            content: $content
            versionName: $versionName
          }
        ) {
          richTextGuideline {
            id
          }
        }
      }
    `
  );

  const [commitUpdateRichTextGuidelineStatus] = useMutation<
    { richTextGuideline: { status: string } },
    { richTextGuidelineDocumentId: string }
  >(
    gql`
      mutation PartnerRichTextGuidelineUpdateStatus(
        $richTextGuidelineDocumentId: ID!
      ) {
        richTextGuidelineMakeActive(
          input: { richTextGuidelineDocumentId: $richTextGuidelineDocumentId }
        ) {
          richTextGuideline {
            status
          }
        }
      }
    `
  );

  const handleSaveName = async () => {
    setIsEditingGuidelineName(false);

    await commitUpdateRichTextGuidelineAsAdmin({
      variables: {
        richTextGuidelineId: versionId,
        versionName: guidelineName,
      },
    });

    if (commitUpdateRichTextGuidelineAsAdminMutation.error == null) {
      await partnerGuidelinesVersionsQuery.refetch();
    }
  };

  React.useEffect(() => {
    const initialSections =
      partnerGuidelinesVersionsQuery.data?.richTextGuidelineById?.document
        ?.content;

    const values = initialSections != null ? JSON.parse(initialSections) : null;

    if (values == null) return;

    setSections(values?.sections);
    setActiveSectionId(values?.sections[0]?.id);
  }, [partnerGuidelinesVersionsQuery.data]);

  React.useEffect(() => {
    if (guidelineVersion?.versionName != null) {
      setGuidelineName(guidelineVersion.versionName);
    }
  }, [guidelineVersion?.versionName]);

  const activeSection = React.useMemo(
    () =>
      activeSectionId == null
        ? undefined
        : sections?.find((s) => s.id === activeSectionId),
    [activeSectionId, sections]
  );

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="align-items-baseline d-flex">
          <NavLink className="mb-0" exact to={'/enterprises'}>
            <h2>Enterprise</h2>
          </NavLink>

          <h2 style={{ marginLeft: 10, marginRight: 10, fontWeight: 'normal' }}>
            /
          </h2>

          <NavLink className="mb-0" exact to={`/enterprises/${partner?.uid}`}>
            <h2>{partner?.name}</h2>
          </NavLink>

          <h2 style={{ marginLeft: 10, marginRight: 10, fontWeight: 'normal' }}>
            /
          </h2>

          <NavLink
            className="mb-0"
            exact
            to={`/enterprises/${partner?.uid}/guidelines/${guidelineId}/versions`}
          >
            <h2>
              {guideline?.name}{' '}
              <span className="text-muted">
                {guideline?.archivedAt != null && '(archived)'}
              </span>
            </h2>
          </NavLink>

          <h2 style={{ marginLeft: 10, marginRight: 10, fontWeight: 'normal' }}>
            /
          </h2>

          {guidelineVersion?.versionName != null && (
            <div className="d-flex flex-row align-items-center">
              <div
                style={{
                  width: isEditingGuidelineName ? '100%' : 'fit-content',
                  paddingLeft: 6,
                  paddingRight: 12,
                  borderRadius: 4,
                }}
              >
                {isEditingGuidelineName === true ? (
                  <Component2
                    didMount={() => {
                      guidelineNameInput?.current?.focus();
                    }}
                  >
                    <form
                      className="d-flex align-items-center"
                      onSubmit={(e) => {
                        e.preventDefault();

                        if (guidelineName === '') return;

                        handleSaveName();
                      }}
                    >
                      <input
                        ref={guidelineNameInput}
                        type="text"
                        size={15}
                        style={{
                          width: '100%',
                          fontSize: 32,
                          color: '#141B24',
                          border: 'none',
                          padding: 0,
                          paddingBottom: 8,
                          maxHeight: 38,
                        }}
                        className="form-control"
                        placeholder="Version name"
                        name="guideline name"
                        value={guidelineName ?? ''}
                        onChange={(e) =>
                          setGuidelineName(
                            e.target.value === '' ? '' : e.target.value
                          )
                        }
                        required
                      />

                      <button
                        type="submit"
                        className="btn btn-primary ml-2"
                        disabled={
                          commitUpdateRichTextGuidelineAsAdminMutation.loading ||
                          guidelineName === ''
                        }
                      >
                        Save
                      </button>
                    </form>
                  </Component2>
                ) : (
                  <div className="d-flex align-items-center">
                    <h2
                      style={{
                        padding: 0,
                      }}
                    >
                      {guidelineVersion.versionName}
                    </h2>

                    <button
                      onClick={() => {
                        setIsEditingGuidelineName(true);
                      }}
                      style={{
                        backgroundColor: 'transparent',
                        color: 'gray',
                        border: 'none',
                        outline: 'none',
                        paddingBottom: 10,
                        maxHeight: 42,
                        marginLeft: 8,
                      }}
                    >
                      <i aria-hidden="true" className="fa fa-pencil fa-lg" />
                    </button>
                  </div>
                )}
              </div>

              <div
                style={{
                  marginLeft: 10,
                  marginRight: 8,
                }}
              />

              {guidelineVersion?.status != null && (
                <StatusLabel status={guidelineVersion.status} />
              )}
            </div>
          )}
        </div>

        <button
          className={`btn btn-dark align-items-center justify-content-center d-flex`}
          onClick={() => {
            if (guidelineVersion?.id == null) return;

            commitUpdateRichTextGuidelineStatus({
              variables: {
                richTextGuidelineDocumentId: guidelineVersion.id,
              },
            }).then(() => partnerGuidelinesVersionsQuery.refetch());
          }}
          disabled={guidelineVersion?.status === 'active'}
        >
          Make active
        </button>
      </div>

      <ContentWrapper
        style={{
          backgroundColor: '#F6F9FF',
          padding: 0,
          margin: 0,
          overflowX: undefined,
        }}
      >
        {partnerGuidelinesVersionsQuery.loading ||
        partnerGuidelinesVersionsQuery.data == null ? (
          <p>Loading...</p>
        ) : activeSection == null || sections == null ? null : (
          <div className="editor-container">
            <SectionList
              sections={sections}
              setSections={setSections}
              activeSection={activeSection}
              changeSection={(section) => setActiveSectionId(section.id)}
            />

            <RichTextInput
              key={activeSection.id}
              value={activeSection.content}
              onChange={(newContent) =>
                setSections((prev) =>
                  produce(prev, (draft) => {
                    const sectionToUpdate = draft?.find(
                      (s) => s.id === activeSection.id
                    );

                    if (sectionToUpdate == null) return;

                    sectionToUpdate.content = newContent;
                  })
                )
              }
              session={session}
              history={history}
              location={location}
              guidelineId={guidelineVersion?.id}
            />
          </div>
        )}
      </ContentWrapper>

      <div style={{ height: 71 }} />

      <div
        className="bg-white p-3 px-4 d-flex justify-content-between"
        style={{
          position: 'fixed',
          bottom: 0,
          right: 0,
          left: 0,
          marginLeft: 200,
          boxShadow: '0 0 1.625em rgba(0,0,0,.05)',
          borderTop: '1px solid #E6E9F2',
          zIndex: 1,
        }}
      >
        <button className="btn btn-outline-secondary" disabled>
          Duplicate version
        </button>

        <button
          className="btn btn-dark"
          onClick={() => {
            if (guidelineVersion?.id == null) return;

            commitUpdateRichTextGuidelineAsAdmin({
              variables: {
                content: JSON.stringify({ sections }),
                richTextGuidelineId: guidelineVersion.id,
              },
            });
          }}
          disabled={commitUpdateRichTextGuidelineAsAdminMutation.loading}
        >
          Save changes
        </button>
      </div>

      <style jsx>{`
        .editor-container {
          display: grid;
          grid-template-columns: 320px 1fr;
          min-height: 700px;
          gap: 16px;
        }

        a {
          text-decoration: none;
          margin-bottom: 2rem;
          h2 {
            margin: 0;
          }
          &.active {
            border-bottom: 3px #1f6fff solid;
          }
        }
      `}</style>
    </>
  );
}
