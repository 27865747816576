import React from 'react';

export const BookingStatusBadge = ({ status, ...rest }) => (
  <span
    className={`text-uppercase badge badge-${
      {
        completed: 'success',
        paid: 'warning',
        created: 'danger',
      }[status] || 'light'
    }`}
    {...rest}
  >
    {status}
  </span>
);
