import { useState, useEffect, ReactNode } from 'react';
import moment from 'moment';

import { Modal } from '../../../../../components/Modal';
import { ErrorProcessor } from '../../../../../components/ErrorProcessor';
import { Tooltip } from '../../../../../components/Tooltip';
import { PromiseButton } from '../../../../../components/PromiseButton';
import { usePromise } from '../../../../../components/usePromise';
import { CancellationReasonSelector } from './CancellationReasonSelector';
import { apiFetch } from '../../../../../utils';
import {
  formatCreditsOrMoney,
  transactionIsDismissed,
  providerPaymentIsDismissed,
  notificationIsDismissed,
} from './utils';
import { formatMoneyCents } from '../../../../../utils';

type Transaction = {
  id: string;
  amount: number;
  reason?: string;
  created_at: string;
};

type ProviderPayment = {
  reason: string;
  description: string;
  value: any;
  product_uid?: any;
};

type Notification = {
  type: string;
  description: string;
};

type ValidationResult = {
  cancellation_reasons: {
    reasons: { customer: string[]; provider: string[]; snappr: string[] };
    selected?: string;
  };
  refund_customer: RefundCustomerEnum;
  transactions: {
    original: Transaction[];
    final: Transaction[];
    total: number;
  };
  provider_payments: {
    original: ProviderPayment[];
    final: ProviderPayment[];
    total: number;
  };
  notifications: {
    final: Notification[];
    original: Notification[];
  };
  token: string;
};

type GenericSelectorProps = {
  name: string;
  label: string | ReactNode;
  options: {
    text: string;
    selected: boolean;
  }[];
  disabled?: boolean;
  onChange?: (value) => void;
};

const GenericSelector = ({
  name,
  label,
  options,
  disabled = false,
  onChange = () => {},
}: GenericSelectorProps) => {
  return (
    <div className="d-flex align-items-center mt-2">
      <span className="w-50">{label}</span>

      {options.map(({ text, selected }, index) => (
        <div key={index} className="form-check form-check-inline ml-2 mr-0">
          <input
            id={`${name}-${text}`}
            className="form-check-input"
            type="radio"
            name={name}
            checked={selected}
            disabled={disabled}
            onChange={onChange}
          />
          <label
            htmlFor={`${name}-${text}`}
            className="d-flex align-items-center form-check-label"
          >
            {text}
          </label>
        </div>
      ))}
    </div>
  );
};

enum RefundCustomerEnum {
  NO = 'NO',
  MONEY = 'MONEY',
  GIFT_CARD = 'GIFT_CARD',
}

export function CancellationModal(props) {
  const {
    setPromise: setValidationPromise,
    isPending: validationPending,
    result: validationResult,
  } = usePromise<ValidationResult>();

  const {
    setPromise: setCommitPromise,
    isPending: commitPending,
    error: errorCommit,
  } = usePromise();

  const [cancellationReason, setCancellationReason] = useState<string>();
  const [refundCustomer, setRefundCustomer] = useState<RefundCustomerEnum>(
    RefundCustomerEnum.NO
  );
  const [dropTransactions, setDropTransactions] = useState<string[]>();
  const [dropProviderPayments, setDropProviderPayments] = useState<
    string[] | null
  >(null);
  const [dropNotifications, setDropNotifications] = useState<string[]>();

  const { onDismiss, booking, session } = props;

  const totalProviderPayments = validationResult?.provider_payments.total || 0;

  useEffect(() => {
    setValidationPromise(
      apiFetch(`/api/v2/admin/bookings/${booking.uid}/validate-cancellation`, {
        token: session.token,
        method: 'POST',
        body: JSON.stringify({
          cancellation_reason: cancellationReason,
          refund_customer: refundCustomer,
          drop_transactions: dropTransactions,
          drop_provider_payments: dropProviderPayments,
          drop_notifications: dropNotifications,
        }),
      })
    );
  }, [
    booking.uid,
    session.token,
    setValidationPromise,
    cancellationReason,
    refundCustomer,
    dropTransactions,
    dropProviderPayments,
    dropNotifications,
  ]);

  function toggleTransaction(transaction: Transaction) {
    setDropTransactions((state) =>
      transactionIsDismissed({
        transactions: validationResult?.transactions.final,
        transaction,
      })
        ? (state ?? []).filter((reason) => reason !== transaction.id)
        : [...(state ?? []), transaction.id]
    );
  }

  function toggleProviderPayment(providerPayment: ProviderPayment) {
    setDropProviderPayments((state) =>
      providerPaymentIsDismissed({
        providerPayments: validationResult?.provider_payments.final,
        providerPayment,
      })
        ? (state ?? []).filter((reason) => reason !== providerPayment.reason)
        : [...(state ?? []), providerPayment.reason]
    );
  }

  function toggleNotification(notification: Notification) {
    setDropNotifications((state) =>
      notificationIsDismissed({
        notifications: validationResult?.notifications.final,
        notification,
      })
        ? (state ?? []).filter((reason) => reason !== notification.type)
        : [...(state ?? []), notification.type]
    );
  }

  async function confirmCancellation() {
    const {
      booking,
      session: { token },
      onDismiss,
      onReload,
    } = props;

    if (
      window.confirm(
        'Are you sure you want to cancel this booking?\n' +
          'Before clicking OK, review the information selected in the modal.' +
          '\n - Party at fault \n - Cancellation reason \n - Refunds \n - Photographer payments \n - Summary'
      )
    ) {
      setCommitPromise(
        (async () => {
          await apiFetch(
            `/api/v2/admin/bookings/${booking.uid}/commit-cancellation`,
            {
              token,
              method: 'POST',
              body: JSON.stringify({
                cancellation_reason:
                  validationResult?.cancellation_reasons.selected,
                refund_customer: validationResult?.refund_customer,
                drop_transactions: validationResult?.transactions.original
                  .filter((transaction) =>
                    transactionIsDismissed({
                      transactions: validationResult?.transactions.final,
                      transaction,
                    })
                  )
                  .map(({ id }) => id),
                drop_provider_payments: validationResult?.provider_payments.original
                  .filter((providerPayment) =>
                    providerPaymentIsDismissed({
                      providerPayments:
                        validationResult?.provider_payments.final,
                      providerPayment,
                    })
                  )
                  .map(({ reason }) => reason),
                drop_notifications: validationResult?.notifications.original
                  .filter((notification) =>
                    notificationIsDismissed({
                      notifications: validationResult?.notifications.final,
                      notification,
                    })
                  )
                  .map(({ type }) => type),
                token: validationResult?.token,
              }),
            }
          );

          await onReload();
          onDismiss();
        })()
      );
    }
  }

  const refundOptions = [
    {
      text: 'Gift card',
      value: RefundCustomerEnum.GIFT_CARD,
    },
    {
      text: 'Money',
      value: RefundCustomerEnum.MONEY,
    },
    {
      text: 'No',
      value: RefundCustomerEnum.NO,
    },
  ];

  const filteredRefundOptions = (booking) => {
    if (booking.partner_name !== null) {
      return refundOptions.filter(
        (option) => option.value !== RefundCustomerEnum.GIFT_CARD
      );
    }
    return refundOptions;
  };

  const showSnapprPayingWarning =
    (validationResult?.refund_customer === RefundCustomerEnum.MONEY ||
      validationResult?.refund_customer === RefundCustomerEnum.GIFT_CARD) &&
    validationResult.provider_payments.total >
      validationResult.transactions.total;

  return (
    <Modal onDismiss={onDismiss}>
      <div className="card my-4">
        <div className="card-header">
          <h4 className="mb-0">Cancel Booking</h4>
        </div>

        <div
          className="card-body position-relative d-flex flex-column"
          style={{ width: '1000px', minHeight: '600px' }}
        >
          {!commitPending && errorCommit != null && (
            <ErrorProcessor error={errorCommit}>
              {({ result: errorBody }) =>
                (errorBody || null) && (
                  <div className="alert alert-danger">{errorBody}</div>
                )
              }
            </ErrorProcessor>
          )}

          <div className="alert alert-warning">
            <p>Caution - please read the following steps before proceeding:</p>
            <ol>
              <li>
                Only cancel if the shoot will not move forward with any
                photographer.
              </li>
              <li className="mt-1">
                If the original photographer can’t do the shoot but the client
                still wants to proceed, please reallocate the shoot to a new
                photographer instead.
              </li>
              <li className="mt-1">
                If the client wants to reschedule but is unsure of the new date,
                put the shoot on hold instead of cancelling
              </li>
            </ol>
          </div>

          <div className="card mb-3">
            <div className="card-body mb-n2">
              <h5>Information required</h5>

              {validationResult?.cancellation_reasons.reasons != null && (
                <CancellationReasonSelector
                  reasons={validationResult?.cancellation_reasons.reasons}
                  selectedReason={
                    validationResult.cancellation_reasons.selected
                  }
                  autoSelectReason={false}
                  onReasonChange={({ reason }) => setCancellationReason(reason)}
                />
              )}

              <h6 className="mt-4 font-weight-bolder">Refunds</h6>

              {validationResult != null && (
                <div className="d-flex align-items-center mt-2">
                  <span className="w-50">Refund customer?</span>

                  {filteredRefundOptions(booking).map(
                    ({ text, value }, index) => (
                      <div
                        key={index}
                        className="form-check form-check-inline ml-2 mr-0"
                      >
                        <input
                          id={`refundCustomer-Refund customer?`}
                          className="form-check-input"
                          type="radio"
                          name="refundCustomer"
                          checked={validationResult?.refund_customer === value}
                          disabled={
                            validationResult.cancellation_reasons.selected ==
                            null
                          }
                          onChange={() => setRefundCustomer(value)}
                        />
                        <label
                          id={`refundCustomer-Refund customer?`}
                          className="d-flex align-items-center form-check-label"
                        >
                          {text}
                        </label>
                      </div>
                    )
                  )}
                </div>
              )}

              {(validationResult?.refund_customer ===
                RefundCustomerEnum.MONEY ||
                validationResult?.refund_customer ===
                  RefundCustomerEnum.GIFT_CARD) &&
                validationResult?.transactions.original.length > 0 && (
                  <>
                    <div className="alert alert-warning my-2">
                      <p>
                        Caution - please read the following steps before
                        proceeding:
                      </p>
                      <ol>
                        <li>
                          You should only issue refunds if Snappr or the
                          Photographer is at fault.
                        </li>
                        <li className="mt-1">
                          If the customer is at fault and wants to cancel, then
                          try to convince them to put the shoot on hold instead
                          and rebook at a later time.
                        </li>
                        <li className="mt-1">
                          Under exceptional circumstances, you can issue a
                          refund to a customer when they’re at fault AFTER
                          getting manager approval.
                        </li>
                      </ol>
                    </div>

                    <table className="mt-2 table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Refund?</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Reason</th>
                          <th scope="col">Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {validationResult.transactions.original.map(
                          (transaction) => (
                            <tr key={transaction.id}>
                              <td>
                                <input
                                  type="checkbox"
                                  name={transaction.id}
                                  checked={
                                    !transactionIsDismissed({
                                      transactions:
                                        validationResult.transactions.final,
                                      transaction,
                                    })
                                  }
                                  onChange={() =>
                                    toggleTransaction(transaction)
                                  }
                                />
                              </td>
                              <td>
                                <span className="badge badge-danger">
                                  {formatCreditsOrMoney({
                                    booking,
                                    value: transaction.amount,
                                  })}
                                </span>
                              </td>
                              <td>
                                {'reason' in transaction
                                  ? transaction.reason
                                  : transaction.id}
                              </td>
                              <td>
                                {moment(transaction.created_at).format(
                                  'ddd, MMM Do YYYY, h:mm:ss a zz'
                                )}
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </>
                )}

              <h6 className="mt-4 font-weight-bolder">Photographer payments</h6>

              {showSnapprPayingWarning && (
                <div className="alert alert-warning my-2">
                  Warning - You are about to pay the photographer out of
                  Snappr's pocket. Please get manager approval BEFORE proceeding
                  or avoid refunding the customer.
                </div>
              )}

              {validationResult?.provider_payments.original.map(
                (providerPayment) => {
                  const isDismissed = providerPaymentIsDismissed({
                    providerPayment,
                    providerPayments: validationResult.provider_payments.final,
                  });

                  return (
                    <GenericSelector
                      key={providerPayment.reason}
                      name={providerPayment.reason}
                      label={
                        <>
                          {providerPayment.description}
                          <Tooltip
                            label={
                              providerPayment.reason === 'SHORT_NOTICE_FEE'
                                ? 'Use this if the shoot is within the next 24 hours.'
                                : 'Use this if the shoot is NOT within the next 24 hours.'
                            }
                            style={{
                              background: 'hsla(0, 0%, 0%, 0.75)',
                              color: 'white',
                              border: 'none',
                              borderRadius: '4px',
                              padding: '0.5em 1em',
                              zIndex: '999999999',
                            }}
                          >
                            <i
                              aria-hidden="true"
                              className="fa fa-info-circle ml-2"
                            />
                          </Tooltip>
                        </>
                      }
                      options={[
                        { text: 'Yes', selected: !isDismissed },
                        { text: 'No', selected: isDismissed },
                      ]}
                      disabled={
                        validationResult.cancellation_reasons.selected == null
                      }
                      onChange={() => toggleProviderPayment(providerPayment)}
                    />
                  );
                }
              )}
            </div>
          </div>

          {validationResult?.transactions.total !== 0 && (
            <div className="card mb-3">
              <div className="card-body">
                <h5>Notifications</h5>

                {validationResult?.notifications != null &&
                  validationResult?.notifications.original.length > 0 &&
                  validationResult?.notifications.original.map(
                    (notification) => (
                      <div key={notification.type} className="form-check">
                        <label className="form-check-label">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={
                              !notificationIsDismissed({
                                notifications:
                                  validationResult?.notifications.final,
                                notification,
                              })
                            }
                            onChange={() => toggleNotification(notification)}
                          />

                          <span>{notification.description}</span>
                        </label>
                      </div>
                    )
                  )}
              </div>
            </div>
          )}

          <div className="card mb-3">
            <div className="card-body">
              <h5>Summary</h5>

              <label htmlFor="" className="w-100 mb-2">
                Amount{' '}
                {validationResult?.transactions.total != null &&
                validationResult?.transactions.total <= 0
                  ? 'charged'
                  : 'refunded'}{' '}
                to Customer:{' '}
                <span className="badge badge-danger">
                  {formatCreditsOrMoney({
                    booking,
                    value: validationResult?.transactions.total,
                  })}
                </span>
              </label>

              <label htmlFor="" className="w-100 mb-0">
                Amount paid to Photographer:{' '}
                <span className="badge badge-danger">
                  {formatMoneyCents(totalProviderPayments, {
                    currency: booking.currency || 'USD',
                  })}
                </span>
              </label>
            </div>
          </div>

          <div className="flex-1 d-flex justify-content-between align-items-end mt-3">
            <button className="btn btn-secondary mr-2" onClick={onDismiss}>
              Close
            </button>

            <PromiseButton
              className="btn btn-primary"
              disabled={
                validationPending ||
                commitPending ||
                validationResult?.cancellation_reasons.selected == null
              }
              pendingChildren="Processing..."
              onClick={() => confirmCancellation()}
            >
              {validationResult?.transactions.total != null &&
              validationResult?.transactions.total <= 0
                ? 'Cancel shoot'
                : `Cancel shoot & refund ${formatCreditsOrMoney({
                    booking,
                    value: validationResult?.transactions.total,
                  })}`}
            </PromiseButton>
          </div>

          {(validationPending || commitPending) && (
            <div
              className="d-flex flex-columns justify-content-center align-items-center"
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
              }}
            >
              <span>Loading...</span>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}
