/* eslint no-nested-ternary: ['off'] */

import React, { Component, Fragment } from 'react';

import { Fetcher } from '../../components/Fetcher';
import { Promiser } from '../../components/Promiser';
import { ErrorProcessor } from '../../components/ErrorProcessor';

import BaseLayout from '../../components/BaseLayout';
import ContentWrapper from '../../components/ContentWrapper';
import { apiFetch } from '../../utils';

export class EditRoute extends Component {
  state = {
    abandonedCartPromise: apiFetch(
      `/api/v2/abandoned-carts/${this.props.match.params.abandonedCartId}`,
      { token: this.props.session.token }
    ),
  };

  render() {
    const {
      session,
      session: { token },
      match,
      match: {
        params: { abandonedCartId },
      },
    } = this.props;

    return (
      <Promiser promise={this.state.abandonedCartPromise}>
        {({
          isPending: loadingAbandonedCart,
          result: abandonedCart = {},
          error: abandonedCartError,
        }) => (
          <ErrorProcessor error={abandonedCartError}>
            {({ result: errorBody }) => (
              <Fetcher
                urlToFetch="/api/v2/salesagents"
                queryToFetch={{ limit: 500 }}
                session={session}
              >
                {({ response: { results: salesAgents = [] } = {} }) => (
                  <BaseLayout session={session} match={match}>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <h2>Potential Sale {abandonedCartId}</h2>
                      <ContentWrapper>
                        {loadingAbandonedCart ? (
                          <p>Loading...</p>
                        ) : (
                          <Fragment>
                            {errorBody && (
                              <div className="alert alert-danger">
                                {console.log('errorBody', errorBody) ||
                                  errorBody}
                              </div>
                            )}

                            <p
                              style={{
                                display: 'inline-block',
                                marginRight: 10,
                              }}
                            >
                              Owner:
                            </p>
                            <select
                              value={abandonedCart.salesagent_uid || ''}
                              onChange={(ev) => {
                                const newAgentUid =
                                  ev.target.value === ''
                                    ? null
                                    : ev.target.value;

                                this.setState({
                                  abandonedCartPromise: apiFetch(
                                    `/api/v2/abandoned-carts/${abandonedCartId}`,
                                    {
                                      token,
                                      method: 'PATCH',
                                      body: JSON.stringify({
                                        salesagent_uid: newAgentUid,
                                      }),
                                    }
                                  ),
                                });
                              }}
                              style={{ display: 'inline-block', maxWidth: 300 }}
                            >
                              <option value="">No agent</option>
                              {salesAgents.map((agent) => (
                                <option value={agent.uid} key={agent.uid}>
                                  {agent.name}
                                </option>
                              ))}
                            </select>
                          </Fragment>
                        )}
                      </ContentWrapper>
                    </div>
                  </BaseLayout>
                )}
              </Fetcher>
            )}
          </ErrorProcessor>
        )}
      </Promiser>
    );
  }
}
