import * as React from 'react';

export function BookingReferenceImages({ referenceImages }) {
  return (
    <div className="col d-flex flex-column">
      <div className="card flex-1">
        <div className="card-body">
          <h3 className="d-flex justify-content-between align-items-baseline">
            <span>Reference images</span>
          </h3>
          {referenceImages?.length > 0 ? (
            <>
              <div className="images-container">
                {referenceImages?.map((image) => {
                  return (
                    <div className="image-item" key={image.id}>
                      <img
                        style={{
                          width: '100%',
                          height: '180px',
                          objectFit: 'cover',
                        }}
                        src={image?.small?.url}
                      />
                    </div>
                  );
                })}
              </div>
              <style jsx>{`
                .images-container {
                  display: grid;
                  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
                  grid-gap: 12px;
                }
              `}</style>
            </>
          ) : (
            <p>None</p>
          )}
        </div>
      </div>
    </div>
  );
}
