import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { gql, useQuery } from './ApolloClient';
import { locationForModal } from './ModalRoute';
import { LoadingSpinnerCentered } from './LoadingSpinner';
import { Modal } from './Modal';
import { TdLink } from './index';

export const ClosestProvidersModal = ({
  id,
  onDismiss,
  location,
  history,
  modalParams,
}: {
  id: string;
  onDismiss: () => void;
  location: RouteComponentProps['location'];
  history: RouteComponentProps['history'];
  modalParams: {
    longDistanceRequestId: string;
    modalName: string;
    shootTypeFilter?: boolean;
  };
}) => {
  const longDistanceTrackerClosestProviders = useQuery<
    {
      longDistanceBookingRequestById?: {
        id: string;
        closestProviders?: {
          edges?: {
            id: string;
            provider?: {
              id: string;
              publicFullName?: string;
              email?: string;
              mobilePhoneValidated?: string;
            };
            distanceToShoot?: string;
          }[];
        };
      };
    },
    {
      id: string;
      shootTypeFilter: boolean;
      numberOfProviders: number;
    }
  >(
    gql`
      query LongDistanceTrackerClosestProvidersQuery(
        $id: ID!
        $shootTypeFilter: Boolean
        $numberOfProviders: Int
      ) {
        longDistanceBookingRequestById(id: $id) {
          id
          closestProviders(
            numberOfProviders: $numberOfProviders
            shootTypeFilter: $shootTypeFilter
          ) {
            edges {
              id
              provider {
                id
                publicFullName
                email
                mobilePhoneValidated
              }
              distanceToShoot
            }
          }
        }
      }
    `,
    {
      variables: {
        id: id,
        numberOfProviders: 10,
        shootTypeFilter: modalParams.shootTypeFilter ?? false,
      },
    }
  );

  const providers =
    longDistanceTrackerClosestProviders.data?.longDistanceBookingRequestById
      ?.closestProviders?.edges ?? [];

  const KM_TO_MILE = 0.621371;
  const formatDistanceForBookingInKm = ({ distance }: { distance: number }) =>
    `${(distance / 1000).toFixed(2)} km`;

  const formatDistanceForBookingInMi = ({ distance }: { distance: number }) =>
    `${((distance / 1000) * KM_TO_MILE).toFixed(2)} mi`;

  return (
    <Modal onDismiss={onDismiss}>
      {longDistanceTrackerClosestProviders.loading === true ? (
        <LoadingSpinnerCentered />
      ) : (
        <div
          className="card my-4"
          style={{ minWidth: 900, maxWidth: 900, minHeight: 700 }}
        >
          <div className="card-header">
            <h4 className="mb-0">Closest providers</h4>
          </div>

          <div className="form-check form-check-inline m-3">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="checkbox"
                checked={
                  modalParams.shootTypeFilter == null ||
                  modalParams.shootTypeFilter === false
                    ? false
                    : true
                }
                onChange={(value) => {
                  history.replace(
                    locationForModal({
                      location,
                      modal: {
                        ...modalParams,
                        shootTypeFilter: !modalParams.shootTypeFilter,
                      },
                    })
                  );
                }}
              />
              Specialist
            </label>

            <div style={{ width: 5 }} />

            <i className="fa fa-check-circle text-success" aria-hidden="true" />

            <div style={{ width: 5 }} />

            <span style={{ fontSize: 10, fontStyle: 'italic' }}>
              (Check this box to show only photographers who offer the shoot
              type specified in the current long-distance request.)
            </span>
          </div>

          <div
            className="table-responsive"
            style={{
              position: 'relative',
            }}
          >
            <table className="table table-hover">
              <thead>
                <tr>
                  <th className="text-muted text-truncate">Name</th>
                  <th className="text-muted text-truncate">
                    Distance to shoot
                  </th>
                  <th className="text-muted text-truncate">Email</th>
                  <th className="text-muted text-truncate">Phone</th>
                  <th className="text-muted text-truncate">Provider link</th>
                </tr>
              </thead>

              <tbody>
                {providers?.map((provider) => (
                  <tr key={provider?.id}>
                    <td>{provider?.provider?.publicFullName ?? '-'}</td>
                    <td>
                      {formatDistanceForBookingInKm({
                        distance: Number(provider?.distanceToShoot),
                      })}{' '}
                      or{' '}
                      {formatDistanceForBookingInMi({
                        distance: Number(provider?.distanceToShoot),
                      })}
                    </td>
                    <td>{provider?.provider?.email}</td>
                    <td>{provider?.provider?.mobilePhoneValidated}</td>
                    <TdLink
                      to={`/providers/${provider?.provider?.id}`}
                      className="align-middle"
                      style={{ textDecoration: 'underline' }}
                    >
                      Profile
                    </TdLink>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </Modal>
  );
};
