import qs from 'query-string';
import { Link } from 'react-router-dom';

import { gql, useMutation, useQuery } from '../../components/ApolloClient';
import {
  TableHeaderAttr,
  StyledDatatable,
} from '../../components/StyledDatatable';
import ContentWrapper from '../../components/ContentWrapper';
import { Pagination } from '../../components/Pagination';
import { formatMoneyCents } from '../../utils';
import { ReadOnlyInput } from '../bookings/edit/components/CustomerDetails';
import { useFetch } from '../../components/useFetch';

const BOOKINGS_PER_PAGE = 10;
const PRINT_JOBS_PER_PAGE = 10;

const CustomerDetails = ({
  customerId,
  session,
}: {
  customerId: string;
  session: { token: string };
}) => {
  const userFetch = useFetch<{
    user: { uid: string; firstname: string; surname: string };
  }>({
    urlToFetch: `/api/v2/customers/${customerId}`,
    session,
  });

  const user = userFetch?.result?.user;

  const [requestEmail, { data: email, loading: emailLoading }] = useMutation(
    gql`
      mutation customerRequestEmail($customerId: String!) {
        customerEmailRequestAsAdmin(customerId: $customerId)
      }
    `
  );

  const [requestPhone, { data: phone, loading: phoneLoading }] = useMutation(
    gql`
      mutation customerRequestPhone($customerId: String!) {
        customerMobilePhoneRequestAsAdmin(customerId: $customerId)
      }
    `
  );

  return (
    <>
      {user == null ? (
        <div>Loading...</div>
      ) : (
        <ContentWrapper>
          <div className={`card-body`}>
            <div className="row">
              <div className="col">
                <h3>Customer</h3>
              </div>
            </div>

            <div className="row">
              <ReadOnlyInput label="First name" value={user.firstname} />

              <ReadOnlyInput label="Last name" value={user.surname} />
            </div>

            <div className="row">
              <ReadOnlyInput label="UID" value={user.uid} />
            </div>

            <div className="row">
              <div className="form-group col">
                <label>
                  <small className="text-secondary">Email</small>
                </label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    readOnly={true}
                    value={email?.customerEmailRequestAsAdmin ?? '**********'}
                  />
                  <div className="input-group-append">
                    <button
                      type="button"
                      className="btn btn-outline-secondary"
                      disabled={emailLoading}
                      onClick={() =>
                        requestEmail({ variables: { customerId } })
                      }
                    >
                      <i className="mr-2 fa fa-eye" />
                      Show
                    </button>
                  </div>
                </div>
              </div>
              <div className="form-group col">
                <label>
                  <small className="text-secondary">Phone</small>
                </label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    readOnly={true}
                    value={
                      phone?.customerMobilePhoneRequestAsAdmin ?? '**********'
                    }
                  />
                  <div className="input-group-append">
                    <button
                      type="button"
                      className="btn btn-outline-secondary"
                      disabled={phoneLoading}
                      onClick={() =>
                        requestPhone({ variables: { customerId } })
                      }
                    >
                      <i className="mr-2 fa fa-eye" />
                      Show
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ContentWrapper>
      )}
    </>
  );
};

const GET_CUSTOMER_WITH_BOOKINGS = gql`
  query getCustomerWithBookings(
    $customerId: ID!
    $first: Int
    $offset: Int
    $firstPrints: Int
    $offsetPrints: Int
    $ordering: String
  ) {
    customerByIdAsAdmin(id: $customerId) {
      id
      firstName
      lastName

      bookings(first: $first, offset: $offset, ordering: $ordering) {
        total
        edges {
          id
          createdAt
          city
          suburb
          status

          shootType {
            displayName
          }
        }
      }

      printJobs(first: $firstPrints, offset: $offsetPrints) {
        total
        edges {
          id
          finalPrice
          status
        }
      }
    }
  }
`;

type IGetCustomerWithBooking = {
  customerByIdAsAdmin?: {
    id: string;
    firstName?: string;
    lastName?: string;

    bookings?: {
      total: number;
      edges?: {
        id: string;
        createdAt?: string;
        city?: string;
        suburb?: string;
        status?: string;

        shootType?: {
          displayName?: string;
        };
      }[];
    };

    printJobs?: {
      total: number;
      edges?: {
        id: string;
        finalPrice?: number;
        status: string;
      }[];
    };
  };
};

function CustomerRoute({ location, match, history, session }) {
  const parsedSearch = qs.parse(location.search);
  const customerId = match.params.customer_id;
  const { data, loading } = useQuery<IGetCustomerWithBooking>(
    GET_CUSTOMER_WITH_BOOKINGS,
    {
      variables: {
        customerId,
        first: parseInt((parsedSearch.limit as string) ?? BOOKINGS_PER_PAGE),
        offset: parseInt((parsedSearch.offset as string) ?? '0'),
        firstPrints: parseInt(
          (parsedSearch.limitPrints as string) ?? PRINT_JOBS_PER_PAGE
        ),
        offsetPrints: parseInt((parsedSearch.offsetPrints as string) ?? '0'),
        ordering: parsedSearch.ordering,
      },
    }
  );

  function toggleOrderBy(attrName) {
    const ordering = qs.parse(location.search).ordering;

    let newOrdering;
    if (ordering === attrName) {
      newOrdering = `-${attrName}`;
    } else if (ordering === `-${attrName}`) {
      newOrdering = undefined;
    } else {
      newOrdering = attrName;
    }

    history.push({
      ...location,
      search: qs.stringify({
        ...qs.parse(location.search),
        ordering: newOrdering,
      }),
    });
  }

  if (loading) {
    return <h3>Loading customer {match.params.customer_id}...</h3>;
  }

  const ordering = qs.parse(location.search).ordering;

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CustomerDetails customerId={customerId} session={session} />
      <ContentWrapper>
        <h3>Bookings:</h3>

        <StyledDatatable
          style={{
            overflowX: 'scroll',
          }}
        >
          <table className="table">
            <thead>
              <tr>
                <th>UID</th>
                <TableHeaderAttr
                  attrName="createdAt"
                  label="Created At"
                  ordering={ordering}
                  onClick={() => toggleOrderBy('createdAt')}
                />
                <TableHeaderAttr
                  attrName="city"
                  label="City"
                  ordering={ordering}
                  onClick={() => toggleOrderBy('city')}
                />
                <TableHeaderAttr
                  attrName="suburb"
                  label="Suburb"
                  ordering={ordering}
                  onClick={() => toggleOrderBy('suburb')}
                />
                <TableHeaderAttr
                  attrName="shootType"
                  label="Shoot type"
                  ordering={ordering}
                  onClick={() => toggleOrderBy('shootType')}
                />
                <TableHeaderAttr
                  attrName="status"
                  label="Status"
                  ordering={ordering}
                  onClick={() => toggleOrderBy('status')}
                />
              </tr>
            </thead>
            <tbody>
              {data?.customerByIdAsAdmin?.bookings?.edges?.map((booking) => (
                <tr key={booking.id} className="tableEntry linked">
                  <td>
                    <Link to={`/bookings/${booking.id}`} title={booking.id}>
                      {booking.id}
                    </Link>
                  </td>
                  <td>
                    <Link to={`/bookings/${booking.id}`}>
                      {booking.createdAt}
                    </Link>
                  </td>
                  <td>
                    <Link to={`/bookings/${booking.id}`}>{booking.city}</Link>
                  </td>
                  <td>
                    <Link to={`/bookings/${booking.id}`}>{booking.suburb}</Link>
                  </td>
                  <td>
                    <Link to={`/bookings/${booking.id}`}>
                      {booking.shootType?.displayName}
                    </Link>
                  </td>
                  <td>
                    <Link to={`/bookings/${booking.id}`}>{booking.status}</Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </StyledDatatable>
      </ContentWrapper>

      <div className="d-flex justify-content-end">
        <Pagination
          limit={BOOKINGS_PER_PAGE}
          offset={parseInt(
            (qs.parse(location.search).offset as string) || '0',
            10
          )}
          count={data?.customerByIdAsAdmin?.bookings?.total}
          linkForOffset={(offset, { disabled, ...props }) => (
            <Link
              className="page-link"
              to={{
                ...location,
                search: qs.stringify({
                  ...qs.parse(location.search),
                  offset: offset || undefined,
                }),
              }}
              {...props}
            />
          )}
        />
      </div>

      <div className="p-3" />

      <ContentWrapper>
        <h3>Print jobs:</h3>

        <StyledDatatable
          style={{
            overflowX: 'scroll',
          }}
        >
          <table className="table">
            <thead>
              <tr>
                <th>UID</th>
                <th>Status</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {data?.customerByIdAsAdmin?.printJobs?.edges?.map((printJob) => (
                <tr key={printJob.id} className="tableEntry linked">
                  <td>{printJob.id}</td>
                  <td>{printJob.status}</td>
                  <td>{formatMoneyCents(printJob.finalPrice, null)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </StyledDatatable>
      </ContentWrapper>

      <div className="d-flex justify-content-end">
        <Pagination
          limit={PRINT_JOBS_PER_PAGE}
          offset={parseInt(
            (qs.parse(location.search).offsetPrints as string) || '0',
            10
          )}
          count={data?.customerByIdAsAdmin?.printJobs?.total}
          linkForOffset={(offset, { disabled, ...props }) => (
            <Link
              className="page-link"
              to={{
                ...location,
                search: qs.stringify({
                  ...qs.parse(location.search),
                  offsetPrints: offset || undefined,
                }),
              }}
              {...props}
            />
          )}
        />
      </div>
    </div>
  );
}

export default CustomerRoute;
