import qs from 'query-string';
import React, { ReactElement } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { ensureString } from '../utils/strings';

export const locationForModal = ({ location, modal, search = undefined }) => ({
  ...location,
  search: qs.stringify({
    ...(search != null ? search : qs.parse(location.search)),
    modal: modal === undefined ? undefined : JSON.stringify(modal),
  }),
});

export function parseModalQuery({ location, catchErrors = true }) {
  const parsedSearch = qs.parse(location.search);

  let modalParams;
  try {
    modalParams =
      parsedSearch.modal == null
        ? null
        : JSON.parse(ensureString(parsedSearch.modal) ?? '');
  } catch (err) {
    if (catchErrors === false) {
      throw err;
    }
  }

  return modalParams;
}

export const ModalRoute = ({
  modalName,
  render,
  children,
}: {
  modalName: string;
  render?;
  children?;
}): ReactElement<RouteProps & { modalParams }> => (
  <Route>
    {(routeProps) => {
      const { location } = routeProps;

      let modalParams;
      try {
        modalParams = parseModalQuery({ location, catchErrors: false });
      } catch (err) {
        return (
          <Redirect
            to={locationForModal({
              location,
              modal: undefined,
            })}
          />
        );
      }

      if (!modalParams || modalParams.modalName !== modalName) {
        return null;
      }

      // @ts-ignore
      return (children || render)({ ...routeProps, modalParams });
    }}
  </Route>
);
