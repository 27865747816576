import React from 'react';

export class Promiser extends React.Component {
  state = { isPending: !!this.props.promise, error: null, result: null };

  componentDidMount() {
    this.subscribeToPromise(this.props.promise);
  }

  componentDidUpdate(prevProms) {
    if (prevProms.promise !== this.props.promise) {
      this.subscribeToPromise(this.props.promise);
    }
  }

  componentWillUnmount() {
    this._promise = null;
  }

  subscribeToPromise(promise) {
    this._promise = promise;
    if (!promise) {
      this.setState({ isPending: false, error: null, result: null });
      return;
    }

    this.setState({ isPending: true });
    return promise
      .then((result) => {
        if (promise !== this._promise) return;
        if ('onFulfilled' in this.props) {
          this.props.onFulfilled({ result });
        }
        this.setState({ isPending: false, result, error: null });
      })
      .catch((error) => {
        if (promise !== this._promise) return;
        this.setState({ isPending: false, result: null, error });
      });
  }

  render() {
    const render = this.props.children || this.props.render;
    return render({
      isPending: this.state.isPending,
      error: this.state.error || undefined,
      result: this.state.result || undefined,
    });
  }
}
