import * as React from 'react';
import AutosizeInput from 'react-input-autosize';
import { PillBadge } from './PillBadge';

export function PillBadgeList({
  items = [],
  maxItems,
  backgroundColor = '#F6F9FF',
  onAddBackgroundColor = '#C1C8D4',
  onAddColor = '#F6F9FF',
  placeholderWhite = false,
  onDelete,
  onAdd,
}: {
  items: string[];
  maxItems?: number;
  backgroundColor?: string;
  onAddBackgroundColor?: string;
  onAddColor?: string;
  showSeeMoreButton?;
  placeholderWhite?: boolean;
  onDelete?: (tag: any) => void;
  onAdd?: (value: any) => void;
}) {
  const [value, setValue] = React.useState('');
  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 8,
        alignItems: 'center',
      }}
    >
      {items
        .map((item, index) => {
          return (
            <PillBadge
              item={item}
              key={index}
              onDelete={onDelete}
              backgroundColor={backgroundColor}
            >
              {item}
            </PillBadge>
          );
        })
        .slice(0, maxItems)}
      {onAdd && (
        <>
          <AutosizeInput
            className={placeholderWhite ? 'autoSizeWhite' : 'autoSizeBlack'}
            value={value}
            onChange={({ target: { value } }) => setValue(value)}
            placeholder="+ add"
            onFocus={(e) => (e.target.placeholder = '')}
            onBlur={(e) => (e.target.placeholder = '+ add')}
            onKeyPress={({ key }) => {
              if (key === 'Enter' && value) {
                onAdd?.(value);
                setValue('');
              }
            }}
            inputStyle={{
              backgroundColor: onAddBackgroundColor,
              padding: '1px 8px',
              textTransform: 'lowercase',
              borderRadius: 15,
              color: onAddColor,
              fontSize: '10px',
              border: 'none',
              outline: 'none',
              height: ' min-content',
              minWidth: '30px',
              display: 'flex',
            }}
          />

          <style global jsx>{`
            .autoSizeWhite input::placeholder {
              color: white;
            }

            .autoSizeBlack input::placeholder {
              color: black;
            }
          `}</style>
        </>
      )}
    </div>
  );
}
