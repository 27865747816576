import React from 'react';
import Dropzone from 'react-dropzone';
import { ZQueue } from 'zqueue';

import { apiFetch } from '../../utils';
import {
  UploadImagePreview,
  useUploadManager,
  ProgressIndicator,
  IUpload,
} from '../../components/useUploadManager';
import { useQuery, gql, useMutation } from '../../components/ApolloClient';
import { LoadingSpinnerCentered } from '../../components/LoadingSpinner';
import { SimpleLine } from './components/SimpleLine';
import { Heading5 } from './components';

type Image = {
  url: string;
  width: number;
};

type GalleryImage = {
  id: string;
  imageRatio?: number;
  medium?: Image;
  large?: Image;
  downloadUrl?: string;
};

type EditJobItem = {
  id: string;
  originalImage: GalleryImage;
  finalImage?: GalleryImage;
};

type Job = {
  id: string;

  items?: {
    cursor: string;
    edges: EditJobItem[];
  };
};

type ImageOrUploadItem<T> = { id: string; finalImage?: GalleryImage } & (
  | { upload: IUpload<T> }
  | { originalImage: GalleryImage }
);

function ImageRow<T>({
  item,
  session: { token },

  editingJobId,
  reload,
  queue,
}: {
  item: ImageOrUploadItem<T>;
  session: { token: string };
  editingJobId: string;
  reload;
  queue: ZQueue;
}) {
  const [saveFinals] = useMutation<
    any,
    {
      editingJobId: string;
      originalMediaId: string;
      finalMediaId: string | null;
    }
  >(
    gql`
      mutation EditingJobSaveImagesUpdate(
        $editingJobId: ID!
        $originalMediaId: ID!
        $finalMediaId: ID
      ) {
        editingJobUpdateItem(
          input: {
            editingJobId: $editingJobId
            originalMediaId: $originalMediaId
            finalMediaId: $finalMediaId
          }
        ) {
          item {
            id
          }
        }
      }
    `
  );

  const { uploadsList, addFiles } = useUploadManager({
    token,
    onUploadReady:
      'originalImage' in item
        ? ({ fileName, mediaUid, remove }) =>
            apiFetch('/api/v2/media/create-from-temp', {
              token,
              method: 'POST',
              body: JSON.stringify({
                tempMediaUid: mediaUid,
                fileName,
              }),
            }).then(() =>
              saveFinals({
                variables: {
                  editingJobId,
                  originalMediaId: item.originalImage.id,
                  finalMediaId: mediaUid ?? '',
                },
              })
                .then(() => reload())
                .then(() => remove())
            )
        : () => {},
    queue,
  });

  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
      }}
    >
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          flexBasis: 0,
          flexGrow: 10,
          position: 'relative',
        }}
      >
        {'upload' in item ? (
          <>
            <UploadImagePreview
              file={item.upload.file}
              alt="preview"
              width="300"
              height="200"
              style={{ objectFit: 'contain', opacity: 0.3 }}
            />

            {item.upload.sizeUploaded != null && item.upload.fileSize != null && (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <ProgressIndicator
                  progress={item.upload.sizeUploaded / item.upload.fileSize}
                />
              </div>
            )}
          </>
        ) : (
          <img
            alt="originalImage"
            src={item?.originalImage?.medium?.url}
            width="300"
            height="200"
            style={{ objectFit: 'contain' }}
          />
        )}
      </div>

      <div
        style={{
          flex: 1,
          display: 'flex',
          flexBasis: 0,
          flexGrow: 1,
        }}
      />

      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          flexBasis: 0,
          flexGrow: 10,
          position: 'relative',
        }}
      >
        {item.finalImage == null ? (
          uploadsList.length > 0 ? (
            <>
              <UploadImagePreview
                file={uploadsList[0].file}
                alt="preview"
                width="300"
                height="200"
                style={{ objectFit: 'contain' }}
              />

              {uploadsList[0].sizeUploaded != null &&
                uploadsList[0].fileSize != null && (
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      bottom: 0,
                      left: 0,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <ProgressIndicator
                      progress={
                        uploadsList[0].sizeUploaded / uploadsList[0].fileSize
                      }
                    />
                  </div>
                )}
            </>
          ) : (
            'originalImage' in item && (
              <Dropzone
                accept="image/jpeg"
                onDrop={(acceptedFiles) => addFiles({ acceptedFiles })}
              />
            )
          )
        ) : (
          <>
            <img
              alt="final"
              src={item?.finalImage?.medium?.url}
              height="200"
              style={{ objectFit: 'contain' }}
            />

            <button
              style={{ position: 'absolute', top: 0, right: 0, border: 'none' }}
              onClick={() =>
                'originalImage' in item &&
                saveFinals({
                  variables: {
                    editingJobId,
                    originalMediaId: item?.originalImage.id,
                    finalMediaId: null,
                  },
                }).then(() => reload())
              }
            >
              x
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export const NoSubmissionsGalleryUploadRoute = ({
  editingJob,
  session,
  reload,
}: {
  editingJob: Job;
  session: { token: string };
  reload;
}) => {
  const [queue] = React.useState(() => new ZQueue({ max: 3 }));

  const [saveFinals] = useMutation<
    any,
    {
      editingJobId: string;
      originalMediaId: string;
    }
  >(
    gql`
      mutation EditingJobSaveImagesCreate(
        $editingJobId: ID!
        $originalMediaId: ID!
      ) {
        editingJobCreateItem(
          input: {
            editingJobId: $editingJobId
            originalMediaId: $originalMediaId
          }
        ) {
          item {
            id
          }
        }
      }
    `
  );

  const { uploadsList, addFiles } = useUploadManager({
    token: session.token!,
    onUploadReady: ({ fileName, mediaUid, remove }) =>
      apiFetch('/api/v2/media/create-from-temp', {
        token: session.token,
        method: 'POST',
        body: JSON.stringify({
          tempMediaUid: mediaUid,
          fileName,
        }),
      }).then(() =>
        saveFinals({
          variables: {
            editingJobId: editingJob.id,
            originalMediaId: mediaUid ?? '',
          },
        })
          .then(() => reload())
          .then(() => remove())
      ),
    queue,
  });

  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <h3>Gallery</h3>
      </div>

      <Dropzone
        className="card bg-transparent mb-3"
        accept="image/jpeg"
        onDrop={(acceptedFiles) => addFiles({ acceptedFiles })}
      >
        <div className="card-body text-center">
          <h5>Drop your images</h5>

          <button className="btn btn-outline-primary mt-2">
            or click here to upload
          </button>
        </div>
      </Dropzone>

      <div className="pt-2" />

      <div
        style={{
          backgroundColor: 'white',
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          padding: 20,
        }}
      >
        <div
          style={{
            flex: 1,
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
          }}
        >
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              flexBasis: 0,
              flexGrow: 10,
            }}
          >
            <Heading5 title="Original files" />
            <div
              style={{
                display: 'flex',
                flex: 1,
                height: 1,
                width: '100%',
              }}
            >
              <SimpleLine />
            </div>
          </div>

          <div
            style={{
              flex: 1,
              display: 'flex',
              flexBasis: 0,
              flexGrow: 1,
            }}
          />

          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              flexBasis: 0,
              flexGrow: 10,
            }}
          >
            <Heading5 title="Edited images" />
            <div
              style={{
                display: 'flex',
                flex: 1,
                height: 1,
                width: '100%',
              }}
            >
              <SimpleLine />
            </div>
          </div>
        </div>

        {React.useMemo(
          () => [
            ...(editingJob.items?.edges ?? []).map((i) => ({
              id: i.id,
              originalImage: i.originalImage,
              finalImage: i.finalImage,
            })),
            ...uploadsList.map((i) => ({
              id: i.clientId,
              upload: i,
            })),
          ],
          [editingJob.items, uploadsList]
        ).map((item) => (
          <div key={item.id}>
            <ImageRow
              editingJobId={editingJob.id}
              reload={reload}
              item={item}
              session={session}
              queue={queue}
            />

            <div style={{ height: 10 }} />
            <SimpleLine />
            <div style={{ height: 10 }} />
          </div>
        ))}
      </div>
    </>
  );
};

export const GalleryUploadRoute = ({
  match,
  session,
}: {
  match;
  session: { token: string };
}) => {
  const {
    params: { jobId },
  } = match;

  const jobQuery = useQuery<{
    editingJob?: Job;
  }>(
    gql`
      query EditingJobImagesOnGalleryUpload(
        $jobId: ID!
        $cursor: String
        $first: Int
      ) {
        editingJob: editingJobById(id: $jobId) {
          id
          # status
          items(first: $first, cursor: $cursor) {
            cursor
            edges {
              id
              finalImage {
                id
                imageRatio
                downloadUrl
                medium {
                  url
                  width
                }
                large {
                  url
                  width
                }
              }
              originalImage {
                id
                imageRatio
                downloadUrl
                medium {
                  url
                  width
                }
                large {
                  url
                  width
                }
              }
            }
          }
        }
      }
    `,
    {
      variables: {
        first: 5000,
        jobId,
      },
    }
  );

  const editingJob = jobQuery.data?.editingJob;

  return jobQuery && editingJob ? (
    editingJob.items?.edges ? (
      <NoSubmissionsGalleryUploadRoute
        session={session}
        editingJob={editingJob}
        reload={jobQuery.refetch}
      />
    ) : (
      // TODO: improve
      <div>ERROR</div>
    )
  ) : (
    <LoadingSpinnerCentered />
  );
};
