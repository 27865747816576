import React, { useState, useEffect } from 'react';
import uuidV4 from 'uuid/v4';
import axios from 'axios';
import { ZQueue } from 'zqueue';
import { useInView } from 'react-intersection-observer';

import { apiFetch } from '../utils';

export const isVideo = (file) => file?.type === 'video/mp4';

export const isVideoByFileName = (fileName) => {
  const regExp = /(?:\.([^.]+))?$/;
  return regExp.exec(fileName)?.[1] === 'mp4';
};

class UploadManagerBase extends React.Component<any, any> {
  state = {
    queue: new ZQueue({ max: this.props.uploadConcurrency || 3 }),
    uploadIds: [],
    uploads: {},
  };

  createUpload = ({ clientId, file }) => {
    const axiosCancelSource = axios.CancelToken.source();

    return {
      clientId,
      file,
      mediaUid: null,
      fileSize: null,
      sizeUploaded: null,
      axiosCancelSource,
      fileName: file.name,

      preSignedUrlPromise: null,
      uploadPromise: null,
      mediaCreationPromise: null,

      onRestart: () =>
        this.setState((state) => ({
          uploads: {
            ...state.uploads,
            [clientId]: this.createUpload({ clientId, file }),
          },
        })),

      poolerPromise: this.state.queue.run(() => {
        const preSignedUrlPromise = this.props.onPresignedPost({
          fileType: file.type,
        });

        const uploadPromise = preSignedUrlPromise.then(
          ({ signedUrl, mediaUid }) => {
            this.setState((state) => ({
              uploads: {
                ...state.uploads,
                [clientId]: state.uploads[clientId] && {
                  ...state.uploads[clientId],
                  mediaUid,
                },
              },
            }));
            return axios
              .put(signedUrl, file, {
                onUploadProgress: (progressEvent) => {
                  this.setState((state) => ({
                    uploads: {
                      ...state.uploads,
                      [clientId]: {
                        ...state.uploads[clientId],
                        fileSize: progressEvent.total,
                        sizeUploaded: progressEvent.loaded,
                      },
                    },
                  }));
                },
                cancelToken: axiosCancelSource.token,
                headers: { 'Content-Type': file.type },
              })
              .then(() => ({ mediaUid }));
          }
        );

        this.setState((state) => ({
          uploads: {
            ...state.uploads,
            [clientId]: {
              ...state.uploads[clientId],

              uploadProgress: 0,
              axiosCancelSource,

              preSignedUrlPromise,
              uploadPromise,
              mediaCreationPromise: uploadPromise
                .then(({ mediaUid }) =>
                  this.props.onUploadReady({
                    fileName: file.name,
                    mediaUid,
                    remove: () =>
                      this.setState((state) => ({
                        uploadIds: state.uploadIds.filter(
                          (id) => id !== clientId
                        ),
                        uploads: {
                          ...state.uploads,
                          [clientId]: undefined,
                        },
                      })),
                    isVideo: isVideo(file),
                  })
                )
                .catch((error) => {
                  if (axios.isCancel(error)) return;
                  throw error;
                }),
            },
          },
        }));

        return uploadPromise;
      }),
    };
  };

  addFiles = ({ acceptedFiles }) => {
    const filesWithId = [...acceptedFiles].map((file) => ({
      clientId: uuidV4(),
      file,
    }));

    this.setState((state) =>
      filesWithId.reduce(
        (res, { clientId, file }) => ({
          uploadIds: [...res.uploadIds, clientId],
          uploads: {
            ...res.uploads,
            [clientId]: this.createUpload({ clientId, file }),
          },
        }),
        {
          uploadIds: state.uploadIds,
          uploads: state.uploads,
        }
      )
    );
  };

  render() {
    return (this.props.children as any)({
      addFiles: this.addFiles,
      uploads: this.state.uploadIds.map(
        (clientId) => this.state.uploads[clientId]
      ),
    });
  }
}

export const UploadManager = ({ session: { token }, ...props }) => (
  <UploadManagerBase
    {...props}
    onPresignedPost={({ fileType }) =>
      apiFetch(`/api/v2/media/presigned-post`, {
        token,
        method: 'POST',
        body: JSON.stringify({ fileType }),
      })
    }
  />
);

const TRANSPARENT_IMAGE_URL =
  'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

export function UploadImagePreview({ file, ...rest }) {
  const [imageUrl, setImageUrl] = useState(TRANSPARENT_IMAGE_URL);

  const [ref, inView] = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (file != null && inView) {
      const url = window.URL.createObjectURL(file);
      setImageUrl(url);

      return () => {
        setImageUrl(TRANSPARENT_IMAGE_URL);
        window.URL.revokeObjectURL(url);
      };
    }
  }, [file, inView]);

  return <img alt="preview" {...rest} ref={ref} src={imageUrl} />;
}
