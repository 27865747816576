import React from 'react';
import { Link } from 'react-router-dom';
import qs from 'query-string';

export const OrderByLink = ({ attrName, location, children }) => {
  const ordering = qs.parse(location.search).ordering;
  const newOrdering =
    ordering === attrName
      ? `-${attrName}`
      : ordering === `-${attrName}`
      ? undefined
      : attrName;
  return (
    <Link
      className="text-dark"
      to={{
        ...location,
        search: qs.stringify({
          ...qs.parse(location.search),
          ordering: newOrdering,
        }),
      }}
    >
      {children}{' '}
      {ordering &&
        ordering.includes(attrName) &&
        (ordering.includes('-') ? (
          <i className="fa fa-caret-down" />
        ) : (
          <i className="fa fa-caret-up" />
        ))}
    </Link>
  );
};
