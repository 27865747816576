import { useEffect, useCallback } from 'react';
import qs from 'query-string';

import { usePromise, IPromiser } from './usePromise';
import { apiFetch } from '../utils/apiFetch';

export type IFetcher<T> = Omit<IPromiser<T>, 'setPromise'> & {
  reload: () => Promise<T | null>;
};

export function useFetch<T>({
  urlToFetch,
  queryToFetch = {},
  session,
  token = (session || {}).token,
  disabled = false,
}: {
  urlToFetch: string;
  queryToFetch?: any;
  session?: { token: string };
  token?: string;
  disabled?: boolean;
}): IFetcher<T> {
  const { setPromise, isPending, ...rest } = usePromise<T>();
  const { promise } = rest;

  const queryStringify = qs.stringify(queryToFetch);
  const urlWithQuery =
    queryStringify && queryStringify.length > 0
      ? `${urlToFetch}?${queryStringify}`
      : urlToFetch;

  const load = useCallback(async () => {
    const promise = disabled ? null : apiFetch<T>(urlWithQuery, { token });
    setPromise(promise);
    return promise;
  }, [urlWithQuery, token, setPromise, disabled]);

  useEffect(() => {
    load();
  }, [load]);

  return {
    ...rest,
    isPending: isPending || (promise == null && !disabled),
    reload: load,
  };
}
