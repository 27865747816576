import { useState, useEffect } from 'react';

export function useInBrowser() {
  const [inBrowser, setInBrowser] = useState(false);

  useEffect(() => {
    setInBrowser(true);
  }, []);

  return inBrowser;
}
