import moment, { Moment } from 'moment-timezone';
import qs from 'query-string';
import React, { Fragment, useCallback, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { OnBeforeUnload } from '../../../components/OnBeforeUnload';
import { apiFetch } from '../../../utils';
import {
  BookingSummaryDetails,
  ConfirmAfterPaidChanges,
  CustomerDetails,
  DateTimeDetails,
  LocationDetails,
  PaymentLinkButton,
  PhotographerDetails,
} from './components';
import { OnHoldModal } from './components/ConfirmAfterPaidChanges/OnHoldModal';
import { ProviderCancellationModal } from './components/ConfirmAfterPaidChanges/ProviderCancellationModal';
import { ChargeCustomerButton } from './components/ChargeCustomerButton';
import { ChargeCustomerDepositButton } from './components/ChargeCustomerDepositButton';
import { PackageTab } from './components/PackageTab';
import {
  bookingHasBeingPaid,
  bookingHasGallery,
  bookingCanBeOnHold,
  BOOKING_STATUS,
  isPartnerBooking,
} from './selectors';
import {
  calculateChangesToPatch,
  calculateKeysThatChanged,
  isAutoAllocationRunning,
} from './utils';
import { AvailabilityFetcher } from './components/AvailabilityFetcher';
import { usePromise } from '../../../components/usePromise';
import { PartnerChargeButton } from './components/PartnerChargeButton';
import { BookingEditRouteType } from './types';
import { NextBookingModal } from './components/NextBookingPrediction/NexBookingModal';
import { SetFromPaidPendingScheduleToPaid } from './components/SetFromPaidPendingScheduleToPaid';
import { BookingExtraDetails } from './components/BookingExtraDetails';
import { BookingReferenceImages } from './components/BookingReferenceImages';
import { gql, useQuery } from '../../../components/ApolloClient';
import { API_URL } from '../../../config';

const ActionButtonsContainer = styled.div`
  .btn {
    min-width: calc(20% - 1rem);

    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
`;

export const BookingRoute: React.FC<{
  session: { token: string };

  match: {
    params: { bookingId: string };
    url: string;
  };
  rootBasePath: string;
  history;
  location;
}> = ({
  session,
  session: { token },
  match,
  match: {
    params: { bookingId },
  },
  rootBasePath,
  history,
  location,
}) => {
  const createBookingPromiser = usePromise<BookingEditRouteType>();
  const patchBookingPromiser = usePromise<BookingEditRouteType>();
  const chargePartnerBookingPromiser = usePromise<BookingEditRouteType>();
  const bookingPromiser = usePromise<BookingEditRouteType>();
  const { setPromise: setBookingPromiser } = bookingPromiser;

  const [booking, setBooking] = useState<BookingEditRouteType>({});
  const [bookingClone, setBookingClone] = useState<BookingEditRouteType>({});

  const createBooking = useCallback(
    (bookingData) => {
      const promiseToSet = apiFetch<BookingEditRouteType>(
        '/api/v2/admin/bookings',
        {
          token,
          method: 'POST',
          body: JSON.stringify(bookingData),
        }
      ).then((createdBooking) => {
        history.replace({
          ...location,
          pathname: `${rootBasePath}/${createdBooking.uid}`,
        });

        return createdBooking;
      });

      createBookingPromiser.setPromise(promiseToSet);

      setBookingPromiser(
        promiseToSet.catch((err) =>
          err.json().then((res) => Promise.reject(res))
        )
      );

      return promiseToSet;
    },
    [
      createBookingPromiser,
      history,
      location,
      rootBasePath,
      setBookingPromiser,
      token,
    ]
  );

  const patchBooking = useCallback(
    (bookingChanges) => {
      const promiseToSet = apiFetch<BookingEditRouteType>(
        `/api/v2/admin/bookings/${booking?.uid}`,
        {
          token,
          method: 'PATCH',
          body: JSON.stringify(bookingChanges),
        }
      );
      patchBookingPromiser.setPromise(promiseToSet);
      setBookingPromiser(
        promiseToSet.catch((err) =>
          err.json().then((res) => Promise.reject(res))
        )
      );

      return promiseToSet;
    },
    [booking, patchBookingPromiser, setBookingPromiser, token]
  );

  const chargePartnerBooking = useCallback(() => {
    if (window.confirm(`Are you sure you want charge this partner?`) !== true) {
      return;
    }

    const promiseToSet = apiFetch<BookingEditRouteType>(
      `/api/v2/admin/bookings/${booking?.uid}/charge-partner`,
      {
        token,
        method: 'POST',
      }
    );

    chargePartnerBookingPromiser.setPromise(promiseToSet);
    setBookingPromiser(
      promiseToSet.catch((err) => err.json().then((res) => Promise.reject(res)))
    );

    return promiseToSet;
  }, [booking, chargePartnerBookingPromiser, setBookingPromiser, token]);

  const reloadBooking = useCallback(() => {
    const promiseToSet = apiFetch<BookingEditRouteType>(
      `/api/v2/admin/bookings/${bookingId}`,
      {
        token,
      }
    );

    setBookingPromiser(promiseToSet);

    return promiseToSet;
  }, [bookingId, token, setBookingPromiser]);

  const [selectedDate, setSelectedDate] = useState<Moment>(
    booking?.start_at
      ? booking?.timezone
        ? moment(booking.start_at).tz(booking.timezone)
        : moment(booking.start_at)
      : moment()
  );

  const startAtClone = bookingClone?.start_at;
  const timezoneClone = bookingClone?.timezone;

  const bookingQuery = useQuery<{
    bookingById?: {
      id: string;
      notesToEditor?: string;
      complianceIssuesNotes?: string;
      rawUploadStartedOrCompletedAt?: string;

      package?: {
        tier?: string;
      };

      ecommerceData?: {
        shootingElements?: string[];
        style?: string;
        background?: string;
        size?: string;
        numberOfUniqueItems?: number;
        angles?: string[];
        numberOfMacroShots?: number;
        numberOfOtherShots?: number;
      };
      referenceImages?: {
        edges?: {
          id: string;
          small?: {
            url: string;
          };
        }[];
      };
      workflowRunExtraDetails?: string;
      guidelines?: {
        uid: string;
        richTextGuidelines?: { edges: { id: string }[] };
      }[];
    };
  }>(
    gql`
      query BookingExtraDetailsQuery($bookingId: ID!) {
        bookingById(id: $bookingId) {
          id
          complianceIssuesNotes
          notesToEditor
          rawUploadStartedOrCompletedAt

          package {
            tier
          }

          ecommerceData {
            id
            shootingElements
            style
            background
            size
            numberOfUniqueItems
            angles
            numberOfMacroShots
            numberOfOtherShots
          }
          referenceImages {
            edges {
              id
              small {
                url
              }
            }
          }
          workflowRunExtraDetails

          guidelines {
            uid
            richTextGuidelines {
              edges {
                id
              }
            }
          }
        }
      }
    `,
    {
      variables: { bookingId: booking.uid },
      skip: booking.uid == null,
    }
  );

  useEffect(() => {
    if (startAtClone != null) {
      setSelectedDate(
        startAtClone
          ? timezoneClone
            ? moment(startAtClone).tz(timezoneClone)
            : moment(startAtClone)
          : moment()
      );
    }
  }, [startAtClone, timezoneClone]);

  useEffect(() => {
    setBookingPromiser(
      bookingId === 'new'
        ? Promise.resolve({})
        : apiFetch<BookingEditRouteType>(
            `/api/v2/admin/bookings/${bookingId}`,
            {
              token,
            }
          )
    );
  }, [bookingId, setBookingPromiser, token]);

  const isNew = bookingId === 'new';

  const bookingChangedKeys =
    booking && bookingClone
      ? calculateKeysThatChanged(booking, bookingClone)
      : {};

  const bookingHasChanges = Object.keys(bookingChangedKeys).length > 0;

  useEffect(() => {
    // If the promise failed, do not reset the booking Clone.
    // This allows us to maintain the edited fields when a validation error occurs
    if (bookingPromiser.error != null) return;

    const refreshedBookingRaw = bookingPromiser.result
      ? bookingPromiser.result
      : {};

    const refreshedBooking = {
      ...refreshedBookingRaw,
      start_at:
        refreshedBookingRaw.start_at &&
        moment(refreshedBookingRaw.start_at).toISOString(),
    };

    setBooking(refreshedBooking);
    setBookingClone(refreshedBooking);
  }, [bookingPromiser.error, bookingPromiser.result]);

  const referenceImages =
    bookingQuery.data?.bookingById?.referenceImages?.edges ?? [];
  const ecommerceData = bookingQuery.data?.bookingById?.ecommerceData;

  const workflowRunExtraDetails =
    bookingQuery.data?.bookingById?.workflowRunExtraDetails;

  const pgGuideline = bookingQuery.data?.bookingById?.guidelines?.filter(
    ({ richTextGuidelines }) => (richTextGuidelines?.edges ?? []).length > 0
  )[0]?.richTextGuidelines?.edges[0];

  const editingExtraDetails =
    booking.centralize_editing_enabled !== false &&
    bookingQuery.data?.bookingById?.rawUploadStartedOrCompletedAt != null
      ? {
          isCentrallyEdited: booking.centralize_editing_enabled,
          rawUploadStartedOrCompletedAt:
            bookingQuery.data?.bookingById?.rawUploadStartedOrCompletedAt,
          complianceIssuesNotes:
            bookingQuery.data?.bookingById?.complianceIssuesNotes,
          notesToEditor: bookingQuery.data?.bookingById?.notesToEditor,
        }
      : undefined;

  return (
    <AvailabilityFetcher
      excludeBookingId={bookingClone?.uid}
      session={session}
      coords={bookingClone?.point}
      timezone={bookingClone?.timezone}
      selectedDate={selectedDate}
      duration={bookingClone?.duration}
      shoottype={bookingClone?.shoottype}
    >
      {({ providersAvailability, providersAvailabilityLoading }) => (
        <Fragment>
          {bookingPromiser.error && (
            <div className="alert alert-danger">
              {
                // @ts-ignore
                bookingPromiser.error.errors ? (
                  // @ts-ignore
                  bookingPromiser.error.errors?.length === 1 ? (
                    // @ts-ignore
                    <span>{bookingPromiser.error.errors[0]}</span>
                  ) : (
                    <ul className="m-0">
                      {
                        // @ts-ignore
                        bookingPromiser.error.errors.map((error) => (
                          <li key={error}>{error}</li>
                        ))
                      }
                    </ul>
                  )
                ) : (
                  <span>Error</span>
                )
              }
            </div>
          )}
          {isAutoAllocationRunning({ booking: bookingClone }) && (
            <span className="alert alert-danger">
              Auto allocating a provider. Please don't modify the booking
            </span>
          )}

          <div className="row mb-4 d-flex align-items-stretch">
            <BookingSummaryDetails
              isNew={isNew}
              booking={
                {
                  ...booking,
                  product_tier: bookingQuery.data?.bookingById?.package?.tier,
                } ?? {}
              }
              bookingClone={bookingClone ?? {}}
              bookingChangedKeys={bookingChangedKeys}
              session={session}
              onPurposeChange={({ purpose }) =>
                setBookingClone((bookingClone) => ({
                  ...bookingClone,
                  purpose: purpose || booking?.purpose,
                }))
              }
              onNameChange={({ name }) =>
                setBookingClone((bookingClone) => ({
                  ...bookingClone,
                  name: name || booking?.name,
                }))
              }
              onInternalNotesChange={({ internal_notes }) =>
                setBookingClone((bookingClone) => ({
                  ...bookingClone,
                  internal_notes: internal_notes || undefined,
                }))
              }
              onPartnerChange={({ partner_uid }) =>
                setBookingClone((bookingClone) => ({
                  ...bookingClone,
                  partner_uid: partner_uid || undefined,
                }))
              }
              onPartnerRepresentativeChange={({ partner_representative_uid }) =>
                setBookingClone((bookingClone) => ({
                  ...bookingClone,
                  partner_representative_uid:
                    partner_representative_uid || undefined,
                }))
              }
              onGalleryEnabledChange={({ gallery_enabled }) =>
                setBookingClone((bookingClone) => ({
                  ...bookingClone,
                  gallery_enabled,
                }))
              }
              onCentralizeEditingChange={({ centralize_editing_enabled }) =>
                setBookingClone((bookingClone) => ({
                  ...bookingClone,
                  centralize_editing_enabled,
                }))
              }
              onBookingPilotModeChange={({ pilot_mode_enabled_at }) =>
                setBookingClone((bookingClone) => ({
                  ...bookingClone,
                  pilot_mode_enabled_at,
                }))
              }
              onEnableVideoUploadChange={({ video_upload_file_enabled }) =>
                setBookingClone((bookingClone) => ({
                  ...bookingClone,
                  video_upload_file_enabled,
                }))
              }
              location={location}
              rootBasePath={rootBasePath}
            />

            <CustomerDetails
              session={session}
              booking={booking}
              bookingClone={bookingClone}
              history={history}
              location={location}
              bookingChangedKeys={bookingChangedKeys}
              onReload={reloadBooking}
              onChange={({ customer_uid }) =>
                setBookingClone((bookingClone) => ({
                  ...bookingClone,
                  customer_uid: customer_uid,
                }))
              }
              onPartnerCustomerFirstnameChange={({
                partner_customer_firstname,
              }) =>
                setBookingClone((bookingClone) => ({
                  ...bookingClone,
                  partner_customer_firstname:
                    partner_customer_firstname || undefined,
                }))
              }
              onPartnerCustomerSurnameChange={({ partner_customer_surname }) =>
                setBookingClone((bookingClone) => ({
                  ...bookingClone,
                  partner_customer_surname:
                    partner_customer_surname || undefined,
                }))
              }
              onPartnerCustomerCompanyChange={({ partner_customer_company }) =>
                setBookingClone((bookingClone) => ({
                  ...bookingClone,
                  partner_customer_company:
                    partner_customer_company || undefined,
                }))
              }
              onPartnerCustomerEmailChange={({ partner_customer_email }) =>
                setBookingClone((bookingClone) => ({
                  ...bookingClone,
                  partner_customer_email: partner_customer_email || undefined,
                }))
              }
              onPartnerCustomerMobilephoneChange={({
                partner_customer_mobilephone,
                partner_customer_mobilephone_country,
              }) =>
                setBookingClone((bookingClone) => ({
                  ...bookingClone,
                  partner_customer_mobilephone:
                    partner_customer_mobilephone || undefined,
                  partner_customer_mobilephone_country:
                    partner_customer_mobilephone_country || undefined,
                }))
              }
            />
          </div>

          <div className="row mb-4">
            <LocationDetails
              key={bookingClone.address}
              bookingClone={bookingClone}
              booking={booking}
              bookingChangedKeys={bookingChangedKeys}
              onPlaceChange={({
                point,
                address,
                country,
                timezone,
                suburb,
                city,
                state,
              }) =>
                setBookingClone((bookingClone) => ({
                  ...bookingClone,

                  point,
                  address,
                  country,
                  timezone,
                  suburb,
                  city,
                  state,
                }))
              }
              onLocationNoteChange={({ location_note }) =>
                setBookingClone((bookingClone) => ({
                  ...bookingClone,
                  location_note,
                }))
              }
            />

            <PackageTab
              history={history}
              session={session}
              booking={booking}
              bookingClone={bookingClone}
              bookingChangedKeys={bookingChangedKeys}
              onServiceTypeChange={({ service_type }) =>
                setBookingClone((bookingClone) => ({
                  ...bookingClone,
                  service_type,
                  shoottype: undefined,
                  product_uid: undefined,
                  duration: undefined,
                }))
              }
              onPackageChange={({ shoottype, product_uid, duration }) =>
                setBookingClone((bookingClone) => ({
                  ...bookingClone,
                  shoottype,
                  product_uid,
                  duration,
                }))
              }
              onDetailsChange={({ details }) =>
                setBookingClone((bookingClone) => ({
                  ...bookingClone,
                  details: details || booking?.details,
                }))
              }
              onPropertySizeChange={({ value }) =>
                setBookingClone((bookingClone) => ({
                  ...bookingClone,
                  property_size: value,
                }))
              }
              location={location}
            />
          </div>

          <div className="row mb-4">
            <DateTimeDetails
              bookingClone={bookingClone}
              booking={booking}
              session={session}
              bookingChangedKeys={bookingChangedKeys}
              providersAvailability={providersAvailability}
              providersAvailabilityLoading={providersAvailabilityLoading}
              selectedDate={selectedDate}
              onDateSelect={(datetime) =>
                setSelectedDate(
                  moment(datetime ?? undefined)
                    .clone()
                    .tz(bookingClone?.timezone ?? moment.tz.guess())
                )
              }
              onStartAtChange={({ start_at, providerNotAvailable }) =>
                setBookingClone((bookingClone) => ({
                  ...bookingClone,
                  start_at,
                  ...(providerNotAvailable === true && {
                    provider_uid: undefined,
                  }),
                }))
              }
            />

            <PhotographerDetails
              bookingClone={bookingClone}
              providersAvailability={providersAvailability}
              session={session}
              location={location}
              reloadBooking={reloadBooking}
              onProviderChange={({ provider_uid }) =>
                setBookingClone((bookingClone) => ({
                  ...bookingClone,
                  provider_uid,
                }))
              }
            />
          </div>

          <div className="row">
            <BookingExtraDetails
              booking={booking}
              ecommerceData={ecommerceData}
              workflowRunExtraDetails={workflowRunExtraDetails}
              editingExtraDetails={editingExtraDetails}
              pgGuideline={pgGuideline}
            />
            <BookingReferenceImages referenceImages={referenceImages} />
          </div>

          <div style={{ height: 70 }} />

          <div
            className="bg-white p-3"
            style={{
              position: 'fixed',
              bottom: 0,
              right: 0,
              left: 0,
              marginLeft: 200,
              boxShadow: '0 0 1.625em rgba(0,0,0,.05)',
            }}
          >
            <div className="d-flex align-items-baseline">
              {!isNew && (
                <React.Fragment>
                  <a
                    className="btn btn-link"
                    href={`/audit/booking-${booking?.uid}`}
                  >
                    Audit Events
                  </a>

                  <Link
                    className="btn btn-link"
                    to={`/bookings/${booking?.uid}/chat`}
                  >
                    Chat
                  </Link>

                  {bookingHasGallery({ booking }) && (
                    <Link
                      className="btn btn-link"
                      to={`/bookings/${booking?.uid}/gallery`}
                    >
                      Gallery
                    </Link>
                  )}

                  {booking?.status != null &&
                    [BOOKING_STATUS.PAID, BOOKING_STATUS.COMPLETED].includes(
                      booking?.status
                    ) &&
                    isPartnerBooking({ booking }) === false && (
                      <a
                        href={`${API_URL}/api/v2/bookings/${
                          booking.uid
                        }/payment-summary?${qs.stringify({
                          access_token: (session || {}).token,
                        })}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-link"
                      >
                        Download payment summary
                      </a>
                    )}

                  <NextBookingModal booking={booking} location={location} />

                  {booking?.photos_submitted_at != null && (
                    <small className="text-secondary mr-2">
                      Submitted{' '}
                      {moment(booking.photos_submitted_at)
                        .tz(booking.timezone || moment.tz.guess())
                        .format('MMM Do YYYY, h:mm:ss a zz')}
                    </small>
                  )}
                </React.Fragment>
              )}

              <ActionButtonsContainer className="flex-1 d-flex justify-content-end align-items-center">
                <PartnerChargeButton
                  booking={booking}
                  bookingClone={bookingClone}
                  bookingIsPending={bookingPromiser.isPending}
                  chargePartnerBooking={chargePartnerBooking}
                  chargePartnerBookingPromiser={chargePartnerBookingPromiser}
                  bookingHasChanges={bookingHasChanges}
                  disabled={isAutoAllocationRunning({ booking: bookingClone })}
                />

                <PaymentLinkButton
                  session={session}
                  booking={booking}
                  bookingClone={bookingClone}
                  bookingChangedKeys={bookingChangedKeys}
                  bookingHasChanges={bookingHasChanges}
                  location={location}
                  onReload={reloadBooking}
                  disabled={isAutoAllocationRunning({ booking: bookingClone })}
                />

                {/* <SetBookingToPaidButton
                  session={session}
                  booking={booking}
                  bookingClone={bookingClone}
                  bookingChangedKeys={bookingChangedKeys}
                  bookingHasChanges={bookingHasChanges}
                  location={location}
                  onReload={reloadBooking}
                  disabled={isAutoAllocationRunning({ booking: bookingClone })}
                /> */}

                <ChargeCustomerButton
                  key={[booking.product_uid, booking.customer_uid].join(':')}
                  session={session}
                  booking={booking}
                  bookingClone={bookingClone}
                  bookingHasChanges={bookingHasChanges}
                  location={location}
                  onReload={reloadBooking}
                  disabled={isAutoAllocationRunning({ booking: bookingClone })}
                />

                <ChargeCustomerDepositButton
                  session={session}
                  booking={booking}
                  bookingClone={bookingClone}
                  bookingChangedKeys={bookingChangedKeys}
                  bookingHasChanges={bookingHasChanges}
                  location={location}
                  onReload={reloadBooking}
                  disabled={isAutoAllocationRunning({ booking: bookingClone })}
                />

                {booking.status === BOOKING_STATUS.PAID_PENDING_SCHEDULE && (
                  <SetFromPaidPendingScheduleToPaid
                    booking={booking}
                    onReload={reloadBooking}
                    disabled={isAutoAllocationRunning({
                      booking: bookingClone,
                    })}
                  />
                )}

                {bookingCanBeOnHold({ booking }) ? (
                  <OnHoldModal
                    session={session}
                    booking={booking}
                    bookingClone={bookingClone}
                    bookingChangedKeys={bookingChangedKeys}
                    bookingHasChanges={bookingHasChanges}
                    location={location}
                    onReload={reloadBooking}
                    disabled={isAutoAllocationRunning({
                      booking: bookingClone,
                    })}
                  />
                ) : null}

                {isPartnerBooking({ booking }) === false &&
                bookingHasBeingPaid({ booking }) ? (
                  <ProviderCancellationModal
                    booking={booking}
                    location={location}
                    onReload={reloadBooking}
                    disabled={isAutoAllocationRunning({
                      booking: bookingClone,
                    })}
                  />
                ) : null}

                {isNew ? (
                  <button
                    className="btn btn-success"
                    onClick={() => createBooking(bookingClone)}
                    disabled={isAutoAllocationRunning({
                      booking: bookingClone,
                    })}
                  >
                    {createBookingPromiser.isPending ? 'Creating...' : 'Create'}
                  </button>
                ) : bookingHasBeingPaid({ booking }) ? (
                  <ConfirmAfterPaidChanges
                    session={session}
                    booking={booking}
                    bookingClone={bookingClone}
                    bookingChangedKeys={bookingChangedKeys}
                    bookingHasChanges={bookingHasChanges}
                    location={location}
                    onReload={reloadBooking}
                    disabled={isAutoAllocationRunning({
                      booking: bookingClone,
                    })}
                  />
                ) : (
                  bookingHasChanges && (
                    <button
                      className="btn btn-success"
                      disabled={
                        !bookingHasChanges ||
                        isAutoAllocationRunning({ booking: bookingClone })
                      }
                      onClick={() =>
                        patchBooking(
                          calculateChangesToPatch({
                            bookingClone,
                            bookingChangedKeys,
                          })
                        )
                      }
                    >
                      {patchBookingPromiser.isPending ? 'Saving...' : 'Save'}
                    </button>
                  )
                )}

                {/* {!isPartnerBooking({ booking: bookingClone }) && (
                      <button className="btn btn-secondary">Pay Link</button>
                    )} */}
              </ActionButtonsContainer>
            </div>
          </div>

          {!isNew && bookingHasChanges && (
            <OnBeforeUnload
              history={history}
              historyBlocker={({ message }) => (location) =>
                // allow transitions to the same booking
                // e.g. open a modal using query params
                location.pathname === match.url ? undefined : message}
            />
          )}
        </Fragment>
      )}
    </AvailabilityFetcher>
  );
};
