import React from 'react';
import Rect from '@reach/rect';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Component2 from '@reach/component-component';
import { Promiser } from 'react-prometo';

import { LoadingSpinner } from '../../components/LoadingSpinner';
import {
  ChatManager,
  ChatMessage,
  ChatComposer,
  ScrollToBottom,
  ScrollAtBottom,
} from '../../components/Chat';
import { Fetcher } from '../../components/NewFetcher';
import { apiFetch } from '../../utils';

const NoHoverLink = styled(Link)`
  :hover {
    text-decoration: none;
  }
`;

function groupMessagesReducer(acc, message) {
  if (acc.length > 0 && acc[acc.length - 1].userId === message.user_uid) {
    acc[acc.length - 1].messages.push(message);
    return acc;
  }
  return [...acc, { userId: message.user_uid, messages: [message] }];
}

const ChatMessages = ({ messages, session }) => (
  <Component2 initialState={{ cache: {} }}>
    {({ state: { cache }, setState }) =>
      messages.reduce(groupMessagesReducer, []).map((messagesGroup) => (
        <Promiser
          key={messagesGroup.messages.map((message) => message.uid).join(':')}
          promise={cache[messagesGroup.userId]}
        >
          {({ result: user }) => (
            <React.Fragment>
              <div className="mt-3" />

              {messagesGroup.messages.map((message) => (
                <ChatMessage
                  key={message.uid}
                  message={message}
                  currentUserId={session.uid}
                />
              ))}

              {!(
                messagesGroup.messages[messagesGroup.messages.length - 1]
                  .isPending || session.uid === messagesGroup.userId
              ) && (
                <React.Fragment>
                  <div className="d-flex flex-shrink-0 justify-content-start">
                    <span className="mt-0 text-muted">
                      <small>{(user && user.full_name) || '...'}</small>
                    </span>
                  </div>

                  <Component2
                    didMount={() =>
                      setState((state) => ({
                        cache: {
                          ...state.cache,
                          [messagesGroup.userId]:
                            state.cache[messagesGroup.userId] ||
                            apiFetch(
                              `/api/v2/admin/users/${messagesGroup.userId}`,
                              {
                                token: session.token,
                              }
                            ),
                        },
                      }))
                    }
                  />
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </Promiser>
      ))
    }
  </Component2>
);

export class ChatRoute extends React.Component<any, any> {
  messagesContainerRef = React.createRef<HTMLDivElement>();

  render() {
    const {
      session,
      match: {
        params: { bookingId },
      },
    } = this.props;

    return (
      <Fetcher
        urlToFetch={`/api/v2/admin/bookings/${bookingId}`}
        session={session}
      >
        {({ result: booking }) =>
          booking == null ? null : (
            <React.Fragment>
              <div className="d-flex align-items-center justify-content-between">
                <h3>
                  <NoHoverLink to={`/bookings/${bookingId}`}>
                    <i
                      className="fa fa-angle-left text-muted"
                      aria-hidden="true"
                    />{' '}
                  </NoHoverLink>{' '}
                  Chat
                </h3>
              </div>

              <div className="pt-2" />

              <div
                className="card d-flex flex-column flex-grow-1"
                style={{ overflow: 'hidden' }}
              >
                <div className="card-body d-flex flex-column flex-grow-1 p-0">
                  <Fetcher
                    urlToFetch={`/api/v2/bookings/${booking.uid}/chat`}
                    session={session}
                  >
                    {({
                      isPending: bookingChatPending,
                      result: {
                        chat_channel_uid: chatChannelId,
                        chat_channel_firebase_ref: chatChannelFirebaseRef,
                        chat_channel_is_archived: chatChannelIsArchived,

                        firebaseConfig,
                      } = {} as any,
                      error: bookingChatError,
                    }) =>
                      bookingChatError != null ? (
                        bookingChatError.status === 404 ? (
                          <div className="d-flex flex-grow-1 justify-content-center align-items-center">
                            <p className="mb-0 text-secondary">
                              Chat is not available for this booking
                            </p>
                          </div>
                        ) : (
                          <div className="d-flex flex-grow-1 justify-content-center align-items-center">
                            <p className="mb-0 text-secondary">
                              Error opening your chat. Please try again.
                            </p>
                          </div>
                        )
                      ) : (
                        <ChatManager
                          chatChannelId={chatChannelId}
                          chatChannelFirebaseRef={chatChannelFirebaseRef}
                          firebaseConfig={firebaseConfig}
                          session={session}
                        >
                          {({
                            chatMessagesPending,
                            chatMessages,
                            message,
                            setMessage,
                            sendMessage,
                          }) => (
                            <Rect>
                              {({
                                rect: { height: composerHeight = 60 } = {},
                                ref: composerRef,
                              }) => (
                                <div className="d-flex flex-column flex-grow-1 flex-shrink-1 position-relative">
                                  <div
                                    ref={this.messagesContainerRef}
                                    className="d-flex flex-column flex-grow-1 flex-shrink-1 pb-3"
                                    style={{
                                      overflowY: 'scroll',
                                      WebkitOverflowScrolling: 'touch',

                                      position: 'absolute',
                                      top: 0,
                                      bottom: composerHeight,
                                      left: 0,
                                      right: 0,
                                      paddingLeft: 15,
                                      paddingRight: 15,
                                    }}
                                  >
                                    {bookingChatPending ||
                                    (chatMessagesPending &&
                                      chatMessages == null) ? (
                                      <div className="d-flex flex-1 justify-content-center align-items-center">
                                        <LoadingSpinner />
                                      </div>
                                    ) : (
                                      <React.Fragment>
                                        <ChatMessages
                                          messages={chatMessages}
                                          session={session}
                                        />

                                        <ScrollAtBottom
                                          scrollableElement={
                                            this.messagesContainerRef.current
                                          }
                                        >
                                          {(atBottom) => (
                                            <ScrollToBottom
                                              messages={chatMessages}
                                              onScroll={() => {
                                                const lastMessageIsFromCurrentUser =
                                                  chatMessages.length > 0 &&
                                                  chatMessages[
                                                    chatMessages.length - 1
                                                  ].isPending;

                                                if (
                                                  atBottom === false &&
                                                  !lastMessageIsFromCurrentUser
                                                ) {
                                                  return;
                                                }

                                                if (
                                                  this.messagesContainerRef
                                                    .current != null
                                                ) {
                                                  this.messagesContainerRef.current.scrollTop = this.messagesContainerRef.current.scrollHeight;
                                                }
                                              }}
                                            />
                                          )}
                                        </ScrollAtBottom>
                                      </React.Fragment>
                                    )}
                                  </div>

                                  <div
                                    className="mb-3"
                                    style={{
                                      height: composerHeight,
                                    }}
                                  />

                                  <ChatComposer
                                    wrapperPosition={'absolute'}
                                    wrapperRef={composerRef}
                                    message={message}
                                    setMessage={setMessage}
                                    sendMessage={sendMessage}
                                    disabled
                                  />
                                </div>
                              )}
                            </Rect>
                          )}
                        </ChatManager>
                      )
                    }
                  </Fetcher>
                </div>
              </div>
            </React.Fragment>
          )
        }
      </Fetcher>
    );
  }
}
