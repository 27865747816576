import React from 'react';
import qs from 'query-string';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { GOOGLE_MAPS_API_KEY } from '../../config';
import locationGpsIcon from './location-gps-icon.svg';

const findInAddressComponents = (address_components, type) =>
  (address_components.find(({ types }) => types.find((t) => t === type)) || {})
    .short_name;

export const timezoneByPoint = (
  { lat, lng },
  { timestamp = null, apiKey = GOOGLE_MAPS_API_KEY } = {}
) =>
  fetch(
    `https://maps.googleapis.com/maps/api/timezone/json?${[
      `location=${lat},${lng}`,
      `timestamp=${timestamp || Date.now() / 1000}`,
      `key=${apiKey}`,
    ].join('&')}`
  ).then((res) => {
    if (res.status >= 200 && res.status < 300) {
      return res.json();
    }

    console.error(res);

    throw res;
  });

const Icon = ({ style = {}, src, ...rest }) => (
  <div
    style={{
      display: 'block',
      width: 37,
      height: 37,
      background: 'no-repeat center',
      backgroundSize: 'contain',
      backgroundImage: `url(${src})`,
      ...style,
    }}
    {...rest}
  />
);

export class TimezoneFromCoordsFetcher extends React.Component {
  state = {
    response: null,
    loading: true,
    error: null,
    reloadAt: Date.now(),
  };

  componentDidMount() {
    if (!this.props.disabled) {
      this.fetch(this.props);
    }
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.disabled) return;
    this.fetch(nextProps);
  }

  componentWillUnmount() {
    this._key = null;
  }

  fetch({ coords: [lat, lng] }) {
    const key = [lat, lng].join(':');

    if (this._key === key) return;
    this._key = key;

    this.setState({ loading: true });

    return timezoneByPoint({ lat, lng }).then((response) => {
      if (this._key !== key) return;
      this.setState({ response, loading: false });
    });
  }

  render() {
    return this.props.render({
      response: this.state.response || undefined,
      loading: this.state.loading,
      error: this.state.error,
    });
  }
}

export class MapsLibLoader extends React.Component {
  state = {
    maps: null,
    loading: true,
  };

  componentDidMount() {
    const {
      mapsConfig: {
        libraries = ['places'],
        language = 'en',
        apiKey = GOOGLE_MAPS_API_KEY,
      } = {},
    } = this.props;

    const _loadingKey = Date.now();
    this._loadingKey = _loadingKey;

    if (!MapsLibLoader.loadingMapPromise) {
      MapsLibLoader.loadingMapPromise = new Promise((resolve) => {
        const head = document.getElementsByTagName('head')[0];
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = `https://maps.googleapis.com/maps/api/js?${qs.stringify({
          key: apiKey,
          libraries: libraries.join(','),
          language,
        })}`;

        // There are several events for cross browser compatibility.
        script.onreadystatechange = () => resolve(window.google.maps);
        script.onload = () => resolve(window.google.maps);

        head.appendChild(script);
      });
    }

    return Promise.resolve()
      .then(() => MapsLibLoader.loadingMapPromise)
      .then((maps) => {
        if (this._loadingKey !== _loadingKey) return;
        this.setState({ maps, loading: false });
      });
  }

  componentWillUnmount() {
    this._loadingKey = null;
  }

  render() {
    const render = this.props.children || this.props.render;
    return render({
      maps: this.state.maps,
      loading: this.state.loading,
    });
  }
}

MapsLibLoader.loadingMapPromise = null;

const StyledDiv = styled.div`
  position: relative;

  .sp-autocomplete-icon {
    position: absolute;
    top: 10px;
    left: 10px;
  }

  input {
    padding-left: 34px;
  }
`;

export class AddressAutocompleteInput extends React.Component {
  state = {
    maps: null,
    addressValue: this.props.defaultValue,
  };

  static propTypes = { mapsLib: PropTypes.object.isRequired };

  isControlled() {
    return this.props.value != null;
  }

  componentDidMount() {
    const {
      mapsLib: maps,
      mapsConfig: { types = ['geocode'] } = {},
    } = this.props;

    const autocomplete = new maps.places.Autocomplete(this.inputRef, {
      types,
    });
    const listener = autocomplete.addListener(
      'place_changed',
      this.onPlaceChanged
    );
    this.deactivate = () => maps.event.removeListener(listener);
    this.autocomplete = autocomplete;
  }

  componentWillUnmount() {
    this.deactivate && this.deactivate();
  }

  onPlaceChanged = () => {
    const place = this.autocomplete.getPlace();
    this._selectedPlace = place;

    if (!this.isControlled()) {
      this.setState({ addressValue: this.inputRef.value });
    }

    if ('onChange' in this.props) {
      this.props.onChange(this.inputRef.value);
    }

    if (!place.geometry) {
      alert('Please select a place from the available options.');
    } else {
      const coords = place.geometry.location.toJSON();
      const { address_components } = place;
      const { lat, lng } = coords;

      return timezoneByPoint(coords).then(({ timeZoneId: timezone }) => {
        if (place === this._selectedPlace) {
          this.props.onPlaceSelected({
            coords: [lat, lng],
            address: this.inputRef.value,
            country: findInAddressComponents(address_components, 'country'),
            suburb: findInAddressComponents(address_components, 'locality'),
            city: findInAddressComponents(
              address_components,
              'administrative_area_level_2'
            ),
            state: findInAddressComponents(
              address_components,
              'administrative_area_level_1'
            ),
            timezone,
          });
        }
      });
    }
  };

  render() {
    const {
      mapsConfig,
      onPlaceSelected,
      defaultValue,
      onChange,
      mapsLib,
      ...inputProps
    } = this.props;

    return (
      <StyledDiv>
        <Icon
          src={locationGpsIcon}
          className="sp-autocomplete-icon"
          style={{ width: 18, height: 18 }}
        />
        <input
          disabled={this.props.disabled}
          ref={(input) => (this.inputRef = input)}
          value={
            this.isControlled()
              ? this.props.value
              : this.state.addressValue || ''
          }
          onChange={(ev) => {
            if (!this.isControlled()) {
              this.setState({ addressValue: ev.target.value });
            }
            if ('onChange' in this.props) {
              this.props.onChange(this.inputRef.value);
            }
          }}
          {...inputProps}
        />
      </StyledDiv>
    );
  }
}

export class MyMap extends React.Component {
  state = { map: null };

  static propTypes = {
    mapsLib: PropTypes.object.isRequired,
    defaultCenter: PropTypes.arrayOf(PropTypes.number).isRequired,
  };

  componentDidMount() {
    const { mapsLib: maps, defaultCenter, mapsConfig = {} } = this.props;
    const map = new maps.Map(this.mapNode, {
      disableDefaultUI: true,
      zoom: 14,
      center: { lat: defaultCenter[0], lng: defaultCenter[1] },
      ...mapsConfig,
    });

    this.setState({ map });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.center && nextProps.center !== this.props.center) {
      this.state.map.panTo({
        lat: nextProps.center[0],
        lng: nextProps.center[1],
      });
      this.state.map.setZoom(14);
    }
  }

  render() {
    const {
      mapsLib,
      mapsConfig,
      children,
      center,
      defaultCenter,
      ...rest
    } = this.props;

    return (
      <div ref={(node) => (this.mapNode = node)} {...rest}>
        {this.state.map &&
          React.Children.map(
            children,
            (child) =>
              child &&
              React.cloneElement(child, {
                ...child.props,
                mapsLib: this.props.mapsLib,
                map: this.state.map,
              })
          )}
      </div>
    );
  }
}

export class Marker extends React.Component {
  componentDidMount() {
    const { mapsLib: maps, map, coords, ...rest } = this.props;
    this.marker = new maps.Marker({
      position: { lat: coords[0], lng: coords[1] },
      map,
      ...rest,
    });
    this.marker.setAnimation(maps.Animation.DROP);
  }

  componentWillReceiveProps(nextProps) {
    const { mapsLib: maps, map, coords, ...rest } = nextProps;

    if (nextProps.coords !== this.props.coords) {
      // kill old marker and create a new one every time
      // to be able to trigger the drop animation
      this.marker.setMap(null);
      this.marker = new maps.Marker({
        position: { lat: coords[0], lng: coords[1] },
        map,
        ...rest,
      });
      this.marker.setAnimation(maps.Animation.DROP);
    }
  }

  componentWillUnmount() {
    this.marker.setMap(null);
  }

  render() {
    return null;
  }
}

export class Circle extends React.Component {
  componentDidMount() {
    const { mapsLib: maps, map, coords, ...rest } = this.props;
    this.circleInitialConfig = {
      strokeColor: '#2b87f9',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#2b87f9',
      fillOpacity: 0.15,
      map,
      ...rest,
    };

    this.circle = new maps.Circle(this.circleInitialConfig);
  }

  componentDidUpdate(prevProps) {
    const { center, radius, mapsLib: maps } = this.props;
    if (center !== prevProps.center || radius !== prevProps.radius) {
      this.circle.setMap(null);
      this.circle = new maps.Circle({
        ...this.circleInitialConfig,
        center,
        radius,
      });
    }
  }

  componentWillUnmount() {
    this.circle.setMap(null);
  }

  render() {
    return null;
  }
}

export class Polygon extends React.Component {
  componentDidMount() {
    const { mapsLib: maps, map, paths } = this.props;
    this.polygonInitialConfig = {
      strokeColor: '#2b87f9',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#2b87f9',
      fillOpacity: 0.15,
      map,
      paths,
    };

    this.polygon = new maps.Polygon(this.polygonInitialConfig);
  }

  componentDidUpdate(prevProps) {
    const { mapsLib: maps, paths } = this.props;
    if (paths !== prevProps.paths) {
      this.polygon.setMap(null);
      this.polygon = new maps.Polygon({
        paths,
        ...this.polygonInitialConfig,
      });
    }
  }

  componentWillUnmount() {
    this.polygon.setMap(null);
  }

  render() {
    return null;
  }
}
