import { AutoAllocationStatusEnum } from './types';

export function calculateKeysThatChanged<T extends {}>(
  fromObject: T,
  toObject: T
) {
  const keysToCheck = Object.keys(
    [...Object.keys(fromObject), ...Object.keys(toObject)].reduce(
      (res, key) => ({ ...res, [key]: true }),
      {}
    )
  );

  return keysToCheck.reduce(
    (acu, key) =>
      fromObject[key] === toObject[key] ? acu : { ...acu, [key]: true },
    {} as { [k: string]: true }
  );
}

export const BOOKING_CHANGED_KEYS_TO_OMIT = ['duration'];

export const calculateChangesToPatch = ({
  bookingClone,
  bookingChangedKeys,
}) => ({
  ...Object.keys(bookingChangedKeys)
    .filter((key) => !BOOKING_CHANGED_KEYS_TO_OMIT.includes(key))
    .reduce(
      (res, key) => ({
        ...res,
        [key]: bookingClone[key] == null ? null : bookingClone[key],
      }),
      {}
    ),

  // if booking is changing place we
  // must send all these attributes
  ...(() =>
    bookingChangedKeys.address
      ? {
          coords: bookingClone.coords,
          address: bookingClone.address,
          country: bookingClone.country,
          timezone: bookingClone.timezone,
          suburb: bookingClone.suburb,
          city: bookingClone.city,
          state: bookingClone.state,
        }
      : {})(),
});

export const isAutoAllocationRunning = ({ booking }) =>
  booking.auto_allocation_status &&
  [
    AutoAllocationStatusEnum.FINDING_PROVIDER,
    AutoAllocationStatusEnum.FINDING_PROVIDER_PROCESSING,
  ].includes(booking.auto_allocation_status);
