import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  MenuPopover,
} from '@reach/menu-button';

import { COLOR_PALLETTE } from '../../../../../constants';
import { SvgImg } from '../../../../../components/SvgImg';

import usFlag from '../../components/img/flags/us.svg';
import auFlag from '../../components/img/flags/au.svg';
import beFlag from '../../components/img/flags/be.svg';
import caFlag from '../../components/img/flags/ca.svg';
import chFlag from '../../components/img/flags/ch.svg';
import deFlag from '../../components/img/flags/de.svg';
import frFlag from '../../components/img/flags/fr.svg';
import gbFlag from '../../components/img/flags/gb.svg';
import ilFlag from '../../components/img/flags/il.svg';
import itFlag from '../../components/img/flags/it.svg';
import nlFlag from '../../components/img/flags/nl.svg';
import nzFlag from '../../components/img/flags/nz.svg';
import sgFlag from '../../components/img/flags/sg.svg';
import esFlag from '../../components/img/flags/es.svg';
import phFlag from '../../components/img/flags/ph.svg';

export const COUNTRIES = [
  { code: 'US', name: 'US', flag: usFlag },
  { code: 'AU', name: 'AU', flag: auFlag },
  { code: 'BE', name: 'BE', flag: beFlag },
  { code: 'CA', name: 'CA', flag: caFlag },
  { code: 'CH', name: 'CH', flag: chFlag },
  { code: 'DE', name: 'DE', flag: deFlag },
  { code: 'FR', name: 'FR', flag: frFlag },
  { code: 'GB', name: 'GB', flag: gbFlag },
  { code: 'IL', name: 'IL', flag: ilFlag },
  { code: 'IT', name: 'IT', flag: itFlag },
  { code: 'NL', name: 'NL', flag: nlFlag },
  { code: 'NZ', name: 'NZ', flag: nzFlag },
  { code: 'SG', name: 'SG', flag: sgFlag },
  { code: 'ES', name: 'ES', flag: esFlag },
  { code: 'PH', name: 'PH', flag: phFlag },
];

export const PhoneInput = ({
  disabled,
  readOnly,
  originalPhoneData,
  setCountry,
  setMobilephone,
  onPhoneChange,
  className,
}: {
  disabled?: boolean;
  readOnly?: boolean;
  originalPhoneData?: Record<string, string | null> | null;
  setCountry: (country: string) => void;
  setMobilephone: (mobilephone: string) => void;
  onPhoneChange?: ({
    country,
    mobilephone,
  }: {
    country: string;
    mobilephone: string;
  }) => void;
  className?: string;
}) => {
  const countryMetadata =
    COUNTRIES.find((c) => c.code === originalPhoneData?.country) ?? null;

  return (
    <>
      {disabled ? (
        <div className={'form-group col'}>
          <label>
            <small className="text-secondary">Mobile phone</small>
          </label>

          <input
            type="text"
            className="form-control"
            readOnly
            placeholder={'Mobile phone'}
          />
        </div>
      ) : (
        <div className={`form-group ${className}`}>
          <label>
            <small className="text-secondary">Mobile phone</small>
          </label>

          <div
            style={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              fontSize: 16,
              backgroundColor: '#FFF',
              color: COLOR_PALLETTE.GRAY_2,
              border: `1px solid ${COLOR_PALLETTE.GRAY_2}`,
              borderRadius: '2px',
            }}
          >
            <Menu>
              <MenuButton
                type="button"
                disabled={readOnly}
                className="data-reach-menu-button"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: 70,
                  padding: '0.4rem',
                  color: COLOR_PALLETTE.MUTED,
                  borderTop: 'none',
                  borderBottom: 'none',
                  borderLeft: 'none',
                  borderRight: `1px solid ${COLOR_PALLETTE.GRAY_2}`,
                }}
              >
                {countryMetadata ? (
                  <SvgImg
                    src={countryMetadata.flag}
                    alt={countryMetadata.name}
                    style={{
                      width: 20,
                      height: 'auto',
                      marginRight: '0.5rem',
                    }}
                  />
                ) : (
                  <span style={{ marginRight: '0.5rem' }}>
                    {(originalPhoneData ?? {}).country ?? ''}
                  </span>
                )}
                {readOnly !== true && <span aria-hidden>▾</span>}
              </MenuButton>

              <MenuPopover
                className="data-reach-menu-list"
                style={{
                  zIndex: 100000000,
                  width: 70,
                  background: '#FFF',
                  outline: 'none',
                  border: `solid 1px ${COLOR_PALLETTE.GRAY_3}`,
                  borderRadius: '2px',
                }}
              >
                <MenuItems>
                  {COUNTRIES.map((country) => (
                    <MenuItem
                      key={country.code}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                      }}
                      onSelect={() => {
                        setCountry(country.code);

                        if (onPhoneChange != null) {
                          onPhoneChange({
                            country: country.code,
                            mobilephone:
                              (originalPhoneData ?? {}).mobilephone ?? '',
                          });
                        }
                      }}
                    >
                      <SvgImg
                        src={country.flag}
                        alt={country.name}
                        style={{ width: 20, height: 40 }}
                      />
                    </MenuItem>
                  ))}
                </MenuItems>
              </MenuPopover>
            </Menu>

            <input
              value={(originalPhoneData ?? {}).mobilephone ?? ''}
              disabled={readOnly}
              placeholder={'Mobile phone'}
              style={{
                flex: 1,
                padding: '0.4rem',
                backgroundColor: 'transparent',
                border: 'none',
              }}
              onChange={({ target: { value } }) => {
                setMobilephone(value);

                if (onPhoneChange != null) {
                  onPhoneChange({
                    country: (originalPhoneData ?? {}).country ?? '',
                    mobilephone: value,
                  });
                }
              }}
            />
          </div>

          <style jsx global>{`
            [data-reach-menu-button] {
              background-color: #fff;
            }

            [data-reach-menu-button]:hover {
              background-color: ${COLOR_PALLETTE.GRAY_1};
            }

            input:focus {
              border: solid 1px ${COLOR_PALLETTE.GRAY_5};
            }
          `}</style>
        </div>
      )}
    </>
  );
};
