import React from 'react';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import qs from 'query-string';
import styled from 'styled-components';

import { MasonryGallery } from './components/MasonryGallery';
import {
  Slider,
  SliderContainer,
  BottomControls,
  KeyboardControlEvents,
} from './components/Slider';
import leftArrow from '../../components/img/white-left-arrow.png';
import rightArrow from '../../components/img/white-right-arrow.png';
import { API_URL } from '../../config';
import { Fetcher } from '../../components/Fetcher';
import { Modal } from '../../components/Modal';
import { ImageLoadIndicator } from './components/ImageLoadIndicator';
import { LoadingModal } from '../../components/LoadingModal';
import { prettifyShoottype } from './utils';

const ImageAnimatedLoadStyle = styled.img`
  transition: opacity 1s;
  opacity: ${(props) => (props.loaded ? 1 : 0)};
`;

const ImageAnimatedLoad = ({ mediaId, size = 'gallerythumb', ...props }) => {
  const src = `${API_URL}/rpc/v1/image/${mediaId}?size=${size}`;
  return (
    <ImageLoadIndicator
      src={src}
      render={({ loaded, error }) => (
        <ImageAnimatedLoadStyle loaded={loaded} src={src} {...props} />
      )}
    />
  );
};

export class GalleryRoute extends React.Component {
  render() {
    const {
      match: {
        params: { bookingId },
      },
      location,
      history,
      session,
    } = this.props;

    return (
      <div
        className="d-flex flex-column flex-1 bg-white"
        style={{ position: 'relative' }}
      >
        <Fetcher session={session} urlToFetch={`/api/v2/bookings/${bookingId}`}>
          {({
            response: booking,
            loading: bookingLoading,
            error: bookingGalleryError,
          }) =>
            bookingGalleryError ? (
              <div className="text-center p-5 flex-1">
                {"Looks like the gallery doesn't exist."}
              </div>
            ) : bookingLoading ? (
              <LoadingModal />
            ) : (
              <div className="p-3">
                <Fetcher
                  session={session}
                  urlToFetch={`/api/v2/bookings/${bookingId}/photos`}
                >
                  {({ response, loading, error }) => (
                    <div>
                      {!loading && response && response.results.length > 0 && (
                        <Gallery
                          images={response.results}
                          booking={booking}
                          location={location}
                          history={history}
                          bookingId={bookingId}
                        />
                      )}
                    </div>
                  )}
                </Fetcher>
              </div>
            )
          }
        </Fetcher>
      </div>
    );
  }
}

export const StyledLink = styled(Link)`
  :hover {
    text-decoration: none;
  }
`;

const Gallery = ({
  images,
  booking,
  location,
  location: { search },
  history,
}) => {
  const { photo, shareModal, ...cleanedSearch } = qs.parse(search);
  return (
    <React.Fragment>
      <div className="d-flex justify-content-between align-items-center mt-4">
        <h1>
          <StyledLink to={`/bookings/${booking.uid}`}>
            <i className="fa fa-angle-left text-muted" aria-hidden="true" />{' '}
          </StyledLink>
          {booking.name
            ? booking.name
            : !booking.customer_firstname || booking.customer_firstname === ' '
            ? `${prettifyShoottype(booking.shoot_type)} shoot`
            : `${booking.customer_firstname}'s ${booking.shoot_type} shoot`}
        </h1>

        <div>
          {booking.logo_uid ? (
            <img
              src={`${API_URL}/rpc/v1/image/${booking.logo_uid}?size=gallerythumb`}
              alt={booking.partner_name}
              style={{
                maxWidth: 150,
                maxHeight: 40,
              }}
            />
          ) : (
            booking.partner_name
          )}
        </div>
      </div>

      <div className="d-flex justify-content-between mb-4">
        <div className="text-muted">
          {moment(booking.start_at)
            .tz(booking.timezone || 'UTC')
            .format('dddd, MMMM Do YYYY')}
        </div>
      </div>

      <div className="row">
        <div className="col">
          <MasonryGallery images={images} columnCount={5}>
            {({ columns, loading }) => (
              <React.Fragment>
                <div className="d-flex justify-content-space-around">
                  {columns.map((column, key) => (
                    <div key={key} className={`flex-1 mx-1`}>
                      {column.images.map((img) => (
                        <Link
                          key={img.media}
                          to={{
                            ...location,
                            search: qs.stringify({
                              photo: img.media,
                            }),
                          }}
                        >
                          <ImageAnimatedLoad
                            alt=""
                            className="mb-2 rounded"
                            style={{ width: '100%' }}
                            mediaId={img.media}
                          />
                        </Link>
                      ))}
                    </div>
                  ))}
                </div>

                {loading && (
                  <div className="flex-1 text-center p-4">Loading...</div>
                )}
              </React.Fragment>
            )}
          </MasonryGallery>
        </div>
      </div>

      {photo && (
        <GalleryModal
          location={location}
          history={history}
          cleanedSearch={qs.stringify(cleanedSearch)}
          images={images}
          preselectedImage={images.findIndex(({ media }) => media === photo)}
        />
      )}
    </React.Fragment>
  );
};

const SliderImage = ({ imgId, index, onSlideRef }) => {
  return (
    <div
      className="slide px-3 text-center"
      ref={(slideRef) => {
        index === 0 && onSlideRef(slideRef);
      }}
    >
      <ImageAnimatedLoad
        mediaId={imgId}
        size="gallery"
        alt=""
        style={{
          maxWidth: '100%',
          height: 'auto',
          maxHeight: '80vh',
          zIndex: '10000',
        }}
      />
      ;
    </div>
  );
};

const GalleryModal = ({
  images,
  preselectedImage,
  location,
  cleanedSearch,
  history,
}) => (
  <Modal>
    <div className="container">
      <Slider images={images} index={preselectedImage}>
        {({ index, translateValue, animate, next, prev, onSlideRef }) => (
          <KeyboardControlEvents
            right={next}
            left={prev}
            esc={() => history.push({ ...location, search: cleanedSearch })}
          >
            {() => (
              <React.Fragment>
                <SliderContainer
                  animate={animate}
                  translateValue={translateValue}
                  className="slider"
                >
                  <div className="slider-wrapper">
                    {images &&
                      images.map(({ media }, index) => (
                        <SliderImage
                          imgId={media}
                          key={media}
                          index={index}
                          onSlideRef={onSlideRef}
                        />
                      ))}
                  </div>
                </SliderContainer>

                <Link
                  to={{ ...location, search: cleanedSearch }}
                  style={{
                    background: 'transparent',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    border: 'none',
                    width: '100%',
                  }}
                />

                <BottomControls className="d-flex justify-content-between align-items-center p-3">
                  <button onClick={prev} className="btn btn-link">
                    <img
                      src={leftArrow}
                      style={{ width: '20px', height: '20px' }}
                      alt="left"
                    />
                  </button>

                  <button onClick={next} className="btn btn-link">
                    <img
                      src={rightArrow}
                      style={{ width: '20px', height: '20px' }}
                      alt="right"
                    />
                  </button>
                </BottomControls>
              </React.Fragment>
            )}
          </KeyboardControlEvents>
        )}
      </Slider>
    </div>
  </Modal>
);
