import React from 'react';

export function PartnerLevelBadge({ status }: { status?: string }) {
  if (status == null) return null;

  return (
    <span
      className="text-uppercase badge"
      style={{
        color: '#212529',
        backgroundColor: {
          bronze: 'sandybrown',
          silver: 'lightgray',
          gold: 'gold',
        }[status],
      }}
    >
      {status}
    </span>
  );
}
