import * as React from 'react';
import { DeleteItemButton } from './DeleteItemButton';

export function PillBadge({
  children,
  item,
  backgroundColor = '#F6F9FF',
  fontSize = '10px',
  onDelete,
}: {
  children: React.ReactNode;
  item: {};
  backgroundColor?: string;
  fontSize?: string;
  onDelete?: (item: {}) => void;
}) {
  return (
    <span
      style={{
        backgroundColor: backgroundColor,
        padding: '1px 8px',
        textTransform: 'lowercase',
        borderRadius: 15,
        color: '#141B24',
        height: 'min-content',
        fontSize,
      }}
    >
      {children}
      {onDelete && (
        <DeleteItemButton
          onClick={() => {
            onDelete(item);
          }}
        />
      )}
    </span>
  );
}
