import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import BaseLayout from '../components/BaseLayout';
import * as React from 'react';
import { API_URL } from '../config';

function BullQueuesAdminRoute({ history, location }: RouteComponentProps) {
  const src = `${API_URL}/admin/queues`;
  return (
    <div style={{ display: 'flex', flex: 1 }}>
      <iframe style={{ width: '100%' }} src={src} title="bull queue" />
    </div>
  );
}

export function BullQueuesRoute({
  session,
  match,
  match: { path: basePath },
  history,
}: {
  session: { token: string };
} & RouteComponentProps) {
  return (
    <BaseLayout session={session} match={match}>
      <Switch>
        <Route
          exact
          path={basePath}
          render={(props) => <BullQueuesAdminRoute {...props} />}
        />
      </Switch>
    </BaseLayout>
  );
}
