import { BOOKING_STATUS } from '../../../constants';

export { BOOKING_STATUS } from '../../../constants';

export const isPartnerBooking = ({ booking }) => booking.partner_uid != null;

const NON_CHARGEABLE_STATUSES = [
  BOOKING_STATUS.PAID,
  BOOKING_STATUS.COMPLETED,
  BOOKING_STATUS.REFUNDED,
  BOOKING_STATUS.CANCELLED,
  BOOKING_STATUS.PAID_ON_HOLD,
];
const BOOKING_AFTER_PAYMENT_STATUS_LIST = [
  'paid',
  'completed',
  'refunded',
  'cancelled',
  'paid_pending_schedule',
  'paid_on_hold',
];

export const isBookingReadyToUnHold = ({ booking }) =>
  booking.status === BOOKING_STATUS.PAID_ON_HOLD;

export const isBookingReadyForCharge = ({ booking }) =>
  isPartnerBooking({ booking })
    ? booking.product_uid != null &&
      booking.point !== null &&
      booking.starttime !== null &&
      booking.provider_uid !== null &&
      NON_CHARGEABLE_STATUSES.indexOf(booking.status) === -1
    : booking.product_uid != null &&
      booking.customer_uid != null &&
      booking.point !== null &&
      booking.starttime !== null &&
      booking.provider_uid !== null &&
      NON_CHARGEABLE_STATUSES.indexOf(booking.status) === -1;

export const isBookingReadyForDepositCharge = ({ booking }) =>
  booking.product_uid != null &&
  booking.customer_uid != null &&
  booking.point !== null &&
  booking.starttime !== null &&
  // booking.provider_uid !== null &&
  [...NON_CHARGEABLE_STATUSES, BOOKING_STATUS.PAID_DEPOSIT].indexOf(
    booking.status
  ) === -1;

export const bookingHasBeingPaid = ({ booking }) =>
  BOOKING_AFTER_PAYMENT_STATUS_LIST.indexOf(booking.status) !== -1;

export const bookingHasGallery = ({ booking }) =>
  [BOOKING_STATUS.COMPLETED, BOOKING_STATUS.REFUNDED].includes(booking.status);

export const bookingCanBeOnHold = ({ booking }) =>
  BOOKING_STATUS.PAID === booking.status;

export const isBookingStatusPaid = ({ booking }) =>
  BOOKING_STATUS.PAID === booking.status;
