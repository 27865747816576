export const API_URL =
  process.env.NEXT_PUBLIC_API_URL ?? 'http://localhost:5000';
export const GOOGLE_MAPS_API_KEY = process.env.GOOGLE_MAPS_API_KEY;
export const APP_URL = process.env.APP_URL ?? 'https://dev-app.snappr.com';
export const ADMIN_URL =
  process.env.ADMIN_URL ?? 'https://dev-admin.snappr.com';

export const APARSNIP_URL =
  process.env.NEXT_PUBLIC_APARSNIP_URL ?? 'https://dev-www.aparsnip.com/';

export const CHECKR_URL =
  process.env.NEXT_PUBLIC_CHECKR_URL ?? 'https://dashboard.checkr.com';

export const FLAT_FILE_LICENSE_KEY = process.env.FLAT_FILE_LICENSE_KEY
  ? process.env.FLAT_FILE_LICENSE_KEY
  : '05a7cf25-e303-4cc2-9121-c90bb04ef42c';

export const ENABLE_CUSTOMER_EXTRA_CHARGES = JSON.parse(
  process.env.NEXT_PUBLIC_ENABLE_CUSTOMER_EXTRA_CHARGES || 'true'
);

export const NEW_BOOKING_CANCELLATION_MODAL_CUSTOMER = JSON.parse(
  process.env.NEXT_PUBLIC_NEW_BOOKING_CANCELLATION_MODAL_CUSTOMER || 'true'
);

export const NEW_BOOKING_CANCELLATION_MODAL_ENTERPRISE = JSON.parse(
  process.env.NEXT_PUBLIC_NEW_BOOKING_CANCELLATION_MODAL_ENTERPRISE || 'true'
);

export const ENABLE_AI_HEADSHOT_PACKAGES = JSON.parse(
  process.env.NEXT_PUBLIC_ENABLE_AI_HEADSHOT_PACKAGES || 'false'
);

export const ENABLE_AI_HEADSHOT_QC_SYSTEM = JSON.parse(
  process.env.NEXT_PUBLIC_ENABLE_AI_HEADSHOT_QC_SYSTEM || 'false'
);

export const ENTERPRISE_AUTO_BILLING_ENABLED =
  JSON.parse(
    process.env.NEXT_PUBLIC_ENTERPRISE_AUTO_BILLING_ENABLED ?? 'false'
  ) === true;

export const ENABLE_LISTING_SIMULATOR = JSON.parse(
  process.env.NEXT_PUBLIC_ENABLE_LISTING_SIMULATOR || 'true'
);

export const ENABLE_PARTNER_PILOT_MODE = JSON.parse(
  process.env.NEXT_PUBLIC_ENABLE_PARTNER_PILOT_MODE || 'true'
);

export const ENABLE_ENTERPRISE_BOOKING_TRANSACTION_REFUNDS = JSON.parse(
  process.env.NEXT_PUBLIC_ENABLE_ENTERPRISE_BOOKING_TRANSACTION_REFUNDS ||
    'false'
);

export const ENABLE_PHOTOGRAPHER_GEO_BATCHING_SHOOTS =
  JSON.parse(
    process.env.NEXT_PUBLIC_ENABLE_PHOTOGRAPHER_GEO_BATCHING_SHOOTS ?? 'false'
  ) === true;

export const ENABLE_GEO_BOOKING_MAP =
  JSON.parse(process.env.NEXT_PUBLIC_ENABLE_GEO_BOOKING_MAP ?? 'false') ===
  true;

export const GEO_BOOKING_MAP_ZOOM_LEVEL = process.env
  .NEXT_PUBLIC_GEO_BOOKING_MAP_ZOOM_LEVEL
  ? +process.env.NEXT_PUBLIC_GEO_BOOKING_MAP_ZOOM_LEVEL
  : 10;

export const ENABLE_HIDE_CLAIM_BUTTON =
  JSON.parse(process.env.NEXT_PUBLIC_ENABLE_HIDE_CLAIM_BUTTON ?? 'false') ===
  true;

export const ENABLE_HEADSHOTS_REFUND_BUTTON =
  JSON.parse(
    process.env.NEXT_PUBLIC_ENABLE_HEADSHOTS_REFUND_BUTTON ?? 'false'
  ) === true;

export const ENABLE_COMPLIANCE_REVISIONS_CHECKLIST =
  JSON.parse(
    process.env.NEXT_PUBLIC_ENABLE_COMPLIANCE_REVISIONS_CHECKLIST ?? 'false'
  ) === true;

export const ENABLE_COMPLIANCE_REVISIONS_ALL =
  JSON.parse(
    process.env.NEXT_PUBLIC_ENABLE_COMPLIANCE_REVISIONS_ALL ?? 'false'
  ) === true;

export const ENABLE_COMPLETION_TEAM_DASHBOARD =
  JSON.parse(
    process.env.NEXT_PUBLIC_ENABLE_COMPLETION_TEAM_DASHBOARD ?? 'false'
  ) === true;
