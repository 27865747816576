// @ts-check
import React, { Component } from 'react';
import styled from 'styled-components';
import { apiFetch } from '../../../../utils';
import cardSprite from './img/card-sprite.png';

const focusMap = {
  number: 'expirationDate',
  expirationDate: 'cvv',
};

const SUPPORTED_METHODS = {
  US: ['visa', 'master-card', 'american-express', 'discover', 'jcb'],
  AU: ['visa', 'master-card'],
};

export const CreditCardInputs = ({
  numberValid,
  expirationDateValid,
  cvvValid,
  cardType,
  country = 'US',
}) => {
  const cardTypeInvalid =
    cardType &&
    country in SUPPORTED_METHODS &&
    !SUPPORTED_METHODS[country].includes(cardType);
  return (
    <div className="flex-1 mb-2" style={{ position: 'relative' }}>
      <div className="form-group">
        <div
          id="card-number"
          className={`form-control ${
            (cardTypeInvalid && 'is-invalid') || numberValid
          }`}
          style={{ height: '3rem', position: 'relative' }}
        >
          <CreditCardBackgroundImage className={cardType} />
        </div>
        {cardTypeInvalid && (
          <div className="invalid-feedback">
            <span className="text-capitalize">{cardType}</span> is not currently
            supported in {country}.
          </div>
        )}
      </div>

      <div className="row">
        <div className="col">
          <div className="form-group">
            <div
              id="expiration-date"
              className={`form-control ${expirationDateValid}`}
              style={{ height: '3rem' }}
            />
          </div>
        </div>

        <div className="col">
          <div className="form-group">
            <div
              id="cvv"
              className={`form-control ${cvvValid}`}
              style={{ height: '3rem' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export class CreditCardLoader extends Component {
  state = {
    cardType: '',
    cvvValid: '',
    expirationDateValid: '',
    formValid: false,
    loading: true,
    numberValid: '',
  };

  componentDidMount() {
    const {
      bookingId,
      session: { token },
    } = this.props;

    const getClientToken = ({ bookingId, token }) => {
      return apiFetch(`/api/v2/bookings/${bookingId}/payment-client-token`, {
        method: 'POST',
        token,
      }).then(({ clientToken }) => clientToken);
    };

    Promise.all([import('braintree-web'), getClientToken({ bookingId, token })])
      .then(([braintree, clientToken]) => {
        if (this.stop) return;
        this.braintree = braintree;
        this.loadBraintreeInputs({ clientToken, braintree });
      })
      .catch((err) => {
        this.setState({
          depLoadingError: 'There was an error. Please reload the page.',
        });
      });
  }

  componentWillUnmount() {
    this.hostedFieldsInstance && this.hostedFieldsInstance.teardown();
    this.stop = true;
  }

  loadBraintreeInputs = ({ braintree, clientToken }) => {
    if (this.stop) return;
    braintree.client
      .create({ authorization: clientToken })
      .then((client) => {
        if (this.stop) return;
        return braintree.hostedFields.create({
          client: client,
          styles: {
            input: {
              'font-size': '16px',
              color: '#3A3A3A',
              'font-weight': 'bold',
              'letter-spacing': '1.5px',
              'font-family':
                'Europa, -apple-system, BlinkMacSystemFont' +
                ", 'Segoe UI', Roboto,'Helvetica Neue'" +
                ", Arial, sans-serif, 'Apple Color Emoji'," +
                " 'Segoe UI Emoji', 'Segoe UI Symbol'",
            },
            'input::-webkit-input-placeholder': {
              'letter-spacing': 'normal',
              'font-weight': 'normal',
            },
            'input::-moz-placeholder': {
              'letter-spacing': 'normal',
              'font-weight': 'normal',
            },
            'input:-ms-input-placeholder': {
              'letter-spacing': 'normal',
              'font-weight': 'normal',
            },
            'input:-moz-placeholder': {
              'letter-spacing': 'normal',
              'font-weight': 'normal',
            },
            '.valid': {
              color: '#28a745',
            },
            '.invalid': {
              color: '#dc3545',
            },
          },
          fields: {
            number: {
              selector: '#card-number',
              placeholder: 'Card number',
            },
            cvv: {
              selector: '#cvv',
              placeholder: 'CVC',
            },
            expirationDate: {
              selector: '#expiration-date',
              placeholder: 'Expiry (MM/YY)',
            },
          },
        });
      })
      .then((hostedFieldsInstance) => {
        if (this.stop) {
          hostedFieldsInstance && hostedFieldsInstance.teardown();
          return;
        }
        this.setState({ loading: false });
        this.hostedFieldsInstance = hostedFieldsInstance;
        hostedFieldsInstance.on('validityChange', this.validate);
        hostedFieldsInstance.on('cardTypeChange', this.setCardType);
      });
  };

  tokenize = () => {
    const { hostedFieldsInstance } = this;
    const {
      session: { token },
      onProcessing,
      booking: { customer_uid: userId },
    } = this.props;
    if (this.stop) {
      hostedFieldsInstance && hostedFieldsInstance.teardown();
      return;
    }

    onProcessing(true);
    return hostedFieldsInstance
      .tokenize()
      .then((payload) => {
        return apiFetch(`/api/v2/customers/${userId}/payment-methods`, {
          token,
          method: 'POST',
          body: JSON.stringify({
            paymentMethodNonce: payload.nonce,
          }),
        });
      })
      .then(({ token: paymentMethodToken }) => ({ paymentMethodToken }));
  };

  validate = (event) => {
    const { hostedFieldsInstance } = this;
    const { emittedBy } = event;
    const field = event.fields[emittedBy];
    const state = hostedFieldsInstance.getState();
    const formValid = Object.keys(state.fields).every((key) => {
      return state.fields[key].isValid;
    });
    this.setState({ formValid });

    if (field.isValid) {
      this.setState({ [`${emittedBy}Valid`]: 'is-valid' });
      if (emittedBy !== 'cvv') {
        hostedFieldsInstance.focus(focusMap[emittedBy]);
      }
    } else if (field.isPotentiallyValid) {
      this.setState({ [`${emittedBy}Valid`]: '' });
    } else {
      this.setState({ [`${emittedBy}Valid`]: 'is-invalid' });
    }
  };

  setCardType = (event) => {
    if (event.cards.length === 1) {
      const cardType = event.cards[0].type;
      this.setState({ cardType });
    } else {
      this.setState({ cardType: '' });
    }
  };

  render() {
    const render = this.props.children || this.props.render;
    const {
      cardType,
      cvvValid,
      expirationDateValid,
      formValid,
      loading,
      numberValid,
    } = this.state;

    return render({
      tokenize: this.tokenize,
      cardType,
      cvvValid,
      expirationDateValid,
      formValid,
      loading,
      numberValid,
    });
  }
}

const CreditCardBackgroundImage = styled.div`
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  width: 44px;
  height: 28px;
  background-image: url(${cardSprite});
  background-size: 86px 458px;
  border-radius: $radius;
  background-position: -100px 0;
  background-repeat: no-repeat;
  &.visa {
    background-position: 0 -398px;
  }
  &.master-card {
    background-position: 0 -281px;
  }
  &.american-express {
    background-position: 0 -370px;
  }
  &.discover {
    background-position: 0 -163px;
  }
  &.maestro {
    background-position: 0 -251px;
  }
  &.jcb {
    background-position: 0 -221px;
  }
  &.diners-club {
    background-position: 0 -133px;
  }
`;
