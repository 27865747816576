import * as React from 'react';
import { useQuery, gql, useMutation } from '../../components/ApolloClient';
import { createRef } from 'react';
import Component2 from '@reach/component-component';
import Select from 'react-select';
import ContentWrapper from '../../components/ContentWrapper';
import InView from 'react-intersection-observer';
import moment from 'moment-timezone';
import { Link, RouteComponentProps } from 'react-router-dom';
import { useFetch } from '../../components/useFetch';
import sortBy from 'lodash.sortby';
import qs from 'query-string';
import { ensureString } from '../../utils/strings';
import { badgeBgColorStatus } from './details';
import { SearchField } from '../partners/components';

const ELLIPSIS_STYLE = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
} as const;

const VIRTUAL_STAGING = 'virtual-staging';

type Job = {
  id: string;
  verticalType?: { displayName: string };
  displayStatus?: string;
  partner?: {
    uid: string;
    name: string;
    activeContract?: {
      id: string;
      durationInMonths?: number;
    };
  };
  dueAt?: string;
  editor?: { id: string; fullName: string; email: string };
  servicesSummary: {
    edges: {
      id: string;
      editingJobService?: {
        id: string;
        slug?: string;
      };
    }[];
  };
  hasVirtualStaging;
};

const CopyIdField = ({ id }: { id: string }) => {
  const editingJobIdInputRef = createRef<HTMLInputElement>();

  return (
    <div className="input-group">
      <input
        type="text"
        className="form-control"
        readOnly={true}
        ref={editingJobIdInputRef}
        value={id}
        style={{ maxWidth: 320, textOverflow: 'ellipsis' }}
      />
      <div className="input-group-append">
        <button
          className="btn btn-primary"
          onClick={() => {
            if (editingJobIdInputRef.current != null) {
              editingJobIdInputRef.current.select();
              document.execCommand('copy');
            }
          }}
        >
          <i className="fa fa-copy" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
};

const customStyles = {
  valueContainer: (provided, state) => ({
    ...provided,
    marginLeft: 70,
  }),
};

const MultiSelectFiler = ({
  filterName,
  options,
  location,
  history,
}: {
  filterName: string;
  options;
  location;
  history;
}) => {
  const filterString = qs.parse(location.search)[filterName];
  const selectedValues = filterString
    ? ensureString(filterString ?? '')?.split(',')
    : [];

  const selectedOptions =
    selectedValues && options.filter((o) => selectedValues.includes(o.value));
  return (
    <div
      style={{
        minWidth: 150,
        marginRight: 16,
        position: 'relative',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 7,
          left: 10,
          zIndex: 2,
          textTransform: 'capitalize',
          color: '#C1C8D4',
        }}
      >
        {filterName !== 'dueAt' ? filterName : 'Due in'}
      </div>
      <div style={{ minWidth: 100 }}>
        <Select
          isMulti
          value={selectedOptions}
          options={options}
          styles={customStyles}
          placeholder=""
          onChange={(selectedOptions) => {
            const selected = (selectedOptions || []).map((s) => s.value);

            history.push({
              ...location,
              search: qs.stringify({
                ...qs.parse(location.search),
                [filterName]:
                  selected.length > 0 ? selected.join(',') : undefined,
              }),
            });
          }}
        />
      </div>
    </div>
  );
};

function DataRowsChunk({
  cursor,
  onMore,
  saveEditor,
  saveEditorMutation,
  user,
  setSelectedIds,
  selectedIds,
  editorIds,
  status,
  dueAt,
  search,
}: {
  cursor: string | null;
  onMore?: (p: { nextCursor: string }) => void;
  saveEditor;
  saveEditorMutation;
  user: string | null;
  setSelectedIds;
  selectedIds: string[];
  editorIds: string[];
  status: string[];
  dueAt: string[];
  search?: string;
}) {
  const editingJobsQuery = useQuery<{
    editingJobsAsAdmin?: {
      cursor?: string;
      edges?: Job[];
    };
  }>(
    gql`
      query EditingJobsQueueAsAdmin(
        $first: Int
        $cursor: String
        $editorIds: [ID]
        $status: [String]
        $dueAt: [String]
        $search: String
      ) {
        editingJobsAsAdmin(
          first: $first
          cursor: $cursor
          editorIds: $editorIds
          status: $status
          dueAt: $dueAt
          search: $search
        ) {
          cursor
          edges {
            id
            displayStatus
            verticalType {
              displayName
            }
            partner {
              uid
              name
              activeContract {
                id
                durationInMonths
              }
            }
            dueAt
            editor {
              id
              fullName
              email
            }
            servicesSummary {
              edges {
                id
                editingJobService {
                  id
                  slug
                }
              }
            }
          }
        }
      }
    `,
    {
      variables: {
        cursor,
        first: cursor == null ? 20 : 40,
        status,
        editorIds,
        dueAt,
        search,
      },
    }
  );

  const editingJobs = editingJobsQuery.data?.editingJobsAsAdmin?.edges?.map(
    (job) => {
      const hasVirtualStaging = job.servicesSummary.edges?.some(
        (service) => service.editingJobService?.slug === VIRTUAL_STAGING
      );
      return {
        ...job,
        hasVirtualStaging,
      };
    }
  );
  const nextCursor = editingJobsQuery.data?.editingJobsAsAdmin?.cursor;

  return (
    <>
      {onMore == null || nextCursor == null ? null : (
        <InView>
          {({ inView, ref }) => (
            <tr ref={ref}>
              {inView && <Component2 didMount={() => onMore({ nextCursor })} />}
            </tr>
          )}
        </InView>
      )}
      {editingJobs == null && editingJobsQuery.loading
        ? Array.from(Array(7)).map((i, index) => (
            <tr key={index}>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
            </tr>
          ))
        : editingJobs?.map((editingJob) => (
            <tr key={editingJob?.id}>
              <td>
                <input
                  checked={selectedIds.includes(editingJob?.id)}
                  type="checkbox"
                  onChange={() =>
                    setSelectedIds((prev) =>
                      prev.includes(editingJob?.id)
                        ? prev.filter((id) => id !== editingJob?.id)
                        : [...prev, editingJob?.id]
                    )
                  }
                />
              </td>
              <td style={{ fontWeight: 'bold' }}>
                {editingJob?.partner?.name}
              </td>
              <td>
                {editingJob?.partner?.activeContract?.durationInMonths != null
                  ? editingJob?.partner?.activeContract?.durationInMonths ??
                    0 >= 12
                    ? 'Long term'
                    : 'Pilot'
                  : 'No contract'}
              </td>
              <td>{editingJob?.verticalType?.displayName}</td>

              <td>
                <span
                  className="badge badge-primary text-uppercase"
                  style={badgeBgColorStatus(editingJob?.displayStatus ?? '')}
                >
                  {editingJob.displayStatus}
                </span>
              </td>
              <td> No issues</td>

              <td
                style={{
                  color:
                    moment(editingJob?.dueAt) < moment() &&
                    editingJob.displayStatus !== 'completed'
                      ? 'red'
                      : '',
                }}
              >
                {editingJob?.dueAt != null
                  ? moment(editingJob?.dueAt)
                      .subtract(editingJob.hasVirtualStaging ? 0 : 2, 'days')
                      .fromNow()
                  : '-'}
              </td>
              <td>
                {editingJob?.editor != null ? (
                  <span>{editingJob?.editor?.fullName}</span>
                ) : (
                  <button
                    className="btn btn-primary"
                    disabled={saveEditorMutation.loading}
                    onClick={() =>
                      saveEditor({
                        variables: {
                          editingJobIds: [editingJob?.id],
                          editorId: user,
                        },
                      }).then(() => editingJobsQuery.refetch())
                    }
                  >
                    Claim
                  </button>
                )}
              </td>
              <td>
                <div style={{ display: 'flex' }}>
                  <CopyIdField id={editingJob?.id} />
                  <div className="btn btn-dark">
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      to={`editing-jobs/${editingJob.id}/details`}
                    >
                      <i className="fa fa-external-link" aria-hidden="true" />
                    </Link>
                  </div>
                </div>
              </td>
            </tr>
          ))}
      {onMore == null || nextCursor == null ? null : (
        <InView>
          {({ inView, ref }) => (
            <tr ref={ref}>
              {inView && <Component2 didMount={() => onMore({ nextCursor })} />}
            </tr>
          )}
        </InView>
      )}
    </>
  );
}

export const EditingQueueRoute = ({
  location,
  session,
  history,
}: {
  location: RouteComponentProps['location'];
  session: { token: string; uid: string };
  history;
}) => {
  const [cursors, setCursors] = React.useState<string[]>([]);
  const [selectedIds, setSelectedIds] = React.useState<string[]>([]);
  const editorsFetch = useFetch<{
    results?: {
      uid: string;
      email: string;
      edited_bookings_count: number;
    }[];
  }>({
    urlToFetch: '/api/v2/photo-editing-queue/editors',
    session,
  });

  const sortedEditors = React.useMemo(
    () =>
      sortBy(
        editorsFetch.result?.results?.map((e) => ({
          value: e.uid,
          label: e.email,
        })),
        (e) => e.value
      ),
    [editorsFetch.result?.results]
  );

  const statusOptions = [
    { label: 'CREATED', value: 'created' },
    { label: 'EDITING', value: 'paid' },
    { label: 'COMPLETED', value: 'completed' },
  ];

  const dueAtOptions = [
    { label: 'Overdue', value: 'overdue' },
    { label: '< 8 hours', value: 'less_8_hours' },
    { label: '8 to 16 hours', value: 'less_16_hours' },
    { label: '16 to 48 hours', value: 'less_48_hours' },
    { label: '48 to 72 hours', value: 'less_72_hours' },
    { label: '> 72 hours', value: 'greater_72_hours' },
  ];

  const selectedEditors = React.useMemo(
    () =>
      ensureString(qs.parse(location.search)['editor'] ?? null)?.split(',') ??
      [],
    [location]
  );

  const selectedStatus = React.useMemo(
    () =>
      ensureString(qs.parse(location.search)['status'] ?? null)?.split(',') ??
      [],
    [location]
  );

  const selectedDueAt = React.useMemo(
    () =>
      ensureString(qs.parse(location.search)['dueAt'] ?? null)?.split(',') ??
      [],
    [location]
  );

  React.useEffect(() => {
    setCursors([]);
  }, [selectedEditors, selectedStatus, selectedDueAt]);

  const [saveEditor, saveEditorMutation] = useMutation<{
    editingJobSaveEditorAsAdmin: {
      edges: { id: string }[];
    };
  }>(
    gql`
      mutation EditingJobSaveEditor($editingJobIds: [ID]!, $editorId: ID) {
        editingJobSaveEditorAsAdmin(
          input: { editingJobIds: $editingJobIds, editorId: $editorId }
        ) {
          edges {
            id
          }
        }
      }
    `
  );

  const parsedSearch = qs.parse(location.search);

  return (
    <div>
      <div style={{ fontWeight: 'bold', fontSize: 22, marginBottom: 16 }}>
        Editing jobs queue
      </div>
      <ContentWrapper>
        <Component2
          didMount={() => {
            const shouldRedirectOnMount =
              parsedSearch.filter_editing_status === undefined;

            if (shouldRedirectOnMount) {
              return history.replace({
                ...location,
                search: qs.stringify({
                  ...parsedSearch,

                  status: parsedSearch.status || 'paid',
                }),
              });
            }
          }}
        />

        <div className="table-responsive">
          {selectedIds?.length > 0 ? (
            <button
              className="btn btn-danger"
              style={{ margin: 8 }}
              disabled={saveEditorMutation.loading}
              onClick={() =>
                saveEditor({
                  variables: {
                    editingJobIds: selectedIds,
                    editorId: null,
                  },
                }).then(() => setCursors([]))
              }
            >
              Remove editor
            </button>
          ) : (
            <div className="d-flex justify-content-between">
              <div style={{ margin: 8, display: 'flex' }}>
                <MultiSelectFiler
                  filterName={'status'}
                  options={statusOptions}
                  location={location}
                  history={history}
                />
                <MultiSelectFiler
                  filterName={'editor'}
                  options={sortedEditors}
                  location={location}
                  history={history}
                />
                <MultiSelectFiler
                  filterName={'dueAt'}
                  options={dueAtOptions}
                  location={location}
                  history={history}
                />
              </div>
              <SearchField
                style={{ margin: 8 }}
                onSearch={({ q }) =>
                  history.push({
                    ...location,
                    search: qs.stringify({
                      ...qs.parse(location.search),
                      q: q || undefined,
                    }),
                  })
                }
                defaultValue={qs.parse(location.search).q || ''}
              />
            </div>
          )}

          <table className="table" style={{ position: 'relative' }}>
            <thead style={{ color: '#71767E' }}>
              <tr>
                <th></th>
                <th style={ELLIPSIS_STYLE} scope="col">
                  Customer
                </th>
                <th style={ELLIPSIS_STYLE} scope="col">
                  Partner Type
                </th>
                <th style={ELLIPSIS_STYLE} scope="col">
                  Type
                </th>
                <th style={ELLIPSIS_STYLE} scope="col">
                  Status
                </th>
                <th style={ELLIPSIS_STYLE} scope="col">
                  Issues
                </th>
                <th style={ELLIPSIS_STYLE} scope="col">
                  Due in
                </th>
                <th style={ELLIPSIS_STYLE} scope="col">
                  Editor
                </th>
                <th style={ELLIPSIS_STYLE} scope="col">
                  JobID
                </th>
              </tr>
            </thead>
            <tbody style={{ color: '#6C757D' }}>
              {[null, ...cursors].map((cursor, index) => (
                <DataRowsChunk
                  key={cursor ?? '1'}
                  cursor={cursor}
                  saveEditor={saveEditor}
                  saveEditorMutation={saveEditorMutation}
                  user={session.uid}
                  setSelectedIds={setSelectedIds}
                  selectedIds={selectedIds}
                  editorIds={selectedEditors}
                  status={selectedStatus}
                  dueAt={selectedDueAt}
                  search={ensureString(parsedSearch['q'])}
                  onMore={
                    index === cursors.length + 1 - 1
                      ? ({ nextCursor }) =>
                          setCursors((state) =>
                            state.includes(nextCursor)
                              ? state
                              : [...state, nextCursor]
                          )
                      : undefined
                  }
                />
              ))}
            </tbody>
          </table>
        </div>
      </ContentWrapper>
    </div>
  );
};
