import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Select from 'react-select';

import { Modal } from './Modal';

import { locationForModal } from './ModalRoute';
import { useFetch } from './useFetch';

type SelectedEditor = {
  label: string;
  value: string;
};

type EnterpriseRichTextSelectPartnerProps = {
  location: RouteComponentProps['location'];
  history: RouteComponentProps['history'];
  session: { token: string };
  onContinue: (context: { partnerId: string; guidelineId: string }) => void;
  onDismiss: () => void;
  modalParams: {
    partnerId: string;
    partnerName: string;
    guidelineId: string;
  };
};

export const EnterpriseRichTextSelectPartnerModal = ({
  location,
  history,
  session,
  onDismiss,
  onContinue,
  modalParams,
}: EnterpriseRichTextSelectPartnerProps) => {
  const canSubmit = modalParams.partnerId != null;

  const {
    result: { results: partners } = {},
    isPending: partnersPending,
  } = useFetch<{
    results: any[];
  }>({
    urlToFetch: `/api/v2/partners/summary`,
    session,
    queryToFetch: {
      limit: 499,
    },
  });

  const [
    selectedPartner,
    setSelectedPartner,
  ] = React.useState<SelectedEditor | null>(
    modalParams.partnerId != null
      ? {
          label: modalParams.partnerName,
          value: modalParams.partnerId,
        }
      : null
  );

  async function onSubmitHandler(event) {
    event.preventDefault();

    if (canSubmit !== true) {
      return;
    }

    onContinue({
      partnerId: modalParams.partnerId,
      guidelineId: modalParams.guidelineId,
    });
  }

  const handleOnChange = (selectedValue: SelectedEditor) => {
    history.replace(
      locationForModal({
        location,
        modal: {
          ...modalParams,
          partnerName: selectedValue.label,
          partnerId: selectedValue.value,
        },
      })
    );
    setSelectedPartner(selectedValue);
  };

  return (
    <Modal onDismiss={onDismiss}>
      <div className="card my-4">
        <div className="card-header">
          <h4 className="mb-0">Duplicate Guideline</h4>
        </div>

        <form
          className="card-body d-flex flex-column"
          style={{ width: '1000px' }}
          onSubmit={onSubmitHandler}
        >
          <div className="flex-1" style={{ position: 'relative' }}>
            <div className="row">
              <p className="col mb-4">
                Select the enterprise you want the new guideline to belong to.
              </p>
            </div>
            <div className="row">
              <div className="form-group col">
                <label>Enterprise</label>
                <Select
                  className="w-100"
                  placeholder={
                    partnersPending ? 'Loading...' : 'Select enterprise'
                  }
                  isLoading={partnersPending}
                  options={partners?.map((partner) => ({
                    label: partner.name,
                    value: partner.uid,
                  }))}
                  value={selectedPartner}
                  onChange={handleOnChange}
                  isSearchable
                />
              </div>
            </div>
          </div>

          <div className="p-2" />

          <div className="flex-grow-1" />

          <div className="row">
            <div className="col">
              <button
                type="button"
                className="btn btn-outline-secondary btn-block"
                onClick={onDismiss}
              >
                Cancel
              </button>
            </div>

            <div className="col">
              <button
                type="submit"
                className="btn btn-primary btn-block"
                disabled={!canSubmit}
                onClick={() => {
                  onContinue({
                    partnerId: modalParams.partnerId,
                    guidelineId: modalParams.guidelineId,
                  });
                }}
              >
                Select
              </button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};
