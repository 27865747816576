import React, { Component } from 'react';

export class PromiseButton extends Component {
  constructor() {
    super(...arguments);
    this.state = { promiseStatus: PromiseButton.STATUS_INIT };
    this.currentPromiseKey = null;
  }

  componentWillUnmount() {
    this.currentPromiseKey = null;
  }

  render() {
    const {
      pendingChildren,
      rejectedChildren,
      resolvedChildren,
      children,
      onClick,
      disabled,
      ...rest
    } = this.props;
    const { promiseStatus } = this.state;
    const statusHash = {
      [PromiseButton.STATUS_INIT]: children,
      [PromiseButton.STATUS_PENDING]: pendingChildren || children,
      [PromiseButton.STATUS_REJECTED]: rejectedChildren || children,
      [PromiseButton.STATUS_RESOLVED]: resolvedChildren || children,
    };
    const buttonChildren = statusHash[promiseStatus];
    return (
      <button
        {...rest}
        onClick={(...args) => {
          const promiseKey = Date.now();
          this.currentPromiseKey = promiseKey;
          onClick(...args)
            .then(() => {
              if (this.currentPromiseKey !== promiseKey) return;
              this.setState({ promiseStatus: PromiseButton.STATUS_RESOLVED });
            })
            .catch((err) => {
              if (this.currentPromiseKey !== promiseKey) return;
              this.setState({ promiseStatus: PromiseButton.STATUS_REJECTED });
              throw err;
            });
          this.setState({ promiseStatus: PromiseButton.STATUS_PENDING });
        }}
        disabled={disabled || promiseStatus === PromiseButton.STATUS_PENDING}
      >
        {buttonChildren}
      </button>
    );
  }
}
PromiseButton.STATUS_INIT = 'init';
PromiseButton.STATUS_PENDING = 'pending';
PromiseButton.STATUS_REJECTED = 'rejected';
PromiseButton.STATUS_RESOLVED = 'resolved';
