import React, { useState } from 'react';
import moment, { Moment } from 'moment-timezone';

import { DateTimeSelector } from './DateTimeSelector';

export const DateTimeDetails: React.FC<{
  providersAvailability;
  providersAvailabilityLoading: boolean;
  selectedDate: Moment;
  bookingClone?: {
    uid?: string;
    start_at?: string;
    timezone?: string;
    point?: [number, number];
    duration?: number;
    shoottype?: string;
    provider_uid?: string;
  };
  booking;
  session: {};
  onDateSelect: (datetime: Moment | null) => void;
  onStartAtChange: (p: {
    start_at: string;
    providerNotAvailable?: boolean;
    availableProvidersUids?: string[];
    availableSpecialistProvidersUids?: string[];
  }) => void;
  bookingChangedKeys: { start_at?: boolean };
}> = (props) => {
  const [noDatetimeRestrictions, setNoDatetimeRestrictions] = useState<boolean>(
    false
  );

  const { bookingClone, onStartAtChange, bookingChangedKeys } = props;

  return (
    <div className="col-xl-8 col-lg-12">
      <div className="card h-100">
        <div
          className={`card-body ${
            bookingChangedKeys.start_at ? 'border border-success' : ''
          }`}
        >
          <h3 className="d-flex justify-content-between align-items-baseline">
            <span>Date and time</span>
            {bookingClone?.start_at && (
              <small className="text-muted">
                {moment(bookingClone.start_at)
                  .tz(bookingClone.timezone ?? moment.tz.guess())
                  .format('ddd, MMM Do YYYY, h:mm:ss a zz')}
              </small>
            )}
          </h3>

          <div className="row">
            <div className="col-6" />
            <div className="col-6">
              <button
                className="btn btn-block btn-sm btn-outline-secondary"
                onClick={() =>
                  setNoDatetimeRestrictions(!noDatetimeRestrictions)
                }
              >
                {noDatetimeRestrictions
                  ? 'Show only available dates and times'
                  : 'Allow any date and time'}
              </button>
            </div>
          </div>

          <div style={{ position: 'relative' }} className="mt-2">
            <DateTimeSelector
              selectedDate={props.selectedDate}
              selectedDatetime={moment(bookingClone?.start_at).tz(
                bookingClone?.timezone ?? moment.tz.guess()
              )}
              onDateSelect={props.onDateSelect}
              onDatetimeSelect={({
                startDate,
                providerNotAvailable,
                availableProvidersUids,
                availableSpecialistProvidersUids,
              }) =>
                onStartAtChange({
                  start_at: moment(startDate).toISOString(),
                  providerNotAvailable,
                  availableProvidersUids,
                  availableSpecialistProvidersUids,
                })
              }
              noDatetimeRestrictions={noDatetimeRestrictions}
              providersAvailability={props.providersAvailability}
              providersAvailabilityLoading={props.providersAvailabilityLoading}
              disabledDate={() => false}
              selectedProvider={bookingClone?.provider_uid}
            />

            {!(
              bookingClone?.timezone &&
              bookingClone.duration &&
              bookingClone.shoottype
            ) && (
              <div
                className={[
                  'd-flex flex-columns',
                  'justify-content-center align-items-center',
                ].join(' ')}
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  backgroundColor: 'rgba(255, 255, 255, 0.5)',
                }}
              >
                <div className="alert alert-warning">
                  First select a location & package, and save
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
