import * as React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import type { Descendant } from 'slate';
import Component2 from '@reach/component-component';

import { Tooltip } from '../../../../components/Tooltip';
import { GUIDELINE_PLACE_HOLDER } from '..';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const nextId = (sections: { id: string }[]) =>
  (Math.max(...sections.map(({ id }) => parseInt(id, 10))) + 1).toString();

export function SectionList({
  sections,
  setSections,
  activeSection,
  changeSection,
}: {
  sections: { id: string; name: string; content: Descendant[] }[];
  setSections: React.Dispatch<React.SetStateAction<any>>;
  activeSection: { id: string; name: string };
  changeSection: (p: { id: string }) => void;
}) {
  const [nameChangeIndex, setNameChangeIndex] = React.useState<string | null>(
    null
  );
  const sectionNameInputRef = React.useRef<HTMLInputElement>(null);

  const getItemStyle = (isDragging, draggableStyle) => ({
    background: isDragging ? '#f6f9ff' : 'transparent',
    ...draggableStyle,
  });

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedItems = reorder(
      sections,
      result.source.index,
      result.destination.index
    ) as { id: number; name: string }[];

    setSections(reorderedItems);
  };

  const duplicateItem = (index) => {
    setSections([
      ...sections,
      {
        id: nextId(sections),
        name: sections[index].name,
        content: sections[index].content,
      },
    ]);
  };

  const deleteItem = (id) => {
    setSections(sections.filter((item) => item.id !== id));
  };

  const setContent = (id, value) => {
    const newItems = sections.map((s) =>
      s.id !== id ? s : { id: s.id, name: value, content: s.content }
    );
    setSections(newItems);
  };

  const newItem = () => {
    const newItemId = nextId(sections);

    setSections([
      ...sections,
      {
        id: newItemId,
        name: 'New section',
        content: GUIDELINE_PLACE_HOLDER(''),
      },
    ]);

    setNameChangeIndex(newItemId);
  };

  const renameItem = ({ itemId, value }: { itemId: string; value: string }) => {
    setContent(itemId, value);
    setNameChangeIndex(null);
  };

  return (
    <div className="container" style={{ height: '100%' }}>
      <div style={{ position: 'sticky', top: 62 }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {sections?.map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={item.id.toString()}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        {nameChangeIndex === item.id ? (
                          <Component2
                            didMount={() => {
                              sectionNameInputRef.current?.focus();
                            }}
                          >
                            <form
                              onSubmit={(e) => {
                                e.preventDefault();

                                renameItem({
                                  itemId: item.id,
                                  value: e.target['section-name'].value,
                                });
                              }}
                            >
                              <input
                                type="text"
                                name="section-name"
                                className="form-control"
                                defaultValue={item.name ?? ''}
                                ref={sectionNameInputRef}
                                onBlur={(e) =>
                                  renameItem({
                                    itemId: item.id,
                                    value: e.target.value,
                                  })
                                }
                              />
                            </form>
                          </Component2>
                        ) : (
                          <div
                            className={
                              activeSection.id === item.id
                                ? 'active'
                                : 'section'
                            }
                            key={item.id}
                          >
                            <div onClick={() => changeSection(item)}>
                              {item.name}
                            </div>
                            <div
                              className="actions"
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                              }}
                            >
                              <Tooltip label={'rename'}>
                                <i
                                  className="icon fa fa-pencil"
                                  onClick={() => setNameChangeIndex(item.id)}
                                />
                              </Tooltip>
                              <Tooltip label={'duplicate'}>
                                <i
                                  className="icon fa fa-copy"
                                  onClick={() => duplicateItem(index)}
                                />
                              </Tooltip>
                              {activeSection.id !== item.id && (
                                <Tooltip label={'delete'}>
                                  <i
                                    className="icon fa fa-trash"
                                    onClick={() => deleteItem(item.id)}
                                  />
                                </Tooltip>
                              )}

                              <Tooltip label={'reorder'}>
                                <i
                                  className="icon fa fa-list"
                                  {...provided.dragHandleProps}
                                />
                              </Tooltip>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <button className="btn btn-block btn-primary" onClick={() => newItem()}>
          New section
        </button>
      </div>
      <style jsx>{`
        .container {
          background-color: white;
          padding: 16px;
          border: 1px solid #E8EDF5;
          border-radius: '4px';
        }

        .icon {
          color: grey;
          padding: 4px;
        }

        .actions {
          display: flex
          gap: 8;
          visibility: hidden;
        }

        .active {
          background-color: #f6f9ff;
          padding: 16px;
          font-weight: bold;
          color: black;
          display: grid;
          grid-template-columns: 60% 40%;
        }

        .section {
          padding: 16px;
          color: #1f6fff;
          display: grid;
          grid-template-columns: 60% 40%;
        }

        .section:hover {
          background-color: #f6f9ff;
        }

        .active:hover .actions {
            visibility: visible;
        }

        .section:hover .actions {
            visibility: visible;
        }
      `}</style>
    </div>
  );
}
