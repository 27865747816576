import React from 'react';

export class ImageLoadIndicator extends React.Component {
  state = {
    loaded: false,
    error: false,
    width: null,
    height: null,
  };

  componentDidMount() {
    this.deactivate = (() => {
      let disabled = false;

      const self = this;
      const img = new Image();
      img.onload = function () {
        !disabled &&
          self.setState({
            loaded: true,
            width: this.width,
            height: this.height,
          });
      };
      img.onerror = () => !disabled && this.setState({ error: true });
      img.src = this.props.src;

      return () => (disabled = true);
    })();
  }

  componentWillUnmount() {
    this.deactivate();
  }

  render() {
    const render = this.props.children || this.props.render;
    return render(this.state);
  }
}
