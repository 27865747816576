import React from 'react';
import {
  gql,
  useMutation,
  useQuery,
} from '../../../../components/ApolloClient';
import { ReadOnlyInput } from './CustomerDetails';
import { LoadingSpinnerCentered } from '../../../../components/LoadingSpinner';

enum RequestParticipantDataFieldEnum {
  EMAIL = 'EMAIL',
  MOBILEPHONE_VALIDATED = 'MOBILEPHONE_VALIDATED',
  MOBILEPHONE = 'MOBILEPHONE',
}

export function PartnerBookingBusinessInfo({
  bookingId,
}: {
  bookingId: string;
  onPartnerCustomerEmailChange: (p: { partner_customer_email: string }) => void;
  onPartnerCustomerMobilephoneChange: (p: {
    partner_customer_mobilephone: string;
  }) => void;
}) {
  const participantsQuery = useQuery<{
    bookingById?: {
      id: string;
      contacts: {
        id: string;
        email?: string;
        firstName?: string;
        role?: string;
        phoneNumberValidated?: string;
        lastName?: string;
        websiteUrl?: string;
        googleMapsUrl?: string;
        openingHours?: string[];
        internationalPhoneNumber?: string;
      }[];
    };
  }>(
    gql`
      query PartnerBookingBusinessInfoAsAdmin(
        $bookingId: ID!
        $filterSkippedContacts: Boolean
      ) {
        bookingById(id: $bookingId) {
          id

          contacts(filterSkippedContacts: $filterSkippedContacts) {
            id
            firstName
            lastName
            role
            websiteUrl
            googleMapsUrl
            openingHours
            internationalPhoneNumber
          }
        }
      }
    `,
    {
      variables: { bookingId, filterSkippedContacts: true },
      skip: bookingId == null,
    }
  );

  const googleMapsPlaceDetails = participantsQuery.data?.bookingById?.contacts?.filter(
    (contact) => contact.role === 'Google maps details'
  );

  if (googleMapsPlaceDetails == null || googleMapsPlaceDetails.length < 1) {
    return participantsQuery.loading ? (
      <LoadingSpinnerCentered />
    ) : (
      <div className="card-body">
        <h3>Booking Business Info</h3>
        <div className="row">
          <div className="form-group col">
            No data was found from the Google Maps API for this booking.
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="card-body">
      {googleMapsPlaceDetails?.map((participant) => (
        <>
          <div className="alert alert-warning">
            This data was taken from the Google Maps API, please confirm it is
            correct.
          </div>
          <div className="row">
            <div className="form-group col">
              <label>
                <small className="text-secondary">Website url</small>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Website URL"
                value={participant.websiteUrl ?? ''}
                readOnly
                onClick={(ev) => (ev.target as HTMLInputElement).select()}
              />
            </div>

            <div className="form-group col">
              <label>
                <small className="text-secondary">Google maps url</small>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Google maps URL"
                value={participant.googleMapsUrl ?? ''}
                readOnly
                onClick={(ev) => (ev.target as HTMLInputElement).select()}
              />
            </div>
          </div>

          <div className="row">
            <RequestParticipantDataField
              bookingPartnerContactId={participant.id}
              label="Mobile phone"
              fieldToRequest={RequestParticipantDataFieldEnum.MOBILEPHONE}
            />
          </div>

          <div className="row">
            <div className="form-group col">
              <label>
                <small className="text-secondary">Opening hours</small>
              </label>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  color: '#495057',
                }}
              >
                {participant.openingHours?.map((hour) => {
                  const [date, time] = hour.split(': ');
                  return (
                    <div
                      style={{
                        borderBottom: '1px solid #E8EDF5',
                        padding: '0.375rem 0.75rem',
                        fontSize: '1rem',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <p style={{ fontSize: 12, marginBottom: 0 }}>{date}</p>
                      {time.toLowerCase() === 'closed' ? (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            background: '#E11F59',
                            borderRadius: 1,
                            width: 56,
                            height: 14,
                          }}
                        >
                          <p
                            style={{
                              fontFamily: 'Nunito Sans',
                              fontStyle: 'normal',
                              fontWeight: 700,
                              fontSize: 10,
                              color: '#FFFFFF',
                              marginBottom: 0,
                            }}
                          >
                            CLOSED
                          </p>
                        </div>
                      ) : (
                        <div>
                          {time.split(',').map((t, index) => {
                            return (
                              <p
                                style={{
                                  fontSize: 12,
                                  marginBottom: 0,
                                  textAlign: 'right',
                                  marginTop: index !== 0 ? 8 : 0,
                                }}
                              >
                                {t}
                              </p>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          {googleMapsPlaceDetails.indexOf(participant) !==
            googleMapsPlaceDetails.length - 1 && (
            <hr style={{ borderTopWidth: 3 }} />
          )}
        </>
      ))}
    </div>
  );
}

const RequestParticipantDataField = ({
  bookingPartnerContactId,
  fieldToRequest,
  label,
  placeholder = label,
}: {
  bookingPartnerContactId?: string;
  fieldToRequest: RequestParticipantDataFieldEnum;
  label: string;
  placeholder?: string;
}) => {
  const [requestData, { data }] = useMutation<
    {
      bookingPartnerContactRequestDataAsAdmin: {
        field?: string;
      };
    },
    {
      bookingPartnerContactId: string;
      field: RequestParticipantDataFieldEnum;
    }
  >(
    gql`
      mutation RequestBusinessInfoParticipantAsAdmin(
        $bookingPartnerContactId: ID!
        $field: BookingPartnerContactRequestDataAsAdminFieldEnum!
      ) {
        bookingPartnerContactRequestDataAsAdmin(
          input: {
            bookingPartnerContactId: $bookingPartnerContactId
            field: $field
          }
        ) {
          field
        }
      }
    `
  );

  const customerField = data?.bookingPartnerContactRequestDataAsAdmin.field;

  if (bookingPartnerContactId == null) {
    return (
      <ReadOnlyInput label={label} placeholder={placeholder} value="Error" />
    );
  }

  if (customerField == null) {
    return (
      <div className="form-group col">
        <label>
          <small className="text-secondary">{label}</small>
        </label>

        <div className="input-group">
          <input
            type="text"
            className="form-control"
            readOnly={true}
            value="**********"
          />
          <div className="input-group-append">
            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={() =>
                requestData({
                  variables: { bookingPartnerContactId, field: fieldToRequest },
                })
              }
            >
              <i className="mr-2 fa fa-eye" />
              Show
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <ReadOnlyInput
      label={label}
      placeholder={placeholder}
      value={customerField}
    />
  );
};
