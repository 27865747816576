import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import moment from 'moment-timezone';
import Component2 from '@reach/component-component';
import InView from 'react-intersection-observer';
import qs from 'query-string';
import Select from 'react-select';
import sortBy from 'lodash.sortby';

import ContentWrapper from '../../components/ContentWrapper';
import { gql, useMutation, useQuery } from '../../components/ApolloClient';
import { ensureString } from '../../utils/strings';
import { formatTimestampToCountdown } from '../../utils';
import { useFetch } from '../../components/useFetch';

const basePath = 'headshot-quality-control';

const STATUS = 'status';
const EDITOR = 'editor';
const Q = 'q';

type SubmissionsQuery = {
  offset: string;
  q: string;
  limit: string;
};

const statusOptions = [
  { label: 'pending', value: 'pending' },
  { label: 'claimed', value: 'claimed' },
  { label: 'reviewed', value: 'reviewed' },
];

const customStyles = {
  valueContainer: (provided, state) => ({
    ...provided,
    marginLeft: 70,
  }),
};

// Filters
const MultiSelectFiler = ({
  filterName,
  options,
  location,
  history,
}: {
  filterName: string;
  options;
  location: RouteComponentProps['location'];
  history: RouteComponentProps['history'];
}) => {
  const filterString = qs.parse(location.search)[filterName];
  const selectedValues = filterString
    ? ensureString(filterString ?? '')?.split(',')
    : [];

  const selectedOptions =
    selectedValues && options.filter((o) => selectedValues.includes(o.value));

  return (
    <div
      style={{
        minWidth: 150,
        marginRight: 16,
        position: 'relative',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 7,
          left: 10,
          zIndex: 2,
          textTransform: 'capitalize',
          color: '#C1C8D4',
        }}
      >
        {filterName !== 'dueAt' ? filterName : 'Due in'}
      </div>
      <div style={{ minWidth: 100 }}>
        <Select
          isMulti
          value={selectedOptions}
          options={options}
          styles={customStyles}
          placeholder=""
          onChange={(selectedOptions) => {
            const selected = (selectedOptions || []).map((s) => s.value);

            history.push({
              ...location,
              search: qs.stringify({
                ...qs.parse(location.search),
                [filterName]:
                  selected.length > 0 ? selected.join(',') : undefined,
              }),
            });
          }}
        />
      </div>
    </div>
  );
};

function DataRowsChunk({
  cursor,
  onMore,
  location,
  history,
  session,
}: {
  cursor?: string;
  onMore?: (p: { nextCursor: string }) => void;
  location: RouteComponentProps['location'];
  history: RouteComponentProps['history'];
  session: { token: string; uid: string };
}) {
  const selectedStatus = React.useMemo(
    () =>
      ensureString(qs.parse(location.search)[STATUS] ?? null)?.split(',') ?? [],
    [location]
  );

  const selectedEditors = React.useMemo(
    () =>
      ensureString(qs.parse(location.search)[EDITOR] ?? null)?.split(',') ?? [],
    [location]
  );

  const q = React.useMemo(
    () => ensureString(qs.parse(location.search)[Q] ?? null) ?? '',
    [location]
  );

  type HeadshotSubmission = {
    id: string;
    createdAt?: string;
    reviewedAt: string;
    status?: string;
    reviewerEmail?: string;
    editorEmail?: string;
    aiPortraitJob?: {
      id: string;
      status?: string;
      createdAt?: string;
      subjectName?: string;
      editorEmail?: string;
      deadlineDate?: string;
      headshotPackage?: { id: string; displayName?: string };
      submissions?: { edges: { id: string }[] };
    };
  };

  const submissionList = useQuery<
    {
      aiPortraitJobSubmissionList?: {
        edges?: HeadshotSubmission[];
        cursor?: string;
      };
    },
    {
      first: number;
      cursor?: string;
      statusFilter?: string[];
      editorFilter?: string[];
      search?: string;
    }
  >(
    gql`
      query AiPortraitJobSubmissionListAsAdmin(
        $first: Int
        $cursor: String
        $statusFilter: [String]
        $editorFilter: [String]
        $search: String
      ) {
        aiPortraitJobSubmissionList(
          first: $first
          cursor: $cursor
          statusFilter: $statusFilter
          editorFilter: $editorFilter
          search: $search
        ) {
          edges {
            id
            createdAt
            reviewedAt
            status
            reviewerEmail
            editorEmail
            aiPortraitJob {
              id
              submissions {
                edges {
                  id
                }
              }
              createdAt
              status
              subjectName
              editorEmail
              deadlineDate
              headshotPackage {
                id
                displayName
              }
            }
          }
          cursor
        }
      }
    `,
    {
      variables: {
        cursor: String(cursor),
        first: cursor == null ? 20 : 40,
        statusFilter: selectedStatus,
        editorFilter: selectedEditors,
        search: q,
      },
    }
  );

  const submissionData =
    submissionList.data?.aiPortraitJobSubmissionList?.edges ?? [];

  const nextCursor = submissionList.data?.aiPortraitJobSubmissionList?.cursor;

  const [
    aiPortraitsJobsAssignToReviewer,
    aiPortraitsJobsAssignToReviewerMutation,
  ] = useMutation<
    { success: boolean },
    {
      aiPortraitJobSubmissionIds: string[];
      reviewerId: string;
    }
  >(
    gql`
      mutation aiPortraitsJobsClaimReviewer(
        $aiPortraitJobSubmissionIds: [ID!]!
        $reviewerId: ID
      ) {
        aiPortraitsJobsSubmissionAssignToReviewer(
          input: {
            aiPortraitJobSubmissionIds: $aiPortraitJobSubmissionIds
            reviewerId: $reviewerId
          }
        ) {
          success
        }
      }
    `
  );

  return (
    <>
      {onMore == null || nextCursor == null ? null : (
        <InView>
          {({ inView, ref }) => (
            <tr ref={ref}>
              {inView && <Component2 didMount={() => onMore({ nextCursor })} />}
            </tr>
          )}
        </InView>
      )}

      {submissionData == null && submissionList.loading
        ? Array.from(Array(7)).map((i, index) => (
            <tr key={'loading-' + index}>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
            </tr>
          ))
        : submissionData?.map((submission) => {
            const submissions =
              submission.aiPortraitJob?.submissions?.edges ?? [];

            const reverseSubmissions = [...submissions].reverse();

            return (
              <tr key={submission.id}>
                <td className="text-truncate">
                  {reverseSubmissions.findIndex((s) => s.id === submission.id) +
                    1}
                </td>
                <td className="text-truncate">
                  {submission?.aiPortraitJob?.id}
                </td>
                <td className="text-truncate">
                  {submission.aiPortraitJob?.subjectName}
                </td>
                <td className="text-truncate">
                  {submission.aiPortraitJob?.headshotPackage?.displayName}
                </td>
                <td className="text-truncate">
                  {submission?.editorEmail ?? '-'}
                </td>
                <td className="text-truncate">
                  {submission.reviewerEmail ??
                    (submission.aiPortraitJob?.status !== 'completed' ? (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          aiPortraitsJobsAssignToReviewer({
                            variables: {
                              aiPortraitJobSubmissionIds: [submission.id],
                              reviewerId: session.uid,
                            },
                          }).then(() => submissionList.refetch());
                        }}
                      >
                        Claim
                      </button>
                    ) : (
                      '-'
                    ))}
                </td>
                <td
                  className="text-truncate"
                  style={{
                    color:
                      moment(submission.aiPortraitJob?.deadlineDate) <
                        moment() &&
                      submission.aiPortraitJob?.status !== 'completed'
                        ? 'red'
                        : 'black',
                  }}
                >
                  {submission?.aiPortraitJob?.deadlineDate != null &&
                  submission?.aiPortraitJob?.status !== 'completed'
                    ? formatTimestampToCountdown({
                        deadline: submission?.aiPortraitJob?.deadlineDate,
                      })
                    : '-'}
                </td>

                <td className="text-truncate">
                  {submission?.aiPortraitJob?.status}
                </td>

                <td className="text-truncate">{submission?.status}</td>
                <td className="text-truncate">
                  <div className="btn btn-dark">
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      to={`ai-portrait-job/${submission?.aiPortraitJob?.id}`}
                    >
                      <i className="fa fa-external-link" aria-hidden="true" />
                    </Link>
                  </div>
                </td>

                <td className="text-truncate">
                  <div className="btn btn-dark">
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      to={`${basePath}/${submission?.id}`}
                    >
                      <i className="fa fa-external-link" aria-hidden="true" />
                    </Link>
                  </div>
                </td>
              </tr>
            );
          })}

      {onMore == null || nextCursor == null ? null : (
        <InView>
          {({ inView, ref }) => (
            <tr ref={ref}>
              {inView && <Component2 didMount={() => onMore({ nextCursor })} />}
            </tr>
          )}
        </InView>
      )}
    </>
  );
}

export const HeadshotSubmissionListRoute = ({
  location,
  session,
  history,
}: {
  location: RouteComponentProps['location'];
  session: { token: string; uid: string };
  history: RouteComponentProps['history'];
}) => {
  const [cursors, setCursors] = React.useState<string[]>([]);

  const editorsFetch = useFetch<{
    results?: {
      uid: string;
      email: string;
      edited_bookings_count: number;
    }[];
  }>({
    urlToFetch: '/api/v2/photo-editing-queue/editors',
    session,
  });

  const sortedEditors = React.useMemo(
    () =>
      sortBy(
        editorsFetch.result?.results?.map((e) => ({
          value: e.uid,
          label: e.email,
        })),
        (e) => e.value
      ),
    [editorsFetch.result?.results]
  );

  const { offset, q } = qs.parse(location.search) as SubmissionsQuery;

  const [search, setSearch] = React.useState<string>(q);

  const handleSubmit = (e) => {
    e.preventDefault();
    history.push({ ...location, search: qs.stringify({ offset, q: search }) });
  };

  return (
    <>
      <h2>AI Headshots Quality Control</h2>
      <ContentWrapper>
        <div
          className="table-responsive"
          style={{
            position: 'relative',
          }}
        >
          <div style={{ margin: 8, display: 'flex' }}>
            <MultiSelectFiler
              filterName={STATUS}
              options={statusOptions}
              location={location}
              history={history}
            />

            <MultiSelectFiler
              filterName={'editor'}
              options={sortedEditors}
              location={location}
              history={history}
            />

            <form
              onSubmit={handleSubmit}
              style={{ display: 'flex', marginRight: '16px' }}
            >
              <input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                type="text"
                className="form-control"
                style={{ maxWidth: 300 }}
                placeholder="Search by headshotId"
              />
            </form>
          </div>
          <table className="table table-hover">
            <thead>
              <tr>
                <th className="text-muted text-truncate">Submission #</th>
                <th className="text-muted text-truncate">Job Id</th>
                <th className="text-muted text-truncate">Email</th>
                <th className="text-muted text-truncate">Package</th>
                <th className="text-muted text-truncate">Editor</th>
                <th className="text-muted text-truncate">QC</th>
                <th className="text-muted text-truncate">Due in</th>
                <th className="text-muted text-truncate">Job status</th>
                <th className="text-muted text-truncate">Submission status</th>
                <th className="text-muted text-truncate">Job details</th>
                <th className="text-muted text-truncate">Submission details</th>
              </tr>
            </thead>

            <tbody>
              {[null, ...cursors].map((cursor, index) => (
                <DataRowsChunk
                  key={cursor ?? '0'}
                  cursor={cursor ?? '0'}
                  history={history}
                  location={location}
                  session={session}
                  onMore={
                    index === cursors.length
                      ? ({ nextCursor }) =>
                          setCursors((state) =>
                            state.includes(nextCursor)
                              ? state
                              : [...state, nextCursor]
                          )
                      : undefined
                  }
                />
              ))}
            </tbody>
          </table>
        </div>
      </ContentWrapper>
    </>
  );
};
