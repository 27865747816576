export enum AutoAllocationStatusEnum {
  FINDING_PROVIDER = 'FINDING_PROVIDER',
  FINDING_PROVIDER_PROCESSING = 'FINDING_PROVIDER_PROCESSING ',
  PROVIDER_FOUND = 'PROVIDER_FOUND',
  PROVIDER_NOT_FOUND = 'PROVIDER_NOT_FOUND',
}

export type BookingEditRouteType = {
  uid?: string;
  purpose?: string;
  name?: string;
  timezone?: string;
  point?: [number, number];
  duration?: number;
  address?: string;
  country?: string;
  suburb?: string;
  city?: string;
  state?: string;
  service_type?: 'photography' | 'videography' | null;
  shoottype?: string;
  details?: string;
  start_at?: string;
  status?: string;
  provider_uid?: string;
  product_uid?: string;
  gallery_enabled?: string;
  location_note?: string;
  photos_submitted_at?: string;
  internal_notes?: string;
  partner_uid?: string;
  customer_uid?: string;
  partner_customer_company?: string;
  partner_customer_surname?: string;
  partner_customer_firstname?: string;
  partner_customer_email?: string;
  partner_customer_mobilephone?: string;
  partner_representative_uid?: string;
  centralize_editing_enabled?: boolean;
  pilot_mode_enabled_at?: string;
  auto_allocation_status?: AutoAllocationStatusEnum;
  property_size?: number;
  video_upload_file_enabled?: boolean;
};
