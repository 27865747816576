import * as React from 'react';
import { createRef } from 'react';
import { gql, useMutation, useQuery } from '../../../components/ApolloClient';
import { COLOR_PALLETTE } from '../../../constants';

import styleIcon from '../../../img/styleIcon.svg';
import backgroundIcon from '../../../img/background-icon.svg';
import packageIcon from '../../../img/package-icon.svg';
import { Link, RouteComponentProps } from 'react-router-dom';
import qs from 'query-string';
import rightArrow from '../../../components/img/right-arrow-in-circle.png';
import leftArrow from '../../../components/img/left-arrow-in-circle.png';
import { LoadingSpinnerCentered } from '../../../components/LoadingSpinner';
import { Modal } from '../../../components/Modal';
import {
  ModalRoute,
  locationForModal,
  parseModalQuery,
} from '../../../components/ModalRoute';
import { parseStyleNaming } from '../../ai-portrait-job/details';

const SEND_SUBMISSION_MODAL = 'send-headshot-submission-to-client-modal';

function ConfirmSendToClientModal({
  onDismiss,
  reload,
  submissionId,
  disabled,
}: {
  onDismiss: () => void;
  reload: () => void;
  submissionId: string;
  disabled: boolean;
}) {
  const [sendToClient, sendToClientMutation] = useMutation<
    {
      aiPortraitJobSubmissionSendToCustomerAsAdmin: {
        success: boolean;
      };
    },
    {
      aiPortraitJobSubmissionId: string;
    }
  >(
    gql`
      mutation AiPortraitSubmissionSendToClient(
        $aiPortraitJobSubmissionId: ID!
      ) {
        aiPortraitJobSubmissionSendToCustomerAsAdmin(
          input: { aiPortraitJobSubmissionId: $aiPortraitJobSubmissionId }
        ) {
          success
        }
      }
    `
  );

  return (
    <Modal onDismiss={onDismiss}>
      <div className="card modalContent" style={{ minWidth: 712 }}>
        <div className="title">Send images to client</div>

        <div className="content">
          You will be sending the client only the images that have been approved
          and marked as "Pass." Do you want to continue?
        </div>

        <div className="buttons">
          <button
            disabled={
              disabled === true || sendToClientMutation.loading === true
            }
            className="btn btn-secondary mr-3 ml-3"
            onClick={() => onDismiss()}
          >
            Close
          </button>

          <button
            disabled={
              disabled === true || sendToClientMutation.loading === true
            }
            className="btn btn-primary mr-3 ml-3 btn-end"
            onClick={() => {
              sendToClient({
                variables: {
                  aiPortraitJobSubmissionId: submissionId,
                },
              })
                .then(() => reload())
                .then(() => onDismiss());
            }}
          >
            Yes, send to client
          </button>
        </div>
      </div>
      <style jsx>{`
        .title {
          font-size: 22px;
          background-color: #f6f9ff;
          padding: 16px;
          font-weight: bold;
        }

        .content {
          padding: 16px;
        }

        .buttons {
          padding-bottom: 16px;
          display: flex;
          width: 100%;
        }

        .btn-end {
          margin-left: auto !important;
        }

        .modalContent {
          max-width: 500px;
        }
      `}</style>
    </Modal>
  );
}

const CopyIdField = ({ id }: { id: string }) => {
  const editingJobIdInputRef = createRef<HTMLInputElement>();

  return (
    <div className="input-group">
      <input
        type="text"
        className="form-control"
        readOnly={true}
        ref={editingJobIdInputRef}
        value={id}
        style={{ maxWidth: 320, textOverflow: 'ellipsis' }}
      />
      <div className="input-group-append">
        <button
          className="btn btn-primary"
          onClick={() => {
            if (editingJobIdInputRef.current != null) {
              editingJobIdInputRef.current.select();
              document.execCommand('copy');
            }
          }}
        >
          <i className="fa fa-copy" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
};

const SubmissionDetails = ({
  media,
  loading,
  reload,
  location,
  isModalDisplay = false,
  saveStatus,
  saveStatusMutation,
  saveFeedback,
  saveFeedbackMutation,
  setFeedback,
}: {
  media: {
    id: string;
    media: string;
    fileName: string;
    largeUrl: string;
    previewUrl?: string;
    feedback?: string;
    width: string;
    height: string;
    status: string;
  };
  loading: boolean;
  reload: () => void;
  location: RouteComponentProps['location'];
  isModalDisplay?: boolean;
  saveStatus: (args: {}) => Promise<any>;
  saveStatusMutation: any;
  saveFeedback: (args: {}) => Promise<any>;
  saveFeedbackMutation: any;
  setFeedback?: (string) => void;
}) => {
  const [textValue, setTextValue] = React.useState<string | undefined>(
    media?.feedback
  );

  React.useEffect(() => {
    setTextValue(media?.feedback);
  }, [media]);

  return (
    <>
      <div
        className={isModalDisplay ? 'submission-modal-display' : 'submission'}
        key={media?.id}
      >
        {loading && media == null ? (
          <LoadingSpinnerCentered />
        ) : (
          <>
            {isModalDisplay ? (
              <img
                src={media?.largeUrl}
                alt="portfolio"
                className="submission-img"
              />
            ) : (
              <Link
                key={media.id}
                to={{
                  ...location,
                  search: qs.stringify({
                    ...qs.parse(location.search),
                    photo: media.id,
                  }),
                }}
              >
                <img
                  src={media?.largeUrl}
                  alt="portfolio"
                  className="submission-img"
                />
              </Link>
            )}

            <div>
              <div
                style={{
                  paddingLeft: 10,
                  fontWeight: 'bold',
                  marginBottom: '8px',
                }}
              >
                Approval status
              </div>
              <div className="sub-btn-container">
                <button
                  className={`subBtn ${
                    media?.status === 'rejected' ? 'fail' : 'pending'
                  }`}
                  disabled={loading || saveStatusMutation.loading}
                  onClick={() => {
                    saveStatus({
                      variables: {
                        aiPortraitJobSubmissionMediaId: media.id,
                        status: 'rejected',
                      },
                    }).then(() => reload());
                  }}
                >
                  Fail
                </button>
                <button
                  className={`subBtn ${
                    media?.status === 'approved' ? 'pass' : 'pending'
                  }`}
                  disabled={loading || saveStatusMutation.loading}
                  onClick={() => {
                    saveStatus({
                      variables: {
                        aiPortraitJobSubmissionMediaId: media.id,
                        status: 'approved',
                      },
                    }).then(() => reload());
                  }}
                >
                  Pass
                </button>
              </div>
              {(media.status === 'rejected') === true && (
                <div className="text-area-container">
                  <div className="tag">Required</div>
                  <div style={{ paddingLeft: 8, fontWeight: 'bold' }}>
                    Reviewer notes
                  </div>

                  <textarea
                    value={textValue}
                    onBlur={(e) => {
                      if (isModalDisplay && setFeedback != null) {
                        setFeedback(e.target.value);
                      } else {
                        saveFeedback({
                          variables: {
                            aiPortraitJobSubmissionMediaId: media.id,
                            feedback: e.target.value,
                          },
                        }).then(() => reload());
                      }
                    }}
                    disabled={loading || saveFeedbackMutation.loading}
                    onChange={({ target }) => setTextValue(target.value)}
                    className="form-control text-area"
                    rows={3}
                  />
                </div>
              )}
            </div>
          </>
        )}
      </div>
      <style jsx>{`
        .text-area-container {
          position: relative;
        }

        .text-area {
          margin-top: 8px;
          margin-left: 8px;
        }

        .tag {
          position: absolute;
          right: 0;
          background-color: ${COLOR_PALLETTE.GRAY_3};
          font-size: 10px;
          padding: 2px;
          padding-left: 5px;
          padding-right: 5px;
          color: white;
          margin-radius: 2px;
        }

        .pending {
          background-color: ${COLOR_PALLETTE.GRAY_2};
        }

        .fail {
          background-color: ${COLOR_PALLETTE.DANGER};
          color: white;
        }

        .pass {
          background-color: ${COLOR_PALLETTE.SUCCESS};
          color: white;
        }

        .sub-btn-container {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 16px;
          margin-left: 8px;
          margin-bottom: 16px;
        }

        .subBtn {
          border: none;
          border-radius: 8px;
          padding: 8px;
        }

        .submission {
          display: grid;
          grid-template-columns: 40% 60%;
          max-height: 450px;
          padding: 16px;
          max-width: 600px;
          padding-left: 32px;
        }

        .submission-modal-display {
          display: grid;
          grid-template-columns: 60% 40%;
          width: 100%;
          align-items: start;
        }

        .submission-img {
          width: 100%;
        }

        .submissions-container {
          display: flex;
          flex-wrap: wrap;
        }
      `}</style>
    </>
  );
};

export function Loading() {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '100wh',
        minHeight: '100vh',
      }}
    >
      <div
        style={{
          borderRadius: 4,
          padding: '2em',
          backgroundColor: '#fff',
          boxShadow: '0 0 1.625em rgba(0,0,0,.05)',
        }}
      >
        <h3 style={{ padding: 0, margin: 0 }}>Loading results...</h3>
      </div>
    </div>
  );
}

type HeadshotSubmission = {
  id: string;
  createdAt?: string;
  reviewedAt: string;
  status?: string;
  submissionImages?: {
    edges?: {
      id: string;
      feedback?: string;
      status: string;
      image: {
        id: string;
        large: { url: string };
        downloadUrl: string;
        fileName;
      };
    }[];
  };
  aiPortraitJob?: {
    id: string;
    status?: string;
    createdAt?: string;
    subjectName?: string;
    editorEmail?: string;
    deadlineDate?: string;
    headshotPackage?: {
      id: string;
      displayName?: string;
      numberOfImages?: number;
    };
    submissions?: { edges: { id: string }[] };
    styleData?: {
      style?: string;
      backgroundFamily?: string;
      styles?: string[];
      backgroundFamilies?: string[];
    };
    items: {
      edges: {
        id: string;
        croppedImage: {
          id: string;
          downloadUrl?: string;
        };
      }[];
    };
    generatedImages: {
      edges: {
        id: string;
        finalImage: {
          id: string;
          fileName: string;
          downloadUrl?: string;
          large: { url: string };
        };
      }[];
    };
  };
};

const GalleryModal = ({
  onCloseRequest,
  modalTitle,
  propertyName,
  images,
  history,
  location,
}: {
  onCloseRequest: () => void;
  modalTitle: string;
  propertyName: string;
  images?: {
    id: string;
    url?: string;
  }[];
  history: any;
  location: any;
}) => {
  const [preselectedImage, setPreselectedImage] = React.useState(0);

  React.useEffect(() => {
    const modalParams = parseModalQuery({ location, catchErrors: false });

    const photo = modalParams[propertyName];
    const index = images?.findIndex((image) => image.id === photo) ?? 0;

    setPreselectedImage(index);
  }, [location.search, images, propertyName, location]);

  const prevId =
    images != null
      ? images[preselectedImage - 1]?.id ?? images[images.length - 1]?.id
      : null;

  const nextId =
    images != null ? images[preselectedImage + 1]?.id ?? images[0]?.id : null;

  const next = () => {
    const currentQuery = qs.parse(location.search);
    const modalParams = parseModalQuery({ location, catchErrors: false });

    const updatedQuery = {
      ...modalParams,
      [propertyName]: nextId,
    };

    history.push({
      ...location,
      search: qs.stringify({
        ...currentQuery,
        modal: JSON.stringify(updatedQuery),
      }),
    });
  };

  const prev = () => {
    const currentQuery = qs.parse(location.search);
    const modalParams = parseModalQuery({ location, catchErrors: false });

    const updatedQuery = {
      ...modalParams,
      [propertyName]: prevId,
    };

    history.push({
      ...location,
      search: qs.stringify({
        ...currentQuery,
        modal: JSON.stringify(updatedQuery),
      }),
    });
  };

  return (
    <Modal backgroundColor="black" outline="none">
      <div className="container">
        <div
          style={{
            position: 'relative',
            width: '100%',
            minWidth: 1000,
            margin: '0 auto',
          }}
        >
          {images == null ? (
            <LoadingSpinnerCentered />
          ) : (
            <div className="card my-4">
              <div className="card-header">
                <h4 className="mb-0">{modalTitle}</h4>
              </div>
              <div
                className="card-body d-flex flex-row justify-content-center align-items-center"
                style={{
                  paddingTop: 32,
                  paddingBottom: 24,
                }}
              >
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ width: '60px', height: '60px', marginRight: 36 }}
                >
                  {preselectedImage !== 0 && (
                    <button onClick={prev} className="btn btn-link">
                      <img
                        src={leftArrow}
                        style={{
                          width: '50px',
                          height: '50px',
                        }}
                        alt="left"
                      />
                    </button>
                  )}
                </div>

                {images != null && (
                  <img
                    style={{
                      maxWidth: '512px',
                      height: 'auto',
                      maxHeight: '80vh',
                      zIndex: 10000,
                    }}
                    src={images[preselectedImage]?.url}
                    alt=""
                  />
                )}

                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ width: '60px', height: '60px', marginLeft: 36 }}
                >
                  {preselectedImage !== images?.length - 1 && (
                    <button onClick={next} className="btn btn-link">
                      <img
                        src={rightArrow}
                        style={{
                          width: '50px',
                          height: '50px',
                        }}
                        alt="right"
                      />
                    </button>
                  )}
                </div>
              </div>
              <div
                className="d-flex justify-content-end"
                style={{ padding: '16px 24px 24px 17px' }}
              >
                <button className="btn btn-secondary" onClick={onCloseRequest}>
                  Close
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

const SubmissionApprovalModal = ({
  onCloseRequest,
  onSaveRequest,
  children,
}: {
  onCloseRequest: () => void;
  onSaveRequest: () => void;
  children: React.ReactNode;
}) => {
  return (
    <Modal backgroundColor="black" outline="none">
      <div className="container">
        <div
          style={{
            position: 'relative',
            width: '100%',
            minWidth: 1000,
            margin: '0 auto',
          }}
        >
          <div className="card my-4">
            <div className="card-header">
              <h4 className="mb-0">Images to review</h4>
            </div>
            <div
              className="card-body d-flex flex-row justify-content-center align-items-center"
              style={{
                paddingTop: 32,
                paddingBottom: 24,
              }}
            >
              {children}
            </div>
            <div
              className="d-flex justify-content-between"
              style={{ padding: '16px 24px 24px 17px' }}
            >
              <button className="btn btn-secondary" onClick={onCloseRequest}>
                Close
              </button>

              <button
                className="btn btn-primary"
                onClick={() => onSaveRequest()}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export const HeadshotSubmissionDetailsRoute = ({
  match,
  session,
  session: { token },
  location,
  history,
}: {
  match;
  session: { token: string; uid: string };
  history: RouteComponentProps['history'];
  location: RouteComponentProps['location'];
}) => {
  const {
    params: { submissionId },
  } = match;

  const [feedback, setFeedback] = React.useState<string>('');

  const submissionQuery = useQuery<
    {
      aiPortraitsJobSubmissionById?: HeadshotSubmission;
    },
    { submissionId: string; first?: number }
  >(
    gql`
      query AiPortraitJobQuerySubmissionJobImages(
        $submissionId: ID!
        $first: Int
      ) {
        aiPortraitsJobSubmissionById(id: $submissionId) {
          id
          createdAt
          reviewedAt
          status
          submissionImages {
            edges {
              id
              feedback
              status
              image {
                id
                large {
                  url
                }
                fileName
                downloadUrl
              }
            }
          }

          aiPortraitJob {
            id
            createdAt
            status
            subjectName
            editorEmail
            deadlineDate
            submissions {
              edges {
                id
              }
            }
            items(first: $first) {
              edges {
                id
                croppedImage {
                  id
                  downloadUrl
                }
              }
            }
            generatedImages {
              edges {
                id
                finalImage {
                  id
                  large {
                    url
                  }
                  downloadUrl
                }
              }
            }
            styleData {
              style
              backgroundFamily
              styles
              backgroundFamilies
            }
            headshotPackage {
              id
              displayName
              numberOfImages
            }
          }
        }
      }
    `,
    {
      variables: {
        first: 300,
        submissionId,
      },
      skip: submissionId == null,
    }
  );

  const [sendToEditor, sendToEditorMutation] = useMutation<
    {
      aiPortraitJobSendBackToEditorAsAdmin: {
        success: boolean;
      };
    },
    {
      aiPortraitJobSubmissionId: string;
    }
  >(
    gql`
      mutation AiPortraitSendToEditor($aiPortraitJobSubmissionId: ID!) {
        aiPortraitJobSendBackToEditorAsAdmin(
          input: { aiPortraitJobSubmissionId: $aiPortraitJobSubmissionId }
        ) {
          success
        }
      }
    `
  );

  const submission = submissionQuery.data?.aiPortraitsJobSubmissionById;

  const job = submission?.aiPortraitJob;

  const croppedTrainingImages = job?.items?.edges;

  const generatedImages = job?.generatedImages?.edges;

  const jobStyle = job?.styleData?.style;

  const jobBackground = job?.styleData?.backgroundFamily;

  const portraitsJobStyles = job?.styleData?.styles?.map(
    (style) => parseStyleNaming[style]
  );

  const portraitsJobBackgrounds = job?.styleData?.backgroundFamilies?.map(
    (background) => parseStyleNaming[background]
  );

  const jobPackage = job?.headshotPackage?.displayName;

  const submissionImages = submission?.submissionImages?.edges;

  const submissions = job?.submissions?.edges ?? [];

  const reverseSubmissions = [...submissions].reverse();

  const canSubmit = submissionImages?.every(
    (submission) => submission.status === 'approved'
  );

  const canReturn = submissionImages?.every(
    (submission) => submission.status !== 'pending'
  );

  const hasFeedback = submissionImages
    ?.filter((submission) => submission.status === 'rejected')
    ?.every(
      (submission) => submission.feedback != null && submission.feedback !== ''
    );

  const { photo, trainingPhoto, generatedPhoto, ...cleanedSearch } = qs.parse(
    location.search
  );

  const formattedImagesData =
    submissionImages?.map((media) => {
      const {
        image: { large, fileName },
        feedback,
        status,
        id,
      } = media;

      return {
        id,
        media: id,
        fileName,
        feedback,
        status,
        largeUrl: large?.url,
        width: '100%',
        height: '100%',
      };
    }) ?? [];

  const currentImageIndex = formattedImagesData.findIndex(
    ({ id }) => id === photo
  );

  const [saveStatus, saveStatusMutation] = useMutation<
    { success: boolean },
    {
      aiPortraitJobSubmissionMediaId: string;
      status: string;
    }
  >(
    gql`
      mutation headshotSubmissionMediaSaveStatus(
        $aiPortraitJobSubmissionMediaId: ID!
        $status: AiPortraitJobSubmissionMediaSetStatusEnum!
      ) {
        aiPortraitJobSubmissionMediaSetStatus(
          input: {
            aiPortraitJobSubmissionMediaId: $aiPortraitJobSubmissionMediaId
            status: $status
          }
        ) {
          success
        }
      }
    `
  );

  const [saveFeedback, saveFeedbackMutation] = useMutation<
    { success: boolean },
    {
      aiPortraitJobSubmissionMediaId: string;
      feedback: string;
    }
  >(
    gql`
      mutation headshotSubmissionMediaSaveFeedback(
        $aiPortraitJobSubmissionMediaId: ID!
        $feedback: String
      ) {
        aiPortraitJobSubmissionMediaSetFeedback(
          input: {
            aiPortraitJobSubmissionMediaId: $aiPortraitJobSubmissionMediaId
            feedback: $feedback
          }
        ) {
          success
        }
      }
    `
  );

  const numberOfImages = job?.headshotPackage?.numberOfImages ?? 6;

  const approvedImages =
    submissionImages?.filter((sub) => sub?.status === 'approved')?.length ?? 0;

  const submitDisabled =
    canSubmit !== true ||
    submission?.status === 'reviewed' ||
    job?.status === 'completed' ||
    sendToEditorMutation.loading === true ||
    submissionQuery.loading === true;

  return (
    <div>
      <h4>
        {submissions != null &&
          `AI Headshot gallery submission ${
            reverseSubmissions.findIndex((s) => s.id === submissionId) + 1
          } `}
      </h4>
      {job?.id != null && (
        <div style={{ display: 'flex' }}>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            to={`/ai-portrait-job/${submission?.aiPortraitJob?.id}`}
          >
            <i className="fa fa-external-link" aria-hidden="true" />
          </Link>
          <CopyIdField id={job?.id} />
        </div>
      )}

      {submission != null && (
        <div> Submission status: {submission?.status}</div>
      )}

      {submission != null && <div> Job status: {job?.status}</div>}

      <div className="grid justify-content-start mt-4" style={{ margin: 0 }}>
        <div className="card">
          <div className="title">Training Images</div>

          <div className="img-container">
            {croppedTrainingImages?.map((media) => (
              <div
                style={{
                  position: 'relative',
                  height: 200,
                  width: 200,
                  marginRight: 5,
                  marginBottom: 5,
                }}
                key={media.id}
                className="image-with-prompt"
              >
                <Link
                  key={media.id}
                  to={locationForModal({
                    location,
                    modal: {
                      modalName: 'trainingPhoto',
                      trainingPhoto: media.id,
                    },
                  })}
                >
                  <img
                    src={media.croppedImage.downloadUrl}
                    alt="portfolio"
                    className="img-fluid"
                  />
                </Link>
              </div>
            ))}
          </div>
        </div>

        <div className="card">
          <div className="title">Generated Images</div>

          <div className="img-container">
            {generatedImages?.map((media) => (
              <div
                style={{
                  position: 'relative',
                  height: 200,
                  width: 200,
                  marginRight: 5,
                  marginBottom: 5,
                }}
                key={media.id}
                className="image-with-prompt"
              >
                <div className="overlay">
                  <div className="buttons">
                    <a
                      download={`${media.id}.jpg`}
                      href={media?.finalImage?.downloadUrl}
                      rel={'noreferrer'}
                      title="SampleName"
                    >
                      <button
                        className={`tw-bg-gray-500 hover:tw-bg-gray-700 tw-text-white tw-w-40 tw-h-14 tw-mt-2`}
                      >
                        Download
                      </button>
                    </a>

                    <Link
                      key={media.id}
                      to={locationForModal({
                        location,
                        modal: {
                          modalName: 'generatedPhoto',
                          generatedPhoto: media.id,
                        },
                      })}
                    >
                      <button
                        className={`tw-bg-gray-500 hover:tw-bg-gray-700 tw-text-white tw-w-40 tw-h-14 tw-mt-2`}
                      >
                        View
                      </button>
                    </Link>
                  </div>
                </div>

                <img
                  src={media.finalImage?.large?.url}
                  alt="portfolio"
                  className="img-fluid"
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="card mt-4">
        <div className="title">Delivery guidelines</div>
        <div
          style={{
            display: 'flex',
            gap: 13,
            marginBottom: '8px',
            marginTop: '16px',
            marginLeft: '16px',
          }}
        >
          <img src={packageIcon} alt="Style" />
          <p
            style={{
              margin: 0,
              color: COLOR_PALLETTE.PRIMARY,
              fontSize: 14,
            }}
          >
            <span>{jobPackage}</span>
          </p>
        </div>

        <div
          style={{
            display: 'flex',
            gap: 13,
            marginBottom: '8px',
            marginLeft: '16px',
          }}
        >
          <img src={styleIcon} alt="Style" />
          <p
            style={{
              margin: 0,
              color: COLOR_PALLETTE.PRIMARY,
              fontSize: 14,
            }}
          >
            {portraitsJobStyles != null && portraitsJobStyles?.length > 0 ? (
              <span>{portraitsJobStyles?.join(', ')} styles</span>
            ) : (
              <span>
                {jobStyle != null && `${parseStyleNaming[jobStyle]} style`}
              </span>
            )}
          </p>
        </div>

        <div
          style={{
            display: 'flex',
            gap: 13,
            marginBottom: '24px',
            marginLeft: '16px',
          }}
        >
          <img src={backgroundIcon} alt="Style" />
          <p
            style={{
              margin: 0,
              color: COLOR_PALLETTE.PRIMARY,
              fontSize: 14,
            }}
          >
            {portraitsJobBackgrounds != null &&
            portraitsJobBackgrounds?.length > 0 ? (
              <span>{portraitsJobBackgrounds?.join(', ')} backgrounds</span>
            ) : (
              <span>
                {jobBackground != null &&
                  `${parseStyleNaming[jobBackground]} background`}
              </span>
            )}
          </p>
        </div>
      </div>

      <div className="card mt-4 submissions-parent">
        <div className="title">Images to review</div>
        <div className="title total">
          QC-Passed Images {approvedImages}/{numberOfImages}{' '}
        </div>
        <div className="submissions-container">
          {formattedImagesData?.map((media) => (
            <SubmissionDetails
              key={media.id}
              media={media}
              loading={submissionQuery.loading}
              reload={submissionQuery.refetch}
              location={location}
              saveStatus={saveStatus}
              saveStatusMutation={saveStatusMutation}
              saveFeedback={saveFeedback}
              saveFeedbackMutation={saveFeedbackMutation}
            />
          ))}
        </div>
      </div>

      {photo && (
        <SubmissionApprovalModal
          onCloseRequest={async () => {
            await submissionQuery.refetch();
            history.push({
              ...location,
              search: qs.stringify(cleanedSearch),
            });
          }}
          onSaveRequest={async () => {
            await saveFeedback({
              variables: {
                aiPortraitJobSubmissionMediaId:
                  formattedImagesData[currentImageIndex].id,
                feedback,
              },
            });
            await submissionQuery.refetch();
            history.push({
              ...location,
              search: qs.stringify(cleanedSearch),
            });
          }}
        >
          <SubmissionDetails
            media={formattedImagesData[currentImageIndex]}
            loading={submissionQuery.loading}
            reload={submissionQuery.refetch}
            location={location}
            isModalDisplay={true}
            saveStatus={saveStatus}
            saveStatusMutation={saveStatusMutation}
            saveFeedback={saveFeedback}
            setFeedback={setFeedback}
            saveFeedbackMutation={saveFeedbackMutation}
          />
        </SubmissionApprovalModal>
      )}

      <div style={{ height: 50 }} />

      <div
        className="bg-white p-3 d-flex justify-content-end align-items-center"
        style={{
          position: 'fixed',
          bottom: 0,
          right: 0,
          left: 0,
          marginLeft: 200,
          boxShadow: '0 0 1.625em rgba(0,0,0,.05)',
        }}
      >
        <button
          onClick={() =>
            history.push(`/headshot-quality-control/${submissions[0]?.id}`)
          }
          className="btn btn-outline-primary mr-3"
        >
          Go to latest submission
        </button>

        <select
          className="form-control"
          style={{ width: 200 }}
          value={submissionId}
          onChange={({ target: { value } }) =>
            history.push(`/headshot-quality-control/${value}`)
          }
        >
          {submissions?.map((submission, index) => (
            <option key={submission.id} value={submission.id}>
              Submission {(job?.submissions?.edges?.length ?? 0) - index}
            </option>
          ))}
        </select>

        {submitDisabled === true ? (
          <button disabled={true} className="btn btn-primary mr-3 ml-3">
            Send to client
          </button>
        ) : (
          <Link
            className="btn btn-primary mr-3 ml-3"
            to={locationForModal({
              location,
              modal: { modalName: SEND_SUBMISSION_MODAL },
            })}
          >
            Send to client
          </Link>
        )}

        <button
          disabled={
            canReturn !== true ||
            hasFeedback !== true ||
            canSubmit === true ||
            sendToEditorMutation.loading === true ||
            submissionQuery.loading === true ||
            submission?.status === 'reviewed' ||
            job?.status === 'completed'
          }
          className="btn btn-warning"
          onClick={() => {
            sendToEditor({
              variables: {
                aiPortraitJobSubmissionId: submissionId,
              },
            }).then(() => submissionQuery.refetch());
          }}
        >
          Return to editors
        </button>
      </div>

      <ModalRoute modalName={SEND_SUBMISSION_MODAL}>
        {(routeProps) => {
          return (
            <ConfirmSendToClientModal
              reload={submissionQuery.refetch}
              onDismiss={() =>
                history.push(
                  locationForModal({
                    location,
                    modal: undefined,
                  })
                )
              }
              disabled={submitDisabled}
              submissionId={submissionId}
            />
          );
        }}
      </ModalRoute>

      <ModalRoute
        modalName={`generatedPhoto`}
        render={({ history, location, modalParams }) => (
          <GalleryModal
            modalTitle="Training Images"
            propertyName="generatedPhoto"
            images={generatedImages?.map((media) => ({
              id: media.id,
              url: media.finalImage?.large?.url,
            }))}
            history={history}
            location={location}
            onCloseRequest={() =>
              history.replace(
                locationForModal({
                  location,
                  modal: undefined,
                })
              )
            }
          />
        )}
      />

      <ModalRoute
        modalName={`trainingPhoto`}
        render={({ history, location, modalParams }) => (
          <GalleryModal
            modalTitle="Training Images"
            propertyName="trainingPhoto"
            images={croppedTrainingImages?.map((media) => ({
              id: media.id,
              url: media.croppedImage.downloadUrl,
            }))}
            history={history}
            location={location}
            onCloseRequest={() =>
              history.replace(
                locationForModal({
                  location,
                  modal: undefined,
                })
              )
            }
          />
        )}
      />

      <style jsx>{`
        .grid {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 20px;
        }

        .submissions-parent {
          position: relative;
        }

        .total {
          position: absolute;
          right: 0;
          padding-right: 16px;
        }

        .image-with-prompt .overlay {
          visibility: hidden;
        }

        .image-with-prompt:hover .overlay {
          visibility: visible;
        }

        .overlay {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          backdrop-filter: blur(5px);
          background-color: rgba(0, 0, 0);
          opacity: 0.8;
          transition: opacity 0.3s ease;
        }

        .buttons {
          opacity: 1;
          text-align: center;
          z-index: 3;
        }

        .title {
          font-size: 18px;
          padding-top: 16px;
          padding-left: 16px;
          font-weight: bold;
        }

        .img-container {
          padding: 16px;
          display: flex;
          flex-wrap: wrap;
          max-height: 500px;
          overflow: scroll;
        }

        .submissions-container {
          display: flex;
          flex-wrap: wrap;
        }
      `}</style>
    </div>
  );
};
