import * as React from 'react';

export function multiline(text?: string) {
  return (text == null ? '' : text)
    .split('\n')
    .reduce(
      (acc, line, index) =>
        index === 0 ? [...acc, line] : [...acc, <br key={index} />, line],
      []
    );
}

export function ensureString(source?: string | string[] | null) {
  return (
    (source == null || typeof source === 'string' ? source : source[0]) ??
    undefined
  );
}
