import React from 'react';
import { LoadingSpinnerCentered } from '../../../../components/LoadingSpinner';
import { useFetch } from '../../../../components/useFetch';
import { apiFetch } from '../../../../utils';

export function PaymentMethodsList({
  customerId,
  session,
}: {
  customerId: string;
  session: { token: string };
}) {
  const {
    result: paymentMethods,
    isPending: paymentMethodsPending,
    reload,
  } = useFetch<{
    results: {
      token: string;
      cardType?: string;
      maskedNumber?: string;
      email?: string;
    }[];
  }>({
    urlToFetch: `/api/v2/customers/${customerId}/payment-methods`,
    session,
  });

  const deletePaymentMethod = async (token: string) => {
    if (confirm('Are you sure you want to delete this payment method?')) {
      await apiFetch(
        `/api/v2/customers/${customerId}/payment-methods/${token}`,
        {
          token: session.token,
          method: 'DELETE',
        }
      );
      await reload();
    }
  };

  return (
    <div
      className="card-body"
      style={{ maxHeight: '300px', overflowY: 'scroll' }}
    >
      <h3 className="mb-2">Customer's payment methods</h3>
      {paymentMethodsPending ? (
        <LoadingSpinnerCentered />
      ) : (
        paymentMethods?.results.map((method) => (
          <div key={method.token} className="row align-items-baseline">
            <div className="form-group col">
              <p className="text-secondary">{method.cardType || 'PayPal'}</p>
            </div>
            <div className="form-group col">
              <p className="text-secondary">
                {method.maskedNumber || method.email}
              </p>
            </div>
            <div className="form-group col">
              <button
                className="btn btn-danger mr-2"
                onClick={() => deletePaymentMethod(method.token)}
              >
                Delete
              </button>
            </div>
          </div>
        ))
      )}
    </div>
  );
}
