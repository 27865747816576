import React from 'react';
import { Link } from 'react-router-dom';

import { BOOKING_STATUS } from '../../selectors';
import { ModalRoute, locationForModal } from '../ModalRoute';
import { PutOnHold } from './PutOnHold';
export { WarningSection } from './ChangesModal';

const MODAL_NAME_PUT_ON_HOLD = 'put-on-hold';

export const OnHoldModal = (props) => (
  <>
    {BOOKING_STATUS.PAID === props.booking.status && (
      <Link
        className={`btn btn-danger ${props.disabled ? 'disabled' : ''}`}
        to={locationForModal({
          location: props.location,
          modal: { modalName: MODAL_NAME_PUT_ON_HOLD },
        })}
      >
        Put on hold
      </Link>
    )}

    <ModalRoute
      modalName={MODAL_NAME_PUT_ON_HOLD}
      render={({ history, location, modalParams }) => (
        <PutOnHold
          {...props}
          modalParams={modalParams}
          history={history}
          location={location}
          onDismiss={() =>
            history.replace(
              locationForModal({
                location,
                modal: undefined,
              })
            )
          }
        />
      )}
    />
  </>
);
