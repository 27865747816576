import React, { useMemo } from 'react';
import Dropzone from 'react-dropzone';
import qs from 'query-string';
import { Link, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { API_URL } from '../../../config';

import { apiFetch } from '../../../utils';
import { BookingStatusBadge } from '../../../components/BookingStatusBadge';
import { DeleteGalleryButton } from '../components/DeleteGalleryButton';
import {
  UploadImagePreview,
  isVideoByFileName,
  isVideo,
} from '../../../components/UploadManager';
import { ImageRowBase } from './components/ImageRowBase';

import { SubmissionsGalleryUploadRoute } from './submissions';
import { gql, useQuery } from '../../../components/ApolloClient';
import { usePromise } from '../../../components/usePromise';
import { useFetch } from '../../../components/useFetch';
import { useUploadManager } from '../../../components/useUploadManager';

const ImageRow = ({
  item: {
    isUpload,
    isVideo,

    preSignedUrlPromise,
    uploadPromise,
    mediaCreationPromise,

    onRestart,
    axiosCancelSource,

    fileSize,
    sizeUploaded,

    fileName,
    previewUrl,
    largeUrl,
    downloadUrl,
    file,
    isHero,
  },
  onDeleteFromGallery,
  onSetAsHero,
}) => (
  <ImageRowBase
    isUpload={isUpload}
    sizeUploaded={sizeUploaded}
    fileSize={fileSize}
    preSignedUrlPromise={preSignedUrlPromise}
    uploadPromise={uploadPromise}
    mediaCreationPromise={mediaCreationPromise}
  >
    {({
      status,
      progress,

      preSignedUrlReady,
      uploadError,
      uploadPending,
    }) => (
      <tr>
        <td>
          {isVideo ? null : (
            <button
              className="btn btn-link text-warning pt-0"
              onClick={onSetAsHero}
            >
              {isHero ? (
                <i className="fa fa-star" aria-hidden="true" />
              ) : (
                <i className="fa fa-star-o" aria-hidden="true" />
              )}
            </button>
          )}
        </td>

        <td>
          {isVideo ? (
            <video width="300" height="200" controls>
              <source src={downloadUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : isUpload ? (
            <UploadImagePreview
              file={file}
              alt="preview"
              width="300"
              height="200"
              style={{ objectFit: 'contain' }}
            />
          ) : (
            <a href={largeUrl} target="_blank" rel="noopener noreferrer">
              <img
                src={previewUrl}
                alt="preview"
                width="300"
                height="200"
                style={{ objectFit: 'contain' }}
              />
            </a>
          )}
        </td>

        <td>{fileName}</td>

        <td>
          {status}{' '}
          <span
            style={{
              width: '5rem',
              textAlign: 'right',
              fontFamily: 'Arial',
            }}
          >
            {progress}
          </span>
        </td>

        <td className="text-right">
          {uploadError ? (
            <button className="btn btn-link btn-sm py-0" onClick={onRestart}>
              Restart
            </button>
          ) : null}

          {preSignedUrlReady && uploadPending && (
            <button
              className="btn btn-link text-danger btn-sm py-0"
              onClick={() => axiosCancelSource.cancel()}
            >
              Cancel
            </button>
          )}

          {!isUpload && (
            <button
              className="btn btn-link text-danger btn-sm py-0"
              onClick={() => {
                if (
                  window.confirm(
                    `Do you want to remove this ${isVideo ? 'video' : 'image'}?`
                  )
                ) {
                  return onDeleteFromGallery({ isVideo });
                }
              }}
            >
              Delete
            </button>
          )}
        </td>
      </tr>
    )}
  </ImageRowBase>
);

function merger({ bookingGallery, uploadsList }) {
  return [
    ...bookingGallery.map(
      ({
        media,
        gallerythumb_url,
        lightbox_url,
        original_url,
        download_url,
        file_name: fileName,
        hero,
      }) => ({
        uid: media,
        fileName,
        previewUrl: gallerythumb_url,
        largeUrl: original_url || lightbox_url,
        downloadUrl: download_url,
        isUpload: false,
        isHero: hero,
        isVideo: isVideoByFileName(fileName),
      })
    ),
    ...uploadsList.map(({ clientId, previewUrl, fileName, file, ...rest }) => ({
      ...rest,
      uid: clientId,
      file,
      fileName,
      isUpload: true,
      isVideo: isVideo(file),
    })),
  ];
}

const NoHoverLink = styled(Link)`
  :hover {
    text-decoration: none;
  }
`;

function NoSubmissionsGalleryUploadRoute({
  videoEnabled,
  bookingFetch,
  location,
  session,
  history,
  match: {
    params: { bookingId },
  },
}: {
  videoEnabled: boolean;
  bookingFetch: {
    isPending: boolean;
    result: { uid: string; status: string };
    reload: () => Promise<{ notified: boolean }>;
  };
  location: RouteComponentProps['location'];
  session: {
    token: string;
    uid: string;
    user?: { IAM?: string[] };
  };
  history: RouteComponentProps['history'];
  match: {
    params: { bookingId: string };
  };
}) {
  const {
    isPending: bookingPending,
    result: booking,
    reload: bookingReload,
  } = bookingFetch;

  const {
    result: { results: bookingGallery } = {},
    reload: bookingGalleryReload,
  } = useFetch<{ results: any }>({
    urlToFetch: `/api/v2/bookings/${bookingId}/${
      videoEnabled ? 'photos-and-videos' : 'photos'
    }`,
    session,
  });

  const { addFiles, uploadsList } = useUploadManager({
    token: session.token,
    onUploadReady: ({ fileName, mediaUid, remove, isVideo }) =>
      apiFetch('/api/v2/media/create-from-temp', {
        token: session.token,
        method: 'POST',
        body: JSON.stringify({
          tempMediaUid: mediaUid,
          fileName,
          isVideo,
        }),
      })
        .then(() =>
          apiFetch(
            `/api/v2/bookings/${bookingId}/${isVideo ? 'videos' : 'photos'}`,
            {
              token: session.token,
              method: 'POST',
              body: JSON.stringify({
                media_uid: mediaUid,
              }),
            }
          )
        )
        .then(() => bookingGalleryReload())
        .then(() => remove()),
  });
  const mergedMedias = useMemo(
    () =>
      merger({
        bookingGallery: bookingGallery || [],
        uploadsList,
      }),
    [bookingGallery, uploadsList]
  );
  const isMediaUploading = useMemo(
    () => mergedMedias.filter((upload) => upload.isUpload).length > 0,
    [mergedMedias]
  );

  const completeAndSendBookingPromiser = usePromise();

  const notifyBookingPromiser = usePromise();

  const contractorMode =
    session?.user?.IAM?.length === 1 &&
    session?.user?.IAM[0] === 'contractorEditingTaskQueue';

  return bookingPending && booking == null ? null : (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <h3>
          <NoHoverLink
            to={
              contractorMode
                ? `/contractor-editing-queue`
                : `/bookings/${bookingId}`
            }
          >
            <i className="fa fa-angle-left text-muted" aria-hidden="true" />{' '}
          </NoHoverLink>{' '}
          Gallery
          {(bookingGallery || null) && (
            <span> ({bookingGallery.length})</span>
          )}{' '}
          <small className="align-self-center">
            <BookingStatusBadge status={booking.status} />
          </small>
        </h3>

        <div className="d-flex">
          <Link
            className="btn btn-outline-secondary mr-3"
            to={{
              ...location,
              search: qs.stringify({
                ...qs.parse(location.search),
                submission_uid: null,
              }),
            }}
          >
            Show submissions
          </Link>

          <a
            className="btn btn-outline-primary mr-3"
            href={`${API_URL}/api/v2/bookings/${bookingId}/photos/download?access_token=${session.token}`}
          >
            Download all files
          </a>

          {booking.status === 'paid' ? (
            <button
              className="btn btn-primary"
              disabled={completeAndSendBookingPromiser.isPending}
              onClick={() => {
                if (bookingGallery.find((i) => i.hero === true) == null) {
                  return alert(
                    'Please select a Hero image before sending to client (by using the ⭐️)'
                  );
                }

                completeAndSendBookingPromiser.setPromise(
                  apiFetch(
                    `/api/v2/bookings/${bookingId}/complete-and-send-booking`,
                    { token: session.token, method: 'POST' }
                  ).then(() => bookingReload())
                );
              }}
            >
              {completeAndSendBookingPromiser.isPending
                ? 'Sending...'
                : 'Send to client'}
            </button>
          ) : booking.status === 'completed' ? (
            <button
              className="btn btn-primary"
              style={{ width: 200 }}
              disabled={
                isMediaUploading === true ||
                notifyBookingPromiser.isPending ||
                notifyBookingPromiser.result != null
              }
              onClick={() =>
                notifyBookingPromiser.setPromise(
                  apiFetch(
                    `/api/v2/admin/bookings/${bookingId}/notify-photos-uploaded`,
                    { token: session.token, method: 'POST' }
                  ).then(() => bookingReload())
                )
              }
            >
              {isMediaUploading === true
                ? 'Notify (media uploading...)'
                : notifyBookingPromiser.result
                ? 'Notified'
                : notifyBookingPromiser.isPending
                ? 'Notify...'
                : 'Notify media added'}
            </button>
          ) : null}

          <div className="p-1" />

          <DeleteGalleryButton
            session={session}
            booking={booking}
            location={location}
            onReload={() =>
              Promise.all([bookingReload(), bookingGalleryReload()])
            }
          />
        </div>
      </div>

      <div className="pt-2" />

      <Dropzone
        className="card bg-transparent mb-3"
        accept={videoEnabled ? 'image/jpeg, video/mp4' : 'image/jpeg'}
        onDrop={(acceptedFiles) => addFiles({ acceptedFiles })}
      >
        <div className="card-body text-center">
          <h5>Drop your images {videoEnabled ? ' or videos ' : ' '}</h5>

          <button className="btn btn-outline-primary mt-2">
            or click here to upload
          </button>
        </div>
      </Dropzone>

      {((bookingGallery && bookingGallery.length > 0) ||
        uploadsList.length > 0) && (
        <div className="card mb-3">
          <div className="card-body">
            <table className="table table-hover mb-0">
              <thead>
                <tr>
                  <th />
                  <th style={{ width: 50 }} />
                  <th>File name</th>
                  <th style={{ width: '20%' }}>Status</th>
                  <th style={{ width: '10%' }} />
                </tr>
              </thead>

              <tbody>
                {mergedMedias.map((item) => (
                  <ImageRow
                    key={item.uid}
                    item={item}
                    onDeleteFromGallery={({ isVideo }) =>
                      apiFetch(
                        `/api/v2/bookings/${bookingId}/${
                          isVideo ? 'videos' : 'photos'
                        }`,
                        {
                          token: session.token,
                          method: 'DELETE',
                          body: JSON.stringify({
                            media_uids: [item.uid],
                          }),
                        }
                      ).then(() => bookingGalleryReload())
                    }
                    onSetAsHero={() =>
                      apiFetch(
                        `/api/v2/bookings/${bookingId}/photos/${item.uid}/hero`,
                        { token: session.token, method: 'PUT' }
                      ).then(() => bookingGalleryReload())
                    }
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
}

export const GalleryUploadRoute = (props) => {
  const {
    match: {
      params: { bookingId },
    },
    location,
    session,
  } = props;

  const { submission_uid } = qs.parse(location.search);

  const bookingFetch = useFetch({
    urlToFetch: `/api/v2/bookings/${bookingId}`,
    session,
  });

  const { data: videoUploadData } = useQuery(
    gql`
      query VideoUploadDataGalleryUpload($bookingId: ID!) {
        bookingById(id: $bookingId) {
          id
          videoUploadData {
            id
            folderId
          }
        }
      }
    `,
    {
      variables: { bookingId: bookingId },
    }
  );

  const videoEnabled = videoUploadData?.bookingById?.videoUploadData != null;

  return submission_uid === undefined ? (
    <NoSubmissionsGalleryUploadRoute
      {...props}
      bookingFetch={bookingFetch}
      videoEnabled={videoEnabled}
    />
  ) : (
    <SubmissionsGalleryUploadRoute
      {...props}
      bookingFetch={bookingFetch}
      submissionId={submission_uid}
    />
  );
};
