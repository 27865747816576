import {
  useState,
  Component,
  Fragment,
  ChangeEvent,
  InputHTMLAttributes,
  useMemo,
} from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Promiser } from 'react-prometo';
import {
  isValidPhoneNumber,
  parsePhoneNumberWithError,
} from 'libphonenumber-js';

import { Fetcher } from '../../../../../components/NewFetcher';
import { ErrorProcessor } from '../../../../../components/ErrorProcessor';
import { Modal } from '../../../../../components/Modal';
import { ModalRoute, locationForModal } from '../ModalRoute';
import { CustomerSelectionModal } from './CustomerSelectionModal';
import { CustomerCreationModal } from './CustomerCreationModal';
import { PaymentMethodsList } from '../PaymentMethodsList';

import { checkIfCanAccess, checkIfSuperAdmin } from '../../../../../utils';

import { BOOKING_STATUS, isPartnerBooking } from '../../selectors';
import {
  MODAL_NAME_CUSTOMER_SELECTION,
  MODAL_NAME_CUSTOMER_CREATION,
} from './utils';
import { calculateKeysThatChanged } from '../../utils';
import { apiFetch } from '../../../../../utils';
import { CallMaskingLogs } from '../CallMaskingLogs';
import {
  gql,
  useMutation,
  useQuery,
} from '../../../../../components/ApolloClient';
import { PartnerBookingParticipants } from '../PartnerBookingParticipants';
import { PartnerBookingBusinessInfo } from '../PartnerBookingBusinessInfo';
import {
  SECONDS_IN_AN_HOUR,
  SECONDS_IN_A_DAY,
} from '../../../../../utils/time';
import { usePromise } from '../../../../../components/usePromise';
import { ConfirmBookingConfirmationModal } from '../../../../../components/ConfirmBookingConfirmationModal';
import { PhoneInput } from './PhoneInput';

import usFlag from '../../components/img/flags/us.svg';
import auFlag from '../../components/img/flags/au.svg';
import beFlag from '../../components/img/flags/be.svg';
import caFlag from '../../components/img/flags/ca.svg';
import chFlag from '../../components/img/flags/ch.svg';
import deFlag from '../../components/img/flags/de.svg';
import frFlag from '../../components/img/flags/fr.svg';
import gbFlag from '../../components/img/flags/gb.svg';
import ilFlag from '../../components/img/flags/il.svg';
import itFlag from '../../components/img/flags/it.svg';
import nlFlag from '../../components/img/flags/nl.svg';
import nzFlag from '../../components/img/flags/nz.svg';
import sgFlag from '../../components/img/flags/sg.svg';
import esFlag from '../../components/img/flags/es.svg';
import phFlag from '../../components/img/flags/ph.svg';

export const COUNTRIES = [
  { code: 'US', name: 'US', flag: usFlag },
  { code: 'AU', name: 'AU', flag: auFlag },
  { code: 'BE', name: 'BE', flag: beFlag },
  { code: 'CA', name: 'CA', flag: caFlag },
  { code: 'CH', name: 'CH', flag: chFlag },
  { code: 'DE', name: 'DE', flag: deFlag },
  { code: 'FR', name: 'FR', flag: frFlag },
  { code: 'GB', name: 'GB', flag: gbFlag },
  { code: 'IL', name: 'IL', flag: ilFlag },
  { code: 'IT', name: 'IT', flag: itFlag },
  { code: 'NL', name: 'NL', flag: nlFlag },
  { code: 'NZ', name: 'NZ', flag: nzFlag },
  { code: 'SG', name: 'SG', flag: sgFlag },
  { code: 'ES', name: 'ES', flag: esFlag },
  { code: 'PH', name: 'PH', flag: phFlag },
];

const MODAL_NAME_CUSTOMER_DETAILS_EDITING = 'customer_details_editing';
const MODAL_NAME_CONFIRM_BOOKING_CONFIRMATION_MODAL =
  'confirm-booking-confirmation-modal';

type CustomerDetailsEditingModalProps = {
  bookingClone: { customer_uid: string; country: string };
  session: { token: string; user?: { IAM?: string[] } };
  onDismiss: () => void;
  onUpdated: () => void;
};

type CustomerDetailsEditingModalState = {
  customerPromise: Promise<any> | null;
  customer: Record<string, string | null> | null;
  customerClone: Record<string, string | null> | null;
  mobilephone: string | null;
  mobilephone_country: string | null;
};

class CustomerDetailsEditingModal extends Component<
  CustomerDetailsEditingModalProps,
  CustomerDetailsEditingModalState
> {
  state: CustomerDetailsEditingModalState = {
    customerPromise: null,
    customer: null,
    customerClone: null,
    mobilephone: null,
    mobilephone_country: null,
  };

  componentDidMount() {
    const {
      bookingClone,
      session: { token },
    } = this.props;

    if (bookingClone.customer_uid == null) return;

    this.setState({
      customerPromise: apiFetch(
        `/api/v2/customers/${bookingClone.customer_uid}`,
        { token }
      ),
      mobilephone_country:
        this.state.customerClone?.mobilephone_country ??
        bookingClone.country ??
        '⚠️',
      mobilephone: this.state.customerClone?.mobilephone ?? '',
    });
  }

  render() {
    const {
      onDismiss,
      bookingClone,
      session,
      session: { token },
      onUpdated,
    } = this.props;

    const customerChangedKeys =
      this.state.customer && this.state.customerClone
        ? calculateKeysThatChanged(
            this.state.customer,
            this.state.customerClone
          )
        : {};
    const customerHasChanges = Object.keys(customerChangedKeys).length > 0;

    return (
      <Modal onDismiss={onDismiss}>
        <div className="card">
          <div className="card-body d-flex flex-column">
            <h3 className="text-center">Customer details</h3>

            <Promiser
              promise={this.state.customerPromise}
              onFulfilled={({ result: { user: customer } = { user: {} } }) => {
                this.setState({
                  customer,
                  customerClone: {
                    ...customer,
                  },
                });
              }}
            >
              {({ isPending: customerPending, error: customerError }) => (
                <Fragment>
                  {!customerPending && customerError != null && (
                    <div className="alert alert-danger">
                      {customerError.status === 401 ? (
                        'Unauthorized'
                      ) : (
                        <ErrorProcessor error={customerError}>
                          {({ result }) => result || 'Unknown error'}
                        </ErrorProcessor>
                      )}
                    </div>
                  )}

                  <div className="row">
                    <Input
                      disabled={customerPending}
                      label="First name"
                      isValid={
                        (this.state.customerClone || {}).firstname != null &&
                        customerChangedKeys.firstname != null
                      }
                      value={(this.state.customerClone || {}).firstname || ''}
                      onChange={({ target: { value } }) =>
                        this.setState((state) => ({
                          customerClone: {
                            ...state.customerClone,
                            firstname: value === '' ? null : value,
                          },
                        }))
                      }
                    />

                    <Input
                      disabled={customerPending}
                      label="Surname"
                      isValid={
                        (this.state.customerClone || {}).surname != null &&
                        customerChangedKeys.surname != null
                      }
                      value={(this.state.customerClone || {}).surname || ''}
                      onChange={({ target: { value } }) =>
                        this.setState((state) => ({
                          customerClone: {
                            ...state.customerClone,
                            surname: value === '' ? null : value,
                          },
                        }))
                      }
                    />
                  </div>

                  <Input
                    singleRow
                    disabled={customerPending}
                    readOnly={checkIfCanAccess(session, 'customers') !== true}
                    label="Email"
                    type="email"
                    isValid={
                      (this.state.customerClone || {}).email != null &&
                      customerChangedKeys.email != null
                    }
                    value={(this.state.customerClone || {}).email || ''}
                    onChange={({ target: { value } }) =>
                      this.setState((state) => ({
                        customerClone: {
                          ...state.customerClone,
                          email: value === '' ? null : value,
                        },
                      }))
                    }
                  />

                  <PhoneInput
                    disabled={customerPending}
                    originalPhoneData={{
                      country: this.state.mobilephone_country,
                      mobilephone: this.state.mobilephone,
                    }}
                    setCountry={(country) =>
                      this.setState(() => ({
                        mobilephone_country: country === '' ? null : country,
                      }))
                    }
                    setMobilephone={(mobilephone) =>
                      this.setState(() => ({
                        mobilephone: mobilephone === '' ? null : mobilephone,
                      }))
                    }
                    onPhoneChange={({ country, mobilephone }) => {
                      if (mobilephone == null || mobilephone === '') return;

                      this.setState((state) => ({
                        customerClone: {
                          ...state.customerClone,
                          mobilephone: mobilephone === '' ? null : mobilephone,
                          mobilephone_country: country === '' ? null : country,
                        },
                      }));
                    }}
                  />

                  <div className="row">
                    <ReadOnlyInput
                      label="UID"
                      value={(this.state.customerClone || {}).uid || ''}
                    />
                  </div>
                </Fragment>
              )}
            </Promiser>

            <div className="d-flex justify-content-between">
              <button className="btn btn-secondary" onClick={onDismiss}>
                Close
              </button>

              <button
                className="btn btn-primary"
                disabled={!customerHasChanges}
                onClick={() =>
                  this.setState({
                    customerPromise: apiFetch(
                      `/api/v2/customers/${bookingClone.customer_uid}/details`,
                      {
                        token,
                        method: 'PATCH',
                        body: JSON.stringify(
                          Object.keys(customerChangedKeys).reduce(
                            (acc, key) => ({
                              ...acc,
                              [key]: (this.state.customerClone || {})[key],
                            }),
                            {}
                          )
                        ),
                      }
                    )
                      .then(() => onUpdated())
                      .then(() => onDismiss()),
                  })
                }
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

type InputParams = InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  isValid?: boolean;
  placeholder?: string;
  readOnly?: boolean;
  singleRow?: boolean;
};

export const Input = ({
  label,
  isValid = false,
  placeholder = label,
  readOnly = false,
  singleRow = false,
  ...rest
}: InputParams) => (
  <div className={`form-group ${singleRow ? '' : 'col'}`}>
    <label>
      <small className="text-secondary">{label}</small>
    </label>

    <input
      type="text"
      className={'form-control' + (isValid === true ? ' is-valid' : '')}
      readOnly={readOnly}
      placeholder={placeholder}
      onClick={(ev) => {
        if (readOnly) {
          (ev.target as HTMLInputElement).select();
        } else if (rest.onClick != null) {
          rest.onClick(ev);
        }
      }}
      {...rest}
    />
  </div>
);

export const ReadOnlyInput = (props) => <Input {...props} readOnly />;

const TabCode = {
  END_CUSTOMER_DETAILS: 'END_CUSTOMER_DETAILS',
  PARTNER_BOOKING_PARTICIPANTS: 'PARTNER_BOOKING_PARTICIPANTS',
  PARTNER_BUSINESS_INFO: 'PARTNER_BUSINESS_INFO',
  END_CUSTOMER_PAYMENT_METHODS: 'END_CUSTOMER_PAYMENT_METHODS',
  CALL_MASKING_LOGS: 'CALL_MASKING_LOGS',
};

function secondsSlotToDayAndHour({
  start,
  end,
}: {
  start: number;
  end: number;
}) {
  const weekDayNumber = Math.floor(start / SECONDS_IN_A_DAY);
  const startHour =
    (start - weekDayNumber * SECONDS_IN_A_DAY) / SECONDS_IN_AN_HOUR;
  const endHour = (end - weekDayNumber * SECONDS_IN_A_DAY) / SECONDS_IN_AN_HOUR;

  return { weekDayNumber, startHour, endHour };
}

function hourDisplay({ hour }: { hour: number }) {
  const isPM = hour >= 12;
  const isMM = hour === 12;

  return [isPM && !isMM ? hour - 12 : hour, ':00', isPM ? 'pm' : 'am'].join('');
}

export const CustomerDetails = (props) => {
  const [selectedTab, setSelectedTab] = useState(TabCode.END_CUSTOMER_DETAILS);

  return (
    <div className="col d-flex flex-column">
      <div className="card flex-1">
        <div className="card-header">
          <ul className="nav nav-tabs card-header-tabs">
            <li className="nav-item">
              <button
                className={
                  'btn btn-link nav-link' +
                  (selectedTab !== TabCode.END_CUSTOMER_DETAILS
                    ? ''
                    : ' active')
                }
                onClick={() => setSelectedTab(TabCode.END_CUSTOMER_DETAILS)}
              >
                <strong className="mb-0">End customer</strong>
              </button>
            </li>

            {isPartnerBooking({ booking: props.bookingClone }) && (
              <li className="nav-item">
                <button
                  className={
                    'btn btn-link nav-link' +
                    (selectedTab !== TabCode.PARTNER_BOOKING_PARTICIPANTS
                      ? ''
                      : ' active')
                  }
                  onClick={() =>
                    setSelectedTab(TabCode.PARTNER_BOOKING_PARTICIPANTS)
                  }
                >
                  <strong className="mb-0">Participants</strong>
                </button>
              </li>
            )}

            {isPartnerBooking({ booking: props.bookingClone }) && (
              <li className="nav-item">
                <button
                  className={
                    'btn btn-link nav-link' +
                    (selectedTab !== TabCode.PARTNER_BUSINESS_INFO
                      ? ''
                      : ' active')
                  }
                  onClick={() => setSelectedTab(TabCode.PARTNER_BUSINESS_INFO)}
                >
                  <strong className="mb-0">Business Info</strong>
                </button>
              </li>
            )}

            <li className="nav-item">
              <button
                className={
                  'btn btn-link nav-link' +
                  (selectedTab !== TabCode.END_CUSTOMER_PAYMENT_METHODS
                    ? ''
                    : ' active')
                }
                onClick={() =>
                  setSelectedTab(TabCode.END_CUSTOMER_PAYMENT_METHODS)
                }
              >
                <strong className="mb-0">Payment methods</strong>
              </button>
            </li>

            <li className="nav-item">
              <button
                className={
                  'btn btn-link nav-link' +
                  (selectedTab !== TabCode.CALL_MASKING_LOGS ? '' : ' active')
                }
                onClick={() => setSelectedTab(TabCode.CALL_MASKING_LOGS)}
              >
                <strong className="mb-0">Call recordings</strong>
              </button>
            </li>
          </ul>
        </div>

        {TabCode.END_CUSTOMER_DETAILS === selectedTab ? (
          isPartnerBooking({ booking: props.bookingClone }) ? (
            <PartnerCustomerDetails {...props} />
          ) : (
            <NonPartnerCustomerDetails {...props} />
          )
        ) : TabCode.PARTNER_BOOKING_PARTICIPANTS === selectedTab ? (
          <PartnerBookingParticipants
            bookingId={props.bookingClone.uid}
            {...props}
          />
        ) : TabCode.PARTNER_BUSINESS_INFO === selectedTab ? (
          <PartnerBookingBusinessInfo
            bookingId={props.bookingClone.uid}
            {...props}
          />
        ) : TabCode.END_CUSTOMER_PAYMENT_METHODS === selectedTab ? (
          <PaymentMethodsList
            customerId={props.bookingClone.customer_uid}
            session={props.session}
          />
        ) : (
          <CallMaskingLogs booking={props.bookingClone} />
        )}
      </div>
    </div>
  );
};

const RequestCustomerData = ({
  bookingId,
  mutation,
  label,
  placeholder = label,
  phoneCountryFlags = false,
  onShow,
  onChange,
  onPhoneChange,
  ...rest
}: InputHTMLAttributes<HTMLInputElement> & {
  bookingId?: string;
  mutation: string;
  label: string;
  placeholder?: string;
  phoneCountryFlags?: boolean;
  onShow?: (value: string) => void;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onPhoneChange?: ({
    country,
    mobilephone,
  }: {
    country: string;
    mobilephone: string;
  }) => void;
}) => {
  const [mobilephoneCountry, setMobilephoneCountry] = useState('');
  const [mobilephone, setMobilephone] = useState<string>();

  const [requestData, { data, loading: requestDataLoading }] = useMutation<
    { [mutation: string]: string },
    { bookingId: string }
  >(
    gql`
      mutation RequestCustomerData($bookingId: String!) {
        ${mutation}(bookingId: $bookingId)
      }
    `,
    {
      onCompleted(data) {
        if (data == null || onShow == null) return;
        onShow(data[mutation]);
      },
    }
  );

  const customerField = data?.[mutation];

  if (bookingId == null && phoneCountryFlags === false) {
    return onChange != null ? (
      <Input
        {...rest}
        label={label}
        placeholder={placeholder}
        value={rest.value ?? ''}
        onChange={onChange}
      />
    ) : (
      <ReadOnlyInput
        {...rest}
        label={label}
        placeholder={placeholder}
        value={customerField}
      />
    );
  }

  if (customerField == null && bookingId != null) {
    return (
      <div className="form-group col">
        <label>
          <small className="text-secondary">{label}</small>
        </label>

        <div className="input-group">
          <input
            type="text"
            className="form-control"
            readOnly={true}
            value="**********"
          />
          <div className="input-group-append">
            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={() => requestData({ variables: { bookingId } })}
            >
              <i className="mr-2 fa fa-eye" />
              Show
            </button>
          </div>
        </div>
      </div>
    );
  }

  const parsedPhoneNumber =
    customerField != null && isValidPhoneNumber(customerField)
      ? parsePhoneNumberWithError(customerField)
      : null;

  return phoneCountryFlags === true ? (
    <PhoneInput
      className="col"
      disabled={requestDataLoading}
      readOnly={onPhoneChange == null}
      originalPhoneData={{
        country:
          mobilephoneCountry === ''
            ? parsedPhoneNumber?.country ?? '⚠️'
            : mobilephoneCountry,
        mobilephone:
          mobilephone != null
            ? mobilephone
            : parsedPhoneNumber?.nationalNumber ?? customerField ?? '',
      }}
      setCountry={setMobilephoneCountry}
      setMobilephone={setMobilephone}
      onPhoneChange={onPhoneChange}
    />
  ) : onChange != null ? (
    <Input
      {...rest}
      label={label}
      placeholder={placeholder}
      value={rest.value ?? ''}
      onChange={onChange}
    />
  ) : (
    <ReadOnlyInput
      {...rest}
      label={label}
      placeholder={placeholder}
      value={customerField}
    />
  );
};

const RequestEmail = ({
  bookingId,
  placeholder,
  onShow,
  onChange,
  ...rest
}: InputHTMLAttributes<HTMLInputElement> & {
  bookingId: string;
  placeholder?: string;
  onShow?: (value: string) => void;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}) => (
  <RequestCustomerData
    {...rest}
    bookingId={bookingId}
    mutation={'bookingRequestDataCustomerEmailAsAdmin'}
    label={'Email'}
    placeholder={placeholder || 'Email'}
    onChange={onChange}
    onShow={onShow}
  />
);

const RequestMobilePhone = ({
  bookingId,
  placeholder,
  phoneCountryFlags,
  onShow,
  onChange,
  onPhoneChange,
  ...rest
}: InputHTMLAttributes<HTMLInputElement> & {
  bookingId: string;
  placeholder?: string;
  phoneCountryFlags?: boolean;
  onShow?: (value: string) => void;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onPhoneChange?: ({
    country,
    mobilephone,
  }: {
    country: string;
    mobilephone: string;
  }) => void;
}) => (
  <RequestCustomerData
    {...rest}
    bookingId={bookingId}
    mutation={'bookingRequestDataCustomerMobilePhoneAsAdmin'}
    label={'Mobile phone'}
    placeholder={placeholder || 'Mobile phone'}
    phoneCountryFlags
    onChange={onChange}
    onPhoneChange={onPhoneChange}
    onShow={onShow}
  />
);

function calcTimeSlots({
  availability,
}: {
  availability: {
    start: number;
    end: number;
  }[];
}) {
  const timeSlots = availability.map(secondsSlotToDayAndHour);

  let timeSlotsCompact: {
    weekDayStart: number;
    weekDayEnd: number;
    hourStart: number;
    hourEnd: number;
  }[] = [];
  timeSlots.forEach((slot) => {
    const existingIndex = timeSlotsCompact.findIndex(
      (slotCompact) =>
        slotCompact.hourStart === slot.startHour &&
        slotCompact.hourEnd === slot.endHour &&
        slotCompact.weekDayEnd === slot.weekDayNumber - 1
    );

    if (existingIndex !== -1) {
      timeSlotsCompact[existingIndex] = {
        ...timeSlotsCompact[existingIndex],
        weekDayEnd: slot.weekDayNumber,
      };
    } else {
      timeSlotsCompact.push({
        weekDayStart: slot.weekDayNumber,
        weekDayEnd: slot.weekDayNumber,
        hourStart: slot.startHour,
        hourEnd: slot.endHour,
      });
    }
  });

  return timeSlotsCompact;
}

const PartnerCustomerDetails = ({
  bookingClone,
  onPartnerCustomerFirstnameChange,
  onPartnerCustomerSurnameChange,
  onPartnerCustomerEmailChange,
  onPartnerCustomerMobilephoneChange,
  onPartnerCustomerCompanyChange,
  location,
  history,
}: {
  bookingClone: any;
  onPartnerCustomerFirstnameChange: (p: {
    partner_customer_firstname: string;
  }) => void;
  onPartnerCustomerSurnameChange: (p: {
    partner_customer_surname: string;
  }) => void;
  onPartnerCustomerEmailChange: (p: { partner_customer_email: string }) => void;
  onPartnerCustomerMobilephoneChange: (p: {
    partner_customer_mobilephone: string;
    partner_customer_mobilephone_country?: string;
  }) => void;
  onPartnerCustomerCompanyChange: (p: {
    partner_customer_company: string;
  }) => void;
  location: RouteComponentProps['location'];
  history: RouteComponentProps['history'];
}) => {
  const confirmationPromise = usePromise();

  const bookingPartnerInfoQuery = useQuery<
    {
      booking?: {
        uid: string;
        endCustomerConfirmedAt?: string;
        endCustomerCallAvailability?: {
          start: number;
          end: number;
        }[];
      };
    },
    {
      bookingId: string;
    }
  >(
    gql`
      query BookingPartnerInfoForCustomerDetailsSection($bookingId: ID!) {
        booking: schedulingBookingByIdAsEndCustomer(bookingId: $bookingId) {
          uid
          endCustomerConfirmedAt
          endCustomerCallAvailability {
            start
            end
          }
        }
      }
    `,
    {
      skip: bookingClone.uid == null,
      variables: { bookingId: bookingClone.uid },
    }
  );

  const callAvailability =
    bookingPartnerInfoQuery.data?.booking?.endCustomerCallAvailability;

  const callAvailabilityFormattedString = useMemo(() => {
    const dayDisplayLookup = {
      0: 'Mon',
      1: 'Tue',
      2: 'Wed',
      3: 'Thu',
      4: 'Fri',
      5: 'Sat',
      6: 'Sun',
    };

    if (callAvailability == null) return;

    const timeSlots = calcTimeSlots({ availability: callAvailability });

    const formattedTimeSlots = timeSlots.map((slot) =>
      [
        slot.weekDayStart === slot.weekDayEnd
          ? dayDisplayLookup[slot.weekDayStart]
          : `${dayDisplayLookup[slot.weekDayStart]} to ${
              dayDisplayLookup[slot.weekDayEnd]
            }`,
        ' from ',
        hourDisplay({ hour: slot.hourStart }),
        ' to ',
        hourDisplay({ hour: slot.hourEnd }),
      ].join('')
    );

    return formattedTimeSlots.join(', ');
  }, [callAvailability]);

  const endCustomerConfirmedAt =
    bookingPartnerInfoQuery.data?.booking?.endCustomerConfirmedAt;
  const bookingId = bookingPartnerInfoQuery.data?.booking?.uid;

  const [confirmShoot] = useMutation<
    { success: boolean },
    {
      bookingId: string;
      snapprAgentAssistedConfirmation?: boolean;
    }
  >(gql`
    mutation snapprAgentAssistedShootConfirmation(
      $bookingId: ID!
      $snapprAgentAssistedConfirmation: Boolean
    ) {
      snapprAgentEndCustomerConfirmShoot(
        input: {
          bookingId: $bookingId
          snapprAgentAssistedConfirmation: $snapprAgentAssistedConfirmation
        }
      ) {
        success
      }
    }
  `);

  return (
    <>
      <div className="card-body">
        <h3>End customer</h3>
        <div className="row">
          <div className="form-group col">
            <label>
              <small className="text-secondary">First name</small>
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="End customer first name"
              value={bookingClone.partner_customer_firstname || ''}
              onChange={({ target: { value } }) =>
                onPartnerCustomerFirstnameChange({
                  partner_customer_firstname: value,
                })
              }
            />
          </div>

          <div className="form-group col">
            <label>
              <small className="text-secondary">Last name</small>
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="End customer last name"
              value={bookingClone.partner_customer_surname || ''}
              onChange={({ target: { value } }) =>
                onPartnerCustomerSurnameChange({
                  partner_customer_surname: value,
                })
              }
            />
          </div>
        </div>

        <div className="row">
          <RequestEmail
            bookingId={bookingClone.uid}
            placeholder="End customer email"
            value={bookingClone.partner_customer_email}
            onShow={(value) =>
              onPartnerCustomerEmailChange({ partner_customer_email: value })
            }
            onChange={({ target: { value } }) =>
              onPartnerCustomerEmailChange({
                partner_customer_email: value,
              })
            }
          />

          <RequestMobilePhone
            type="tel"
            bookingId={bookingClone.uid}
            placeholder="End customer mobile phone"
            value={bookingClone.partner_customer_mobilephone}
            onShow={(value) =>
              onPartnerCustomerMobilephoneChange({
                partner_customer_mobilephone: value,
              })
            }
            onPhoneChange={({ country, mobilephone }) =>
              onPartnerCustomerMobilephoneChange({
                partner_customer_mobilephone: mobilephone,
                partner_customer_mobilephone_country: country,
              })
            }
            phoneCountryFlags
          />
        </div>

        <div className="row">
          <div className="form-group col">
            <label>
              <small className="text-secondary">Company name</small>
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="eg: Tacohouse"
              value={bookingClone.partner_customer_company || ''}
              onChange={({ target: { value } }) =>
                onPartnerCustomerCompanyChange({
                  partner_customer_company: value,
                })
              }
            />
          </div>

          {bookingClone.customer_role != null && (
            <div className="form-group col">
              <label>
                <small className="text-secondary">Role</small>
              </label>
              <input
                type="text"
                className="form-control"
                readOnly
                value={bookingClone.customer_role}
              />
            </div>
          )}
        </div>

        {bookingClone.schedule_booking_url != null && (
          <div className="row">
            <div className="form-group col">
              <label>
                <small className="text-secondary">
                  {{
                    paid_pending_schedule: 'Schedule URL',
                    paid_on_hold: 'On hold rescheduling URL',
                    paid: 'Rescheduling URL',
                  }[bookingClone.status] ?? ''}
                </small>
              </label>
              <input
                type="text"
                className="form-control"
                readOnly
                value={bookingClone.schedule_booking_url || ''}
                onClick={(ev) => (ev.target as HTMLInputElement).select()}
              />
            </div>
          </div>
        )}

        <div className="row">
          <div className="form-group col">
            <label className="text-secondary text-sm">
              <small>Call availability</small>
            </label>

            <input
              type="text"
              className="form-control"
              readOnly
              value={
                callAvailability != null
                  ? callAvailabilityFormattedString
                  : 'No call availability defined'
              }
            />
          </div>
        </div>

        {endCustomerConfirmedAt != null ? (
          <div className="m-0 p-2 alert alert-success">
            Booking has been confirmed by the end customer
          </div>
        ) : confirmationPromise.error != null ? (
          <div className="m-0 p-2 alert alert-danger">
            Booking confirmation failed. Please contact support.
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
            }}
          >
            <button
              type="button"
              className="btn btn-primary"
              disabled={
                bookingId == null ||
                bookingClone.status !== BOOKING_STATUS.PAID ||
                confirmationPromise.error != null ||
                confirmationPromise.isPending
              }
              onClick={() => {
                history.push(
                  locationForModal({
                    location,
                    modal: {
                      modalName: MODAL_NAME_CONFIRM_BOOKING_CONFIRMATION_MODAL,
                    },
                  })
                );
              }}
            >
              Confirm Booking
            </button>
          </div>
        )}
      </div>

      <ModalRoute
        modalName={MODAL_NAME_CONFIRM_BOOKING_CONFIRMATION_MODAL}
        render={(props) => (
          <ConfirmBookingConfirmationModal
            {...props}
            confirmBooking={() => {
              if (bookingId != null) {
                confirmationPromise.setPromise(
                  confirmShoot({
                    variables: {
                      bookingId,
                      snapprAgentAssistedConfirmation: true,
                    },
                  }).then(() => bookingPartnerInfoQuery.refetch())
                );
              }
            }}
            onDismiss={() =>
              history.replace(
                locationForModal({
                  location,
                  modal: undefined,
                })
              )
            }
          />
        )}
      />
    </>
  );
};

const NonPartnerCustomerDetails = ({
  bookingClone,
  onReload: onBookingReload,
  session,
  history,
  location,
  onChange: onCustomerChange,
  bookingChangedKeys,
}) => (
  <Fetcher
    disabled={!bookingClone.customer_uid}
    urlToFetch={`/api/v2/customers/${bookingClone.customer_uid}`}
    session={session}
  >
    {({
      isPending: customerLoading,
      result: { user: customer = {} } = {} as {
        user: {
          uid: string;
          firstname: string;
          surname: string;
        };
      },
      reload: customerReload,
    }) => (
      <>
        <div
          className={`card-body ${
            bookingChangedKeys.customer_uid ? 'border border-success' : ''
          }`}
        >
          <div className="row">
            <div className="col">
              <h3>Customer</h3>
            </div>

            <div className="col text-right">
              <Link
                className="btn btn-secondary mr-2"
                to={`/customers/${bookingClone.customer_uid}`}
              >
                See profile
              </Link>

              <Link
                className="btn btn-secondary mr-2"
                to={locationForModal({
                  location,
                  modal: { modalName: MODAL_NAME_CUSTOMER_DETAILS_EDITING },
                })}
              >
                Edit details
              </Link>

              <Link
                className="btn btn-secondary"
                to={locationForModal({
                  location,
                  modal: { modalName: MODAL_NAME_CUSTOMER_SELECTION },
                })}
              >
                Change
              </Link>
            </div>
          </div>

          <div className="row">
            <ReadOnlyInput
              label="First name"
              value={
                (bookingClone.customer_uid &&
                  !customerLoading &&
                  customer.firstname) ||
                ''
              }
            />

            <ReadOnlyInput
              label="Surname"
              value={
                (bookingClone.customer_uid &&
                  !customerLoading &&
                  customer.surname) ||
                ''
              }
            />
          </div>

          <div className="row">
            <RequestEmail bookingId={bookingClone.uid} />
            <RequestMobilePhone
              bookingId={bookingClone.uid}
              phoneCountryFlags
            />
          </div>

          <div className="row">
            <ReadOnlyInput
              label="UID"
              value={
                (bookingClone.customer_uid &&
                  !customerLoading &&
                  customer.uid) ||
                ''
              }
            />
          </div>
        </div>
        <ModalRoute
          modalName={MODAL_NAME_CUSTOMER_SELECTION}
          render={(props) => (
            <CustomerSelectionModal
              {...props}
              session={session}
              bookingClone={bookingClone}
              onCustomerChange={onCustomerChange}
              onDismiss={() =>
                history.replace(
                  locationForModal({
                    location,
                    modal: undefined,
                  })
                )
              }
            />
          )}
        />

        <ModalRoute
          modalName={MODAL_NAME_CUSTOMER_CREATION}
          render={(props) => (
            <CustomerCreationModal
              {...props}
              session={session}
              bookingClone={bookingClone}
              onCustomerChange={onCustomerChange}
              onDismiss={() =>
                history.replace(
                  locationForModal({
                    location,
                    modal: undefined,
                  })
                )
              }
            />
          )}
        />

        <ModalRoute
          modalName={MODAL_NAME_CUSTOMER_DETAILS_EDITING}
          render={(props) =>
            bookingClone == null ? null : (
              <CustomerDetailsEditingModal
                {...props}
                session={session}
                bookingClone={bookingClone}
                onCustomerChange={onCustomerChange}
                onUpdated={() =>
                  Promise.all([onBookingReload(), customerReload()])
                }
                onDismiss={() =>
                  history.replace(
                    locationForModal({
                      location,
                      modal: undefined,
                    })
                  )
                }
              />
            )
          }
        />
      </>
    )}
  </Fetcher>
);
