import React, { useState } from 'react';

import { Modal } from '../../../../../components/Modal';
import {
  gql,
  useMutation,
  useQuery,
} from '../../../../../components/ApolloClient';
import { usePromise } from '../../../../../components/usePromise';

type ProviderCancelReasonCode = {
  code: string;
  name: string;
  providerRecommendation?: {
    title: string;
    description: string;
    secondDescription?: string;
    dangerDescription?: string;
    recommendedArticles?: { title: string; url: string }[];
    inAppRedirection?: {
      title: string;
      route: string;
      buttonStyle?: string;
    }[];
  };
};

type ProviderCancellationProps = {
  booking;
  onDismiss: () => void;
  onReload: () => Promise<any>;
};

export const ProviderCancellation = ({
  booking,
  onDismiss,
  onReload,
}: ProviderCancellationProps) => {
  const recommendChangeCommit = usePromise();

  const [cancellationReason, setCancellationReason] = useState<string>('');
  const [cancellationDetail, setCancellationDetail] = useState<string>('');

  const providerCancelMetaDataQuery = useQuery<{
    providerCancellationReasonsAsAdmin: {
      providerCancel: {
        reasons: ProviderCancelReasonCode[];
      };
    };

    getJobChangeRecommendationAsAdminList?: {
      edges?: {
        id: string;
      }[];
    };
  }>(
    gql`
      query ProviderCancelSelfServiceMetaDataQueryAsAdmin(
        $bookingId: String!
        $status: [String]
      ) {
        providerCancellationReasonsAsAdmin {
          providerCancel {
            reasons {
              code
              name
            }
          }
        }

        getJobChangeRecommendationAsAdminList(
          bookingId: $bookingId
          status: $status
        ) {
          edges {
            id
          }
        }
      }
    `,
    {
      variables: {
        bookingId: booking.uid,
        status: ['pending', 'booking_provider_changed'],
      },
      skip: booking.uid == null,
    }
  );

  const hasChangeRecommendationPending =
    (
      providerCancelMetaDataQuery.data?.getJobChangeRecommendationAsAdminList
        ?.edges ?? []
    ).length > 0;

  const providerCancelMetaData =
    providerCancelMetaDataQuery.data?.providerCancellationReasonsAsAdmin
      .providerCancel.reasons;

  const [
    recommendChangesProviderCancel,
    recommendChangesProviderCancelMutation,
  ] = useMutation<
    { jobChangeRecommendationCreateAsProvider?: { success: boolean } },
    {
      bookingId: string;
      extraDetails?: string;
      reason: string;
      snapprAtFaultExtraDetails?: string;
      snapprAtFaultReason?: string;
      attachmentMediaIds?: string[];
    }
  >(gql`
    mutation SelfServiceRecommendChangesProviderCancel(
      $bookingId: ID!
      $extraDetails: String
      $reason: JobChangeRecommendationOperationDetailsCustomerAllReasons!
      $snapprAtFaultExtraDetails: String
      $snapprAtFaultReason: JobChangeRecommendationProviderCancelSnapprAtFaultCodes
      $attachmentMediaIds: [String]
    ) {
      jobChangeRecommendationCreateAsProvider(
        input: {
          jobId: $bookingId
          type: PROVIDER_NEEDS_CANCEL
          operationDetails: {
            extraDetails: $extraDetails
            reason: $reason
            snapprAtFaultExtraDetails: $snapprAtFaultExtraDetails
            snapprAtFaultReason: $snapprAtFaultReason
            attachmentMediaIds: $attachmentMediaIds
          }
        }
      ) {
        success
      }
    }
  `);

  return (
    <Modal onDismiss={onDismiss}>
      <div className="card my-4">
        <div className="card-header">
          <h4 className="mb-0">Trigger provider cancellation</h4>
        </div>

        <form
          className="card-body d-flex flex-column"
          style={{ width: '1000px', minHeight: '600px' }}
          onSubmit={(e) => {
            e.preventDefault();

            recommendChangeCommit.setPromise(
              recommendChangesProviderCancel({
                variables: {
                  bookingId: booking.uid,
                  extraDetails: cancellationDetail,
                  reason: cancellationReason,
                },
              })
                .then(() => onReload())
                .then(() => onDismiss())
            );
          }}
        >
          <div className="flex-1" style={{ position: 'relative' }}>
            <div className="card mb-3">
              <div className="card-body d-flex">
                <div className="flex-1">
                  <h5>Information required</h5>

                  <div className="form-group mt-3">
                    <label>Reason</label>

                    <select
                      className="form-control"
                      value={cancellationReason}
                      disabled={recommendChangeCommit.isPending}
                      onChange={({ target: { value } }) =>
                        setCancellationReason(value)
                      }
                    >
                      <option key="" value="">
                        Select a reason
                      </option>
                      {providerCancelMetaData != null &&
                        providerCancelMetaData.map((reason) => (
                          <option key={reason.code} value={reason.code}>
                            {reason.name}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="form-group mt-3">
                    <label htmlFor="exampleFormControlTextarea1">
                      Cancellation details
                    </label>

                    <textarea
                      onChange={(ev) => setCancellationDetail(ev.target.value)}
                      value={cancellationDetail}
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows={3}
                    />
                  </div>

                  {hasChangeRecommendationPending === true && (
                    <small className="text-danger">
                      *This booking has provider change pending, please check FD
                      ticket related to this booking
                    </small>
                  )}
                </div>
              </div>
            </div>

            {recommendChangesProviderCancelMutation.loading === true && (
              <div
                className={[
                  'd-flex flex-columns',
                  'justify-content-center align-items-center',
                ].join(' ')}
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  backgroundColor: 'rgba(255, 255, 255, 0.5)',
                }}
              >
                <span>Loading...</span>
              </div>
            )}
          </div>

          <div className="d-flex justify-content-between mt-3">
            <button className="btn btn-secondary mr-2" onClick={onDismiss}>
              Close
            </button>

            <button
              type="submit"
              className="btn btn-primary"
              disabled={
                recommendChangeCommit.isPending ||
                hasChangeRecommendationPending
              }
            >
              Confirm
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
