import React from 'react';

export const RatingInput = ({ value, setReview, inputName, disabled }) => {
  const STARTS = [...Array(5).keys()];
  return (
    <div className="d-flex flex-columns justify-content-center">
      {STARTS.map((index) => (
        <div
          className="custom-control custom-radio px-0 mx-1"
          key={`start-${index + 1}`}
        >
          <input
            type="radio"
            name={inputName}
            value={index + 1}
            id={`${inputName}-${index}`}
            className="custom-control-input"
            disabled={disabled}
            onChange={({ target }) =>
              setReview({
                target: {
                  name: inputName,
                  value: Number(target.value),
                },
              })
            }
          />

          <label
            className={`mb-0 ${disabled ? 'text-muted' : 'text-warning'}`}
            htmlFor={`${inputName}-${index}`}
          >
            <i
              className={`fa ${value > index ? 'fa-star' : 'fa-star-o'} `}
              aria-hidden="true"
              key={index}
            />
          </label>
        </div>
      ))}
    </div>
  );
};
