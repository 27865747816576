import * as React from 'react';
import ReachToolTip, { TooltipProps } from '@reach/tooltip';

const getCenteredPosition = (triggerRect, tooltipRect) => {
  const triggerCenter = triggerRect.left + triggerRect.width / 2;
  const left = triggerCenter - tooltipRect.width / 2;
  const maxLeft = window.innerWidth - tooltipRect.width - 2;
  return {
    left: Math.min(Math.max(2, left), maxLeft) + window.scrollX,
    top: triggerRect.bottom + 8 + window.scrollY,
  };
};

export function Tooltip({
  centered,
  maxWidth,
  ...rest
}: TooltipProps & { style?; centered?: boolean; maxWidth?: number }) {
  return (
    <>
      <ReachToolTip
        {...rest}
        position={centered ? getCenteredPosition : undefined}
        style={{
          ...(maxWidth != null ? { maxWidth } : {}),
        }}
      />

      <style jsx global>{`
        :root {
          --reach-tooltip: 1;
        }

        [data-reach-tooltip] {
          z-index: 1;
          pointer-events: none;
          position: absolute;
          padding: 0.25em 0.5em;
          box-shadow: 2px 2px 10px hsla(0, 0%, 0%, 0.1);
          font-size: 85%;
          background: #f0f0f0;
          color: #444;
          border: solid 1px #ccc;
        }
      `}</style>
    </>
  );
}
