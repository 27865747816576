import { Link, RouteComponentProps } from 'react-router-dom';
import qs from 'query-string';

import { badgeBgColorStatus, qualityControlStatus } from './utils';
import * as React from 'react';
import { gql, useMutation } from '../../components/ApolloClient';
import {
  QualityControlMediaCriteriaStatusEnum,
  QualityControlMediaStatusEnum,
  QualityControlStatusEnum,
  QUALITY_CONTROL_DEFAULT_NAME,
} from './types';
import { LoadingSpinnerCentered } from '../../components/LoadingSpinner';
import approvedIcon from '../../styles/img/accepted.svg';
import rejectedIcon from '../../styles/img/rejected.svg';
import { useQualityControlByIdQuery } from './components/useQualityControlByIdQuery';

const TabCode = {
  ALL: 'All',
  ACCEPTED: 'Accepted',
  REJECTED: 'Rejected',
};

const getTabStatus = ({ selectedTab }: { selectedTab: string }) => {
  return (
    {
      ACCEPTED: QualityControlMediaStatusEnum.ACCEPTED,
      REJECTED: QualityControlMediaStatusEnum.REJECTED,
    }[selectedTab] ?? undefined
  );
};

export function QualityControlGallery({
  match,

  location,
  history,
}: {
  match: {
    params: { qualityControlJobId: string };
  };

  location: RouteComponentProps['location'];
  history: RouteComponentProps['history'];
}) {
  const {
    params: { qualityControlJobId },
  } = match;

  const selectedTab =
    (qs.parse(location.search).selectedTab as string) ?? TabCode.ALL;

  const qualityControlQuery = useQualityControlByIdQuery({
    qualityControlJobId,
    mediaStatus: getTabStatus({ selectedTab }),
  });

  const qualityControlJob = qualityControlQuery.data?.qualityControlByIdAsAdmin;

  const qualityControlMediasTotal =
    (qualityControlJob?.totalImagesAccepted ?? 0) +
    (qualityControlJob?.totalImagesRejected ?? 0) +
    (qualityControlJob?.totalImagesPending ?? 0);

  const qualityControlCriteriaList = qualityControlJob?.criteria?.edges;

  const qualityControlJobStatus = qualityControlJob?.status;

  const imageStatusCount = {
    ACCEPTED: qualityControlJob?.totalImagesAccepted,
    REJECTED: qualityControlJob?.totalImagesRejected,
    ALL: qualityControlMediasTotal,
  };

  const [completeQualityControl, completeQualityControlMutation] = useMutation<
    {
      qualityControl: {
        id: string;
        status: QualityControlStatusEnum;
      };
    },
    {
      qualityControlId: string;
    }
  >(
    gql`
      mutation QualityControlCompleteAtMediaGallery($qualityControlId: ID!) {
        qualityControlComplete(input: { qualityControlId: $qualityControlId }) {
          qualityControl {
            id
            status
          }
        }
      }
    `
  );

  const qualityControlImages = qualityControlJob?.medias?.edges
    ?.filter((image) => image.id != null && image.large?.url != null)
    .map((media, index) => {
      const criteria = media.criteria?.edges?.map((criteria) => ({
        id: criteria.id,
        criteriaId: criteria.criteriaId,
        criteriaTotal: qualityControlCriteriaList?.length,
        criteriaDescription: qualityControlCriteriaList?.find(
          (qcCriteria) => qcCriteria.id === criteria.criteriaId
        )?.description,
        criteriaStatus: criteria.status,
        criteriaPosition: qualityControlCriteriaList?.findIndex(
          (qcCriteria) => qcCriteria.id === criteria.criteriaId
        ),
      }));

      return {
        imageId: media.id,
        imageStatus: media.status,
        imageUrl: media.large?.url,
        imageName: media.fileName,
        imagePosition: index,
        // TODO: format in a different way
        imageType: media.type,
        imageWidth: media.width,
        imageHeight: media.height,
        fileSizeInBytes: media.fileSize,
        criteria: criteria,
      };
    })
    .flat();

  return qualityControlQuery != null &&
    qualityControlJob != null &&
    qualityControlJobStatus != null ? (
    qualityControlJob ? (
      <>
        <div
          style={{
            display: 'flex',
            fontFamily: 'Nunito Sans',
            flexDirection: 'column',
          }}
        >
          <div className="tw-flex">
            <h4
              className="tw-font-bold tw-text-2xl"
              style={{
                color: '#1F6FFF',
              }}
            >
              <Link to="/quality-control-view">Workflows QC Queue </Link>
              <span style={{ color: '#141B24' }}>
                / {qualityControlJob.partner.name}
              </span>
              <span style={{ marginLeft: 16 }} />
              <span
                className="badge badge-primary text-uppercase"
                style={badgeBgColorStatus(qualityControlJob.status)}
              >
                {qualityControlStatus[qualityControlJob.status]}
              </span>
            </h4>
          </div>

          <div className="tw-mt-5 tw-bg-white">
            <div
              className="tw-px-8 tw-py-7 tw-justify-between tw-flex tw-flex-row tw-text-[#454F5C]"
              style={{
                borderBottom: '1px solid #E8EDF5',
              }}
            >
              <div className="tw-text-[20px] tw-font-bold">
                {qualityControlJob.name ?? QUALITY_CONTROL_DEFAULT_NAME}
              </div>
              {qualityControlJobStatus ===
              QualityControlStatusEnum.completed ? (
                <div>
                  <div
                    style={{
                      fontSize: 16,
                      justifyContent: 'flex-end',
                    }}
                  >
                    {qualityControlJob.medias?.total ?? 0}/
                    {qualityControlJob.medias?.total} evaluated
                  </div>
                </div>
              ) : null}
            </div>

            <div className="tw-my-7 tw-px-8 tw-flex-col">
              <div className="tw-flex tw-flex-row tw-justify-between">
                <ul className="tw-flex tw-border tw-border-[#E9EDF5] tw-border-solid tw-p-0 tw-list-none tw-text-center">
                  {Object.keys(TabCode).map((tab, index) => (
                    <li
                      className={`tw-text-[#71767E] tw-border-[#E9EDF5] tw-border-0 tw-border-r-[1px] tw-border-solid last:tw-border-r-0`}
                      key={index}
                    >
                      <Link
                        className={
                          'tw-inline-block tw-py-2 tw-px-3 tw-text-inherit tw-ring-black ' +
                          (selectedTab !== tab ? '' : 'tw-text-black tw-ring-1')
                        }
                        to={{
                          ...location,
                          search: qs.stringify({
                            ...qs.parse(location.search),
                            selectedTab: tab,
                            q: undefined,
                          }),
                        }}
                      >
                        <strong className="tw-mb-0">
                          {`${TabCode[tab]} (${imageStatusCount[tab]})`}
                        </strong>
                      </Link>
                    </li>
                  ))}
                </ul>

                <button
                  disabled={
                    qualityControlJobStatus ===
                      QualityControlStatusEnum.completed ||
                    completeQualityControlMutation.loading
                  }
                  type="button"
                  className="btn "
                  style={{
                    width: 260,
                    height: 40,
                    color: '#2FB67D',
                    borderColor: '#2FB67D',
                  }}
                  onClick={async () => {
                    await completeQualityControl({
                      variables: { qualityControlId: qualityControlJob.id },
                    }).then(() => {
                      //TODO: handle errors

                      history.replace(`/quality-control-view`);
                    });
                  }}
                >
                  Submit job
                </button>
              </div>

              {qualityControlImages != null ? (
                <div className="tw-my-7 tw-px-8 tw-flex tw-justify-center">
                  <ul className="tw-grid tw-max-w-fit tw-grid-cols-3 tw-gap-2 tw-list-none tw-p-0">
                    {qualityControlImages.map((image) => (
                      <li
                        key={image.imageId}
                        className="tw-relative tw-group hover:tw-cursor-pointer"
                      >
                        <Link
                          to={`/quality-control-view/${qualityControlJobId}/details?id=${image?.criteria?.[0].id}`}
                        >
                          <div className="group-hover:tw-bg-black tw-hidden group-hover:tw-block tw-z-10 group-hover:tw-bg-opacity-70 tw-absolute tw-top-0 tw-left-0 tw-h-full tw-w-full">
                            <div className="tw-flex">
                              <ul className="tw-absolute tw-bottom-0 tw-left-0 tw-p-4 tw-text-sm tw-font-bold tw-text-white">
                                {image.criteria?.map((criteria) => (
                                  <div
                                    key={criteria.criteriaId}
                                    className="tw-flex tw-items-center tw-gap-1 tw-pt-2"
                                  >
                                    <div
                                      style={{
                                        borderRadius: '50%',
                                        width: 16,
                                        height: 16,
                                        background: 'white',
                                        display: 'inline-flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        border: 'none',
                                        padding: 3,
                                      }}
                                    >
                                      <img
                                        style={{
                                          display: 'block',
                                          height: '100%',
                                          width: '100%',
                                          objectFit: 'cover',
                                        }}
                                        src={
                                          criteria.criteriaStatus ===
                                          QualityControlMediaCriteriaStatusEnum.ACCEPTED
                                            ? approvedIcon
                                            : rejectedIcon
                                        }
                                        alt="avatar"
                                      />
                                    </div>

                                    <li className="tw-text-sm tw-list-none">
                                      {criteria.criteriaDescription}
                                    </li>
                                  </div>
                                ))}
                              </ul>
                            </div>
                          </div>
                          <div className="tw-relative">
                            <img
                              className="tw-h-[300px] tw-w-[350px] tw-object-cover"
                              src={image?.imageUrl}
                              alt=""
                            />
                            <span
                              className={`tw-absolute tw-top-3 tw-left-3 tw-p-1 tw-text-center tw-text-[10px] tw-font-bold ${
                                image.imageStatus ===
                                QualityControlMediaStatusEnum.ACCEPTED
                                  ? 'tw-bg-snappr-green'
                                  : image.imageStatus ===
                                    QualityControlMediaStatusEnum.REJECTED
                                  ? ' tw-bg-snappr-red'
                                  : 'tw-bg-snappr-light-blue'
                              } tw-text-white tw-rounded-sm tw-leading-none`}
                            >
                              {image.imageStatus}
                            </span>
                          </div>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </>
    ) : (
      // @TODO: improve
      <div>ERROR</div>
    )
  ) : (
    <LoadingSpinnerCentered />
  );
}
