import * as React from 'react';
import {
  createEditor,
  Descendant,
  Editor,
  Transforms,
  Element as SlateElement,
} from 'slate';
import { withHistory } from 'slate-history';
import {
  Editable,
  Slate,
  withReact,
  useSlate,
  useSlateStatic,
  ReactEditor,
  useSelected,
  useFocused,
} from 'slate-react';
import isHotkey from 'is-hotkey';
import { ImageElement } from './types';
import { Link, RouteComponentProps } from 'react-router-dom';
import { locationForModal, ModalRoute } from '../ModalRoute';
import { ProgressIndicator, useUploadManager } from '../useUploadManager';
import { Modal } from '../Modal';
import Dropzone from 'react-dropzone';
import { apiFetch } from '../../utils';
import { APP_URL } from '../../config';

import doListIcon from '../../components/img/do-list-icon.svg';
import dontListIcon from '../../components/img/dont-list-icon.svg';
import bulletListIcon from '../../components/img/bullet-list-icon.svg';
import { COLOR_PALLETTE } from '../../constants';

const UPLOAD_IMAGE_MODAL = 'upload_image_modal';

function Button({
  children,
  active,
  ...rest
}: {
  children: React.ReactNode;
  active?: boolean;
  className?: string | undefined;
  onClick?;
} & Pick<React.ButtonHTMLAttributes<HTMLButtonElement>, 'onMouseDown'>) {
  return (
    <button
      style={{
        width: 32,
        height: 32,
        margin: 8,
        backgroundColor: active ? COLOR_PALLETTE.GRAY_2 : 'white',
        border: 'none',
        outline: 'none',
        borderRadius: 2,
      }}
      {...rest}
    >
      {children}
    </button>
  );
}

function DropdownButton({
  children,
  active,
  ...rest
}: {
  children: React.ReactNode;
  active?: boolean;
  className?: string | undefined;
  onClick?;
} & Pick<React.ButtonHTMLAttributes<HTMLButtonElement>, 'onMouseDown'>) {
  return (
    <button
      style={{
        width: 24,
        height: 24,
        padding: 0,
        backgroundColor: 'white',
        border: 'none',
        outline: 'none',
        borderRadius: 2,
      }}
      {...rest}
    >
      {children}
    </button>
  );
}

function Icon({ iconName }: { iconName: string }) {
  return <i className={`icon fa ${iconName}`} />;
}

const Spacer = () => (
  <div
    style={{
      display: 'inline',
      paddingLeft: 1,
      backgroundColor: 'grey',
    }}
  />
);

function Toolbar({ children }: { children: React.ReactNode }) {
  return (
    <div
      style={{
        backgroundColor: 'white',
        border: '1px solid #E8EDF5',
        borderRadius: 4,
        marginTop: 16,
        marginRight: 16,
        position: 'sticky',
        top: 53,
        zIndex: 100,
      }}
    >
      {children}
    </div>
  );
}

const Image = ({ attributes, children, element }) => {
  const editor = useSlateStatic();
  const path = ReactEditor.findPath(editor, element);

  return (
    <div {...attributes}>
      {children}
      <div contentEditable={false} style={{ position: 'relative' }}>
        <img
          src={element.url}
          width={'100%'}
          alt="sample"
          style={{ display: 'block' }}
        />
        <Button
          className="buttonTransparent"
          active
          onClick={() => Transforms.removeNodes(editor, { at: path })}
        >
          <i className={`icon fa fa-trash`} />
        </Button>
        <style jsx global>{`
          .buttonTransparent {
            position: absolute;
            top: 0.5em;
            left: 0.5em;
            background-color: white;
          }
        `}</style>
      </div>
    </div>
  );
};

const withImages = (editor) => {
  const { insertData, isVoid } = editor;

  editor.isVoid = (element) => {
    return element.type === 'image' ? true : isVoid(element);
  };

  editor.insertData = (data) => {
    insertData(data);
  };

  return editor;
};

const withLinks = (editor) => {
  const { isInline } = editor;

  editor.isInline = (element) => {
    return element.type === 'link' ? true : isInline(element);
  };

  return editor;
};

const insertImage = (editor, url, id, height, width) => {
  const text = { text: '' };
  const image: ImageElement = {
    type: 'image',
    url,
    children: [text],
    id,
    height,
    width,
  };
  Transforms.insertNodes(editor, image);
};

const InsertImageButton = ({ session, history, location }) => {
  return (
    <>
      <Link
        style={{
          width: 32,
          height: 32,
          margin: 8,
          backgroundColor: 'white',
          border: 'none',
          color: 'black',
        }}
        to={locationForModal({
          // eslint-disable-next-line no-restricted-globals
          location,
          modal: { modalName: UPLOAD_IMAGE_MODAL },
        })}
      >
        <i className={`icon fa fa-image`} />
      </Link>
      <ModalRoute modalName={UPLOAD_IMAGE_MODAL}>
        {(routeProps) => {
          return (
            <UploadImageModal
              token={session.token}
              history={history}
              location={location}
              onDismiss={() =>
                history.push(
                  locationForModal({
                    location,
                    modal: undefined,
                  })
                )
              }
            />
          );
        }}
      </ModalRoute>
    </>
  );
};

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
};

const LIST_TYPES = ['numbered-list', 'bulleted-list', 'do-list', 'do-not-list'];
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
  );
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });

  let newProperties: Partial<SlateElement>;

  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    };
  } else {
    newProperties = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    };
  }

  Transforms.setNodes<SlateElement>(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const toggleMarkWithValue = (editor, format, value: string) => {
  const isActive = isMarkValueActive(editor, format, value);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.removeMark(editor, format);
    Editor.addMark(editor, format, value);
  }
};

const isBlockActive = (editor, format, blockType = 'type') => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType] === format,
    })
  );

  return !!match;
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] != null : false;
};

const isMarkValueActive = (editor, format, color) => {
  const marks = Editor.marks(editor);
  return marks?.['color'] === color ? true : false;
};

const isLinkNodeAtSelection = (editor) => {
  const { selection } = editor;

  if (selection == null) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
    })
  );

  return match != null;
};

const toggleLinkAtSelection = (editor) => {
  const isLinkActive = isLinkNodeAtSelection(editor);

  if (isLinkActive === true) {
    Transforms.unwrapNodes(editor, {
      match: (n) => SlateElement.isElement(n) && n.type === 'link',
    });

    return;
  }

  const expression = /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;
  const regex = new RegExp(expression);
  let url = prompt('Enter URL');

  if (url?.match(regex) == null) {
    alert("Enter a valid URL, don't forget http:// or https://");

    url = null;
  }

  const isSelectionCollapsed = editor.selection.isCollapsed;

  if (isSelectionCollapsed && url != null) {
    Transforms.insertNodes(
      editor,
      {
        type: 'link',
        url,
        children: [{ text: 'link' }],
      },
      { at: editor.selection }
    );
  } else if (url != null) {
    Transforms.wrapNodes(
      editor,
      { type: 'link', url, children: [{ text: '' }] },
      { split: true, at: editor.selection }
    );
  }
};

const LinkComponent = ({ attributes, children, element }) => {
  const selected = useSelected();
  const focused = useFocused();

  return (
    <a href={element.url} {...attributes}>
      {children}

      {selected && focused && (
        <span
          contentEditable={false}
          style={{
            userSelect: 'none',
            position: 'absolute',
            left: 0,
            top: '100%',
            padding: 14,
            backgroundColor: 'white',
            border: `1px solid ${COLOR_PALLETTE.GRAY_2}`,
            borderRadius: 2,
            zIndex: 1,
          }}
        >
          <a href={element.url} target="_blank" rel="noreferrer">
            {element.url}
          </a>
        </span>
      )}
    </a>
  );
};

const Element = (props) => {
  const { attributes, children, element } = props;
  const style = { textAlign: element.align };

  switch (element.type) {
    case 'link':
      return <LinkComponent {...props} />;
    case 'block-quote':
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      );
    case 'bulleted-list':
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case 'heading-one':
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      );
    case 'heading-two':
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      );
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case 'numbered-list':
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );
    case 'do-list':
      return (
        <ul className="snappr-rich-text__do-list" {...attributes}>
          {children}
        </ul>
      );
    case 'do-not-list':
      return (
        <ul className="snappr-rich-text__do-not-list" {...attributes}>
          {children}
        </ul>
      );
    case 'image':
      return <Image {...props} />;
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
  }
};

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  if (leaf.color != null) {
    children = (
      <span className={`snappr-rich-text-${leaf.color}`}>{children}</span>
    );
  }

  return <span {...attributes}>{children}</span>;
};

const BlockButton = ({ format, icon }: { format: string; icon: string }) => {
  const editor = useSlate();
  return (
    <Button
      active={isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
      )}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      <Icon iconName={icon} />
    </Button>
  );
};

const MarkButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      <Icon iconName={icon} />
    </Button>
  );
};

const MarkColorDropDownButton = ({
  format,
  color,
  label,
}: {
  format: string;
  color: string;
  label: string;
}) => {
  const editor = useSlate();
  return (
    <DropdownButton
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMarkWithValue(editor, format, color ?? true);
      }}
    >
      <i
        style={{
          margin: 0,
          padding: 0,
          height: 24,
          width: 24,
          backgroundColor: label,
          borderRadius: '50%',
          display: 'block',
        }}
      />
    </DropdownButton>
  );
};

const LinkButton = () => {
  const editor = useSlate();

  return (
    <Button
      active={isLinkNodeAtSelection(editor)}
      onMouseDown={() => toggleLinkAtSelection(editor)}
    >
      <i className="icon fa fa-link" />
    </Button>
  );
};

const BlockDropdown = ({
  options,
}: {
  options: { value: string; label: string }[];
}) => {
  const editor = useSlate();

  return (
    <select
      className="mx-3"
      style={{ border: 'none', outline: 'none' }}
      value={
        options.filter((option) => isBlockActive(editor, option.value))[0]
          ?.value ?? 'paragraph'
      }
      onChange={(e) => {
        e.preventDefault();
        toggleBlock(editor, e.target.value);
      }}
    >
      {options.map(({ value, label }, index) => (
        <option key={index} value={value}>
          {label}
          {'\u00A0'}
        </option>
      ))}
    </select>
  );
};

const BlockIconDropdown = ({
  options,
  iconName,
}: {
  options: { value: string; label: string }[];
  iconName: string;
}) => {
  const editor = useSlate();

  const [open, setOpen] = React.useState(false);

  const menuWrapperRef = React.useRef<HTMLDivElement>(null);

  const isAnyActive =
    options.filter((option) => isBlockActive(editor, option.value)).length > 0;

  React.useEffect(() => {
    if (open === false) return;

    function handleClickOutside(event) {
      if (
        menuWrapperRef.current &&
        menuWrapperRef.current.contains(event.target) !== true
      ) {
        setOpen(false);
      }
    }

    document.addEventListener('pointerdown', handleClickOutside);

    return () => {
      document.removeEventListener('pointerdown', handleClickOutside);
    };
  }, [open]);

  return (
    <div style={{ position: 'relative', display: 'inline' }}>
      <Button
        active={isAnyActive}
        onMouseDown={() => setOpen((state) => !state)}
      >
        <Icon iconName={iconName} />
      </Button>

      {open && (
        <div
          ref={menuWrapperRef}
          style={{
            display: 'flex',
            position: 'absolute',
            zIndex: 10,
            gap: 8,
            left: 0,
            margin: 2,
            padding: 14,
            backgroundColor: 'white',
            border: `1px solid ${COLOR_PALLETTE.GRAY_2}`,
            borderRadius: 2,
          }}
        >
          {options.map(({ value, label }, index) => (
            <button
              key={index}
              onClick={(e) => {
                e.preventDefault();
                toggleBlock(editor, value);
              }}
              style={{
                background:
                  label != null
                    ? `url('${label}') no-repeat center center`
                    : undefined,
                backgroundColor: 'transparent',
                border: 'none',
                outline: 'none',
                height: 24,
                width: 24,
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const MarkButtonsDropdown = ({
  options,
  format,
  iconName,
}: {
  options: { value: string; label: string }[];
  format: string;
  iconName: string;
}) => {
  const editor = useSlate();

  const [open, setOpen] = React.useState(false);

  const menuWrapperRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (open === false) return;

    function handleClickOutside(event) {
      if (
        menuWrapperRef.current &&
        menuWrapperRef.current.contains(event.target) !== true
      ) {
        setOpen(false);
      }
    }

    document.addEventListener('pointerdown', handleClickOutside);

    return () => {
      document.removeEventListener('pointerdown', handleClickOutside);
    };
  }, [open]);

  return (
    <div style={{ position: 'relative', display: 'inline' }}>
      <Button
        active={isMarkActive(editor, format)}
        onMouseDown={() => setOpen((state) => !state)}
      >
        <Icon iconName={iconName} />
      </Button>

      {open && (
        <div
          ref={menuWrapperRef}
          style={{
            display: 'flex',
            position: 'absolute',
            zIndex: 10,
            left: 0,
            gap: 8,
            margin: 2,
            padding: 14,
            backgroundColor: 'white',
            border: `1px solid ${COLOR_PALLETTE.GRAY_2}`,
            borderRadius: 2,
          }}
        >
          {options.map(({ value, label }, index) => (
            <MarkColorDropDownButton
              key={index}
              format={format}
              color={value}
              label={label}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export function RichTextInput({
  value,
  onChange,
  session,
  history,
  location,
  guidelineId,
}: {
  value: Descendant[];
  onChange: (p: Descendant[]) => void;
  session;
  history;
  location;
  guidelineId?: string;
}) {
  const [editor] = React.useState(() =>
    withImages(withLinks(withHistory(withReact(createEditor()))))
  );

  const renderElement = React.useCallback((props) => {
    return <Element {...props} />;
  }, []);

  const renderLeaf = React.useCallback((props) => <Leaf {...props} />, []);

  const fontSizeOptions = [
    { value: 'heading-one', label: 'Heading 1' },
    { value: 'heading-two', label: 'Heading 2' },
    { value: 'paragraph', label: 'Paragraph' },
  ];

  const ulListOptions = [
    { value: 'bulleted-list', label: bulletListIcon },
    { value: 'do-list', label: doListIcon },
    { value: 'do-not-list', label: dontListIcon },
  ];

  const textColorOptions = [
    { value: 'green', label: COLOR_PALLETTE.SUCCESS },
    { value: 'yellow', label: COLOR_PALLETTE.WARNING },
    { value: 'red', label: COLOR_PALLETTE.DANGER },
    { value: 'gray', label: COLOR_PALLETTE.GRAY_5 },
  ];

  return (
    <div style={{ position: 'relative', marginBottom: 48 }}>
      <Slate editor={editor} value={value} onChange={onChange}>
        <Toolbar>
          <BlockDropdown options={fontSizeOptions} />

          <Spacer />

          <MarkButton format="bold" icon="fa-bold" />
          <MarkButton format="italic" icon="fa-italic" />
          <MarkButton format="underline" icon="fa-underline" />
          <MarkButton format="code" icon="fa-code" />
          <LinkButton />
          <MarkButtonsDropdown
            options={textColorOptions}
            format="color"
            iconName="fa-font"
          />

          <Spacer />

          <BlockButton format="numbered-list" icon="fa-list-ol" />
          <BlockIconDropdown options={ulListOptions} iconName="fa-list" />

          <Spacer />

          <BlockButton format="left" icon="fa-align-left" />
          <BlockButton format="center" icon="fa-align-center" />
          <BlockButton format="right" icon="fa-align-right" />
          <BlockButton format="justify" icon="fa-align-justify" />

          <Spacer />

          <InsertImageButton
            session={session}
            history={history}
            location={location}
          />

          <Spacer />

          <Button onClick={() => editor.undo()}>
            <i className={`icon fa fa-undo`} />{' '}
          </Button>

          <Button onClick={() => editor.redo()}>
            <i className={`icon fa fa-repeat`} />
          </Button>

          {guidelineId != null && (
            <>
              <Spacer />
              <a
                href={`${APP_URL}/guidelines/${guidelineId}`}
                target="_blank"
                rel="noreferrer"
                style={{ margin: 8, color: 'black' }}
              >
                <i className={`icon fa fa-desktop`} />
              </a>
            </>
          )}
        </Toolbar>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Editable
            className="snappr-rich-text"
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            placeholder="Enter some rich text…"
            spellCheck
            autoFocus
            style={{
              width: 375,
              backgroundColor: 'white',
              textAlign: 'center',
              marginTop: 48,
              padding: 8,
            }}
            onKeyDown={(event) => {
              for (const hotkey in HOTKEYS) {
                if (isHotkey(hotkey, event as any)) {
                  event.preventDefault();
                  const mark = HOTKEYS[hotkey];
                  toggleMark(editor, mark);
                }
              }
            }}
          />
        </div>
      </Slate>

      <style jsx global>{`
        @font-face {
          font-family: 'Tiempos Headline';
          font-display: swap;
          src: url('/fonts/tiempos-headline-bold.otf');
        }

        .snappr-rich-text blockquote,
        .snappr-rich-text h2,
        .snappr-rich-text li,
        .snappr-rich-text ul,
        .snappr-rich-text ol,
        .snappr-rich-text p {
          margin: 0.5rem 0 0;
          color: ${COLOR_PALLETTE.GRAY_4};
          font-family: 'Nunito Sans';
          font-style: normal;
        }

        .snappr-rich-text a {
          color: ${COLOR_PALLETTE.TEAL};
          text-decoration: underline;
          position: relative;
        }

        .snappr-rich-text ul,
        .snappr-rich-text ol {
          margin: 0;
          padding: 0 0 0 1.5rem;
        }

        .snappr-rich-text ul li,
        .snappr-rich-text ol li {
          margin: 0.5rem 0 0;
          padding: 0 0 0 0.4rem;
        }

        .snappr-rich-text h1 {
          margin: 1.5rem 0 0;
          font-family: 'Tiempos Headline';
          font-size: 24px;
          font-weight: 700;
          line-height: 29px;
          color: ${COLOR_PALLETTE.PRIMARY};
        }

        .snappr-rich-text h2 {
          color: ${COLOR_PALLETTE.PRIMARY};
          font-size: 18px;
          font-weight: 700;
        }

        .snappr-rich-text .snappr-rich-text__do-list,
        .snappr-rich-text .snappr-rich-text__do-not-list {
          padding: 0;
          list-style: none;
        }

        .snappr-rich-text .snappr-rich-text__do-list li {
          background: url('${doListIcon}') no-repeat left top;
          padding: 0px 10px 5px 30px;
        }

        .snappr-rich-text .snappr-rich-text__do-not-list li {
          background: url('${dontListIcon}') no-repeat left top;
          padding: 0px 10px 5px 30px;
        }

        .snappr-rich-text-green {
          color: ${COLOR_PALLETTE.SUCCESS};
        }

        .snappr-rich-text-yellow {
          color: ${COLOR_PALLETTE.WARNING};
        }

        .snappr-rich-text-red {
          color: ${COLOR_PALLETTE.DANGER};
        }

        .snappr-rich-text-gray {
          color: ${COLOR_PALLETTE.GRAY_5};
        }
      `}</style>
    </div>
  );
}

export function UploadImageModal({
  token,
  onDismiss,
  location,
  history,
}: {
  token: string;
  onDismiss: () => void;
  location: RouteComponentProps['location'];
  history: RouteComponentProps['history'];
}) {
  const [image, setImage] = React.useState<{
    url?: string;
    uid?: string;
    name?: string;
    width?: number;
    height?: number;
  } | null>(null);

  const { uploadsList, addFiles } = useUploadManager({
    token,
    onUploadReady: ({ fileName, file, remove, mediaUid }) => {
      apiFetch('/api/v2/media/create-from-temp', {
        token,
        method: 'POST',
        body: JSON.stringify({
          tempMediaUid: mediaUid,
          fileName,
          isPublic: true,
        }),
      }).then(({ finalUrl, width, height, uid }) => {
        if (finalUrl != null) {
          setImage({
            url: finalUrl,
            name: fileName,
            width,
            height,
            uid,
          });
          remove();
        }
      });
    },
  });

  const editor = useSlateStatic();
  return (
    <Modal onDismiss={onDismiss}>
      <div className="p-4 card" style={{ minWidth: 712 }}>
        <div>
          <div style={{ height: 16 }} />
          <form
            onSubmit={(e) => {
              e.preventDefault();
              image != null &&
                insertImage(
                  editor,
                  image.url,
                  image.uid,
                  image?.height,
                  image?.width
                );
              history.push(
                locationForModal({
                  location,
                  modal: undefined,
                })
              );
            }}
          >
            <div className="row">
              <div className="col">
                <Dropzone
                  style={{
                    border: '1px dashed #C1C8D4',
                    borderRadius: '4px',
                    height: 160,
                  }}
                  multiple={false}
                  onDrop={(acceptedFiles) => addFiles({ acceptedFiles })}
                >
                  <div
                    className="card-body text-center"
                    style={{ marginTop: 30 }}
                  >
                    <div>
                      <p>
                        Drag and drop your image here, <br />
                        <span style={{ textDecoration: 'underline' }}>
                          or browse
                        </span>
                      </p>
                    </div>
                  </div>
                </Dropzone>

                <div style={{ height: 16 }} />

                {uploadsList != null &&
                  uploadsList.map((file) => (
                    <div key={file.clientId}>
                      {file.fileName}
                      {file.sizeUploaded != null && file.fileSize != null && (
                        <div>
                          <ProgressIndicator
                            progress={file.sizeUploaded / file.fileSize}
                          />
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>

            <div style={{ height: 32 }} />
            {image?.url != null && (
              <img
                src={image.url}
                alt="upload"
                style={{
                  width: '100%',
                  maxWidth: 700,
                }}
              />
            )}

            <div style={{ height: 16 }} />

            <button
              type="submit"
              className="btn btn-dark btn-block"
              disabled={image?.url == null}
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </Modal>
  );
}
