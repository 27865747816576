import moment from 'moment-timezone';

import { isPartnerBooking } from '../../selectors';
import { formatCredits, formatMoneyCents } from '../../../../../utils';
import { BOOKING_STATUS } from '../../selectors';

export const lessThan24ForShoot = (booking) =>
  booking.start_at &&
  moment(booking.start_at).diff(moment(), 'hours', true) <= 24;

export const formatCreditsOrMoney = ({ booking, value }) =>
  isPartnerBooking({ booking })
    ? `${formatCredits(value)} credits`
    : formatMoneyCents(value, {
        currency: booking.currency || 'USD',
      });

export const notificationIsDismissed = ({ notifications, notification }) =>
  notifications.find(({ type }) => type === notification.type) == null;

export const chargeIsDismissed = ({ charges, charge }) =>
  charges.find(({ reason }) => reason === charge.reason) == null;

export const transactionIsDismissed = ({ transactions, transaction }) =>
  transactions.find(({ id }) => id === transaction.id) == null;

export const providerPaymentIsDismissed = ({
  providerPayments,
  providerPayment,
}) =>
  providerPayments.find(({ reason }) => reason === providerPayment.reason) ==
  null;

const BUTTON_TYPES = {
  RESCHEDULE: { label: 'Reschedule', primary: true },
  REALLOCATE: { label: 'Reallocate' },
  CHANGE_PACKAGE: { label: 'Change package', primary: true },
  SAVE: { label: 'Save' },
  // CANCEL: 'Cancel',
  // REFUND: 'Refund',
  CHARGE_EXTRA: { label: 'Charge extra' },
  REMOVE_HOLD: { label: 'Remove hold' },
};

export const infoForBooking = ({ booking, bookingClone, changedKeys }) => {
  const typeChanges = [
    booking.status === BOOKING_STATUS.PAID_ON_HOLD &&
      changedKeys.internal_notes == null &&
      BUTTON_TYPES.REMOVE_HOLD,

    (changedKeys.start_at || changedKeys.address) && BUTTON_TYPES.RESCHEDULE,

    changedKeys.provider_uid && BUTTON_TYPES.REALLOCATE,

    bookingClone.product_uid != null &&
      changedKeys.product_uid &&
      BUTTON_TYPES.CHANGE_PACKAGE,
  ].filter((p) => !!p);

  if (typeChanges.length === 0) {
    return BUTTON_TYPES.SAVE;
  }

  return {
    label:
      typeChanges.length < 3
        ? typeChanges.map(({ label }) => label).join(' and ')
        : [
            typeChanges
              .slice(0, -1)
              .map((p) => p.label)
              .join(', '),
            typeChanges.slice(-1)[0].label,
          ].join(' and '),
    primary: typeChanges.reduce((res, current) => res || current, false),
    warningMessage: lessThan24ForShoot(booking)
      ? !!typeChanges.find((c) => c === BUTTON_TYPES.RESCHEDULE)
        ? 'Our terms do not allow reschedules within 24 hours of a shoot. ' +
          'Management approval is required to override the terms.'
        : null
      : null,
  };
};
