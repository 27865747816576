import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { gql, useQuery } from '@apollo/client';

import { IMap, IMaps } from '../../../../../components/useGoogleMapsLoader';
import BlackMarkerIcon from '../../../../../components/img/booking-black-marker.svg';
import PurpleMarkerIcon from '../../../../../components/img/booking-purple-marker.svg';
import moment from 'moment-timezone';
import {
  SECONDS_IN_A_DAY,
  SECONDS_IN_AN_HOUR,
} from '../../../../../utils/time';
import { apiFetch } from '../../../../../utils';

const MapContext = createContext<{ maps?: IMaps; map?: IMap }>({});

const START_HOUR = 7;
const END_HOUR = 23;
const SLOT_LENGTH_IN_HOURS = 1;

const SLOT_COUNT = (END_HOUR - START_HOUR + 1) / SLOT_LENGTH_IN_HOURS;

// http://www.movable-type.co.uk/scripts/latlong.html
function getDistanceFromLatLonInKm({
  lat1,
  lon1,
  lat2,
  lon2,
}: {
  lat1: number;
  lon1: number;
  lat2: number;
  lon2: number;
}) {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad({ deg: lat2 - lat1 }); // deg2rad below
  const dLon = deg2rad({ deg: lon2 - lon1 });
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad({ deg: lat1 })) *
      Math.cos(deg2rad({ deg: lat2 })) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const angularDistanceInRadians =
    2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * angularDistanceInRadians; // Distance in km

  return distance;
}

function deg2rad({ deg }: { deg: number }) {
  return deg * (Math.PI / 180);
}

function hourDisplay({ hour }: { hour: number }) {
  const isPM = hour >= 12;
  const isMM = hour === 12;
  return [isPM && !isMM ? hour - 12 : hour, isPM ? 'pm' : 'am'].join(' ');
}

const days = [0, 1, 2, 3, 4, 5, 6].map((weekDayNumber) => ({
  weekDayNumber,
  hours: Array.from(Array(SLOT_COUNT)).map((_, index) => ({
    hour: index * SLOT_LENGTH_IN_HOURS + START_HOUR,
    selected: false,
  })),
}));

function dayAndHourToSecondsSlot({
  weekDayNumber,
  hour,
}: {
  weekDayNumber: number;
  hour: number;
}) {
  const start = weekDayNumber * SECONDS_IN_A_DAY + hour * SECONDS_IN_AN_HOUR;
  const end = start + SECONDS_IN_AN_HOUR * SLOT_LENGTH_IN_HOURS;

  return { start, end };
}

function PhotographerBatchMapMarkers({
  center,
  bookingClone,
  date,
  maxDistance,
  isDoordash,
  session,
  onProviderChange,
}: {
  center: { lat: number; lng: number };
  bookingClone: { point: [number, number]; timezone?: string };
  session: { token: string };
  onProviderChange: (p: { provider_uid: string }) => void;
  date?: string;
  maxDistance?: number;
  isDoordash?: boolean;
}) {
  const nearbyBookings = useQuery<{
    bookingsBatchingNearbyGetList: {
      edges: {
        id: string;
        startAt?: string;
        timezone?: string;
        package?: {
          duration: number;
        };
        point?: {
          lat: number;
          lng: number;
        };
        provider?: {
          id: string;
          publicFullName?: string;
        };
      }[];
    };
  }>(
    gql`
      query ProvidersNearby(
        $lat: Float!
        $lng: Float!
        $timezone: String
        $date: String
        $maxDistance: Int
        $isDoordash: Boolean
      ) {
        bookingsBatchingNearbyGetList(
          input: {
            lat: $lat
            lng: $lng
            timezone: $timezone
            date: $date
            maxDistance: $maxDistance
            isDoordash: $isDoordash
          }
        ) {
          edges {
            id
            startAt
            package {
              duration
            }
            point {
              lat
              lng
            }
            timezone
            provider {
              id
              publicFullName
            }
          }
        }
      }
    `,
    {
      variables: {
        lat: center.lat,
        lng: center.lng,
        timezone: bookingClone?.timezone,
        date,
        maxDistance,
        isDoordash,
      },
    }
  );

  const { map, maps } = useContext(MapContext);

  const bookings = nearbyBookings.data?.bookingsBatchingNearbyGetList?.edges;

  useEffect(() => {
    if (map == null || maps == null) return;

    const cleanup: (() => void)[] = [];

    const marker = new maps.Marker({
      position: center,
      map,
    });

    marker?.setIcon(BlackMarkerIcon);

    cleanup.push(() => marker?.setMap(null));

    const originalBookingMarker = {
      marker,
      cleanup: () => {
        cleanup.forEach((fn) => fn());
      },
    };

    const markers = [
      originalBookingMarker,

      ...(bookings ?? []).map((booking) => {
        const cleanup: (() => void)[] = [];

        const marker =
          booking.point == null
            ? undefined
            : new maps.Marker({
                position: booking.point,
                map,
              });

        cleanup.push(() => marker?.setMap(null));

        const timeStart = moment(booking?.startAt)
          .tz(booking?.timezone ?? moment.tz.guess())
          .format('h:mm a ');

        const timeEnd = moment(booking.startAt)
          .add(booking?.package?.duration, 'minutes')
          .tz(booking?.timezone ?? moment.tz.guess())
          .format('h:mm a ');

        const tooltip = ({
          availabilityTimeFrame,
          distanceBetweenBookings,
        }: {
          availabilityTimeFrame: { start: string; end: string }[];
          distanceBetweenBookings: string | undefined;
        }) =>
          new google.maps.InfoWindow({
            content: `
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  padding-top: 8px;
                  padding-left: 4px;
                "
              >
                <h6 style="color: #141B24; margin: 0">${
                  isDoordash ? 'DoorDash booking' : 'Booking details'
                }</h6>

                <label
                  style="
                    color: #71767E;
                    font-size: 10px;
                    padding-top: 8px;
                    margin: 0;
                    padding-bottom: 2px;
                  ">
                  Booking time
                </label>

                <p
                  style="
                    color: #141B24;
                    margin: 0;
                    font-size: 14px;
                    font-weight: 700
                  "
                >
                  ${timeStart} - ${timeEnd}
                </p>

                <label
                  style="
                    color: #71767E;
                    font-size: 10px;
                    padding-top: 4px;
                    margin: 0;
                    padding-bottom: 2px
                  "
                >
                  Distance to new booking
                </label>

                <p
                  style="
                    color: #141B24;
                    margin: 0;
                    font-size: 14px;
                    font-weight: 700;
                    padding-bottom: 8px;
                    border-bottom: 1px solid #E8EDF5
                  "
                >
                  ${distanceBetweenBookings} miles
                </p>

                <label
                  style="
                    color: #71767E;
                    font-size: 10px;
                    padding-top: 8px;
                    margin: 0;
                    padding-bottom: 2px
                  "
                >
                  Photographer assigned
                </label>

                <p
                  id="provider-link"
                  style="
                    color: #141B24;
                    margin: 0;
                    font-size: 14px;
                    font-weight: 700;
                    text-decoration-line: underline;
                    cursor: pointer
                  "
                >
                  ${booking?.provider?.publicFullName}
                </p>

                <label
                  style="
                    color: #71767E;
                    font-size: 10px;
                    padding-top: 8px;
                    margin: 0;
                    padding-bottom: 2px
                  "
                >
                  Available time frame
                </label>

                <ul style="margin: 0; padding-left: 18px; padding-bottom: 16px">
                  ${
                    availabilityTimeFrame == null
                      ? `<li
                         style="
                           color: #141B24;
                           margin: 0;
                           font-size: 14px;
                           font-weight: 700
                         "
                      >
                        Loading...
                      </li>`
                      : availabilityTimeFrame.length > 0
                      ? availabilityTimeFrame
                          ?.map(
                            (timeFrame) =>
                              `
                            <li
                              style="
                                color: #141B24;
                                margin: 0;
                                font-size: 14px;
                                font-weight: 700
                              "
                            >
                              ${timeFrame.start} - ${timeFrame.end}
                            </li>
                          `
                          )
                          .join('')
                      : `<li
                           style="
                             color: #141B24;
                             margin: 0;
                             font-size: 14px;
                             font-weight: 700
                           "
                         >
                           Not found
                         </li>
                      `
                  }
                </ul>

                <button
                  id="assign-pg-button"
                  style="
                    border: 1px solid #1F6FFF;
                    padding: 8px 10px;
                    background-color: white;
                    border-radius: 2px;
                    color: #1F6FFF;
                    font-weight: 700
                  "
                >
                  Assign photographer
                </button>

                <div style="height: 8px; width: 100%" />
              </div>
            `,
          });

        const markerEventListener = marker?.addListener('click', () => {
          const fetchAvailabilityData = async () => {
            const data = (booking.provider?.id != null
              ? await apiFetch(
                  `/api/v2/providers/${booking.provider?.id}/weekly-availability`,
                  {
                    token: session.token,
                    method: 'GET',
                  }
                )
              : undefined) as { results: { start: number; end: number }[] };

            return data?.results;
          };

          fetchAvailabilityData().then((availability) => {
            if (availability != null) {
              const availabilityPerDay = days.map((day) =>
                day.hours.map((h) => {
                  const {
                    start: slotStart,
                    end: slotEnd,
                  } = dayAndHourToSecondsSlot({
                    weekDayNumber: day.weekDayNumber,
                    hour: h.hour,
                  });

                  h.selected =
                    availability?.find(({ start, end }) => {
                      return start <= slotStart && slotEnd <= end;
                    }) != null;

                  return h;
                })
              );

              const bookingDayFromSundayToSaturday =
                booking?.startAt != null
                  ? moment(booking?.startAt)
                      .tz(booking?.timezone ?? moment.tz.guess())
                      .day()
                  : undefined;

              const bookingDayFromMondayToSunday =
                bookingDayFromSundayToSaturday != null
                  ? bookingDayFromSundayToSaturday === 0
                    ? 6
                    : bookingDayFromSundayToSaturday - 1
                  : undefined;

              const availabilityInDaySelected =
                availabilityPerDay != null &&
                bookingDayFromMondayToSunday != null
                  ? availabilityPerDay[bookingDayFromMondayToSunday]
                  : undefined;

              const availabilityTimeFrame =
                availabilityInDaySelected != null
                  ? availabilityInDaySelected
                      .reduce(
                        (
                          previousAvailabilityTimeValueAccumulator,
                          currentAvailabilityTimeValue,
                          i
                        ) => {
                          const skip = previousAvailabilityTimeValueAccumulator.some(
                            (prev) =>
                              currentAvailabilityTimeValue.hour <= prev.end
                          );

                          const timeFrameEnd = !skip
                            ? availabilityInDaySelected.find(
                                (pgAvailability, availabilityIndex) =>
                                  !pgAvailability.selected &&
                                  availabilityIndex > i
                              ) ??
                              availabilityInDaySelected[
                                availabilityInDaySelected.length - 1
                              ]
                            : undefined;

                          const availabilityTimeFrame =
                            currentAvailabilityTimeValue.selected &&
                            timeFrameEnd != null &&
                            !skip
                              ? {
                                  start: currentAvailabilityTimeValue.hour,
                                  end: timeFrameEnd.hour,
                                }
                              : undefined;

                          return [
                            ...previousAvailabilityTimeValueAccumulator,
                            ...(availabilityTimeFrame != null
                              ? [availabilityTimeFrame]
                              : []),
                          ];
                        },
                        [] as { start: number; end: number }[]
                      )
                      .map((timeFrame) => ({
                        start: hourDisplay({ hour: timeFrame.start }),
                        end: hourDisplay({ hour: timeFrame.end }),
                      }))
                  : undefined;

              if (availabilityTimeFrame != null) {
                const distanceBetweenBookings =
                  bookingClone.point != null && booking.point != null
                    ? (
                        (getDistanceFromLatLonInKm({
                          lat1: bookingClone.point[0],
                          lon1: bookingClone.point[1],
                          lat2: booking.point.lat,
                          lon2: booking.point.lng,
                        }) ?? 0) * 0.62137119
                      ) // convert km to miles
                        .toFixed(2)
                    : undefined;

                const makerTooltip = tooltip({
                  availabilityTimeFrame,
                  distanceBetweenBookings,
                });
                makerTooltip.open(map, marker);

                google.maps.event.addListener(
                  makerTooltip,
                  'domready',
                  function () {
                    document
                      .getElementById('assign-pg-button')
                      ?.addEventListener('click', () => {
                        if (booking.provider?.id) {
                          onProviderChange({
                            provider_uid: booking.provider?.id,
                          });
                        }
                      });

                    document
                      .getElementById('provider-link')
                      ?.addEventListener('click', () => {
                        if (booking.provider?.id != null) {
                          window
                            .open(
                              `/providers/${booking.provider?.id}`,
                              '_blank'
                            )
                            ?.focus();
                        }
                      });

                    const closeIconElement = document.querySelector<HTMLElement>(
                      '.gm-ui-hover-effect'
                    )!;

                    closeIconElement.setAttribute(
                      'style',
                      `
                        background: none;
                        display: block;
                        border: 0px;
                        margin: 0px;
                        padding: 6px;
                        text-transform: none;
                        appearance: none;
                        position: absolute;
                        cursor: pointer;
                        user-select: none;
                        top: -2px;
                        right: 8px;
                        width: 30px;
                        height: 30px;
                      `
                    );
                  }
                );
              }
            }
          });
        });

        cleanup.push(() => markerEventListener?.remove());

        marker?.setIcon(PurpleMarkerIcon);

        cleanup.push(() => marker?.setIcon(null));

        return {
          marker,

          cleanup: () => {
            cleanup.forEach((fn) => fn());
          },
        };
      }),
    ];

    return () => {
      markers?.forEach((m) => {
        m.cleanup();
      });
    };
  }, [
    map,
    bookings,
    maps,
    center,
    bookingClone?.point,
    session.token,
    onProviderChange,
  ]);

  return null;
}

export function PhotographerBatchMap({
  maps,
  bookingClone,
  date,
  maxDistance,
  isDoordash,
  session,
  onProviderChange,
}: {
  maps: IMaps;
  bookingClone: { point: [number, number]; timezone?: string };
  onProviderChange: (p: { provider_uid: string }) => void;
  session: { token: string };
  date?: string;
  maxDistance?: number;
  isDoordash?: boolean;
}) {
  const mapDivRef = useRef<HTMLDivElement>(null);

  const [map, setMap] = useState<IMap>();

  const [lat, lng] = bookingClone.point;
  const center = useMemo<{
    lat: number;
    lng: number;
  }>(() => ({ lat, lng }), [lat, lng]);

  useEffect(() => {
    if (mapDivRef.current == null) return;

    const _map =
      map ??
      new maps.Map(mapDivRef.current, {
        center,
        zoom: 11,
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
        zoomControl: false,
      });

    setMap(() => _map);
  }, [maps.Map, map, center]);

  return (
    <>
      <div ref={mapDivRef} style={{ flex: 1 }} />

      <MapContext.Provider value={{ map, maps }}>
        <PhotographerBatchMapMarkers
          center={center}
          bookingClone={bookingClone}
          date={date}
          maxDistance={maxDistance}
          isDoordash={isDoordash}
          session={session}
          onProviderChange={onProviderChange}
        />
      </MapContext.Provider>
    </>
  );
}
