import { Redirect, Route, Switch } from 'react-router-dom';

import { GoogleAdsBids } from './list';

export const GoogleAdsBidRoute = ({
  session,
  match,
  match: { path: basePath },
  history,
}) => (
  <Switch>
    <Route
      exact
      path={basePath}
      render={(props) => (
        <GoogleAdsBids
          {...props}
          location={props.location}
          session={session}
          history={history}
        />
      )}
    />
    <Route
      render={({ location }) => (
        <Redirect to={{ ...location, pathname: basePath }} />
      )}
    />
  </Switch>
);
