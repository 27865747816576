export const QUALITY_CONTROL_DEFAULT_NAME = 'Quality control job';

export enum QualityControlStatusEnum {
  completed = 'completed',
  created = 'created',
  paid = 'paid',
}

export enum QualityControlMediaStatusEnum {
  REJECTED = 'REJECTED',
  ACCEPTED = 'ACCEPTED',
  PENDING = 'PENDING',
}

export enum QualityControlMediaCriteriaStatusEnum {
  REJECTED = 'REJECTED',
  ACCEPTED = 'ACCEPTED',
}
