import React from 'react';
import styled from 'styled-components';
import { Link, NavLink } from 'react-router-dom';
import qs from 'query-string';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { ProductGuidelinesSection } from './guidelines';
import { gql, useMutation, useQuery } from '../../components/ApolloClient';

import { APP_URL, ENABLE_PARTNER_PILOT_MODE } from '../../config';
import godModeIcon from '../../components/img/god-mode-icon.svg';
import { LoadingSpinnerCentered } from '../../components/LoadingSpinner';
import { EnterpriseInvoicesSection } from './invoices';
import { EnterpriseContractSection } from './contracts';
import { EnterpriseBillingSection } from './billing';
import { usePromise } from '../../components/usePromise';

type Partner = {
  uid: string;
  name: string;
  legalName?: string;
  slug: string;
  region: {
    name: string;
    currency: string;
  };
  accountManagerEmail?: string;
  shootExtraDetails?: string;
  strictMode?: boolean;
  centralEditing?: boolean;
  referralsEnabled?: boolean;
  editingJobsEnabled?: boolean;
  realEstateBookingByDurationEnabled?: boolean;
  editingJobFreeCreditsEnabled?: boolean;
  rescheduleFeesWarningEnabled?: boolean;
  cancelAndReschedulingOptionsEnabled?: boolean;
  shortNoticeSettings?: { enabled: boolean };
  level?: string;
  businessType?: string;
  pilotModeEnabled?: boolean;
  manualPhotographerSelectionEnabled?: boolean;
  enterpriseNotesAsAdmin?: { id: string; text: string };
  enforceEditingQAEnabled?: boolean;
  enableIcsFileOnEcxEmails?: boolean;
  ecommercePartnerEnabled?: boolean;
};

const TabCode = {
  COMPANY_INFORMATION: 'COMPANY_INFORMATION',
  GUIDELINES_AND_PACKAGES: 'GUIDELINES_AND_PACKAGES',
  CONTRACTS: 'CONTRACTS',
  BILLING: 'BILLING',
  INVOICES: 'INVOICES',
};

const HeaderLinks = styled.div`
  a {
    text-decoration: none;
    margin-bottom: 2rem;

    h2 {
      margin: 0;
    }

    &.active {
      border-bottom: 3px #1f6fff solid;
    }
  }
`;

export function GodModeLink({
  session,
  partnerId,
  smallLink = false,
}: {
  session: { token: string; uid: string; expire: string };
  partnerId: string;
  smallLink?: boolean;
}) {
  const { uid, token, expire } = session;

  const authToken = btoa([uid, token, expire].join(':'));

  const linkUrl = `${APP_URL}/auth-and-redirect?${qs.stringify({
    authToken,
    next: `/enterprise?partner=${partnerId}`,
  })}`;

  return (
    <a
      className={`btn btn-dark align-items-center justify-content-center d-flex`}
      href={linkUrl}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={godModeIcon} alt="god mode" />
      {smallLink === true ? null : (
        <span style={{ marginLeft: 10 }}>God mode</span>
      )}
    </a>
  );
}

export const EnterpriseDetailsInner = ({
  partner,
  partnerReload,
}: {
  partner: Partner;
  partnerReload;
}) => {
  const partnerBasicDetailsByIdQuery = useQuery<{
    accountManagerListAsAdmin: {
      edges: {
        id: string;
        fullName: string;
        email: string;
      }[];
    };
  }>(
    gql`
      query PartnerByIdAsAdminQuery {
        accountManagerListAsAdmin {
          edges {
            id
            fullName
            email
          }
        }
      }
    `
  );

  const [
    commitUpdatePartnerBasicDetailsAsAdmin,
    commitUpdatePartnerBasicDetailsAsAdminMutation,
  ] = useMutation<{
    partnerCreateAsAdmin: {
      partner: {
        uid: string;
      };
    };
  }>(
    gql`
      mutation PartnerUpdateAsAdmin(
        $displayName: String
        $legalName: String
        $accountManagerId: ID
        $shootExtraDetails: String
        $strictMode: Boolean
        $centralEditing: Boolean
        $referralsEnabled: Boolean
        $editingJobsEnabled: Boolean
        $editingJobFreeCreditsEnabled: Boolean
        $realEstateBookingByDurationEnabled: Boolean
        $partnerId: ID!
        $cancelAndReschedulingOptionsEnabled: Boolean
        $shortNoticeBookingsEnabled: Boolean
        $level: String
        $businessType: String
        $pilotModeEnabled: Boolean
        $manualPhotographerSelectionEnabled: Boolean
        $rescheduleFeesWarningEnabled: Boolean
        $enforceEditingQAEnabled: Boolean
        $enableIcsFileOnEcxEmails: Boolean
        $ecommercePartnerEnabled: Boolean
      ) {
        partnerUpdateBasicDetailsAsAdmin(
          input: {
            partnerId: $partnerId
            displayName: $displayName
            legalName: $legalName
            accountManagerId: $accountManagerId
            shootExtraDetails: $shootExtraDetails
            strictMode: $strictMode
            centralEditing: $centralEditing
            referralsEnabled: $referralsEnabled
            editingJobsEnabled: $editingJobsEnabled
            editingJobFreeCreditsEnabled: $editingJobFreeCreditsEnabled
            realEstateBookingByDurationEnabled: $realEstateBookingByDurationEnabled
            cancelAndReschedulingOptionsEnabled: $cancelAndReschedulingOptionsEnabled
            shortNoticeBookingsEnabled: $shortNoticeBookingsEnabled
            level: $level
            businessType: $businessType
            pilotModeEnabled: $pilotModeEnabled
            manualPhotographerSelectionEnabled: $manualPhotographerSelectionEnabled
            rescheduleFeesWarningEnabled: $rescheduleFeesWarningEnabled
            enforceEditingQAEnabled: $enforceEditingQAEnabled
            enableIcsFileOnEcxEmails: $enableIcsFileOnEcxEmails
            ecommercePartnerEnabled: $ecommercePartnerEnabled
          }
        ) {
          partner {
            uid
          }
        }
      }
    `
  );

  const [updatePartnerNotes] = useMutation<
    { success: boolean },
    {
      partnerId: string;
      notes?: string;
    }
  >(
    gql`
      mutation UpdatePartnerNotesAsAdminFromEnterpriseDetails(
        $partnerId: ID!
        $notes: String
      ) {
        partnerNotesCreateOrUpdateAsAdmin(
          input: { partnerId: $partnerId, notes: $notes }
        ) {
          success
        }
      }
    `
  );

  const [displayName, setDisplayName] = React.useState<string>();
  const [legalName, setLegalName] = React.useState<string>();
  const [shootExtraDetails, setShootExtraDetails] = React.useState<string>();

  const [accountManagerId, setAccountManagerId] = React.useState<string>();

  const [strictMode, setStrictMode] = React.useState<boolean>();
  const [centralEditing, setCentralEditing] = React.useState<boolean>();
  const [referralsEnabled, setReferralsEnabled] = React.useState<boolean>();
  const [editingJobsEnabled, setEditingJobsEnabled] = React.useState<boolean>();
  const [
    editingJobFreeCreditsEnabled,
    setEditingJobFreeCreditsEnabled,
  ] = React.useState<boolean>();

  const [
    ecommercePartnerEnabled,
    setEcommercePartnerEnabled,
  ] = React.useState<boolean>();

  const [
    rescheduleFeesWarningEnabled,
    setRescheduleFeesWarningEnabled,
    ,
  ] = React.useState<boolean>();

  const [
    cancelAndReschedulingOptionsEnabled,
    setCancelAndReschedulingOptionsEnabled,
  ] = React.useState<boolean>();

  const [
    realEstateBookingByDurationEnabled,
    setRealEstateBookingByDurationEnabled,
  ] = React.useState<boolean>();

  const [
    shortNoticeBookingsEnabled,
    setShortNoticeBookingsEnabled,
  ] = React.useState<boolean>();

  const [partnerLevel, setPartnerLevel] = React.useState(
    partner.level as 'bronze' | 'silver' | 'gold' | undefined
  );

  const [businessType, setBusinessType] = React.useState<
    'marketplace' | 'e_commerce' | 'other' | undefined
  >(partner.businessType as 'marketplace' | 'e_commerce' | 'other' | undefined);

  const [pilotModeEnabled, setPilotModeEnabled] = React.useState<
    boolean | undefined
  >(partner.pilotModeEnabled);

  const [
    manualPhotographerSelectionEnabled,
    setManualPhotographerSelectionEnabled,
  ] = React.useState<boolean | undefined>(
    partner.manualPhotographerSelectionEnabled
  );

  const [editNotes, setEditNotes] = React.useState<boolean>(false);

  const [enterpriseAdminNotes, setEnterpriseAdminNotes] = React.useState<
    string | undefined
  >(partner.enterpriseNotesAsAdmin?.text);

  const [enforceEditingQAEnabled, setEnforceEditingQAEnabled] = React.useState<
    boolean | undefined
  >(partner.enforceEditingQAEnabled);

  const [
    enableIcsFileOnEcxEmails,
    setEnableIcsFileOnEcxEmails,
  ] = React.useState<boolean | undefined>(partner.enableIcsFileOnEcxEmails);

  const {
    setPromise: setSavingNotes,
    isPending: savingNotesPending,
  } = usePromise();

  const partnerAccountManagerId = (
    partnerBasicDetailsByIdQuery.data?.accountManagerListAsAdmin.edges ?? []
  ).find((i) => i.email === partner?.accountManagerEmail)?.id;

  const isSomethingEdited =
    (displayName != null && displayName !== partner?.name) ||
    (legalName != null && legalName !== partner?.legalName) ||
    (shootExtraDetails != null &&
      shootExtraDetails !== partner?.shootExtraDetails) ||
    (accountManagerId != null &&
      accountManagerId !== partnerAccountManagerId) ||
    (strictMode != null && strictMode !== partner?.strictMode) ||
    (centralEditing != null && centralEditing !== partner?.centralEditing) ||
    (referralsEnabled != null &&
      referralsEnabled !== partner?.referralsEnabled) ||
    (editingJobsEnabled != null &&
      editingJobsEnabled !== partner?.editingJobsEnabled) ||
    (editingJobFreeCreditsEnabled != null &&
      editingJobFreeCreditsEnabled !== partner?.editingJobFreeCreditsEnabled) ||
    (rescheduleFeesWarningEnabled != null &&
      rescheduleFeesWarningEnabled !== partner?.rescheduleFeesWarningEnabled) ||
    (cancelAndReschedulingOptionsEnabled != null &&
      cancelAndReschedulingOptionsEnabled !==
        partner?.cancelAndReschedulingOptionsEnabled) ||
    (realEstateBookingByDurationEnabled != null &&
      realEstateBookingByDurationEnabled !==
        partner?.realEstateBookingByDurationEnabled) ||
    (shortNoticeBookingsEnabled != null &&
      shortNoticeBookingsEnabled !== partner?.shortNoticeSettings?.enabled) ||
    (partnerLevel != null && partnerLevel !== partner?.level) ||
    (businessType != null && businessType !== partner?.businessType) ||
    (pilotModeEnabled != null &&
      pilotModeEnabled !== partner?.pilotModeEnabled) ||
    (manualPhotographerSelectionEnabled != null &&
      manualPhotographerSelectionEnabled !==
        partner?.manualPhotographerSelectionEnabled) ||
    (enforceEditingQAEnabled != null &&
      enforceEditingQAEnabled !== partner?.enforceEditingQAEnabled) ||
    (enableIcsFileOnEcxEmails != null &&
      enableIcsFileOnEcxEmails !== partner?.enableIcsFileOnEcxEmails) ||
    (ecommercePartnerEnabled != null &&
      ecommercePartnerEnabled !== partner?.ecommercePartnerEnabled);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();

        if (!isSomethingEdited) return;

        commitUpdatePartnerBasicDetailsAsAdmin({
          variables: {
            ...(displayName != null && displayName !== partner.name
              ? { displayName: displayName }
              : {}),
            ...(legalName != null && legalName !== partner.legalName
              ? { legalName: legalName }
              : {}),
            ...(accountManagerId != null &&
            accountManagerId !== partnerAccountManagerId
              ? { accountManagerId: accountManagerId }
              : {}),
            ...(shootExtraDetails != null &&
            shootExtraDetails !== partner.shootExtraDetails
              ? { shootExtraDetails: shootExtraDetails }
              : {}),
            ...(strictMode != null && strictMode !== partner.strictMode
              ? { strictMode: strictMode }
              : {}),
            ...(centralEditing != null &&
            centralEditing !== partner.centralEditing
              ? { centralEditing: centralEditing }
              : {}),
            ...(referralsEnabled != null &&
            referralsEnabled !== partner.referralsEnabled
              ? { referralsEnabled: referralsEnabled }
              : {}),
            ...(editingJobsEnabled != null &&
            editingJobsEnabled !== partner.editingJobsEnabled
              ? { editingJobsEnabled: editingJobsEnabled }
              : {}),
            ...(realEstateBookingByDurationEnabled != null &&
            realEstateBookingByDurationEnabled !==
              partner.realEstateBookingByDurationEnabled
              ? {
                  realEstateBookingByDurationEnabled: realEstateBookingByDurationEnabled,
                }
              : {}),
            ...(enableIcsFileOnEcxEmails != null &&
            enableIcsFileOnEcxEmails !== partner.enableIcsFileOnEcxEmails
              ? {
                  enableIcsFileOnEcxEmails: enableIcsFileOnEcxEmails,
                }
              : {}),
            ...(editingJobFreeCreditsEnabled !==
            partner.editingJobFreeCreditsEnabled
              ? {
                  editingJobFreeCreditsEnabled: editingJobFreeCreditsEnabled,
                }
              : {}),

            ...(ecommercePartnerEnabled !== partner.ecommercePartnerEnabled
              ? {
                  ecommercePartnerEnabled: ecommercePartnerEnabled,
                }
              : {}),

            ...(rescheduleFeesWarningEnabled !==
            partner.rescheduleFeesWarningEnabled
              ? {
                  rescheduleFeesWarningEnabled: rescheduleFeesWarningEnabled,
                }
              : {}),
            ...(cancelAndReschedulingOptionsEnabled !==
            partner.cancelAndReschedulingOptionsEnabled
              ? {
                  cancelAndReschedulingOptionsEnabled: cancelAndReschedulingOptionsEnabled,
                }
              : {}),
            ...(shortNoticeBookingsEnabled !==
            partner.shortNoticeSettings?.enabled
              ? { shortNoticeBookingsEnabled: shortNoticeBookingsEnabled }
              : {}),
            ...(partnerLevel !== partner.level ? { level: partnerLevel } : {}),

            ...(businessType !== partner.businessType ? { businessType } : {}),

            ...(pilotModeEnabled !== partner.pilotModeEnabled
              ? { pilotModeEnabled }
              : {}),
            ...(manualPhotographerSelectionEnabled !==
            partner.manualPhotographerSelectionEnabled
              ? {
                  manualPhotographerSelectionEnabled,
                }
              : {}),
            partnerId: partner.uid,
            ...(enforceEditingQAEnabled !== partner.enforceEditingQAEnabled
              ? {
                  enforceEditingQAEnabled,
                }
              : {}),
          },
        }).then((result) => partnerReload());
      }}
    >
      <div className="row align-items-start p-4">
        <div className="col">
          <h3>Basic information</h3>

          <div className="row">
            <div className="col">
              <label>Display name</label>
              <input
                required
                value={displayName ?? partner?.name}
                disabled={
                  commitUpdatePartnerBasicDetailsAsAdminMutation.loading
                }
                onChange={(e) => setDisplayName(e.target.value)}
                type="text"
                className="form-control"
                placeholder="e.g. Acme"
              />
            </div>

            <div className="col">
              <label>Legal name</label>
              <input
                required
                disabled={
                  commitUpdatePartnerBasicDetailsAsAdminMutation.loading
                }
                value={legalName ?? partner?.legalName}
                onChange={(e) => setLegalName(e.target.value)}
                type="text"
                className="form-control"
                placeholder="e.g. Acme inc"
              />
            </div>
          </div>

          <div className="p-2"></div>

          <div className="row">
            <div className="col">
              <label>URL slug </label>
              <input
                required
                disabled={
                  commitUpdatePartnerBasicDetailsAsAdminMutation.loading
                }
                value={partner?.slug ?? 'loading'}
                readOnly
                type="text"
                className="form-control"
                placeholder="e.g. acme"
              />
            </div>

            <div className="col">
              <label>Region</label>
              <input
                required
                disabled={
                  commitUpdatePartnerBasicDetailsAsAdminMutation.loading
                }
                value={partner?.region.name ?? 'loading'}
                readOnly
                type="text"
                className="form-control"
                placeholder="e.g. acme"
              />
            </div>
          </div>

          <div className="p-2"></div>

          <div className="row">
            <div className="col">
              <label>Snappr account manager</label>
              <select
                required
                value={accountManagerId ?? partnerAccountManagerId ?? ''}
                disabled={
                  commitUpdatePartnerBasicDetailsAsAdminMutation.loading
                }
                className="form-control d-inline"
                onChange={(e) => setAccountManagerId(e.target.value)}
              >
                <option value="">Select account manager</option>
                {(
                  partnerBasicDetailsByIdQuery.data?.accountManagerListAsAdmin
                    .edges ?? []
                ).map((accountManager) => (
                  <option key={accountManager.id} value={accountManager.id}>
                    {accountManager.fullName}
                  </option>
                ))}
              </select>
            </div>

            <div className="col">
              <label>Partner level</label>
              <select
                className="form-control d-inline"
                value={partnerLevel}
                required
                disabled={
                  commitUpdatePartnerBasicDetailsAsAdminMutation.loading
                }
                onChange={(e) => {
                  const value = e.target.value as
                    | 'bronze'
                    | 'silver'
                    | 'gold'
                    | '';
                  setPartnerLevel(value !== '' ? value : undefined);
                }}
              >
                <option value="">Select partner level</option>
                <option value="bronze">Bronze</option>
                <option value="silver">Silver</option>
                <option value="gold">Gold</option>
              </select>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <label>Business type</label>

              <select
                required
                value={businessType}
                disabled={
                  commitUpdatePartnerBasicDetailsAsAdminMutation.loading
                }
                onChange={(e) => {
                  const value = e.target.value as
                    | 'marketplace'
                    | 'e_commerce'
                    | 'other'
                    | '';
                  setBusinessType(value !== '' ? value : undefined);
                }}
                className="form-control d-inline"
              >
                <option value="">Select Business Type</option>
                <option value="marketplace">Marketplace</option>
                <option value="e_commerce">E-commerce</option>
                <option value="other">Other</option>
              </select>
            </div>
          </div>

          <div className="p-2"></div>

          <div className="row">
            <div className="col">
              <h3>Settings</h3>

              <div className="row">
                <div className="col">
                  <div className="form-check">
                    <input
                      id="strict-mode"
                      className="form-check-input"
                      type="checkbox"
                      disabled={
                        commitUpdatePartnerBasicDetailsAsAdminMutation.loading
                      }
                      checked={strictMode ?? partner?.strictMode}
                      onChange={(e) => setStrictMode(e.target.checked === true)}
                    />
                    <label htmlFor="strict-mode" className="form-check-label">
                      Only assign shoots to specialist photographers (Strict
                      mode)
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <div className="form-check">
                    <input
                      id="central-editing"
                      className="form-check-input"
                      type="checkbox"
                      checked={centralEditing ?? partner?.centralEditing}
                      disabled={
                        commitUpdatePartnerBasicDetailsAsAdminMutation.loading
                      }
                      onChange={(e) =>
                        setCentralEditing(e.target.checked === true)
                      }
                    />
                    <label
                      htmlFor="central-editing"
                      className="form-check-label"
                    >
                      Enable central editing
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <div className="form-check">
                    <input
                      id="referrals"
                      className="form-check-input"
                      type="checkbox"
                      disabled={
                        commitUpdatePartnerBasicDetailsAsAdminMutation.loading
                      }
                      checked={referralsEnabled ?? partner?.referralsEnabled}
                      onChange={(e) =>
                        setReferralsEnabled(e.target.checked === true)
                      }
                    />
                    <label htmlFor="referrals" className="form-check-label">
                      Referrals enabled
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <div className="form-check">
                    <input
                      id="editing-job"
                      className="form-check-input"
                      type="checkbox"
                      disabled={
                        commitUpdatePartnerBasicDetailsAsAdminMutation.loading
                      }
                      checked={
                        editingJobsEnabled ?? partner?.editingJobsEnabled
                      }
                      onChange={(e) =>
                        setEditingJobsEnabled(e.target.checked === true)
                      }
                    />
                    <label htmlFor="editing-job" className="form-check-label">
                      Editing Job enabled
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="form-check">
                    <input
                      id="real-estate-by-duration"
                      className="form-check-input"
                      type="checkbox"
                      disabled={
                        commitUpdatePartnerBasicDetailsAsAdminMutation.loading
                      }
                      checked={
                        realEstateBookingByDurationEnabled ??
                        partner?.realEstateBookingByDurationEnabled
                      }
                      onChange={(e) =>
                        setRealEstateBookingByDurationEnabled(
                          e.target.checked === true
                        )
                      }
                    />
                    <label
                      htmlFor="real-estate-by-duration"
                      className="form-check-label"
                    >
                      Real estate booking by duration
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="form-check">
                    <input
                      id="editing-free-credits"
                      className="form-check-input"
                      type="checkbox"
                      disabled={
                        commitUpdatePartnerBasicDetailsAsAdminMutation.loading
                      }
                      checked={
                        editingJobFreeCreditsEnabled ??
                        partner?.editingJobFreeCreditsEnabled
                      }
                      onChange={(e) =>
                        setEditingJobFreeCreditsEnabled(e.target.checked)
                      }
                    />
                    <label
                      htmlFor="editing-free-credits"
                      className="form-check-label"
                    >
                      Editing job free credits
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <div className="form-check">
                    <input
                      id="short-notice"
                      className="form-check-input"
                      type="checkbox"
                      disabled={
                        commitUpdatePartnerBasicDetailsAsAdminMutation.loading
                      }
                      checked={
                        shortNoticeBookingsEnabled ??
                        partner?.shortNoticeSettings?.enabled
                      }
                      onChange={(e) =>
                        setShortNoticeBookingsEnabled(e.target.checked)
                      }
                    />
                    <label htmlFor="short-notice" className="form-check-label">
                      Short notice bookings
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <div className="form-check">
                    <input
                      id="cancel-and-reschedule"
                      className="form-check-input"
                      type="checkbox"
                      disabled={
                        commitUpdatePartnerBasicDetailsAsAdminMutation.loading
                      }
                      checked={
                        cancelAndReschedulingOptionsEnabled ??
                        partner?.cancelAndReschedulingOptionsEnabled
                      }
                      onChange={(e) =>
                        setCancelAndReschedulingOptionsEnabled(e.target.checked)
                      }
                    />
                    <label
                      htmlFor="cancel-and-reschedule"
                      className="form-check-label"
                    >
                      Enable cancel and rescheduling options for photographers
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <div className="form-check">
                    <input
                      id="cancel-and-reschedule"
                      className="form-check-input"
                      type="checkbox"
                      disabled={
                        commitUpdatePartnerBasicDetailsAsAdminMutation.loading
                      }
                      checked={
                        rescheduleFeesWarningEnabled ??
                        partner?.rescheduleFeesWarningEnabled
                      }
                      onChange={(e) =>
                        setRescheduleFeesWarningEnabled(e.target.checked)
                      }
                    />
                    <label
                      htmlFor="cancel-and-reschedule"
                      className="form-check-label"
                    >
                      End customers pay for reschedules so show warnings on the
                      fees
                    </label>
                  </div>
                </div>
              </div>

              {ENABLE_PARTNER_PILOT_MODE === true && (
                <>
                  <div className="row">
                    <div className="col">
                      <div className="form-check">
                        <input
                          id="pilot-mode"
                          className="form-check-input"
                          type="checkbox"
                          disabled={
                            commitUpdatePartnerBasicDetailsAsAdminMutation.loading
                          }
                          checked={pilotModeEnabled === true}
                          onChange={(e) => {
                            setPilotModeEnabled(e.target.checked);
                            e.target.checked === false &&
                              setManualPhotographerSelectionEnabled(false);
                          }}
                        />
                        <label
                          htmlFor="pilot-mode"
                          className="form-check-label"
                        >
                          Enable Pilot Mode
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col" style={{ marginLeft: 20 }}>
                      <div className="form-check">
                        <input
                          id="manual-pg-selection"
                          className="form-check-input"
                          type="checkbox"
                          disabled={
                            commitUpdatePartnerBasicDetailsAsAdminMutation.loading ||
                            pilotModeEnabled === false
                          }
                          checked={
                            manualPhotographerSelectionEnabled ??
                            partner.manualPhotographerSelectionEnabled
                          }
                          onChange={(e) =>
                            setManualPhotographerSelectionEnabled(
                              e.target.checked
                            )
                          }
                        />
                        <label
                          htmlFor="manual-pg-selection"
                          className="form-check-label"
                        >
                          Enable Snappr manual PG selection
                        </label>
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div className="row">
                <div className="col">
                  <div className="form-check">
                    <input
                      id="enforce-editing-qa"
                      className="form-check-input"
                      type="checkbox"
                      disabled={
                        commitUpdatePartnerBasicDetailsAsAdminMutation.loading
                      }
                      checked={
                        enforceEditingQAEnabled ??
                        partner?.enforceEditingQAEnabled
                      }
                      onChange={(e) =>
                        setEnforceEditingQAEnabled(e.target.checked)
                      }
                    />
                    <label
                      htmlFor="enforce-editing-qa"
                      className="form-check-label"
                    >
                      Enforce Editing QA
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <div className="form-check">
                    <input
                      id="enable-ics-calendar-invites-on-ecx-notifications"
                      className="form-check-input"
                      type="checkbox"
                      disabled={
                        commitUpdatePartnerBasicDetailsAsAdminMutation.loading
                      }
                      checked={
                        enableIcsFileOnEcxEmails ??
                        partner.enableIcsFileOnEcxEmails
                      }
                      onChange={(e) =>
                        setEnableIcsFileOnEcxEmails(e.target.checked)
                      }
                    />
                    <label
                      htmlFor="enable-ics-calendar-invites-on-ecx-notifications"
                      className="form-check-label"
                    >
                      Enable ICS calendar invite on ECX notifications
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <div className="form-check">
                    <input
                      id="ecommerce-partner"
                      className="form-check-input"
                      type="checkbox"
                      disabled={
                        commitUpdatePartnerBasicDetailsAsAdminMutation.loading
                      }
                      checked={
                        ecommercePartnerEnabled ??
                        partner?.ecommercePartnerEnabled
                      }
                      onChange={(e) =>
                        setEcommercePartnerEnabled(e.target.checked)
                      }
                    />
                    <label
                      htmlFor="ecommerce-partner"
                      className="form-check-label"
                    >
                      Ecommerce partner
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col">
          <h3>Other</h3>
          <div className="row">
            <div className="col">
              <label>Shoot extra details</label>
              <p>
                Photographers will see this message for every new booking. This
                is a great way to remind them of the most important things to
                keep in mind for every shoot for this enterprise.
              </p>

              <textarea
                rows={10}
                required
                disabled={
                  commitUpdatePartnerBasicDetailsAsAdminMutation.loading
                }
                value={shootExtraDetails ?? partner?.shootExtraDetails}
                onChange={(e) => setShootExtraDetails(e.target.value)}
                className="form-control"
                placeholder="e.g. Acme"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row align-items-start px-4">
        <div className="col">
          <div className="row">
            <h3 className="col">
              Notes
              <a
                style={{ fontSize: 12 }}
                href="https://www.markdownguide.org/cheat-sheet/"
                target="_blank"
              >
                {' '}
                Markdown cheat sheet
              </a>
            </h3>

            {editNotes === true && <h3 className="col">Preview</h3>}
          </div>

          {editNotes === true ? (
            <>
              <div className="row">
                <div className="col">
                  <textarea
                    className="form-control"
                    rows={enterpriseAdminNotes?.split(/\r\n|\r|\n/).length}
                    value={enterpriseAdminNotes}
                    onChange={(e) => setEnterpriseAdminNotes(e.target.value)}
                    disabled={savingNotesPending}
                    placeholder="e.g. **Add markdown text here**"
                  />
                </div>

                <div
                  className="col"
                  style={{
                    borderTop: 'dashed 1px #C1C8D4',
                  }}
                >
                  <ReactMarkdown
                    children={
                      enterpriseAdminNotes ??
                      partner.enterpriseNotesAsAdmin?.text ??
                      ''
                    }
                    remarkPlugins={[remarkGfm]}
                  />
                </div>
              </div>
              <div className="p-2" />
            </>
          ) : (
            <div className="col">
              <div className="row">
                <div
                  className="col"
                  style={{
                    borderTop:
                      editNotes === false ? 'solid 1px #C1C8D4' : undefined,
                    padding: '5px',
                  }}
                >
                  <ReactMarkdown
                    children={
                      enterpriseAdminNotes ??
                      partner.enterpriseNotesAsAdmin?.text ??
                      ''
                    }
                    remarkPlugins={[remarkGfm]}
                  />
                </div>
                <div className="col" />
              </div>
            </div>
          )}

          {editNotes === true ? (
            <button
              type="button"
              className="col btn btn-primary"
              style={{ width: '120px' }}
              disabled={savingNotesPending}
              onClick={() => {
                setSavingNotes(
                  updatePartnerNotes({
                    variables: {
                      partnerId: partner.uid,
                      notes: enterpriseAdminNotes,
                      ...(enterpriseAdminNotes != null &&
                      enterpriseAdminNotes !==
                        partner.enterpriseNotesAsAdmin?.text
                        ? { notes: enterpriseAdminNotes }
                        : {}),
                    },
                  })
                    .then((result) => partnerReload())
                    .then(() => setEditNotes(false))
                );
              }}
            >
              Save notes
            </button>
          ) : (
            <button
              type="button"
              className="col btn btn-primary"
              style={{ width: '120px' }}
              onClick={() => setEditNotes(true)}
            >
              Edit notes
            </button>
          )}
        </div>
      </div>

      <div className="row m-2 justify-content-end">
        <button
          type="submit"
          className="btn btn-primary px-5 "
          disabled={
            !isSomethingEdited ||
            commitUpdatePartnerBasicDetailsAsAdminMutation.loading
          }
        >
          Save
        </button>
      </div>
    </form>
  );
};

export const EnterpriseDetailsRoute: React.FC<{
  session: { token: string; uid: string; expire: string };

  match: {
    params: { partnerId: string };
    url: string;
    path: string;
  };
  history;
  location;
}> = ({
  history,
  session,
  location,
  match,
  match: {
    params: { partnerId },
    path: basePath,
  },
}) => {
  const partnerQuery = useQuery<{
    partnerById: Partner;
  }>(
    gql`
      query PartnerById($partnerId: UUID!) {
        partnerById(uid: $partnerId) {
          uid
          name
          legalName
          slug
          region {
            name
            currency
          }
          accountManagerEmail
          shootExtraDetails
          strictMode
          centralEditing
          referralsEnabled
          editingJobsEnabled
          editingJobFreeCreditsEnabled
          rescheduleFeesWarningEnabled
          realEstateBookingByDurationEnabled
          cancelAndReschedulingOptionsEnabled
          enforceEditingQAEnabled
          enableIcsFileOnEcxEmails
          ecommercePartnerEnabled
          shortNoticeSettings {
            enabled
          }
          level
          businessType
          pilotModeEnabled
          manualPhotographerSelectionEnabled
          enterpriseNotesAsAdmin {
            id
            text
          }
        }
      }
    `,
    { variables: { partnerId } }
  );

  const partner = partnerQuery.data?.partnerById;

  const selectedTab =
    qs.parse(location.search).selectedTab ?? TabCode.COMPANY_INFORMATION;

  return (
    <div>
      <div className="d-flex justify-content-between  align-items-center mb-3">
        <HeaderLinks className="align-items-center d-flex">
          <NavLink className="mb-0" exact to={'/enterprises'}>
            <h2>Enterprise</h2>
          </NavLink>

          <h2 style={{ marginLeft: 10, marginRight: 10, fontWeight: 'normal' }}>
            /
          </h2>

          <h2 style={{ margin: 0 }}>{partner?.name}</h2>
        </HeaderLinks>

        <GodModeLink session={session} partnerId={partnerId} />
      </div>

      <div className="d-flex flex-column" style={{ minHeight: 400 }}>
        <div className="card flex-1">
          <div className="card-header">
            <ul className="nav nav-tabs card-header-tabs">
              <li className="nav-item">
                <Link
                  style={{
                    borderBottomRightRadius: 0,
                    borderBottomLeftRadius: 0,
                  }}
                  className={
                    'btn btn-link nav-link' +
                    (selectedTab !== TabCode.COMPANY_INFORMATION
                      ? ''
                      : ' active')
                  }
                  to={{
                    ...location,
                    search: qs.stringify({
                      ...qs.parse(location.search),
                      selectedTab: TabCode.COMPANY_INFORMATION,
                    }),
                  }}
                >
                  <strong className="mb-0">Company information</strong>
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  style={{
                    borderBottomRightRadius: 0,
                    borderBottomLeftRadius: 0,
                  }}
                  className={
                    'btn btn-link nav-link' +
                    (selectedTab !== TabCode.GUIDELINES_AND_PACKAGES
                      ? ''
                      : ' active')
                  }
                  to={{
                    ...location,
                    search: qs.stringify({
                      ...qs.parse(location.search),
                      selectedTab: TabCode.GUIDELINES_AND_PACKAGES,
                    }),
                  }}
                >
                  <strong className="mb-0">Guidelines and packages</strong>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  style={{
                    borderBottomRightRadius: 0,
                    borderBottomLeftRadius: 0,
                  }}
                  className={
                    'btn btn-link nav-link' +
                    (selectedTab !== TabCode.CONTRACTS ? '' : ' active')
                  }
                  to={{
                    ...location,
                    search: qs.stringify({
                      ...qs.parse(location.search),
                      selectedTab: TabCode.CONTRACTS,
                    }),
                  }}
                >
                  <strong className="mb-0">Contracts</strong>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  style={{
                    borderBottomRightRadius: 0,
                    borderBottomLeftRadius: 0,
                  }}
                  className={
                    'btn btn-link nav-link' +
                    (selectedTab !== TabCode.BILLING ? '' : ' active')
                  }
                  to={{
                    ...location,
                    search: qs.stringify({
                      ...qs.parse(location.search),
                      selectedTab: TabCode.BILLING,
                    }),
                  }}
                >
                  <strong className="mb-0">Billing</strong>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  style={{
                    borderBottomRightRadius: 0,
                    borderBottomLeftRadius: 0,
                  }}
                  className={
                    'btn btn-link nav-link' +
                    (selectedTab !== TabCode.INVOICES ? '' : ' active')
                  }
                  to={{
                    ...location,
                    search: qs.stringify({
                      ...qs.parse(location.search),
                      selectedTab: TabCode.INVOICES,
                    }),
                  }}
                >
                  <strong className="mb-0">Invoices</strong>
                </Link>
              </li>
            </ul>
          </div>

          {partnerQuery.loading || partner == null ? (
            <LoadingSpinnerCentered />
          ) : TabCode.GUIDELINES_AND_PACKAGES === selectedTab ? (
            <ProductGuidelinesSection
              session={session}
              partner={{ name: partner.name, uid: partner.uid }}
              location={location}
              history={history}
            />
          ) : TabCode.COMPANY_INFORMATION === selectedTab ? (
            <EnterpriseDetailsInner
              partner={partner}
              partnerReload={partnerQuery.refetch}
            />
          ) : TabCode.INVOICES === selectedTab ? (
            <EnterpriseInvoicesSection
              session={session}
              partner={{
                name: partner.name,
                uid: partner.uid,
                currency: partner.region.currency,
              }}
              location={location}
              history={history}
            />
          ) : TabCode.BILLING === selectedTab ? (
            <EnterpriseBillingSection
              session={session}
              partner={{
                name: partner.name,
                uid: partner.uid,
                currency: partner.region.currency,
              }}
              location={location}
            />
          ) : TabCode.CONTRACTS === selectedTab ? (
            <EnterpriseContractSection
              session={session}
              partner={{
                name: partner.name,
                uid: partner.uid,
                currency: partner.region.currency,
              }}
              location={location}
            />
          ) : (
            <div className="d-flex justify-content-center align-items-center mb-3 mt-3">
              <h3>{selectedTab}</h3>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
