import * as React from 'react';
import qs from 'query-string';
import { RouteComponentProps } from 'react-router-dom';
import { ensureString } from '../../../utils/strings';
import Select from 'react-select';

export const MultiSelectFilter = ({
  filterName,
  options,
  location,
  history,
}: {
  filterName: string;
  options: { value: string; label: string }[];
  location: RouteComponentProps['location'];
  history: RouteComponentProps['history'];
}) => {
  const filterString = qs.parse(location.search)[filterName];
  const selectedValues = filterString
    ? ensureString(filterString ?? '')?.split(',')
    : [];

  const selectedOptions =
    selectedValues && options.filter((o) => selectedValues.includes(o.value));

  return (
    <div>
      <label
        style={{
          textTransform: 'capitalize',
          color: '#71767E',
          marginBottom: 4,
          fontSize: 12,
        }}
      >
        {filterName}
      </label>

      <div
        style={{
          minWidth: 150,
          marginRight: 16,
          position: 'relative',
        }}
      >
        <div style={{ minWidth: 100 }}>
          <Select
            isMulti
            value={selectedOptions}
            options={options}
            placeholder="Select"
            onChange={(selectedOptions) => {
              const selected = (selectedOptions || []).map((s) => s.value);

              history.push({
                ...location,
                search: qs.stringify({
                  ...qs.parse(location.search),
                  [filterName]:
                    selected.length > 0 ? selected.join(',') : undefined,
                }),
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};
