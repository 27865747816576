import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import BaseLayout from '../../components/BaseLayout';
import { HeadshotSubmissionListRoute } from './list';
import { HeadshotSubmissionDetailsRoute } from './details';

export const HeadShotsQCRoute = ({
  session,
  match,
  match: { path: basePath },
  history,
  location,
}) => (
  <Switch>
    <Route
      render={() => (
        <BaseLayout session={session} match={match}>
          <Switch>
            <Route
              exact
              path={basePath}
              render={(props) => (
                <HeadshotSubmissionListRoute
                  {...props}
                  location={location}
                  session={session}
                  history={history}
                />
              )}
            />

            <Route
              exact
              path={`${basePath}/:submissionId`}
              render={(props) => {
                return (
                  <HeadshotSubmissionDetailsRoute
                    {...props}
                    location={location}
                    session={session}
                    history={history}
                  />
                );
              }}
            />

            <Route
              render={({ location }) => (
                <Redirect to={{ ...location, pathname: basePath }} />
              )}
            />
          </Switch>
        </BaseLayout>
      )}
    />
  </Switch>
);
