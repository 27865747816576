import React from 'react';

export function CopyIdField({ id }: { id: string }) {
  const inputRef = React.createRef<HTMLInputElement>();

  return (
    <div className="input-group input-group-sm">
      <input
        type="text"
        className="form-control"
        readOnly={true}
        ref={inputRef}
        value={id}
        style={{ maxWidth: 320, textOverflow: 'ellipsis' }}
      />
      <div className="input-group-append">
        <button
          style={{ zIndex: 0 }}
          className="btn btn-primary"
          onClick={() => {
            if (inputRef.current != null) {
              inputRef.current.select();
              document.execCommand('copy');
            }
          }}
        >
          <i className="fa fa-copy" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
}
