import * as React from 'react';

import { usePromise } from '../../components/usePromise';
import { gql, useMutation, useQuery } from '../../components/ApolloClient';
import { LoadingSpinnerCentered } from '../../components/LoadingSpinner';
import { Modal } from '../../components/Modal';
import { COLOR_PALLETTE } from '../../constants';

import closeIcon from '../../components/img/close-icon.svg';
import { formatCredits } from '../../utils';

export const AddSceneToProduct = ({
  modalParams,
  onDismiss,
  productJobId,
  productName,
}: {
  modalParams?: {
    step?: string;
    return_step?: string;

    sceneIndex?: number;
  };
  productJobId?: string;
  onDismiss: () => void;
  productName: string;
}) => {
  const onNextPromiser = usePromise();

  const [sceneDescription, setSceneDescription] = React.useState<string>();

  const [
    createProductScenesForEcommerceProduct,
    createProductScenesForEcommerceProductMutation,
  ] = useMutation<
    {
      ecommerceProductSceneCreate: {
        success: boolean;
        sceneIds: string[];
      };
    },
    {
      aiProductJobId: string;
      scenes: { prompt }[];
      createdFromAdmin?: boolean;
    }
  >(
    gql`
      mutation CreateEcommerceProductJobSceneFromDashboardIntoSnapprAi(
        $aiProductJobId: String!
        $scenes: [EcommerceProductSceneInput!]!
        $createdFromAdmin: Boolean
      ) {
        ecommerceProductSceneCreate(
          input: {
            aiProductJobId: $aiProductJobId
            scenes: $scenes
            createdFromAdmin: $createdFromAdmin
          }
        ) {
          success
          sceneIds
        }
      }
    `
  );

  const disableButton =
    sceneDescription == null || sceneDescription === '' || productJobId == null;

  const [
    promptSourceIsCustomer,
    setPromptSourceIsCustomer,
  ] = React.useState<boolean>(false);

  return (
    <Modal
      aria-label="product-job-create"
      style={{
        padding: 0,
        margin: 0,
        width: 520,
        display: 'flex',
        flexDirection: 'column',
        marginTop: 91,
        marginBottom: 91,
      }}
    >
      <div
        style={{ position: 'relative' }}
        className="bg-white p-3 pt-md-3 pt-4 rounded d-flex flex-column flex-1"
      >
        {onNextPromiser.isPending ? (
          <LoadingSpinnerCentered />
        ) : (
          <>
            <div className="modal-container">
              <button
                onClick={onDismiss}
                style={{
                  position: 'absolute',
                  right: '0px',
                  top: '0px',
                  width: 40,
                  height: 40,
                  cursor: 'pointer',
                  border: 'none',
                  backgroundColor: 'white',
                }}
              >
                <img src={closeIcon} alt="close" />
              </button>

              <div className="container">
                <h4 className="title">Describe the scene you want</h4>

                <p className="description">
                  Describe how you want “{productName}” to be staged like.
                  Include detailed descriptions of the background, props, mood,
                  style and what to exclude to get better results. We will
                  deliver 4 images per scene.
                </p>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                  }}
                >
                  <textarea
                    data-test="create-scene-input"
                    rows={5}
                    className="text-area"
                    placeholder="e.g. My product from the front on a circular platform in the middle of a green forest and with leafs coming in from the bottom left, exclude people’s hands..."
                    value={
                      sceneDescription != null && sceneDescription != null
                        ? sceneDescription
                        : ''
                    }
                    name="comments"
                    disabled={
                      createProductScenesForEcommerceProductMutation.loading
                    }
                    onChange={({ target: { value } }) =>
                      setSceneDescription(value)
                    }
                  />
                </div>

                <div className="prompt-source-input">
                  <input
                    type="checkbox"
                    id="promptSource"
                    checked={promptSourceIsCustomer}
                    onChange={() =>
                      setPromptSourceIsCustomer(
                        (promptCustomer) => !promptCustomer
                      )
                    }
                  />
                  <label className="prompt-source-box" htmlFor="promptSource">
                    This prompt scene is customer generated
                  </label>
                </div>
              </div>

              <button
                disabled={disableButton}
                className={
                  disableButton
                    ? 'tw-bg-slate-200 tw-text-white tw-border-none tw-p-2  '
                    : 'tw-bg-slate-900 tw-text-white tw-border-none tw-p-2 '
                }
                onClick={() => {
                  if (
                    sceneDescription == null ||
                    sceneDescription === '' ||
                    productJobId == null
                  )
                    return;

                  createProductScenesForEcommerceProduct({
                    variables: {
                      aiProductJobId: productJobId,
                      scenes: [{ prompt: sceneDescription }],
                      createdFromAdmin: !promptSourceIsCustomer,
                    },
                  }).then(() => {
                    onDismiss();
                  });
                }}
              >
                Create Scene
              </button>
            </div>

            <style jsx>{`
              .modal-container {
                position: relative;
                display: flex;
                flex-direction: column;
                z-index: 10000;
                background-color: 'rgba(20, 27, 36, 0.8)';
              }

              .container {
                display: flex;
                flex-direction: column;
                gap: 16px;
                padding: 32px 16px 16px 16px;
                flex: 1;
                min-height: 0;
                overflow: hidden;
                background-color: white;
              }

              .title {
                font-size: 24px;
                text-align: center;
              }

              .text-area {
                border: 1px solid ${COLOR_PALLETTE.GRAY_2};
                padding: 16px;
                border-radius: 8px;
                font-size: 16;
                color: ${COLOR_PALLETTE.GRAY_5};
                outline: none;
              }

              .text-area::placeholder {
                color: ${COLOR_PALLETTE.GRAY_3};
              }

              .description {
                font-size: 14px;
                text-align: center;
                margin-bottom: 0px;
                color: ${COLOR_PALLETTE.GRAY_4};
              }

              .prompt-source-input {
                display: flex;
                gap: 4px;
                align-items: center;
              }

              .prompt-source-box {
                margin-bottom: 0px;
                color: ${COLOR_PALLETTE.GRAY_4};
              }
            `}</style>
          </>
        )}
      </div>
    </Modal>
  );
};

export const UpgradeScenePackage = ({
  onDismiss,
  modalParams,
}: {
  modalParams;
  onDismiss: () => void;
}) => {
  const sceneId = modalParams?.sceneId;
  const partnerId = modalParams?.partnerId;

  const priceValidationPromise = usePromise<{
    ecommerceValidatePrice?: {
      pricePerScene: number;
      totalPrice: number;
      validationToken: string;
    };
  }>();

  const upgradePromise = usePromise();

  const ecommerceValidatePrice =
    priceValidationPromise.result?.ecommerceValidatePrice;

  const packageQuery = useQuery<{
    ecommercePackageList?: {
      edges: {
        id: string;
        displayName?: string;
        price?: number;
      }[];
    };
  }>(
    gql`
      query EcommerceJobQueryOnLiveGenFlow($partnerId: ID!) {
        ecommercePackageList(partnerId: $partnerId) {
          edges {
            id
            price
            displayName
          }
        }
      }
    `,
    {
      variables: { partnerId },
      skip: partnerId == null,
    }
  );

  const packages = packageQuery.data?.ecommercePackageList?.edges || [];

  const [packageId, setPackageId] = React.useState<string | undefined>(
    undefined
  );

  const [validatePrice] = useMutation<
    {
      ecommerceProductSceneValidatePrice: {
        pricePerScene: number;
        totalPrice: number;
        validationToken: string;
      };
    },
    {
      numberOfScenes: number;
      productId: string;
    }
  >(
    gql`
      mutation EcommerceValidatePriceAdmin(
        $productId: ID!
        $numberOfScenes: Int!
      ) {
        ecommerceProductSceneValidatePrice(
          input: { productId: $productId, numberOfScenes: $numberOfScenes }
        ) {
          pricePerScene
          totalPrice
          validationToken
        }
      }
    `
  );

  const price = ecommerceValidatePrice?.totalPrice;

  const disableButton = packageId == null || price == null;

  const [upgradePackage] = useMutation<
    { success: boolean },
    {
      packageId: string;
      sceneId: string;
      validationToken: string;
    }
  >(
    gql`
      mutation ecommerceSendPayment(
        $sceneId: ID!
        $packageId: ID!
        $validationToken: String!
      ) {
        ecommercePartnerProductSceneUpgradeAdmin(
          input: {
            sceneId: $sceneId
            packageId: $packageId
            validationToken: $validationToken
          }
        ) {
          success
        }
      }
    `
  );

  return (
    <Modal
      aria-label="product-job-create"
      style={{
        padding: 0,
        margin: 0,
        width: 520,
        display: 'flex',
        flexDirection: 'column',
        marginTop: 91,
        marginBottom: 91,
      }}
    >
      <div
        style={{ position: 'relative' }}
        className="bg-white p-3 pt-md-3 pt-4 rounded d-flex flex-column flex-1"
      >
        {
          <>
            <div className="modal-container">
              <div className="container">
                <button
                  onClick={onDismiss}
                  style={{
                    position: 'absolute',
                    right: '0px',
                    top: '0px',
                    width: 40,
                    height: 40,
                    cursor: 'pointer',
                    border: 'none',
                    backgroundColor: 'white',
                  }}
                >
                  <img src={closeIcon} alt="close" />
                </button>
                <h4 className="title">Package upgrade</h4>

                <select
                  value={packageId}
                  onChange={({ target: { value } }) => {
                    setPackageId(value);
                    priceValidationPromise.setPromise(
                      validatePrice({
                        variables: {
                          productId: value,
                          numberOfScenes: 1,
                        },
                      }).then((res) => ({
                        ecommerceValidatePrice:
                          res.data?.ecommerceProductSceneValidatePrice,
                      }))
                    );
                  }}
                >
                  {packageQuery.loading ? (
                    <option value="">Loading...</option>
                  ) : (
                    <>
                      <option value="">Free</option>
                      {packages.map(({ id, displayName }) => (
                        <option key={id} value={id}>
                          {displayName}
                        </option>
                      ))}
                    </>
                  )}
                </select>

                {
                  <div>
                    Price to charge:
                    {price != null && `${formatCredits(price)} credits`}
                  </div>
                }

                <button
                  disabled={disableButton}
                  className={
                    disableButton
                      ? 'tw-bg-slate-200 tw-text-white tw-border-none tw-p-2  '
                      : 'tw-bg-slate-900 tw-text-white tw-border-none tw-p-2 '
                  }
                  onClick={() => {
                    if (
                      packageId == null ||
                      sceneId == null ||
                      ecommerceValidatePrice?.validationToken == null
                    )
                      return;

                    upgradePromise.setPromise(
                      upgradePackage({
                        variables: {
                          packageId: packageId,
                          sceneId: sceneId,
                          validationToken:
                            ecommerceValidatePrice?.validationToken,
                        },
                      }).then(() => onDismiss())
                    );
                  }}
                >
                  Upgrade
                </button>
              </div>
            </div>

            <style jsx>{`
              .modal-container {
                position: relative;
                display: flex;
                flex-direction: column;
                z-index: 10000;
                background-color: 'rgba(20, 27, 36, 0.8)';
                padding: 20px;
              }

              .container {
                display: flex;
                flex-direction: column;
                gap: 16px;
                padding: 32px 16px 16px 16px;
                flex: 1;
                min-height: 0;
                overflow: hidden;
                background-color: white;
              }

              .title {
                font-size: 24px;
                text-align: center;
              }

              .text-area {
                border: 1px solid ${COLOR_PALLETTE.GRAY_2};
                padding: 16px;
                border-radius: 8px;
                font-size: 16;
                color: ${COLOR_PALLETTE.GRAY_5};
                outline: none;
              }

              .text-area::placeholder {
                color: ${COLOR_PALLETTE.GRAY_3};
              }

              .description {
                font-size: 14px;
                text-align: center;
                margin-bottom: 0px;
                color: ${COLOR_PALLETTE.GRAY_4};
              }

              .prompt-source-input {
                display: flex;
                gap: 4px;
                align-items: center;
              }

              .prompt-source-box {
                margin-bottom: 0px;
                color: ${COLOR_PALLETTE.GRAY_4};
              }
            `}</style>
          </>
        }
      </div>
    </Modal>
  );
};
