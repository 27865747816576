import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import ContentWrapper from '../../components/ContentWrapper';
import Component2 from '@reach/component-component';
import { useQuery, gql, useMutation } from '../../components/ApolloClient';
import InView from 'react-intersection-observer';
import qs from 'query-string';
import { ensureString } from '../../utils/strings';
import Select from 'react-select';
import { formatMoneyCents } from '../../utils';

const REGION = 'region';
const TYPE = 'type';
const SHOOTTYPE = 'shootType';
const STATUS = 'status';

const ELLIPSIS_STYLE = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
} as const;

const customStyles = {
  valueContainer: (provided, state) => ({
    ...provided,
    marginLeft: 70,
  }),
};

// Types and queries
type Package = {
  uid: string;
  displayName?: string;
  shootType: {
    name: string;
    displayName: string;
  };
  isActive: boolean;
  type: string;
  region: {
    name: string;
    currency: string;
  };
  price?: string;
  duration?: string;
};

type packagesQueryType = {
  packagesAsAdmin: {
    cursor: string;
    edges: Package[];
  };
};

type optionQueryType = {
  regionListAsAdmin: {
    edges: {
      uid: string;
      name: string;
      currency: string;
    }[];
  };
  getPublicShootTypes: {
    displayName: string;
    category: string;
  }[];
};

const gqlProductViewOptionsQuery = gql`
  query regionQuery {
    regionListAsAdmin {
      edges {
        uid
        currency
        name
      }
    }
    getPublicShootTypes {
      category
      displayName
    }
  }
`;

const gqlPackageQuery = gql`
  query packageQuery(
    $first: Int
    $cursor: String
    $regionIds: [String]
    $types: [String]
    $shootTypes: [String]
    $status: [String]
  ) {
    packagesAsAdmin(
      first: $first
      cursor: $cursor
      regionIds: $regionIds
      types: $types
      shootTypes: $shootTypes
      status: $status
    ) {
      cursor
      edges {
        uid
        displayName(type: "PROVIDER")
        isActive
        shootType {
          name
          displayName
        }
        type
        region {
          name
          currency
        }
        price
        duration
      }
    }
  }
`;

// Filters
const MultiSelectFiler = ({
  filterName,
  options,
  location,
  history,
}: {
  filterName: string;
  options;
  location;
  history;
}) => {
  const filterString = qs.parse(location.search)[filterName];
  const selectedValues = filterString
    ? ensureString(filterString ?? '')?.split(',')
    : [];

  const selectedOptions =
    selectedValues && options.filter((o) => selectedValues.includes(o.value));

  return (
    <div
      style={{
        minWidth: 150,
        marginRight: 16,
        position: 'relative',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 7,
          left: 10,
          zIndex: 2,
          textTransform: 'capitalize',
          color: '#C1C8D4',
        }}
      >
        {filterName != 'dueAt' ? filterName : 'Due in'}
      </div>
      <div style={{ minWidth: 100 }}>
        <Select
          isMulti
          value={selectedOptions}
          options={options}
          styles={customStyles}
          placeholder=""
          onChange={(selectedOptions) => {
            const selected = (selectedOptions || []).map((s) => s.value);

            history.push({
              ...location,
              search: qs.stringify({
                ...qs.parse(location.search),
                [filterName]:
                  selected.length > 0 ? selected.join(',') : undefined,
              }),
            });
          }}
        />
      </div>
    </div>
  );
};

// Table components
function HeaderTableRow() {
  return (
    <tr>
      <th style={ELLIPSIS_STYLE} scope="col">
        Display Name
      </th>
      <th style={ELLIPSIS_STYLE} scope="col">
        Product UID
      </th>
      <th style={ELLIPSIS_STYLE} scope="col">
        Region
      </th>
      <th style={ELLIPSIS_STYLE} scope="col">
        Type
      </th>
      <th style={ELLIPSIS_STYLE} scope="col">
        Shoot Type
      </th>
      <th style={ELLIPSIS_STYLE} scope="col">
        Price
      </th>
      <th style={ELLIPSIS_STYLE} scope="col">
        Status
      </th>
      <th style={ELLIPSIS_STYLE} scope="col">
        Duration (min)
      </th>
    </tr>
  );
}

function LoadingTableRows({ rows, cols }: { rows: Number; cols: Number }) {
  return (
    <>
      {Array.from(Array(rows)).map((i, indexRows) => (
        <tr key={indexRows}>
          {Array.from(Array(cols)).map((i, indexCols) => (
            <td key={indexCols}>...</td>
          ))}
        </tr>
      ))}
    </>
  );
}

function FormattedTableRows({ packages }: { packages: Package[] | undefined }) {
  const tableRows = packages?.map((packageItem) => (
    <tr key={packageItem?.uid}>
      <td style={{ fontWeight: 'bold' }}>{packageItem?.displayName}</td>
      <td>{packageItem?.uid}</td>
      <td>{packageItem?.region?.name}</td>
      <td>{packageItem?.type}</td>
      <td>{packageItem?.shootType?.displayName}</td>
      <td>
        {formatMoneyCents(
          packageItem?.price,
          packageItem?.region?.currency == null
            ? null
            : {
                currency: packageItem?.region?.currency,
              }
        )}
      </td>
      <td>{packageItem?.isActive ? 'Active' : 'Inactive'}</td>
      <td>{packageItem?.duration}</td>
    </tr>
  ));
  return <>{tableRows}</>;
}

// This function populates table rows
function DataRowsChunk({
  cursor,
  onMore,
  selectedRegions,
  selectedTypes,
  selectedShootTypes,
  selectedStatus,
}: {
  cursor: string | null;
  onMore?: (p: { nextCursor: string }) => void;
  selectedRegions?: string[];
  selectedTypes?: string[];
  selectedShootTypes?: string[];
  selectedStatus?: string[];
}) {
  // Query
  const packageQuery = useQuery<packagesQueryType>(gqlPackageQuery, {
    variables: {
      cursor: cursor,
      first: cursor === null ? 20 : 40,
      regionIds: selectedRegions,
      types: selectedTypes,
      shootTypes: selectedShootTypes,
      status: selectedStatus,
    },
  });
  // Gets data from query
  const packages = packageQuery.data?.packagesAsAdmin?.edges;
  // Cursor
  const nextCursor = packageQuery.data?.packagesAsAdmin?.cursor;
  const contentNotReady = packages === null || packageQuery.loading;

  return (
    <>
      {onMore == null || nextCursor == null ? null : (
        <InView>
          {({ inView, ref }) => (
            <tr ref={ref}>
              {inView && <Component2 didMount={() => onMore({ nextCursor })} />}
            </tr>
          )}
        </InView>
      )}
      {contentNotReady ? (
        <LoadingTableRows rows={7} cols={7} />
      ) : (
        <FormattedTableRows packages={packages} />
      )}
      {onMore == null || nextCursor == null ? null : (
        <InView>
          {({ inView, ref }) => (
            <tr ref={ref}>
              {inView && <Component2 didMount={() => onMore({ nextCursor })} />}
            </tr>
          )}
        </InView>
      )}
    </>
  );
}

// Option objects
const createRegionOptions = (regionQuery: optionQueryType | undefined) => {
  if (!regionQuery) return [];
  return regionQuery?.regionListAsAdmin?.edges?.map((region) => ({
    label: region.name,
    value: region.uid,
  }));
};

const createShoottypeOptions = (regionQuery: optionQueryType | undefined) => {
  if (!regionQuery) return [];
  return regionQuery?.getPublicShootTypes?.map((shootType) => ({
    label: shootType.displayName,
    value: shootType.category,
  }));
};

const typeOptions = [
  { label: 'shoot', value: 'shoot' },
  { label: 'virtualTour', value: 'virtualTour' },
  { label: 'virtualTourAndPhotos', value: 'virtualTourAndPhotos' },
  { label: 'adminShoot', value: 'adminShoot' },
  { label: 'editingService', value: 'editingService' },
];

const statusOptions = [
  { label: 'active', value: 'active' },
  { label: 'inactive', value: 'inactive' },
];

export function ProductsView({
  location,
  session,
  history,
}: {
  location: RouteComponentProps['location'];
  session: { token: string; uid: string };
  history;
}) {
  // State
  const [cursors, setCursors] = React.useState<string[]>([]);

  const optionsQuery = useQuery<optionQueryType>(gqlProductViewOptionsQuery);
  const regionOptions = createRegionOptions(optionsQuery?.data);
  const shootTypeOptions = createShoottypeOptions(optionsQuery?.data);

  const selectedRegions = React.useMemo(
    () =>
      ensureString(qs.parse(location.search)[REGION] ?? null)?.split(',') ?? [],
    [location]
  );

  const selectedTypes = React.useMemo(
    () =>
      ensureString(qs.parse(location.search)[TYPE] ?? null)?.split(',') ?? [],
    [location]
  );

  const selectedShootTypes = React.useMemo(
    () =>
      ensureString(qs.parse(location.search)[SHOOTTYPE] ?? null)?.split(',') ??
      [],
    [location]
  );

  const selectedStatus = React.useMemo(
    () =>
      ensureString(qs.parse(location.search)[STATUS] ?? null)?.split(',') ?? [],
    [location]
  );

  return (
    <section>
      <h2> Products View </h2>
      <ContentWrapper>
        <div className="table-responsive">
          <div style={{ margin: 8, display: 'flex' }}>
            <MultiSelectFiler
              filterName={REGION}
              options={regionOptions}
              location={location}
              history={history}
            />
            <MultiSelectFiler
              filterName={TYPE}
              options={typeOptions}
              location={location}
              history={history}
            />
            <MultiSelectFiler
              filterName={SHOOTTYPE}
              options={shootTypeOptions}
              location={location}
              history={history}
            />
            <MultiSelectFiler
              filterName={STATUS}
              options={statusOptions}
              location={location}
              history={history}
            />
          </div>
          <table className="table" style={{ position: 'relative' }}>
            <thead style={{ color: '#71767E' }}>
              <HeaderTableRow />
            </thead>
            <tbody style={{ color: '#6C757D' }}>
              {[null, ...cursors].map((cursor, index) => (
                <DataRowsChunk
                  key={cursor ?? '1'}
                  selectedRegions={selectedRegions}
                  selectedTypes={selectedTypes}
                  selectedShootTypes={selectedShootTypes}
                  selectedStatus={selectedStatus}
                  cursor={cursor}
                  onMore={
                    index === cursors.length + 1 - 1
                      ? ({ nextCursor }) =>
                          setCursors((state) =>
                            state.includes(nextCursor)
                              ? state
                              : [...state, nextCursor]
                          )
                      : undefined
                  }
                />
              ))}
            </tbody>
          </table>
        </div>
      </ContentWrapper>
    </section>
  );
}
