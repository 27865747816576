export const SvgImg = ({
  src,
  alt,
  style,
  ...props
}: { src: string; style? } & Pick<
  React.ImgHTMLAttributes<HTMLImageElement>,
  'alt' | 'className' | 'height' | 'width'
>) => (
  <img
    alt={alt}
    src={src}
    style={{ width: 40, height: 40, ...style }}
    {...props}
  />
);
