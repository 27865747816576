import * as React from 'react';
import qs from 'query-string';
import uniqBy from 'lodash.uniqby';

import { Link, RouteComponentProps } from 'react-router-dom';

import { gql, useMutation } from '../../../components/ApolloClient';
import { LoadingSpinnerCentered } from '../../../components/LoadingSpinner';
import {
  QualityControlMediaCriteriaStatusEnum,
  QualityControlMediaStatusEnum,
  QualityControlStatusEnum,
  QUALITY_CONTROL_DEFAULT_NAME,
} from '../types';
import {
  badgeBgColorStatus,
  formatBytes,
  qualityControlStatus,
} from '../utils';
import { ensureString } from '../../../utils/strings';

import leftArrowImage from '../../../components/img/left-arrow.svg';
import rightArrowImage from '../../../components/img/right-arrow.svg';

import leftArrowDisabledImage from '../../../components/img/left-arrow-disabled.svg';
import rightArrowDisabledImage from '../../../components/img/right-arrow-disabled.svg';
import { KeyboardControlEvents } from '../../../components/KeyboardControlEvents';
import { useQualityControlByIdQuery } from '../components/useQualityControlByIdQuery';

type MediaAndCriteriaToReview = {
  id: string;
  imageId?: string;
  imageStatus?: QualityControlMediaStatusEnum;
  imageUrl?: string;
  imageName?: string;
  imageType?: string;
  imageWidth?: number;
  imageHeight?: number;
  fileSizeInBytes?: number;
  imagePosition?: number;
  criteriaId?: string;
  criteriaDescription?: string;
  criteriaStatus?: QualityControlMediaCriteriaStatusEnum;
  criteriaTotal?: number;
  criteriaPosition?: number;
};

const QualityControlImageWithCriteria = ({
  media,
  onCriteriaChange,
  disabled,
  previousImage,
  nextImage,

  history,
}: {
  media: MediaAndCriteriaToReview;
  onCriteriaChange: ({
    status,
  }: {
    status: QualityControlMediaCriteriaStatusEnum;
  }) => Promise<void>;
  disabled: boolean;
  previousImage?: string;
  nextImage?: string;

  history: RouteComponentProps['history'];
}) => {
  return (
    <div
      className="tw-flex tw-flex-1 tw-flex-col"
      style={{
        paddingLeft: 32,
        paddingRight: 32,
        paddingBottom: 32,
        paddingTop: 24,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div style={{ color: '#454F5C', fontSize: 12 }}>
            Acceptance criteria {(media.criteriaPosition ?? 0) + 1}/
            {media.criteriaTotal}
          </div>
          <div style={{ color: '#141B24', fontSize: 18, fontWeight: 700 }}>
            {media.criteriaDescription}
          </div>
        </div>

        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <button
            disabled={disabled}
            type="button"
            className="btn"
            style={{
              width: 260,
              height: 40,
              color:
                media.criteriaStatus ===
                QualityControlMediaCriteriaStatusEnum.REJECTED
                  ? '#ffffff'
                  : '#E11F59',
              borderColor: '#E11F59',
              backgroundColor:
                media.criteriaStatus ===
                QualityControlMediaCriteriaStatusEnum.REJECTED
                  ? '#E11F59'
                  : 'transparent',
            }}
            onClick={() => {
              onCriteriaChange({
                status: QualityControlMediaCriteriaStatusEnum.REJECTED,
              });
            }}
          >
            Reject
          </button>

          <div style={{ width: 16 }}></div>

          <button
            disabled={disabled}
            type="button"
            className="btn "
            style={{
              width: 260,
              height: 40,
              color:
                media.criteriaStatus ===
                QualityControlMediaCriteriaStatusEnum.ACCEPTED
                  ? '#ffffff'
                  : '#2FB67D',
              borderColor: '#2FB67D',
              backgroundColor:
                media.criteriaStatus ===
                QualityControlMediaCriteriaStatusEnum.ACCEPTED
                  ? '#2FB67D'
                  : 'transparent',
            }}
            onClick={() => {
              onCriteriaChange({
                status: QualityControlMediaCriteriaStatusEnum.ACCEPTED,
              });
            }}
          >
            Accept
          </button>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          height: 40,
          marginTop: 18,
        }}
      >
        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div
            className="tw-text-center"
            style={{
              color: '#71767E',
              fontSize: 14,
            }}
          >
            <React.Fragment>
              <span
                style={{ fontWeight: 700, color: '#454F5C', paddingRight: 16 }}
              >
                Image metadata:
              </span>

              {media.imageName != null && (
                <React.Fragment>
                  <span style={{ fontWeight: 400 }}>Name: </span>
                  <span style={{ fontWeight: 700, marginRight: 16 }}>
                    {media.imageName}
                  </span>
                </React.Fragment>
              )}

              {media.imageType != null && (
                <React.Fragment>
                  <span style={{ fontWeight: 400 }}>Type: </span>
                  <span style={{ fontWeight: 700, marginRight: 16 }}>
                    {media.imageType}
                  </span>
                </React.Fragment>
              )}

              {media.imageWidth != null && (
                <React.Fragment>
                  <span style={{ fontWeight: 400 }}>Width: </span>
                  <span style={{ fontWeight: 700, marginRight: 16 }}>
                    {media.imageWidth}
                  </span>
                </React.Fragment>
              )}

              {media.imageHeight != null && (
                <React.Fragment>
                  <span style={{ fontWeight: 400 }}>Height: </span>
                  <span style={{ fontWeight: 700, marginRight: 16 }}>
                    {media.imageHeight}
                  </span>
                </React.Fragment>
              )}

              {media.fileSizeInBytes != null && (
                <React.Fragment>
                  <span style={{ fontWeight: 400 }}>File size: </span>
                  <span style={{ fontWeight: 700, marginRight: 16 }}>
                    {formatBytes({ bytes: media.fileSizeInBytes })}
                  </span>
                </React.Fragment>
              )}
            </React.Fragment>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          {previousImage != null ? (
            <Link rel="noopener noreferrer" to={`?id=${previousImage}`}>
              <img src={leftArrowImage} alt="left" />
            </Link>
          ) : (
            <img src={leftArrowDisabledImage} alt="left-disabled" />
          )}

          <div style={{ width: 16 }}></div>

          {nextImage != null ? (
            <Link rel="noopener noreferrer" to={`?id=${nextImage}`}>
              <img src={rightArrowImage} alt="right" />
            </Link>
          ) : (
            <img src={rightArrowDisabledImage} alt="right-disabled" />
          )}
        </div>
      </div>

      <div className="tw-h-2" />

      <div className="tw-flex tw-flex-col tw-flex-1 tw-relative">
        <img
          // This key is important for us not to show an
          // outdated image while the new one loads
          key={media.imageUrl}
          className="
            tw-absolute tw-top-0 tw-left-0 tw-h-full tw-w-full
            tw-object-contain
          "
          src={media.imageUrl}
          alt={media.imageName}
        />
      </div>

      <KeyboardControlEvents
        left={() =>
          previousImage != null && history.push(`?id=${previousImage}`)
        }
        right={() => nextImage != null && history.push(`?id=${nextImage}`)}
      />
    </div>
  );
};

const IMAGES_TO_PRELOAD_COUNT = 3;

const QualityControlImages = ({
  imagesWithCriteria,
  totalImages,
  mediaCriteriaId,
  location,
  history,
  qualityControlId,
  isJobCompleted,
  jobName,
}: {
  imagesWithCriteria: (MediaAndCriteriaToReview | undefined)[];
  totalImages?: number;
  mediaCriteriaId?: string;
  location: RouteComponentProps['location'];
  history: RouteComponentProps['history'];
  qualityControlId: string;
  isJobCompleted: boolean;
  jobName?: string;
}) => {
  const [
    changeQualityControlMediaCriteriaStatus,
    changeQualityControlMediaCriteriaStatusMutation,
  ] = useMutation<
    {
      qualityControlMedia: {
        id: string;
        status: QualityControlMediaStatusEnum;
        criteria: {
          edges: {
            id: string;
            status: QualityControlMediaCriteriaStatusEnum;
          }[];
        };
      };
    },
    {
      status: QualityControlMediaCriteriaStatusEnum;
      qualityControlMediaId: string;
      qualityControlMediaCriteriaId: string;
    }
  >(
    gql`
      mutation QualityControlMediaCriteriaStatusChangeAtMedia(
        $qualityControlMediaCriteriaId: ID!
        $qualityControlMediaId: ID!
        $status: QualityControlMediaCriteriaStatusEnum!
      ) {
        qualityControlMediaCriteriaStatusChange(
          input: {
            qualityControlMediaCriteriaId: $qualityControlMediaCriteriaId
            qualityControlMediaId: $qualityControlMediaId
            status: $status
          }
        ) {
          qualityControlMedia {
            id
            status
            criteria {
              edges {
                id
                status
              }
            }
          }
        }
      }
    `
  );

  const currentImageCriteriaIndex =
    mediaCriteriaId != null
      ? imagesWithCriteria.findIndex((item) => item?.id === mediaCriteriaId)
      : 0;
  const currentImageCriteria = imagesWithCriteria[currentImageCriteriaIndex];

  const previousImage = imagesWithCriteria[currentImageCriteriaIndex - 1]?.id;
  const nextImage = imagesWithCriteria[currentImageCriteriaIndex + 1]?.id;

  const prevMediaList = React.useMemo(
    () =>
      uniqBy(
        imagesWithCriteria.slice(0, currentImageCriteriaIndex).reverse(),
        (i) => i?.imageUrl
      )
        .filter(
          (i) =>
            i?.imageUrl != null && i.imageUrl !== currentImageCriteria?.imageUrl
        )
        .slice(0, IMAGES_TO_PRELOAD_COUNT),
    [
      currentImageCriteria?.imageUrl,
      imagesWithCriteria,
      currentImageCriteriaIndex,
    ]
  );

  const nextMediaList = React.useMemo(
    () =>
      uniqBy(
        imagesWithCriteria.slice(currentImageCriteriaIndex + 1),
        (i) => i?.imageUrl
      )
        .filter(
          (i) =>
            i?.imageUrl != null && i.imageUrl !== currentImageCriteria?.imageUrl
        )
        .slice(0, IMAGES_TO_PRELOAD_COUNT),
    [
      currentImageCriteria?.imageUrl,
      imagesWithCriteria,
      currentImageCriteriaIndex,
    ]
  );

  const imagesToPreload = React.useMemo(
    () => [...prevMediaList, ...nextMediaList],
    [nextMediaList, prevMediaList]
  );

  return (
    <div
      className="tw-flex tw-flex-1 tw-flex-col"
      style={{
        backgroundColor: '#FFFFFF',
        marginTop: 20,
      }}
    >
      <div
        style={{
          color: '#454F5C',
          paddingLeft: 32,
          paddingTop: 24,
          paddingBottom: 24,
          paddingRight: 32,
          borderBottom: '1px solid #E8EDF5',
          justifyContent: 'space-between',
          flexDirection: 'row',
          display: 'flex',
        }}
      >
        <div
          style={{
            fontSize: 20,
            fontWeight: 700,
          }}
        >
          {jobName ?? QUALITY_CONTROL_DEFAULT_NAME}
        </div>

        <div
          style={{
            fontSize: 16,
            justifyContent: 'flex-end',
          }}
        >
          {currentImageCriteria?.imagePosition ?? 0}/{totalImages} evaluated
        </div>
      </div>

      {currentImageCriteria != null && (
        <QualityControlImageWithCriteria
          history={history}
          disabled={
            changeQualityControlMediaCriteriaStatusMutation.loading ||
            isJobCompleted
          }
          previousImage={previousImage}
          nextImage={nextImage}
          media={currentImageCriteria}
          onCriteriaChange={async ({
            status,
          }: {
            status: QualityControlMediaCriteriaStatusEnum;
          }) => {
            currentImageCriteria?.criteriaId != null &&
              currentImageCriteria?.imageId != null &&
              (await changeQualityControlMediaCriteriaStatus({
                variables: {
                  status,
                  qualityControlMediaCriteriaId:
                    currentImageCriteria?.criteriaId,
                  qualityControlMediaId: currentImageCriteria?.imageId,
                },
              }).then(() => {
                //TODO: handle errors

                if (nextImage == null) {
                  history.replace(
                    `/quality-control-view/${qualityControlId}/gallery?selectedTab=ALL`
                  );
                } else {
                  history.push({
                    ...location,
                    search: qs.stringify({
                      ...qs.parse(location.search),
                      id: nextImage,
                    }),
                  });
                }
              }));
          }}
        />
      )}

      {imagesToPreload.map((i, index) => (
        <React.Fragment key={[i?.imageUrl, index].join(':')}>
          {i?.imageUrl != null && (
            <img
              src={i.imageUrl}
              alt="prerender"
              // Render this image outside of the screen
              className="tw-fixed tw-right-[-999999px]"
            />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export const QualityControlDetails = ({
  match,
  session,
  session: { token },
  location,
  history,
}: {
  match: {
    params: { qualityControlJobId: string };
  };
  session: { token: string; uid: string };
  location: RouteComponentProps['location'];
  history: RouteComponentProps['history'];
}) => {
  const mediaCriteriaId = ensureString(qs.parse(location.search).id);

  const {
    params: { qualityControlJobId },
  } = match;

  const qualityControlQuery = useQualityControlByIdQuery({
    qualityControlJobId,
  });

  const qualityControlJob = qualityControlQuery.data?.qualityControlByIdAsAdmin;

  const qualityControlCriteriaList = qualityControlJob?.criteria?.edges;

  const imagesWithCriteria = qualityControlJob?.medias?.edges
    ?.filter((image) => image.id != null && image.large?.url != null)
    .map((media, index) => {
      return media.criteria?.edges?.map((criteria) => ({
        id: criteria.id,
        imageId: media.id,
        imageStatus: media.status,
        imageUrl: media.large?.url,
        imagePosition: index,
        criteriaId: criteria.criteriaId,
        criteriaTotal: qualityControlCriteriaList?.length,
        criteriaPosition: qualityControlCriteriaList?.findIndex(
          (qcCriteria) => qcCriteria.id === criteria.criteriaId
        ),
        criteriaDescription: qualityControlCriteriaList?.find(
          (qcCriteria) => qcCriteria.id === criteria.criteriaId
        )?.description,
        criteriaStatus: criteria.status,
        imageName: media.fileName,
        // TODO: format in a different way
        imageType: media.type,
        imageWidth: media.width,
        imageHeight: media.height,
        fileSizeInBytes: media.fileSize,
      }));
    })
    .flat();

  const isJobCompleted =
    qualityControlJob?.status === QualityControlStatusEnum.completed;

  // todo: if the job is completed display the gallery

  return qualityControlQuery != null && qualityControlJob != null ? (
    qualityControlJob ? (
      <>
        <div className="tw-flex tw-flex-1 tw-flex-col tw-font-nunito">
          <div className="tw-flex">
            <h4
              style={{
                color: '#1F6FFF',
                fontWeight: 'bold',
                fontSize: '22px',
                lineHeight: '29px',
              }}
            >
              <Link to="/quality-control-view">Workflows QC Queue </Link>

              <span style={{ color: '#141B24' }}>
                / {qualityControlJob.partner.name}
              </span>

              <span style={{ marginLeft: 16 }} />

              <span
                className="badge badge-primary text-uppercase"
                style={badgeBgColorStatus(qualityControlJob.status)}
              >
                {qualityControlStatus[qualityControlJob.status]}
              </span>
            </h4>
          </div>

          {imagesWithCriteria != null && (
            <QualityControlImages
              imagesWithCriteria={imagesWithCriteria.filter(
                (image) => image != null
              )}
              totalImages={qualityControlJob.medias?.total}
              mediaCriteriaId={mediaCriteriaId}
              location={location}
              history={history}
              qualityControlId={qualityControlJobId}
              isJobCompleted={isJobCompleted}
              jobName={qualityControlJob.name}
            />
          )}
        </div>
      </>
    ) : (
      // @TODO: improve
      <div>ERROR</div>
    )
  ) : (
    <LoadingSpinnerCentered />
  );
};
