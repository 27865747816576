import React, { useEffect, useMemo, useState } from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import qs from 'query-string';
import moment from 'moment-timezone';
import Select from 'react-select';
import upperFirst from 'lodash/upperFirst';
import Component2 from '@reach/component-component';
import InView from 'react-intersection-observer';
import download from 'downloadjs';

import {
  gql,
  useLazyQuery,
  useMutation,
  useQuery,
} from '../components/ApolloClient';
import BaseLayout from '../components/BaseLayout';
import { ensureString } from '../utils/strings';
import { BookingStatusBadge } from '../components/BookingStatusBadge';
import { PartnerLevelBadge } from '../components/PartnerLevelBadge';
import { PillBadgeList } from '../components/PillBadgeList';
import { CopyIdField } from '../components/CopyIdField';
import { createCSV } from './bookings/utils';
import { OrderByLink } from '../components/OrderByLink';
import { LoadingSpinnerCentered } from '../components/LoadingSpinner';
import { locationForModal, ModalRoute } from '../components/ModalRoute';
import { NotesModalBody, NotesModalForm } from '../components/NotesModal';
import { Modal } from '../components/Modal';

type NotesModalProps = {
  id: string;
  onDismiss: () => void;
};

const enterpriseTrackerJobList = gql`
  query EnterpriseTrackerJobList(
    $first: Int
    $cursor: String
    $statusFilter: [String]
    $levelFilter: [String]
    $jobTypeFilter: [String]
    $partnerFilter: [String]
    $excludePartnerFilter: [String]
    $dueAtFilter: [String]
    $clientTypeFilter: [String]
    $contractStatusFilter: [String]
    $searchFilter: [String]
    $sort: EnterpriseTrackerConnectionSortKeys
  ) {
    enterpriseTrackerJobListAsAdmin(
      first: $first
      cursor: $cursor
      statusFilter: $statusFilter
      levelFilter: $levelFilter
      jobTypeFilter: $jobTypeFilter
      partnerFilter: $partnerFilter
      excludePartnerFilter: $excludePartnerFilter
      dueAtFilter: $dueAtFilter
      clientTypeFilter: $clientTypeFilter
      contractStatusFilter: $contractStatusFilter
      searchFilter: $searchFilter
      sort: $sort
    ) {
      cursor
      edges {
        id

        booking {
          id
          shootType {
            displayName
          }
          createdAt
          startAt(forceShowAsAdmin: true)
          dueAt: photosDeliveryOverdueAtAsAdmin
          rawUploadedAt: rawPhotosSubmittedAtAsAdmin
          address
          partner {
            uid
            name
            level
            activeContract {
              id
              status
            }
          }
          provider {
            id
            publicFullName
          }
          status
          name
        }

        editingJob {
          id
          createdAt
          partner {
            uid
            name
            level
          }
          verticalType {
            displayName
          }
          dueAt
          editor {
            fullName
          }
          displayStatus
          name
        }

        tags {
          edges {
            id
            name
          }
        }

        enterpriseTrackerComments {
          edges {
            id
            text
          }
        }
      }
    }
  }
`;

type EnterpriseTrackerJobsQuery = {
  cursor?: string;
  statusFilter?: string;
  levelFilter?: string;
  jobTypeFilter?: string;
  partnerFilter?: string;
  excludePartnerFilter?: string;
  dueAtFilter?: string;
  clientTypeFilter?: string;
  contractStatusFilter?: string;
  q?: string;
};

type Booking = {
  id: string;
  shootType?: {
    displayName?: string;
  };
  createdAt?: string;
  startAt?: string;
  dueAt?: string;
  rawUploadedAt?: string;
  address?: string;
  partner?: Partner;
  provider?: { id: string; publicFullName?: string };
  status?: string;
  name?: string;
};

type EditingJob = {
  id: string;
  createdAt?: string;
  partner?: Partner;
  verticalType?: { displayName?: string };
  dueAt?: string;
  editor?: { fullName?: string };
  displayStatus?: string;
  rawUploadedAt: string;
  name?: string;
};

type Partner = {
  uid: string;
  name: string;
  level: string;
};

type PilotTrackerJobTag = {
  id: string;
  name: string;
};

type EnterpriseTrackerJob =
  | {
      id: string;
      booking: Booking;
      editingJob: null;
      tags?: { edges?: PilotTrackerJobTag[] };
      enterpriseTrackerComments?: {
        edges?: {
          id: string;
          text: string;
          createdAt?: string;
          createdBy: {
            id: string;
            fullName: string;
          };
        }[];
      };
    }
  | {
      id: string;
      booking: null;
      editingJob: EditingJob;
      tags?: { edges?: PilotTrackerJobTag[] };
      enterpriseTrackerComments?: {
        edges?: {
          id: string;
          text: string;
          createdAt?: string;
          createdBy: {
            id: string;
            fullName: string;
          };
        }[];
      };
    };

type EnterpriseTrackerJobsListConnection = {
  edges: EnterpriseTrackerJob[];
  cursor: string;
};

type EnterpriseTrackerJobsListResponse = {
  enterpriseTrackerJobListAsAdmin: EnterpriseTrackerJobsListConnection;
};

const partnersAsAdmin = gql`
  query GetPartnersAsAdmin(
    $clientTypeFilter: String
    $contractStatusFilter: [String]
  ) {
    getPartnersAsAdmin(
      clientTypeFilter: $clientTypeFilter
      contractStatusFilter: $contractStatusFilter
    ) {
      edges {
        uid
        name
      }
    }
  }
`;

type PartnersAsAdminGetConnection = {
  edges: Partner[];
};

type PartnersAsAdminResponse = {
  getPartnersAsAdmin: PartnersAsAdminGetConnection;
};

const CLIENT_TYPE_OPTIONS = [
  { label: 'Pilot clients', value: 'pilotClients' },
  { label: 'Non Pilot clients', value: 'notPilotClients' },
  { label: 'All', value: 'All' },
];

const CONTRACT_STATUS_TYPE_OPTIONS = [
  { label: 'Active', value: 'active' },
  { label: 'All', value: 'all' },
];

function MultiSelectFiler({
  filterName,
  title,
  options,
  location,
  history,
}: {
  filterName: string;
  title: string;
  options;
  location;
  history;
}) {
  const filterString = qs.parse(location.search)[filterName];
  const selectedValues = filterString
    ? ensureString(filterString ?? '')?.split(',')
    : [];

  const selectedOptions =
    selectedValues && options.filter((o) => selectedValues.includes(o.value));

  return (
    <div>
      <div
        style={{
          top: 7,
          left: 10,
          zIndex: 2,
          textTransform: 'capitalize',
          color: '#C1C8D4',
        }}
      >
        {title}
      </div>
      <div
        style={{
          minWidth: 125,
          marginRight: 16,
          position: 'relative',
        }}
      >
        <div style={{ minWidth: 150 }}>
          <Select
            styles={{
              indicatorsContainer: (baseStyles, state) => ({
                ...baseStyles,
                display: 'flex',
                flexDirection: 'column',
              }),
              indicatorSeparator: (baseStyles, state) => ({
                ...baseStyles,
                display: 'none',
              }),
            }}
            isMulti
            value={selectedOptions}
            options={options}
            placeholder="Select"
            onChange={(selectedOptions) => {
              const selected = (selectedOptions || []).map((s) => s.value);

              history.push({
                ...location,
                search: qs.stringify({
                  ...qs.parse(location.search),
                  [filterName]:
                    selected.length > 0 ? selected.join(',') : undefined,
                }),
              });
            }}
          />
        </div>
      </div>
    </div>
  );
}

function PilotTrackerJobTags({
  pilotTrackerJob,
}: {
  pilotTrackerJob: EnterpriseTrackerJob;
}) {
  const [loadTags, { loading: tagsQueryLoading }] = useLazyQuery<
    {
      job?: {
        id: string;
        tags?: { edges?: PilotTrackerJobTag[] };
      };
    },
    {
      jobId: string;
    }
  >(
    gql`
      query pilotTrackerJobByIdTagsQuery($jobId: ID!) {
        job: pilotTrackerJobByIdAsAdmin(pilotTrackerJobId: $jobId) {
          id

          tags {
            edges {
              id
              name
            }
          }
        }
      }
    `,
    {
      variables: {
        jobId: pilotTrackerJob.id,
      },
    }
  );

  const [objectTagCreation, { loading: createLoading }] = useMutation<
    { data?: { success: boolean } },
    {
      name: string;
      object: string;
      objectId: string;
      type: string;
    }
  >(
    gql`
      mutation ObjectTagPilotTrackerCreation(
        $name: String!
        $object: String!
        $objectId: ID!
        $type: String
      ) {
        objectTagCreation(
          input: {
            name: $name
            object: $object
            objectId: $objectId
            type: $type
          }
        ) {
          success
        }
      }
    `
  );

  const [objectTagDeletion, { loading: deleteLoading }] = useMutation<
    { data?: { success: boolean } },
    {
      objectTagId: string;
    }
  >(
    gql`
      mutation ObjectTagPilotTrackerDeletion($objectTagId: ID!) {
        objectTagDeletion(input: { objectTagId: $objectTagId }) {
          success
        }
      }
    `
  );

  const loadingTags = tagsQueryLoading || deleteLoading || createLoading;

  const handleDeleteTagClick = async ({ value }) => {
    const jobTag = pilotTrackerJob.tags?.edges?.find(
      (filteredBooking) => filteredBooking.name === value
    );

    if (jobTag == null) return null;

    await objectTagDeletion({
      variables: {
        objectTagId: jobTag.id,
      },
    });
  };

  const tags = pilotTrackerJob.tags?.edges ?? [];

  return (
    <>
      {loadingTags ? (
        <div
          className="d-flex justify-center align-center"
          style={{ width: '100%', color: '#6C757D' }}
        >
          <i className="fa fa-circle-o-notch fa-lg fa-spin" />
        </div>
      ) : (
        <PillBadgeList
          items={tags.map(({ id, name }) => name)}
          backgroundColor="#F6F9FF"
          placeholderWhite={true}
          onDelete={async (value) =>
            await handleDeleteTagClick({ value }).then(() =>
              loadTags({
                variables: {
                  jobId: pilotTrackerJob.id,
                },
              })
            )
          }
          onAdd={(value) =>
            objectTagCreation({
              variables: {
                name: value,
                object:
                  pilotTrackerJob.booking != null ? 'booking' : 'editing_job',
                objectId: pilotTrackerJob.id,
                type: 'PILOT_TRACKER_JOB',
              },
            }).then(() =>
              loadTags({
                variables: {
                  jobId: pilotTrackerJob.id,
                },
              })
            )
          }
        />
      )}
    </>
  );
}

const NotesModal = ({ id, onDismiss }: NotesModalProps) => {
  const enterpriseTrackerComments = useQuery<
    {
      job?: {
        id: string;
        enterpriseTrackerComments?: {
          edges?: {
            id: string;
            text: string;
            createdAt?: string;
            createdBy: {
              id: string;
              fullName: string;
            };
          }[];
        };
      };
    },
    {
      jobId: string;
    }
  >(
    gql`
      query enterpriseClientTrackerCommentsQuery($jobId: ID!) {
        job: pilotTrackerJobByIdAsAdmin(pilotTrackerJobId: $jobId) {
          id
          enterpriseTrackerComments {
            edges {
              id
              text
              createdAt
              createdBy {
                id
                fullName
              }
            }
          }
        }
      }
    `,
    {
      variables: {
        jobId: id,
      },
    }
  );

  const notes =
    enterpriseTrackerComments.data?.job?.enterpriseTrackerComments?.edges;

  const [
    createCommentAsAdmin,
    { loading: createCommentAsAdminLoading },
  ] = useMutation(
    gql`
      mutation EnterpriseTrackerCommentLogCreateAsAdmin(
        $object: String!
        $objectId: ID!
        $text: String!
      ) {
        commentLogCreateAsAdmin(
          input: { object: $object, objectId: $objectId, text: $text }
        ) {
          commentLog {
            id
          }
        }
      }
    `
  );

  const onCommentSubmit = ({ comment: text }: { comment: string }) => {
    const object = 'enterprise_client_tracker';
    createCommentAsAdmin({
      variables: { object, objectId: id, text },
    })
      .then(() => enterpriseTrackerComments.refetch())
      .catch((error) =>
        alert(
          `Unable to create a note. Please check the user's permissions and try again. If the problem persists, contact support.". ${error}`
        )
      );
  };

  return (
    <Modal onDismiss={onDismiss}>
      {enterpriseTrackerComments.loading === true ? (
        <LoadingSpinnerCentered />
      ) : (
        <div className="card my-4" style={{ minWidth: 712, maxWidth: 648 }}>
          <div className="card-header">
            <h4 className="mb-0">Notes</h4>
          </div>
          <NotesModalBody notes={notes} />

          <NotesModalForm
            disabled={
              createCommentAsAdminLoading || enterpriseTrackerComments.loading
            }
            onSubmit={({ newNote }) => onCommentSubmit({ comment: newNote })}
            onClose={onDismiss}
          />
        </div>
      )}
    </Modal>
  );
};

function DataRowsChunk({
  cursor,
  location,
  onMore,
  sortByKey,
}: {
  cursor: string | null;
  location: { search: string };
  sortByKey?: string;
  onMore?: (p: { nextCursor: string }) => void;
}) {
  const {
    statusFilter,
    levelFilter,
    jobTypeFilter,
    partnerFilter,
    excludePartnerFilter,
    dueAtFilter,
    clientTypeFilter,
    contractStatusFilter,
    q,
  } = qs.parse(location.search) as EnterpriseTrackerJobsQuery;

  const enterpriseTrackerJobsQuery = useQuery<
    EnterpriseTrackerJobsListResponse,
    {
      first?: number;
      cursor?: string;
      statusFilter?: string[];
      levelFilter?: string[];
      jobTypeFilter?: string[];
      partnerFilter?: string[];
      excludePartnerFilter?: string[];
      dueAtFilter?: string[];
      clientTypeFilter?: string[];
      contractStatusFilter?: string[];
      searchFilter?: string[];
      sort?: string;
    }
  >(enterpriseTrackerJobList, {
    variables: {
      first: cursor == null ? 20 : 40,
      cursor: cursor != null ? cursor : undefined,
      statusFilter: statusFilter?.split(','),
      levelFilter: levelFilter?.split(','),
      jobTypeFilter: jobTypeFilter?.split(','),
      partnerFilter: partnerFilter?.split(','),
      excludePartnerFilter: excludePartnerFilter?.split(','),
      dueAtFilter: dueAtFilter?.split(','),
      clientTypeFilter: clientTypeFilter?.split(','),
      contractStatusFilter: contractStatusFilter?.split(','),
      searchFilter: q?.split(','),
      sort: sortByKey,
    },
  });

  const enterpriseTrackerJobs =
    enterpriseTrackerJobsQuery.data?.enterpriseTrackerJobListAsAdmin?.edges;
  const nextCursor =
    enterpriseTrackerJobsQuery.data?.enterpriseTrackerJobListAsAdmin?.cursor;

  if (enterpriseTrackerJobsQuery.error) return <p>Error</p>;

  return (
    <>
      {onMore == null || nextCursor == null ? null : (
        <InView>
          {({ inView, ref }) => (
            <tr ref={ref}>
              {inView && <Component2 didMount={() => onMore({ nextCursor })} />}
            </tr>
          )}
        </InView>
      )}

      <ModalRoute modalName="ENTERPRISE_CLIENT_TRACKER_NOTES">
        {({ history, location, modalParams }) => {
          return (
            <NotesModal
              id={modalParams.enterpriseTrackerJobId}
              onDismiss={() =>
                history.push(
                  locationForModal({
                    location,
                    modal: undefined,
                  })
                )
              }
            />
          );
        }}
      </ModalRoute>

      {enterpriseTrackerJobs != null ||
      enterpriseTrackerJobsQuery.loading === false
        ? enterpriseTrackerJobs?.map(
            ({ id, editingJob, booking, tags, enterpriseTrackerComments }) => (
              <tr key={booking?.id ?? editingJob?.id}>
                <td style={{ minWidth: 150 }}>
                  {booking != null ? (
                    <a href={`/enterprises/${booking?.partner?.uid}`}>
                      {booking?.partner?.name}
                    </a>
                  ) : (
                    <a href={`/enterprises/${editingJob?.partner?.uid}`}>
                      {editingJob?.partner?.name}
                    </a>
                  )}
                </td>
                <td>
                  <PartnerLevelBadge
                    status={
                      booking?.partner?.level ?? editingJob?.partner?.level
                    }
                  />
                </td>
                <td style={{ whiteSpace: 'nowrap' }}>
                  {booking != null ? 'Booking' : 'Editing Job'}
                </td>
                <td style={{ whiteSpace: 'nowrap' }}>
                  {booking?.name != null
                    ? booking?.name
                    : editingJob?.name != null
                    ? editingJob?.name
                    : '-'}
                </td>
                <td style={{ whiteSpace: 'nowrap', minWidth: 300 }}>
                  <div className="d-flex">
                    <CopyIdField
                      id={(booking?.id ?? editingJob?.id) as string}
                    />
                    <div className="btn btn-dark ml-1">
                      <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        to={
                          booking != null
                            ? `/bookings/${booking?.id}`
                            : `/editing-jobs/${editingJob?.id}/details`
                        }
                      >
                        <i className="fa fa-external-link" aria-hidden="true" />
                      </Link>
                    </div>
                  </div>
                </td>
                <td>
                  {booking != null
                    ? booking?.shootType?.displayName
                    : editingJob?.verticalType?.displayName}
                </td>
                <td style={{ minWidth: 200 }}>
                  {booking != null ? booking.address : '-'}
                </td>
                <td style={{ whiteSpace: 'nowrap' }}>
                  <span className="d-block">
                    {upperFirst(
                      moment(
                        booking?.createdAt ?? editingJob?.createdAt
                      ).fromNow()
                    )}
                  </span>
                  <span
                    className="d-block mt-2 text-secondary"
                    style={{
                      fontSize: 12,
                    }}
                  >
                    {moment(booking?.createdAt ?? editingJob?.createdAt).format(
                      'MM/DD/YYYY hh:mm'
                    )}
                  </span>
                </td>
                <td style={{ whiteSpace: 'nowrap' }}>
                  {booking?.startAt != null && (
                    <span className="d-block">
                      {upperFirst(moment(booking?.startAt).fromNow())}
                    </span>
                  )}
                  <span
                    className="d-block mt-2 text-secondary"
                    style={{
                      fontSize: 12,
                    }}
                  >
                    {booking?.startAt != null
                      ? moment(booking.startAt).format('MM/DD/YYYY hh:mm')
                      : '-'}
                  </span>
                </td>
                <td style={{ whiteSpace: 'nowrap' }}>
                  {booking?.rawUploadedAt != null && (
                    <span className="d-block">
                      {upperFirst(moment(booking?.rawUploadedAt).fromNow())}
                    </span>
                  )}
                  <span
                    className="d-block mt-2 text-secondary"
                    style={{
                      fontSize: 12,
                    }}
                  >
                    {booking?.rawUploadedAt != null
                      ? moment(booking.rawUploadedAt).format('MM/DD/YYYY hh:mm')
                      : '-'}
                  </span>
                </td>
                <td
                  style={{
                    whiteSpace: 'nowrap',
                    color:
                      moment(booking?.dueAt ?? editingJob?.dueAt).diff(
                        moment(),
                        'hours'
                      ) < 12
                        ? 'red'
                        : undefined,
                  }}
                >
                  {(booking?.dueAt ?? editingJob?.dueAt) != null &&
                    upperFirst(
                      moment(booking?.dueAt ?? editingJob?.dueAt).fromNow()
                    )}
                  <span
                    className="d-block mt-2"
                    style={{
                      fontSize: 12,
                    }}
                  >
                    {(booking?.dueAt ?? editingJob?.dueAt) != null
                      ? moment(booking?.dueAt ?? editingJob?.dueAt).format(
                          'MM/DD/YYYY hh:mm'
                        )
                      : '-'}
                  </span>
                </td>
                <td
                  style={{
                    color:
                      booking != null && booking?.provider == null
                        ? 'red'
                        : undefined,
                  }}
                >
                  {booking == null ? (
                    '-'
                  ) : booking?.provider != null ? (
                    <a href={`/providers/${booking?.provider?.id}`}>
                      {booking?.provider?.publicFullName}
                    </a>
                  ) : (
                    'Unscheduled'
                  )}
                </td>
                <td className="text-capitalize">
                  {booking?.status != null ? (
                    <BookingStatusBadge
                      status={booking.status.split('_').join(' ')}
                      style={{
                        ...(booking?.status === 'paid_pending_schedule'
                          ? {
                              color: 'white',
                              backgroundColor: 'red',
                            }
                          : {}),
                      }}
                    />
                  ) : (
                    editingJob?.displayStatus
                  )}
                </td>
                <td style={{ minWidth: 250 }}>
                  <PilotTrackerJobTags
                    pilotTrackerJob={
                      {
                        id,
                        booking,
                        editingJob,
                        tags,
                      } as EnterpriseTrackerJob
                    }
                  />
                </td>
                <td
                  style={{
                    minWidth: 300,
                    maxWidth: 300,
                    display: 'flex',
                    flexDirection: 'column',
                    textDecoration: 'none',
                  }}
                >
                  {enterpriseTrackerComments?.edges != null &&
                  enterpriseTrackerComments?.edges?.length > 0 ? (
                    <span className="truncate-text">
                      {enterpriseTrackerComments?.edges[0].text}
                    </span>
                  ) : null}
                  <Link
                    style={{
                      color: '#1F6FFF',
                      textDecoration: 'underline',
                      textAlign: 'start',
                      padding: 0,
                    }}
                    className={`btn`}
                    to={locationForModal({
                      location,
                      modal: {
                        modalName: 'ENTERPRISE_CLIENT_TRACKER_NOTES',
                        enterpriseTrackerJobId: id,
                      },
                    })}
                  >
                    Go to notes
                  </Link>
                </td>
              </tr>
            )
          )
        : Array.from(Array(7)).map((i, index) => (
            <tr key={'loading-' + index}>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
            </tr>
          ))}

      {onMore == null || nextCursor == null ? null : (
        <InView>
          {({ inView, ref }) => (
            <tr ref={ref}>
              {inView && <Component2 didMount={() => onMore({ nextCursor })} />}
            </tr>
          )}
        </InView>
      )}

      <style jsx>{`
        .truncate-text {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          line-height: 25px;
          max-height: 50px;
          width: 300px;
        }
      `}</style>
    </>
  );
}

function EnterpriseTrackerListRoute({
  session,
  match,
  location,
  history,
  rootBaseUrl,
}) {
  const [cursors, setCursors] = useState<string[]>([]);

  const parsedSearch = useMemo(() => qs.parse(location.search), [
    location.search,
  ]);

  const {
    statusFilter,
    levelFilter,
    jobTypeFilter,
    partnerFilter,
    excludePartnerFilter,
    dueAtFilter,
    clientTypeFilter,
    contractStatusFilter,
    q,
  } = parsedSearch as EnterpriseTrackerJobsQuery;

  const {
    data: partners,
    loading: partnersLoading,
  } = useQuery<PartnersAsAdminResponse>(partnersAsAdmin, {
    variables: {
      clientTypeFilter: clientTypeFilter,
      contractStatusFilter: contractStatusFilter?.split(','),
    },
  });

  const clientTypeFilterString = qs.parse(location.search)['clientTypeFilter'];
  const contractStatusFilterString = qs.parse(location.search)[
    'contractStatusFilter'
  ];

  const clientTypeValue = clientTypeFilterString
    ? ensureString(clientTypeFilterString ?? '')
    : '';

  const contractStatusTypeValue = contractStatusFilterString
    ? ensureString(contractStatusFilterString ?? '')
    : '';

  const partnersList = partners?.getPartnersAsAdmin?.edges;

  const partnerOptions =
    partnersList?.map((partner) => ({
      label: partner.name,
      value: partner.uid,
    })) ?? [];

  const doordashPartners = partnersList
    ?.filter((partner) => partner.name.toLowerCase().includes('doordash'))
    .map((partner) => partner.uid)
    .join(',');

  const sortingMethods = {
    '-created_at': 'CREATED_AT_DESC',
    created_at: 'CREATED_AT',
    '-start_at': 'START_AT_DESC',
    start_at: 'START_AT',
    '-raw_uploaded': 'RAW_UPLOADED_AT_DESC',
    raw_uploaded: 'RAW_UPLOADED_AT',
    '-due_date': 'PHOTOS_DELIVERY_OVERDUE_AT_DESC',
    due_date: 'PHOTOS_DELIVERY_OVERDUE_AT',
  };

  const ordering = ensureString(parsedSearch['ordering']) || '';

  const sortBy = sortingMethods[ordering] || 'CREATED_AT_DESC';

  useEffect(() => {
    setCursors([]);
  }, [parsedSearch]);

  const enterpriseTrackerJobsDownloadQuery = useQuery<
    EnterpriseTrackerJobsListResponse,
    {
      first?: number;
      cursor?: string;
      statusFilter?: string[];
      levelFilter?: string[];
      jobTypeFilter?: string[];
      partnerFilter?: string[];
      excludePartnerFilter?: string[];
      dueAtFilter?: string[];
      clientTypeFilter?: string[];
      contractStatusFilter?: string[];
      searchFilter?: string[];
    }
  >(enterpriseTrackerJobList, {
    variables: {
      first: 2000,
      statusFilter: statusFilter?.split(','),
      levelFilter: levelFilter?.split(','),
      jobTypeFilter: jobTypeFilter?.split(','),
      partnerFilter: partnerFilter?.split(','),
      excludePartnerFilter: excludePartnerFilter?.split(','),
      dueAtFilter: dueAtFilter?.split(','),
      clientTypeFilter: clientTypeFilter?.split(','),
      contractStatusFilter: contractStatusFilter?.split(','),
      searchFilter: q?.split(','),
    },
  });

  const statusOptions = [
    { label: 'Created', value: 'created' },
    { label: 'Paid', value: 'paid' },
    { label: 'Paid pending schedule', value: 'paid_pending_schedule' },
    { label: 'Paid on hold', value: 'paid_on_hold' },
    { label: 'Completed', value: 'completed' },
    { label: 'Cancelled', value: 'cancelled' },
  ];

  const levelOptions = [
    { label: 'Bronze', value: 'bronze' },
    { label: 'Silver', value: 'silver' },
    { label: 'Gold', value: 'gold' },
  ];

  const jobTypeOptions = [
    { label: 'Booking', value: 'booking' },
    { label: 'Editing job', value: 'editingJob' },
  ];

  const dueAtOptions = [
    { label: 'Overdue', value: 'overdue' },
    { label: '< 6 hours', value: 'lessThanSix' },
    { label: '6 to 12 hours', value: 'lessThanTwelve' },
    { label: '> 12 hours', value: 'moreThanTwelve' },
  ];

  const downloadCSV = () => {
    const downloadData = enterpriseTrackerJobsDownloadQuery.data?.enterpriseTrackerJobListAsAdmin?.edges.map(
      ({ id, booking, editingJob, tags, enterpriseTrackerComments }) => ({
        partner_name: `"${
          booking?.partner?.name ?? editingJob?.partner?.name
        }"`,
        partner_level: booking?.partner?.level ?? editingJob?.partner?.level,
        job_type: booking != null ? 'booking' : 'editing job',
        job_name: `"${booking?.name ?? editingJob?.name}"`,
        job_id: id,

        shoot_type: `"${
          booking?.shootType?.displayName ??
          editingJob?.verticalType?.displayName
        }"`,

        metro: `"${booking?.address}"`,
        created_at:
          booking?.createdAt != null
            ? moment(booking?.createdAt).toISOString()
            : editingJob?.createdAt != null
            ? moment(editingJob?.createdAt).toISOString()
            : null,
        start_at:
          booking?.startAt != null
            ? moment(booking?.startAt).toISOString()
            : null,
        raw_uploaded:
          booking?.rawUploadedAt != null
            ? moment(booking?.rawUploadedAt).toISOString()
            : null,
        due_date:
          booking?.dueAt != null
            ? moment(booking?.dueAt).toISOString()
            : editingJob?.dueAt != null
            ? moment(editingJob?.dueAt).toISOString()
            : null,
        pg_name: `"${
          booking == null
            ? null
            : booking?.provider?.publicFullName ?? 'Unscheduled'
        }"`,

        status: booking?.status ?? editingJob?.displayStatus,

        tags: `"${
          tags?.edges != null
            ? tags.edges.map((tag) => tag.name).join(', ')
            : ''
        }"`,

        notes: `"${
          enterpriseTrackerComments?.edges != null
            ? enterpriseTrackerComments?.edges
                .map((comment) => comment.text)
                .join(', ')
            : null
        }"`,

        job_link:
          booking != null
            ? `https://admin.snappr.com/bookings/${id}`
            : `https://admin.snappr.com/editing-jobs/e-${id}/details`,
      })
    );

    download(
      createCSV({
        data: downloadData,
      }),
      'pilot-tracker-export.csv',
      'text/csv'
    );
  };

  return partnersLoading === true ? (
    <LoadingSpinnerCentered />
  ) : (
    <>
      <h2>Enterprise Client Tracker</h2>
      <Component2
        didMount={() => {
          const shouldRedirectOnMount =
            parsedSearch.statusFilter === undefined ||
            parsedSearch.clientTypeFilter === undefined ||
            parsedSearch.contractStatusFilter === undefined;

          if (shouldRedirectOnMount) {
            return history.replace({
              ...location,
              search: qs.stringify({
                ...parsedSearch,

                statusFilter:
                  parsedSearch.statusFilter || 'paid,paid_pending_schedule',
                clientTypeFilter: parsedSearch.clientTypeFilter || 'All',
                contractStatusFilter:
                  parsedSearch.contractStatusFilter || 'active',
                ...(doordashPartners != null && doordashPartners.length > 0
                  ? {
                      excludePartnerFilter: doordashPartners,
                    }
                  : {}),
              }),
            });
          }
        }}
      />

      <div className="row">
        <div className="col-12">
          <div className="d-flex bg-white p-3 flex-column position-relative">
            <button
              className="btn btn-primary"
              style={{ width: '100px', alignSelf: 'flex-end' }}
              onClick={downloadCSV}
              disabled={enterpriseTrackerJobsDownloadQuery.loading}
            >
              Export
            </button>

            <div className="table-responsive" style={{ position: 'relative' }}>
              <div className="d-flex align-items-center pb-3">
                <MultiSelectFiler
                  title={'Partner'}
                  filterName={'partnerFilter'}
                  options={partnerOptions}
                  location={location}
                  history={history}
                />
                <MultiSelectFiler
                  title={'Exclude partner'}
                  filterName={'excludePartnerFilter'}
                  options={partnerOptions}
                  location={location}
                  history={history}
                />
                <MultiSelectFiler
                  title={'Status'}
                  filterName={'statusFilter'}
                  options={statusOptions}
                  location={location}
                  history={history}
                />
                <MultiSelectFiler
                  title={'Level'}
                  filterName={'levelFilter'}
                  options={levelOptions}
                  location={location}
                  history={history}
                />
                <MultiSelectFiler
                  title={'Job type'}
                  filterName={'jobTypeFilter'}
                  options={jobTypeOptions}
                  location={location}
                  history={history}
                />
                <MultiSelectFiler
                  title={'Due at'}
                  filterName={'dueAtFilter'}
                  options={dueAtOptions}
                  location={location}
                  history={history}
                />

                <div>
                  <div
                    style={{
                      top: 7,
                      left: 10,
                      zIndex: 2,
                      textTransform: 'capitalize',
                      color: '#C1C8D4',
                    }}
                  >
                    Client type
                  </div>
                  <div
                    style={{
                      minWidth: 125,
                      marginRight: 16,
                      position: 'relative',
                    }}
                  >
                    <div style={{ minWidth: 150 }}>
                      <Select
                        value={CLIENT_TYPE_OPTIONS.find(
                          (clientType) => clientType.value === clientTypeValue
                        )}
                        options={CLIENT_TYPE_OPTIONS}
                        onChange={(clientType) => {
                          history.push({
                            ...location,
                            search: qs.stringify({
                              ...qs.parse(location.search),
                              clientTypeFilter: clientType?.value,
                            }),
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <div
                    style={{
                      top: 7,
                      left: 10,
                      zIndex: 2,
                      textTransform: 'capitalize',
                      color: '#C1C8D4',
                    }}
                  >
                    Contract status
                  </div>
                  <div
                    style={{
                      minWidth: 125,
                      marginRight: 16,
                      position: 'relative',
                    }}
                  >
                    <div style={{ minWidth: 150 }}>
                      <Select
                        value={CONTRACT_STATUS_TYPE_OPTIONS.find(
                          (contractStatus) =>
                            contractStatus.value === contractStatusTypeValue
                        )}
                        options={CONTRACT_STATUS_TYPE_OPTIONS}
                        onChange={(contractStatusType) => {
                          history.push({
                            ...location,
                            search: qs.stringify({
                              ...qs.parse(location.search),
                              contractStatusFilter: contractStatusType?.value,
                            }),
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>

                <Component2
                  search={qs.parse(location.search)}
                  initialState={{ q: null }}
                  didMount={({
                    props: {
                      search: { q },
                    },
                    setState,
                  }) => setState({ q })}
                  didUpdate={({
                    props: {
                      search: { q },
                    },
                    props,
                    prevProps,
                    setState,
                  }) =>
                    props.search.q !== prevProps.search.q && setState({ q })
                  }
                >
                  {({ state, setState }) => (
                    <form
                      className="d-flex justify-content-end pt-4"
                      onSubmit={(ev) => {
                        ev.preventDefault();
                        history.push({
                          ...location,
                          search: qs.stringify({
                            ...qs.parse(location.search),
                            q: state.q || undefined,
                          }),
                        });
                      }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        style={{ maxWidth: 300 }}
                        placeholder="Search"
                        value={state.q || ''}
                        onChange={({ target: { value } }) =>
                          setState({ q: value })
                        }
                      />
                    </form>
                  )}
                </Component2>
              </div>
              <table className="table table-hover mb-0">
                <thead>
                  <tr>
                    <th className="text-muted text-truncate">Partner</th>
                    <th className="text-muted text-truncate">Account level</th>
                    <th className="text-muted text-truncate">Job type</th>
                    <th className="text-muted text-truncate">Job name</th>
                    <th className="text-muted text-truncate">Job ID</th>
                    <th className="text-muted text-truncate">Shoot type</th>
                    <th className="text-muted text-truncate">Metro</th>
                    <th className="text-muted text-truncate">
                      <OrderByLink attrName="created_at" location={location}>
                        Created at
                      </OrderByLink>
                    </th>
                    <th className="text-muted text-truncate">
                      <OrderByLink attrName="start_at" location={location}>
                        Start at
                      </OrderByLink>
                    </th>
                    <th className="text-muted text-truncate">
                      <OrderByLink attrName="raw_uploaded" location={location}>
                        RAW uploaded
                      </OrderByLink>
                    </th>
                    <th className="text-muted text-truncate">
                      <OrderByLink attrName="due_date" location={location}>
                        Due date
                      </OrderByLink>
                    </th>
                    <th className="text-muted text-truncate">PG name</th>
                    <th className="text-muted text-truncate">Status</th>
                    <th className="text-muted text-truncate">Tags</th>
                    <th className="text-muted text-truncate">Notes</th>
                  </tr>
                </thead>

                <tbody style={{ color: '#6C757D' }}>
                  {[null, ...cursors].map((cursor, index) => (
                    <DataRowsChunk
                      key={cursor ?? index}
                      cursor={cursor}
                      location={location}
                      sortByKey={sortBy}
                      onMore={
                        index === cursors.length
                          ? ({ nextCursor }) =>
                              setCursors((state) =>
                                state.includes(nextCursor)
                                  ? state
                                  : [...state, nextCursor]
                              )
                          : undefined
                      }
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export function EnterpriseTrackerRoute({
  session,
  match,
  match: { path: basePath, url: baseUrl },
}) {
  return (
    <BaseLayout session={session} match={match}>
      <Switch>
        <Route
          exact
          path={basePath}
          render={(props) => (
            <EnterpriseTrackerListRoute
              {...props}
              session={session}
              rootBaseUrl={baseUrl}
            />
          )}
        />
      </Switch>
    </BaseLayout>
  );
}
