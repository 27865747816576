import React, { createRef, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';

import { LoadingSpinnerCentered } from '../../components/LoadingSpinner';
import { COLOR_PALLETTE } from '../../constants';
import { gql, useQuery } from '../../components/ApolloClient';
import { useFetch } from '../../components/useFetch';
import { boxFolderUrl } from '../../utils/box';
import { EfficientGalleryPreview } from '../../components/EfficientGalleryPreview';

type IBookingWithRawPreviews = {
  id: string;
  partner: {
    uid: string;
    name: string;
  };
  rawPreviews?: {
    cursor?: string;
    total?: number;
    edges: {
      id: string;
      imageRatio?: number;
      lightboxUrl?: string;
      fileName?: string;
      small?: {
        url: string;
        width?: number;
      };
    }[];
  };
};

function EmptyContent() {
  return (
    <div className="tw-grid tw-grid-cols-12 tw-gap-4 tw-rounded-sm">
      <div className="tw-col-span-8 tw-bg-white">
        <LoadingSpinnerCentered />
      </div>

      <div className="tw-col-span-4 tw-bg-white" />
    </div>
  );
}

const CopyValueField = ({
  value,
  inputStyle,
  buttonStyle,
}: {
  value: string;
  inputStyle?: Record<string, any>;
  buttonStyle?: Record<string, any>;
}) => {
  const valueInputRef = createRef<HTMLInputElement>();

  return (
    <div
      className="input-group"
      style={{
        flex: '0 1 auto',
        width: 'auto',
        justifyContent: 'space-around',
      }}
    >
      <input
        type="text"
        className="form-control"
        readOnly={true}
        ref={valueInputRef}
        value={value}
        style={{ maxWidth: 320, textOverflow: 'ellipsis', ...inputStyle }}
      />
      <div className="input-group-append">
        <button
          className="btn btn-primary"
          onClick={() => {
            if (valueInputRef.current != null) {
              valueInputRef.current.select();
              document.execCommand('copy');
            }
          }}
        >
          <i className="fa fa-copy" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
};

export function RawPhotoPreviewRoute({
  match,
  location,
  history,
  session,
}: { session: { token: string } } & RouteComponentProps<{
  bookingId: string;
}>) {
  const {
    params: { bookingId },
  } = match;

  const [selectedImageId, setSelectedImageId] = useState<string | null>(null);

  const { loading, data } = useQuery<
    {
      booking: IBookingWithRawPreviews;
    },
    { bookingId: string }
  >(
    gql`
      query RAWPhotoPreviewGetAllFromPreviewPage($bookingId: ID!) {
        booking: bookingById(id: $bookingId) {
          id
          partner {
            uid
            name
          }
          rawPreviews(first: 200) {
            cursor
            total
            edges {
              id
              imageRatio
              lightboxUrl
              fileName
              small {
                url
                width
              }
            }
          }
        }
      }
    `,
    { variables: { bookingId } }
  );

  const { result: bookingEditingData } = useFetch<{
    id: string;
    file_request_destination: string | null;
    file_request_service: 'box' | 'dropbox' | null;
    file_request_shared_link: string | null;
  }>({
    urlToFetch: `/api/v2/photo-editing-queue/${bookingId}`,
    session,
  });

  const partnerName = data?.booking.partner?.name;

  const totalRAWs = data?.booking.rawPreviews?.total;
  const rawPreviews = data?.booking.rawPreviews?.edges ?? [];

  const folderUrl =
    bookingEditingData?.file_request_destination == null
      ? undefined
      : bookingEditingData?.file_request_service === 'box'
      ? boxFolderUrl({ folderId: bookingEditingData?.file_request_destination })
          .url
      : `https://www.dropbox.com/home${bookingEditingData?.file_request_destination}`;

  return (
    <div className="tw-w-full tw-flex-1 tw-flex tw-flex-col tw-space-y-4 tw-mb-12">
      <div className="tw-w-full tw-flex tw-justify-between tw-items-center">
        <h4
          style={{
            margin: 0,
            fontWeight: 'bold',
            color: COLOR_PALLETTE.PRIMARY,
          }}
        >
          <Link to={`/editing-queue`}>Editing task queue</Link>

          {partnerName != null && (
            <>
              <span> / {partnerName}</span>

              <span> / Photo Review</span>
            </>
          )}
        </h4>

        <CopyValueField value={bookingId} />
      </div>

      {loading === true ? (
        <EmptyContent />
      ) : (
        <div className="tw-flex-1 tw-grid tw-grid-cols-3 tw-gap-4 tw-rounded-sm">
          <div className="tw-col-span-3 tw-flex tw-flex-col tw-bg-white tw-divide-y tw-divide-x-0 tw-divide-solid tw-divide-snappr-gray-2">
            <div className="tw-p-4 tw-flex tw-justify-between tw-items-center">
              <h3 className="tw-m-0 tw-text-2xl tw-text-snappr-gray-5">
                {totalRAWs != null ? `${totalRAWs} ` : '00 '}RAW photos
              </h3>

              {folderUrl != null && (
                <a
                  href={folderUrl}
                  className="tw-px-4 tw-py-2.5 tw-text-white tw-text-center tw-border-none tw-rounded disabled:tw-bg-snappr-gray-3 tw-bg-snappr-blue hover:tw-no-underline"
                >
                  Download all RAW files
                </a>
              )}
            </div>

            <div className="tw-flex-1 tw-w-full tw-relative">
              <div className="tw-absolute tw-inset-0 tw-overflow-y-auto tw-p-4 tw-grid tw-grid-cols-3 tw-gap-2">
                {rawPreviews.map((rawPreview) => (
                  <div key={rawPreview.id}>
                    <div onClick={() => setSelectedImageId(rawPreview.id)}>
                      <img
                        src={rawPreview.small?.url}
                        alt="RAW preview"
                        className="tw-w-full tw-object-contain"
                      />
                    </div>
                    {rawPreview.fileName != null && (
                      <div style={{ marginTop: 8 }}>
                        <CopyValueField
                          value={rawPreview.fileName}
                          inputStyle={{
                            backgroundColor: 'white',
                            borderWidth: 0,
                          }}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>

              {rawPreviews.length > 0 && selectedImageId != null && (
                <EfficientGalleryPreview
                  currentImageId={selectedImageId}
                  images={rawPreviews.map((raw) => ({
                    id: raw.id,
                    src: raw.lightboxUrl ?? '',
                  }))}
                  className="tw-absolute tw-inset-0 tw-z-50 tw-overflow-hidden tw-bg-black tw-text-white"
                  onImageIdChange={({ imageId }) => {
                    setSelectedImageId(imageId ?? null);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
