import React from 'react';
import groupBy from 'lodash/groupBy';
import Select from 'react-select';
import { gql, useMutation, useQuery } from '../../../components/ApolloClient';
import { LoadingSpinnerCentered } from '../../../components/LoadingSpinner';
import {
  ProgressIndicator,
  UploadImagePreview,
  useUploadManager,
} from '../../../components/useUploadManager';
import Dropzone from 'react-dropzone';
import { apiFetch } from '../../../utils/apiFetch';
import { COLOR_PALLETTE, SNAPPR_BLUE } from '../../../constants';

import styleIcon from '../../../img/styleIcon.svg';
import backgroundIcon from '../../../img/background-icon.svg';
import packageIcon from '../../../img/package-icon.svg';
import starIconGray from '../../../img/starIconGray.png';
import starIconYellow from '../../../img/starIconYellow.png';
import refundIcon from '../../../img/refund-icon.svg';
import refundUserIcon from '../../../img/refund-user-icon.svg';
import finalImageIcon from '../../../img/final-image-icon.svg';
import headshotsImagesEmptyState from '../../../img/headshots-images-empty-state.svg';
import closeIcon from '../../../img/close-icon.svg';
import deleteIcon from '../../../img/delete-icon.svg';

import {
  ENABLE_AI_HEADSHOT_PACKAGES,
  ENABLE_AI_HEADSHOT_QC_SYSTEM,
  ENABLE_HEADSHOTS_REFUND_BUTTON,
} from '../../../config';
import { Link, RouteComponentProps } from 'react-router-dom';
import { checkIfCanAccess, formatMoneyCents } from '../../../utils';
import { Modal } from '../../../components/Modal';
import { ModalRoute, locationForModal } from '../../../components/ModalRoute';
import { LinkButton } from '../../../components/LinkButton';

type CopiedValue = string | null;
type CopyFn = (text: string) => Promise<boolean>;

type Media = {
  id: string;
  prompt?: string;
  negativePrompt?: string;
  style?: string;
  background?: string;
  status?: string;
  finalImage: {
    id: string;
    large: {
      url: string;
    };
    fileName: string;
    downloadUrl?: string;
  };
};

enum HeadshotStyleEnum {
  PROFESSIONAL = 'PROFESSIONAL',
  CASUAL = 'CASUAL',
  WATERCOLOR = 'Watercolor',
  COMIC = 'Comic',
  PENCIL = 'Pencil drawing',
  FALL_VIBES = 'Fall Vibes',
}

interface IAiPortraitsVariations {
  edges: {
    id: string;
    displayName: string;
    slug: HeadshotStyleEnum | string;
  }[];
}

enum aiPortraitsStatus {
  CREATED = 'created',
  STARTING = 'starting',
  PROCESSING = 'processing',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
  CANCELED = 'canceled',
  FINAL = 'final',
  PAID = 'paid',
  QUEUED = 'queued',
  TRIGGERING = 'triggering',
  COMPLETED = 'completed',
  PENDING_REVISION = 'pending_revision',
  PENDING_QC = 'pending_qc',
  IN_EDITING = 'in_editing',
  IN_QC = 'in_qc',
}

const SEND_SUBMISSION_MODAL = 'send-headshot-submission-to-editor-modal';
const REFUND_PORTRAIT_MODAL = 'refund-portrait-modal';
const REFUND_PORTRAIT_MODAL_CONFIRMATION = 'refund-portrait-modal-confirmation';
const CHANGE_STYLE_MODAL = 'change-style-modal';

enum HeadshotStyleEnumForStylesV2 {
  PROFESSIONAL = 'professional-style',
  CASUAL = 'casual-style',
  WATERCOLOR = 'watercolor-style',
  COMIC = 'comic-style',
  PENCIL = 'pencil-style',
  FALL_VIBES = 'fall-vibes-style',
}

export const parseStyleNaming = {
  NONE: 'No',
  COOLS: 'Cool',
  NEUTRALS: 'Neutral',
  WARMS: 'Warm',
  CASUAL: 'Casual',
  PROFESSIONAL: 'Professional',
  [HeadshotStyleEnumForStylesV2.CASUAL]: 'Casual',
  [HeadshotStyleEnumForStylesV2.PROFESSIONAL]: 'Professional',
  [HeadshotStyleEnumForStylesV2.WATERCOLOR]: 'Watercolor',
  [HeadshotStyleEnumForStylesV2.COMIC]: 'Comic',
  [HeadshotStyleEnumForStylesV2.PENCIL]: 'Pencil drawing',
  [HeadshotStyleEnumForStylesV2.FALL_VIBES]: 'Fall Vibes',
};

const CategoryWeight = {
  Professional: 1,
  'Professional-Neutrals': 2,
  'Professional-Warms': 3,
  'Professional-Cools': 4,
  Casual: 5,
  'Casual-Neutrals': 6,
  'Casual-Warms': 7,
  'Casual-Cools': 8,
  'Fall vibes': 9,
  Watercolor: 10,
  'Comic style': 11,
  'Pencil drawing': 12,
  'No style': 13,
};

function ConfirmSendToQCModal({
  onDismiss,
  reload,
  aiPortraitJobId,
  disabled,
}: {
  onDismiss: () => void;
  reload: () => void;
  aiPortraitJobId?: string;
  disabled: boolean;
}) {
  const [createSubmission, createSubmissionMutation] = useMutation<
    {
      aiPortraitJobSendSubmission: {
        submission: { id: string };
      };
    },
    {
      aiPortraitJobId: string;
    }
  >(
    gql`
      mutation AiPortraitSendSubmission($aiPortraitJobId: ID!) {
        aiPortraitsJobCreateSubmission(
          input: { aiPortraitJobId: $aiPortraitJobId }
        ) {
          submission {
            id
            status
          }
        }
      }
    `
  );

  return (
    <Modal onDismiss={onDismiss}>
      <div className="card modalContent" style={{ minWidth: 712 }}>
        <div className="title">Submission confirmation</div>

        <div className="content">
          Are you sure you want to submit the images marked as final?
        </div>

        <div className="buttons">
          <button
            disabled={
              disabled === true || createSubmissionMutation.loading === true
            }
            className="btn btn-secondary mr-3 ml-3"
            onClick={() => onDismiss()}
          >
            Close
          </button>

          <button
            disabled={
              disabled === true || createSubmissionMutation.loading === true
            }
            className="btn btn-primary mr-3 ml-3 btn-end"
            onClick={() => {
              aiPortraitJobId != null &&
                createSubmission({
                  variables: {
                    aiPortraitJobId: aiPortraitJobId,
                  },
                })
                  .then(() => reload())
                  .then(() => onDismiss());
            }}
          >
            Yes, submit to QC
          </button>
        </div>
      </div>
      <style jsx>{`
        .title {
          font-size: 22px;
          background-color: #f6f9ff;
          padding: 16px;
          font-weight: bold;
        }

        .content {
          padding: 16px;
        }

        .buttons {
          padding-bottom: 16px;
          display: flex;
          width: 100%;
        }

        .btn-end {
          margin-left: auto !important;
        }

        .modalContent {
          max-width: 500px;
        }
      `}</style>
    </Modal>
  );
}

function RefundPortraitJobModal({
  onDismiss,
  reload,
  aiPortraitJobId,
  disabled,
  history,
  location,
  setAmountToRefundForParent,
  setRefundTypeForParent,
  setRefundDetailsForParent,
}: {
  onDismiss: () => void;
  reload: () => void;
  aiPortraitJobId?: string;
  disabled: boolean;
  location: RouteComponentProps['location'];
  history: RouteComponentProps['history'];
  setAmountToRefundForParent: (x) => void;
  setRefundTypeForParent: (x) => void;
  setRefundDetailsForParent: (x) => void;
}) {
  const portraitJobQuery = useQuery<
    {
      snapprAiPortraitsJobById?: {
        id: string;
        subjectName: string;
        finalPaidAmount: number;
        headshotPackage: {
          id: string;
          region: {
            uid: string;
            currency: string;
          };
        };
      };
    },
    { jobId: string; first?: number }
  >(
    gql`
      query AiPortraitJobQueryForRefund($jobId: ID!) {
        snapprAiPortraitsJobById(id: $jobId) {
          id
          subjectName
          finalPaidAmount
          headshotPackage {
            id
            region {
              uid
              currency
            }
          }
        }
      }
    `,
    {
      variables: {
        jobId: aiPortraitJobId ?? '',
      },
      skip: aiPortraitJobId == null,
    }
  );
  const customerEmail =
    portraitJobQuery?.data?.snapprAiPortraitsJobById?.subjectName;

  const transactionAmount =
    portraitJobQuery?.data?.snapprAiPortraitsJobById?.finalPaidAmount;

  const jobCurrency =
    portraitJobQuery?.data?.snapprAiPortraitsJobById?.headshotPackage?.region
      ?.currency;

  enum refundTypeEnum {
    FULL = 'Full',
    PARTIAL = 'Partial',
  }

  const [refundType, setRefundType] = React.useState<refundTypeEnum>();

  const [
    partialRefundAmount,
    setPartialRefundAmount,
  ] = React.useState<number>();

  const [refundDetails, setRefundDetails] = React.useState<string>();

  const amountToRefund =
    refundType === refundTypeEnum.FULL
      ? transactionAmount
      : (partialRefundAmount ?? 0) * 100;

  return (
    <Modal onDismiss={onDismiss}>
      <div className="card modalContent" style={{ minWidth: 712 }}>
        <div className="title">Refund Portrait AI Job</div>

        <div className="content">
          <div className="alert alert-warning">
            <span style={{ fontWeight: 400 }}>
              Warning: Refunds require manager approval. Do not proceed without
              approval.
            </span>
          </div>

          <div className="transaction-details">
            <span className="subtitle">Transaction Details</span>
            <span className="subtitle_2">
              {formatMoneyCents(transactionAmount, {
                currency: jobCurrency ?? 'USD',
              })}
            </span>
            <span>Refund amount:</span>

            <div className="refund-options-container">
              <div className="form-check form-check-inline">
                <input
                  id="fullRefund"
                  className="form-check-input"
                  type="radio"
                  name="refundOptions"
                  value="full"
                  checked={refundType === refundTypeEnum.FULL}
                  onChange={() => {
                    setRefundType(refundTypeEnum.FULL);
                  }}
                />

                <label htmlFor="fullRefund" className="form-check-label">
                  {refundTypeEnum.FULL}
                </label>
              </div>

              <div className="form-check form-check-inline">
                <input
                  id="partialRefund"
                  className="form-check-input"
                  type="radio"
                  name="refundOptions"
                  value="partial"
                  checked={refundType === refundTypeEnum.PARTIAL}
                  onChange={() => {
                    setRefundType(refundTypeEnum.PARTIAL);
                  }}
                />

                <label htmlFor="partialRefund" className="form-check-label">
                  {refundTypeEnum.PARTIAL}
                </label>

                <span className="sign">$</span>

                <input
                  disabled={
                    refundType === undefined ||
                    refundType === refundTypeEnum.FULL
                  }
                  value={partialRefundAmount}
                  onChange={(e) =>
                    setPartialRefundAmount(parseInt(e.target.value))
                  }
                  type="number"
                  className="form-control input"
                  placeholder="Amount"
                />
              </div>
            </div>

            <div className="refund-details">
              <span>Refund details:</span>

              <textarea
                value={refundDetails}
                onChange={(e) => setRefundDetails(e.target.value)}
                className="form-control text-area"
                rows={3}
              />
            </div>
          </div>

          <div className="transaction-details">
            <span className="subtitle">User Details</span>
            <span className="subtitle_2">{customerEmail}</span>
          </div>
        </div>

        <div className="buttons">
          <button
            className="btn btn-secondary mr-3 ml-3"
            onClick={() => onDismiss()}
          >
            Close
          </button>

          {amountToRefund == null ||
          isNaN(amountToRefund) ||
          portraitJobQuery.loading === true ? (
            <button
              disabled={true}
              className="btn btn-primary mr-3 ml-3 btn-end"
            >
              Process Refund{' '}
              {amountToRefund != null &&
                amountToRefund > 0 &&
                ` - ${formatMoneyCents(amountToRefund, {
                  currency: jobCurrency ?? 'USD',
                })}`}
            </button>
          ) : (
            <Link
              onClick={() => {
                setAmountToRefundForParent(amountToRefund);
                setRefundTypeForParent(refundType);
                setRefundDetailsForParent(refundDetails);
              }}
              className="btn btn-primary mr-3 ml-3 btn-end"
              to={locationForModal({
                location,
                modal: { modalName: REFUND_PORTRAIT_MODAL_CONFIRMATION },
              })}
            >
              Process Refund{' '}
              {amountToRefund != null &&
                amountToRefund > 0 &&
                ` - ${formatMoneyCents(amountToRefund, {
                  currency: jobCurrency ?? 'USD',
                })}`}
            </Link>
          )}
        </div>
      </div>

      <style jsx>{`
        .title {
          font-size: 22px;
          background-color: #f6f9ff;
          padding: 16px;
          font-weight: bold;
        }

        .subtitle {
          font-size: 24px;
          font-weight: 500;
        }

        .subtitle_2 {
          font-size: 20px;
        }

        .content {
          padding: 16px;
          font-family: 'Nunito Sans';
          color: ${COLOR_PALLETTE.PRIMARY};
          width: 970px;
        }

        .transaction-details {
          display: flex;
          flex-direction: column;
          gap: 8px;
          border: 1px solid ${COLOR_PALLETTE.GRAY_2};
          border-radius: 2px;
          padding: 10px 16px 16px 16px;
          margin-bottom: 16px;
        }

        .refund-options-container {
          display: flex;
          flex-direction: column;
        }

        .partial-refund-container {
          display: flex;
        }

        .sign {
          margin-left: 8px;
          margin-right: 8px;
        }
        .input {
          width: 177px;
        }

        .form-control:disabled {
          background-color: ${COLOR_PALLETTE.GRAY_1};
          opacity: 1;
        }

        .refund-details {
          display: flex;
        }

        .text-area {
          width: 442px;
          margin-left: 8px;
        }

        .buttons {
          padding-bottom: 16px;
          display: flex;
          width: 100%;
          justify-content: space-between;
        }

        .btn-end {
          margin-left: auto !important;
        }

        .modalContent {
          max-width: 1000px;
        }
      `}</style>
    </Modal>
  );
}

function ChangeImageStyleModal({
  onDismiss,
  reload,
  modalParams,
}: {
  onDismiss: () => void;
  reload: () => void;
  modalParams: { predictionResultId: string; predictionResultMedia: string };
}) {
  const portraitJobStyleListQuery = useQuery<{
    aiPortraitVariationsList?: IAiPortraitsVariations;
  }>(
    gql`
      query AiPortraitStylesListAsAdmin($typeFilter: String!) {
        aiPortraitVariationsList(typeFilter: $typeFilter) {
          edges {
            id
            displayName
            slug
          }
        }
      }
    `,
    {
      variables: {
        typeFilter: 'style',
      },
    }
  );

  const [changeImageStyle] = useMutation<{
    aiPortraitJobPredictionSetStyle: {
      success: boolean;
    };
  }>(
    gql`
      mutation changePortraitJobImageStyleAsAdmin(
        $aiPortraitJobPredictionResultId: ID!
        $style: String!
      ) {
        aiPortraitJobPredictionSetStyle(
          input: {
            aiPortraitJobPredictionResultId: $aiPortraitJobPredictionResultId
            style: $style
          }
        ) {
          success
        }
      }
    `
  );

  const availableStyles =
    portraitJobStyleListQuery?.data?.aiPortraitVariationsList?.edges;

  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      color: '#d3d8dc',
    }),
    control: (provided) => ({
      ...provided,
      border: `1px solid ${COLOR_PALLETTE.GRAY_2}`,
      borderRadius: '2px',
      height: '52px',
      outline: 'none',
      width: '390px',
    }),
  };

  const [style, setStyle] = React.useState<string | null>(null);

  const styleOptions =
    availableStyles != null
      ? availableStyles?.map((style) => {
          return {
            value: style.slug,
            label: parseStyleNaming[style.slug],
          };
        })
      : [];

  return (
    <Modal onDismiss={onDismiss}>
      <div style={{ position: 'relative' }}>
        <button className="close-button" onClick={() => onDismiss()}>
          <img src={closeIcon} alt="close" />
        </button>

        <div className=" card content">
          <div className="title">Edit style</div>
          <div className="image-select">
            <div className="image-container">
              {modalParams?.predictionResultMedia != null && (
                <img
                  src={modalParams.predictionResultMedia}
                  alt="prediction result media"
                  className="image"
                />
              )}
            </div>

            <div style={{ minWidth: 100 }}>
              <Select
                className="select"
                onChange={(e) => setStyle(e?.value ?? '')}
                options={styleOptions}
                styles={customStyles}
                placeholder="Select"
              />
            </div>
          </div>

          <div className="button-container">
            <button
              disabled={style == null}
              className="button"
              onClick={() => {
                changeImageStyle({
                  variables: {
                    aiPortraitJobPredictionResultId:
                      modalParams.predictionResultId,
                    style: style,
                  },
                })
                  .then(() => onDismiss())
                  .then(() => reload());
              }}
            >
              Confirm and continue
            </button>
          </div>
        </div>
      </div>

      <style jsx>{`
        .title {
          font-size: 22px;
          font-weight: bold;
          margin-bottom: 24px;
        }

        .content {
          padding: 24px;
          font-family: 'Nunito Sans';
          color: ${COLOR_PALLETTE.PRIMARY};
          width: 439px;
        }

        .close-button {
          position: absolute;
          top: -52px;
          right: 0px;
          background-color: transparent;
          border: none;
          text-decoration: none;
          outline: none;
          width: 32px;
          height: 32px;
          padding: 8px;
        }

        .form-control:disabled {
          background-color: ${COLOR_PALLETTE.GRAY_1};
          opacity: 1;
        }

        .image-select {
          margin-bottom: 24px;
        }

        .image-container {
          height: 390px;
          width: 100%;
          margin-bottom: 8px;
        }

        .image {
          height: 390px;
          width: 390px;
          border-radius: 3px;
        }

        .button {
          background-color: ${COLOR_PALLETTE.PRIMARY};
          color: white;
          border: none;
          outline: none;
          padding: 10px 16px 10px 16px;
          border-radius: 2px;
        }

        .button-container {
          display: flex;
          justify-content: flex-end;
        }

        .button:disabled {
          background-color: ${COLOR_PALLETTE.GRAY_3};
        }
      `}</style>
    </Modal>
  );
}

function RefundPortraitJobModalConfirmation({
  onDismiss,
  reload,
  aiPortraitJobId,
  disabled,
  amountToRefund,
  refundType,
  refundDetails,
  refetchJob,
}: {
  onDismiss: () => void;
  reload: () => void;
  aiPortraitJobId?: string;
  disabled: boolean;
  amountToRefund?: number;
  refundType?: string;
  refundDetails?: string;
  refetchJob: () => void;
}) {
  const portraitJobQuery = useQuery<
    {
      snapprAiPortraitsJobById?: {
        id: string;
        subjectName: string;
        finalPaidAmount: number;
        headshotPackage: {
          id: string;
          region: {
            uid: string;
            currency: string;
          };
        };
      };
    },
    { jobId: string; first?: number }
  >(
    gql`
      query AiPortraitJobQueryForRefundConfirmation($jobId: ID!) {
        snapprAiPortraitsJobById(id: $jobId) {
          id
          subjectName
          finalPaidAmount
          headshotPackage {
            id
            region {
              uid
              currency
            }
          }
        }
      }
    `,
    {
      variables: {
        jobId: aiPortraitJobId ?? '',
      },
      skip: aiPortraitJobId == null,
    }
  );
  const customerEmail =
    portraitJobQuery?.data?.snapprAiPortraitsJobById?.subjectName;

  const jobCurrency =
    portraitJobQuery?.data?.snapprAiPortraitsJobById?.headshotPackage?.region
      ?.currency;

  const [
    validatePortraitJobRefund,
    validatePortraitJobRefundMutation,
  ] = useMutation<{
    portraitJobRefundValidateAsAdmin: {
      portraitJob: {
        id: string;
        subjectName: string;
      };
      token: string;
      amountToRefund: number;
    };
  }>(
    gql`
      mutation portraitJobRefundValidateAsAdmin(
        $portraitJobId: ID!
        $amountToRefund: Float!
        $typeOfRefund: AiPortraitJobRefundType!
      ) {
        portraitJobRefundValidateAsAdmin(
          input: {
            portraitJobId: $portraitJobId
            amountToRefund: $amountToRefund
            typeOfRefund: $typeOfRefund
          }
        ) {
          portraitJob {
            id
            subjectName
          }
          token
          amountToRefund
        }
      }
    `
  );

  React.useEffect(() => {
    validatePortraitJobRefund({
      variables: {
        portraitJobId: aiPortraitJobId,
        amountToRefund,
        typeOfRefund: refundType?.toLowerCase(),
      },
    });
  }, [
    aiPortraitJobId,
    amountToRefund,
    refundType,
    reload,
    validatePortraitJobRefund,
  ]);

  const validationToken =
    validatePortraitJobRefundMutation.data?.portraitJobRefundValidateAsAdmin
      ?.token;

  const validatedAmountToRefund =
    validatePortraitJobRefundMutation.data?.portraitJobRefundValidateAsAdmin
      ?.amountToRefund;

  const [
    commitPortraitJobRefund,
    commitPortraitJobRefundMutation,
  ] = useMutation<{
    portraitJobRefundCommitAsAdmin: {
      aiPortraitJob: {
        id;
        status;
      };
      amountToRefund;
      transactionResult;
    };
  }>(
    gql`
      mutation portraitJobRefundCommitAsAdmin(
        $aiPortraitJobId: ID!
        $amountToRefund: Float!
        $validationToken: String!
        $typeOfRefund: AiPortraitJobRefundType!
        $refundDetails: String
      ) {
        portraitJobRefundCommitAsAdmin(
          input: {
            aiPortraitJobId: $aiPortraitJobId
            amountToRefund: $amountToRefund
            validationToken: $validationToken
            typeOfRefund: $typeOfRefund
            refundDetails: $refundDetails
          }
        ) {
          aiPortraitJob {
            id
            subjectName
          }
          amountToRefund
          transactionResult
        }
      }
    `
  );

  return (
    <Modal onDismiss={onDismiss}>
      <div className="card modalContent" style={{ minWidth: 712 }}>
        <div className="title">Refund Confirmation</div>

        <div className="content">
          <span className="subtitle_2">
            <strong>Are you sure you want to confirm this refund?</strong>
          </span>

          <div className="transaction-details">
            <div className="text-line">
              <span className="subtitle_3">{refundType} refund:</span>
              <span className="subtitle_2">
                {formatMoneyCents(validatedAmountToRefund, {
                  currency: jobCurrency ?? 'USD',
                })}
              </span>
            </div>
            <div className="text-line">
              <span className="subtitle_3">User: </span>
              <span className="subtitle_2">{customerEmail}</span>
            </div>
          </div>

          <div className="alert-confirm">
            <span style={{ fontWeight: 400 }}>
              <strong>Please note:</strong> Once the refund is confirmed, it
              cannot be undone.
            </span>
          </div>
        </div>

        <div className="buttons">
          <button
            className="btn btn-secondary mr-3 ml-3"
            onClick={() => onDismiss()}
          >
            Close
          </button>

          <button
            disabled={
              amountToRefund == null ||
              isNaN(amountToRefund) ||
              validatePortraitJobRefundMutation.loading === true ||
              commitPortraitJobRefundMutation.loading === true
            }
            className="btn btn-primary mr-3 ml-3 btn-end"
            onClick={() => {
              aiPortraitJobId != null &&
                validatePortraitJobRefundMutation.loading === false &&
                commitPortraitJobRefund({
                  variables: {
                    aiPortraitJobId: aiPortraitJobId,
                    amountToRefund: validatedAmountToRefund,
                    validationToken,
                    typeOfRefund: refundType?.toLowerCase(),
                    refundDetails,
                  },
                }).then(() => {
                  refetchJob();
                  onDismiss();
                });
            }}
          >
            Yes, confirm refund
          </button>
        </div>
      </div>
      <style jsx>{`
        .title {
          font-size: 22px;
          background-color: #f6f9ff;
          padding: 16px;
          font-weight: bold;
          color: ${COLOR_PALLETTE.GRAY_5};
        }

        .subtitle_2 {
          font-size: 20px;
          margin-right: 8px;
        }

        .subtitle_3 {
          font-size: 20px;
          margin-right: 8px;
          font-weight: 600;
        }

        .content {
          padding: 16px;
          padding-top: 24px;
          font-family: 'Nunito Sans';
          color: ${COLOR_PALLETTE.PRIMARY};
        }

        .transaction-details {
          display: flex;
          flex-direction: column;
          gap: 8px;
          border: 1px solid ${COLOR_PALLETTE.GRAY_2};
          border-radius: 2px;
          padding: 10px 16px 16px 16px;
          margin-top: 24px;
          margin-bottom: 16px;
        }

        .refund-options-container {
          display: flex;
          flex-direction: column;
        }

        .partial-refund-container {
          display: flex;
        }

        .sign {
          margin-left: 8px;
          margin-right: 8px;
        }
        .input {
          width: 177px;
        }

        .form-control:disabled {
          background-color: ${COLOR_PALLETTE.GRAY_1};
          opacity: 1;
        }

        .refund-details {
          display: flex;
        }

        .text-area {
          width: 442px;
          margin-left: 8px;
        }

        .text-line {
          display: flex;
        }

        .alert-confirm {
          color: ${COLOR_PALLETTE.DANGER};
          border: 1px solid ${COLOR_PALLETTE.DANGER};
          border-radius: 4px;
          padding: 12px 20px 12px 20px;
          font-size: 16px;
        }

        .buttons {
          padding-bottom: 16px;
          display: flex;
          width: 100%;
        }

        .btn-end {
          margin-left: auto !important;
        }

        .modalContent {
          max-width: 648px;
        }
      `}</style>
    </Modal>
  );
}

function useCopyToClipboard(): { value: CopiedValue; copyFunction: CopyFn } {
  const [copiedText, setCopiedText] = React.useState<CopiedValue>(null);

  const copy: CopyFn = async (text) => {
    if (!navigator?.clipboard) {
      console.warn('Clipboard not supported');
      return false;
    }

    await navigator.clipboard.writeText(text);
    setCopiedText(text);
    return true;
  };

  return { value: copiedText, copyFunction: copy };
}

function HeadshotsImages({
  media,
  promptClick,
  negativePromptClick,
  setAsFinalClick,
  setAsFinalDisabled,
  location,
}: {
  media: Media;
  promptClick: () => void;
  negativePromptClick: () => void;
  setAsFinalClick: () => void;
  setAsFinalDisabled: boolean;
  location: RouteComponentProps['location'];
}) {
  return (
    <div
      key={media.id}
      style={{
        position: 'relative',
        height: 445,
        width: 445,
        marginRight: 5,
        marginBottom: 5,
      }}
      className={`image-with-prompt ${
        media.status === 'final'
          ? 'final'
          : media.status === 'customer_selected'
          ? 'customer-selected'
          : ''
      }`}
    >
      {media?.finalImage?.large?.url != null && (
        <img
          src={media?.finalImage?.large.url}
          alt="portfolio"
          className="img-fluid"
        />
      )}

      {media.status === 'final' && (
        <div className="image-final-tag">
          <img src={finalImageIcon} alt="final" />
        </div>
      )}

      {media.status === 'customer_selected' && (
        <div className="customer-selected-tag">USER SELECTION</div>
      )}

      {media.prompt === 'manual_media_upload' && (
        <div className="uploaded-tag">UPLOADED</div>
      )}

      {media.style != null ? (
        <div className="image-tag">
          {`${media?.style?.replace('-style', '').toUpperCase()} ${
            media?.background != null
              ? `-${media?.background?.toUpperCase()}`
              : ''
          }`}
        </div>
      ) : (
        <div className="image-tag">NO-STYLE</div>
      )}

      <div className="overlay">
        <div className="prompt">
          <div>
            <button onClick={() => promptClick()}>
              <i className="fa fa-copy" aria-hidden="true" />
            </button>
            Prompt: {media?.prompt}
          </div>
          {media.negativePrompt != null && media.negativePrompt !== '' && (
            <>
              <br />
              <div>
                <button onClick={() => negativePromptClick()}>
                  <i className="fa fa-copy" aria-hidden="true" />
                </button>
                Negative prompt: {media.negativePrompt}
              </div>
            </>
          )}
        </div>

        <div className="buttons">
          <button
            disabled={setAsFinalDisabled}
            onClick={() => setAsFinalClick()}
            className={`
    ${
      media.status === 'final'
        ? 'tw-bg-red-500 hover:tw-bg-red-700'
        : 'tw-bg-green-500 hover:tw-bg-green-700'
    } tw-text-white tw-w-80 tw-h-14 set-as-final-button`}
          >
            {media.status === 'succeeded' ||
            media.status === 'customer_selected'
              ? 'Set as final'
              : 'Remove from finals'}
          </button>
          {media?.finalImage?.downloadUrl != null && (
            <a
              download={`${media.id}.jpg`}
              href={media?.finalImage?.downloadUrl}
              title="ImageName"
            >
              <button
                className={`tw-bg-gray-500 hover:tw-bg-gray-700 tw-text-white tw-w-80 tw-h-14 tw-mt-2`}
              >
                Download
              </button>
            </a>
          )}
          {media?.finalImage?.downloadUrl != null && (
            <a
              href={media?.finalImage?.large?.url}
              title="ImageName"
              target="_blank"
              rel="noreferrer"
            >
              <button
                className={`tw-bg-gray-500 hover:tw-bg-gray-700 tw-text-white tw-w-80 tw-h-14 tw-mt-2`}
              >
                Open in a new tab
              </button>
            </a>
          )}

          <Link
            style={{
              textDecoration: 'none',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginLeft: '63px',
            }}
            className={`tw-bg-gray-500 hover:tw-bg-gray-700 tw-text-white tw-w-80 tw-h-14 tw-mt-2`}
            to={locationForModal({
              location,

              modal: {
                modalName: CHANGE_STYLE_MODAL,
                predictionResultId: media?.id,
                predictionResultMedia: media?.finalImage?.large?.url,
              },
            })}
            onClick={() => {}}
          >
            Change/Add style
          </Link>
        </div>
      </div>
      <style jsx>{`
        .image-with-prompt .overlay {
          visibility: hidden;
        }

        .image-final-tag {
          position: absolute;
          top: 16px;
          left: 16px;
          padding: 0px 7px;
        }

        .customer-selected-tag {
          position: absolute;
          bottom: 16px;
          left: 16px;
          background-color: ${COLOR_PALLETTE.PURPLE};
          color: white;
          padding: 0px 7px;
          font-family: 'Nunito Sans';
          z-index: 0;
        }

        .uploaded-tag {
          position: absolute;
          bottom: 16px;
          left: 16px;
          background-color: ${COLOR_PALLETTE.PRIMARY};
          color: white;
          padding: 0px 7px;
          font-family: 'Nunito Sans';
        }

        .image-tag {
          position: absolute;
          bottom: 16px;
          right: 16px;
          background-color: ${SNAPPR_BLUE};
          color: white;
          padding: 0px 7px;
          font-family: 'Nunito Sans';
        }

        .overlay {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          backdrop-filter: blur(5px);
          background-color: rgba(0, 0, 0);
          opacity: 0.8;
          transition: opacity 0.3s ease;
        }

        .prompt {
          padding-left: 10px;
          padding-top: 16px;
          min-height: 30px;
          align-items: left;
          color: #ffffff;
          font-size: 14px;
          height: 40%;
          overflow: scroll;
        }

        .buttons {
          opacity: 1;
          text-align: center;
          z-index: 3;
        }

        .set-as-final-button:disabled {
          background-color: ${COLOR_PALLETTE.GRAY_1} !important;
          color: ${COLOR_PALLETTE.GRAY_5} !important;
        }

        .final {
          outline: 4px solid ${COLOR_PALLETTE.SUCCESS};
        }

        .customer-selected {
          outline: 4px solid ${COLOR_PALLETTE.PURPLE};
        }

        .image-with-prompt:hover .overlay {
          visibility: visible;
        }

        .progress {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
        }

        .grid-cont {
          display: flex;
          flex-direction: row;
          margin-bottom: 24px;
        }

        .prompt-input {
          width: 100%;
        }

        .gallery-grid-for-final-images {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          grid-gap: 16px;
          width: 100%;
          flex: 0 1 auto;
        }

        .image-container img {
          width: 100%;
          height: auto;
        }

        .subtitle {
          font-weight: 600;
          font-size: 24px;
          margin-bottom: 16px;
        }
      `}</style>
    </div>
  );
}
export function Loading() {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '100wh',
        minHeight: '100vh',
      }}
    >
      <div
        style={{
          borderRadius: 4,
          padding: '2em',
          backgroundColor: '#fff',
          boxShadow: '0 0 1.625em rgba(0,0,0,.05)',
        }}
      >
        <h3 style={{ padding: 0, margin: 0 }}>Loading results...</h3>
      </div>
    </div>
  );
}

export const AiPortraitsJobDetails = ({
  match,
  session,
  session: { token },
  history,
  location,
}: {
  match;
  session: {
    token: string;
    uid: string;
    user?: { IAM?: string[] };
  };
  history: RouteComponentProps['history'];
  location: RouteComponentProps['location'];
}) => {
  const {
    params: { jobId },
  } = match;

  const [prompt, setPrompt] = React.useState<string>('');
  const [negativePrompt, setNegativePrompt] = React.useState<string>('');
  const [amountToRefund, setAmountToRefund] = React.useState<number>();
  const [refundType, setRefundType] = React.useState<string>();
  const [refundDetails, setRefundDetails] = React.useState<string>();

  const [
    createPortraitJobPrediction,
    createPortraitJobPredictionMutation,
  ] = useMutation<{
    aiPortraitJobPredictionCreateAsAdmin: {
      success: boolean;
    };
  }>(
    gql`
      mutation AiPortraitJobPredictionCreate(
        $aiPortraitJobId: ID!
        $prompt: String!
        $negativePrompt: String
        $style: String
        $background: String
      ) {
        aiPortraitJobPredictionCreateAsAdmin(
          input: {
            aiPortraitJobId: $aiPortraitJobId
            prompt: $prompt
            negativePrompt: $negativePrompt
            style: $style
            background: $background
          }
        ) {
          success
        }
      }
    `
  );

  const portraitJobQuery = useQuery<
    {
      snapprAiPortraitsJobById?: {
        id: string;
        finalPaidAmount: number;
        editorEmail?: string;
        customerId?: string;
        submissions?: { edges: { id: string; status: string }[] };
        headshotPackage: {
          id: string;
          displayName: string;
          numberOfImages: number;
          region: {
            uid: string;
            currency: string;
          };
        };
        items: {
          edges: {
            id: string;
            croppedImage: {
              id: string;
              downloadUrl?: string;
            };
            originalImage: {
              id: string;
              downloadUrl?: string;
            };
          }[];
        };
        status: aiPortraitsStatus;
        modelTrainingCreatedAt?: string;
        styleData?: {
          style?: string;
          backgroundFamily?: string;
          styles?: string[];
          backgroundFamilies?: string[];
        };
        customerFeedback?: {
          rating?: number;
          comments?: string;
          nps?: number;
          sharePermissions?: boolean;
        };
      };
    },
    { jobId: string; first?: number }
  >(
    gql`
      query AiPortraitJobQueryJobImages($jobId: ID!, $first: Int) {
        snapprAiPortraitsJobById(id: $jobId) {
          id
          finalPaidAmount
          editorEmail
          customerId
          submissions {
            edges {
              id
              status
            }
          }
          headshotPackage {
            id
            displayName
            numberOfImages
            region {
              uid
              currency
            }
          }
          items(first: $first) {
            edges {
              id
              originalImage {
                id
                downloadUrl
              }
              croppedImage {
                id
                downloadUrl
              }
            }
          }
          status
          modelTrainingCreatedAt
          styleData {
            style
            backgroundFamily
            styles
            backgroundFamilies
          }
          customerFeedback {
            rating
            comments
            nps
            sharePermissions
          }
        }
      }
    `,
    {
      variables: {
        first: 300,
        jobId,
      },
      skip: jobId == null,
    }
  );

  const status = portraitJobQuery?.data?.snapprAiPortraitsJobById?.status;

  const modelTrainingCreatedAt =
    portraitJobQuery?.data?.snapprAiPortraitsJobById?.modelTrainingCreatedAt;

  const customerFeedback =
    portraitJobQuery?.data?.snapprAiPortraitsJobById?.customerFeedback;

  const jobStyle =
    portraitJobQuery?.data?.snapprAiPortraitsJobById?.styleData?.style;

  const jobBackground =
    portraitJobQuery?.data?.snapprAiPortraitsJobById?.styleData
      ?.backgroundFamily;

  const totalPaidByCustomer =
    portraitJobQuery?.data?.snapprAiPortraitsJobById?.finalPaidAmount;

  const jobRegion =
    portraitJobQuery?.data?.snapprAiPortraitsJobById?.headshotPackage?.region;

  const portraitsJobStyles = portraitJobQuery?.data?.snapprAiPortraitsJobById?.styleData?.styles?.map(
    (style) => parseStyleNaming[style]
  );

  const editorEmail =
    portraitJobQuery?.data?.snapprAiPortraitsJobById?.editorEmail;

  const customerId =
    portraitJobQuery?.data?.snapprAiPortraitsJobById?.customerId;

  const portraitsJobBackgrounds = portraitJobQuery?.data?.snapprAiPortraitsJobById?.styleData?.backgroundFamilies?.map(
    (background) => parseStyleNaming[background]
  );

  const croppedTrainingImages =
    portraitJobQuery?.data?.snapprAiPortraitsJobById?.items?.edges;

  const jobPackage =
    portraitJobQuery?.data?.snapprAiPortraitsJobById?.headshotPackage
      ?.displayName;

  const numberOfImages =
    portraitJobQuery?.data?.snapprAiPortraitsJobById?.headshotPackage
      ?.numberOfImages;

  const aiPortraitJobPredictionResults = useQuery<{
    aiPortraitJobPredictionResultForAiJobIdList: {
      edges: {
        id: string;
        prompt: string;
        negativePrompt?: string;
        status:
          | 'starting'
          | 'processing'
          | 'succeeded'
          | 'failed'
          | 'canceled'
          | 'customer_selected'
          | 'final';
        resultMedia: {
          id: string;
          large: {
            url: string;
          };
          downloadUrl?: string;
        };
        mediaStyle?: string;
      }[];
    };
  }>(
    gql`
      query aiPortraitJobPredictionResults($aiPortraitJobId: ID!) {
        aiPortraitJobPredictionResultForAiJobIdList(
          aiPortraitJobId: $aiPortraitJobId
        ) {
          edges {
            id
            prompt
            negativePrompt
            status
            resultMedia {
              id
              large {
                url
              }
              downloadUrl
            }
          }
        }
      }
    `,
    {
      variables: {
        aiPortraitJobId: jobId,
      },
      skip: jobId == null,
    }
  );

  const snapprAiPortraitsJobByIdForAdmin = useQuery<{
    snapprAiPortraitsJobById: {
      id: string;
      allImages: {
        edges: {
          id: string;
          prompt?: string;
          negativePrompt?: string;
          style?: string;
          background?: string;
          status?: string;
          finalImage: {
            id: string;
            large: {
              url: string;
            };
            fileName: string;
            downloadUrl?: string;
          };
        }[];
      };
    };
  }>(
    gql`
      query snapprAiPortraitsJobByIdForAdmin($id: ID!) {
        snapprAiPortraitsJobById(id: $id) {
          id
          allImages(first: 500) {
            edges {
              id
              prompt
              negativePrompt
              style
              background
              status
              finalImage {
                id
                large {
                  url
                }
                downloadUrl
              }
            }
          }
        }
      }
    `,
    {
      variables: {
        id: jobId,
      },
      skip: jobId == null,
    }
  );

  const [savePrediction] = useMutation<
    {
      aiPortraitJobManualPredictionCreateAsAdmin: {
        success: boolean;
      };
    },
    {
      aiPortraitsJobId: string;
      mediaId: string;
    }
  >(
    gql`
      mutation AiPortraitSaveManualPrediction(
        $aiPortraitsJobId: ID!
        $mediaId: ID!
      ) {
        aiPortraitJobManualPredictionCreateAsAdmin(
          input: { aiPortraitsJobId: $aiPortraitsJobId, mediaId: $mediaId }
        ) {
          success
        }
      }
    `
  );

  const [setStatus, setStatusMutation] = useMutation<
    {
      aiPortraitJobPredictionToggleFinalStatusAsAdmin: {
        success: boolean;
      };
    },
    {
      aiPortraitJobPredictionResultId: string;
      status: string;
    }
  >(
    gql`
      mutation AiPortraitSetPredictionStatus(
        $aiPortraitJobPredictionResultId: ID!
        $status: AiPortraitJobPredictionResultStatusToggle!
      ) {
        aiPortraitJobPredictionToggleFinalStatusAsAdmin(
          input: {
            aiPortraitJobPredictionResultId: $aiPortraitJobPredictionResultId
            status: $status
          }
        ) {
          success
        }
      }
    `
  );

  const [cancelModelTraining, cancelModelTrainingLoading] = useMutation<
    {
      aiPortraitJobTrainingCancelAsAdmin: {
        success: boolean;
      };
    },
    {
      aiPortraitJobId: string;
    }
  >(
    gql`
      mutation aiPortraitJobTrainingCancelAsAdmin($aiPortraitJobId: ID!) {
        aiPortraitJobTrainingCancelAsAdmin(
          input: { aiPortraitJobId: $aiPortraitJobId }
        ) {
          success
        }
      }
    `
  );

  const [retriggerModelTraining, retriggerModelTrainingLoading] = useMutation<
    {
      aiPortraitJobModelTrainAsAdmin: {
        success: boolean;
      };
    },
    {
      aiPortraitJobId: string;
    }
  >(
    gql`
      mutation aiPortraitJobModelRetriggerTrainingAsAdmin(
        $aiPortraitJobId: ID!
      ) {
        aiPortraitJobModelTrainAsAdmin(
          input: { aiPortraitJobId: $aiPortraitJobId }
        ) {
          success
        }
      }
    `
  );

  function setPredictionStatus({
    aiPortraitJobPredictionResultId,
    status,
  }: {
    aiPortraitJobPredictionResultId: string;
    status: string;
  }) {
    setStatus({
      variables: {
        aiPortraitJobPredictionResultId,
        status,
      },
    }).then(() => {
      aiPortraitJobPredictionResults.refetch();
      snapprAiPortraitsJobByIdForAdmin.refetch();
    });
  }

  const [sendToClient, sendToClientMutation] = useMutation<
    {
      aiPortraitJobSendToClientAsAdmin: {
        success: boolean;
      };
    },
    {
      aiPortraitJobId: string;
    }
  >(
    gql`
      mutation AiPortraitSendToClient($aiPortraitJobId: ID!) {
        aiPortraitJobSendToClientAsAdmin(
          input: { aiPortraitJobId: $aiPortraitJobId }
        ) {
          success
        }
      }
    `
  );

  const [saveTrainingImage] = useMutation<
    { AiPortraitsJobSaveImagesCreate: { success: boolean } },
    {
      aiPortraitsJobId: string;
      originalMediaId: string;
    }
  >(
    gql`
      mutation AiPortraitsJobSaveTrainingImages(
        $aiPortraitsJobId: ID!
        $originalMediaId: ID!
      ) {
        snapprAiPortraitsJobCreateItem(
          input: {
            aiPortraitsJobId: $aiPortraitsJobId
            originalMediaId: $originalMediaId
          }
        ) {
          success
        }
      }
    `
  );

  const [deleteImage, deleteImageMutation] = useMutation<
    {
      aiPortraitsJobDeleteItemAsAdmin: { success: boolean };
    },
    {
      aiPortraitsJobId: string;
      mediaId: string;
    }
  >(
    gql`
      mutation AiPortraitsJobDeleteItemAsAdmin(
        $aiPortraitsJobId: ID!
        $mediaId: ID!
      ) {
        aiPortraitsJobDeleteItemAsAdmin(
          input: { aiPortraitsJobId: $aiPortraitsJobId, mediaId: $mediaId }
        ) {
          success
        }
      }
    `
  );

  const headshotsRefund = useQuery<{
    headshotsRefundByJobId: {
      refundType: string;
      refundAmount: number;
      refundStatus: string;
      refundDetails?: string;
      refundedByUser: string;
    };
  }>(
    gql`
      query headshotsRefundData($aiPortraitJobId: ID!) {
        headshotsRefundByJobId(aiPortraitJobId: $aiPortraitJobId) {
          refundType
          refundAmount
          refundStatus
          refundDetails
          refundedByUser
        }
      }
    `,
    {
      variables: {
        aiPortraitJobId: jobId,
      },
      skip: jobId == null,
    }
  );

  const headshotRefundData = headshotsRefund?.data?.headshotsRefundByJobId;

  const headshotRefundStatusEnum = {
    SUCCESSFUL: 'SUCCESSFUL',
    FAILED: 'FAILED',
    PROCESSING: 'PROCESSING',
  };

  const headshotRefundStatus =
    headshotRefundData?.refundStatus === 'settled' ||
    headshotRefundData?.refundStatus === 'refunded'
      ? headshotRefundStatusEnum.SUCCESSFUL
      : headshotRefundData?.refundStatus === 'gateway_rejected' ||
        headshotRefundData?.refundStatus === 'processor_declined'
      ? headshotRefundStatusEnum.FAILED
      : headshotRefundStatusEnum.PROCESSING;

  const refundStatusColor =
    headshotRefundStatus === headshotRefundStatusEnum.SUCCESSFUL
      ? COLOR_PALLETTE.SUCCESS
      : headshotRefundStatus === headshotRefundStatusEnum.FAILED
      ? COLOR_PALLETTE.DANGER
      : COLOR_PALLETTE.WARNING;

  function completeJob({ aiPortraitJobId }: { aiPortraitJobId: string }) {
    sendToClient({
      variables: {
        aiPortraitJobId,
      },
    }).then(() => {
      portraitJobQuery.refetch();
    });
  }

  /*
   * To upload final images
   * */
  const { uploadsList: predictionUploadList, addFiles } = useUploadManager({
    token: session.token!,
    onUploadReady: ({ fileName, mediaUid, remove }) =>
      apiFetch('/api/v2/media/create-from-temp', {
        token: session.token,
        method: 'POST',
        body: JSON.stringify({
          tempMediaUid: mediaUid,
          fileName,
        }),
      }).then(() =>
        savePrediction({
          variables: {
            aiPortraitsJobId: jobId,
            mediaId: mediaUid ?? '',
          },
        })
          .then(() => aiPortraitJobPredictionResults.refetch())
          .then(() => remove())
      ),
  });

  /*
   * To upload more images for training
   * */
  const {
    uploadsList: trainingUploadList,
    addFiles: AddFilesForTraining,
  } = useUploadManager({
    token: session.token!,
    onUploadReady: ({ fileName, mediaUid, remove }) =>
      apiFetch('/api/v2/media/create-from-temp', {
        token: session.token,
        method: 'POST',
        body: JSON.stringify({
          tempMediaUid: mediaUid,
          fileName,
        }),
      }).then(() =>
        saveTrainingImage({
          variables: {
            aiPortraitsJobId: jobId,
            originalMediaId: mediaUid ?? '',
          },
        }).then(() => portraitJobQuery.refetch())
      ),
  });

  const finalUploadList = React.useMemo(() => {
    const portraitsJobImagesSet = new Set(
      croppedTrainingImages?.map(
        (croppedImage) => croppedImage.originalImage.id
      )
    );

    return trainingUploadList.filter((file) => {
      if (file.mediaUid == null) return true;

      return !portraitsJobImagesSet.has(file.mediaUid);
    });
  }, [trainingUploadList, croppedTrainingImages]);

  const predictionResultMedia =
    aiPortraitJobPredictionResults.data
      ?.aiPortraitJobPredictionResultForAiJobIdList.edges;

  const totalFinals =
    predictionResultMedia?.filter((x) => x.status === 'final')?.length ?? 0;

  React.useEffect(() => {
    const mediaStillPending = predictionResultMedia?.some(
      (media) =>
        media.status === 'processing' ||
        media.status === 'starting' ||
        media.status == null
    );

    if (mediaStillPending === true) {
      console.log('still pending');
      setTimeout(() => {
        aiPortraitJobPredictionResults.refetch();
      }, 10000);
    }

    if (mediaStillPending === false) {
      console.log('no longer pending');
    }
  }, [aiPortraitJobPredictionResults, predictionResultMedia]);

  const { copyFunction: copy } = useCopyToClipboard();

  const submissions =
    portraitJobQuery?.data?.snapprAiPortraitsJobById?.submissions?.edges ?? [];

  const pendingSubmissions = submissions.filter(
    (submission) => submission.status === 'pending'
  );

  const isAnySubmissionPendingForReview = pendingSubmissions.length > 0;

  const submissionDisabled =
    totalFinals <
      (ENABLE_AI_HEADSHOT_PACKAGES === true ? numberOfImages ?? 6 : 6) ||
    status === 'completed' ||
    isAnySubmissionPendingForReview === true;

  const [activeTab, setActiveTab] = React.useState<'ALL' | 'FINALS' | 'USERS'>(
    'ALL'
  );

  const generatedImages =
    snapprAiPortraitsJobByIdForAdmin?.data?.snapprAiPortraitsJobById?.allImages
      ?.edges;

  const groupedImages = groupBy(generatedImages, ({ style, background }) => {
    if (style === 'professional-style') {
      if (background != null) {
        return `Professional-${
          background?.charAt(0).toUpperCase() +
          background?.slice(1).toLowerCase()
        }`;
      }
      return `Professional`;
    }

    if (style === 'casual-style') {
      if (background != null) {
        return `Casual-${
          background?.charAt(0).toUpperCase() +
          background?.slice(1).toLowerCase()
        }`;
      }
      return `Casual`;
    }

    if (style === 'fall-vibes-style') {
      return `Fall vibes`;
    }

    if (style === 'comic-style') {
      return `Comic style`;
    }

    if (style === 'watercolor-style') {
      return `Watercolor`;
    }

    if (style === 'pencil-style') {
      return `Pencil drawing`;
    }

    if (style === null) {
      return 'No style';
    }
  });

  const categories = Object.keys(groupedImages).map((key) => ({
    label: key,
    images: groupedImages[key],
  }));

  const sortedCategories = categories.sort((a, b) => {
    return CategoryWeight[a.label] - CategoryWeight[b.label];
  });

  function getWeight(image) {
    if (image.status === 'final') {
      return 1;
    }

    if (image.prompt === 'manual_media_upload') {
      return 2;
    }

    if (image.status === 'customer_selected') {
      return 3;
    }

    return 4;
  }

  sortedCategories.forEach((category) => {
    category.images.sort((a, b) => {
      return getWeight(a) - getWeight(b);
    });
  });

  const imagesToShow =
    activeTab === 'FINALS'
      ? generatedImages?.filter((image) => image.status === 'final')
      : activeTab === 'USERS'
      ? generatedImages?.filter((media) => media.status === 'customer_selected')
      : predictionResultMedia;

  const portraitJobStyleListQuery = useQuery<{
    aiPortraitVariationsList?: IAiPortraitsVariations;
  }>(
    gql`
      query AiPortraitStylesListForPrompting($typeFilter: String!) {
        aiPortraitVariationsList(typeFilter: $typeFilter) {
          edges {
            id
            displayName
            slug
          }
        }
      }
    `,
    {
      variables: {
        typeFilter: 'style',
      },
    }
  );

  const portraitJobBackgroundListQuery = useQuery<{
    aiPortraitVariationsList?: IAiPortraitsVariations;
  }>(
    gql`
      query AiPortraitBackgroundListForPrompting($typeFilter: String!) {
        aiPortraitVariationsList(typeFilter: $typeFilter) {
          edges {
            id
            displayName
            slug
          }
        }
      }
    `,
    {
      variables: {
        typeFilter: 'background',
      },
    }
  );

  const availableStyles =
    portraitJobStyleListQuery?.data?.aiPortraitVariationsList?.edges;

  const availableBackgrounds =
    portraitJobBackgroundListQuery?.data?.aiPortraitVariationsList?.edges;

  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      color: '#d3d8dc',
    }),
    control: (provided) => ({
      ...provided,
      border: `1px solid ${COLOR_PALLETTE.GRAY_2}`,
      borderRadius: '2px',
      height: '44px',
      outline: 'none',
      width: '160px',
    }),
  };

  const [style, setStyle] = React.useState<string | null>(null);
  const [background, setBackground] = React.useState<string | null>(null);

  const styleOptions =
    availableStyles != null
      ? availableStyles?.map((style) => {
          return {
            value: style.slug,
            label: parseStyleNaming[style.slug],
          };
        })
      : [];

  const styleBackgrounds =
    availableBackgrounds != null
      ? availableBackgrounds?.map((background) => {
          return {
            value:
              background.displayName.charAt(0).toUpperCase() +
              background.displayName.slice(1),
            label:
              background.displayName.charAt(0).toUpperCase() +
              background.displayName.slice(1),
          };
        })
      : [];

  return (
    <>
      {status === 'pending_revision' && (
        <Link
          className="btn btn-outline-danger bg-white"
          to={`/headshot-quality-control/${submissions[0]?.id}`}
        >
          The quality control team is requesting a revision on your submission!
          Please check the QC Details here.
        </Link>
      )}
      <div
        className="card mt-3 border-0"
        style={{ boxShadow: '0 0 1.625em rgba(0,0,0,.05)' }}
      >
        <div className="card-body">
          <h4>Portrait AI Job</h4>
          <div className="grid-cont">
            <form
              className="form-row prompt-input"
              onSubmit={(e) => {
                e.preventDefault();

                createPortraitJobPrediction({
                  variables: {
                    aiPortraitJobId: jobId,
                    prompt,
                    negativePrompt,
                    style,
                    background,
                  },
                }).then(() => {
                  aiPortraitJobPredictionResults.refetch();
                });
              }}
            >
              <div className="prompt-form-container">
                <div className="prompt-form-container_sub">
                  <div className="prompt-input">
                    <label>Enter prompt (use the token @me)</label>
                    <textarea
                      rows={3}
                      className="form-control"
                      placeholder="e.g. A portrait of @me with a hat"
                      value={prompt}
                      onChange={(e) => setPrompt(e.target.value)}
                    />
                  </div>

                  <div className="prompt-input">
                    <label>Enter negative prompt</label>
                    <textarea
                      rows={3}
                      className="form-control"
                      placeholder="e.g. weird hands, weird lips"
                      value={negativePrompt}
                      onChange={(e) => setNegativePrompt(e.target.value)}
                    />
                  </div>
                </div>

                <div className="style-container">
                  <span>Style Group (This won't affect the prompt):</span>
                  <div className="style-dropdowns">
                    <div className="style-dropdown-container">
                      <div
                        className="delete-style-button"
                        onClick={() => {
                          setStyle(null);
                          setBackground(null);
                        }}
                      >
                        <img src={deleteIcon} alt="delete style" />
                      </div>
                      <Select
                        onChange={(e) => {
                          setStyle(e?.value ?? null);
                          setBackground(null);
                        }}
                        options={styleOptions}
                        styles={customStyles}
                        placeholder="Select"
                        value={
                          style == null || style === ''
                            ? null
                            : { value: style, label: parseStyleNaming[style] }
                        }
                      />
                    </div>
                    <div className="style-dropdown-container">
                      <div className="select-background">
                        <div
                          className="delete-background-button"
                          onClick={() => setBackground(null)}
                        >
                          <img src={deleteIcon} alt="delete style" />
                        </div>
                        <Select
                          onChange={(e) => setBackground(e?.value ?? '')}
                          options={styleBackgrounds}
                          styles={customStyles}
                          placeholder="Select"
                          value={
                            background == null || background === ''
                              ? null
                              : {
                                  value: background,
                                  label:
                                    background.charAt(0).toUpperCase() +
                                    background.slice(1).split('-')[0],
                                }
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="prompt-button-container">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={
                      createPortraitJobPredictionMutation.loading ||
                      aiPortraitJobPredictionResults.loading === true ||
                      prompt === '' ||
                      prompt === null ||
                      style === '' ||
                      style === null ||
                      (style === 'casual-style' &&
                        (background === null || background === '')) ||
                      (style === 'professional-style' &&
                        (background === null || background === ''))
                    }
                  >
                    Compute
                  </button>
                </div>
              </div>
            </form>
            <div className="delivery">
              {checkIfCanAccess(session, 'headshotQualityControl') && (
                <button
                  disabled={
                    totalFinals <
                      (ENABLE_AI_HEADSHOT_PACKAGES === true
                        ? numberOfImages ?? 6
                        : 6) ||
                    status === 'completed' ||
                    sendToClientMutation.loading === true
                  }
                  className="btn btn-primary"
                  onClick={() => {
                    completeJob({ aiPortraitJobId: jobId });
                  }}
                >
                  Share portraits with the customer
                </button>
              )}

              <button
                disabled={
                  cancelModelTrainingLoading.loading === true ||
                  retriggerModelTrainingLoading.loading === true
                }
                className="btn btn-primary"
                style={{ marginTop: '8px' }}
                onClick={() => {
                  if (
                    window.confirm(
                      `Are you sure you want to retrigger the model training?`
                    )
                  ) {
                    return retriggerModelTraining({
                      variables: {
                        aiPortraitJobId: jobId,
                      },
                    }).then((res) => {
                      window.alert(`Model training was retriggered`);
                      return portraitJobQuery.refetch();
                    });
                  }
                }}
              >
                Retrigger model training
              </button>

              {status != null &&
                modelTrainingCreatedAt != null &&
                [
                  aiPortraitsStatus.PAID,
                  aiPortraitsStatus.PROCESSING,
                  aiPortraitsStatus.STARTING,
                  aiPortraitsStatus.QUEUED,
                  aiPortraitsStatus.TRIGGERING,
                ].includes(status) && (
                  <button
                    disabled={
                      cancelModelTrainingLoading.loading === true ||
                      retriggerModelTrainingLoading.loading === true
                    }
                    className="btn btn-danger"
                    style={{ marginTop: '8px', marginBottom: '8px' }}
                    onClick={() => {
                      if (
                        window.confirm(
                          `Are you sure you want to cancel the model training?`
                        )
                      ) {
                        return cancelModelTraining({
                          variables: {
                            aiPortraitJobId: jobId,
                          },
                        }).then(() => {
                          window.alert(`Model training was cancelled`);
                          return portraitJobQuery.refetch();
                        });
                      }
                    }}
                  >
                    Cancel model training
                  </button>
                )}

              {ENABLE_AI_HEADSHOT_QC_SYSTEM === true &&
                (submissionDisabled === true ? (
                  <button disabled={true} className="btn btn-primary mt-2">
                    Send submission
                  </button>
                ) : (
                  <Link
                    className="btn btn-primary mt-2"
                    to={locationForModal({
                      location,
                      modal: { modalName: SEND_SUBMISSION_MODAL },
                    })}
                  >
                    Send submission
                  </Link>
                ))}

              {ENABLE_AI_HEADSHOT_QC_SYSTEM === true &&
                submissions[0]?.id != null && (
                  <Link
                    className="btn btn-outline-primary mt-2 mb-2"
                    to={`/headshot-quality-control/${submissions[0]?.id}`}
                  >
                    Go to latest submission
                  </Link>
                )}

              {ENABLE_HEADSHOTS_REFUND_BUTTON === true &&
                headshotRefundData?.refundAmount == null &&
                session?.user?.IAM?.includes('aiPortraitJobWriteAll') &&
                status != null &&
                ![aiPortraitsStatus.CREATED].includes(status) && (
                  <Link
                    className="btn btn-danger mt-2"
                    to={locationForModal({
                      location,
                      modal: { modalName: REFUND_PORTRAIT_MODAL },
                    })}
                  >
                    Refund
                  </Link>
                )}

              <div>Total finals: {totalFinals}</div>

              <div>Job status: {status}</div>

              <div>Editor: {editorEmail}</div>

              <div>customerId: {customerId}</div>
            </div>
          </div>

          {portraitJobQuery.loading === true ? (
            <Loading />
          ) : (
            <div>
              <div className="justify-content-start" style={{ margin: 0 }}>
                <div className="row" style={{ margin: 0 }}>
                  {headshotRefundData?.refundAmount != null && (
                    <div className="col-8 refund-details-container">
                      <h5>Refund details</h5>

                      <div
                        style={{
                          display: 'flex',
                          gap: 13,
                          marginBottom: '8px',
                          marginTop: '16px',
                          alignItems: 'center',
                        }}
                      >
                        <img src={refundIcon} alt="refund icon" />
                        <p
                          style={{
                            margin: 0,
                            color: COLOR_PALLETTE.PRIMARY,
                            fontSize: 14,
                          }}
                        >
                          <span style={{ textTransform: 'capitalize' }}>
                            {headshotRefundData?.refundType} refund:{' '}
                            {formatMoneyCents(
                              headshotRefundData?.refundAmount,
                              {
                                currency: jobRegion?.currency ?? 'USD',
                              }
                            )}
                          </span>{' '}
                          {headshotRefundData?.refundType === 'partial' && (
                            <span>
                              ( Out of:{' '}
                              {formatMoneyCents(totalPaidByCustomer, {
                                currency: jobRegion?.currency ?? 'USD',
                              })}
                              )
                            </span>
                          )}
                        </p>

                        <span
                          style={{
                            fontFamily: 'Nunito Sans',
                            backgroundColor: refundStatusColor,
                            fontSize: 10,
                            fontWeight: 700,
                            color: 'white',
                            textTransform: 'uppercase',
                            borderRadius: 1,
                            paddingLeft: 4,
                            paddingRight: 4,
                            lineHeight: 'normal',
                            height: '14px',
                          }}
                        >
                          {headshotRefundStatus}
                        </span>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          gap: 13,
                          marginBottom: '8px',
                        }}
                      >
                        <img src={refundUserIcon} alt="Refunded by" />
                        <p
                          style={{
                            margin: 0,
                            color: COLOR_PALLETTE.PRIMARY,
                            fontSize: 14,
                          }}
                        >
                          <span>
                            {' '}
                            Refunded by: {headshotRefundData?.refundedByUser}
                          </span>
                        </p>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 13,
                          marginBottom: '24px',
                        }}
                      >
                        <p
                          style={{
                            margin: 0,
                            color: COLOR_PALLETTE.PRIMARY,
                            fontSize: 14,
                          }}
                        >
                          <span>Refund details:</span>
                        </p>

                        <textarea
                          rows={2}
                          className="form-control"
                          value={headshotRefundData?.refundDetails ?? ''}
                          style={{
                            background: 'white',
                            color: 'black',
                            border: '1px solid #C1C8D4',
                            borderRadius: '4px',
                            fontSize: '14px',
                          }}
                          disabled
                        />
                      </div>
                    </div>
                  )}

                  <div className="col-8">
                    <h5>Output details</h5>

                    <div
                      style={{
                        display: 'flex',
                        gap: 13,
                        marginBottom: '8px',
                        marginTop: '16px',
                      }}
                    >
                      <img src={packageIcon} alt="Style" />
                      <p
                        style={{
                          margin: 0,
                          color: COLOR_PALLETTE.PRIMARY,
                          fontSize: 14,
                        }}
                      >
                        <span>{jobPackage}</span>
                      </p>
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        gap: 13,
                        marginBottom: '8px',
                      }}
                    >
                      <img src={styleIcon} alt="Style" />
                      <p
                        style={{
                          margin: 0,
                          color: COLOR_PALLETTE.PRIMARY,
                          fontSize: 14,
                        }}
                      >
                        {portraitsJobStyles != null &&
                        portraitsJobStyles?.length > 0 ? (
                          <span>{portraitsJobStyles?.join(', ')} styles</span>
                        ) : (
                          <span>
                            {jobStyle != null &&
                              `${parseStyleNaming[jobStyle]} style`}
                          </span>
                        )}
                      </p>
                    </div>

                    <div
                      style={{ display: 'flex', gap: 13, marginBottom: '24px' }}
                    >
                      <img src={backgroundIcon} alt="Style" />
                      <p
                        style={{
                          margin: 0,
                          color: COLOR_PALLETTE.PRIMARY,
                          fontSize: 14,
                        }}
                      >
                        {portraitsJobBackgrounds != null &&
                        portraitsJobBackgrounds?.length > 0 ? (
                          <span>
                            {portraitsJobBackgrounds?.join(', ')} backgrounds
                          </span>
                        ) : (
                          <span>
                            {jobBackground != null &&
                              `${parseStyleNaming[jobBackground]} background`}
                          </span>
                        )}
                      </p>
                    </div>
                  </div>

                  {customerFeedback != null && (
                    <div className="col-4">
                      <div className="d-flex justify-content-between">
                        <div className="d-flex justify-content-left">
                          <h5>User rating</h5>
                          <div style={{ marginLeft: 10 }} />
                          {Array.from(Array(5)).map((_, index) => {
                            if ((customerFeedback?.rating ?? 0) >= index + 1) {
                              return (
                                <img
                                  key={index}
                                  src={starIconYellow}
                                  style={{
                                    width: 20,
                                    height: 20,
                                  }}
                                  alt="Star"
                                />
                              );
                            }
                            return (
                              <img
                                key={index}
                                src={starIconGray}
                                style={{
                                  width: 20,
                                  height: 20,
                                }}
                                alt="Star"
                              />
                            );
                          })}
                        </div>
                        <span className="">NPS: {customerFeedback.nps}</span>
                      </div>
                      <textarea
                        rows={3}
                        className="form-control"
                        value={customerFeedback.comments ?? ''}
                        style={{ background: 'white', color: 'black' }}
                        disabled
                      />
                    </div>
                  )}
                </div>
                <div className="row" style={{ margin: 0 }}>
                  <div className="col-8">
                    <h5>Training images</h5>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                      {(croppedTrainingImages ?? []).map((media) => (
                        <div
                          style={{
                            position: 'relative',
                            height: 200,
                            width: 200,
                            marginRight: 5,
                            marginBottom: 5,
                          }}
                          key={media.id}
                          className="image-with-prompt"
                        >
                          <div className="overlay">
                            <div className="buttons">
                              <a
                                download={`${media.id}.jpg`}
                                href={media?.originalImage?.downloadUrl}
                                title="SampleName"
                              >
                                <button
                                  className={`tw-bg-gray-500 hover:tw-bg-gray-700 tw-text-white tw-w-40 tw-h-14 tw-mt-2`}
                                >
                                  Download
                                </button>
                              </a>
                              <button
                                className={`tw-bg-gray-500 hover:tw-bg-gray-700 tw-text-white tw-w-40 tw-h-14 tw-mt-2`}
                                disabled={deleteImageMutation.loading}
                                onClick={() => {
                                  if (
                                    jobId != null &&
                                    window.confirm(
                                      `Are you sure you want to delete this image from the model training?`
                                    )
                                  ) {
                                    return deleteImage({
                                      variables: {
                                        aiPortraitsJobId: jobId,
                                        mediaId: media.id,
                                      },
                                    }).then(() => {
                                      return portraitJobQuery.refetch();
                                    });
                                  }
                                }}
                              >
                                Delete from model training
                              </button>
                            </div>
                          </div>
                          <img
                            src={media.croppedImage.downloadUrl}
                            alt="portfolio"
                            className="img-fluid"
                          />
                        </div>
                      ))}
                      {finalUploadList.length > 0 && (
                        <p style={{ display: 'flex', alignItems: 'center' }}>
                          Loading more images...
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col">
                    <h5>Upload more training images</h5>
                    <Dropzone
                      style={{
                        border: '4px solid #C1C8D4',
                        borderRadius: '4px',
                        height: 250,
                      }}
                      multiple={true}
                      onDrop={(acceptedFiles) => {
                        acceptedFiles.forEach((file) => {
                          const reader = new FileReader();
                          reader.onload = () => {
                            const img = new Image();
                            img.src = reader.result as string;
                            img.onload = () => {
                              if (img.width === img.height) {
                                AddFilesForTraining({ acceptedFiles: [file] });
                              } else {
                                alert('The image is not square');
                              }
                            };
                          };
                          reader.readAsDataURL(file);
                        });
                      }}
                    >
                      <div
                        className="card-body text-center"
                        style={{ marginTop: 30 }}
                      >
                        <div>
                          <p>
                            Drag and drop your image here, <br />
                            <span style={{ textDecoration: 'underline' }}>
                              or browse
                            </span>
                          </p>
                        </div>
                      </div>
                    </Dropzone>
                  </div>
                </div>
              </div>
            </div>
          )}

          {aiPortraitJobPredictionResults.loading === true ? (
            <Loading />
          ) : (
            <div>
              <h5>Results</h5>
              <Dropzone
                style={{
                  border: '4px solid #C1C8D4',
                  borderRadius: '4px',
                  height: 250,
                }}
                multiple={true}
                onDrop={(acceptedFiles) => addFiles({ acceptedFiles })}
              >
                <div
                  className="card-body text-center"
                  style={{ marginTop: 30 }}
                >
                  <div>
                    <p>
                      Drag and drop your image here, <br />
                      <span style={{ textDecoration: 'underline' }}>
                        or browse
                      </span>
                    </p>
                  </div>
                </div>
              </Dropzone>
              <div style={{ display: 'flex', padding: '24px 0px' }}>
                <LinkButton
                  active={activeTab === 'ALL'}
                  label="All images"
                  onClick={() => setActiveTab('ALL')}
                  isFirst={true}
                />
                <LinkButton
                  active={activeTab === 'FINALS'}
                  label="Finals only"
                  onClick={() => setActiveTab('FINALS')}
                />
                <LinkButton
                  active={activeTab === 'USERS'}
                  label="User selection"
                  onClick={() => setActiveTab('USERS')}
                  isLast={true}
                />
              </div>
              <div className="row tw-py-2 tw-gap-2">
                {predictionUploadList != null &&
                  predictionUploadList.map((file) => (
                    <div
                      key={file.clientId}
                      style={{ position: 'relative', width: 512, height: 512 }}
                    >
                      <UploadImagePreview
                        file={file.file}
                        alt="preview"
                        width="512"
                        height="512"
                        style={{
                          objectFit: 'cover',
                          opacity: 0.3,
                          marginRight: 5,
                          marginBottom: 5,
                        }}
                      />
                      <div className="progress">
                        {file.sizeUploaded != null && file.fileSize != null && (
                          <ProgressIndicator
                            progress={file.sizeUploaded / file.fileSize}
                          />
                        )}
                      </div>
                    </div>
                  ))}
              </div>

              {activeTab === 'ALL' ? (
                sortedCategories.map((key, index) => (
                  <div
                    key={index}
                    className="row"
                    style={{
                      gap: '16px',
                      padding: '0px 20px',
                      marginBottom: '24px',
                    }}
                  >
                    <>
                      <p
                        className="subtitle"
                        style={{ textTransform: 'capitalize' }}
                      >
                        {key.label}
                      </p>
                      <div className="gallery-grid-for-final-images">
                        {key.images.map((media) => (
                          <>
                            <div className="image-container" key={media.id}>
                              <HeadshotsImages
                                key={media.id}
                                media={media}
                                promptClick={() =>
                                  media?.prompt != null && copy(media?.prompt)
                                }
                                negativePromptClick={() => {
                                  media.negativePrompt != null &&
                                    copy(media.negativePrompt);
                                }}
                                setAsFinalClick={() =>
                                  setPredictionStatus({
                                    aiPortraitJobPredictionResultId: media.id,
                                    status:
                                      media.status === 'succeeded' ||
                                      media.status === 'customer_selected'
                                        ? 'final'
                                        : 'succeeded',
                                  })
                                }
                                setAsFinalDisabled={
                                  setStatusMutation.loading ||
                                  media.style == null
                                }
                                location={location}
                              />
                            </div>
                          </>
                        ))}
                      </div>
                    </>
                  </div>
                ))
              ) : imagesToShow != null && imagesToShow.length > 0 ? (
                <div
                  className="row"
                  style={{ gap: '16px', padding: '0px 20px' }}
                >
                  {imagesToShow.map((media) =>
                    ['succeeded', 'final', 'customer_selected'].includes(
                      media.status ?? ''
                    ) ? (
                      <HeadshotsImages
                        media={media}
                        promptClick={() => copy(media?.prompt)}
                        negativePromptClick={() => {
                          media.negativePrompt != null &&
                            copy(media.negativePrompt);
                        }}
                        setAsFinalClick={() =>
                          setPredictionStatus({
                            aiPortraitJobPredictionResultId: media.id,
                            status:
                              media.status === 'succeeded' ||
                              media.status === 'customer_selected'
                                ? 'final'
                                : 'succeeded',
                          })
                        }
                        setAsFinalDisabled={setStatusMutation.loading}
                        location={location}
                      />
                    ) : (
                      <div
                        key={media.id}
                        className="d-flex image-with-prompt"
                        style={{
                          position: 'relative',
                          height: 445,
                          width: 445,
                          marginRight: 5,
                          marginBottom: 5,
                          background: `rgba(255, 255, 255, 0.7)`,
                        }}
                      >
                        <LoadingSpinnerCentered />

                        <div className="blurred-text">
                          <span>
                            Status:{' '}
                            {media.status == null ? 'enqueued' : media.status}
                          </span>
                        </div>
                      </div>
                    )
                  )}
                </div>
              ) : (
                <div className="empty-state-container">
                  <div className="empty-state-image-container">
                    <img src={headshotsImagesEmptyState} alt="Empty state" />
                  </div>
                  <p className="final-images-message">
                    No final images selected yet!
                  </p>
                  <p className="final-images-note">
                    In the "All images" tab, select and mark your chosen images
                    as "finals".
                  </p>
                </div>
              )}
            </div>
          )}
        </div>

        <ModalRoute modalName={SEND_SUBMISSION_MODAL}>
          {(routeProps) => {
            return (
              <ConfirmSendToQCModal
                reload={portraitJobQuery.refetch}
                onDismiss={() =>
                  history.push(
                    locationForModal({
                      location,
                      modal: undefined,
                    })
                  )
                }
                disabled={false}
                aiPortraitJobId={
                  portraitJobQuery.data?.snapprAiPortraitsJobById?.id
                }
              />
            );
          }}
        </ModalRoute>

        <ModalRoute modalName={REFUND_PORTRAIT_MODAL}>
          {(routeProps) => {
            return (
              <RefundPortraitJobModal
                history={history}
                location={location}
                reload={portraitJobQuery.refetch}
                setAmountToRefundForParent={setAmountToRefund}
                setRefundTypeForParent={setRefundType}
                setRefundDetailsForParent={setRefundDetails}
                onDismiss={() =>
                  history.push(
                    locationForModal({
                      location,
                      modal: undefined,
                    })
                  )
                }
                disabled={false}
                aiPortraitJobId={
                  portraitJobQuery.data?.snapprAiPortraitsJobById?.id
                }
              />
            );
          }}
        </ModalRoute>

        <ModalRoute modalName={REFUND_PORTRAIT_MODAL_CONFIRMATION}>
          {(routeProps) => {
            return (
              <RefundPortraitJobModalConfirmation
                reload={portraitJobQuery.refetch}
                onDismiss={() =>
                  history.push(
                    locationForModal({
                      location,
                      modal: undefined,
                    })
                  )
                }
                disabled={false}
                aiPortraitJobId={
                  portraitJobQuery.data?.snapprAiPortraitsJobById?.id
                }
                refundType={refundType}
                refundDetails={refundDetails}
                amountToRefund={amountToRefund}
                refetchJob={headshotsRefund.refetch}
              />
            );
          }}
        </ModalRoute>

        {
          <ModalRoute modalName={CHANGE_STYLE_MODAL}>
            {(routeProps) => {
              return (
                <ChangeImageStyleModal
                  reload={() => snapprAiPortraitsJobByIdForAdmin.refetch()}
                  onDismiss={() =>
                    history.push(
                      locationForModal({
                        location,
                        modal: undefined,
                      })
                    )
                  }
                  modalParams={routeProps.modalParams}
                />
              );
            }}
          </ModalRoute>
        }

        <style jsx>{`
          .image-with-prompt .overlay {
            visibility: hidden;
          }

          .image-with-prompt:hover .overlay {
            visibility: visible;
          }

          .overlay {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            backdrop-filter: blur(5px);
            background-color: rgba(0, 0, 0);
            opacity: 0.8;
            transition: opacity 0.3s ease;
          }

          .buttons {
            opacity: 1;
            text-align: center;
            z-index: 3;
          }

          .progress {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
          }

          .grid-cont {
            display: flex;
            flex-direction: row;
            margin-bottom: 24px;
          }

          .prompt-input {
            width: 100%;
          }

          .delivery {
            padding: 8px;
            padding-top: 16px;
            flex-align: right;
            display: flex;
            flex-direction: column;
            text-align: right;
            margin-left: auto;
            width: 350px;
          }

          .refund-details-container {
            border-bottom: 1px solid ${COLOR_PALLETTE.GRAY_2};
            margin-bottom: 16px;
          }

          .gallery-grid-for-final-images {
            display: flex;
            flex-wrap: wrap;
            gap: 16px;
            width: 100%;
            flex: 0 1 auto;
          }

          .image-container img {
            width: 100%;
            height: auto;
          }

          .subtitle {
            font-weight: 600;
            font-size: 24px;
            margin-bottom: 0px;
          }

          .empty-state-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 80px;
            border: 1px solid ${COLOR_PALLETTE.GRAY_3};
          }

          .empty-state-image-container {
            height: 224px;
          }

          .final-images-message {
            font-weight: 600;
            font-size: 22px;
            margin-bottom: 8px;
            color: ${COLOR_PALLETTE.GRAY_5};
          }

          .final-images-note {
            font-weight: 400;
            font-size: 14px;
            color: ${COLOR_PALLETTE.GRAY_4};
          }

          .prompt-form-container {
            display: flex;
            flex-direction: column;
            gap: 8px;
            width: 70%;
          }

          .prompt-form-container_sub {
            display: flex;
            flex-direction: row;
            gap: 8px;
            width: 100%;
          }

          .prompt-input {
            display: flex;
            flex-direction: column;
          }

          .prompt-button-container{
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
          }

          .style-container {
            display: flex;
            flex-direction: column;
            gap: 8px;
          }

          .style-dropdowns {
            display: flex;
            gap: 8px;
          }

          .style-dropdown-container{
            position:relative
          }

          .delete-style-button{
            position: absolute;
            top: 8px;
            right: 47px;
            z-index: 1;
            cursor: pointer;
            display: ${style == null || style === '' ? 'none' : 'block'};
          }

          .delete-background-button{
            position: absolute;
            top: 8px;
            right: 47px;
            z-index: 1;
            cursor: pointer;
            display: ${
              background == null || background === '' ? 'none' : 'block'
            };
          }

          .select-background {
            display: ${
              style == null ||
              (style !== 'casual-style' && style !== 'professional-style')
                ? 'none'
                : 'block'
            };
              ? 'none'
              : 'block'};
          }
        `}</style>
      </div>
    </>
  );
};
