import React from 'react';

export const LoadingSpinner: React.FC<{
  noMargin?: boolean;
}> = ({ noMargin }) => (
  <>
    <div
      className="load-spinner"
      style={{
        margin: noMargin ? 0 : '60px',
      }}
    >
      Loading...
    </div>

    <style jsx>{`
      .load-spinner,
      .load-spinner:after {
        border-radius: 50%;
        width: 30px;
        height: 30px;
      }

      .load-spinner {
        font-size: 5px;
        position: relative;
        text-indent: -9999em;
        border-top: 1.1em solid rgba(91, 91, 91, 0.2);
        border-right: 1.1em solid rgba(91, 91, 91, 0.2);
        border-bottom: 1.1em solid rgba(91, 91, 91, 0.2);
        border-left: 1.1em solid #5b5b5b;
        transform: translateZ(0);
        animation: load8 0.5s infinite linear;
        overflow: hidden;
      }

      @keyframes load8 {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    `}</style>
  </>
);

export const LoadingSpinnerCentered = () => (
  <div className="d-flex flex-1 justify-content-center align-items-center">
    <LoadingSpinner />
  </div>
);
