import {
  locationForModal,
  ModalRoute,
} from '../../../../components/ModalRoute';
import React, { useState } from 'react';
import { Modal } from '../../../../components/Modal';
import { useHistory, useLocation } from 'react-router-dom';
import { gql, useMutation } from '../../../../components/ApolloClient';
import { usePromise } from '../../../../components/usePromise';

export const MODAL_NAME_REPORT_EDITING_ISSUES = 'report-editing-issues';

type IModalParams = {
  modalName: string;
  submissionId: string;
};

function EditingComplianceIssueModal({
  modalParams,
  complianceIssueOptions,
  onSubmit,
  onRequestClose,
}: {
  modalParams: IModalParams;
  complianceIssueOptions: { value: string; label: string }[];
  onSubmit: () => Promise<void>;
  onRequestClose: () => void;
}) {
  const [mainIssue, setMainIssue] = useState<string | undefined>();
  const [otherIssues, setOtherIssues] = useState<string[]>([]);
  const [description, setDescription] = useState<string | undefined>();

  const [reportEditingComplianceIssue] = useMutation<
    { success: boolean },
    {
      submissionId: string;
      mainIssue: string;
      otherIssues?: string[];
      description: string;
    }
  >(gql`
    mutation BookingReportEditingComplianceIssueAsAdmin(
      $submissionId: ID!
      $mainIssue: String!
      $otherIssues: [String!]
      $description: String!
    ) {
      bookingReportEditingComplianceIssueAsAdmin(
        input: {
          submissionId: $submissionId
          mainIssue: $mainIssue
          otherIssues: $otherIssues
          description: $description
        }
      ) {
        success
      }
    }
  `);

  const reportEditingComplianceIssuePromise = usePromise();

  const isFormValid = (mainIssue ?? '') !== '' && (description ?? '') !== '';

  return (
    <Modal onDismiss={onRequestClose}>
      <div className="card my-4">
        <div className="card-header">
          <h5 className="mb-0">Editing issues</h5>
        </div>

        <form
          onSubmit={(e) => {
            e.preventDefault();

            if (mainIssue == null || description == null) return;

            reportEditingComplianceIssuePromise.setPromise(
              reportEditingComplianceIssue({
                variables: {
                  submissionId: modalParams.submissionId,
                  mainIssue,
                  otherIssues,
                  description,
                },
              }).then(onSubmit)
            );
          }}
        >
          <div
            className="card-body d-flex flex-column"
            style={{ width: '700px' }}
          >
            <div className="form-group">
              <h5>Main issue*</h5>

              <div className="col-md-5 p-0">
                <select
                  required
                  className="form-control"
                  value={mainIssue}
                  onChange={(e) => {
                    const value = e.target.value;

                    if (value === '') {
                      setMainIssue(undefined);
                      return;
                    }

                    setMainIssue(value);
                    setOtherIssues(
                      otherIssues.filter((issue) => issue !== value)
                    );
                  }}
                >
                  <option value="">Select an option</option>
                  {complianceIssueOptions?.map(({ value, label }) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="form-group">
              <h5>Other issues</h5>

              {complianceIssueOptions?.map(({ value, label }) => (
                <div key={value} className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="editing-issues"
                    id={value}
                    value={value}
                    checked={otherIssues.includes(value)}
                    disabled={mainIssue === value}
                    onChange={() =>
                      otherIssues.includes(value) === true
                        ? setOtherIssues(
                            otherIssues.filter((issue) => issue !== value)
                          )
                        : setOtherIssues([...otherIssues, value])
                    }
                  />
                  <label className="form-check-label" htmlFor={value}>
                    {label}
                  </label>
                </div>
              ))}
            </div>

            <div className="form-group">
              <label
                htmlFor="issue-description"
                style={{ fontSize: '1.25rem' }}
              >
                Feedback details*
              </label>
              <textarea
                className="form-control"
                id="issue-description"
                rows={4}
                value={description}
                onChange={(event) => setDescription(event.target.value)}
              />
            </div>
          </div>

          <div className="card-footer d-flex justify-content-end align-items-center">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={
                isFormValid !== true ||
                reportEditingComplianceIssuePromise.isPending === true
              }
            >
              {reportEditingComplianceIssuePromise.isPending === true
                ? 'Submitting...'
                : 'Submit'}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export function ReportEditingIssues({
  onSubmit,
  complianceIssueOptions,
}: {
  onSubmit: () => Promise<void>;
  complianceIssueOptions: { value: string; label: string }[];
}) {
  const history = useHistory();
  const location = useLocation();

  return (
    <ModalRoute
      modalName={MODAL_NAME_REPORT_EDITING_ISSUES}
      render={({ modalParams }: { modalParams: IModalParams }) => (
        <EditingComplianceIssueModal
          key={modalParams.submissionId}
          complianceIssueOptions={complianceIssueOptions}
          modalParams={modalParams}
          onRequestClose={() => {
            history.replace(
              locationForModal({
                location,
                modal: undefined,
              })
            );
          }}
          onSubmit={onSubmit}
        />
      )}
    />
  );
}
