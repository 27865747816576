import React from 'react';
import Papa from 'papaparse';
import Dropzone from 'react-dropzone';
import { Link, RouteComponentProps } from 'react-router-dom';
import Component2 from '@reach/component-component';
import InView from 'react-intersection-observer';
import moment from 'moment-timezone';
import qs from 'query-string';
import Select from 'react-select';
import csvIcon from '../../components/img/csv-icon.svg';

import ContentWrapper from '../../components/ContentWrapper';
import { gql, useMutation, useQuery } from '../../components/ApolloClient';
import { TdLink } from '../../components';
import { ModalRoute, locationForModal } from '../../components/ModalRoute';
import { Modal } from '../../components/Modal';
import { ensureString } from '../../utils/strings';
import { SearchField } from '../partners/components';
import { AssignJobToUserModal } from './details';
import { ActionsDropdown } from '../../components/ActionsDropdown';

const basePath = 'ai-ecommerce-job';
const categoryBasePath = 'ai-ecommerce-job/category';
const MODAL_NAME_CREATE_PRODUCT = 'create-product';
const MODAL_NAME_CREATE_PRODUCT_BATCH = 'create-batch-product';

const MODAL_NAME_CREATE_CATEGORY = 'create-template';
const MODAL_ASSIGN_USER_TO_ECOMMERCE_PRODUCT =
  'modal_assign_user_to_ecommerce_product';

const AiProductJobsList = ({
  location,
  history,
  session,
  cursors,
  setCursors,
}: {
  location: RouteComponentProps['location'];
  history: RouteComponentProps['history'];
  session: {
    token: string;
    uid: string;
    user: {
      IAM: string[];
    };
  };
  cursors: string[];
  setCursors: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  return (
    <div
      className="table-responsive"
      style={{
        position: 'relative',
        padding: '16px',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'left',
          alignItems: 'center',
        }}
      >
        <SearchField
          style={{ margin: 8 }}
          onSearch={({ q }) =>
            history.push({
              ...location,
              search: qs.stringify({
                ...qs.parse(location.search),
                q: q || undefined,
              }),
            })
          }
          defaultValue={qs.parse(location.search).q || ''}
        />

        <CreateButton
          location={location}
          modalName={MODAL_NAME_CREATE_PRODUCT}
          label="Create a product"
        />

        <CreateButton
          location={location}
          modalName={MODAL_NAME_CREATE_PRODUCT_BATCH}
          label="Upload a product batch"
        />
      </div>

      <div style={{ margin: 8, display: 'flex', flexWrap: 'wrap' }}>
        <table className="table table-hover">
          <thead>
            <tr>
              <th className="text-muted text-truncate">ID</th>
              <th className="text-muted text-truncate">Name</th>
              <th className="text-muted text-truncate">Created at</th>
              <th className="text-muted text-truncate">CustomerId</th>
              <th className="text-muted text-truncate">Actions</th>
            </tr>
          </thead>

          <tbody>
            {[null, ...cursors].map((cursor, index) => (
              <DataRowsChunk
                location={location}
                key={cursor ?? '0'}
                cursor={cursor ?? '0'}
                session={session}
                onMore={
                  index === cursors.length + 1 - 1
                    ? ({ nextCursor }) =>
                        setCursors((state) =>
                          state.includes(nextCursor)
                            ? state
                            : [...state, nextCursor]
                        )
                    : undefined
                }
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const AiProductCategories = ({
  location,
  session,
  cursors,
  setCursors,
}: {
  location: RouteComponentProps['location'];
  session: {
    token: string;
    uid: string;
    user: {
      IAM: string[];
    };
  };
  cursors: string[];
  setCursors: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  return (
    <div
      className="table-responsive"
      style={{
        position: 'relative',
        padding: '16px',
      }}
    >
      <CreateButton
        location={location}
        modalName={MODAL_NAME_CREATE_CATEGORY}
        label="Create a template"
      />
      <div style={{ margin: 8, display: 'flex', flexWrap: 'wrap' }}>
        <table className="table table-hover">
          <thead>
            <tr>
              <th className="text-muted text-truncate">ID</th>
              <th className="text-muted text-truncate">Name</th>
              <th className="text-muted text-truncate">Images</th>
            </tr>
          </thead>
          <tbody>
            {[null, ...cursors].map((cursor, index) => (
              <CategoriesDataRowsChunk
                key={cursor ?? '0'}
                cursor={cursor ?? '0'}
                session={session}
                onMore={
                  index === cursors.length + 1 - 1
                    ? ({ nextCursor }) =>
                        setCursors((state) =>
                          state.includes(nextCursor)
                            ? state
                            : [...state, nextCursor]
                        )
                    : undefined
                }
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

function CSVUploader({ setCsvFile }: { setCsvFile: (file: File) => void }) {
  const [cvsFileName, setCvsFileName] = React.useState<string | undefined>(
    undefined
  );

  return (
    <div>
      <div className="form-group">
        {cvsFileName != null ? (
          <div
            className="form-control"
            style={{
              backgroundColor: '#F6F9FF',
              border: '1px solid #C1C8D4',
              minHeight: 150,
              width: '100%',
            }}
          >
            <div
              style={{
                flex: 1,
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 17,
              }}
            >
              <img
                src={csvIcon}
                alt="csv"
                style={{
                  width: 35,
                }}
              />

              <div>
                <span
                  style={{
                    textOverflow: 'ellipsis',
                    display: 'inline-block',
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    paddingRight: '0.5em',
                  }}
                >
                  {cvsFileName}
                </span>
              </div>
            </div>
          </div>
        ) : (
          <Dropzone
            accept="text/csv"
            style={{
              border: '1px dashed #C1C8D4',
              borderRadius: '4px',
              height: 150,
              width: '100%',
            }}
            multiple={false}
            disabled={false}
            onDrop={(acceptedFiles) => {
              if (acceptedFiles.length === 0) return;

              const file = acceptedFiles[0];

              setCsvFile(file);

              setCvsFileName(file.name);
            }}
          >
            {({ isDragReject, rejectedFiles }) => (
              <div
                style={{
                  marginTop: 30,
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                <div
                  className={
                    isDragReject === true || rejectedFiles.length > 0
                      ? 'text-danger'
                      : ''
                  }
                >
                  <p>
                    Drag and drop your CSV file here, <br />
                    <span style={{ textDecoration: 'underline' }}>
                      or browse
                    </span>
                  </p>
                  {(isDragReject === true || rejectedFiles.length > 0) && (
                    <p>Only CSV files are supported.</p>
                  )}
                </div>
              </div>
            )}
          </Dropzone>
        )}
      </div>
    </div>
  );
}

function convertCsvFileToJson(file: File): Promise<Record<string, any>[]> {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      complete: function (results) {
        resolve(results.data);
      },
      error: function (err) {
        reject(err);
      },
      header: true,
    });
  });
}

const CreateProductBatchModal = ({
  onDismiss,
  history,
}: {
  onDismiss: () => void;
  history: RouteComponentProps['history'];
}) => {
  const [batchName, setBatchName] = React.useState<string | null>(null);
  const [csvFile, setCsvFile] = React.useState<File | undefined>(undefined);

  const [createNewBatch, createNewBatchMutation] = useMutation(gql`
    mutation CreateEcommerceProductBatchImport(
      $importRequest: String!
      $batchName: String!
    ) {
      ecommerceProductBatchCreate(
        input: { importRequest: $importRequest, batchName: $batchName }
      ) {
        success
        errors
      }
    }
  `);

  const errors =
    createNewBatchMutation?.data?.ecommerceProductBatchCreate?.errors;

  const saveImport = () => {
    if (csvFile == null) return;
    convertCsvFileToJson(csvFile).then((data) =>
      createNewBatch({
        variables: {
          importRequest: JSON.stringify(data),
          batchName,
        },
      }).then((res) => {
        if (res.data.ecommerceProductBatchCreate.success === true) {
          onDismiss();
        }
      })
    );
  };

  return (
    <Modal onDismiss={onDismiss}>
      <div className="card tw-w-[500px]">
        <div
          className="card-body d-flex flex-column"
          style={{ minWidth: 500, minHeight: 250 }}
        >
          <h3 className="text-center">Upload a product batch</h3>

          <form
            className="d-flex flex-grow-1 flex-column mt-3"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="form-group">
              <div>
                <label>Batch name</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="productName"
                  aria-describedby="product name"
                  placeholder="Enter product name"
                  value={batchName ?? ''}
                  onChange={({ target: { value } }) => setBatchName(value)}
                />
              </div>
              <CSVUploader setCsvFile={setCsvFile} />
            </div>

            {errors && (
              <div className=" tw-text-red-600 tw-text-sm">{errors}</div>
            )}
            <div className="tw-h-4" />

            <div className="row">
              <div className="col">
                <button
                  type="button"
                  className="btn btn-outline-secondary btn-block"
                  onClick={onDismiss}
                >
                  Cancel
                </button>
              </div>
              <div className="col">
                <button
                  type="submit"
                  onClick={() => saveImport()}
                  disabled={
                    batchName == null ||
                    csvFile == null ||
                    createNewBatchMutation.loading
                  }
                  className="btn btn-primary btn-block"
                >
                  Create batch
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

const CreateProductModal = ({
  onDismiss,
  history,
}: {
  onDismiss: () => void;
  history: RouteComponentProps['history'];
}) => {
  const [productName, setProductName] = React.useState<string | null>(null);
  const [companyName, setCompanyName] = React.useState<string | null>(null);
  const [type, setType] = React.useState<{
    label: string;
    value: string;
  } | null>(null);

  const [createProduct] = useMutation<
    {
      aiProductJobCreateFromAdmin: {
        success: boolean;
        aiProductJobId: string;
      };
    },
    {
      productName: string;
      type: string;
      companyName: string;
    }
  >(
    gql`
      mutation aiProductJobCreateFromAdmin(
        $productName: String!
        $type: String
        $companyName: String
      ) {
        aiProductJobCreateFromAdmin(
          input: {
            productName: $productName
            type: $type
            companyName: $companyName
          }
        ) {
          success
          aiProductJobId
        }
      }
    `
  );

  return (
    <Modal onDismiss={onDismiss}>
      <div className="card">
        <div
          className="card-body d-flex flex-column"
          style={{ minWidth: 500, minHeight: 250 }}
        >
          <h3 className="text-center">Create a product</h3>

          <form
            className="d-flex flex-grow-1 flex-column mt-3"
            onSubmit={(e) => {
              e.preventDefault();

              productName != null &&
                companyName != null &&
                type != null &&
                createProduct({
                  variables: {
                    productName: productName,
                    type: type.value,
                    companyName: companyName,
                  },
                }).then((res) => {
                  if (res.data?.aiProductJobCreateFromAdmin.aiProductJobId) {
                    history.push(
                      `ai-ecommerce-job/${res.data?.aiProductJobCreateFromAdmin.aiProductJobId}`
                    );
                  }
                });
            }}
          >
            <div className="form-group">
              <div>
                <label>Product name</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="productName"
                  aria-describedby="product name"
                  placeholder="Enter product name"
                  value={productName ?? ''}
                  onChange={({ target: { value } }) => setProductName(value)}
                />
              </div>
              <div className="tw-h-2" />
              <div>
                <label>Type</label>
                <Select
                  options={[
                    { value: 'outreach-campaign', label: 'Outreach campaign' },
                    { value: 'Pilot', label: 'Pilot' },
                    { value: 'customer-demo', label: 'Customer demo' },
                    { value: 'other', label: 'Other' },
                  ]}
                  placeholder=""
                  value={type}
                  onChange={(selectedOption) =>
                    selectedOption != null && setType(selectedOption)
                  }
                />
              </div>
              <div className="tw-h-2" />
              <div>
                <label>Company name</label>
                <input
                  type="text"
                  className="form-control"
                  id="company-name"
                  name="companyName"
                  aria-describedby="company name"
                  placeholder="Enter company name"
                  value={companyName ?? ''}
                  onChange={({ target: { value } }) => setCompanyName(value)}
                />
              </div>
            </div>
            <div className="tw-h-4" />

            <div className="row">
              <div className="col">
                <button
                  type="button"
                  className="btn btn-outline-secondary btn-block"
                  onClick={onDismiss}
                >
                  Cancel
                </button>
              </div>
              <div className="col">
                <button
                  type="submit"
                  className="btn btn-primary btn-block"
                  disabled={
                    productName === '' ||
                    companyName === '' ||
                    productName == null ||
                    companyName == null ||
                    type == null
                  }
                >
                  Create product
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

const CreateButton = ({
  location,
  modalName,
  label,
}: {
  location: RouteComponentProps['location'];
  modalName: string;
  label: string;
}) => (
  <React.Fragment>
    <Link
      to={locationForModal({
        location,
        modal: { modalName: modalName },
      })}
      className={'btn btn-secondary mr-2'}
      replace
    >
      {label}
    </Link>
  </React.Fragment>
);

const CreateCategoryModal = ({ onDismiss }: { onDismiss: () => void }) => {
  const [categoryName, setCategoryName] = React.useState('');
  const [displayName, setDisplayName] = React.useState('');

  const [createCategory] = useMutation<
    {
      aiProductJobCategoryCreate: {
        success: boolean;
        aiProductJobCategoryId: string;
      };
    },
    {
      name: string;
      displayName: string;
    }
  >(
    gql`
      mutation aiProductJobCategoryCreateFromAdmin(
        $name: String!
        $displayName: String!
      ) {
        aiProductJobCategoryCreate(
          input: { name: $name, displayName: $displayName }
        ) {
          success
          aiProductJobCategoryId
        }
      }
    `
  );

  return (
    <Modal onDismiss={onDismiss}>
      <div className="card">
        <div
          className="card-body d-flex flex-column"
          style={{ minWidth: 500, minHeight: 250 }}
        >
          <h3 className="text-center">Create a template</h3>

          <form
            className="d-flex flex-grow-1 flex-column mt-3"
            onSubmit={(e) => {
              e.preventDefault();

              createCategory({
                variables: {
                  name: categoryName,
                  displayName,
                },
              }).then((res) => {
                if (res.data?.aiProductJobCategoryCreate.success) {
                  window.location.replace(
                    `${categoryBasePath}/${res.data?.aiProductJobCategoryCreate.aiProductJobCategoryId}`
                  );
                }
              });
            }}
          >
            <div className="form-group">
              <label>Category name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="categoryName"
                aria-describedby="category name"
                placeholder="ex: outdoors_category"
                onChange={({ target: { value } }) => setCategoryName(value)}
              />
            </div>

            <div className="form-group">
              <label>Category display name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="categoryDisplayName"
                aria-describedby="category display name"
                placeholder="ex Outdoors"
                onChange={({ target: { value } }) => setDisplayName(value)}
              />
            </div>

            <button
              type="submit"
              className="btn btn-primary btn-block"
              disabled={categoryName === ''}
            >
              Create Category
            </button>
          </form>
        </div>
      </div>
    </Modal>
  );
};

function DataRowsChunk({
  cursor,
  session,
  location,
  onMore,
}: {
  cursor?: string;
  session: {
    token: string;
    uid: string;
    user: {
      IAM: string[];
    };
  };
  location: RouteComponentProps['location'];
  onMore?: ({ nextCursor }: { nextCursor: string }) => void;
}) {
  const search = React.useMemo(
    () => ensureString(qs.parse(location.search)['q'] ?? null),
    [location]
  );

  const aiProductJobList = useQuery<{
    aiProductJobList: {
      cursor: string;
      first: number;
      edges: {
        id: string;
        name: string;
        createdAt: string;
        customer?: {
          id: string;
        };
      }[];
    };
  }>(
    gql`
      query aiProductJobListInAdmin(
        $cursor: String
        $first: Int
        $search: String
      ) {
        aiProductJobList(cursor: $cursor, first: $first, search: $search) {
          cursor
          edges {
            id
            name
            createdAt
            customer {
              id
            }
          }
        }
      }
    `,
    {
      variables: {
        cursor,
        first: cursor == null ? 20 : 40,
        search,
      },
      fetchPolicy: 'network-only',
    }
  );

  const productList = aiProductJobList.data?.aiProductJobList.edges;

  const nextCursor = aiProductJobList.data?.aiProductJobList?.cursor;

  const [getLoginLink, getLoginLinkMutation] = useMutation<
    {
      aiProductGenerateLoginLink: {
        link: string;
      };
    },
    {
      aiProductJobId: string;
    }
  >(
    gql`
      mutation aiProductGenerateLoginLinkFromAdminEcommerceJobsList(
        $aiProductJobId: ID!
      ) {
        aiProductGenerateLoginLink(input: { aiProductJobId: $aiProductJobId }) {
          link
        }
      }
    `
  );

  return (
    <>
      {onMore == null || nextCursor == null ? null : (
        <InView>
          {({ inView, ref }) => (
            <tr ref={ref}>
              {inView && <Component2 didMount={() => onMore({ nextCursor })} />}
            </tr>
          )}
        </InView>
      )}

      {productList == null && aiProductJobList.loading
        ? Array.from(Array(4)).map((i, index) => (
            <tr key={'loading-' + index}>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
            </tr>
          ))
        : productList?.map((product, index) => (
            <tr key={product.id}>
              <TdLink
                title={product.id}
                to={`${basePath}/${product.id}`}
                className="text-truncate"
              >
                {product.id}
              </TdLink>
              <TdLink
                title={product.name}
                to={`${basePath}/${product.id}`}
                className="text-truncate"
              >
                {product.name}
              </TdLink>
              <TdLink
                title={product.createdAt}
                to={`${basePath}/${product.id}`}
                className="text-truncate"
              >
                {moment(product.createdAt)
                  .tz(moment.tz.guess())
                  .format('YYYY-MM-DD HH:mm z')}
              </TdLink>
              <TdLink
                title={product?.customer?.id}
                to={`${basePath}/${product.id}`}
                className="text-truncate"
              >
                {product?.customer?.id}
              </TdLink>

              <td className="">
                <ActionsDropdown
                  direction="bottom"
                  customTransform="translate(-100%)"
                >
                  {({ onClose }) => (
                    <div
                      style={{
                        textAlign: 'left',
                        flexDirection: 'column',
                        minWidth: 160,
                      }}
                    >
                      <Link
                        className="btn"
                        to={locationForModal({
                          location,
                          modal: {
                            modalName: MODAL_ASSIGN_USER_TO_ECOMMERCE_PRODUCT,
                            productJobId: product.id,
                          },
                        })}
                      >
                        Assign user
                      </Link>
                      <button
                        className="btn tw-p-1 tw-px-3 tw-border-none"
                        disabled={getLoginLinkMutation.loading}
                        onClick={() => {
                          product.customer?.id != null &&
                            getLoginLink({
                              variables: {
                                aiProductJobId: product.id,
                              },
                            }).then(async (res) => {
                              const loginLink =
                                res?.data?.aiProductGenerateLoginLink?.link;
                              if (loginLink == null || !navigator?.clipboard) {
                                window.alert(
                                  'Failed generating login link, please check your permissions'
                                );
                              } else {
                                await navigator.clipboard.writeText(loginLink);
                                window.alert('Login link copied to clipboard');
                              }
                            });
                        }}
                      >
                        Generate login link
                      </button>
                    </div>
                  )}
                </ActionsDropdown>
              </td>
            </tr>
          ))}

      {onMore == null || nextCursor == null ? null : (
        <InView>
          {({ inView, ref }) => (
            <tr ref={ref}>
              {inView && <Component2 didMount={() => onMore({ nextCursor })} />}
            </tr>
          )}
        </InView>
      )}
    </>
  );
}

function CategoriesDataRowsChunk({
  cursor,
  session,
  onMore,
}: {
  cursor?: string;
  session: {
    token: string;
    uid: string;
    user: {
      IAM: string[];
    };
  };
  onMore?: ({ nextCursor }: { nextCursor: string }) => void;
}) {
  const aiProductJobCategoriesList = useQuery<{
    aiProductJobCategoriesList: {
      cursor: string;
      edges: {
        id: string;
        displayName: string;
        name: string;
        categoryMedia: {
          total: number;
          edges: {
            prompt: string;
            deactivatedAt: string;
            imageItem: {
              id: string;
              downloadUrl: string;
            };
          };
        };
      }[];
    };
  }>(
    gql`
      query aiProductJobCategoriesListInAdmin(
        $cursor: String
        $search: String
      ) {
        aiProductJobCategoriesList(cursor: $cursor, search: $search) {
          cursor
          edges {
            id
            displayName
            name
            categoryMedia {
              total
              edges {
                id
                prompt
                deactivatedAt
                imageItem {
                  id
                  downloadUrl
                }
              }
            }
          }
        }
      }
    `,
    {
      variables: {
        cursor,
        first: cursor == null ? 20 : 40,
      },
    }
  );

  const categoryList =
    aiProductJobCategoriesList.data?.aiProductJobCategoriesList.edges;

  const nextCursor =
    aiProductJobCategoriesList.data?.aiProductJobCategoriesList?.cursor;

  return (
    <>
      {onMore == null || nextCursor == null ? null : (
        <InView>
          {({ inView, ref }) => (
            <tr ref={ref}>
              {inView && <Component2 didMount={() => onMore({ nextCursor })} />}
            </tr>
          )}
        </InView>
      )}

      {categoryList == null && aiProductJobCategoriesList.loading
        ? Array.from(Array(4)).map((i, index) => (
            <tr key={'loading-' + index}>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
            </tr>
          ))
        : categoryList?.map((category, index) => (
            <tr key={category.id}>
              <TdLink
                title={category.id}
                to={`${categoryBasePath}/${category.id}`}
                className="text-truncate"
              >
                {category.id}
              </TdLink>
              <TdLink
                title={category.displayName}
                to={`${basePath}/${category.id}`}
                className="text-truncate"
              >
                {category.name}
              </TdLink>

              <TdLink
                title={category.categoryMedia.total}
                to={`${basePath}/${category.id}`}
                className="text-truncate"
              >
                {category.categoryMedia.total}
              </TdLink>
            </tr>
          ))}

      {onMore == null || nextCursor == null ? null : (
        <InView>
          {({ inView, ref }) => (
            <tr ref={ref}>
              {inView && <Component2 didMount={() => onMore({ nextCursor })} />}
            </tr>
          )}
        </InView>
      )}
    </>
  );
}

const TabCode = {
  AI_PRODUCT_JOBS_LIST: 'AI_PRODUCT_JOBS_LIST',
  AI_PRODUCT_JOBS_TEMPLATES: 'AI_PRODUCT_JOBS_TEMPLATES',
};

export const AiProductJobListRoute = ({
  location,
  session,
  history,
}: {
  location: RouteComponentProps['location'];
  session: {
    token: string;
    uid: string;
    user: {
      IAM: string[];
    };
  };
  history: RouteComponentProps['history'];
}) => {
  const [cursors, setCursors] = React.useState<string[]>([]);
  const [categoriesCursors, setCategoriesCursors] = React.useState<string[]>(
    []
  );

  const selectedTab =
    qs.parse(location.search).selectedTab ?? TabCode.AI_PRODUCT_JOBS_LIST;

  return (
    <>
      <h2>AI Ecommerce jobs</h2>
      <ContentWrapper>
        <div
          className="table-responsive"
          style={{
            position: 'relative',
          }}
        >
          <div className="d-flex flex-column" style={{ minHeight: 400 }}>
            <div className="card flex-1">
              <div className="card-header">
                <ul className="nav nav-tabs card-header-tabs">
                  <li className="nav-item">
                    <Link
                      style={{
                        borderBottomRightRadius: 0,
                        borderBottomLeftRadius: 0,
                      }}
                      className={
                        'btn btn-link nav-link' +
                        (selectedTab !== TabCode.AI_PRODUCT_JOBS_LIST
                          ? ''
                          : ' active')
                      }
                      to={{
                        ...location,
                        search: qs.stringify({
                          ...qs.parse(location.search),
                          selectedTab: TabCode.AI_PRODUCT_JOBS_LIST,
                          q: undefined,
                        }),
                      }}
                    >
                      <strong className="mb-0">AI Ecommerce jobs</strong>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      style={{
                        borderBottomRightRadius: 0,
                        borderBottomLeftRadius: 0,
                      }}
                      className={
                        'btn btn-link nav-link' +
                        (selectedTab !== TabCode.AI_PRODUCT_JOBS_TEMPLATES
                          ? ''
                          : ' active')
                      }
                      to={{
                        ...location,
                        search: qs.stringify({
                          ...qs.parse(location.search),
                          selectedTab: TabCode.AI_PRODUCT_JOBS_TEMPLATES,
                          q: undefined,
                        }),
                      }}
                    >
                      <strong className="mb-0">AI Ecommerce templates</strong>
                    </Link>
                  </li>
                </ul>
              </div>

              {TabCode.AI_PRODUCT_JOBS_LIST === selectedTab ? (
                <AiProductJobsList
                  cursors={cursors}
                  setCursors={setCursors}
                  location={location}
                  history={history}
                  session={session}
                />
              ) : TabCode.AI_PRODUCT_JOBS_TEMPLATES === selectedTab ? (
                <AiProductCategories
                  location={location}
                  session={session}
                  cursors={categoriesCursors}
                  setCursors={setCategoriesCursors}
                />
              ) : (
                <div className="d-flex justify-content-center align-items-center mb-3 mt-3">
                  <h3>{selectedTab}</h3>
                </div>
              )}
            </div>
          </div>
        </div>
      </ContentWrapper>
      <ModalRoute
        modalName={MODAL_NAME_CREATE_PRODUCT}
        render={(props) => (
          <CreateProductModal
            {...props}
            session={session}
            history={history}
            onDismiss={() =>
              Promise.resolve()
                .then(() =>
                  history.push(locationForModal({ location, modal: undefined }))
                )
                .then(() => window.location.reload())
            }
          />
        )}
      />

      <ModalRoute
        modalName={MODAL_NAME_CREATE_PRODUCT_BATCH}
        render={(props) => (
          <CreateProductBatchModal
            {...props}
            session={session}
            history={history}
            onDismiss={() =>
              Promise.resolve().then(() =>
                history.push(locationForModal({ location, modal: undefined }))
              )
            }
          />
        )}
      />

      <ModalRoute
        modalName={MODAL_NAME_CREATE_CATEGORY}
        render={(props) => (
          <CreateCategoryModal
            {...props}
            session={session}
            onDismiss={() =>
              Promise.resolve().then(() =>
                history.push(locationForModal({ location, modal: undefined }))
              )
            }
          />
        )}
      />

      <ModalRoute modalName={MODAL_ASSIGN_USER_TO_ECOMMERCE_PRODUCT}>
        {({
          modalParams,
        }: {
          modalParams: {
            productJobId: string;
          };
        }) => (
          <AssignJobToUserModal
            refetch={() => {
              history.push(locationForModal({ location, modal: undefined }));
              window.location.reload();
            }}
            modalParams={modalParams}
            onDismiss={() =>
              Promise.resolve().then(() =>
                history.push(locationForModal({ location, modal: undefined }))
              )
            }
          />
        )}
      </ModalRoute>
    </>
  );
};
