import React from 'react';

export const COLOR_STANDARD_BORDER = `#E8EDF5`;

export const STANDARD_BORDER = `1px solid ${COLOR_STANDARD_BORDER}`;

export const Heading5 = ({
  title,
  align = 'left',
  marginBottom = 8,
  icon,
  iconAlt,
}: {
  title: React.ReactNode;
  align?: 'center' | 'left';
  marginBottom?: number;
  marginTop?: number;
  icon?: string;
  iconAlt?: string;
}) => (
  <div style={{ display: 'flex', marginBottom, alignItems: 'center' }}>
    {icon && (
      <img
        src={icon || ''}
        style={{ height: 24, marginRight: 12 }}
        alt={iconAlt}
      />
    )}
    <h5
      style={{
        fontSize: 18,
        color: '#454F5C',
        textAlign: align,
        marginBottom: 0,
      }}
    >
      {title}
    </h5>
  </div>
);
