import React from 'react';
import uuidV4 from 'uuid/v4';
import axios, { CancelTokenSource } from 'axios';
import { ZQueue } from 'zqueue';

import { apiFetch } from '../utils/apiFetch';
import { isVideo } from './UploadManager';

export const COLOR_SNAPPR_BLUE = '#1f6fff';

export function addIdToFiles<T>({
  files,
}: {
  files: { file: File; customData?: T }[];
}) {
  return [...files].map(({ file, customData }) => ({
    clientId: uuidV4(),
    file,
    customData,
  }));
}

export function useUploadManager<T>({
  token,
  isPublic,
  ...props
}: {
  token: string;
  isPublic?: boolean;
} & Pick<
  Parameters<typeof useUploadManagerBase>[0],
  'queue' | 'onUploadReady' | 'uploadConcurrency'
>) {
  return useUploadManagerBase<T>({
    onPresignedPost: React.useCallback(
      ({ fileType, fileName }) => {
        return apiFetch(
          isPublic === true
            ? `/api/v2/media/presigned-post-public-asset`
            : `/api/v2/media/presigned-post`,
          {
            token,
            method: 'POST',
            body: JSON.stringify({ fileType, fileName }),
          }
        );
      },
      [isPublic, token]
    ),

    ...props,
  });
}

export type IUpload<T> = Readonly<{
  clientId: string;
  axiosCancelSource: CancelTokenSource;
  file: File;
  fileName: string;
  mediaUid?: string;
  finalUrl?: string;
  mediaCreationFulfilled: boolean;

  customData?: T;

  fileSize?: number;
  sizeUploaded?: number;

  preSignedUrlPromise?: Promise<unknown>;
  uploadPromise?: Promise<unknown>;
  mediaCreationPromise?: Promise<unknown>;
}>;

export type IOnUploadReady<T> = (p: {
  fileName: string;
  mediaUid?: string;
  finalUrl?: string;
  file: File;
  isVideo: boolean;
  remove: () => void;
  customData?: T;
}) => void;

type State<T> = Readonly<{
  queue: ZQueue;
  readonly uploads: {
    [k: string]: IUpload<T> | undefined;
  };
  uploadIds: string[];
}>;

export function useUploadManagerBase<T = null>({
  uploadConcurrency = 3,
  onPresignedPost,
  onUploadReady,
  queue,
}: {
  uploadConcurrency?: number;
  onUploadReady: IOnUploadReady<T>;
  onPresignedPost: (p: {
    fileType: string;
    fileName?: string;
  }) => Promise<{ signedUrl: string; mediaUid: string; finalUrl?: string }>;
  queue?: ZQueue;
}) {
  const [{ uploads, uploadIds }, setState] = React.useState<State<T>>(() => ({
    queue: queue ?? new ZQueue({ max: uploadConcurrency }),
    uploadIds: [],
    uploads: {},
  }));

  const onPresignedPostRef = React.useRef(onPresignedPost);
  React.useEffect(() => {
    onPresignedPostRef.current = onPresignedPost;
  });

  const onUploadReadyRef = React.useRef(onUploadReady);
  React.useEffect(() => {
    onUploadReadyRef.current = onUploadReady;
  });

  const createUpload = React.useCallback(
    ({
      state,
      clientId,
      file,
      onUploadReadyCustom,
      customData,
    }: {
      state: State<T>;
      clientId: string;
      file: File;
      onUploadReadyCustom?: IOnUploadReady<T>;
      customData?: T;
    }) => {
      const axiosCancelSource = axios.CancelToken.source();

      return {
        clientId,
        file,
        customData,

        axiosCancelSource,
        fileName: file.name,

        finishedUpload: false,

        mediaCreationFulfilled: false,

        onRestart: () =>
          setState((state) => ({
            ...state,
            uploads: {
              ...state.uploads,
              [clientId]: createUpload({ state, clientId, file }),
            },
          })),

        poolerPromise: state.queue.run(() => {
          const preSignedUrlPromise = onPresignedPostRef.current({
            fileType: file.type,
            fileName: file.name,
          });

          const uploadPromise = preSignedUrlPromise.then(
            async ({ signedUrl, mediaUid, finalUrl }) => {
              setState((state) => {
                const upload = state.uploads[clientId];

                return {
                  ...state,
                  uploads: {
                    ...state.uploads,
                    [clientId]: upload && {
                      ...upload,
                      mediaUid,
                      finalUrl,
                    },
                  },
                };
              });

              return axios
                .put(signedUrl, file, {
                  onUploadProgress: (progressEvent) => {
                    setState((state) => {
                      const upload = state.uploads[clientId];

                      return {
                        ...state,
                        uploads: {
                          ...state.uploads,
                          [clientId]: upload && {
                            ...upload,
                            fileSize: progressEvent.total,
                            sizeUploaded: progressEvent.loaded,
                          },
                        },
                      };
                    });
                  },
                  cancelToken: axiosCancelSource.token,
                  headers: { 'Content-Type': file.type },
                })
                .then(() => ({ mediaUid, finalUrl }));
            }
          );

          setState((state) => {
            const upload = state.uploads[clientId];

            return {
              ...state,
              uploads: {
                ...state.uploads,
                [clientId]: upload && {
                  ...upload,

                  uploadProgress: 0,

                  preSignedUrlPromise,
                  uploadPromise,
                  mediaCreationPromise: uploadPromise
                    .then(({ mediaUid, finalUrl }) => {
                      setState((state) => {
                        const upload = state.uploads[clientId];

                        return {
                          ...state,
                          uploads: {
                            ...state.uploads,
                            [clientId]: upload && {
                              ...upload,
                              finishedUpload: true,
                            },
                          },
                        };
                      });

                      return Promise.resolve()
                        .then(() => {
                          const onUploadReady =
                            onUploadReadyCustom ?? onUploadReadyRef.current;

                          return onUploadReady({
                            fileName: file.name,

                            file,
                            customData,
                            mediaUid,
                            finalUrl,
                            isVideo: isVideo(file),
                            remove: () =>
                              setState((state) => ({
                                ...state,
                                uploadIds: state.uploadIds.filter(
                                  (id) => id !== clientId
                                ),
                                uploads: {
                                  ...state.uploads,
                                  [clientId]: undefined,
                                },
                              })),
                          });
                        })
                        .then((response) => {
                          setState((state) => {
                            const upload = state.uploads[clientId];
                            return {
                              ...state,
                              uploads: {
                                ...state.uploads,
                                [clientId]: upload && {
                                  ...upload,
                                  mediaCreationFulfilled: true,
                                },
                              },
                            };
                          });

                          return response;
                        });
                    })
                    .catch((error) => {
                      if (axios.isCancel(error)) return;
                      throw error;
                    }),
                },
              },
            };
          });

          return uploadPromise;
        }),
      };
    },
    []
  );

  const addFiles = React.useCallback(
    ({
      acceptedFiles,
      filesWithCustomData,
      filesWithId,
      onUploadReadyCustom,
    }: {
      acceptedFiles?: File[];
      filesWithId?: { file: File; clientId: string; customData?: T }[];
      filesWithCustomData?: { file: File; customData: T }[];
      onUploadReadyCustom?: IOnUploadReady<T>;
    }) => {
      filesWithId =
        filesWithId ??
        (acceptedFiles != null
          ? addIdToFiles({ files: acceptedFiles.map((file) => ({ file })) })
          : filesWithCustomData != null
          ? addIdToFiles({ files: filesWithCustomData })
          : undefined);

      setState((state) =>
        filesWithId == null
          ? state
          : filesWithId.reduce(
              (res, { clientId, file, customData }) => ({
                ...res,
                uploadIds: [...res.uploadIds, clientId],
                uploads: {
                  ...res.uploads,
                  [clientId]: createUpload({
                    state,
                    clientId,
                    file,
                    onUploadReadyCustom,
                    customData,
                  }),
                },
              }),
              {
                ...state,
                uploadIds: state.uploadIds,
                uploads: state.uploads,
              }
            )
      );
    },
    [createUpload]
  );

  return {
    addFiles,
    uploads,
    uploadIds,
    uploadsList: React.useMemo(
      () =>
        uploadIds
          .map((clientId) => uploads[clientId])
          .filter((u) => u != null) as IUpload<T>[],
      [uploadIds, uploads]
    ),
  };
}

export function ProgressIndicator({ borderRadius = 50, progress }) {
  return (
    <div
      style={{
        height: 10,
        backgroundColor: 'white',
        alignItems: 'flex-start',
        borderRadius,
        marginLeft: 16,
        marginRight: 16,
        flex: 1,
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          backgroundColor: COLOR_SNAPPR_BLUE,
          height: '100%',
          width: `${progress * 100}%`,
          borderRadius,
        }}
      />
    </div>
  );
}

export const UploadImagePreview: React.FC<
  {
    file: File;
  } & React.ImgHTMLAttributes<HTMLImageElement>
> = ({ file, alt, ...imgProps }) => {
  const [src, setSrc] = React.useState<string | undefined>();

  React.useEffect(() => {
    const imageSrc = window.URL.createObjectURL(file);
    setSrc(imageSrc);
    return () => window.URL.revokeObjectURL(imageSrc);
  }, [file]);

  return <img {...imgProps} src={src} alt={alt} />;
};

export function FileSelectorButton({
  onFilesChange,
  accept = 'image/jpeg, image/png',
  singleFile = false,
  disabled = false,
  ...buttonProps
}: {
  onFilesChange: (p: { files?: FileList }) => void;
  accept?: string;
  singleFile?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>) {
  const inputRef = React.useRef<HTMLInputElement>(null);

  return (
    <>
      <input
        ref={inputRef}
        type="file"
        multiple={!singleFile}
        accept={accept}
        style={{ display: 'none' }}
        onChange={(ev) => {
          onFilesChange({ files: ev.target.files ?? undefined });

          // reset the file input so that if the user re selects the same files
          // onChange is triggered
          inputRef.current && (inputRef.current.value = '');
        }}
      />

      <button
        disabled={disabled}
        onClick={() => (inputRef.current as any).click()}
        type="button"
        {...buttonProps}
      />
    </>
  );
}
