import React from 'react';
import { Promiser } from 'react-prometo';

export class ErrorProcessor extends React.Component {
  state = { errorMessagePromise: null };

  componentDidMount() {
    this.processError(this.props);
  }

  componentDidUpdate(prevProps) {
    if (this.props.error !== prevProps.error) {
      this.processError(this.props);
    }
  }

  processError = ({ error }) => {
    this.setState({
      errorMessagePromise:
        error == null
          ? null
          : error
              .json()
              .then(({ errors }) => errors.join(';'))
              .catch((err) => {
                console.error(err);
                return 'Unknown error.';
              }),
    });
  };

  render() {
    return (
      <Promiser promise={this.state.errorMessagePromise}>
        {(props) => this.props.children(props)}
      </Promiser>
    );
  }
}
