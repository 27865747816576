import React from 'react';
import { gql, useMutation } from './ApolloClient';

import { Modal } from './Modal';
import { usePromise } from './usePromise';

export const ArchiveGuidelineModal = ({
  onDismiss,
  onContinue,
  modalParams,
}: {
  onDismiss: () => void;
  onContinue: () => void;
  modalParams: {
    guidelineId: string;
  };
}) => {
  const guidelineArchivePromise = usePromise();

  const [partnerGuidelineArchive] = useMutation<
    { partnerGuidelineArchive: { success: boolean } },
    {
      guidelineId: string;
    }
  >(
    gql`
      mutation PartnerGuidelineArchiveFromListAdmin($guidelineId: ID!) {
        partnerGuidelineArchive(input: { guidelineId: $guidelineId }) {
          success
        }
      }
    `
  );

  return (
    <Modal onDismiss={onDismiss}>
      <div className="card my-4" style={{ maxWidth: '450px' }}>
        <div className="card-header">
          <h4 className="mb-0">Archive Guideline</h4>
        </div>

        <div className="card-body">
          <div className="col p-0 mb-4">
            <p className="mb-1">
              Are you sure you want to archive this guideline?
            </p>
            <small className="mb-4 text-danger">
              *You'll need to create a product request to unarchive it
            </small>
          </div>

          <div className="row">
            <div className="col">
              <button
                type="button"
                className="btn btn-outline-secondary btn-block"
                onClick={onDismiss}
              >
                No
              </button>
            </div>

            <div className="col">
              <button
                type="button"
                className="btn btn-primary btn-block"
                disabled={guidelineArchivePromise.isPending}
                onClick={() =>
                  guidelineArchivePromise.setPromise(
                    partnerGuidelineArchive({
                      variables: {
                        guidelineId: modalParams.guidelineId,
                      },
                    }).then(() => onContinue())
                  )
                }
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
