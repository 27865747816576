import { GOOGLE_MAPS_API_KEY } from '../config';
import { useScript } from './useScript';

export type IMaps = typeof google.maps;
export type IMap = google.maps.Map;

export function useGoogleMapsLoader(props: { googleMapsApiKey?: string } = {}) {
  const [mapsHasLoaded] = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${
      props.googleMapsApiKey ?? GOOGLE_MAPS_API_KEY
    }&libraries=places&language=en`
  );

  return {
    maps:
      mapsHasLoaded === true
        ? ((window as any).google?.maps as IMaps)
        : undefined,
  };
}
