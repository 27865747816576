import React, { useState } from 'react';
import { LoadingSpinnerCentered } from '../../../../../components/LoadingSpinner';
import { Modal } from '../../../../../components/Modal';
import { formatMoneyCents } from '../../../../../utils';
import { gql, useMutation } from '@apollo/client';

export function RefundEnterpriseTransaction(props) {
  const [isPartial, setIsPartial] = useState(false);

  const {
    onDismiss,
    booking,
    modalParams: { modalParams },
    refetchTransactions,
  } = props;

  const [transactionValue, setTransactionValue] = useState<
    number | undefined
  >();
  const [refundToken, setRefundToken] = useState<string | undefined>();
  const [refundAmount, setRefundAmount] = useState<number | undefined>();
  const [transactionError, setTransactionError] = useState<
    string | undefined
  >();

  const currentTransactionId = modalParams?.transactionId;

  //Validate Mutation
  const [
    bookingTransactionRefundValidate,
    bookingTransactionRefundValidateMutation,
  ] = useMutation<
    {
      bookingTransactionRefundValidate: {
        token: string;
        amount: number;
      };
    },
    { bookingId: string; transactionId: string; amount?: number }
  >(
    gql`
      mutation BookingTransactionRefundValidateFromAdmin(
        $bookingId: ID!
        $transactionId: ID!
        $amount: Int
      ) {
        bookingTransactionRefundValidate(
          input: {
            bookingId: $bookingId
            transactionId: $transactionId
            amount: $amount
          }
        ) {
          token
          amount
        }
      }
    `
  );

  //commit Mutation
  const [
    bookingTransactionRefundCommit,
    bookingTransactionRefundCommitMutation,
  ] = useMutation<
    {
      bookingTransactionRefundCommit: {
        transactionId: string;
      };
    },
    { bookingId: string; transactionId: string; amount?: number; token: string }
  >(
    gql`
      mutation bookingTransactionRefundCommitFromAdmin(
        $bookingId: ID!
        $transactionId: ID!
        $amount: Int
        $token: String!
      ) {
        bookingTransactionRefundCommit(
          input: {
            bookingId: $bookingId
            transactionId: $transactionId
            amount: $amount
            token: $token
          }
        ) {
          transactionId
        }
      }
    `
  );

  React.useEffect(() => {
    if (currentTransactionId != null) {
      bookingTransactionRefundValidate({
        variables: {
          bookingId: booking.uid,
          transactionId: currentTransactionId,
        },
      })
        .then((result) => {
          setTransactionValue(
            result?.data?.bookingTransactionRefundValidate.amount
          );
          setRefundAmount(
            result?.data?.bookingTransactionRefundValidate.amount
          );
          setRefundToken(result?.data?.bookingTransactionRefundValidate.token);
        })
        .catch((err) => {
          setTransactionError(err.message);
        });
    }
  }, [booking.uid, bookingTransactionRefundValidate, currentTransactionId]);

  return (
    <Modal onDismiss={onDismiss}>
      <div className="card my-4">
        <div className="card-header">
          <h4 className="mb-0">Refund Transaction</h4>
        </div>

        <div
          className="card-body d-flex flex-column"
          style={{ width: '1000px', minHeight: '300px' }}
        >
          <div className="flex-1" style={{ position: 'relative' }}>
            {transactionError != null ? (
              <div className="alert alert-danger" role="alert">
                <p>We can not refund this transaction.</p>
                {transactionError}
              </div>
            ) : null}
            {refundAmount != null && refundToken != null ? (
              <div className="card mb-3">
                <div className="card-body">
                  <h4>Transaction Details</h4>
                  <div>
                    {formatMoneyCents(refundAmount, {
                      currency: booking.currency,
                    })}
                  </div>
                  <div>
                    <span>Refund amount:</span>
                    <div className="form-check form-check-inline ml-2">
                      <label className="form-check-label">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="refundAmountOptions"
                          checked={!isPartial}
                          onChange={() => {
                            setIsPartial(false);
                            setRefundAmount(transactionValue);
                          }}
                        />
                        Full
                      </label>
                    </div>

                    <div className="form-check form-check-inline">
                      <label className="form-check-label">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="refundAmountOptions"
                          checked={isPartial}
                          onChange={() => {
                            setIsPartial(true);
                            setRefundAmount(0);
                          }}
                        />
                        Partial
                      </label>
                    </div>

                    <span>{'$ '}</span>
                    <input
                      type="number"
                      min="1"
                      step="any"
                      className="form-control d-inline-block"
                      placeholder="Amount"
                      style={{ width: 200 }}
                      disabled={!isPartial}
                      value={
                        isPartial === true ? refundAmount : transactionValue
                      }
                      onFocus={(ev) => ev.target.select()}
                      onChange={async ({ target: { value } }) => {
                        setTransactionError(undefined);
                        bookingTransactionRefundValidate({
                          variables: {
                            bookingId: booking.uid,
                            transactionId: currentTransactionId,
                            amount: parseFloat(value) || 0,
                          },
                        })
                          .then((result) => {
                            setRefundAmount(
                              result?.data?.bookingTransactionRefundValidate
                                .amount
                            );
                            setRefundToken(
                              result?.data?.bookingTransactionRefundValidate
                                .token
                            );
                          })
                          .catch((err) => {
                            setTransactionError(err.message);
                          });
                      }}
                      onBlur={({ target: { value } }) => {
                        const parsedValue = parseFloat(value) || 0;
                        if (parsedValue < 0) {
                          alert('Refund amount can not be less than 0.');
                          setRefundAmount(0);
                          return;
                        }

                        setRefundAmount(parseFloat(value) || 0);
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : transactionError == null ? (
              <LoadingSpinnerCentered />
            ) : null}
          </div>

          <div className="d-flex justify-content-between mt-3">
            <button className="btn btn-secondary mr-2" onClick={onDismiss}>
              Close
            </button>
            <button
              className="btn btn-primary"
              disabled={
                refundToken == null ||
                refundAmount == null ||
                bookingTransactionRefundCommitMutation.loading
              }
              onClick={() => {
                if (refundAmount == null || refundToken == null) return;

                alert(
                  `Are you sure you want to refund this ${formatMoneyCents(
                    refundAmount,
                    {
                      currency: booking.currency,
                    }
                  )} of ${formatMoneyCents(transactionValue, {
                    currency: booking.currency,
                  })}?`
                );

                bookingTransactionRefundCommit({
                  variables: {
                    bookingId: booking.uid,
                    transactionId: currentTransactionId,
                    amount: isPartial ? refundAmount : undefined,
                    token: refundToken,
                  },
                })
                  .then((res) => {
                    refetchTransactions();
                    onDismiss();
                  })
                  .catch((err) => {
                    setTransactionError(err.message);
                  });
              }}
            >
              {bookingTransactionRefundCommitMutation.loading === true
                ? 'Refunding...'
                : 'Refund'}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
