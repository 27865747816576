import React from 'react';

export const genPageNumbers = ({
  limit,
  count,
  offset = 0,
  maxPageCount = 10,
}) => {
  const pageCount = Math.min(Math.ceil(count / limit), maxPageCount);
  const currentPage = Math.floor(offset / limit) + 1;
  const lowPage =
    currentPage - Math.floor(pageCount / 2) < 1
      ? 1
      : currentPage + Math.floor(pageCount / 2) <= Math.ceil(count / limit)
      ? currentPage - Math.floor(pageCount / 2)
      : Math.ceil(count / limit) - pageCount + 1;

  return Array.from(Array(pageCount)).map((val, index) => ({
    current: currentPage === index + lowPage,
    page: index + lowPage,
  }));
};

export const Pagination = ({
  navProps = {},
  limit,
  offset,
  count,
  linkForOffset,
  hidePageNumbers = false,
}) =>
  (count > limit && (
    <nav {...navProps} className="border">
      <ul className="shadow-soft pagination mb-0">
        <li className={`page-item ${offset - limit < 0 ? 'disabled' : ''}`}>
          {linkForOffset(offset - limit, {
            children: 'Previous',
            disabled: offset - limit < 0,
          })}
        </li>
        {!hidePageNumbers &&
          genPageNumbers({
            limit,
            count,
            offset,
          }).map(({ current, page }) => (
            <li className={`page-item ${current ? 'active' : ''}`} key={page}>
              {linkForOffset((page - 1) * limit, {
                children: page,
              })}
            </li>
          ))}
        <li
          className={`page-item ${offset + limit >= count ? 'disabled' : ''}`}
        >
          {linkForOffset(offset + limit, {
            children: 'Next',
            disabled: offset + limit >= count,
          })}
        </li>
      </ul>
    </nav>
  )) ||
  null;
