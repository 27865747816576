import styled from 'styled-components';

export const BaseStyle = styled.div`
  /* .text-align-center {
    text-align: center;
  }
  .text-align-right {
    text-align: right;
  }
  .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    @media (min-width: 576px) {
      max-width: 540px;
    }
    @media (min-width: 768px) {
      max-width: 720px;
    }
    @media (min-width: 992px) {
      max-width: 1024px;
    }
  }
  .row {
    display: flex;
    margin-right: -15px;
    margin-left: -15px;
  }
  .table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
    border-collapse: collapse;
    th,
    td {
      padding: 0.75rem;
      vertical-align: top;
      border-top: 1px solid #dee2e6;
    }
  }

  .m-0 {
    margin: 0;
  }

  &.d-flex,
  .d-flex {
    display: flex;
  }
  &.flex-column,
  .flex-column {
    flex-direction: column;
  }
  .justify-content-center {
    justify-content: center;
  }
  .justify-content-between {
    justify-content: space-between;
  }
  &.flex-1,
  .flex-1 {
    flex: 1;
  }

  .align-items-center {
    align-items: center;
  } */
`;
