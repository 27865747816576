import React, { Fragment, useState } from 'react';
import qs from 'query-string';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';

import ContentWrapper from '../../components/ContentWrapper';
import { formatCredits, formatMoneyCents } from '../../utils';

import { SearchField } from '../partners/components';

import { OrderByLink } from '../../components/OrderByLink';
import { locationForModal, ModalRoute } from '../../components/ModalRoute';
import { Modal } from '../../components/Modal';

import { gql, useMutation, useQuery } from '../../components/ApolloClient';
import { EnterpriseGuidelineModal } from '../../components/EnterpriseGuidelineModal';
import { API_URL, APP_URL, ENABLE_PARTNER_PILOT_MODE } from '../../config';
import checkSvgIcon from '../../styles/img/check.svg';
import { IFetcher, useFetch } from '../../components/useFetch';
import { ActionsDropdown } from '../../components/ActionsDropdown';
import {
  OnEnterpriseInvoiceCancelModal,
  OnEnterpriseInvoiceSetToPaidModal,
  OnEnterpriseInvoiceSetToPendingModal,
} from './invoices';
import * as H from 'history';
import { GodModeLink } from './details';
import Select from 'react-select';
import { ApolloQueryResult, QueryResult } from '@apollo/client';

const PARTNERS_PER_PAGE = 499;

interface Enterprise {
  credits: number;
  uid: string;
  name: string;
  shoot_guide_url?: string;
  end_customer_shoot_guide_url?: string;
  default_shoottype_display_name?: string;
  strict_mode?: boolean;
  value_pricing?: boolean;
  preapproved_providers?: boolean;
  account_manager_name?: string;
  users_count?: number;
  bookings_past_30_count?: number;
  bookings_next_30_count?: number;
  last_recharged_at?: string;
  remaining_days?: number;
  expired_contract?: boolean;
  is_referrals_enabled: boolean;
}

interface EnterpriseListProps {
  enterpriseList: IFetcher<{
    results: Enterprise[];
  }>;
  location: H.Location<H.LocationState>;
  history: H.History<H.LocationState>;
  session: {
    token: string;
    uid: string;
    expire: string;
  };
}

interface OverdueInvoicesProps {
  overdueInvoicesList: QueryResult<{
    getAdminEnterpriseOverdueInvoices: {
      edges: Invoice[];
    };
  }>;
  location: H.Location<H.LocationState>;
  history: H.History<H.LocationState>;
  session: {
    token: string;
  };
  reFetchOverdueInvoices: (
    variables?:
      | {
          [key: string]: any;
        }
      | undefined
  ) => Promise<
    ApolloQueryResult<{
      getAdminEnterpriseOverdueInvoices: {
        edges: Invoice[];
      };
    }>
  >;
}
interface PendingInvoicesProps {
  pendingInvoicesList: QueryResult<{
    getAdminEnterprisePendingInvoices: {
      edges: Invoice[];
    };
  }>;
  location: H.Location<H.LocationState>;
  history: H.History<H.LocationState>;
  session: {
    token: string;
  };
  reFetchPendingInvoices: (
    variables?:
      | {
          [key: string]: any;
        }
      | undefined
  ) => Promise<
    ApolloQueryResult<{
      getAdminEnterprisePendingInvoices: {
        edges: Invoice[];
      };
    }>
  >;
}

interface Invoice {
  id: string;
  invoiceId: string;
  dateIssued: string;
  dueDate: string;
  managerFullName: string;
  credits: number;
  amount: number;
  currency: string;
  status: string;
  datePaid?: string;
  pdfUrl?: string;
  partner: {
    uid: string;
    name: string;
  };
}

export enum EnterpriseModalsEnum {
  CREATE_ENTERPRISE = 'CREATE_ENTERPRISE',
  CREATE_GUIDELINE = 'CREATE_GUIDELINE',
  CREATE_USER = 'CREATE_USER',
  CREATE_PRIMARY_BILLING_USER = 'CREATE_PRIMARY_BILLING_USER',
  SUCCESS = 'SUCCESS',
}

const MODAL_NAME_ENTERPRISE_INVOICE_SET_TO_PENDING_MODAL =
  'modal_name_enterprise_invoice_set_to_pending_modal';

const MODAL_NAME_ENTERPRISE_INVOICE_CANCEL_MODAL =
  'modal_name_enterprise_invoice_cancel_modal';

const MODAL_NAME_ENTERPRISE_INVOICE_SET_TO_PAID_MODAL =
  'modal_name_enterprise_invoice_set_to_paid_modal';

const StyledTd = ({
  title,
  children,
  textAlign = 'text-left',
}: {
  title?: string;
  textAlign?: 'text-right' | 'text-left';
  children;
}) => (
  <td
    title={title}
    style={{ verticalAlign: 'middle' }}
    className={`${textAlign}`}
  >
    <span className="text-secondary d-inline-block text-truncate" />
    {children}
  </td>
);

const TabCode = {
  ENTERPRISE_LIST: 'ENTERPRISE_LIST',
  OVERDUE_INVOICES: 'OVERDUE_INVOICES',
  PENDING_INVOICES: 'PENDING_INVOICES',
};

function NewUserModal({
  partnerId,
  onDismiss,
  onContinue,
}: {
  partnerId: string;
  onDismiss: () => void;
  onContinue: ({
    primaryBillingContactSelected,
  }: {
    primaryBillingContactSelected: boolean;
  }) => void;
}) {
  const [firstName, setFirstName] = React.useState<string>();
  const [lastName, setLastName] = React.useState<string>();
  const [title, setTitle] = React.useState<string>();
  const [email, setEmail] = React.useState<string>();
  const [
    isPrimaryBillingContact,
    setIsPrimaryBillingContact,
  ] = React.useState<boolean>(false);

  const [createNewUser, createNewUserMutation] = useMutation(gql`
    mutation CreateNewPartnerUser(
      $partnerId: UUID!
      $firstName: String!
      $lastName: String!
      $email: String!
      $role: PartnerUserRoles!
      $title: String
      $isUserInChargeOfPayments: Boolean
    ) {
      createNewPartnerUser(
        input: {
          partnerId: $partnerId
          firstName: $firstName
          lastName: $lastName
          email: $email
          role: $role
          title: $title
          isUserInChargeOfPayments: $isUserInChargeOfPayments
        }
      ) {
        user {
          uid
        }
      }
    }
  `);

  const disableContinueButton =
    firstName == null || lastName == null || title == null || email == null;

  return (
    <Modal onDismiss={onDismiss}>
      <form
        className="modal-body"
        onSubmit={(e) => {
          e.preventDefault();

          return createNewUser({
            variables: {
              partnerId,
              firstName,
              lastName,
              email,
              role: 'ADMIN',
              title,
              isUserInChargeOfPayments: isPrimaryBillingContact,
            },
          }).then(() =>
            onContinue({
              primaryBillingContactSelected: isPrimaryBillingContact,
            })
          );
        }}
      >
        <div className="p-4 card" style={{ minWidth: 712 }}>
          <h2>Create first enterprise admin user</h2>

          {createNewUserMutation.loading === false &&
            createNewUserMutation.error != null && (
              <div>
                {createNewUserMutation.error.graphQLErrors
                  .map(
                    (graphQLError) =>
                      ({
                        BELONGS_TO_PARTNER:
                          'User already linked to the account',
                        BELONGS_TO_OTHER_PARTNER:
                          'User already linked to other enterprise account',
                      }[(graphQLError.extensions as any)?.exception.code])
                  )
                  .filter((s) => s != null)}
              </div>
            )}

          <div className="row">
            <div className="col">
              <label>First name</label>
              <input
                required
                disabled={createNewUserMutation.loading}
                name="firstName"
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName ?? ''}
                type="text"
                className="form-control"
                placeholder="e.g. Mary"
              />
            </div>

            <div className="col">
              <label>Last name</label>
              <input
                required
                disabled={createNewUserMutation.loading}
                name="lastName"
                onChange={(e) => setLastName(e.target.value)}
                value={lastName ?? ''}
                type="text"
                className="form-control"
                placeholder="e.g. Simpson"
              />
            </div>
          </div>

          <div className="p-2" />

          <div className="row">
            <div className="col">
              <label>Email</label>
              <input
                required
                disabled={createNewUserMutation.loading}
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email ?? ''}
                type="text"
                className="form-control"
                placeholder="e.g. julian@snappr.com"
              />
            </div>

            <div className="col">
              <label>Title</label>
              <input
                required
                disabled={createNewUserMutation.loading}
                name="title"
                onChange={(e) => setTitle(e.target.value)}
                value={title ?? ''}
                type="text"
                className="form-control"
                placeholder="e.g. CEO"
              />
            </div>
          </div>

          <div
            style={{ display: 'flex', alignItems: 'center', paddingTop: 24 }}
          >
            <input
              type="checkbox"
              checked={isPrimaryBillingContact}
              onChange={(e) =>
                setIsPrimaryBillingContact(e.target.checked === true)
              }
            />
            <p style={{ margin: 0, paddingLeft: 8, color: '#454F5C' }}>
              This user is the primary billing contact
            </p>
          </div>

          <div className="p-3" />

          <button
            className="btn btn-dark btn-block"
            type="submit"
            disabled={createNewUserMutation.loading || disableContinueButton}
          >
            Create and notify user
          </button>
        </div>
      </form>
    </Modal>
  );
}

function CreatePrimaryBillingUserModal({
  partnerId,
  onContinue,
}: {
  partnerId: string;
  onContinue: () => void;
}) {
  const [firstName, setFirstName] = React.useState<string>();
  const [lastName, setLastName] = React.useState<string>();
  const [title, setTitle] = React.useState<string>();
  const [email, setEmail] = React.useState<string>();

  const [createNewUser, createNewUserMutation] = useMutation(gql`
    mutation CreateNewPartnerUser_LADY_PLEASE_FIX(
      $partnerId: UUID!
      $firstName: String!
      $lastName: String!
      $email: String!
      $role: PartnerUserRoles!
      $title: String
      $isUserInChargeOfPayments: Boolean
    ) {
      createNewPartnerUser(
        input: {
          partnerId: $partnerId
          firstName: $firstName
          lastName: $lastName
          email: $email
          role: $role
          title: $title
          isUserInChargeOfPayments: $isUserInChargeOfPayments
        }
      ) {
        user {
          uid
        }
      }
    }
  `);

  const [userRole, setUserRole] = useState<string | undefined>(undefined);

  const disableContinueButton =
    firstName == null ||
    lastName == null ||
    title == null ||
    email == null ||
    userRole == null;

  return (
    <Modal>
      <form
        className="modal-body"
        onSubmit={(e) => {
          e.preventDefault();

          return createNewUser({
            variables: {
              partnerId,
              firstName,
              lastName,
              email,
              role: userRole?.toUpperCase(),
              title,
              isUserInChargeOfPayments: true,
            },
          }).then(onContinue);
        }}
      >
        <div className="p-4 card" style={{ minWidth: 712 }}>
          <h2>Create a primary billing contact</h2>

          {createNewUserMutation.loading === false &&
            createNewUserMutation.error != null && (
              <div>
                {createNewUserMutation.error.graphQLErrors
                  .map(
                    (graphQLError) =>
                      ({
                        BELONGS_TO_PARTNER:
                          'User already linked to the account',
                        BELONGS_TO_OTHER_PARTNER:
                          'User already linked to other enterprise account',
                      }[(graphQLError.extensions as any)?.exception.code])
                  )
                  .filter((s) => s != null)}
              </div>
            )}

          <div className="row">
            <div className="col">
              <label>First name</label>
              <input
                required
                disabled={createNewUserMutation.loading}
                name="firstName"
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName ?? ''}
                type="text"
                className="form-control"
                placeholder="e.g. Mary"
              />
            </div>

            <div className="col">
              <label>Last name</label>
              <input
                required
                disabled={createNewUserMutation.loading}
                name="lastName"
                onChange={(e) => setLastName(e.target.value)}
                value={lastName ?? ''}
                type="text"
                className="form-control"
                placeholder="e.g. Simpson"
              />
            </div>
          </div>

          <div className="p-2" />

          <div className="row">
            <div className="col">
              <label>Email</label>
              <input
                required
                disabled={createNewUserMutation.loading}
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email ?? ''}
                type="text"
                className="form-control"
                placeholder="e.g. julian@snappr.com"
              />
            </div>

            <div className="col">
              <label>Title</label>
              <input
                required
                disabled={createNewUserMutation.loading}
                name="title"
                onChange={(e) => setTitle(e.target.value)}
                value={title ?? ''}
                type="text"
                className="form-control"
                placeholder="e.g. CEO"
              />
            </div>
          </div>

          <div className="p-2" />
          <div className="row">
            <div
              className="col"
              style={{
                display: 'flex',
                flexDirection: 'column',
                maxWidth: '50%',
              }}
            >
              <label>Role</label>
              <Select
                // @ts-ignore
                value={
                  userRole != null
                    ? [
                        {
                          label: userRole,
                          value: userRole,
                        },
                      ]
                    : null
                }
                options={[
                  {
                    label: 'Admin',
                    value: 'Admin',
                  },
                  {
                    label: 'Finance',
                    value: 'Finance',
                  },
                ]}
                onChange={(e) => {
                  setUserRole(e?.value);
                }}
              />
            </div>
          </div>

          <div className="p-3" />
          <div className="row">
            <div className="col">
              <button
                className="btn btn-outline-primary"
                onClick={onContinue}
                style={{ width: '100%' }}
              >
                Skip, I don’t know
              </button>
            </div>
            <div className="col">
              <button
                className="btn btn-dark"
                type="submit"
                style={{ width: '100%' }}
                disabled={
                  createNewUserMutation.loading || disableContinueButton
                }
              >
                Create and notify user
              </button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
}

function CreateEnterpriseModal({
  onDismiss,
  onContinue,
}: {
  onDismiss: () => void;
  onContinue: (p: { partnerId?: string }) => void;
}) {
  const [displayName, setDisplayName] = React.useState<string>();
  const [legalName, setLegalName] = React.useState<string>();
  const [slug, setSlug] = React.useState<string>();
  const [shootType, setShootType] = React.useState<string>();
  const [regionId, setRegionId] = React.useState<string>();
  const [accountManagerId, setAccountManagerId] = React.useState<string>();
  const [shootExtraDetails, setShootExtraDetails] = React.useState<string>();
  const [strictMode, setStrictMode] = React.useState<boolean>(true);
  const [centralEditing, setCentralEditing] = React.useState<boolean>(true);
  const [referralsEnabled, setReferralsEnabled] = React.useState<boolean>(
    false
  );
  const [
    shortNoticeBookingsEnabled,
    setShortNoticeBookingsEnabled,
  ] = React.useState<boolean>(false);
  const [editingJobsEnabled, setEditingJobsEnabled] = React.useState<boolean>(
    false
  );
  const [
    rescheduleFeesWarningEnabled,
    setRescheduleFeesWarningEnabled,
  ] = React.useState<boolean>(false);

  const [
    editingJobFreeCreditsEnabled,
    setEditingJobFreeCreditsEnabled,
  ] = React.useState<boolean>(false);

  const [partnerLevel, setPartnerLevel] = React.useState<
    'bronze' | 'silver' | 'gold' | undefined
  >(undefined);

  const [businessType, setBusinessType] = React.useState<
    'marketplace' | 'e_commerce' | 'other' | undefined
  >(undefined);

  const [pilotModeEnabled, setPilotModeEnabled] = React.useState<boolean>(
    false
  );
  const [
    manualPhotographerSelectionEnabled,
    setManualPhotographerSelectionEnabled,
  ] = React.useState<boolean | undefined>(true);

  const [
    enforceEditingQAEnabled,
    setEnforceEditingQAEnabled,
  ] = React.useState<boolean>(false);

  const optionsQuery = useQuery<{
    getPublicShootTypes: {
      displayName: string;
      category: string;
    }[];

    regionListAsAdmin: {
      edges: {
        uid: string;
        name: string;
      }[];
    };

    accountManagerListAsAdmin: {
      edges: {
        id: string;
        fullName: string;
      }[];
    };
  }>(gql`
    query GetPublicShootTypesQueryInEnterprises {
      getPublicShootTypes {
        category
        displayName
      }

      regionListAsAdmin {
        edges {
          uid
          name
        }
      }

      accountManagerListAsAdmin {
        edges {
          id
          fullName
          email
        }
      }
    }
  `);

  const [
    commitCreatePartnerAsAdmin,
    commitCreatePartnerAsAdminMutation,
  ] = useMutation<{
    partnerCreateAsAdmin: {
      partner: {
        uid: string;
      };
    };
  }>(
    gql`
      mutation PartnerCreateAsAdmin(
        $displayName: String!
        $legalName: String!
        $slug: String!
        $shootType: String!
        $regionId: ID!
        $accountManagerId: ID!
        $shootExtraDetails: String!
        $strictMode: Boolean!
        $centralEditing: Boolean!
        $referralsEnabled: Boolean!
        $editingJobsEnabled: Boolean!
        $editingJobFreeCreditsEnabled: Boolean!
        $cancelAndReschedulingOptionsEnabled: Boolean!
        $shortNoticeBookingsEnabled: Boolean!
        $level: String!
        $businessType: String!
        $pilotModeEnabled: Boolean!
        $manualPhotographerSelectionEnabled: Boolean!
        $rescheduleFeesWarningEnabled: Boolean!
        $enforceEditingQAEnabled: Boolean!
      ) {
        partnerCreateAsAdmin(
          input: {
            displayName: $displayName
            legalName: $legalName
            slug: $slug
            shootType: $shootType
            regionId: $regionId
            accountManagerId: $accountManagerId
            shootExtraDetails: $shootExtraDetails
            strictMode: $strictMode
            centralEditing: $centralEditing
            referralsEnabled: $referralsEnabled
            editingJobsEnabled: $editingJobsEnabled
            editingJobFreeCreditsEnabled: $editingJobFreeCreditsEnabled
            cancelAndReschedulingOptionsEnabled: $cancelAndReschedulingOptionsEnabled
            shortNoticeBookingsEnabled: $shortNoticeBookingsEnabled
            level: $level
            businessType: $businessType
            pilotModeEnabled: $pilotModeEnabled
            manualPhotographerSelectionEnabled: $manualPhotographerSelectionEnabled
            rescheduleFeesWarningEnabled: $rescheduleFeesWarningEnabled
            enforceEditingQAEnabled: $enforceEditingQAEnabled
          }
        ) {
          partner {
            uid
          }
        }
      }
    `
  );

  const disableButton =
    displayName == null ||
    legalName == null ||
    slug == null ||
    shootType == null ||
    regionId == null ||
    accountManagerId == null ||
    shootExtraDetails == null ||
    partnerLevel == null ||
    businessType == null;

  return (
    <Modal onDismiss={onDismiss}>
      <div className="p-4 card" style={{ minWidth: 712 }}>
        <div>
          <h2>Create enterprise account</h2>

          <form
            onSubmit={(e) => {
              e.preventDefault();

              commitCreatePartnerAsAdmin({
                variables: {
                  displayName,
                  legalName,
                  slug,
                  shootType,
                  regionId,
                  accountManagerId,
                  shootExtraDetails,
                  strictMode,
                  centralEditing,
                  referralsEnabled,
                  editingJobFreeCreditsEnabled,
                  editingJobsEnabled,
                  cancelAndReschedulingOptionsEnabled: false,
                  shortNoticeBookingsEnabled,
                  level: partnerLevel,
                  businessType,
                  pilotModeEnabled,
                  manualPhotographerSelectionEnabled,
                  rescheduleFeesWarningEnabled,
                  enforceEditingQAEnabled,
                },
              }).then((result) =>
                onContinue({
                  partnerId: result.data?.partnerCreateAsAdmin.partner.uid,
                })
              );
            }}
          >
            <div className="row">
              <div className="col">
                <label>Display name</label>
                <input
                  required
                  value={displayName ?? ''}
                  onChange={(e) => setDisplayName(e.target.value)}
                  type="text"
                  className="form-control"
                  placeholder="e.g. Acme"
                />
              </div>

              <div className="col">
                <label>Legal name</label>
                <input
                  required
                  value={legalName ?? ''}
                  onChange={(e) => setLegalName(e.target.value)}
                  type="text"
                  className="form-control"
                  placeholder="e.g. Acme inc"
                />
              </div>
            </div>

            <div className="p-2"></div>

            <div className="row">
              <div className="col">
                <label>URL slug (Unique)</label>
                <input
                  required
                  value={slug ?? ''}
                  onChange={(e) => setSlug(e.target.value.toLowerCase())}
                  type="text"
                  className="form-control"
                  placeholder="e.g. acme"
                />
              </div>

              <div className="col">
                <label>Default shoottype</label>

                <select
                  required
                  className="form-control d-inline"
                  value={shootType ?? ''}
                  onChange={(e) => setShootType(e.target.value)}
                >
                  <option value="">Select shoot type</option>
                  {(optionsQuery.data?.getPublicShootTypes ?? []).map(
                    (shootType) => (
                      <option
                        key={shootType.category}
                        value={shootType.category}
                      >
                        {shootType.displayName}
                      </option>
                    )
                  )}
                </select>
              </div>
            </div>

            <div className="p-2"></div>

            <div className="row">
              <div className="col">
                <label>Region</label>
                <select
                  required
                  value={regionId}
                  onChange={(e) => setRegionId(e.target.value)}
                  className="form-control d-inline"
                >
                  <option value="">Select region</option>
                  {(optionsQuery.data?.regionListAsAdmin.edges ?? []).map(
                    (region) => (
                      <option key={region.uid} value={region.uid}>
                        {region.name}
                      </option>
                    )
                  )}
                </select>
              </div>

              <div className="col">
                <label>Snappr account manager</label>
                <select
                  required
                  value={accountManagerId}
                  onChange={(e) => setAccountManagerId(e.target.value)}
                  className="form-control d-inline"
                >
                  <option value="">Select account manager</option>
                  {(
                    optionsQuery.data?.accountManagerListAsAdmin.edges ?? []
                  ).map((accountManager) => (
                    <option key={accountManager.id} value={accountManager.id}>
                      {accountManager.fullName}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="p-2"></div>

            <div className="row">
              <div className="col-md-6">
                <label>Partner level</label>
                <select
                  className="form-control d-inline"
                  value={partnerLevel}
                  required
                  onChange={(e) => {
                    const value = e.target.value as
                      | 'bronze'
                      | 'silver'
                      | 'gold'
                      | '';
                    setPartnerLevel(value !== '' ? value : undefined);
                  }}
                >
                  <option value="">Select partner level</option>
                  <option value="bronze">Bronze</option>
                  <option value="silver">Silver</option>
                  <option value="gold">Gold</option>
                </select>
              </div>

              <div className="col">
                <label>Business type</label>

                <select
                  required
                  value={businessType}
                  onChange={(e) => {
                    const value = e.target.value as
                      | 'marketplace'
                      | 'e_commerce'
                      | 'other'
                      | '';
                    setBusinessType(value !== '' ? value : undefined);
                  }}
                  className="form-control d-inline"
                >
                  <option value="">Select Business Type</option>
                  <option value="marketplace">Marketplace</option>
                  <option value="e_commerce">E-commerce</option>
                  <option value="other">Other</option>
                </select>
              </div>
            </div>

            <div className="p-2"></div>

            <div className="form-group">
              <label>Shoot extra details</label>
              <small className="form-text text-muted mb-2">
                Artists will see this message for every shoot
              </small>

              <textarea
                required
                value={shootExtraDetails ?? ''}
                onChange={(e) => setShootExtraDetails(e.target.value)}
                placeholder="e.g. The person you will be meeting is a customer of Company X, not an employee of Company X."
                className="form-control"
                rows={5}
              />
            </div>

            <div className="form-group">
              <div className="form-check">
                <input
                  id="strict-mode"
                  className="form-check-input"
                  type="checkbox"
                  checked={strictMode}
                  onChange={(e) => setStrictMode(e.target.checked === true)}
                />
                <label htmlFor="strict-mode" className="form-check-label">
                  Only assign shoots to specialist photographers (Strict mode)
                </label>
              </div>

              <div className="form-check">
                <input
                  id="central-editing"
                  className="form-check-input"
                  type="checkbox"
                  checked={centralEditing}
                  onChange={(e) => setCentralEditing(e.target.checked === true)}
                />
                <label htmlFor="central-editing" className="form-check-label">
                  Enable central editing
                </label>
              </div>

              <div className="form-check">
                <input
                  id="referrals"
                  className="form-check-input"
                  type="checkbox"
                  checked={referralsEnabled}
                  onChange={(e) =>
                    setReferralsEnabled(e.target.checked === true)
                  }
                />
                <label htmlFor="referrals" className="form-check-label">
                  Enable referrals
                </label>
              </div>

              <div className="form-check">
                <input
                  id="editing-free-credits"
                  className="form-check-input"
                  type="checkbox"
                  checked={editingJobFreeCreditsEnabled}
                  onChange={(e) =>
                    setEditingJobFreeCreditsEnabled(e.target.checked)
                  }
                />
                <label
                  htmlFor="editing-free-credits"
                  className="form-check-label"
                >
                  Enable editing job free credits
                </label>
              </div>

              <div className="form-check">
                <input
                  id="editing-free-credits"
                  className="form-check-input"
                  type="checkbox"
                  checked={rescheduleFeesWarningEnabled}
                  onChange={(e) =>
                    setRescheduleFeesWarningEnabled(e.target.checked)
                  }
                />
                <label
                  htmlFor="editing-free-credits"
                  className="form-check-label"
                >
                  End customers pay for reschedules so show warnings on the fees{' '}
                </label>
              </div>

              <div className="form-check">
                <input
                  id="short-notice"
                  className="form-check-input"
                  type="checkbox"
                  checked={shortNoticeBookingsEnabled}
                  onChange={(e) =>
                    setShortNoticeBookingsEnabled(e.target.checked)
                  }
                />
                <label htmlFor="short-notice" className="form-check-label">
                  Enable short notice bookings
                </label>
              </div>

              {ENABLE_PARTNER_PILOT_MODE === true && (
                <>
                  <div className="row">
                    <div className="col">
                      <div className="form-check">
                        <input
                          id="pilot-mode"
                          className="form-check-input"
                          type="checkbox"
                          checked={pilotModeEnabled}
                          onChange={(e) => {
                            setPilotModeEnabled(e.target.checked);
                            e.target.checked === false &&
                              setManualPhotographerSelectionEnabled(false);
                          }}
                        />
                        <label
                          htmlFor="pilot-mode"
                          className="form-check-label"
                        >
                          Enable Pilot Mode
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col" style={{ marginLeft: 20 }}>
                      <div className="form-check">
                        <input
                          id="manual-pg-selection"
                          className="form-check-input"
                          type="checkbox"
                          checked={
                            manualPhotographerSelectionEnabled === true &&
                            pilotModeEnabled === true
                          }
                          disabled={pilotModeEnabled === false}
                          onChange={(e) =>
                            setManualPhotographerSelectionEnabled(
                              (manualPhotographerSelectionEnabled) =>
                                !manualPhotographerSelectionEnabled
                            )
                          }
                        />
                        <label
                          htmlFor="manual-pg-selection"
                          className="form-check-label"
                        >
                          Enable Snappr manual PG selection
                        </label>
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div className="form-check">
                <input
                  id="enforce-editing-qa"
                  className="form-check-input"
                  type="checkbox"
                  checked={enforceEditingQAEnabled}
                  onChange={(e) => setEnforceEditingQAEnabled(e.target.checked)}
                />
                <label
                  htmlFor="enforce-editing-qa"
                  className="form-check-label"
                >
                  Enforce Editing QA
                </label>
              </div>
            </div>

            <div className="p-2" />

            <button
              type="submit"
              disabled={
                optionsQuery.loading ||
                disableButton ||
                commitCreatePartnerAsAdminMutation.loading
              }
              className="btn btn-dark btn-block"
            >
              Continue
            </button>
          </form>
        </div>
      </div>
    </Modal>
  );
}

const EnterpriseList = ({
  enterpriseList,
  location,
  history,
  session,
}: EnterpriseListProps) => {
  const options = [
    {
      label: 'All',
      value: undefined,
    },
    {
      label: 'Ecommerce',
      value: 'true',
    },
    {
      label: 'Non Ecommerce',
      value: 'false',
    },
  ];

  const selectedValue = qs.parse(location.search).ecommercePartner ?? 'false';

  const selectedOption = options.find((o) => o.value === selectedValue);

  return (
    <>
      <div className="d-flex justify-content-start align-items-center mb-3 mt-3 ml-3">
        <SearchField
          onSearch={({ q }) =>
            history.push({
              ...location,
              search: qs.stringify({
                ...qs.parse(location.search),
                q: q || undefined,
              }),
            })
          }
          defaultValue={qs.parse(location.search).q || ''}
        />
        <div style={{ minWidth: 100, width: 200, marginLeft: 8 }}>
          <Select
            value={selectedOption}
            options={options}
            //styles={customStyles}
            placeholder="Enterprise type"
            onChange={(selectedOption) => {
              const selected = selectedOption?.value;

              history.push({
                ...location,
                search: qs.stringify({
                  ...qs.parse(location.search),
                  ['ecommercePartner']: selected,
                }),
              });
            }}
          />
        </div>
      </div>
      <div
        className="table-responsive"
        style={{
          position: 'relative',
        }}
      >
        <table className="table table-hover">
          <thead>
            <tr>
              <th className="text-muted text-truncate">
                <OrderByLink attrName="name" location={location}>
                  Name
                </OrderByLink>
              </th>
              <th className="text-muted text-truncate">
                <OrderByLink attrName="account_manager" location={location}>
                  Account manager
                </OrderByLink>
              </th>
              <th className="text-muted text-truncate">
                <OrderByLink attrName="contact_status" location={location}>
                  Contract status
                </OrderByLink>
              </th>
              <th className="text-muted text-truncate">
                <OrderByLink attrName="users" location={location}>
                  Users
                </OrderByLink>
              </th>
              <th className="text-muted text-truncate">
                <OrderByLink attrName="credits_balance" location={location}>
                  Credits balance
                </OrderByLink>
              </th>
              <th className="text-muted text-truncate">
                <OrderByLink attrName="last_purchase" location={location}>
                  Last purchase
                </OrderByLink>
              </th>
              <th className="text-muted text-truncate">
                <OrderByLink attrName="last_30d" location={location}>
                  Jobs last 30d
                </OrderByLink>
              </th>
              <th className="text-muted text-truncate">
                <OrderByLink attrName="next_30d" location={location}>
                  Jobs next 30d
                </OrderByLink>
              </th>
              <th className="text-muted text-truncate">
                <OrderByLink attrName="shoottype" location={location}>
                  Shoottype
                </OrderByLink>
              </th>
              <th className="text-muted text-truncate">
                <OrderByLink attrName="type" location={location}>
                  Type
                </OrderByLink>
              </th>
              <th className="text-muted text-truncate"></th>
            </tr>
          </thead>

          <tbody>
            {(enterpriseList!.result!.results ?? []).map((partnerSummary) => (
              <tr key={partnerSummary.uid}>
                <StyledTd>
                  <Link to={`/enterprises/${partnerSummary.uid}`}>
                    {partnerSummary.name}
                  </Link>
                </StyledTd>
                <StyledTd>{partnerSummary.account_manager_name}</StyledTd>

                <StyledTd>
                  {partnerSummary.remaining_days != null ? (
                    <span
                      className={
                        partnerSummary.remaining_days < 30 ? 'text-warning' : ''
                      }
                    >
                      {partnerSummary.remaining_days} days to go
                    </span>
                  ) : partnerSummary.expired_contract ? (
                    <span className="text-danger">Expired</span>
                  ) : (
                    <span>-</span>
                  )}
                </StyledTd>
                <StyledTd textAlign="text-right">
                  {partnerSummary.users_count}
                </StyledTd>
                <StyledTd textAlign="text-right">
                  {partnerSummary.credits &&
                    formatCredits(partnerSummary.credits, {
                      maximumFractionDigits: 0,
                    })}
                </StyledTd>
                <StyledTd title={partnerSummary.last_recharged_at}>
                  {partnerSummary.last_recharged_at &&
                    moment(partnerSummary.last_recharged_at).format('D MMM YY')}
                </StyledTd>
                <StyledTd textAlign="text-right">
                  {partnerSummary.bookings_past_30_count}
                </StyledTd>
                <StyledTd textAlign="text-right">
                  {partnerSummary.bookings_next_30_count}
                </StyledTd>
                <StyledTd>
                  {partnerSummary.default_shoottype_display_name}
                </StyledTd>
                <StyledTd>
                  {partnerSummary.is_referrals_enabled
                    ? 'Channel partner'
                    : 'Direct'}
                </StyledTd>
                <StyledTd>
                  <div>
                    <GodModeLink
                      session={session}
                      partnerId={partnerSummary.uid}
                      smallLink={true}
                    />
                  </div>
                </StyledTd>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

const OverdueInvoices = ({
  overdueInvoicesList,
  location,
  session,
  history,
  reFetchOverdueInvoices,
}: OverdueInvoicesProps) => {
  const [partnerIdSelected, setPartnerIdSelected] = useState<string>('');

  return (
    <>
      <div className="d-flex justify-content-start align-items-center mb-3 mt-3 ml-3">
        <SearchField
          onSearch={({ q }) =>
            history.push({
              ...location,
              search: qs.stringify({
                ...qs.parse(location.search),
                q: q || undefined,
              }),
            })
          }
          placeholder="Search invoices"
          defaultValue={qs.parse(location.search).q || ''}
        />
      </div>
      <div
        className="table-responsive"
        style={{
          position: 'relative',
          minHeight: '400px',
          overflowX: 'auto',
        }}
      >
        <table className="table table-hover">
          <thead>
            <tr>
              <th className="text-muted text-truncate">
                <OrderByLink attrName="name" location={location}>
                  Enterprise
                </OrderByLink>
              </th>
              <th className="text-muted text-truncate">
                <OrderByLink attrName="invoice_id" location={location}>
                  Invoice ID
                </OrderByLink>
              </th>
              <th className="text-muted text-truncate">
                <OrderByLink attrName="date_issued" location={location}>
                  Date issued
                </OrderByLink>
              </th>
              <th className="text-muted text-truncate">
                <OrderByLink attrName="due_date" location={location}>
                  Due date
                </OrderByLink>
              </th>
              <th className="text-muted text-truncate">
                <OrderByLink attrName="account_manager" location={location}>
                  Account manager
                </OrderByLink>
              </th>
              <th className="text-muted text-truncate">
                <OrderByLink attrName="credits" location={location}>
                  Credits
                </OrderByLink>
              </th>
              <th className="text-muted text-truncate">
                <OrderByLink attrName="amount" location={location}>
                  Amount
                </OrderByLink>
              </th>
              <th className="text-muted text-truncate"></th>
            </tr>
          </thead>

          <tbody>
            {(
              overdueInvoicesList.data?.getAdminEnterpriseOverdueInvoices
                .edges ?? []
            ).map((overdueInvoice) => (
              <Fragment key={overdueInvoice.invoiceId}>
                <tr>
                  <StyledTd>
                    <Link to={`/enterprises/${overdueInvoice.partner?.uid}`}>
                      {overdueInvoice.partner?.name}
                    </Link>
                  </StyledTd>
                  <StyledTd>{overdueInvoice.invoiceId}</StyledTd>
                  <StyledTd>
                    {overdueInvoice.dateIssued
                      ? moment(overdueInvoice.dateIssued).format('D MMM YY')
                      : null}
                  </StyledTd>
                  <StyledTd>
                    {overdueInvoice.dueDate
                      ? moment(overdueInvoice.dueDate).format('D MMM YY')
                      : null}
                  </StyledTd>
                  <StyledTd>{`${overdueInvoice.managerFullName}`}</StyledTd>
                  <StyledTd>{formatCredits(overdueInvoice.credits)}</StyledTd>
                  <StyledTd>
                    {formatMoneyCents(overdueInvoice.amount, {
                      currency: overdueInvoice.currency,
                    })}
                  </StyledTd>
                  <td className="text-right">
                    <ActionsDropdown>
                      {({ onClose }) => (
                        <div
                          style={{ textAlign: 'left', flexDirection: 'column' }}
                        >
                          <button
                            className="d-flex btn"
                            style={{ width: 'max-content' }}
                            onClick={() =>
                              window.location.assign(
                                `${API_URL}/api/v2/partners/${
                                  overdueInvoice.partner?.uid
                                }/invoice/${overdueInvoice.id}?${qs.stringify({
                                  access_token: (session || {}).token,
                                })}`
                              )
                            }
                            disabled={false}
                          >
                            Download PDF
                          </button>
                          {
                            <div
                              onClick={() =>
                                setPartnerIdSelected(
                                  overdueInvoice.partner?.uid
                                )
                              }
                            >
                              <Link
                                className="d-flex btn"
                                to={locationForModal({
                                  location,
                                  modal: {
                                    modalName: MODAL_NAME_ENTERPRISE_INVOICE_SET_TO_PENDING_MODAL,
                                    invoiceCredits: formatCredits(
                                      overdueInvoice.credits
                                    ),
                                    id: overdueInvoice.id,
                                  },
                                })}
                              >
                                Submit for approval
                              </Link>
                              <Link
                                className="d-flex btn"
                                to={locationForModal({
                                  location,
                                  modal: {
                                    modalName: MODAL_NAME_ENTERPRISE_INVOICE_CANCEL_MODAL,
                                    id: overdueInvoice.id,
                                  },
                                })}
                              >
                                Cancel Invoice
                              </Link>
                            </div>
                          }
                        </div>
                      )}
                    </ActionsDropdown>
                  </td>
                </tr>
              </Fragment>
            ))}
            <OnEnterpriseInvoiceSetToPendingModal
              partnerId={partnerIdSelected}
              session={session}
              reloadInvoices={reFetchOverdueInvoices}
            />
            <OnEnterpriseInvoiceCancelModal
              partnerId={partnerIdSelected}
              session={session}
              reloadInvoices={reFetchOverdueInvoices}
            />
          </tbody>
        </table>
      </div>
    </>
  );
};

const PendingInvoices = ({
  pendingInvoicesList,
  location,
  session,
  history,
  reFetchPendingInvoices,
}: PendingInvoicesProps) => {
  const [partnerIdSelected, setPartnerIdSelected] = useState<string>('');

  return (
    <>
      <div className="d-flex justify-content-start align-items-center mb-3 mt-3 ml-3">
        <SearchField
          onSearch={({ q }) =>
            history.push({
              ...location,
              search: qs.stringify({
                ...qs.parse(location.search),
                q: q || undefined,
              }),
            })
          }
          placeholder="Search invoices"
          defaultValue={qs.parse(location.search).q || ''}
        />
      </div>
      <div
        className="table-responsive"
        style={{
          position: 'relative',
          minHeight: '400px',
          overflowX: 'auto',
        }}
      >
        <table className="table table-hover">
          <thead>
            <tr>
              <th className="text-muted text-truncate">
                <OrderByLink attrName="name" location={location}>
                  Enterprise
                </OrderByLink>
              </th>
              <th className="text-muted text-truncate">
                <OrderByLink attrName="invoice_id" location={location}>
                  Invoice ID
                </OrderByLink>
              </th>
              <th className="text-muted text-truncate">
                <OrderByLink attrName="date_issued" location={location}>
                  Date issued
                </OrderByLink>
              </th>
              <th className="text-muted text-truncate">
                <OrderByLink attrName="due_date" location={location}>
                  Date paid
                </OrderByLink>
              </th>
              <th className="text-muted text-truncate">
                <OrderByLink attrName="account_manager" location={location}>
                  Account manager
                </OrderByLink>
              </th>
              <th className="text-muted text-truncate">
                <OrderByLink attrName="credits" location={location}>
                  Credits
                </OrderByLink>
              </th>
              <th className="text-muted text-truncate">
                <OrderByLink attrName="amount" location={location}>
                  Amount
                </OrderByLink>
              </th>
              <th className="text-muted text-truncate">Evidence</th>
              <th className="text-muted text-truncate"></th>
            </tr>
          </thead>

          <tbody>
            {(
              pendingInvoicesList.data?.getAdminEnterprisePendingInvoices
                .edges ?? []
            ).map((pendingInvoice) => (
              <Fragment key={pendingInvoice.invoiceId}>
                <tr>
                  <StyledTd>
                    <Link to={`/enterprises/${pendingInvoice.partner?.uid}`}>
                      {pendingInvoice.partner?.name}
                    </Link>
                  </StyledTd>
                  <StyledTd>{pendingInvoice.invoiceId}</StyledTd>
                  <StyledTd>
                    {pendingInvoice.dateIssued
                      ? moment(pendingInvoice.dateIssued).format('D MMM YY')
                      : null}
                  </StyledTd>
                  <StyledTd>
                    {pendingInvoice.datePaid
                      ? moment(pendingInvoice.datePaid).format('D MMM YY')
                      : null}
                  </StyledTd>
                  <StyledTd>{`${pendingInvoice.managerFullName}`}</StyledTd>
                  <StyledTd>{formatCredits(pendingInvoice.credits)}</StyledTd>
                  <StyledTd>
                    {formatMoneyCents(pendingInvoice.amount, {
                      currency: pendingInvoice.currency,
                    })}
                  </StyledTd>
                  <StyledTd>
                    {pendingInvoice?.pdfUrl != null && (
                      <a
                        href={pendingInvoice.pdfUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i
                          className="fa fa-download fa-lg"
                          aria-hidden="true"
                        />
                      </a>
                    )}
                  </StyledTd>
                  <td className="text-right">
                    <ActionsDropdown>
                      {({ onClose }) => (
                        <div
                          style={{ textAlign: 'left', flexDirection: 'column' }}
                        >
                          <button
                            className="d-flex btn"
                            style={{ width: 'max-content' }}
                            onClick={() =>
                              window.location.assign(
                                `${API_URL}/api/v2/partners/${
                                  pendingInvoice.partner?.uid
                                }/invoice/${pendingInvoice.id}?${qs.stringify({
                                  access_token: (session || {}).token,
                                })}`
                              )
                            }
                            disabled={false}
                          >
                            Download PDF
                          </button>
                          {
                            <div
                              onClick={() =>
                                setPartnerIdSelected(
                                  pendingInvoice.partner?.uid
                                )
                              }
                            >
                              <Link
                                className="d-flex btn"
                                to={locationForModal({
                                  location,
                                  modal: {
                                    modalName: MODAL_NAME_ENTERPRISE_INVOICE_SET_TO_PAID_MODAL,
                                    invoiceCredits: formatCredits(
                                      pendingInvoice.credits
                                    ),
                                    id: pendingInvoice.id,
                                  },
                                })}
                              >
                                Set to paid
                              </Link>
                              <Link
                                className="d-flex btn"
                                to={locationForModal({
                                  location,
                                  modal: {
                                    modalName: MODAL_NAME_ENTERPRISE_INVOICE_CANCEL_MODAL,
                                    id: pendingInvoice.id,
                                  },
                                })}
                              >
                                Cancel Invoice
                              </Link>
                            </div>
                          }
                        </div>
                      )}
                    </ActionsDropdown>
                  </td>
                </tr>
              </Fragment>
            ))}
            <OnEnterpriseInvoiceSetToPendingModal
              partnerId={partnerIdSelected}
              session={session}
              reloadInvoices={reFetchPendingInvoices}
            />
            <OnEnterpriseInvoiceCancelModal
              partnerId={partnerIdSelected}
              session={session}
              reloadInvoices={reFetchPendingInvoices}
            />
            <OnEnterpriseInvoiceSetToPaidModal
              partnerId={partnerIdSelected}
              session={session}
              reloadInvoices={reFetchPendingInvoices}
            />
          </tbody>
        </table>
      </div>
    </>
  );
};

export const ListEnterprisesRoute = ({ history, session, location }) => {
  const selectedTab =
    qs.parse(location.search).selectedTab ?? TabCode.ENTERPRISE_LIST;
  const enterpriseList = useFetch<{
    results: Enterprise[];
  }>({
    urlToFetch: '/api/v2/partners/summary',
    session,
    queryToFetch: {
      limit: PARTNERS_PER_PAGE,
      q:
        selectedTab === TabCode.ENTERPRISE_LIST
          ? qs.parse(location.search).q || undefined
          : undefined,
      ecommercePartner: qs.parse(location.search).ecommercePartner || 'false',
    },
  });

  const overdueInvoicesList = useQuery<{
    getAdminEnterpriseOverdueInvoices: {
      edges: Invoice[];
    };
  }>(
    gql`
      query AdminEnterpriseOverdueInvoices($search: String) {
        getAdminEnterpriseOverdueInvoices(search: $search) {
          edges {
            id
            invoiceId
            dateIssued
            dueDate
            managerFullName
            credits
            amount
            currency
            status
            partner {
              uid
              name
            }
          }
        }
      }
    `,
    {
      variables: {
        search:
          selectedTab === TabCode.OVERDUE_INVOICES
            ? qs.parse(location.search).q || undefined
            : undefined,
      },
    }
  );

  const pendingInvoicesList = useQuery<{
    getAdminEnterprisePendingInvoices: {
      edges: Invoice[];
    };
  }>(
    gql`
      query AdminEnterprisePendingInvoices($search: String) {
        getAdminEnterprisePendingInvoices(search: $search) {
          edges {
            id
            invoiceId
            dateIssued
            dueDate
            managerFullName
            datePaid
            credits
            amount
            currency
            status
            pdfUrl
            partner {
              uid
              name
            }
          }
        }
      }
    `,
    {
      variables: {
        search:
          selectedTab === TabCode.PENDING_INVOICES
            ? qs.parse(location.search).q || undefined
            : undefined,
      },
    }
  );

  return (
    <div className="d-flex flex-column">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h2>Enterprises</h2>

        <Link
          className={`btn btn-dark`}
          to={locationForModal({
            location,
            modal: { modalName: EnterpriseModalsEnum.CREATE_ENTERPRISE },
          })}
        >
          Create Enterprise
        </Link>

        <ModalRoute modalName={EnterpriseModalsEnum.CREATE_ENTERPRISE}>
          {(routeProps) => {
            return (
              <CreateEnterpriseModal
                onContinue={({ partnerId }: { partnerId: string }) =>
                  history.push(
                    locationForModal({
                      location,
                      modal: {
                        modalName: EnterpriseModalsEnum.CREATE_GUIDELINE,
                        partnerId,
                      },
                    })
                  )
                }
                onDismiss={() =>
                  history.push(
                    locationForModal({
                      location,
                      modal: undefined,
                    })
                  )
                }
              />
            );
          }}
        </ModalRoute>

        <ModalRoute modalName={EnterpriseModalsEnum.CREATE_GUIDELINE}>
          {({ history, location, modalParams }) => {
            return (
              <EnterpriseGuidelineModal
                onDismiss={() => alert('You must add the first guideline')}
                session={session}
                location={location}
                history={history}
                partnerId={modalParams.partnerId}
                onContinue={() =>
                  history.replace(
                    locationForModal({
                      location,
                      modal: {
                        partnerId: modalParams.partnerId,
                        modalName: EnterpriseModalsEnum.CREATE_USER,
                      },
                    })
                  )
                }
                modalParams={modalParams}
              />
            );
          }}
        </ModalRoute>

        <ModalRoute modalName={EnterpriseModalsEnum.CREATE_USER}>
          {({ history, location, modalParams }) => {
            return (
              <NewUserModal
                onDismiss={() => alert('You must add the first admin user')}
                partnerId={modalParams.partnerId}
                onContinue={({
                  primaryBillingContactSelected,
                }: {
                  primaryBillingContactSelected: boolean;
                }) =>
                  history.replace(
                    locationForModal({
                      location,
                      modal: {
                        partnerId: modalParams.partnerId,
                        modalName: primaryBillingContactSelected
                          ? EnterpriseModalsEnum.SUCCESS
                          : EnterpriseModalsEnum.CREATE_PRIMARY_BILLING_USER,
                      },
                    })
                  )
                }
              />
            );
          }}
        </ModalRoute>

        <ModalRoute
          modalName={EnterpriseModalsEnum.CREATE_PRIMARY_BILLING_USER}
        >
          {({ history, location, modalParams }) => {
            return (
              <CreatePrimaryBillingUserModal
                partnerId={modalParams.partnerId}
                onContinue={() =>
                  history.replace(
                    locationForModal({
                      location,
                      modal: {
                        partnerId: modalParams.partnerId,
                        modalName: EnterpriseModalsEnum.SUCCESS,
                      },
                    })
                  )
                }
              />
            );
          }}
        </ModalRoute>

        <ModalRoute modalName={EnterpriseModalsEnum.SUCCESS}>
          {({ history, location, modalParams }) => {
            const { uid, token, expire } = session;

            const authToken = btoa([uid, token, expire].join(':'));

            const linkUrl = `${APP_URL}/auth-and-redirect?${qs.stringify({
              authToken,
              next: `/enterprise/settings/branding?partner=${modalParams.partnerId}`,
            })}`;

            return (
              <Modal
                onDismiss={() =>
                  enterpriseList.reload().then(() =>
                    history.replace(
                      locationForModal({
                        location,
                        modal: undefined,
                      })
                    )
                  )
                }
              >
                <div className="p-4 card" style={{ minWidth: 712 }}>
                  <h2>Enterprise created successfully</h2>
                  <p>
                    Just one last thing. To complete the enterprise’s creation,
                    please go to God Mode and upload the standard and square
                    logos.
                  </p>

                  <img src={checkSvgIcon} height={200} alt="god mode" />

                  <div className="p-3" />

                  <a
                    href={linkUrl}
                    className="btn btn-dark btn-block"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Upload logos in God Mode
                  </a>
                </div>
              </Modal>
            );
          }}
        </ModalRoute>
      </div>

      <ContentWrapper>
        {enterpriseList.isPending || enterpriseList.result == null ? (
          <p>Loading...</p>
        ) : (
          <div className="d-flex flex-column" style={{ minHeight: 400 }}>
            <div className="card flex-1">
              <div className="card-header">
                <ul className="nav nav-tabs card-header-tabs">
                  <li className="nav-item">
                    <Link
                      style={{
                        borderBottomRightRadius: 0,
                        borderBottomLeftRadius: 0,
                      }}
                      className={
                        'btn btn-link nav-link' +
                        (selectedTab !== TabCode.ENTERPRISE_LIST
                          ? ''
                          : ' active')
                      }
                      to={{
                        ...location,
                        search: qs.stringify({
                          ...qs.parse(location.search),
                          selectedTab: TabCode.ENTERPRISE_LIST,
                          q: undefined,
                        }),
                      }}
                    >
                      <strong className="mb-0">Enterprise list</strong>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      style={{
                        borderBottomRightRadius: 0,
                        borderBottomLeftRadius: 0,
                      }}
                      className={
                        'btn btn-link nav-link' +
                        (selectedTab !== TabCode.OVERDUE_INVOICES
                          ? ''
                          : ' active')
                      }
                      to={{
                        ...location,
                        search: qs.stringify({
                          ...qs.parse(location.search),
                          selectedTab: TabCode.OVERDUE_INVOICES,
                          q: undefined,
                        }),
                      }}
                    >
                      <strong className="mb-0">Overdue invoices</strong>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      style={{
                        borderBottomRightRadius: 0,
                        borderBottomLeftRadius: 0,
                      }}
                      className={
                        'btn btn-link nav-link' +
                        (selectedTab !== TabCode.PENDING_INVOICES
                          ? ''
                          : ' active')
                      }
                      to={{
                        ...location,
                        search: qs.stringify({
                          ...qs.parse(location.search),
                          selectedTab: TabCode.PENDING_INVOICES,
                          q: undefined,
                        }),
                      }}
                    >
                      <strong className="mb-0">Pending invoices</strong>
                    </Link>
                  </li>
                </ul>
              </div>

              {enterpriseList.isPending || enterpriseList.result == null ? (
                <p>Loading...</p>
              ) : TabCode.ENTERPRISE_LIST === selectedTab ? (
                <EnterpriseList
                  enterpriseList={enterpriseList}
                  location={location}
                  history={history}
                  session={session}
                />
              ) : TabCode.OVERDUE_INVOICES === selectedTab ? (
                <OverdueInvoices
                  overdueInvoicesList={overdueInvoicesList}
                  location={location}
                  session={session}
                  history={history}
                  reFetchOverdueInvoices={overdueInvoicesList.refetch}
                />
              ) : TabCode.PENDING_INVOICES === selectedTab ? (
                <PendingInvoices
                  pendingInvoicesList={pendingInvoicesList}
                  location={location}
                  session={session}
                  history={history}
                  reFetchPendingInvoices={pendingInvoicesList.refetch}
                />
              ) : (
                <div className="d-flex justify-content-center align-items-center mb-3 mt-3">
                  <h3>{selectedTab}</h3>
                </div>
              )}
            </div>
          </div>
        )}
      </ContentWrapper>
    </div>
  );
};
