import React, { useCallback, useEffect } from 'react';

type KeyboardProps = {
  children?: () => JSX.Element | null;
  right?: () => void;
  left?: () => void;
  esc?: () => void;
};

export const KeyboardControlEvents: React.FC<KeyboardProps> = ({
  children,
  right,
  left,
  esc,
}) => {
  const addKeyboardEvent = useCallback(
    ({ key }) => {
      if (key === 'ArrowRight') right && right();
      if (key === 'ArrowLeft') left && left();
      if (key === 'Escape') esc && esc();
    },
    [right, left, esc]
  );

  useEffect(() => {
    window.addEventListener('keydown', addKeyboardEvent);
    return () => window.removeEventListener('keydown', addKeyboardEvent);
  }, [addKeyboardEvent, right, left, esc]);

  return children ? children() : null;
};
