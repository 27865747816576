import React from 'react';
import { gql, useMutation, useQuery } from '../../../components/ApolloClient';
import {
  ProgressIndicator,
  UploadImagePreview,
  useUploadManager,
} from '../../../components/useUploadManager';
import Dropzone from 'react-dropzone';
import { apiFetch } from '../../../utils/apiFetch';
import { COLOR_PALLETTE } from '../../../constants';
import { Link, RouteComponentProps } from 'react-router-dom';
import { ModalRoute, locationForModal } from '../../../components/ModalRoute';
import { Modal } from '../../../components/Modal';
import { LoadingSpinnerCentered } from '../../../components/LoadingSpinner';

const MODAL_NAME_ADD_CATEGORY_MEDIA = 'add-category-media';

export function Loading() {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '100wh',
        minHeight: '100vh',
      }}
    >
      <div
        style={{
          borderRadius: 4,
          padding: '2em',
          backgroundColor: '#fff',
          boxShadow: '0 0 1.625em rgba(0,0,0,.05)',
        }}
      >
        <h3 style={{ padding: 0, margin: 0 }}>Loading results...</h3>
      </div>
    </div>
  );
}

const CreateCategoryButton = ({
  location,
}: {
  location: RouteComponentProps['location'];
}) => (
  <div className="btn-group">
    <React.Fragment>
      <Link
        to={locationForModal({
          location,
          modal: { modalName: MODAL_NAME_ADD_CATEGORY_MEDIA },
        })}
        className={'btn btn-primary mr-2'}
        replace
      >
        Add media
      </Link>
    </React.Fragment>
  </div>
);

const CreateCategoryMediaModal = ({
  categoryId,
  categoryName,
  onDismiss,
  session,
  refetchMedias,
}: {
  onDismiss: () => void;
  categoryId: string;
  categoryName: string;
  session;
  refetchMedias: () => void;
}) => {
  const [prompt, setPrompt] = React.useState<string>('');
  const [mediaToUpload, setMediaToUpload] = React.useState<string>('');
  const [src, setSrc] = React.useState<string | undefined>();

  const [addCategoryMedia] = useMutation<
    {
      aiProductJobCategoryAddMedia: {
        success: boolean;
        aiProductJobCategoryMediaId: string;
      };
    },
    {
      categoryId: string;
      mediaId: string;
      prompt: string;
    }
  >(
    gql`
      mutation aiProductJobAddCategoryMediaFromAdmin(
        $categoryId: ID!
        $mediaId: ID!
        $prompt: String!
      ) {
        aiProductJobCategoryAddMedia(
          input: { categoryId: $categoryId, mediaId: $mediaId, prompt: $prompt }
        ) {
          success
          aiProductJobCategoryMediaId
        }
      }
    `
  );

  const {
    uploadsList: referenceImagesList,
    addFiles: AddCategoryFiles,
  } = useUploadManager({
    token: session.token!,
    onUploadReady: ({ fileName, mediaUid, remove }) =>
      apiFetch('/api/v2/media/create-from-temp', {
        token: session.token,
        method: 'POST',
        body: JSON.stringify({
          tempMediaUid: mediaUid,
          fileName,
        }),
      })
        .then((res: { uid: string }) => setMediaToUpload(res.uid))
        .then(() => refetchMedias())
        .then(() => remove()),
  });

  return (
    <Modal onDismiss={onDismiss}>
      <div className="card">
        <div
          className="card-body d-flex flex-column"
          style={{ minWidth: 500, minHeight: 250 }}
        >
          <h3 className="text-center">
            Create media for {categoryName ?? 'category'}
          </h3>

          {referenceImagesList.length === 0 && mediaToUpload === '' ? (
            <Dropzone
              style={{
                border: '4px solid #C1C8D4',
                borderRadius: '4px',
                height: 250,
              }}
              multiple={false}
              onDrop={(acceptedFiles) => {
                acceptedFiles.forEach((file) => {
                  const reader = new FileReader();
                  reader.onload = () => {
                    const img = new Image();
                    img.src = reader.result as string;
                    img.onload = () => {
                      AddCategoryFiles({ acceptedFiles: [file] });
                    };
                  };
                  reader.readAsDataURL(file);
                  const imageSrc = window.URL.createObjectURL(file);
                  setSrc(imageSrc);
                });
              }}
            >
              <div className="card-body text-center" style={{ marginTop: 30 }}>
                <div>
                  <p>
                    Drag and drop your image here, <br />
                    <span style={{ textDecoration: 'underline' }}>
                      or browse
                    </span>
                  </p>
                </div>
              </div>
            </Dropzone>
          ) : (
            mediaToUpload !== '' && (
              <div>
                <div style={{ width: 500, height: 'auto' }}>
                  <img
                    style={{ width: '100%', height: 'auto', borderRadius: 4 }}
                    src={src}
                    alt="media to upload"
                  />
                </div>
              </div>
            )
          )}

          <div className="row tw-py-2 tw-gap-2">
            {referenceImagesList != null &&
              referenceImagesList.map((file) => (
                <div
                  key={file.clientId}
                  style={{ position: 'relative', width: 512, height: 512 }}
                >
                  <UploadImagePreview
                    file={file.file}
                    alt="preview"
                    width="512"
                    height="512"
                    style={{
                      objectFit: 'cover',
                      opacity: 0.3,
                      marginRight: 5,
                      marginBottom: 5,
                    }}
                  />
                  <div className="progress">
                    {file.sizeUploaded != null && file.fileSize != null && (
                      <ProgressIndicator
                        progress={file.sizeUploaded / file.fileSize}
                      />
                    )}
                  </div>
                </div>
              ))}
          </div>

          <form
            className="d-flex flex-grow-1 flex-column mt-3"
            onSubmit={(e) => {
              e.preventDefault();

              if (prompt == null) {
                return;
              }

              addCategoryMedia({
                variables: {
                  categoryId,
                  mediaId: mediaToUpload,
                  prompt,
                },
              }).then((res) => {
                if (res.data?.aiProductJobCategoryAddMedia.success) {
                  refetchMedias();
                  onDismiss();
                }
              });
            }}
          >
            <div className="form-group">
              <label>Media prompt</label>

              <input
                type="text"
                className="form-control"
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                placeholder="ex: ...in an outdoors background"
              />
            </div>

            <div className="row">
              <div className="col">
                <button
                  type="button"
                  className="btn btn-outline-secondary btn-block"
                  onClick={onDismiss}
                >
                  Cancel
                </button>
              </div>
              <div className="col">
                <button
                  type="submit"
                  className="btn btn-primary btn-block"
                  disabled={prompt === '' || mediaToUpload === ''}
                >
                  Create media
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export const AiProductJobCategoryDetails = ({
  match,
  session,
  session: { token },
  history,
  location,
}: {
  match;
  session: {
    token: string;
    uid: string;
    user?: { IAM?: string[] };
  };
  history: RouteComponentProps['history'];
  location: RouteComponentProps['location'];
}) => {
  const {
    params: { categoryId },
  } = match;

  const aiProductJobCategory = useQuery<{
    aiProductJobCategoryById: {
      id: string;
      name: string;
      displayName: string;
      categoryMedia: {
        edges: {
          id: string;
          prompt: string;
          deactivatedAt: string;
          imageItem: {
            id: string;
            downloadUrl: string;
            medium: {
              url: string;
            };
            do;
          };
        }[];
      };
    };
  }>(
    gql`
      query aiProductJobCategoryByIdForAdmin($id: ID!) {
        aiProductJobCategoryById(id: $id) {
          id
          name
          displayName
          categoryMedia {
            edges {
              id
              prompt
              deactivatedAt
              imageItem {
                id
                downloadUrl
                medium {
                  url
                }
              }
            }
          }
        }
      }
    `,
    {
      variables: {
        id: categoryId,
      },
      skip: categoryId == null,
    }
  );

  const productJobImages =
    aiProductJobCategory.data?.aiProductJobCategoryById.categoryMedia.edges;

  const categoryHasImages =
    productJobImages != null && productJobImages?.length > 0;

  const [deactivateMedia] = useMutation<
    {
      aiProductJobCategoryMediaDeactivate: {
        success: boolean;
      };
    },
    {
      mediaCategoryId: string;
    }
  >(
    gql`
      mutation aiProductJobCategoryMediaDeactivateFromAdmin(
        $mediaCategoryId: ID!
      ) {
        aiProductJobCategoryMediaDeactivate(
          input: { mediaCategoryId: $mediaCategoryId }
        ) {
          success
        }
      }
    `
  );

  return (
    <>
      <div
        className="card mt-3 border-0"
        style={{ boxShadow: '0 0 1.625em rgba(0,0,0,.05)' }}
      >
        <div className="card-body">
          {aiProductJobCategory.loading === true ? (
            <Loading />
          ) : (
            <div
              className="justify-content-start"
              style={{
                margin: 0,
                display: 'flex',
                flexDirection: 'column',
                gap: 16,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 20,
                }}
              >
                <h3>
                  Category:{' '}
                  {aiProductJobCategory.data?.aiProductJobCategoryById
                    ?.displayName != null &&
                  aiProductJobCategory.data?.aiProductJobCategoryById
                    ?.displayName !== ''
                    ? aiProductJobCategory.data?.aiProductJobCategoryById
                        ?.displayName
                    : aiProductJobCategory.data?.aiProductJobCategoryById?.name}
                </h3>
                {categoryHasImages === true && (
                  <CreateCategoryButton location={location} />
                )}
              </div>

              <div
                style={{
                  border: `1px solid ${COLOR_PALLETTE.GRAY_3}`,
                  padding: '16px',
                  borderRadius: '8px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {categoryHasImages === false ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 16,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    There are no images yet
                    <CreateCategoryButton location={location} />
                  </div>
                ) : (
                  <div className="gallery-grid-for-final-images">
                    {productJobImages != null &&
                      productJobImages.map((media) =>
                        media.imageItem != null ? (
                          <div
                            className="image-container image-with-prompt"
                            key={media.imageItem?.id}
                          >
                            <img
                              src={media.imageItem?.medium.url}
                              alt="category sample"
                              className="img-fluid"
                            />

                            <div className="overlay">
                              <div
                                style={{
                                  fontSize: '18px',
                                  color: 'white',
                                }}
                              >
                                <span
                                  style={{
                                    fontWeight: 600,
                                  }}
                                >
                                  Image prompt:
                                </span>
                                <span> {media.prompt} </span>
                              </div>
                              {media.deactivatedAt != null && (
                                <div
                                  style={{
                                    fontWeight: 600,
                                    fontSize: '18px',
                                    color: `${COLOR_PALLETTE.DANGER}`,
                                  }}
                                >
                                  This media is not active
                                </div>
                              )}

                              {media.deactivatedAt == null && (
                                <button
                                  onClick={() =>
                                    deactivateMedia({
                                      variables: {
                                        mediaCategoryId: media.id ?? '',
                                      },
                                    }).then(() =>
                                      aiProductJobCategory.refetch()
                                    )
                                  }
                                  style={{
                                    color: `${COLOR_PALLETTE.GRAY_1}`,
                                    outline: 'none',
                                    fontSize: '18px',
                                    backgroundColor: `${COLOR_PALLETTE.DANGER}`,
                                    border: 'none',
                                    borderRadius: '2px',
                                    height: '32px',
                                    width: '200px',
                                  }}
                                >
                                  Deactivate image
                                </button>
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className="image-container" key={media.id}>
                            <LoadingSpinnerCentered />
                          </div>
                        )
                      )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <ModalRoute
          modalName={MODAL_NAME_ADD_CATEGORY_MEDIA}
          render={(props) => (
            <CreateCategoryMediaModal
              session={session}
              categoryName={
                aiProductJobCategory.data?.aiProductJobCategoryById
                  ?.displayName != null
                  ? aiProductJobCategory.data?.aiProductJobCategoryById
                      ?.displayName
                  : aiProductJobCategory.data?.aiProductJobCategoryById?.name !=
                    null
                  ? aiProductJobCategory.data?.aiProductJobCategoryById?.name
                  : ''
              }
              categoryId={categoryId}
              refetchMedias={() => {
                aiProductJobCategory.refetch();
              }}
              onDismiss={() =>
                Promise.resolve().then(() =>
                  history.push(locationForModal({ location, modal: undefined }))
                )
              }
            />
          )}
        />

        <style jsx>{`
          .image-with-prompt .overlay {
            visibility: hidden;
          }

          .image-with-prompt:hover .overlay {
            visibility: visible;
          }

          .img-fluid {
            object-fit: cover;
            width: 100%;
            height: 100%;
            object-position: top;
            border-radius: 8px;
          }

          .overlay {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            backdrop-filter: blur(5px);
            background-color: rgba(0, 0, 0);
            opacity: 0.8;
            transition: opacity 0.3s ease;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            padding: 8px;
            justify-content: center;
            align-items: center;
            gap: 16px;
          }

          .progress {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
          }

          .gallery-grid-for-final-images {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            min-height: 380px;
            gap: 8px;
          }

          .image-container {
            position: relative;
            height: 100%;
            min-height: 380px;
            display: flex;
            align-items: center;
            margin: auto;
          }

          .image-container img {
            width: 100%;
            height: auto;
          }
        `}</style>
      </div>
    </>
  );
};
