import qs from 'query-string';
import fetch from 'isomorphic-unfetch';

import { API_URL } from '../config';

export function apiFetch<T>(
  url,
  options:
    | undefined
    | ({ token?: string | null | undefined; query?: Object } & RequestInit) = {}
): Promise<T> {
  const { token, query, body, ...restOptions } = options;

  const urlWithQuery =
    query && Object.getOwnPropertyNames(query).length > 0
      ? `${url}?${qs.stringify(query)}`
      : url;

  return fetch(API_URL + urlWithQuery, {
    headers: {
      ...(body instanceof FormData
        ? {}
        : { 'Content-Type': 'application/json' }),
      ...(token && { Authorization: `Bearer ${token}` }),
    },
    body,
    ...restOptions,
  }).then((res) => {
    if (res.status >= 200 && res.status < 300) {
      return res.json();
    }

    console.error(res);
    const resClone = res.clone();

    return resClone
      .json()
      .then(
        (resBody) => resBody,
        () => null
      )
      .then((resBody) => {
        throw res;
      });
  });
}
