import qs from 'query-string';
import { Link } from 'react-router-dom';
import Component2 from '@reach/component-component';

import { gql, useQuery } from '../../components/ApolloClient';
import { formatMoneyCents } from '../../utils';
import { OrderByLink } from '../../components/OrderByLink';
import { LoadingSpinnerCentered } from '../../components/LoadingSpinner';
import { Pagination } from '../../components/Pagination';

const CUSTOMERS_PER_PAGE = 20;

const TdLink = ({
  to,
  title,
  className,
  ...props
}: {
  to: string;
  title?: string;
  className?: string;
  [K: string]: unknown;
}) => (
  <td title={title} style={{ verticalAlign: 'middle' }}>
    <Link
      to={to}
      className={['text-secondary d-inline-block text-truncate', className]
        .filter((r) => !!r)
        .join(' ')}
      {...props}
    />
  </td>
);

const GET_CUSTOMERS = gql`
  query getCustomers(
    $first: Int
    $offset: Int
    $query: String
    $ordering: String
  ) {
    customersAsAdmin(
      first: $first
      offset: $offset
      query: $query
      ordering: $ordering
    ) {
      total
      edges {
        id
        firstName
        lastName
        createdAt

        latestBooking {
          startAt
          city
        }

        totalBookings
        totalSpend
      }
    }
  }
`;

type IGetCustomers = {
  customersAsAdmin: {
    total?: number;
    edges: {
      id: string;
      firstName?: string;
      lastName?: string;
      createdAt?: string;

      latestBooking?: {
        startAt?: string;
        city?: string;
      };

      totalBookings?: number;
      totalSpend?: number;
    }[];
  };
};

export default function CustomersListRoute(props) {
  const { basePath, location, history } = props;

  const parsedSearch = qs.parse(location.search);
  const { data, loading } = useQuery<IGetCustomers>(GET_CUSTOMERS, {
    variables: {
      first: parseInt(parsedSearch.limit as string) || CUSTOMERS_PER_PAGE,
      offset: parseInt(parsedSearch.offset as string) || undefined,
      query: parsedSearch.q || undefined,
      ordering: parsedSearch.ordering || undefined,
    },
  });

  return (
    <>
      <h2>Customers</h2>

      <div className="row">
        <div className="col-12">
          <div className="d-flex justify-content-end bg-white rounded mb-2 p-2">
            <Component2
              search={qs.parse(location.search)}
              initialState={{ q: null }}
              didMount={({
                props: {
                  search: { q },
                },
                setState,
              }) => setState({ q })}
              didUpdate={({
                props: {
                  search: { q },
                },
                props,
                prevProps,
                setState,
              }) => props.search.q !== prevProps.search.q && setState({ q })}
            >
              {({ state, setState }) => (
                <form
                  className="d-flex justify-content-end"
                  onSubmit={(ev) => {
                    ev.preventDefault();
                    history.push({
                      ...location,
                      search: qs.stringify({
                        ...qs.parse(location.search),
                        q: state.q || undefined,
                      }),
                    });
                  }}
                >
                  <input
                    type="text"
                    className="form-control"
                    style={{ maxWidth: 300 }}
                    placeholder="Search"
                    value={state.q || ''}
                    onChange={({ target: { value } }) => setState({ q: value })}
                  />
                </form>
              )}
            </Component2>

            <div className="d-inline-flex ml-2">
              <select
                className="custom-select"
                style={{
                  appearance: 'none',
                  WebkitAppearance: 'none',
                  MozAppearance: 'none',
                }}
                defaultValue={
                  qs.parse(location.search).limit || CUSTOMERS_PER_PAGE
                }
                onChange={(e) => {
                  e.preventDefault();
                  history.push({
                    ...location,
                    search: qs.stringify({
                      ...qs.parse(location.search),
                      limit: Number(e.target.value),
                      providers: undefined,
                    }),
                  });
                }}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="position-relative bg-white p-2">
            <div className="table-responsive position-relative">
              <table className="table">
                <thead>
                  <tr>
                    <th className="text-muted text-truncate">UID</th>
                    <th className="text-muted text-truncate">
                      <OrderByLink attrName="firstName" location={location}>
                        First Name
                      </OrderByLink>
                    </th>
                    <th className="text-muted text-truncate">
                      <OrderByLink attrName="lastName" location={location}>
                        Last Name
                      </OrderByLink>
                    </th>
                    <th className="text-muted text-truncate">
                      <OrderByLink attrName="createdAt" location={location}>
                        Created
                      </OrderByLink>
                    </th>
                    <th className="text-muted text-truncate">
                      <OrderByLink
                        attrName="totals.totalBookings"
                        location={location}
                      >
                        Total Bookings
                      </OrderByLink>
                    </th>
                    <th className="text-muted text-truncate">
                      <OrderByLink
                        attrName="totals.totalSpend"
                        location={location}
                      >
                        Total Spend
                      </OrderByLink>
                    </th>
                    <th className="text-muted text-truncate">
                      <OrderByLink
                        attrName="latestBooking.city"
                        location={location}
                      >
                        Latest Booking City
                      </OrderByLink>
                    </th>
                    <th className="text-muted text-truncate">
                      <OrderByLink
                        attrName="latestBooking.startAt"
                        location={location}
                      >
                        Latest Booking Start Time
                      </OrderByLink>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {(data?.customersAsAdmin.edges ?? []).map((customer) => (
                    <tr key={customer.id} className="tableEntry linked">
                      <TdLink
                        to={`${basePath}/${customer.id}`}
                        title={customer.id}
                      >
                        {customer.id}
                      </TdLink>

                      <TdLink to={`${basePath}/${customer.id}`}>
                        {customer.firstName}
                      </TdLink>

                      <TdLink to={`${basePath}/${customer.id}`}>
                        {customer.lastName}
                      </TdLink>

                      <TdLink to={`${basePath}/${customer.id}`}>
                        {customer.createdAt}
                      </TdLink>

                      <TdLink to={`${basePath}/${customer.id}`}>
                        {customer.totalBookings}
                      </TdLink>

                      <TdLink to={`${basePath}/${customer.id}`}>
                        {formatMoneyCents(customer.totalSpend, null)}
                      </TdLink>

                      <TdLink to={`${basePath}/${customer.id}`}>
                        {customer.latestBooking?.city}
                      </TdLink>

                      <TdLink to={`${basePath}/${customer.id}`}>
                        {customer.latestBooking?.startAt}
                      </TdLink>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {loading && (
              <div
                className="position-absolute d-flex"
                style={{
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  background: `rgba(255, 255, 255, 0.7)`,
                }}
              >
                <LoadingSpinnerCentered />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-end mt-3">
        <Pagination
          limit={
            (qs.parse(location.search).limit &&
              Number(qs.parse(location.search).limit)) ||
            CUSTOMERS_PER_PAGE
          }
          offset={parseInt(
            (qs.parse(location.search).offset as string) || '0',
            10
          )}
          count={data?.customersAsAdmin.total}
          linkForOffset={(offset, { disabled, ...props }) => (
            <Link
              className="page-link"
              to={{
                ...location,
                search: qs.stringify({
                  ...qs.parse(location.search),
                  offset: offset || undefined,
                }),
              }}
              {...props}
            />
          )}
        />
      </div>
    </>
  );
}
