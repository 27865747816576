import React from 'react';
import { Modal } from './Modal';
import { gql, useMutation } from './ApolloClient';

type EnterpriseContractCancelProps = {
  partnerId: string;
  session: { token: string };
  onDismiss: () => void;
  location;
  history;
  onContinue: () => void;
  modalParams: {
    modalName: string;
    id: string;
    deactivatedAt: string;
  };
};

export const EnterpriseContractCancelModal = ({
  partnerId,
  session,
  location,
  history,
  onDismiss,
  onContinue,
  modalParams,
}: EnterpriseContractCancelProps) => {
  const [commitSetToCancelled, commitSetToCancelledMutation] = useMutation(
    gql`
      mutation PartnerContractSetToCancelledAsAdminMutation(
        $id: ID!
        $deactivatedAt: String
      ) {
        partnerContractSetDeactivateAsAdmin(
          input: { id: $id, deactivatedAt: $deactivatedAt }
        ) {
          contract {
            id
          }
        }
      }
    `
  );

  return (
    <Modal onDismiss={onDismiss}>
      <div className="card my-4">
        <div className="card-header">
          <h4 className="mb-0">
            {modalParams.deactivatedAt != null
              ? 'Deactivate contract'
              : 'Activate contract'}
          </h4>
        </div>
        <div className="card-body d-flex flex-column">
          <div style={{ padding: 16, textAlign: 'center' }}>
            {` Are you sure you want to set this contract as ${
              modalParams.deactivatedAt != null ? 'deactivated' : 'activated'
            }?`}
          </div>
          <div
            style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 16 }}
          >
            <button
              disabled={commitSetToCancelledMutation.loading}
              onClick={() =>
                commitSetToCancelled({
                  variables: {
                    id: modalParams.id,
                    deactivatedAt: modalParams.deactivatedAt,
                  },
                }).then(() => onContinue())
              }
              type="submit"
              className="btn  btn-danger"
            >
              Yes
            </button>
            <button
              type="submit"
              className="btn  btn-dark"
              disabled={commitSetToCancelledMutation.loading}
              onClick={() => onDismiss()}
            >
              No
            </button>
          </div>
        </div>
      </div>

      <style jsx>{`
        label.text-secondary,
        label.text-danger {
          font-size: 0.8rem;
        }

        p.text-danger {
          font-size: 0.8rem;
        }
      `}</style>
    </Modal>
  );
};
