import React from 'react';

import { PromiseButton } from '../../../../../components/PromiseButton';
import { Promiser } from '../../../../../components/Promiser';
import { Fetcher } from '../../../../../components/Fetcher';
import { Modal } from '../../../../../components/Modal';
import { CancellationReasonSelector } from './CancellationReasonSelector';
import MoreThan24Warning from './MoreThan24Warning';
import { apiFetch, formatMoneyCents } from '../../../../../utils';
import { formatCreditsOrMoney, notificationIsDismissed } from './utils';
import { locationForModal } from '../ModalRoute';
import { isPartnerBooking } from '../../selectors';

const RefundSummary = ({ onDismiss, session, booking, location, history }) => (
  <Modal onDismiss={onDismiss}>
    <Fetcher
      urlToFetch={`/api/v2/admin/bookings/${booking.uid}/refunds/summary`}
      session={session}
      onFulfilled={({ response: summary }) => {
        if (summary === null) {
          onDismiss();
        }
      }}
    >
      {({ loading: summaryLoading, response: summary }) => (
        <div className="card my-4">
          <div className="card-header">
            <h4 className="mb-0">Summary</h4>
          </div>

          <div
            className="card-body d-flex flex-column"
            style={{ width: '1000px', minHeight: '600px' }}
          >
            <div className="flex-1" style={{ position: 'relative' }}>
              {summary && (
                <React.Fragment>
                  <div className="alert alert-success">
                    Refund has been recorded on the system successfully.
                    <br />
                    Now you must apply the refund on Braintreee.
                  </div>

                  <div className="card mb-3">
                    <div className="card-body">
                      <div className="form-group mb-0">
                        <label>
                          <small className="text-secondary">Amount:</small>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          readOnly
                          value={formatCreditsOrMoney({
                            booking,
                            value: summary.amount,
                          })}
                          onClick={(ev) => ev.target.select()}
                        />
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}

              {summaryLoading && (
                <div
                  className={[
                    'd-flex flex-columns',
                    'justify-content-center align-items-center',
                  ].join(' ')}
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                  }}
                >
                  <span>Loading...</span>
                </div>
              )}
            </div>

            <div className="d-flex justify-content-between mt-3">
              <button className="btn btn-secondary mr-2" onClick={onDismiss}>
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </Fetcher>
  </Modal>
);

class RefundSection extends React.Component {
  state = {
    amount: this.props.partialAmount,
  };

  componentDidMount() {
    const {
      reasons,
      onReasonChange,
      onDeductFromPhotographerChange,
    } = this.props;
    onReasonChange({ reason: reasons[0] });
    onDeductFromPhotographerChange({ deductFromPhotographer: true });
  }

  componentDidUpdate(prevProps) {
    if (this.props.partialAmount !== prevProps.partialAmount) {
      this.setState({ amount: this.props.partialAmount });
    }
  }

  componentWillUnmount() {
    const {
      onReasonChange,
      onDeductFromPhotographerChange,
      onDeductFromPhotographerAmountChange,
    } = this.props;
    onReasonChange({ reason: null });
    onDeductFromPhotographerChange({ deductFromPhotographer: null });
    onDeductFromPhotographerAmountChange({
      deductFromPhotographerAmount: null,
    });
  }

  onPhotographerDeductAmountValidation({ value }) {
    const parsedValue = parseFloat(value) * 100 || 0;
    if (parsedValue < 0) {
      alert('Deduct amount can not be less than 0.');
      this.props.onDeductFromPhotographerAmountChange({
        deductFromPhotographerAmount: null,
      });
      return;
    }

    const { booking } = this.props;

    const maxValue =
      booking.centralize_editing_enabled === true
        ? booking.product.provider_pay_central_editing
        : booking.product.provider_pay;

    if (parsedValue > maxValue) {
      alert(
        `Deduct amount can not be greater than ${formatMoneyCents(maxValue)}`
      );
      this.props.onDeductFromPhotographerAmountChange({
        deductFromPhotographerAmount: null,
      });
      return;
    }
  }

  render() {
    const {
      reasons,
      selectedReason,
      onReasonChange,
      partial,
      onPartialChange,
      details,
      onDetailsChange,
      deductFromPhotographer,
      deductFromPhotographerAmount,
      onDeductFromPhotographerChange,
      onDeductFromPhotographerAmountChange,
      booking,
    } = this.props;

    return (
      <React.Fragment>
        <div>
          <span>Refund amount:</span>
          <div className="form-check form-check-inline ml-2">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                name="refundAmountOptions"
                checked={!partial}
                onChange={({ target: { value } }) => {
                  onPartialChange({
                    partial: false,
                    amount: null,
                  });
                }}
              />
              Full
            </label>
          </div>

          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                name="refundAmountOptions"
                checked={!!partial}
                onChange={({ target: { value } }) => {
                  onPartialChange({
                    partial: true,
                    amount: 0,
                  });
                }}
              />
              Partial
            </label>
          </div>

          <span>{'$ '}</span>
          <input
            type="number"
            min="1"
            max={`${(booking.final_price || booking.price) / 100}`}
            step="any"
            className="form-control d-inline-block"
            placeholder="Amount"
            style={{ width: 200 }}
            disabled={!partial}
            value={this.state.amount == null ? '' : this.state.amount}
            onFocus={(ev) => ev.target.select()}
            onChange={({ target: { value } }) =>
              this.setState({ amount: value })
            }
            onBlur={({ target: { value } }) => {
              const parsedValue = parseFloat(value) * 100 || 0;
              if (parsedValue < 0) {
                alert('Refund amount can not be less than 0.');
                this.setState({ amount: 0 });
                onPartialChange({ amount: 0 });
                return;
              }

              if (parsedValue > (booking.final_price || booking.price)) {
                alert(
                  'Refund amount can not be greater than the price of the booking.'
                );
                this.setState({
                  amount: (booking.final_price || booking.price) / 100,
                });
                onPartialChange({
                  amount: (booking.final_price || booking.price) / 100,
                });
                return;
              }

              onPartialChange({ amount: value || 0 });
            }}
          />
        </div>

        <div className="mt-2">
          <span>Refund category</span>

          <select
            className="form-control d-inline w-50 ml-2"
            value={selectedReason || ''}
            onChange={({ target: { value } }) =>
              onReasonChange({
                reason: value,
              })
            }
          >
            {reasons.map((reason) => (
              <option key={reason} value={reason}>
                {reason}
              </option>
            ))}
          </select>
        </div>

        <div className="mt-2">
          <span>Refund details:</span>

          <input
            type="text"
            className="form-control d-inline w-50 ml-2"
            value={details || ''}
            onChange={({ target: { value } }) =>
              onDetailsChange({ details: value || null })
            }
          />
        </div>
        {booking.provider_uid != null && (
          <div className="mt-2">
            <span>Refund photographer fee:</span>
            <div className="form-check form-check-inline ml-2">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  name="deductFromPhotographerOptions"
                  checked={!!deductFromPhotographer}
                  onChange={({ target: { value } }) => {
                    onDeductFromPhotographerChange({
                      deductFromPhotographer: true,
                    });
                  }}
                />
                Yes
              </label>
            </div>

            <div className="form-check form-check-inline ml-2">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  name="deductFromPhotographerOptions"
                  checked={!deductFromPhotographer}
                  onChange={({ target: { value } }) => {
                    onDeductFromPhotographerChange({
                      deductFromPhotographer: false,
                    });
                  }}
                />
                No
              </label>
            </div>

            <input
              type="number"
              min="1"
              max={`${
                (booking.centralize_editing_enabled === true
                  ? booking.product.provider_pay_central_editing
                  : booking.product.provider_pay) / 100
              }`}
              step="any"
              className="form-control d-inline-block"
              placeholder="Amount"
              style={{ width: 200 }}
              disabled={!deductFromPhotographer}
              defaultValue={deductFromPhotographerAmount / 100}
              value={deductFromPhotographerAmount || ''}
              onFocus={(ev) => ev.target.select()}
              onChange={({ target: { value } }) => {
                onDeductFromPhotographerAmountChange({
                  deductFromPhotographerAmount: value,
                });
              }}
              onBlur={({ target: { value } }) => {
                this.onPhotographerDeductAmountValidation({
                  value: value,
                });
              }}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}

const serializeAmount = (amount) =>
  amount == null ? undefined : Math.round(parseFloat(amount) * 100);

export class CancelOrRefundModal extends React.Component {
  state = {
    validationPromise: null,
    drop_notifications: [],
    refund_customer: true,

    refund_partial: false,
    refund_amount: null,
    refund_reason: null,
    refund_deduct_from_photographer: null,
    refund_deduct_from_photographer_amount: null,
    refund_details: null,

    cancellation_reason: null,
  };

  componentDidMount() {
    this.requestValidation(this.props);

    const { booking } = this.props;

    if (booking.product != null) {
      this.setState({
        refund_deduct_from_photographer_amount:
          booking.centralize_editing_enabled === true
            ? booking.product.provider_pay_central_editing / 100
            : booking.product.provider_pay / 100,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.drop_notifications === this.state.drop_notifications &&
      prevState.refund_customer === this.state.refund_customer &&
      prevState.refund_partial === this.state.refund_partial &&
      prevState.refund_amount === this.state.refund_amount &&
      prevState.refund_reason === this.state.refund_reason &&
      prevState.refund_details === this.state.refund_details &&
      prevState.refund_deduct_from_photographer_amount ===
        this.state.refund_deduct_from_photographer_amount &&
      prevState.refund_deduct_from_photographer ===
        this.state.refund_deduct_from_photographer
    ) {
      return;
    }

    this.requestValidation(this.props);
  }

  requestValidation({ booking, session: { token } }) {
    this.setState((state) => ({
      validationPromise: apiFetch(
        `/api/v2/admin/bookings/${booking.uid}/validate-cancellation-or-refund`,
        {
          token,
          method: 'POST',
          body: JSON.stringify({
            drop_notifications: state.drop_notifications,
            refund_customer: state.refund_customer,

            refund_partial: state.refund_partial || undefined,
            refund_amount: serializeAmount(state.refund_amount),

            refund_reason:
              this.state.refund_reason == null
                ? undefined
                : this.state.refund_reason,
            refund_details:
              this.state.refund_details == null
                ? undefined
                : this.state.refund_details,
            refund_deduct_from_photographer_amount:
              this.state.refund_deduct_from_photographer_amount == null
                ? undefined
                : this.state.refund_deduct_from_photographer === true
                ? serializeAmount(
                    this.state.refund_deduct_from_photographer_amount
                  )
                : undefined,
          }),
        }
      ),
    }));
  }

  toggleNotification = ({ notification, notifications }) => {
    this.setState((prevState) => ({
      drop_notifications: notificationIsDismissed({
        notification,
        notifications,
      })
        ? prevState.drop_notifications.filter(
            (type) => type !== notification.type
          )
        : [...prevState.drop_notifications, notification.type],
    }));
  };

  confirmCancellation = ({ validationToken }) => {
    const {
      booking,
      session: { token },
      history,
      location,
      modalParams,
      onDismiss,
      onReload,
    } = this.props;
    return apiFetch(
      `/api/v2/admin/bookings/${booking.uid}/commit-cancellation-or-refund`,
      {
        token,
        method: 'POST',
        body: JSON.stringify({
          drop_notifications: this.state.drop_notifications,
          refund_customer: this.state.refund_customer,

          cancellation_reason: this.state.cancellation_reason || undefined,

          refund_partial: this.state.refund_partial || undefined,
          refund_amount: serializeAmount(this.state.refund_amount),
          refund_reason:
            this.state.refund_reason == null
              ? undefined
              : this.state.refund_reason,
          refund_details:
            this.state.refund_details == null
              ? undefined
              : this.state.refund_details,

          token: validationToken,
          refund_deduct_from_photographer_amount:
            this.state.refund_deduct_from_photographer_amount == null
              ? undefined
              : this.state.refund_deduct_from_photographer === true
              ? serializeAmount(
                  this.state.refund_deduct_from_photographer_amount
                )
              : undefined,
        }),
      }
    )
      .then(() => onReload())
      .then(() =>
        isPartnerBooking({ booking: this.props.booking })
          ? onDismiss()
          : history.push(
              locationForModal({
                location,
                modal: { ...modalParams, refundSummary: true },
              })
            )
      );
  };

  render() {
    const {
      onDismiss,
      bookingClone,
      booking,
      modalParams,
      session,
      location,
      history,
    } = this.props;

    if (modalParams.refundSummary) {
      return (
        <RefundSummary
          onDismiss={onDismiss}
          booking={booking}
          session={session}
          location={location}
          history={history}
        />
      );
    }

    return (
      <Modal onDismiss={onDismiss}>
        <Promiser promise={this.state.validationPromise}>
          {({
            isPending: validationLoading,
            result: {
              notifications: {
                final: notifications = [],
                original: original_notifications = [],
              } = {},
              information_required: {
                is_cancellation,
                cancellation_reasons,
                refund_reasons,
              } = {},
              charges: { total: total_charges } = {},
              token: validationToken,
            } = {},
          }) => (
            <div className="card my-4">
              <div className="card-header">
                <h4 className="mb-0">
                  {is_cancellation != null
                    ? is_cancellation
                      ? 'Cancel Booking'
                      : 'Refund Booking'
                    : 'Loading...'}
                </h4>
              </div>

              <div
                className="card-body d-flex flex-column"
                style={{ width: '1000px', minHeight: '600px' }}
              >
                <div className="flex-1" style={{ position: 'relative' }}>
                  {is_cancellation != null &&
                    (is_cancellation ? (
                      <React.Fragment>
                        <div className="alert alert-warning">
                          Caution: If a photographer can no longer take a shoot,
                          you should try to reallocate the same booking to a new
                          photographer. Do not cancel if the shoot is going
                          ahead.
                        </div>

                        <MoreThan24Warning booking={booking} />
                      </React.Fragment>
                    ) : (
                      <div className="alert alert-warning">
                        Warning: Refunds require manager approval. Do not
                        proceed without approval.
                      </div>
                    ))}

                  <div className="card mb-3">
                    <div className="card-body">
                      <h5>Information required</h5>

                      {cancellation_reasons != null && (
                        <CancellationReasonSelector
                          reasons={cancellation_reasons}
                          selectedReason={this.state.cancellation_reason}
                          onReasonChange={({ reason }) =>
                            this.setState({
                              cancellation_reason: reason,
                            })
                          }
                        />
                      )}

                      {is_cancellation === true && (
                        <div className="form-check mt-2">
                          <label className="form-check-label">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={this.state.refund_customer}
                              onChange={() =>
                                this.setState((state) => ({
                                  refund_customer: !state.refund_customer,
                                }))
                              }
                            />

                            <span>Refund the customer</span>
                          </label>
                        </div>
                      )}

                      {refund_reasons != null && (
                        <RefundSection
                          reasons={refund_reasons}
                          selectedReason={this.state.refund_reason}
                          onReasonChange={({ reason }) =>
                            this.setState({
                              refund_reason: reason,
                            })
                          }
                          booking={booking}
                          partial={this.state.refund_partial}
                          partialAmount={this.state.refund_amount}
                          onPartialChange={({ partial, amount }) =>
                            this.setState((state) => ({
                              refund_partial:
                                partial === undefined
                                  ? state.refund_partial
                                  : partial,
                              refund_amount: amount,
                            }))
                          }
                          details={this.state.refund_details}
                          onDetailsChange={({ details }) =>
                            this.setState({ refund_details: details })
                          }
                          deductFromPhotographer={
                            this.state.refund_deduct_from_photographer
                          }
                          deductFromPhotographerAmount={
                            this.state.refund_deduct_from_photographer_amount
                          }
                          onDeductFromPhotographerChange={({
                            deductFromPhotographer,
                          }) =>
                            this.setState({
                              refund_deduct_from_photographer: deductFromPhotographer,
                            })
                          }
                          onDeductFromPhotographerAmountChange={({
                            deductFromPhotographerAmount,
                          }) => {
                            this.setState({
                              refund_deduct_from_photographer_amount: deductFromPhotographerAmount,
                            });
                          }}
                        />
                      )}
                    </div>
                  </div>

                  <div className="card mb-3">
                    <div className="card-body">
                      <h5>Notifications</h5>

                      {original_notifications &&
                        original_notifications.length > 0 &&
                        original_notifications.map((notification) => (
                          <div className="form-check" key={notification.type}>
                            <label className="form-check-label">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={
                                  !notificationIsDismissed({
                                    notification,
                                    notifications,
                                  })
                                }
                                onChange={() =>
                                  this.toggleNotification({
                                    notification,
                                    notifications,
                                  })
                                }
                              />

                              <span>{notification.description}</span>
                            </label>
                          </div>
                        ))}
                    </div>
                  </div>

                  {validationLoading && (
                    <div
                      className={[
                        'd-flex flex-columns',
                        'justify-content-center align-items-center',
                      ].join(' ')}
                      style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                      }}
                    >
                      <span>Loading...</span>
                    </div>
                  )}
                </div>

                <div className="d-flex justify-content-between mt-3">
                  <button
                    className="btn btn-secondary mr-2"
                    onClick={onDismiss}
                  >
                    Close
                  </button>

                  {is_cancellation != null && (
                    <PromiseButton
                      className="btn btn-primary"
                      disabled={validationLoading}
                      pendingChildren="Processing..."
                      onClick={() =>
                        this.confirmCancellation({ validationToken })
                      }
                    >
                      {is_cancellation ? 'Cancel shoot' : 'Process Refund'}{' '}
                      {total_charges != null &&
                        formatCreditsOrMoney({
                          booking: bookingClone,
                          value: total_charges,
                        })}
                    </PromiseButton>
                  )}
                </div>
              </div>
            </div>
          )}
        </Promiser>
      </Modal>
    );
  }
}
