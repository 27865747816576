import React from 'react';

import ContentWrapper from '../../components/ContentWrapper';
import { gql, useMutation, useQuery } from '../../components/ApolloClient';
import { usePromise } from '../../components/usePromise';

type Booking = {
  id: string;
  status?: string;
  region?: {
    name?: string;
  };
};

const regions = ['AU', 'CA', 'NZ', 'PR', 'US'];

function bookingsGroupByRegion({ bookings }: { bookings: Booking[] }) {
  return bookings?.reduce((group, booking) => {
    const regionName = booking?.region?.name ?? '';

    group[regionName] = group[regionName] ?? [];

    group[regionName].push(booking);

    return group;
  }, {});
}

export function CompletionLeadQueue() {
  const {
    setPromise: setClaimLeadsPromise,
    isPending: claimLeadsIsPending,
  } = usePromise();

  const bookingsAccountOwnerQueueByIdAsAdminQuery = useQuery<{
    bookingsAccountOwnerQueueByIdAsAdmin?: {
      edges: Booking[];
    };
  }>(
    gql`
      query BookingsAccountOwnerQueueByIdAsAdmin {
        bookingsAccountOwnerQueueByIdAsAdmin {
          edges {
            id
            status
            region {
              name
            }
          }
        }
      }
    `
  );

  const [accountOwnerQueueLeadsAllocateAsAdmin] = useMutation<
    {
      accountOwnerQueueLeadsAllocateAsAdmin: {
        jobStatusUrl?: string;
        message?: string;
      };
    },
    {
      leadsBatchSize: number;
    }
  >(
    gql`
      mutation AccountOwnerQueueLeadsAllocateAsAdmin($leadsBatchSize: Int!) {
        accountOwnerQueueLeadsAllocateAsAdmin(
          input: { leadsBatchSize: $leadsBatchSize }
        ) {
          jobStatusUrl
          message
        }
      }
    `
  );

  const [
    leadsAllocationTrackingInfo,
    setLeadsAllocationTrackingInfo,
  ] = React.useState<{ message?: string; jobStatusUrl?: string }[]>();

  const bookingsPendingToClaim =
    bookingsAccountOwnerQueueByIdAsAdminQuery.data
      ?.bookingsAccountOwnerQueueByIdAsAdmin?.edges;

  const bookingsPendingToClaimPaidPendingSchedule = bookingsPendingToClaim?.filter(
    (booking) => booking?.status === 'paid_pending_schedule'
  );

  const bookingsPendingToClaimPendingSchedule = bookingsPendingToClaim?.filter(
    (booking) => booking?.status === 'pending_schedule'
  );

  const bookingsPendingToClaimPaid = bookingsPendingToClaim?.filter(
    (booking) => booking?.status === 'paid'
  );

  const bookingsPendingToClaimOnHold = bookingsPendingToClaim?.filter(
    (booking) => booking?.status === 'on_hold'
  );

  const bookingsPaidPendingScheduleGroupedByRegion =
    bookingsPendingToClaimPaidPendingSchedule != null
      ? bookingsGroupByRegion({
          bookings: bookingsPendingToClaimPaidPendingSchedule,
        })
      : undefined;

  const bookingsPendingScheduleGroupedByRegion =
    bookingsPendingToClaimPendingSchedule != null
      ? bookingsGroupByRegion({
          bookings: bookingsPendingToClaimPendingSchedule,
        })
      : undefined;

  const bookingsPaidGroupedByRegion =
    bookingsPendingToClaimPaid != null
      ? bookingsGroupByRegion({
          bookings: bookingsPendingToClaimPaid,
        })
      : undefined;

  const bookingsOnHoldGroupedByRegion =
    bookingsPendingToClaimOnHold != null
      ? bookingsGroupByRegion({
          bookings: bookingsPendingToClaimOnHold,
        })
      : undefined;

  return (
    <>
      <h2>Completion Lead Queue</h2>
      <ContentWrapper>
        <h3>Queue Status</h3>
        <div className="table-responsive">
          <table className="table table-hover mt-3">
            <thead>
              <tr>
                <th />

                {regions.map((regionName) => (
                  <th
                    key={`${regionName}-title`}
                    colSpan={1}
                    className="text-center"
                  >
                    {regionName} leads
                  </th>
                ))}
              </tr>

              <tr>
                <th>Type</th>

                {regions.map((regionName) => (
                  <th className="text-center" key={`${regionName}-available`}>
                    Available
                  </th>
                ))}
              </tr>
            </thead>

            <tbody>
              <tr>
                <th>Paid pending schedule</th>

                {regions.map((regionName) => {
                  const bookingsByCurrentRegion =
                    bookingsPaidPendingScheduleGroupedByRegion?.[regionName];

                  return (
                    <td
                      className="text-center"
                      key={`${regionName}-pending-schedule`}
                    >
                      {bookingsByCurrentRegion != null
                        ? bookingsByCurrentRegion?.length
                        : '0'}
                    </td>
                  );
                })}
              </tr>

              <tr>
                <th>Pending schedule</th>

                {regions.map((regionName) => {
                  const bookingsByCurrentRegion =
                    bookingsPendingScheduleGroupedByRegion?.[regionName];

                  return (
                    <td
                      className="text-center"
                      key={`${regionName}-pending-schedule`}
                    >
                      {bookingsByCurrentRegion != null
                        ? bookingsByCurrentRegion?.length
                        : '0'}
                    </td>
                  );
                })}
              </tr>

              <tr>
                <th>Paid</th>

                {regions.map((regionName) => {
                  const bookingsByCurrentRegion =
                    bookingsPaidGroupedByRegion?.[regionName];

                  return (
                    <td className="text-center" key={`${regionName}-paid`}>
                      {bookingsByCurrentRegion != null
                        ? bookingsByCurrentRegion?.length
                        : '0'}
                    </td>
                  );
                })}
              </tr>

              <tr>
                <th>On hold</th>

                {regions.map((regionName) => {
                  const bookingsByCurrentRegion =
                    bookingsOnHoldGroupedByRegion?.[regionName];

                  return (
                    <td className="text-center" key={`${regionName}-on-hold`}>
                      {bookingsByCurrentRegion != null
                        ? bookingsByCurrentRegion?.length
                        : '0'}
                    </td>
                  );
                })}
              </tr>

              <tr>
                <th>Total</th>

                {regions.map((regionName) => {
                  const bookingsOnHoldByCurrentRegion =
                    bookingsOnHoldGroupedByRegion?.[regionName];

                  const bookingsPendingScheduleByCurrentRegion =
                    bookingsPendingScheduleGroupedByRegion?.[regionName];

                  const bookingsPaidPendingScheduleCurrentRegion =
                    bookingsPaidPendingScheduleGroupedByRegion?.[regionName];

                  const bookingsPaidByCurrentRegion =
                    bookingsPaidGroupedByRegion?.[regionName];

                  const totalBookingByCurrentRegion = [
                    ...(bookingsOnHoldByCurrentRegion ?? []),
                    ...(bookingsPendingScheduleByCurrentRegion ?? []),
                    ...(bookingsPaidPendingScheduleCurrentRegion ?? []),
                    ...(bookingsPaidByCurrentRegion ?? []),
                  ];

                  return (
                    <td className="text-center" key={`${regionName}-total`}>
                      {`${totalBookingByCurrentRegion?.length}`}
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </table>
        </div>
      </ContentWrapper>

      <ContentWrapper>
        <h3>Request allocation</h3>

        <div className="d-flex justify-content-start">
          {[10, 5, 1].map((claimSize) => (
            <button
              key={claimSize}
              className="btn btn-primary mr-3"
              style={{ minWidth: '8rem' }}
              disabled={
                bookingsAccountOwnerQueueByIdAsAdminQuery.loading ||
                claimLeadsIsPending ||
                (bookingsPendingToClaim?.length ?? 0) === 0
              }
              onClick={() =>
                setClaimLeadsPromise(
                  accountOwnerQueueLeadsAllocateAsAdmin({
                    variables: {
                      leadsBatchSize: claimSize,
                    },
                  })
                    .then((response) => {
                      const accountOwnerQueueLeadsAllocateAsAdminResponse =
                        response.data?.accountOwnerQueueLeadsAllocateAsAdmin;

                      if (
                        accountOwnerQueueLeadsAllocateAsAdminResponse != null
                      ) {
                        setLeadsAllocationTrackingInfo([
                          ...(leadsAllocationTrackingInfo ?? []),
                          accountOwnerQueueLeadsAllocateAsAdminResponse,
                        ]);
                      }
                    })
                    .catch(() => {
                      setLeadsAllocationTrackingInfo([
                        { message: 'Request allocation failed' },
                      ]);
                    })
                )
              }
            >
              <span>
                {claimSize} {claimSize === 1 ? 'lead' : 'leads'}
              </span>
            </button>
          ))}
        </div>

        <div
          style={{ display: 'flex', flexDirection: 'column', paddingTop: 16 }}
        >
          {leadsAllocationTrackingInfo?.map((allocationTracking) => (
            <span
              key={allocationTracking.jobStatusUrl}
              style={{
                paddingTop: 16,
                display: 'flex',
              }}
            >
              <p style={{ margin: 0, paddingRight: 8 }}>
                {allocationTracking.message}
              </p>

              {allocationTracking.jobStatusUrl != null && (
                <a
                  href={allocationTracking.jobStatusUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  Job status url
                </a>
              )}
            </span>
          ))}
        </div>
      </ContentWrapper>
    </>
  );
}
