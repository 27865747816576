import React from 'react';

export class HotJarLoader extends React.Component<{
  children?: React.ReactNode;
  hotJarId?: string;
  userId: string;
}> {
  static loadPromise;

  state = { loadPromise: null };

  componentDidMount() {
    const { hotJarId, userId } = this.props;

    if (hotJarId == null) return;

    this.setState({
      loadPromise: (() => {
        if (!HotJarLoader.loadPromise) {
          HotJarLoader.loadPromise = new Promise((resolve, reject) => {
            const head = document.getElementsByTagName('head')[0];
            const script = document.createElement('script');
            script.text = `
              (function(h,o,t,j,a,r){
                  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                  h._hjSettings={hjid:${hotJarId},hjsv:6};
                  a=o.getElementsByTagName('head')[0];
                  r=o.createElement('script');r.async=1;
                  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                  a.appendChild(r);
              })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');

              var userId = '${userId}' || null; // Replace your_user_id with your own if available.
              window.hj('identify', userId, {
                  // Add your own custom attributes here. Some EXAMPLES:
                  // 'Signed up': '2019—06-20Z', // Signup date in ISO-8601 format.
              });
            `;

            // There are several events for cross browser compatibility.
            // @ts-ignore
            script.onreadystatechange = () => resolve(window.FS);
            // @ts-ignore
            script.onload = () => resolve(window.FS);
            script.onerror = (err) =>
              reject(typeof err === 'string' ? new Error(err) : err);

            head.appendChild(script);
          });
        }

        return HotJarLoader.loadPromise;
      })(),
    });
  }

  render() {
    return this.props.children || null;
  }
}
