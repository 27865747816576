import React from 'react';
import { Link } from 'react-router-dom';

import { BOOKING_STATUS } from '../../selectors';
import { ModalRoute, locationForModal } from '../ModalRoute';
import { NextBooking } from './NextBooking';

const MODAL_NAME_NEXT_BOOKING = 'next_booking';

export const NextBookingModal = (props) => (
  <>
    {BOOKING_STATUS.COMPLETED === props.booking.status && (
      <Link
        style={{ marginRight: '16px' }}
        to={locationForModal({
          location: props.location,
          modal: { modalName: MODAL_NAME_NEXT_BOOKING },
        })}
      >
        Next Booking
      </Link>
    )}

    <ModalRoute
      modalName={MODAL_NAME_NEXT_BOOKING}
      render={({ history, location }) => (
        <NextBooking
          {...props}
          onDismiss={() =>
            history.replace(
              locationForModal({
                location,
                modal: undefined,
              })
            )
          }
        />
      )}
    />
  </>
);
