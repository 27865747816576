import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';

import { BOOKING_STATUS } from '../../selectors';
import { ModalRoute, locationForModal } from '../ModalRoute';
import { ProviderCancellation } from './ProviderCancellation';

const MODAL_NAME_PROVIDER_CANCELLATION = 'provider-cancellation';

export const ProviderCancellationModal = ({
  booking,
  location,
  onReload,
  disabled,
}: {
  booking;
  location: RouteComponentProps['location'];
  onReload: () => Promise<any>;
  disabled?: boolean;
}) => (
  <>
    {BOOKING_STATUS.PAID === booking.status && (
      <Link
        className={`btn btn-danger ${disabled ? 'disabled' : ''}`}
        to={locationForModal({
          location: location,
          modal: { modalName: MODAL_NAME_PROVIDER_CANCELLATION },
        })}
      >
        Provider cancellation
      </Link>
    )}

    <ModalRoute
      modalName={MODAL_NAME_PROVIDER_CANCELLATION}
      render={({ history, location }) => (
        <ProviderCancellation
          booking={booking}
          onReload={onReload}
          onDismiss={() =>
            history.replace(
              locationForModal({
                location,
                modal: undefined,
              })
            )
          }
        />
      )}
    />
  </>
);
